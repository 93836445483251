import {
    Box,
    Card,
  } from "@mui/material";
import React, { useState } from "react";
import utilsFunc from "../../utils/utilsFunctions";
import ChangePassword from "./ChangePassword";
import Login from "./Login";
import RecoverPassword from "./RecoverPassword";
import "./SignIn.css";
import logoPropins from "../../img/logo.svg";

const isMobile =
  !!navigator.userAgent.match(/iphone|android|blackberry/gi) || false;

const style = {
  position: "absolute",
  top: "30%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 500,
  bgcolor: "background.paper",
  borderRadius: "8px",
  p: 4,
};

const styleMobile = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "90vw",
  bgcolor: "background.paper",
  boxShadow: 24,
  p: 4,
};

const IndexSignIn = () => {
  const id = utilsFunc.getUrlParameter("id");
  const [current, setCurrent] = useState(id ? "change" : "login");
  /*
  const dispatch = useDispatch();
  const [message, setMessage] = useState(null);
  const request = useSelector((state) => state.app.requestLogin);

  useEffect(() => {
    id && dispatch(fetchValidaToken(id));
  }, [id]);
  */
  const changeStep = (step) => setCurrent(step);

  return (
    <Box className="sign-in">
      <Box sx={isMobile ? styleMobile : style}>
        <Card variant="outlined" sx={{border: "none"}}>
          <React.Fragment>
            <img src={logoPropins} alt="Logo propins" className="logo"></img>
            {current === "login" && (<Login changeLoginState={changeStep} />)}
            {current === "change" && (<ChangePassword changeLoginState={changeStep} />)}
            {current === "recover" && (<RecoverPassword changeLoginState={changeStep} />)}
          </React.Fragment>
        </Card>
      </Box>
    </Box>
    
  );
};
  
export default IndexSignIn;
  