import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  getClientesPaginados,
  getPropiedadesPaginadas,
  getProspectos,
  getUsuario,
  setComunas,
} from "../../action";
import { useState } from "react";
import {
  Card,
  CardContent,
  CircularProgress,
  Divider,
  Grid,
  IconButton,
  Tooltip,
} from "@mui/material";
import moment from "moment";
import utilsFunc from "../../utils/utilsFunctions";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import EditIcon from "@mui/icons-material/Edit";
import ModalEditarDatosPersonales from "./ModalEditarDatosPersonales";
import ModalEditarDatosBancarios from "./ModalEditarDatosBancarios";
import Swal from "sweetalert2";

const currentMonth = moment().get("month");
const currentYear = moment().get("year");

const pastDate = moment().subtract(1, "months");

const Home = () => {
  const dispatch = useDispatch();
  const [isLoading, setIsLoading] = useState(false);
  const [openModalEditarDatosPersonales, setOpenModalEditarDatosPersonales] =
    useState(false);
  const handleCloseModalEditarDatosPersonales = () =>
    setOpenModalEditarDatosPersonales(false);

  const [openModalEditarDatosBancarios, setOpenModalEditarDatosBancarios] =
    useState(false);
  const handleCloseModalEditarDatosBancarios = () =>
    setOpenModalEditarDatosBancarios(false);
  const [metricas, setMetricas] = useState({});
  const propietarios = useSelector(
    (state) => state.app.clientes && state.app.clientes.results
  );
  const [prospectos, setProspectos] = useState([]);
  const requestGetPropietarios = useSelector(
    (state) => state.app.requestGetClientes && state.app.requestGetClientes
  );
  let propiedades = useSelector(
    (state) =>
      state.app.propiedadesPaginadas && state.app.propiedadesPaginadas.results
  );
  const requestPropiedadesPaginadas = useSelector(
    (state) => state.app.requestPropiedadesPaginadas
  );
  const prospectosCA = useSelector((state) =>
    state.app.prospectosCA && state.app.prospectosCA.results
      ? state.app.prospectosCA.results
      : []
  );
  const requestGetProspectosCA = useSelector(
    (state) => state.app.requestGetProspectosCA
  );
  const prospectosVA = useSelector((state) =>
    state.app.prospectosCA && state.app.prospectosVA.results
      ? state.app.prospectosVA.results
      : []
  );
  const requestGetProspectosVA = useSelector(
    (state) => state.app.requestGetProspectosVA
  );
  const requestGetUsuario = useSelector((state) => state.app.requestGetUsuario);
  const requestStateUpdate = useSelector(
    (state) => state.app.requestUpdateUsuario
  );
  const errorMessage = useSelector((state) => state.app.errorMessage);
  const userData = useSelector((state) => state.app.usuario);
  const itemUsuario = useSelector((state) => state.app.itemUsuario);
  useEffect(() => {
    dispatch(getUsuario());
  }, []);
  useEffect(() => {
    dispatch(
      getPropiedadesPaginadas(
        "",
        userData && userData.tipoCuenta === 10 ? userData.userId : "",
        0,
        999,
        "todos",
        "createdAtDescending",
        "",
        "",
        "",
        0,
        0,
        0,
        0,
        0,
        0,
        0,
        0,
        0,
        0,
        "",
        "",
        ""
      )
    );
    dispatch(getProspectos("ca", 0, 999, "", "", "", 0, 0));
    dispatch(getProspectos("va", 0, 999, "", "", "", 0, 0));
    //dispatch(getUsuario());
    dispatch(getClientesPaginados(0, 999, "true"));
  }, [userData, itemUsuario]);

  useEffect(() => {
    if (
      requestPropiedadesPaginadas === "LOADING" ||
      requestGetProspectosVA === "LOADING" ||
      requestGetProspectosCA === "LOADING"
    ) {
      setIsLoading(true);
    } else {
      setIsLoading(false);
    }
  }, [
    requestPropiedadesPaginadas,
    requestGetProspectosVA,
    requestGetProspectosCA,
  ]);

  useEffect(() => {
    let propiedadesCurrentMonth = [];
    let propiedadesPastMonth = [];
    let propiedadesCurrentMonthVenta = [];
    let propiedadesCurrentMonthArriendo = [];
    let propiedadesVenta = [];
    let propiedadesArriendo = [];
    let propiedadesCerradas = [];
    let comisiones = 0;
    let comunas = new Set();

    if (
      propiedades &&
      propiedades.length > 0 &&
      requestPropiedadesPaginadas !== "LOADING"
    ) {
      propiedades.map((p) => {
        if (!comunas.has(p.comuna)) {
          comunas.add(p.comuna);
        }

        const fechaCreacion = moment(p.createdAt.split("T")[0]);
        if (
          fechaCreacion.month() === currentMonth &&
          fechaCreacion.year() === currentYear
        ) {
          propiedadesCurrentMonth.push(p);
          if (p.planContratado !== null) {
            if (p.planContratado.esVenta) {
              propiedadesCurrentMonthVenta.push(p);
            } else {
              propiedadesCurrentMonthArriendo.push(p);
            }
          }
        }

        if (
          fechaCreacion.month() === pastDate.month() &&
          fechaCreacion.year() === pastDate.year()
        ) {
          propiedadesPastMonth.push(p);
        }

        if (p.planContratado !== null) {
          if (p.planContratado.esVenta) {
            propiedadesVenta.push(p);
          } else {
            propiedadesArriendo.push(p);
          }
        }

        const fechaTermino = moment(p.fechaTermino.split("T")[0]);
        if (
          fechaTermino &&
          fechaTermino.year() !== null &&
          fechaTermino.year() !== undefined &&
          fechaTermino.year().toString().includes("20")
        ) {
          if (
            fechaTermino.month() === currentMonth &&
            fechaTermino.year() === currentYear
          ) {
            propiedadesCerradas.push(p);
            if (p.planContratado !== null) {
              if (p.planContratado.esVenta) {
                comisiones = comisiones + p.valorCompraPropiedad * 0.5;
              } else {
                comisiones = comisiones + p.valorCompraPropiedad * 0.5;
              }
            }
          }
        }
      });
    }
    let mejoraCaptacionPropiedades =
      propiedadesCurrentMonth.length - propiedadesPastMonth.length;

    dispatch(setComunas(comunas));
    setMetricas({
      ...metricas,
      comisiones,
      propiedadesArriendo,
      propiedadesVenta,
      propiedadesCerradas,
      mejoraCaptacionPropiedades: `${mejoraCaptacionPropiedades}`,
      propiedadesCaptadasCurrentMonth: propiedadesCurrentMonth,
      propiedadesCaptadasPastMonth: propiedadesPastMonth,
      propiedadesCaptadasCurrentMonthVenta: propiedadesCurrentMonthVenta,
      propiedadesCaptadasCurrentMonthArriendo: propiedadesCurrentMonthArriendo,
    });
  }, [propiedades, requestPropiedadesPaginadas]);

  useEffect(() => {
    let prospectosCurrentMonth = [];
    let prospectosPastMonth = [];
    let prospectosCAcaptados = [];
    let prospectosVAcaptados = [];
    var prospectosUnion = [...new Set([...prospectosCA, ...prospectosVA])];
    setProspectos(prospectosUnion);
    if (
      prospectosUnion &&
      prospectosUnion.length > 0 &&
      requestGetProspectosCA !== "LOADING" &&
      requestGetProspectosVA !== "LOADING"
    ) {
      prospectosUnion.map((p) => {
        const fechaCreacion = moment(p.createdAt.split("T")[0]);
        if (
          fechaCreacion.month() === currentMonth &&
          fechaCreacion.year() === currentYear
        ) {
          prospectosCurrentMonth.push(p);

          if (p.tipoPropiedad !== null) {
            prospectosVAcaptados.push(p);
          } else {
            prospectosCAcaptados.push(p);
          }
        }

        if (
          fechaCreacion.month() === pastDate.month() &&
          fechaCreacion.year() === pastDate.year()
        ) {
          prospectosPastMonth.push(p);
        }
      });
    }
    let mejoraCaptacionProspectos =
      prospectosCurrentMonth.length - prospectosPastMonth.length;

    setMetricas({
      ...metricas,
      mejoraCaptacionProspectos: `${mejoraCaptacionProspectos}`,
      prospectosCAcaptados,
      prospectosVAcaptados,
      prospectosCaptadosCurrentMonth: prospectosCurrentMonth,
      prospectosCaptadosPastMonth: prospectosPastMonth,
    });
  }, [
    prospectosCA,
    prospectosVA,
    requestGetProspectosCA,
    requestGetProspectosVA,
  ]);

  useEffect(() => {
    let prospectosPasadosClienteCurrentMonth = [];
    let prospectosPasadosClientePastMonth = [];
    let prospectosPasadosCliente = [];

    if (
      prospectos &&
      prospectos.length > 0 &&
      propietarios &&
      propietarios.length > 0 &&
      requestGetProspectosCA !== "LOADING" &&
      requestGetProspectosVA !== "LOADING" &&
      requestGetPropietarios !== "LOADING"
    ) {
      propietarios.map((p) => {
        const fechaCreacion = moment(p.createdAt.split("T")[0]);
        if (prospectos.some((x) => x.email === p.mail)) {
          prospectosPasadosCliente.push(p);
        }
        if (
          fechaCreacion.month() === currentMonth &&
          fechaCreacion.year() === currentYear
        ) {
          if (prospectos.some((x) => x.email === p.mail)) {
            prospectosPasadosClienteCurrentMonth.push(p);
          }
        }

        if (
          fechaCreacion.month() === pastDate.month() &&
          fechaCreacion.year() === pastDate.year()
        ) {
          if (prospectos.some((x) => x.email === p.mail)) {
            prospectosPasadosClientePastMonth.push(p);
          }
        }
      });
    }
    /*
    let mejoraCaptacionProspectos = 
        prospectosCurrentMonth.length - prospectosPastMonth.length;
    */
    setMetricas({
      ...metricas,
      //mejoraCaptacionProspectos,
      prospectosPasadosCliente,
      prospectosPasadosClientePastMonth,
      prospectosPasadosClienteCurrentMonth,
    });
  }, [prospectos]);

  const { formatToThousandSeparator } = utilsFunc;

  if (requestStateUpdate === "SUCCESS") {
    Swal.fire({
      title: "Datos personales actualizados",
      text: "Se han actualizado sus datos personales exitosamente",
      icon: "success",
    }).then((result) => {
      if (result.value || result.dismiss === "backdrop") {
        handleCloseModalEditarDatosBancarios();
        handleCloseModalEditarDatosPersonales();
        dispatch(getUsuario());
      }
    });
  }

  if (requestStateUpdate === "ERROR") {
    Swal.fire({
      title: "Error",
      text: errorMessage,
      icon: "error",
    });
  }

  return (
    <div className="contenido">
      <div className="header">
        <h2>Home</h2>
      </div>      
      {isLoading && <CircularProgress />}
      {!isLoading && (
        <div className="sobrePropiedades">
          <Grid container spacing={4}>
            <Grid item xs={12} sm={12} md={5} lg={5} xl={4}>
              <Card sx={{ minWidth: 275, pl: 2, pr: 2 }}>
                <CardContent>
                  <Grid
                    container
                    spacing={2}
                    sx={{ mb: 2 }}
                    justifyContent="space-between"
                  >
                    <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                      <AccountCircleIcon
                        sx={{ fontSize: 256, color: "darkgray" }}
                      />
                    </Grid>
                    <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                      <Grid
                        container
                        justifyContent="space-between"
                        alignItems="flex-end"
                      >
                        <Grid item xs={10} sm={10} md={12} lg={10} xl={10}>
                          <h4>
                            Hola {userData && userData.nombres}{" "}
                            {userData && userData.apellidos} !
                          </h4>
                        </Grid>
                        <Grid item>
                          <Tooltip title="Editar" aria-label="editar">
                            <IconButton
                              aria-label="Editar"
                              component="span"
                              onClick={() =>
                                setOpenModalEditarDatosPersonales(true)
                              }
                            >
                              <EditIcon />
                            </IconButton>
                          </Tooltip>
                        </Grid>
                      </Grid>
                    </Grid>
                    {requestGetUsuario === "LOADING" && <CircularProgress />}
                    {requestGetUsuario !== "LOADING" && (
                      <Grid container spacing={2} sx={{ pr: 2, pl: 2 }}>
                        <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                          <Grid
                            container
                            spacing={2}
                            justifyContent="space-between"
                          >
                            <Grid
                              item
                              xs={4}
                              sm={4}
                              md={4}
                              lg={4}
                              xl={4}
                              className="alignLeft"
                            >
                              Rut
                            </Grid>
                            <Grid item className="alignLeft">
                              {userData && userData.rut}
                            </Grid>
                          </Grid>
                        </Grid>

                        <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                          <Grid
                            container
                            spacing={2}
                            justifyContent="space-between"
                          >
                            <Grid
                              item
                              xs={4}
                              sm={4}
                              md={4}
                              lg={4}
                              xl={4}
                              className="alignLeft"
                            >
                              Teléfono
                            </Grid>
                            <Grid item className="alignLeft">
                              {userData && userData.telefono}
                            </Grid>
                          </Grid>
                        </Grid>

                        <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                          <Grid
                            container
                            spacing={2}
                            sx={{ mb: 2 }}
                            justifyContent="space-between"
                          >
                            <Grid
                              item
                              xs={4}
                              sm={4}
                              md={4}
                              lg={4}
                              xl={4}
                              className="alignLeft"
                            >
                              Correo
                            </Grid>
                            <Grid item className="alignLeft">
                              {userData && userData.mail}
                            </Grid>
                          </Grid>
                        </Grid>
                      </Grid>
                    )}

                    <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                      <Divider
                        variant="middle"
                        sx={{ mb: 2, backgroundColor: "black" }}
                      />
                    </Grid>
                    <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                      <Grid container justifyContent="space-between">
                        <Grid
                          item
                          xs={10}
                          sm={10}
                          md={10}
                          lg={10}
                          xl={10}
                          className="alignLeft"
                        >
                          <h5>Datos bancarios</h5>
                        </Grid>
                        <Grid item>
                          <Tooltip title="Editar" aria-label="editar">
                            <IconButton
                              //color="primary"
                              aria-label="Editar"
                              component="span"
                              onClick={() =>
                                setOpenModalEditarDatosBancarios(true)
                              }
                            >
                              <EditIcon />
                            </IconButton>
                          </Tooltip>
                        </Grid>
                      </Grid>
                    </Grid>
                    {requestGetUsuario === "LOADING" && <CircularProgress />}
                    {requestGetUsuario !== "LOADING" && (
                      <Grid container spacing={2} sx={{ pr: 2, pl: 2 }}>
                        <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                          <Grid
                            container
                            spacing={2}
                            justifyContent="space-between"
                          >
                            <Grid
                              item
                              xs={5}
                              sm={5}
                              md={5}
                              lg={5}
                              xl={5}
                              className="alignLeft"
                            >
                              Banco
                            </Grid>
                            <Grid item className="alignLeft">
                              {userData &&
                                userData.datosBancarios &&
                                userData.datosBancarios.banco}
                            </Grid>
                          </Grid>
                        </Grid>

                        <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                          <Grid
                            container
                            spacing={2}
                            justifyContent="space-between"
                          >
                            <Grid
                              item
                              xs={5}
                              sm={5}
                              md={5}
                              lg={5}
                              xl={5}
                              className="alignLeft"
                            >
                              Tipo cuenta
                            </Grid>
                            <Grid item className="alignLeft">
                              {userData &&
                                userData.datosBancarios &&
                                userData.datosBancarios.tipoCuenta}
                            </Grid>
                          </Grid>
                        </Grid>

                        <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                          <Grid
                            container
                            spacing={2}
                            justifyContent="space-between"
                          >
                            <Grid
                              item
                              xs={5}
                              sm={5}
                              md={5}
                              lg={5}
                              xl={5}
                              className="alignLeft"
                            >
                              Número cuenta
                            </Grid>
                            <Grid item className="alignLeft">
                              {userData &&
                                userData.datosBancarios &&
                                userData.datosBancarios.numeroCuenta}
                            </Grid>
                          </Grid>
                        </Grid>
                      </Grid>
                    )}
                  </Grid>
                </CardContent>
              </Card>
            </Grid>
            <Grid item xs={12} sm={12} md={7} lg={6} xl={6}>
              <Grid container spacing={2} justifyContent="space-between">
                <Grid item>
                  <h4>Mi rendimiento</h4>
                </Grid>
                <Grid item sx={{ display: "none" }}>
                  <button className="boton">Descargar informe</button>
                </Grid>
              </Grid>
              <Grid
                container
                spacing={2}
                justifyContent="space-between"
                sx={{ mb: 2 }}
              >
                <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                  <Card sx={{ pl: 2, pr: 2 }}>
                    <CardContent>
                      <Grid
                        container
                        spacing={2}
                        justifyContent="space-between"
                        alignItems="center"
                      >
                        <Grid item>
                          <Grid
                            container
                            justifyContent="flex-start"
                            alignItems="center"
                          >
                            <Grid
                              item
                              xs={12}
                              sm={12}
                              md={12}
                              lg={12}
                              xl={12}
                              className="metrica-title alignLeft"
                            >
                              Comisiones generadas
                            </Grid>
                            <Grid
                              item
                              xs={12}
                              sm={12}
                              md={12}
                              lg={12}
                              xl={12}
                              className="metrica-subtitle alignLeft"
                            >
                              % total de comisiones por venta
                            </Grid>
                          </Grid>
                        </Grid>
                        <Grid item className="bold-subtitle">
                          $
                          {metricas.comisiones &&
                            metricas.comisiones !== 0 &&
                            formatToThousandSeparator(metricas.comisiones)}
                        </Grid>
                      </Grid>
                    </CardContent>
                  </Card>
                </Grid>
              </Grid>

              <Grid
                container
                spacing={2}
                justifyContent="space-between"
                sx={{ mb: 2 }}
              >
                <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                  <Card sx={{ height: "100%", pl: 2, pr: 2 }}>
                    <CardContent>
                      <Grid
                        container
                        spacing={2}
                        justifyContent="space-between"
                      >
                        <Grid
                          item
                          className="metrica-title alignLeft"
                          xs={10}
                          sm={10}
                          md={10}
                          lg={10}
                          xl={10}
                        >
                          Leads captados
                        </Grid>
                        <Grid
                          item
                          className="bold-subtitle"
                          xs={2}
                          sm={2}
                          md={2}
                          lg={2}
                          xl={2}
                        >
                          {metricas.prospectosCaptadosCurrentMonth &&
                            metricas.prospectosCaptadosCurrentMonth.length}
                        </Grid>
                      </Grid>
                    </CardContent>
                  </Card>
                </Grid>
                <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                  <Card sx={{ pl: 2, pr: 2 }}>
                    <CardContent>
                      <Grid
                        container
                        spacing={2}
                        justifyContent="space-between"
                      >
                        <Grid item xs={9} sm={9} md={9} lg={9} xl={9}>
                          <Grid
                            container
                            justifyContent="flex-start"
                            alignItems="center"
                          >
                            <Grid
                              item
                              xs={12}
                              sm={12}
                              md={12}
                              lg={12}
                              xl={12}
                              className="metrica-title alignLeft"
                            >
                              Conversión leads
                            </Grid>
                            <Grid
                              item
                              xs={12}
                              sm={12}
                              md={12}
                              lg={12}
                              xl={12}
                              className="metrica-subtitle alignLeft"
                            >
                              de prospecto a cliente
                            </Grid>
                          </Grid>
                        </Grid>
                        <Grid item className="bold-subtitle">
                          {metricas.prospectosPasadosCliente &&
                            metricas.prospectosPasadosCliente.length}
                        </Grid>
                      </Grid>
                    </CardContent>
                  </Card>
                </Grid>
              </Grid>

              <Grid
                container
                spacing={2}
                justifyContent="space-between"
                sx={{ mb: 2 }}
              >
                <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                  <Card sx={{ height: "100%", pl: 2, pr: 2 }}>
                    <CardContent>
                      <Grid
                        container
                        spacing={2}
                        justifyContent="space-between"
                        alignItems="center"
                      >
                        <Grid
                          item
                          className="metrica-title alignLeft"
                          xs={10}
                          sm={10}
                          md={10}
                          lg={10}
                          xl={10}
                        >
                          Propiedades captadas
                        </Grid>
                        <Grid
                          item
                          className="bold-subtitle"
                          xs={2}
                          sm={2}
                          md={2}
                          lg={2}
                          xl={2}
                        >
                          {metricas.propiedadesCaptadasCurrentMonth &&
                            metricas.propiedadesCaptadasCurrentMonth.length}
                        </Grid>
                      </Grid>
                    </CardContent>
                  </Card>
                </Grid>
                <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                  <Card sx={{ pl: 2, pr: 2 }}>
                    <CardContent>
                      <Grid
                        container
                        spacing={2}
                        justifyContent="space-between"
                      >
                        <Grid item xs={9} sm={9} md={9} lg={9} xl={9}>
                          <Grid
                            container
                            justifyContent="flex-start"
                            alignItems="center"
                          >
                            <Grid
                              item
                              xs={12}
                              sm={12}
                              md={12}
                              lg={12}
                              xl={12}
                              className="metrica-title alignLeft"
                            >
                              N° de ventas cerradas
                            </Grid>
                            <Grid
                              item
                              xs={12}
                              sm={12}
                              md={12}
                              lg={12}
                              xl={12}
                              className="metrica-subtitle alignLeft"
                            >
                              con plan contratado
                            </Grid>
                          </Grid>
                        </Grid>
                        <Grid item className="bold-subtitle">
                          {metricas.propiedadesCerradas &&
                            metricas.propiedadesCerradas.length}
                        </Grid>
                      </Grid>
                    </CardContent>
                  </Card>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </div>
      )}
      <ModalEditarDatosPersonales
        isOpen={openModalEditarDatosPersonales}
        values={userData}
        handleClose={handleCloseModalEditarDatosPersonales}
        //reloadMethod={loadUserdata}
      />
      <ModalEditarDatosBancarios
        isOpen={openModalEditarDatosBancarios}
        values={userData}
        handleClose={handleCloseModalEditarDatosBancarios}
        //reloadMethod={loadUserdata}
      />
    </div>
  );
};

export default Home;
