import {
  Backdrop,
  Button,
  CardContent,
  CircularProgress,
  Grid,
} from "@mui/material";
import { Field, Form, Formik } from "formik";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { fetchPostLoginUser } from "../../action";

const Login = ({changeLoginState}) => {
  const history = useHistory();
  const dispatch = useDispatch();
  const [readyToRedirect, setReadyToRedirect] = useState(false);
  const [message, setMessage] = useState(null);
  const request = useSelector((state) => state.app.requestLogin);
  const userData = useSelector((state) => state.app.itemUsuario);
  const lastErrorMessage = useSelector((state) => state.app.errorMessage);
  const userDataLocalStorage = localStorage.getItem('user');
  useEffect(() => {
    if (request === "SUCCESS") {
      setReadyToRedirect(true);
      setMessage("Login exitoso. Redireccionando...");
    }
  }, [request]);

  useEffect(() => {
    if (request === "ERROR") {
      setReadyToRedirect(false);
      setMessage(lastErrorMessage);
    }
  }, [request]);

  useEffect(() => {
    if (readyToRedirect && userData && userData.token && userDataLocalStorage && history !== null) {
      setTimeout(() => {
        history.push("/");
      }, 500);
    }
  }, [userData, userDataLocalStorage, request, readyToRedirect, history]);
  
  const handleSubmit = (data) => {
    dispatch(fetchPostLoginUser(data.password, data.email));
  };

  return (
    <CardContent>
      <Grid container>
        <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
          <h3>Ingresa al sitio</h3>
        </Grid>
      </Grid>
      {!readyToRedirect && request === "LOADING" && (
        <Backdrop
          sx={{
            color: "#fff",
            zIndex: (theme) => theme.zIndex.drawer + 1,
          }}
          open={true}
          onClick={console.log("cerrar")}
        >
          <CircularProgress color="inherit" />
        </Backdrop>
      )}
      <Formik
        initialValues={{
          email: "",
          password: "",
        }}
        onSubmit={handleSubmit}
      >
        {({ values, handleSubmit }) => (
          <Form onSubmit={handleSubmit}>
            <Grid container>
              <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                <label className="milabel">Email</label>
                <Field name="email" type="text" className="miSelect" />
              </Grid>
            </Grid>
            <Grid container>
              <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                <label className="milabel">Contraseña</label>
                <Field
                  name="password"
                  type="password"
                  className="miSelect"
                />
              </Grid>
            </Grid>

            <Grid container justifyContent="center" sx={{ mt: 2 }}>
              <Grid item>
                <Button
                  type="submit"
                  className="main-btn"
                  variant="contained"
                  color="success"
                >
                  Ingresar
                </Button>
              </Grid>
            </Grid>
          </Form>
        )}
      </Formik>
      <Grid container>
        <Grid item xs={12} sm={12} md={12} lg={12} xl={12} sx={{mt: 2}} onClick={() => changeLoginState("recover")} className="cursor-pointer">
          ¿Olvidaste tu contraseña?
        </Grid>
        { message && 
          <Grid item xs={12} sm={12} md={12} lg={12} xl={12} sx={{mt: 2}}>
            <div>{message}</div>
          </Grid>
        }
      </Grid>
    </CardContent>
  );
};

export default Login;