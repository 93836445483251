import { Grid } from "@mui/material";
import React from "react";
import Back from "../../assets/Back";

const ArchivosActividad = ({ values, toggleVerArchivos }) => {
  return (
    <Grid
      container
      justifyContent="flex-start"
      className="alignLeft"
      rowSpacing={2}
    >
      <Back onClickMethod={toggleVerArchivos} />
      {values &&
        values.archivos &&
        values.archivos.length > 0 &&
        values.archivos
          .filter((f) => !f.deleted)
          .map((f) => {
            const format = f.name.slice(-4);
            if (format == ".pdf") {
              return (
                <Grid
                  item
                  xs={12}
                  sm={12}
                  md={12}
                  lg={12}
                  xl={12}
                  style={{ height: 300 }}
                >
                  <object
                    data={f.downloadLink}
                    type="application/pdf"
                    width="100%"
                    height="100%"
                  ></object>
                </Grid>
              );
            } else {
              return (
                <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                  <img src={f.downloadLink} alt={f.name} width="100%" />
                </Grid>
              );
            }
          })}
      {values && values.archivos && values.archivos.length == 0 && (
        <Grid
          item
          xs={12}
          sm={12}
          md={12}
          lg={12}
          xl={12}
          style={{ height: 300 }}
        >
          No hay archivos asociados a esta actividad
        </Grid>
      )}
    </Grid>
  );
};

export default ArchivosActividad;
