import { Formik, Form, Field } from "formik";
import { useDispatch, useSelector } from "react-redux";
import {
  Backdrop,
  Box,
  Button,
  CircularProgress,
  Grid,
  Modal,
} from "@mui/material";
import { useEffect, useState } from "react";
import { updateUsuario } from "../../action";
import { BANCOS, TIPOS_CUENTA_BANCARIA } from "../../utils/constants";

const isMobile =
    !!navigator.userAgent.match(/iphone|android|blackberry/gi) || false;

export const UPLOAD_IMAGE_MIME_TYPES = [
  "image/png",
  "image/x-citrix-png",
  "image/x-png",
  "image/jpeg",
  "image/x-citrix-jpeg",
  "image/pjpeg",
];

const style = {
  position: "absolute",
  top: "0px",
  left: "50%",
  transform: "translate(-50%, 0%)",
  width: 500,
  bgcolor: "background.paper",
  boxShadow: 24,
  p: 4,
  //maxHeight: '90%'
};

const styleMobile = {
  position: "absolute",
  top: "0px",
  left: "0px",
  //transform: "translate(-50%, 0%)",
  width: "100vw",
  bgcolor: "background.paper",
  boxShadow: 24,
  p: 4,
  //maxHeight: '90%'
};

const ModalEditarDatosBancarios = ({
  isOpen,
  handleClose,
  values,
}) => {
  const dispatch = useDispatch();
  const [isLoading, setIsLoading] = useState(false);
  const requestStateUpdate = useSelector(
    (state) => state.app.requestUpdateUsuario
  );

  const handleSubmit = (formValues) => {
    const data = {
      datosBancarios: {
        banco: formValues.banco,
        medioPago: formValues.medioPago,
        numeroCuenta: formValues.numeroCuenta,
        tipoCuenta: formValues.tipoCuenta,
      },
      nombres: values.nombres,
      apellidos: values.apellidos,
      telefono: values.telefono,
      email: values.mail,
      rut: values.rut,
    };
    
    dispatch(updateUsuario(data));
  };
  useEffect(() => {
    if (requestStateUpdate === "LOADING") {
      setIsLoading(true);
    } else {
      setIsLoading(false);
    }
  }, [requestStateUpdate]);

  const [openBackdrop, setOpenBackdrop] = useState(false);
  const handleCloseBackdrop = () => {
    setOpenBackdrop(false);
  };

  return (
    <Modal
      disableScrollLock={false}
      open={isOpen}
      onClose={handleClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"   
      sx={{
        overflowY: "auto",
        top: isMobile ? "0px" : "10px",
        maxHeight: isMobile ? "100%" : "95%",
      }}
    >
      <Box sx={isMobile ? styleMobile : style}>
        {isLoading && (
          <Backdrop
            sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
            open={true}
            onClick={handleCloseBackdrop}
          >
            <CircularProgress color="inherit" />
          </Backdrop>
        )}
        <h4>Actualizar datos bancarios</h4>
        <Formik
          initialValues={{
            banco:
              values && values.datosBancarios && values.datosBancarios.banco,
            medioPago:
              values &&
              values.datosBancarios &&
              values.datosBancarios.medioPago,
            numeroCuenta:
              values &&
              values.datosBancarios &&
              values.datosBancarios.numeroCuenta,
            tipoCuenta:
              values &&
              values.datosBancarios &&
              values.datosBancarios.tipoCuenta,
          }}
          onSubmit={handleSubmit}
        >
          {({ values, handleSubmit }) => (
            <Form onSubmit={handleSubmit}>
              <Grid container>
                <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                  <label className="milabel">Banco</label>
                  <Field name="banco" as="select" className="miSelect">
                    <option value={""}>- Seleccione -</option>
                    {BANCOS.map((c) => (
                      <option value={c}>{c}</option>
                    ))}
                  </Field>
                </Grid>
                <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                  <label className="milabel">Tipo cuenta bancaria</label>
                  <Field name="tipoCuenta" as="select" className="miSelect">
                    <option value={""}>- Seleccione -</option>
                    {TIPOS_CUENTA_BANCARIA.map((c) => (
                      <option value={c}>{c}</option>
                    ))}
                  </Field>
                </Grid>
                <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                  <label className="milabel">Numero cuenta</label>
                  <Field name="numeroCuenta" type="text" className="miSelect" />
                </Grid>
              </Grid>
              <Grid container justifyContent="flex-end" sx={{ mt: 2 }}>
                <Grid item>
                  <Button onClick={handleClose} className="main-btn">
                    Cancelar
                  </Button>
                </Grid>
                <Grid item>
                  <Button
                    type="submit"
                    className="main-btn"
                    variant="contained"
                    color="success"
                  >
                    Guardar
                  </Button>
                </Grid>
              </Grid>
            </Form>
          )}
        </Formik>
      </Box>
    </Modal>
  );
};

export default ModalEditarDatosBancarios;
