import React from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  fetchRecuperaPassword,
  setReestablecerPassword,
  setValidateToken,
  setRecoverPassword,
} from "../../action";
import { Formik, Form, Field } from "formik";
import Swal from "sweetalert2";
import { useEffect } from "react";
import {
  Backdrop,
  Button,
  CardContent,
  CircularProgress,
  Grid,
} from "@mui/material";
import Back from "../../assets/Back";
import FieldError from "../../utils/FieldError";

const RecoverPassword = ({ changeLoginState }) => {
  const dispatch = useDispatch();
  const message = "";
  /*
  constructor(props) {
    super(props);

    const { dispatch } = this.props;
    this.state = {
      email: "",
      password: "",
      redirectToReferrer: false,
      isLoad: false,
      isError: false,
    };
  }
  */
  const request = useSelector((state) => state.app.requestRecover);
  const recover = useSelector((state) => state.app.recover);
  useEffect(() => {
    dispatch(setReestablecerPassword(null));
    dispatch(setValidateToken(null));
    dispatch(setRecoverPassword(null));
    if (request === "ERROR") {
      //setReadyToRedirect(false);
      //setMessage(lastErrorMessage);
    }
  }, [request, dispatch]);

  const handleSubmit = (values) => {
    dispatch(fetchRecuperaPassword(values));
  };

  useEffect(() => {
    if (recover && recover.data && recover.data.estado === 1) {
      Swal.fire({
        icon: "success",
        title: "Recuperación realizada con éxito",
        text: "Un correo electrónico de recuperación ha sido enviado a su casilla electrónica",
      }).then((result) => {
        if (result.value || result.dismiss === "backdrop") {
          changeLoginState("login");
        }
      });
    } else if (recover && recover.estado === 0) {
      Swal.fire("Error", recover.mensaje, "error");
    } else {
    }
  }, [recover]);
  return (
    <CardContent>
      <Grid container>
        <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
          <h5>
            Ingresa la dirección de correo electrónico vinculada a tu cuenta y
            te enviaremos instrucciones para restablecer tu contraseña
          </h5>
        </Grid>
      </Grid>
      {request === "LOADING" && (
        <Backdrop
          sx={{
            color: "#fff",
            zIndex: (theme) => theme.zIndex.drawer + 1,
          }}
          open={true}
          onClick={console.log("cerrar")}
        >
          <CircularProgress color="inherit" />
        </Backdrop>
      )}
      <Formik
        initialValues={{
          usuarioEMail: "",
        }}
        onSubmit={handleSubmit}
        validate={validate}
      >
        {({ values, handleSubmit }) => (
          <Form onSubmit={handleSubmit}>
            <Grid container>
              <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                <label className="milabel">Correo electrónico</label>
                <Field name="usuarioEMail" type="text" className="miSelect" />
                <FieldError
                  name={"usuarioEMail"}
                  className={`color-red`}
                  //component={ErrorLabel}
                />
              </Grid>
            </Grid>

            <Grid container justifyContent="center" sx={{ mt: 2 }}>
              <Grid item>
                <Button
                  type="submit"
                  className="main-btn"
                  variant="contained"
                  color="success"
                >
                  Enviar
                </Button>
              </Grid>
            </Grid>
          </Form>
        )}
      </Formik>
      <Grid container>
        <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
          {message && <div>{message}</div>}
        </Grid>
      </Grid>
      <Back onClickMethod={() => changeLoginState("login")} />
    </CardContent>
  );
};

const validate = (formValues) => {
  const errors = {};

  if (formValues.usuarioEMail === "") {
    errors.usuarioEMail = "Por favor ingrese su correo electrónico";
  }
  if (
    !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(formValues.usuarioEMail) &&
    formValues.usuarioEMail !== ""
  ) {
    errors.usuarioEMail = "Correo electrónico inválido";
  }

  return errors;
};

export default RecoverPassword;
