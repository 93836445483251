import { BottomNavigation, BottomNavigationAction, Paper } from "@mui/material";
import {
  AccountCircle,
  Assignment,
  CameraAlt,
  Info,
} from "@mui/icons-material";

const PropiedadNavegacionMobile = ({ currentTab, handleChange }) => {
  return (
    <Paper
      sx={{ position: "fixed", bottom: 0, left: 0, right: 0 }}
      elevation={3}
    >
      <BottomNavigation
        showLabels
        //value={value}
        onChange={(event, newValue) => {
          handleChange(event, newValue);
        }}
      >
        <BottomNavigationAction label="Ficha" icon={<Info sx={{color: currentTab === 0 ? "#00a72c" : "inherit"}} />} />
        <BottomNavigationAction label="Fotos" icon={<CameraAlt sx={{color: currentTab === 1 ? "#00a72c" : "inherit"}} />} />
        <BottomNavigationAction label="Actividades" icon={<Assignment sx={{color: currentTab === 2 ? "#00a72c" : "inherit"}} />} />
        <BottomNavigationAction label="Propietario" icon={<AccountCircle sx={{color: currentTab === 3 ? "#00a72c" : "inherit"}} />} />
      </BottomNavigation>
    </Paper>
  );
};

export default PropiedadNavegacionMobile;