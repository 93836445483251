import { Edit, ExpandMore } from "@mui/icons-material";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Button,
  Divider,
  Grid,
  IconButton,
  Tooltip,
  Typography,
} from "@mui/material";
import { Field, Form, Formik } from "formik";
import { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { red } from "@mui/material/colors";
import Dropzone from "react-dropzone";
import * as Yup from "yup";
import FieldError from "../../utils/FieldError";

const UPLOAD_MIME_TYPES = [
  //"application/pdf"
  "image/png",
  "image/x-citrix-png",
  "image/x-png",
  "image/jpeg",
  "image/x-citrix-jpeg",
  "image/pjpeg",
];

const tipoFirmante = {
  4: "Datos del firmante (Broker externo)", //orden de canje
  5: "Datos del firmante (Propietario de propiedad seleccionada)", //orden de arriendo
  6: "Datos del firmante (Prospecto comprador seleccionado)", //carta oferta
  7: "Datos del firmante (Propietario de propiedad seleccionada)", //aceptacion carta oferta
  8: "Datos del firmante (Propietario)", //orden de venta
  9: "Datos del firmante (Prospecto comprador seleccionado)", //orden de visita
};

const validateSigner = Yup.object().shape({
  nombres: Yup.string().required("Ingrese nombres"),
  apellidos: Yup.string().required("Ingrese apellidos"),
  email: Yup.string()
    .email("Ingrese un correo electronico valido")
    .required("Ingrese correo electronico"),
  telefono: Yup.string()
    .matches(/^\+?569 ?[0-9]{8}$/, {
      message: "Telefono debe cumplir con formato +569XXXXXXXX",
    })
    .required("Ingrese telefono valido"),
  rut: Yup.string()
    .matches(/^[0-9]+-[0-9kK]{1}$/, { message: "Ingrese rut valido" })
    .required("Ingrese rut valido"),
  //archivoVersoIdentidad: Yup.string().required("Ingrese archivo verso documento identidad"),
  //archivoReversoIdentidad: Yup.string().required("Ingrese archivo reverso documento identidad"),
});

const FirmantesForm = ({
  setFieldValue,
  firmante,
  setFirmante,
  allowEditFirmanteLocal,
  editPath,
  tipoDocumento,
  usarDocumentosAlvaro,
  onChangeUsarDocumentosAlvaro,
}) => {
  const history = useHistory();
  const [validationMessages, setValidationMessages] = useState([]);
  const [versoDocIdPropins, setVersoDocIdPropins] = useState(null);
  const [reversoDocIdPropins, setReversoDocIdPropins] = useState(null);
  const [versoDocIdFirmante, setVersoDocIdFirmante] = useState(null);
  const [reversoDocIdFirmante, setReversoDocIdFirmante] = useState(null);
  const [files, setFiles] = useState([]);
  const [firmanteLocal, setFirmanteLocal] = useState(firmante);
  const [editDatosFirmante, setEditDatosFirmante] = useState(
    firmante && firmante.nombres !== "" ? false : true
  );
  const onGuardarDatosFirmanteLocal = (values) => {
    setFirmanteLocal(values);
    setEditDatosFirmante(false);
    setFirmante(values);
  };
  
  useEffect(() => {
    setFirmanteLocal(firmante);
  }, [firmante]);

  const onDrop = (files, setFieldValueMethod, fieldName) => {
    if (files) {
      files.map((f) => {
        if (f) {
          let result = fileValidator(f);

          if (result === null) {
            fileToBase64(f).then((file64) => {
              setFieldValueMethod(fieldName, file64);
              setFiles((oldValue) => [...oldValue, file64]);
              setValidationMessages([]);
            });
            switch (fieldName) {
              case "archivoVersoIdentidadPropins":
                setVersoDocIdPropins(f.name);
                break;
              case "archivoReversoIdentidadPropins":
                setReversoDocIdPropins(f.name);
                break;
              case "archivoVersoIdentidad":
                setVersoDocIdFirmante(f.name);
                break;
              default:
                setReversoDocIdFirmante(f.name);
                break;
            }
          } else {
            if (!validationMessages.includes(result)) {
              setValidationMessages((oldValue) => [...oldValue, result]);
            }
            switch (fieldName) {
              case "archivoVersoIdentidadPropins":
                setVersoDocIdPropins(null);
                break;
              case "archivoReversoIdentidadPropins":
                setReversoDocIdPropins(null);
                break;
              case "archivoVersoIdentidad":
                setVersoDocIdFirmante(null);
                break;
              default:
                setReversoDocIdFirmante(null);
                break;
            }
          }
        }
        return null;
      });
    }
  };

  const fileValidator = (file) => {
    let { name, type } = file;

    if (!UPLOAD_MIME_TYPES.includes(type)) {
      return `${name}: el tipo de archivo es inválido!`;
    }
    return null;
  };

  const fileToBase64 = (file) => {
    if (file) {
      return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => resolve(reader.result);
        reader.onerror = (error) => reject(error);
      });
    }
  };

  return (
    <div>
      <Grid container sx={{ mt: 2 }}>
        <Grid item>
          <h5>Firmantes</h5>
        </Grid>
      </Grid>
      <Grid container justifyContent={"flex-start"} alignItems={"center"}>
        <Grid item xs={5} sm={5} md={5} lg={5} xl={5} className="alignLeft">
          <Typography>Firmante 1: PROPINS SPA</Typography>
        </Grid>

        <Grid item xs={7} sm={7} md={7} lg={7} xl={7}>
          <Typography
            sx={{
              color: "text.secondary",
              fontSize: "12pt",
              marginLeft: "0.5rem",
            }}
          >
            Se usarán documentos de Álvaro Devescovi
          </Typography>
          {/*
          <FormGroup>
            <FormControlLabel
              control={
                <Checkbox
                  checked={usarDocumentosAlvaro}
                  size="small"
                  name="documentosAlvaro"
                  onChange={onChangeUsarDocumentosAlvaro}
                />
              }
              classes={{ root: "alignLeft" }}
              label="Usar documentos de Alvaro Devescovi"
            />
          </FormGroup>
  */}
        </Grid>

        <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
          {!usarDocumentosAlvaro && (
            <Grid container>
              <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                Seleccione archivo verso documento de identidad(*)
                <Dropzone
                  name="archivoVersoIdentidadPropins"
                  accept={UPLOAD_MIME_TYPES}
                  onDrop={(file) =>
                    onDrop(file, setFieldValue, "archivoVersoIdentidadPropins")
                  }
                  maxFiles={1}
                >
                  {({ getRootProps, getInputProps }) => (
                    <section>
                      <div {...getRootProps({ className: "dropzonenew" })}>
                        <input {...getInputProps()} />
                        <p>Arrastre el archivo aquí</p>
                      </div>
                    </section>
                  )}
                </Dropzone>
              </Grid>
              {versoDocIdPropins && (
                <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                  {versoDocIdPropins}
                </Grid>
              )}
              <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                <Divider
                  variant="middle"
                  sx={{ mb: 2, backgroundColor: "black" }}
                />
              </Grid>
              <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                Seleccione archivo reverso documento de identidad(*)
                <Dropzone
                  name="archivoReversoIdentidadPropins"
                  accept={UPLOAD_MIME_TYPES}
                  onDropAccepted={(file) =>
                    onDrop(
                      file,
                      setFieldValue,
                      "archivoReversoIdentidadPropins"
                    )
                  }
                  maxFiles={1}
                >
                  {({ getRootProps, getInputProps }) => (
                    <section>
                      <div {...getRootProps({ className: "dropzonenew" })}>
                        <input {...getInputProps()} />
                        <p>Arrastre el archivo aquí</p>
                      </div>
                    </section>
                  )}
                </Dropzone>
              </Grid>
              {reversoDocIdPropins && (
                <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                  {reversoDocIdPropins}
                </Grid>
              )}
            </Grid>
          )}
        </Grid>
        <Grid item xs={12} sm={12} md={12} lg={12} xl={12} sx={{ mt: 4 }}>
          <Divider variant="middle" sx={{ mb: 2, backgroundColor: "black" }} />
        </Grid>
        <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
          <Accordion>
            <AccordionSummary
              expandIcon={<ExpandMore />}
              aria-controls="panel2a-content"
              id="panel2a-header"
            >
              <Typography sx={{ width: "33%", flexShrink: 0, textAlign: "left" }}>
                Firmante 2:
                {firmanteLocal && firmanteLocal.nombres
                  ? `${firmanteLocal.nombres} ${firmanteLocal.apellidos}`
                  : "Sin información"}
              </Typography>
              <Typography
                sx={{
                  color: "text.secondary",
                  fontSize: "12pt",
                  marginLeft: "0.5rem",
                }}
              >
                Haga click aqui para completar formulario
              </Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Grid container justifyContent={"space-between"}>
                <Grid item xs={8} sm={8} md={8} lg={8} xl={8}>
                  <h6 className="alignLeft">{tipoFirmante[tipoDocumento]}</h6>
                </Grid>

                <Grid item>
                  {!editDatosFirmante && (
                    <Tooltip title="Editar" aria-label="editar">
                      <IconButton
                        //color="primary"
                        aria-label="Editar"
                        component="span"
                        onClick={() =>
                          allowEditFirmanteLocal
                            ? setEditDatosFirmante(true)
                            : history.push(editPath)
                        }
                      >
                        <Edit />
                      </IconButton>
                    </Tooltip>
                  )}
                </Grid>
                <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                  {allowEditFirmanteLocal && editDatosFirmante && (
                    <Formik
                      initialValues={{
                        nombres: firmanteLocal && firmanteLocal.nombres,
                        apellidos: firmanteLocal && firmanteLocal.apellidos,
                        email: firmanteLocal && firmanteLocal.email,
                        telefono: firmanteLocal && firmanteLocal.telefono,
                        rut: firmanteLocal && firmanteLocal.rut,
                      }}
                      //onSubmit={guardarDatosAlvaro}
                      validationSchema={validateSigner}
                    >
                      {({ values, isValid }) => (
                        <Form>
                          <Grid container spacing={1}>
                            <Grid item xs={6} sm={6} md={6} lg={6} xl={6}>
                              <label className="milabel">Nombres</label>
                              <Field
                                name="nombres"
                                type="text"
                                className="miSelect"
                              />
                              <FieldError name="nombres" />
                            </Grid>
                            <Grid item xs={6} sm={6} md={6} lg={6} xl={6}>
                              <label className="milabel">Apellidos</label>
                              <Field
                                name="apellidos"
                                type="text"
                                className="miSelect"
                              />
                              <FieldError name="apellidos" />
                            </Grid>
                          </Grid>
                          <Grid container spacing={1}>
                            <Grid item xs={6} sm={6} md={6} lg={6} xl={6}>
                              <label className="milabel">Rut</label>
                              <Field
                                name="rut"
                                type="text"
                                className="miSelect"
                              />
                              <FieldError name="rut" />
                            </Grid>
                            <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                              <label className="milabel">Telefono</label>
                              <Field
                                name="telefono"
                                type="text"
                                className="miSelect"
                              />
                              <FieldError name="telefono" />
                            </Grid>
                          </Grid>
                          <Grid container spacing={1}>
                            <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                              <label className="milabel">
                                Correo electronico
                              </label>
                              <Field
                                name="email"
                                type="text"
                                className="miSelect"
                              />
                              <FieldError name="email" />
                            </Grid>
                          </Grid>
                          <Grid
                            container
                            justifyContent="flex-end"
                            sx={{ mt: 2 }}
                          >
                            <Grid item>
                              <Button
                                type="button"
                                className="main-btn"
                                variant="contained"
                                color="success"
                                disabled={!isValid}
                                onClick={() =>
                                  isValid
                                    ? onGuardarDatosFirmanteLocal(values)
                                    : console.log("error")
                                }
                              >
                                Guardar
                              </Button>
                            </Grid>
                          </Grid>
                        </Form>
                      )}
                    </Formik>
                  )}
                  {!editDatosFirmante && (
                    <Grid container>
                      <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                        Nombre: {firmanteLocal && firmanteLocal.nombres}
                      </Grid>
                      <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                        Apellidos: {firmanteLocal && firmanteLocal.apellidos}
                      </Grid>
                      <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                        Rut: {firmanteLocal && firmanteLocal.rut}
                      </Grid>
                      <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                        Correo electronico:{" "}
                        {firmanteLocal && firmanteLocal.email}
                      </Grid>
                      <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                        Telefono: {firmanteLocal && firmanteLocal.telefono}
                      </Grid>
                    </Grid>
                  )}
                </Grid>
              </Grid>
              <Grid container>
                <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                  Seleccione archivo verso documento de identidad(*)
                  <Dropzone
                    name="archivoVersoIdentidad"
                    accept={UPLOAD_MIME_TYPES}
                    onDropAccepted={(file) =>
                      onDrop(file, setFieldValue, "archivoVersoIdentidad")
                    }
                    maxFiles={1}
                  >
                    {({ getRootProps, getInputProps }) => (
                      <section>
                        <div {...getRootProps({ className: "dropzonenew" })}>
                          <input {...getInputProps()} />
                          <p>Arrastre el archivo aquí</p>
                        </div>
                      </section>
                    )}
                  </Dropzone>
                </Grid>
                {versoDocIdFirmante && (
                  <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                    {versoDocIdFirmante}
                  </Grid>
                )}
                <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                  <Divider
                    variant="middle"
                    sx={{ mb: 2, backgroundColor: "black" }}
                  />
                </Grid>
                <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                  Seleccione archivo reverso documento de identidad(*)
                  <Dropzone
                    name="archivoReversoIdentidad"
                    accept={UPLOAD_MIME_TYPES}
                    onDropAccepted={(file) =>
                      onDrop(file, setFieldValue, "archivoReversoIdentidad")
                    }
                    maxFiles={1}
                  >
                    {({ getRootProps, getInputProps }) => (
                      <section>
                        <div {...getRootProps({ className: "dropzonenew" })}>
                          <input {...getInputProps()} />
                          <p>Arrastre el archivo aquí</p>
                        </div>
                      </section>
                    )}
                  </Dropzone>
                </Grid>
                {reversoDocIdFirmante && (
                  <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                    {reversoDocIdFirmante}
                  </Grid>
                )}
                <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                  {validationMessages &&
                    validationMessages.length > 0 &&
                    validationMessages.map((m) => (
                      <Typography
                        variant="caption"
                        display="block"
                        gutterBottom
                        sx={{ color: red[500] }}
                      >
                        {m}
                      </Typography>
                    ))}
                </Grid>
              </Grid>
            </AccordionDetails>
          </Accordion>
        </Grid>
      </Grid>
    </div>
  );
};

export default FirmantesForm;
