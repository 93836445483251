import { Formik, Form, Field } from "formik";
import { useDispatch, useSelector } from "react-redux";
import {
  Backdrop,
  Box,
  Button,
  CircularProgress,
  Grid,
  IconButton,
  Modal,
} from "@mui/material";
import { useEffect, useState } from "react";
import Swal from "sweetalert2";
import { Close } from "@mui/icons-material";
import { addGrafica } from "../../action";

const isMobile =
    !!navigator.userAgent.match(/iphone|android|blackberry/gi) || false;

const style = {
  position: "absolute",
  top: "0px",
  left: "50%",
  transform: "translate(-50%, 0%)",
  width: 500,
  bgcolor: "background.paper",
  boxShadow: 24,
  p: 4,
  //maxHeight: '90%'
};

const styleMobile = {
  position: "absolute",
  top: "0px",
  left: "0px",
  //transform: "translate(-50%, 0%)",
  width: "100vw",
  bgcolor: "background.paper",
  boxShadow: 24,
  p: 4,
  //maxHeight: '90%'
};

const FormAgregarGrafica = ({
  isOpen,
  handleClose,
  reloadMethod,
}) => {
  const dispatch = useDispatch();
  const [isLoading, setIsLoading] = useState(false);
  const requestUsuarioFiltrados = useSelector((state) => state.app.requestUsuarioFiltrados);
  const brokers = useSelector((state) => state.app.usuariosFiltrados);
  const requestPostGrafica = useSelector((state) => state.app.requestPostGrafica);

  const handleSubmit = (formValues) => {
    dispatch(addGrafica(formValues));
  };

  if (requestPostGrafica === "SUCCESS") {
    handleClose();
    Swal.fire({
      title: "Grafica anadida",
      text: "Se ha agregado la grafica exitosamente",
      icon: "success",
    }).then((result) => {
      if (result.value || result.dismiss === "backdrop") {
        //reloadMethod();
      }
    });
  }


  useEffect(() => {
    if (
      requestUsuarioFiltrados === "LOADING" || requestPostGrafica === "LOADING"
    ) {
      setIsLoading(true);
    } else {
      setIsLoading(false);
    }
  }, [requestUsuarioFiltrados, requestPostGrafica]);

  const [openBackdrop, setOpenBackdrop] = useState(false);

  const handleCloseBackdrop = () => {
    setOpenBackdrop(false);
  };

  return (
    <Modal
      disableScrollLock={false}
      open={isOpen}
      onClose={handleClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"   
      sx={{
        overflowY: "auto",
        top: isMobile ? "0px" : "10px",
        maxHeight: isMobile ? "100%" : "95%",
      }}
    >
      <Box sx={isMobile ? styleMobile : style}>
        {isLoading && (
          <Backdrop
            sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
            open={true}
            onClick={handleCloseBackdrop}
          >
            <CircularProgress color="inherit" />
          </Backdrop>
        )}
        <Grid container justifyContent="space-between" alignItems={"center"} sx={{marginBottom: 2}}>
          <Grid item xs={10} sm={10} md={10} lg={10} xl={10}>
            <h4>Agregar grafica</h4>
          </Grid>
          <Grid item>
            <IconButton
              color="primary"
              aria-label="copiar"
              component="span"
              onClick={() => handleClose()}
            >
              <Close sx={{ fontSize: "20pt" }} />
            </IconButton>
          </Grid>
        </Grid>        
        <Formik
          initialValues={{
            idBroker: "",
            link: "",
            nombre: ""
          }}
          onSubmit={handleSubmit}
        >
          {({ values, handleSubmit }) => (
            <Form onSubmit={handleSubmit}>              
              {requestUsuarioFiltrados === "LOADING" && <CircularProgress />}
              {requestUsuarioFiltrados === "IDLE" && brokers && (
                <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                  <label className="milabel">Broker</label>
                  <Field
                    name="idBroker"
                    as="select"
                    className="miSelect"
                  >
                    <option value={""}>- Seleccione -</option>
                    {brokers.map((c) => (
                      <option value={c.id}>
                        {c.nombres} {c.apellidos}
                      </option>
                    ))}
                  </Field>
                </Grid>
              )}
              <Grid container>
                <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                  <label className="milabel">Nombre grafica</label>
                  <Field name="nombre" type="text" className="miSelect" />
                </Grid>
              </Grid>
              <Grid container>
                <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                  <label className="milabel">Link</label>
                  <Field name="link" type="text" className="miSelect" />
                </Grid>
              </Grid>
              <Grid container justifyContent="flex-end" sx={{ mt: 2 }}>
                <Grid item>
                  <Button onClick={handleClose} className="main-btn">
                    Cancelar
                  </Button>
                </Grid>
                <Grid item>
                  <Button
                    type="submit"
                    className="main-btn"
                    variant="contained"
                    color="success"
                  >
                    Guardar
                  </Button>
                </Grid>
              </Grid>
            </Form>
          )}
        </Formik>
      </Box>
    </Modal>
  );
};

export default FormAgregarGrafica;
