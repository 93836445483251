import {
  Sidebar,
  Menu,
  MenuItem,
  SubMenu,
  useProSidebar,
} from "react-pro-sidebar";
import { SideBarData } from "./SideBarData";
import logoPropins from "../../img/LogoPropins.png";
import { useHistory } from "react-router-dom";
import {
  AppBar,
  Box,
  Button,
  IconButton,
  Toolbar,
  Typography,
} from "@mui/material";
import MenuIcon from "@mui/icons-material/Menu";
import { useSelector } from "react-redux";

const SideMenu = () => {
  const history = useHistory();
  const isMobile =
    !!navigator.userAgent.match(/iphone|android|blackberry/gi) || false;
  const { toggleSidebar } = useProSidebar();

  const userData = useSelector((state) => state.app.itemUsuario);
  const tipoCuenta = userData && userData.tipoCuenta;

  const routeChange = (newPath) => {
    let path = newPath;
    history.push(path);
    if (isMobile) {
      toggleSidebar();
    }
  };

  if (!isMobile) {
    return (
      <div className="sidebarHeight">
        <Sidebar breakPoint="lg" className="sidebarHeight">
          <div className="logo">
            <img src={logoPropins} alt="Logo propins" className="logo"></img>
          </div>
          <Menu className="sideBarMenu">
            {SideBarData.map((val, key) => {
              if (val.accountType.includes(tipoCuenta)) {
                if (!val.submenu) {
                  return (
                    <MenuItem
                      style={{
                        fontSize: "20px",
                        marginBottom: "3px",
                      }}
                      id={
                        window.location.pathname.includes(val.link)
                          ? "active"
                          : "notActive"
                      }
                      key={key}
                      onClick={() => {
                        routeChange(val.link);
                      }}
                    >
                      <div style={{ marginLeft: "20px" }}>{val.title}</div>
                    </MenuItem>
                  );
                } else {
                  return (
                    <SubMenu
                      label={val.title}
                      style={{
                        fontSize: "20px",
                        marginBottom: "3px",
                        marginLeft: "20px",
                      }}
                    >
                      {val.submenu.map((subItem) => {
                        if (subItem.accountType.includes(tipoCuenta)) {
                          return (
                            <MenuItem
                              style={{
                                fontSize: "17px",
                                marginBottom: "3px",
                              }}
                              className="sideBarElement"
                              id={
                                window.location.pathname.includes(subItem.link)
                                  ? "active"
                                  : "notActive"
                              }
                              key={key}
                              onClick={() => {
                                routeChange(subItem.link);
                              }}
                            >
                              <div style={{ marginLeft: "20px" }}>
                                {subItem.title}
                              </div>
                            </MenuItem>
                          );
                        }                      
                      })}
                    </SubMenu>
                  );
                }
              }
              
            })}
          </Menu>
        </Sidebar>
      </div>
    );
  } else {
    return (
      <Box
        sx={{
          flexGrow: 1,
          width: "100%",
          minWidth: "100vw",
          backgroundColor: "#00a62b",
        }}
      >
        <AppBar position="static" sx={{ backgroundColor: "#00a62b" }}>
          <Toolbar>
            <IconButton
              size="large"
              edge="start"
              color="inherit"
              aria-label="menu"
              sx={{ mr: 2 }}
              onClick={() => toggleSidebar()}
            >
              <MenuIcon />
            </IconButton>
            <Typography variant="h6" component="div" sx={{ flexGrow: 1, textAlign: "left" }}>
              Propins
            </Typography>
          </Toolbar>
        </AppBar>
        <Sidebar breakPoint="lg" className="sidebarHeight" backgroundColor="#FBFBFB">
          <div className="logo">
            <img src={logoPropins} alt="Logo propins" className="logo"></img>
          </div>
          <Menu className="sideBarMenu">
            {SideBarData.map((val, key) => {
              if (!val.submenu) {
                return (
                  <MenuItem
                    style={{
                      fontSize: "20px",
                      marginBottom: "3px",
                    }}
                    id={
                      window.location.pathname.includes(val.link)
                        ? "active"
                        : "notActive"
                    }
                    key={key}
                    onClick={() => {
                      routeChange(val.link);
                    }}
                  >
                    <div style={{ marginLeft: "20px" }}>{val.title}</div>
                  </MenuItem>
                );
              } else {
                return (
                  <SubMenu
                    label={val.title}
                    style={{
                      fontSize: "20px",
                      marginBottom: "3px",
                      marginLeft: "20px",
                    }}
                  >
                    {val.submenu.map((subItem) => {
                      return (
                        <MenuItem
                          style={{
                            fontSize: "17px",
                            marginBottom: "3px",
                          }}
                          className="sideBarElement"
                          id={
                            window.location.pathname.includes(subItem.link)
                              ? "active"
                              : "notActive"
                          }
                          key={key}
                          onClick={() => {
                            routeChange(subItem.link);
                          }}
                        >
                          <div style={{ marginLeft: "20px" }}>
                            {subItem.title}
                          </div>
                        </MenuItem>
                      );
                    })}
                  </SubMenu>
                );
              }
            })}
          </Menu>
        </Sidebar>
      </Box>
    );
  }
};

export default SideMenu;
