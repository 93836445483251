import { Formik, Form, Field, FieldArray } from "formik";
import { useDispatch, useSelector } from "react-redux";
import {
  Backdrop,
  Button,
  Checkbox,
  CircularProgress,
  Divider,
  FormControlLabel,
  FormGroup,
  Grid,
  IconButton,
  ImageList,
  ImageListItem,
  ImageListItemBar,
  Typography,
} from "@mui/material";
import { ESTADO_PROYECTO } from "../../utils/constants";
import React, { useEffect, useState } from "react";
import {
  fetchGetAllInmobiliarias,
  getProyecto,
  postProyecto,
  putProyecto,
} from "../../action";
import Swal from "sweetalert2";
import { AddCircle, Delete, Star } from "@mui/icons-material";
import * as Yup from "yup";
import { useParams } from "react-router-dom";
import { red, yellow } from "@mui/material/colors";
import Dropzone from "react-dropzone";
import { useHistory } from "react-router-dom";
import FormInmobiliaria from "../Inmobiliarias/FormInmobiliaria";
import Back from "../../assets/Back";
import FieldError from "../../utils/FieldError";
import { GoogleMap, useJsApiLoader } from "@react-google-maps/api";
import { MarkerF } from "@react-google-maps/api";
import utilsFunctions from "../../utils/utilsFunctions";

const validateProject = Yup.object().shape({
  inmobiliariaId: Yup.string().required("Seleccione una inmobiliaria"),
  nombre: Yup.string().required("Ingrese un nombre"),
  superficieTotalesDesde: Yup.number()
    .required("Ingrese superficie total desde")
    .typeError("Solo se permiten números"),
  superficieUtilDesde: Yup.number()
    .required("Ingrese superficie util desde")
    .typeError("Solo se permiten números"),
  valorDesde: Yup.number()
    .required("Ingrese valor desde")
    .typeError("Solo se permiten números"),
  estado: Yup.string().required("Seleccione un estado"),
  idRegion: Yup.string().required("Seleccione una región"),
  comuna: Yup.string().required("Seleccione una comuna"),
  nombreCalle: Yup.string().required("Ingrese una calle"),
  numero: Yup.string().required("Ingrese un número"),
  calefaccion: Yup.string().required("Seleccione una calefacción"),
  tipoPiso: Yup.string().required("Seleccione un tipo de piso"),
  tipoProyecto: Yup.string().required("Seleccione un tipo de proyecto"),
  tipoVia: Yup.string().required("Seleccione un tipo de vía"),
});

const validateModel = Yup.object().shape({
  nombre: Yup.string().required("Ingrese un nombre"),
  superficieDesde: Yup.number()
    .required("Ingrese superficie desde")
    .typeError("Solo se permiten números"),
  valorDesde: Yup.number()
    .required("Ingrese superficie total desde")
    .typeError("Solo se permiten números"),
  banio: Yup.number()
    .required("Ingrese baños")
    .typeError("Solo se permiten números"),
  dormitorio: Yup.number()
    .required("Ingrese dormitorios")
    .typeError("Solo se permiten números"),
});

export const ALLOWED_MIME_TYPES = [
  "image/png",
  "image/x-citrix-png",
  "image/x-png",
  "image/jpeg",
  "image/x-citrix-jpeg",
  "image/pjpeg",
];

const isMobile =
  !!navigator.userAgent.match(/iphone|android|blackberry/gi) || false;

const emptyModel = {
  nombre: "",
};

const mapContainerStyle = {
  width: "100%",
  height: "300px",
};

const FormProyecto = ({}) => {
  const [initialValues, setInitialValues] = useState(null);
  const [lat, setLat] = useState(-33.43616371280176);
  const [lng, setLng] = useState(-70.63379790744193);
  const { isLoaded } = useJsApiLoader({
    id: "google-map-script",
    googleMapsApiKey: "AIzaSyCZh4ceCuAMermnESxIFECllWWURCP6Ucs",
  });

  const [map, setMap] = React.useState(null);
  const center = {
    lat,
    lng,
  };

  const onLoad = React.useCallback(function callback(map) {
    // This is just an example of getting and using the map instance!!! don't just blindly copy!
    const bounds = new window.google.maps.LatLngBounds(center);
    if (map.current) {
      map.current.fitBounds(bounds);
    }

    setMap(map);
  }, []);

  const onUnmount = React.useCallback(function callback(map) {
    setMap(null);
  }, []);

  const onClickMap = (e) => {
    const { latLng } = e;
    const latLocal = latLng.lat();
    const lngLocal = latLng.lng();
    setLat(latLocal);
    setLng(lngLocal);
  };

  const dispatch = useDispatch();
  const history = useHistory();
  const [isLoading, setIsLoading] = useState(false);
  const proyecto = useSelector((state) => state.app.proyecto);
  const requestGetProyecto = useSelector(
    (state) => state.app.requestGetProyecto
  );
  const requestPutProyecto = useSelector(
    (state) => state.app.requestPutProyecto
  );
  const requestPostProyecto = useSelector(
    (state) => state.app.requestPostProyecto
  );
  const errorMessage = useSelector((state) => state.app.errorMessage);
  const [imagenes, setImagenes] = useState([]);
  const [modelos, setModelos] = useState(
    initialValues && initialValues.modelos ? initialValues.modelos : []
  );
  const [imageValidationMessage, setImageValidationMessage] = useState([]);
  const [direccion, setDireccion] = useState("");
  const [comuna, setComuna] = useState("");
  const { id } = useParams();
  const inmobiliarias = useSelector((state) => state.app.itemInmobiliarias);
  const requestGetInmobiliarias = useSelector(
    (state) => state.app.requestGetAllInmobiliarias
  );
  const regiones = useSelector(
    (state) =>
      state.app.itemRegiones &&
      state.app.itemRegiones.length > 0 &&
      state.app.itemRegiones[0].regiones
  );

  const [openInmobiliariaForm, setOpenInmobiliariaForm] = useState(false);
  const handleCloseInmobiliariaForm = () => {
    setOpenInmobiliariaForm(false);
  };

  const [isModelFormValid, setModelFormValid] = useState(false);

  const [open, setOpen] = useState(false);
  const handleClose = () => {
    setOpen(false);
  };

  useEffect(() => {
    dispatch(fetchGetAllInmobiliarias());
  }, []);

  useEffect(() => {
    id && dispatch(getProyecto(id));
    if (id === null) {
      setInitialValues(null);
    }
  }, [id]);

  useEffect(() => {
    if (
      requestGetProyecto === "LOADING" ||
      requestPutProyecto === "LOADING" ||
      requestPostProyecto === "LOADING"
    ) {
      setIsLoading(true);
    } else {
      setIsLoading(false);
    }
  }, [requestGetProyecto, requestPutProyecto, requestPostProyecto]);

  useEffect(() => {
    if (id !== null && proyecto !== null) {
      setInitialValues(proyecto);
      setDireccion(proyecto.direccionSalaVenta);
      setComuna(proyecto.comuna);
      setLat(proyecto.loc.x);
      setLng(proyecto.loc.y);
      setModelFormValid(true);
      setImagenes(proyecto.imagenes);
      setConectividad(proyecto.conectividad);
      setRentabilidad(proyecto.rentabilidad);
      setTerminaciones(proyecto.terminaciones);
      setEquipamiento(proyecto.equipamiento);
      setHabilitarLive(proyecto.habilitarLive);
      setAlarma(proyecto && proyecto.proyCar && proyecto.proyCar.alarma);
      setCocinaAmo(proyecto && proyecto.proyCar && proyecto.proyCar.cocinaAmo);
      setAccesoControlado(
        proyecto && proyecto.carCom && proyecto.carCom.accesoControlado
      );
      setAreasVerdes(
        proyecto && proyecto.carCom && proyecto.carCom.areasVerdes
      );
      setBicicletros(
        proyecto && proyecto.carCom && proyecto.carCom.bicicletros
      );
      setCamaraSeguridad(
        proyecto && proyecto.carCom && proyecto.carCom.camaraSeguridad
      );
      setEstVisita(proyecto && proyecto.carCom && proyecto.carCom.estVisita);
      setJuegosInf(proyecto && proyecto.carCom && proyecto.carCom.juegosInf);
      setPiscina(proyecto && proyecto.carCom && proyecto.carCom.piscina);
      setPortonElec(proyecto && proyecto.carCom && proyecto.carCom.portonElec);
      setQuincho(proyecto && proyecto.carCom && proyecto.carCom.quincho);
      setSalaDeCine(proyecto && proyecto.carCom && proyecto.carCom.salaDeCine);
      setSalaDeEventos(
        proyecto && proyecto.carCom && proyecto.carCom.salaDeEventos
      );
      setSalonDeJuegos(
        proyecto && proyecto.carCom && proyecto.carCom.salonDeJuegos
      );
      setSauna(proyecto && proyecto.carCom && proyecto.carCom.sauna);
      setBodegaEcommerce(
        proyecto && proyecto.carCom && proyecto.carCom.bodegaEcommerce
      );
      setTermopanel(
        proyecto && proyecto.proyCar && proyecto.proyCar.termopanel
      );
    }
  }, [proyecto]);

  const [rentabilidad, setRentabilidad] = useState(false);
  const onChangeRentabilidad = (e) => setRentabilidad(e.target.checked);
  const [conectividad, setConectividad] = useState(false);
  const onChangeConectividad = (e) => setConectividad(e.target.checked);
  const [terminaciones, setTerminaciones] = useState(false);
  const onChangeTerminaciones = (e) => setTerminaciones(e.target.checked);
  const [equipamiento, setEquipamiento] = useState(false);
  const onChangeEquipamiento = (e) => setEquipamiento(e.target.checked);
  const [habilitarLive, setHabilitarLive] = useState(false);
  const onChangeHabilitarLive = (e) => setHabilitarLive(e.target.checked);

  const [alarma, setAlarma] = useState(false);
  const onChangeAlarma = (e) => setAlarma(e.target.checked);
  const [cocinaAmo, setCocinaAmo] = useState(false);
  const onChangeCocinaAmo = (e) => setCocinaAmo(e.target.checked);
  const [accesoControlado, setAccesoControlado] = useState(false);
  const onChangeAccesoControlado = (e) => setAccesoControlado(e.target.checked);
  const [areasVerdes, setAreasVerdes] = useState(false);
  const onChangeAreasVerdes = (e) => setAreasVerdes(e.target.checked);
  const [bicicletros, setBicicletros] = useState(false);
  const onChangeBicicletros = (e) => setBicicletros(e.target.checked);
  const [camaraSeguridad, setCamaraSeguridad] = useState(false);
  const onChangeCamaraSeguridad = (e) => setCamaraSeguridad(e.target.checked);
  const [estVisita, setEstVisita] = useState(false);
  const onChangeEstVisita = (e) => setEstVisita(e.target.checked);
  const [juegosInf, setJuegosInf] = useState(false);
  const onChangeJuegosInf = (e) => setJuegosInf(e.target.checked);
  const [piscina, setPiscina] = useState(false);
  const onChangePiscina = (e) => setPiscina(e.target.checked);
  const [portonElec, setPortonElec] = useState(false);
  const onChangePortonElec = (e) => setPortonElec(e.target.checked);
  const [quincho, setQuincho] = useState(false);
  const onChangeQuincho = (e) => setQuincho(e.target.checked);
  const [salaDeCine, setSalaDeCine] = useState(false);
  const onChangeSalaDeCine = (e) => setSalaDeCine(e.target.checked);
  const [salaDeEventos, setSalaDeEventos] = useState(false);
  const onChangeSalaDeEventos = (e) => setSalaDeEventos(e.target.checked);
  const [salonDeJuegos, setSalonDeJuegos] = useState(false);
  const onChangeSalonDeJuegos = (e) => setSalonDeJuegos(e.target.checked);
  const [sauna, setSauna] = useState(false);
  const onChangeSauna = (e) => setSauna(e.target.checked);
  const [bodegaEcommerce, setBodegaEcommerce] = useState(false);
  const onChangeBodegaEcommerce = (e) => setBodegaEcommerce(e.target.checked);
  const [termopanel, setTermopanel] = useState(false);
  const onChangeTermopanel = (e) => setTermopanel(e.target.checked);

  const reloadInmobiliarias = () => {
    dispatch(fetchGetAllInmobiliarias());
  };

  const handleSubmit = (formValues) => {
    if (isModelFormValid) {
      if (!formValues.idRegion) {
        formValues.idRegion = 15;
      }
      const data = {
        ...formValues,
        carCom: {
          accesoControlado,
          bodegaEcommerce,
          estVisita,
          portonElec,
          salonDeJuegos,
          areasVerdes,
          quincho,
          sauna,
          camaraSeguridad,
          bicicletros,
          salaDeCine,
          juegosInf,
          piscina,
          salaDeEventos,
        },
        proyCar: {
          tipoPiso: formValues.tipoPiso,
          calefaccion: formValues.calefaccion,
          alarma,
          termopanel,
          cocinaAmo,
        },
        loc: {
          x: lat,
          y: lng,
        },
        nombreInmobiliaria: getNombreInmobiliaria(formValues.inmobiliariaId),
        imagenes,
        modelos,
      };
      if (initialValues && initialValues.id) {
        const updateData = {
          ...data,
          id: initialValues.id,
        };
        dispatch(putProyecto(updateData));
      } else {
        dispatch(postProyecto(data));
      }
    }
  };

  const getNombreInmobiliaria = (id) => {
    const inmo =
      inmobiliarias.some((u) => u.id === id) &&
      inmobiliarias.find((u) => u.id === id);
    if (inmo) {
      return inmo.nombre;
    }
    return "";
  };

  useEffect(() => {
    setModelos([
      {
        nombre: "",
        superficieDesde: "",
        valorDesde: "",
        banio: "",
        dormitorio: "",
        urlMattePort: "",
      },
    ]);
    if (initialValues !== null) {
      if (initialValues.modelos !== null && initialValues.modelos.length > 0) {
        setModelos(initialValues.modelos);
      }
    }
  }, [initialValues]);

  const agregarModelo = () => {
    const modelo = {
      nombre: "",
      superficieDesde: "",
      valorDesde: "",
      banio: "",
      dormitorio: "",
      urlMattePort: "",
    };
    setModelos((oldValue) => [...oldValue, modelo]);
  };

  const eliminarModelo = (modelo) => {
    setModelos(modelos.filter((f) => f.nombre !== modelo.nombre));
  };

  const deleteImage = (file, isModelo, index) => {
    if (!isModelo) {
      setImagenes(imagenes.filter((f) => f.name !== file.name));
    } else {
      let model = modelos[index];
      model.imagenes = model.imagenes.filter((f) => f.name !== file.name);
      setModelos(
        modelos.map((a) => {
          return a.nombre === model.nombre ? model : a;
        })
      );
    }
  };

  const setPortada = (file, isModelo, index) => {
    if (!isModelo) {
      let image = imagenes.find((f) => f.name === file.name);
      if (image.esPortada) {
        image.esPortada = false;
      } else {
        image.esPortada = true;
      }
      setImagenes(
        imagenes.map((a) => {
          return a.name === file.name ? image : a;
        })
      );
    } else {
      let model = modelos[index];
      let image = model.imagenes.find((f) => f.name === file.name);
      if (image.esPortada) {
        image.esPortada = false;
      } else {
        image.esPortada = true;
      }
      setModelos(
        modelos.map((a) => {
          return a.nombre === model.nombre ? model : a;
        })
      );
    }
  };

  const fileToBase64 = (file) => {
    if (file) {
      return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => resolve(reader.result);
        reader.onerror = (error) => reject(error);
      });
    }
  };

  const onDrop = (files, isModelo, index) => {
    if (files) {
      files.map((f) => {
        if (f !== null) {
          fileToBase64(f).then((file64) => {
            const newFile = {
              name: f.name,
              value: file64,
              deleted: false,
            };
            if (!isModelo) {
              setImagenes((oldValue) => [...oldValue, newFile]);
            } else {
              let model = modelos[index];
              if (model.imagenes && Array.isArray(model.imagenes)) {
                model.imagenes.push(newFile);
              } else {
                model.imagenes = [newFile];
              }
              setModelos(
                modelos.map((a) => {
                  return a.nombre === model.nombre ? model : a;
                })
              );
            }
          });
        }
      });
    }
  };

  const thumbs =
    imagenes &&
    imagenes.length > 0 &&
    imagenes.map((file) => {
      if (!file.name.includes(".mp4")) {
        return (
          <ImageListItem
            key={file.name}
            className={file.esPortada ? "destacar" : ""}
          >
            <img
              src={file.value ? file.value : file.downloadLink}
              srcSet={file.value ? file.value : file.downloadLink}
              alt={file.name}
              loading="lazy"
              width="240px"
              height="170px"
            />
            <ImageListItemBar
              sx={{
                background: "none",
              }}
              position="bottom"
              actionIcon={
                <IconButton
                  sx={{ color: "rgba(245, 66, 66, 1)" }}
                  aria-label={`info about ${file.name}`}
                  onClick={() => deleteImage(file, false)}
                >
                  <Delete />
                </IconButton>
              }
            />
            <ImageListItemBar
              title={file.name}
              position="top"
              actionIcon={
                <IconButton
                  sx={{ color: "rgba(255, 255, 255, 0.54)" }}
                  aria-label={`info about ${file.name}`}
                  onClick={() => setPortada(file, false, null)}
                >
                  {file.esPortada ? (
                    <Star sx={{ color: yellow[500] }} />
                  ) : (
                    <Star />
                  )}
                </IconButton>
              }
            />
          </ImageListItem>
        );
      } else {
        return (
          <ImageListItem
            key={file.name}
            className={file.esPortada ? "destacar" : ""}
          >
            <video
              width="240px"
              height="170px"
              autoPlay
              loop
              muted
              //poster="https://assets.codepen.io/6093409/river.jpg"
            >
              <source
                src={file.value ? file.value : file.downloadLink}
                type="video/mp4"
              />
            </video>
            <ImageListItemBar
              sx={{
                background: "none",
              }}
              position="bottom"
              actionIcon={
                <IconButton
                  sx={{ color: "rgba(245, 66, 66, 1)" }}
                  aria-label={`info about ${file.name}`}
                  onClick={() => deleteImage(file, false)}
                >
                  <Delete />
                </IconButton>
              }
            />
            <ImageListItemBar
              title={file.name}
              position="top"
              actionIcon={
                <IconButton
                  sx={{ color: "rgba(255, 255, 255, 0.54)" }}
                  aria-label={`info about ${file.name}`}
                  onClick={() => (file, false, null)}
                >
                  {file.esPortada ? (
                    <Star sx={{ color: yellow[500] }} />
                  ) : (
                    <Star />
                  )}
                </IconButton>
              }
            />
          </ImageListItem>
        );
      }
    });

  const renderImageValidationMessage = () => {
    return imageValidationMessage.map((m) => (
      <Typography
        variant="caption"
        display="block"
        gutterBottom
        sx={{ color: red[500] }}
      >
        {m}
      </Typography>
    ));
  };

  if (requestPostProyecto === "SUCCESS") {
    Swal.fire({
      title: "Proyecto agregado",
      text: "Se ha agregado el proyecto inmobiliario exitosamente",
      icon: "success",
    }).then((result) => {
      if (result.value || result.dismiss === "backdrop") {
        history.push("/proyectos");
      }
    });
  }

  if (requestPutProyecto === "SUCCESS") {
    Swal.fire({
      title: "Proyecto actualizado",
      text: "Se ha actualizado el proyecto exitosamente",
      icon: "success",
    }).then((result) => {
      if (result.value || result.dismiss === "backdrop") {
        history.push("/proyectos");
      }
    });
  }

  if (requestPostProyecto === "ERROR" || requestPutProyecto === "ERROR") {
    Swal.fire({
      title: "Error",
      text: errorMessage,
      icon: "error",
    });
  }
  const setValuesModelos = (e, index, setFieldValueMethod, isFormValid) => {
    let model = modelos[index];
    let fieldName = e.target.name;
    let fieldValue = e.target.value;
    model[fieldName] = fieldValue;
    setFieldValueMethod(fieldName, fieldValue);
    if (fieldName !== "urlMattePort") {
      setModelFormValid(isFormValid);
    }
    setModelos(
      modelos.map((a, i) => {
        return i === index ? model : a;
      })
    );
  };

  const getCoords = async (address) => {
    const res = await utilsFunctions.GetLatitudeAndLongitudeByAddress(address);
    if (res.status === "ok") {
      setLat(res.lat);
      setLng(res.lng);
    }
  };

  useEffect(() => {
    if (direccion && comuna) {
      getCoords(`${direccion}, ${comuna}`);
    }
  }, [direccion, comuna]);

  const handleBlur = (e) => {
    if (e.target.name === "direccionSalaVenta") {
      if (e.target.value !== "" && e.target.value !== null) {
        setDireccion(e.target.value);
      } else {
        setDireccion(null);
      }
    }
    if (e.target.name === "comuna") {
      if (e.target.value !== "" && e.target.value !== null) {
        setComuna(e.target.value);
      } else {
        setComuna(null);
      }
    }
  };

  return (
    <div className="contenido">
      <Back />
      <div className="header">
        <h2>{initialValues ? "Editar " : "Agregar "}proyecto</h2>
      </div>
      {isLoading && (
        <Backdrop
          sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
          open={true}
          onClick={handleClose}
        >
          <CircularProgress color="inherit" />
        </Backdrop>
      )}
      {requestGetProyecto === "IDLE" && (
        <Formik
          initialValues={{
            inmobiliariaId: initialValues && initialValues.inmobiliariaId,
            nombre: initialValues && initialValues.nombre,
            superficieTotalesDesde:
              initialValues && initialValues.superficieTotalesDesde,
            superficieUtilDesde:
              initialValues && initialValues.superficieUtilDesde,
            valorDesde: initialValues && initialValues.valorDesde,
            tipoPrecio: initialValues && initialValues.tipoPrecio,
            estado: initialValues && initialValues.estado,
            entrega: initialValues && initialValues.entrega,
            operacion: initialValues && initialValues.operacion,
            rentabilidad,
            conectividad,
            terminaciones,
            equipamiento,
            habilitarLive,
            meetingNumber: initialValues && initialValues.meetingNumber,
            meetingPassword: initialValues && initialValues.meetingPassword,
            apiKey: initialValues && initialValues.apiKey,
            apiSecret: initialValues && initialValues.apiSecret,
            urlProyecto: initialValues && initialValues.urlProyecto,
            comuna: initialValues && initialValues.comuna,
            observacionesPublicas:
              initialValues && initialValues.observacionesPublicas,
            direccionSalaVenta:
              initialValues && initialValues.direccionSalaVenta,
            telefonoSalaVenta: initialValues && initialValues.telefonoSalaVenta,
            emailSalaVenta: initialValues && initialValues.emailSalaVenta,
            tipoProyecto: initialValues && initialValues.tipoProyecto,
            tipoVia: initialValues && initialValues.tipoVia,
            idRegion: initialValues && initialValues.idRegion,
            numero: initialValues && initialValues.numero,
            nombreCalle: initialValues && initialValues.nombreCalle,
            urlMattePort: initialValues && initialValues.urlMattePort,
            //carcom
            accesoControlado,
            areasVerdes,
            bicicletros,
            bodegaEcommerce,
            camaraSeguridad,
            estVisita,
            juegosInf,
            piscina,
            portonElec,
            quincho,
            salaDeCine,
            salaDeEventos,
            salonDeJuegos,
            sauna,
            //proycar
            alarma,
            termopanel,
            cocinaAmo,
            calefaccion:
              initialValues &&
              initialValues.proyCar &&
              initialValues.proyCar.calefaccion,
            tipoPiso:
              initialValues &&
              initialValues.proyCar &&
              initialValues.proyCar.tipoPiso,
            modelos:
              initialValues && initialValues.modelos
                ? initialValues.modelos
                : [emptyModel],
            //no estan en el formulario
            delete:
              initialValues && initialValues.delete
                ? initialValues.delete
                : false,
            delete:
              initialValues && initialValues.destacar
                ? initialValues.destacar
                : false,
            tarjetaProyectoHabilitada:
              initialValues && initialValues.tarjetaProyectoHabilitada
                ? initialValues.destacar
                : false,
            nombreInmobiliaria:
              initialValues && initialValues.nombreInmobiliaria,
            evaluacionConectividad:
              initialValues && initialValues.evaluacionConectividad,
            evaluacionEquipamiento:
              initialValues && initialValues.evaluacionEquipamiento,
            evaluacionRentabilidad:
              initialValues && initialValues.evaluacionRentabilidad,
            evaluacionTerminaciones:
              initialValues && initialValues.evaluacionTerminaciones,
            htmlbuttonLink: initialValues && initialValues.htmlbuttonLink,
            codigoProyecto: initialValues && initialValues.codigoProyecto,
            loc: initialValues && initialValues.loc,
            location: initialValues && initialValues.location,
            imagenes,
            evaluacionConectividad:
              initialValues && initialValues.evaluacionConectividad
                ? initialValues.evaluacionConectividad
                : 0,
            evaluacionEquipamiento:
              initialValues && initialValues.evaluacionEquipamiento
                ? initialValues.evaluacionEquipamiento
                : 0,
            evaluacionRentabilidad:
              initialValues && initialValues.evaluacionRentabilidad
                ? initialValues.evaluacionRentabilidad
                : 0,
            evaluacionTerminaciones:
              initialValues && initialValues.evaluacionTerminaciones
                ? initialValues.evaluacionTerminaciones
                : 0,
            //logo: archivos,
          }}
          onSubmit={handleSubmit}
          validationSchema={validateProject}
        >
          {({ values, errors, handleSubmit }) => (
            <Form onSubmit={handleSubmit}>
              <Grid container spacing={2} justifyContent={"flex-start"}>
                <Grid item xs={6} sm={6} md={6} lg={4} xl={4}>
                  <label className="milabel">Inmobiliaria</label>
                  {requestGetInmobiliarias === "LOADING" && <CircularProgress />}
                  {requestGetInmobiliarias !== "LOADING" && inmobiliarias && (
                    <Grid container justifyContent={"space-between"}>
                      <Grid item xs={10} sm={10} md={10} lg={10} xl={10}>
                        <Field
                          name="inmobiliariaId"
                          as="select"
                          className="miSelect"
                        >
                          <option value={""}>Seleccione</option>
                          {inmobiliarias.map((c) => (
                            <option value={c.id}>{c.nombre}</option>
                          ))}
                        </Field>
                        <FieldError name="inmobiliariaId" />
                      </Grid>
                      <Grid item>
                        <IconButton
                          color="primary"
                          aria-label="add to shopping cart"
                          onClick={() => setOpenInmobiliariaForm(true)}
                        >
                          <AddCircle />
                        </IconButton>
                      </Grid>
                    </Grid>
                  )}
                </Grid>
                <Grid item xs={6} sm={6} md={6} lg={4} xl={4}>
                  <label className="milabel">Nombre</label>
                  <Field name="nombre" type="text" className="miSelect" />
                  <FieldError name="nombre" />
                </Grid>

                <Grid item xs={6} sm={6} md={6} lg={4} xl={4}>
                  <label className="milabel">Superficie total desde</label>
                  <Field
                    name="superficieTotalesDesde"
                    type="text"
                    className="miSelect"
                  />
                  <FieldError name="superficieTotalesDesde" />
                </Grid>

                <Grid item xs={6} sm={6} md={6} lg={4} xl={4}>
                  <label className="milabel">Superficie útil desde</label>
                  <Field
                    name="superficieUtilDesde"
                    type="text"
                    className="miSelect"
                  />
                  <FieldError name="superficieUtilDesde" />
                </Grid>

                <Grid item xs={6} sm={6} md={6} lg={4} xl={4}>
                  <label className="milabel">Valor desde</label>
                  <Field name="valorDesde" type="text" className="miSelect" />
                  <FieldError name="valorDesde" />
                </Grid>

                <Grid item xs={6} sm={6} md={6} lg={4} xl={4}>
                  <label className="milabel">Tipo precio</label>
                  <Field name="tipoPrecio" as="select" className="miSelect">
                    <option value={""}>Seleccione</option>
                    <option value={"CLP"}>CLP</option>
                    <option value={"UF"}>UF</option>
                  </Field>
                  <FieldError name="tipoPrecio" />
                </Grid>

                <Grid item xs={6} sm={6} md={6} lg={4} xl={4}>
                  <label className="milabel">Estado proyecto</label>
                  <Field name="estado" as="select" className="miSelect">
                    <option value="">- Seleccione -</option>
                    {Object.keys(ESTADO_PROYECTO).map((c) => (
                      <option value={c}>{ESTADO_PROYECTO[c]}</option>
                    ))}
                  </Field>
                  <FieldError name="estado" />
                </Grid>

                <Grid item xs={6} sm={6} md={6} lg={4} xl={4}>
                  <label className="milabel">Entrega</label>
                  <Field name="entrega" type="text" className="miSelect" />
                </Grid>

                <Grid item xs={6} sm={6} md={6} lg={4} xl={4}>
                  <label className="milabel">Operación</label>
                  <Field name="operacion" as="select" className="miSelect">
                    <option value={""}>Seleccione</option>
                    <option value={"venta"}>Venta</option>
                    <option value={"arriendo"}>Arriendo</option>
                  </Field>
                </Grid>

                <Grid item xs={6} sm={6} md={6} lg={4} xl={4}>
                  <FormGroup>
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={rentabilidad}
                          onChange={onChangeRentabilidad}
                          name="rentabilidad"
                          size="small"
                        />
                      }
                      classes={{ root: "alignLeft" }}
                      label="Rentabilidad"
                    />
                  </FormGroup>
                </Grid>
                <Grid item xs={6} sm={6} md={6} lg={4} xl={4}>
                  <FormGroup>
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={conectividad}
                          onChange={onChangeConectividad}
                          name="conectividad"
                          size="small"
                        />
                      }
                      classes={{ root: "alignLeft" }}
                      label="Conectividad"
                    />
                  </FormGroup>
                </Grid>
                <Grid item xs={6} sm={6} md={6} lg={4} xl={4}>
                  <FormGroup>
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={terminaciones}
                          onChange={onChangeTerminaciones}
                          name="terminaciones"
                          size="small"
                        />
                      }
                      classes={{ root: "alignLeft" }}
                      label="Terminaciones"
                    />
                  </FormGroup>
                </Grid>
                <Grid item xs={6} sm={6} md={6} lg={4} xl={4}>
                  <FormGroup>
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={equipamiento}
                          onChange={onChangeEquipamiento}
                          name="equipamiento"
                          size="small"
                        />
                      }
                      classes={{ root: "alignLeft" }}
                      label="Equipamiento"
                    />
                  </FormGroup>
                </Grid>
                <Grid item xs={6} sm={6} md={6} lg={4} xl={4}>
                  <FormGroup>
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={habilitarLive}
                          onChange={onChangeHabilitarLive}
                          name="habilitarLive"
                          size="small"
                        />
                      }
                      classes={{ root: "alignLeft" }}
                      label="Habilitar live"
                    />
                  </FormGroup>
                </Grid>

                <Grid item xs={6} sm={6} md={6} lg={4} xl={4}>
                  <label className="milabel">Meeting number</label>
                  <Field
                    name="meetingNumber"
                    type="text"
                    className="miSelect"
                  />
                  <FieldError name="meetingNumber" />
                </Grid>

                <Grid item xs={6} sm={6} md={6} lg={4} xl={4}>
                  <label className="milabel">Meeting password</label>
                  <Field
                    name="meetingPassword"
                    type="text"
                    className="miSelect"
                  />
                  <FieldError name="meetingPassword" />
                </Grid>

                <Grid item xs={6} sm={6} md={6} lg={4} xl={4}>
                  <label className="milabel">Api Key</label>
                  <Field name="apiKey" type="text" className="miSelect" />
                  <FieldError name="apiKey" />
                </Grid>

                <Grid item xs={6} sm={6} md={6} lg={4} xl={4}>
                  <label className="milabel">Api Secret</label>
                  <Field name="apiSecret" type="text" className="miSelect" />
                  <FieldError name="apiSecret" />
                </Grid>

                <Grid item xs={6} sm={6} md={6} lg={4} xl={4}>
                  <label className="milabel">Url Sitio web proyecto</label>
                  <Field name="urlProyecto" type="text" className="miSelect" />
                  <FieldError name="urlProyecto" />
                </Grid>

                <Grid item xs={6} sm={6} md={6} lg={4} xl={4}>
                  <label className="milabel">Tipo proyecto</label>
                  <Field name="tipoProyecto" as="select" className="miSelect">
                    <option value={""}>Seleccione</option>
                    <option value={"Casa"}>Casa</option>
                    <option value={"Departamento"}>Departamento</option>
                    <option value={"Oficina"}>Oficina</option>
                    <option value={"Mixto"}>Mixto</option>
                  </Field>
                  <FieldError name="tipoProyecto" />
                </Grid>
              </Grid>

              <Grid container spacing={2} sx={{ marginTop: 2 }}>
                <Grid item>
                  <h3>Ubicación proyecto</h3>
                </Grid>
              </Grid>

              <Grid container spacing={2}>
                <Grid item xs={6} sm={6} md={6} lg={4} xl={4}>
                  <label className="milabel">Región</label>
                  <Field name="idRegion" as="select" className="miSelect">
                    <option value="">- Seleccione -</option>
                    {regiones &&
                      regiones.map((r) => (
                        <option value={r.numero}>{r.region}</option>
                      ))}
                  </Field>
                  <FieldError name="idRegion" />
                </Grid>

                <Grid item xs={6} sm={6} md={6} lg={4} xl={4}>
                  <label className="milabel">Comuna</label>
                  <Field
                    name="comuna"
                    as="select"
                    className="miSelect"
                    onBlur={handleBlur}
                  >
                    <option value={""}>- Seleccione -</option>
                    {regiones &&
                      values &&
                      values.idRegion &&
                      regiones.some((u) => u.numero == values.idRegion) &&
                      regiones.find((u) => u.numero == values.idRegion)
                        .comunas &&
                      regiones
                        .find((u) => u.numero == values.idRegion)
                        .comunas.map((c) => <option value={c}>{c}</option>)}
                  </Field>
                  <FieldError name="comuna" />
                </Grid>

                <Grid item xs={6} sm={6} md={6} lg={4} xl={4}>
                  <label className="milabel">Tipo vía</label>
                  <Field name="tipoVia" as="select" className="miSelect">
                    <option value={""}>Seleccione</option>
                    <option value={"Calle"}>Calle</option>
                    <option value={"Pasaje"}>Pasaje</option>
                    <option value={"Avenida"}>Avenida</option>
                  </Field>
                  <FieldError name="tipoVia" />
                </Grid>

                <Grid item xs={6} sm={6} md={6} lg={4} xl={4}>
                  <label className="milabel">Nombre calle</label>
                  <Field name="nombreCalle" type="text" className="miSelect" />
                  <FieldError name="nombreCalle" />
                </Grid>

                <Grid item xs={6} sm={6} md={6} lg={4} xl={4}>
                  <label className="milabel">Número calle</label>
                  <Field name="numero" type="text" className="miSelect" />
                  <FieldError name="numero" />
                </Grid>

                <Grid item xs={12} sm={6} md={6} lg={6} xl={6}>
                  <label className="milabel">Observaciones públicas</label>
                  <Field
                    name="observacionesPublicas"
                    type="text"
                    className="miTextarea"
                    as="textarea"
                  />
                  <FieldError name="observacionesPublicas" />
                </Grid>
              </Grid>
              <Grid container spacing={2} sx={{ marginTop: 2 }}>
                <Grid item>
                  <h3>Ubicación sala venta</h3>
                </Grid>
              </Grid>
              <Grid container spacing={2}>
                <Grid item xs={6} sm={6} md={6} lg={4} xl={4}>
                  <label className="milabel">Dirección</label>
                  <Field
                    name="direccionSalaVenta"
                    type="text"
                    className="miSelect"
                    onBlur={handleBlur}
                  />
                  <FieldError name="direccionSalaVenta" />
                </Grid>

                <Grid item xs={6} sm={6} md={6} lg={4} xl={4}>
                  <label className="milabel">Teléfono</label>
                  <Field
                    name="telefonoSalaVenta"
                    type="text"
                    className="miSelect"
                  />
                  <FieldError name="telefonoSalaVenta" />
                </Grid>

                <Grid item xs={6} sm={6} md={6} lg={4} xl={4}>
                  <label className="milabel">Correo electrónico</label>
                  <Field
                    name="emailSalaVenta"
                    type="text"
                    className="miSelect"
                  />
                  <FieldError name="emailSalaVenta" />
                </Grid>
              </Grid>

              <Grid container spacing={2} sx={{ marginTop: 2 }}>
                <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                  <label className="milabel">Mapa</label>
                  {isLoaded && (
                    <GoogleMap
                      mapContainerStyle={mapContainerStyle}
                      center={center}
                      zoom={15}
                      onLoad={onLoad}
                      onUnmount={onUnmount}
                      onClick={onClickMap}
                    >
                      {direccion && comuna && (
                        <MarkerF onLoad={onLoad} position={center} />
                      )}
                      <></>
                    </GoogleMap>
                  )}
                </Grid>
              </Grid>

              <Grid container spacing={2} sx={{ marginTop: 2 }}>
                <Grid item>
                  <h3 className="alignLeft">
                    Características de las propiedades
                  </h3>
                </Grid>
              </Grid>
              <Grid container spacing={1}>
                <Grid item xs={6} sm={6} md={6} lg={4} xl={4}>
                  <label className="milabel">Calefacción</label>
                  <Field name="calefaccion" as="select" className="miSelect">
                    <option value={""}>Seleccione</option>
                    <option value={"Radiador"}>Radiador</option>
                    <option value={"Losa"}>Losa</option>
                    <option value={"Electrica"}>Eléctrica</option>
                    <option value={"Otro"}>Otro</option>
                  </Field>
                  <FieldError name="calefaccion" />
                </Grid>
                <Grid item xs={6} sm={6} md={6} lg={4} xl={4}>
                  <label className="milabel">Tipo piso</label>
                  <Field name="tipoPiso" as="select" className="miSelect">
                    <option value={""}>Seleccione</option>
                    <option value={"Porcelanato"}>Porcelanato</option>
                    <option value={"PisoFlotante"}>Piso Flotante</option>
                    <option value={"Ceramico"}>Cerámico</option>
                    <option value={"Parquet"}>Parquet</option>
                    <option value={"Fotolaminado"}>Fotolaminado</option>
                  </Field>
                  <FieldError name="tipoPiso" />
                </Grid>
              </Grid>
              <Grid container spacing={1}>
                <Grid item xs={6} sm={6} md={4} lg={4} xl={4}>
                  <FormGroup>
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={cocinaAmo}
                          onChange={onChangeCocinaAmo}
                          name="cocinaAmo"
                          size="small"
                        />
                      }
                      classes={{ root: "alignLeft" }}
                      label="Cocina amoblada"
                    />
                  </FormGroup>
                </Grid>
                <Grid item xs={6} sm={6} md={4} lg={4} xl={4}>
                  <FormGroup>
                    <FormControlLabel
                      control={
                        <Checkbox
                          name="alarma"
                          checked={alarma}
                          onChange={onChangeAlarma}
                          size="small"
                        />
                      }
                      classes={{ root: "alignLeft" }}
                      label="Alarma"
                    />
                  </FormGroup>
                </Grid>
              </Grid>

              <Grid
                container
                spacing={2}
                sx={{ marginTop: 2 }}
                justifyContent={"flex-start"}
              >
                <Grid item>
                  <h3 className="alignLeft">Características de la comunidad</h3>
                </Grid>
              </Grid>
              <Grid container spacing={2}>
                <Grid item xs={6} sm={6} md={4} lg={4} xl={4}>
                  <FormGroup>
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={accesoControlado}
                          onChange={onChangeAccesoControlado}
                          name="accesoControlado"
                          size="small"
                        />
                      }
                      classes={{ root: "alignLeft" }}
                      label="Acceso controlado"
                    />
                  </FormGroup>
                </Grid>
                <Grid item xs={6} sm={6} md={4} lg={4} xl={4}>
                  <FormGroup>
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={estVisita}
                          onChange={onChangeEstVisita}
                          name="estVisitas"
                          size="small"
                        />
                      }
                      classes={{ root: "alignLeft" }}
                      label="Estacionamiento visitas"
                    />
                  </FormGroup>
                </Grid>
                <Grid item xs={6} sm={6} md={4} lg={4} xl={4}>
                  <FormGroup>
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={portonElec}
                          onChange={onChangePortonElec}
                          name="portonElec"
                          size="small"
                        />
                      }
                      classes={{ root: "alignLeft" }}
                      label="Portón eléctrico"
                    />
                  </FormGroup>
                </Grid>
                <Grid item xs={6} sm={6} md={4} lg={4} xl={4}>
                  <FormGroup>
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={salonDeJuegos}
                          onChange={onChangeSalonDeJuegos}
                          name="salaDeJuegos"
                          size="small"
                        />
                      }
                      classes={{ root: "alignLeft" }}
                      label="Sala de juegos"
                    />
                  </FormGroup>
                </Grid>
                <Grid item xs={6} sm={6} md={4} lg={4} xl={4}>
                  <FormGroup>
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={areasVerdes}
                          onChange={onChangeAreasVerdes}
                          name="areasVerdes"
                          size="small"
                        />
                      }
                      classes={{ root: "alignLeft" }}
                      label="Áreas verdes"
                    />
                  </FormGroup>
                </Grid>
                <Grid item xs={6} sm={6} md={4} lg={4} xl={4}>
                  <FormGroup>
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={quincho}
                          onChange={onChangeQuincho}
                          name="quincho"
                          size="small"
                        />
                      }
                      classes={{ root: "alignLeft" }}
                      label="Quincho"
                    />
                  </FormGroup>
                </Grid>
                <Grid item xs={6} sm={6} md={4} lg={4} xl={4}>
                  <FormGroup>
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={sauna}
                          onChange={onChangeSauna}
                          name="sauna"
                          size="small"
                        />
                      }
                      classes={{ root: "alignLeft" }}
                      label="Sauna"
                    />
                  </FormGroup>
                </Grid>
                <Grid item xs={6} sm={6} md={4} lg={4} xl={4}>
                  <FormGroup>
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={camaraSeguridad}
                          onChange={onChangeCamaraSeguridad}
                          name="camaraSeguridad"
                          size="small"
                        />
                      }
                      classes={{ root: "alignLeft" }}
                      label="Cámara seguridad"
                    />
                  </FormGroup>
                </Grid>
                <Grid item xs={6} sm={6} md={4} lg={4} xl={4}>
                  <FormGroup>
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={salaDeCine}
                          onChange={onChangeSalaDeCine}
                          name="salaDeCine"
                          size="small"
                        />
                      }
                      classes={{ root: "alignLeft" }}
                      label="Sala de cine"
                    />
                  </FormGroup>
                </Grid>
                <Grid item xs={6} sm={6} md={4} lg={4} xl={4}>
                  <FormGroup>
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={termopanel}
                          onChange={onChangeTermopanel}
                          name="termopanel"
                          size="small"
                        />
                      }
                      classes={{ root: "alignLeft" }}
                      label="Termopanel"
                    />
                  </FormGroup>
                </Grid>
                <Grid item xs={6} sm={6} md={4} lg={4} xl={4}>
                  <FormGroup>
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={juegosInf}
                          onChange={onChangeJuegosInf}
                          name="juegosInf"
                          size="small"
                        />
                      }
                      classes={{ root: "alignLeft" }}
                      label="Juegos infantiles"
                    />
                  </FormGroup>
                </Grid>
                <Grid item xs={6} sm={6} md={4} lg={4} xl={4}>
                  <FormGroup>
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={piscina}
                          onChange={onChangePiscina}
                          name="piscina"
                          size="small"
                        />
                      }
                      classes={{ root: "alignLeft" }}
                      label="Piscina"
                    />
                  </FormGroup>
                </Grid>
                <Grid item xs={6} sm={6} md={4} lg={4} xl={4}>
                  <FormGroup>
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={salaDeEventos}
                          onChange={onChangeSalaDeEventos}
                          name="salaDeEventos"
                          size="small"
                        />
                      }
                      classes={{ root: "alignLeft" }}
                      label="Sala de eventos"
                    />
                  </FormGroup>
                </Grid>
                <Grid item xs={6} sm={6} md={4} lg={4} xl={4}>
                  <FormGroup>
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={bicicletros}
                          onChange={onChangeBicicletros}
                          name="bicicletros"
                          size="small"
                        />
                      }
                      classes={{ root: "alignLeft" }}
                      label="Bicicleteros"
                    />
                  </FormGroup>
                </Grid>
                <Grid item xs={6} sm={6} md={4} lg={4} xl={4}>
                  <FormGroup>
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={bodegaEcommerce}
                          onChange={onChangeBodegaEcommerce}
                          name="bodegaEcommerce"
                          size="small"
                        />
                      }
                      classes={{ root: "alignLeft" }}
                      label="Bodegas E-Commerce"
                    />
                  </FormGroup>
                </Grid>
              </Grid>

              <Grid
                container
                spacing={2}
                sx={{ marginTop: 2 }}
                justifyContent={"flex-start"}
              >
                <Grid item>
                  <h3 className="alignLeft">Imágenes</h3>
                </Grid>
              </Grid>
              <Grid container justifyContent="flex-start">
                <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                  <Dropzone
                    name="imagenes"
                    onDropAccepted={(files) => onDrop(files, false, null)}
                    accept={ALLOWED_MIME_TYPES}
                    maxFiles={12}
                  >
                    {({ getRootProps, getInputProps }) => (
                      <section>
                        <div {...getRootProps({ className: "dropzonenew" })}>
                          <input {...getInputProps()} />
                          <p>
                            Arrastre las imágenes aquí o haga click para
                            seleccionar
                          </p>
                        </div>
                      </section>
                    )}
                  </Dropzone>
                  <Typography variant="caption" display="block" gutterBottom>
                    Se aceptan imágenes y videos con formato .PNG, .JPG y .JPEG
                    y .mp4. 12 fotos máximo a la vez
                  </Typography>
                  {values && values.imagenes && values.imagenes.length === 0 && (
                    <Typography
                      variant="caption"
                      display="block"
                      gutterBottom
                      sx={{ color: red[500] }}
                    >
                      Debe ingresar al menos una imagen
                    </Typography>
                  )}
                  {imageValidationMessage.length > 0 && (
                    <div>{renderImageValidationMessage()}</div>
                  )}
                  {imagenes && imagenes.length > 0 && (
                    <ImageList
                      rowHeight={170}
                      cols={isMobile ? 2 : 3}
                      gap={2}
                      sx={{ width: "100%" }}
                    >
                      {thumbs}
                    </ImageList>
                  )}
                </Grid>
              </Grid>
              <Grid container spacing={2} sx={{ marginTop: 2 }}>
                <Grid item>
                  <h3>Modelos</h3>
                </Grid>
              </Grid>
              <FieldArray name="modelos">
                {({ insert, remove, push }) => (
                  <React.Fragment>
                    {modelos &&
                      modelos.length > 0 &&
                      modelos.map((modelo, index) => {
                        return (
                          <Formik
                            initialValues={{
                              nombre: modelo && modelo.nombre,
                              superficieDesde: modelo && modelo.superficieDesde,
                              valorDesde: modelo && modelo.valorDesde,
                              banio: modelo && modelo.banio,
                              dormitorio: modelo && modelo.dormitorio,
                              urlMattePort: modelo && modelo.urlMattePort,
                              imagenes:
                                modelo && modelo.imagenes
                                  ? modelo.imagenes
                                  : [],
                            }}
                            //onSubmit={handleSubmit}
                            validationSchema={validateModel}
                          >
                            {({
                              values,
                              isValid,
                              handleSubmit,
                              setFieldValue,
                            }) => (
                              <Form onSubmit={handleSubmit}>
                                <Grid
                                  container
                                  spacing={2}
                                  sx={{ marginTop: 2 }}
                                >
                                  <Grid item>
                                    <h5>Modelo {index + 1}</h5>
                                  </Grid>
                                </Grid>
                                <Grid container spacing={2}>
                                  <Grid item xs={6} sm={6} md={4} lg={4} xl={4}>
                                    <label className="milabel">Nombre</label>
                                    <Field
                                      name={`nombre`}
                                      type="text"
                                      className="miSelect"
                                      onChange={(e) =>
                                        setValuesModelos(
                                          e,
                                          index,
                                          setFieldValue,
                                          isValid
                                        )
                                      }
                                    />
                                    <FieldError name={`nombre`} />
                                  </Grid>
                                  <Grid item xs={6} sm={6} md={4} lg={4} xl={4}>
                                    <label className="milabel">
                                      Superficie desde
                                    </label>
                                    <Field
                                      name={`superficieDesde`}
                                      type="text"
                                      className="miSelect"
                                      onChange={(e) =>
                                        setValuesModelos(
                                          e,
                                          index,
                                          setFieldValue,
                                          isValid
                                        )
                                      }
                                    />
                                    <FieldError name={`superficieDesde`} />
                                  </Grid>
                                  <Grid item xs={6} sm={6} md={4} lg={4} xl={4}>
                                    <label className="milabel">
                                      Valor desde
                                    </label>
                                    <Field
                                      name={`valorDesde`}
                                      type="text"
                                      className="miSelect"
                                      onChange={(e) =>
                                        setValuesModelos(
                                          e,
                                          index,
                                          setFieldValue,
                                          isValid
                                        )
                                      }
                                    />
                                    <FieldError name={`valorDesde`} />
                                  </Grid>
                                  <Grid item xs={6} sm={6} md={4} lg={4} xl={4}>
                                    <label className="milabel">Baños</label>
                                    <Field
                                      name={`banio`}
                                      type="text"
                                      className="miSelect"
                                      onChange={(e) =>
                                        setValuesModelos(
                                          e,
                                          index,
                                          setFieldValue,
                                          isValid
                                        )
                                      }
                                    />
                                    <FieldError name={`banio`} />
                                  </Grid>
                                  <Grid item xs={6} sm={6} md={4} lg={4} xl={4}>
                                    <label className="milabel">
                                      Dormitorios
                                    </label>
                                    <Field
                                      name={`dormitorio`}
                                      type="text"
                                      className="miSelect"
                                      onChange={(e) =>
                                        setValuesModelos(
                                          e,
                                          index,
                                          setFieldValue,
                                          isValid
                                        )
                                      }
                                    />
                                    <FieldError name={`dormitorio`} />
                                  </Grid>
                                  <Grid item xs={6} sm={6} md={4} lg={4} xl={4}>
                                    <label className="milabel">
                                      Url Matteport
                                    </label>
                                    <Field
                                      name={`urlMattePort`}
                                      type="text"
                                      className="miSelect"
                                      onChange={(e) =>
                                        setValuesModelos(
                                          e,
                                          index,
                                          setFieldValue,
                                          isValid
                                        )
                                      }
                                    />
                                  </Grid>
                                </Grid>
                                <Grid
                                  container
                                  justifyContent="flex-start"
                                  spacing={2}
                                >
                                  <Grid
                                    item
                                    xs={12}
                                    sm={12}
                                    md={12}
                                    lg={12}
                                    xl={12}
                                  >
                                    <label className="milabel">Imágenes</label>
                                    <Dropzone
                                      name="imagenes"
                                      onDropAccepted={(files) =>
                                        onDrop(files, true, index)
                                      }
                                      accept={ALLOWED_MIME_TYPES}
                                      maxFiles={12}
                                    >
                                      {({ getRootProps, getInputProps }) => (
                                        <section>
                                          <div
                                            {...getRootProps({
                                              className: "dropzonenew",
                                            })}
                                          >
                                            <input {...getInputProps()} />
                                            <p>
                                              Arrastre las imágenes aquí o haga
                                              click para seleccionar
                                            </p>
                                          </div>
                                        </section>
                                      )}
                                    </Dropzone>
                                    <Typography
                                      variant="caption"
                                      display="block"
                                      gutterBottom
                                    >
                                      Se aceptan imágenes y videos con formato
                                      .PNG, .JPG y .JPEG y .mp4. 12 fotos máximo
                                      a la vez
                                    </Typography>
                                    {(modelo.imagenes &&
                                      modelo.imagenes.length === 0) ||
                                      (values.imagenes &&
                                        values.imagenes.length === 0 && (
                                          <Typography
                                            variant="caption"
                                            display="block"
                                            gutterBottom
                                            sx={{ color: red[500] }}
                                          >
                                            Debe ingresar al menos una imagen
                                          </Typography>
                                        ))}
                                    {imageValidationMessage.length > 0 && (
                                      <div>
                                        {renderImageValidationMessage()}
                                      </div>
                                    )}
                                    {modelo.imagenes && (
                                      <ImageList
                                        rowHeight={170}
                                        gap={2}
                                        cols={isMobile ? 2 : 3}
                                        sx={{ width: "100%" }}
                                      >
                                        {modelo.imagenes.length > 0 &&
                                          modelo.imagenes.map((file) => {
                                            if (!file.name.includes(".mp4")) {
                                              return (
                                                <ImageListItem
                                                  key={file.name}
                                                  className={
                                                    file.esPortada
                                                      ? "destacar"
                                                      : ""
                                                  }
                                                >
                                                  <img
                                                    src={
                                                      file.value
                                                        ? file.value
                                                        : file.downloadLink
                                                    }
                                                    srcSet={
                                                      file.value
                                                        ? file.value
                                                        : file.downloadLink
                                                    }
                                                    alt={file.name}
                                                    loading="lazy"
                                                    width="240px"
                                                    height="170px"
                                                  />
                                                  <ImageListItemBar
                                                    sx={{
                                                      background: "none",
                                                    }}
                                                    position="bottom"
                                                    actionIcon={
                                                      <IconButton
                                                        sx={{
                                                          color:
                                                            "rgba(245, 66, 66, 1)",
                                                        }}
                                                        aria-label={`info about ${file.name}`}
                                                        onClick={() =>
                                                          deleteImage(
                                                            file,
                                                            true,
                                                            index
                                                          )
                                                        }
                                                      >
                                                        <Delete />
                                                      </IconButton>
                                                    }
                                                  />
                                                  <ImageListItemBar
                                                    title={file.name}
                                                    position="top"
                                                    actionIcon={
                                                      <IconButton
                                                        sx={{
                                                          color:
                                                            "rgba(255, 255, 255, 0.54)",
                                                        }}
                                                        aria-label={`info about ${file.name}`}
                                                        onClick={() =>
                                                          setPortada(
                                                            file,
                                                            true,
                                                            index
                                                          )
                                                        }
                                                      >
                                                        {file.esPortada ? (
                                                          <Star
                                                            sx={{
                                                              color:
                                                                yellow[500],
                                                            }}
                                                          />
                                                        ) : (
                                                          <Star />
                                                        )}
                                                      </IconButton>
                                                    }
                                                  />
                                                </ImageListItem>
                                              );
                                            } else {
                                              return (
                                                <ImageListItem
                                                  key={file.name}
                                                  className={
                                                    file.esPortada
                                                      ? "destacar"
                                                      : ""
                                                  }
                                                >
                                                  <video
                                                    width="240px"
                                                    height="170px"
                                                    autoPlay
                                                    loop
                                                    muted
                                                    //poster="https://assets.codepen.io/6093409/river.jpg"
                                                  >
                                                    <source
                                                      src={
                                                        file.value
                                                          ? file.value
                                                          : file.downloadLink
                                                      }
                                                      type="video/mp4"
                                                    />
                                                  </video>
                                                  <ImageListItemBar
                                                    sx={{
                                                      background: "none",
                                                    }}
                                                    position="bottom"
                                                    actionIcon={
                                                      <IconButton
                                                        sx={{
                                                          color:
                                                            "rgba(245, 66, 66, 1)",
                                                        }}
                                                        aria-label={`info about ${file.name}`}
                                                        onClick={() =>
                                                          deleteImage(
                                                            file,
                                                            true,
                                                            index
                                                          )
                                                        }
                                                      >
                                                        <Delete />
                                                      </IconButton>
                                                    }
                                                  />
                                                  <ImageListItemBar
                                                    title={file.name}
                                                    position="top"
                                                    actionIcon={
                                                      <IconButton
                                                        sx={{
                                                          color:
                                                            "rgba(255, 255, 255, 0.54)",
                                                        }}
                                                        aria-label={`info about ${file.name}`}
                                                        onClick={() =>
                                                          setPortada(
                                                            file,
                                                            true,
                                                            index
                                                          )
                                                        }
                                                      >
                                                        {file.esPortada ? (
                                                          <Star
                                                            sx={{
                                                              color:
                                                                yellow[500],
                                                            }}
                                                          />
                                                        ) : (
                                                          <Star />
                                                        )}
                                                      </IconButton>
                                                    }
                                                  />
                                                </ImageListItem>
                                              );
                                            }
                                          })}
                                      </ImageList>
                                    )}
                                  </Grid>
                                </Grid>
                                <Grid
                                  container
                                  justifyContent="flex-end"
                                  sx={{ mt: 2 }}
                                  spacing={2}
                                >
                                  {modelos.length > 1 && (
                                    <Grid item>
                                      <Button
                                        type="button"
                                        className="main-btn bg-red"
                                        variant="contained"
                                        //color="danger"
                                        onClick={() => eliminarModelo(modelo)}
                                      >
                                        Eliminar modelo
                                      </Button>
                                    </Grid>
                                  )}
                                  {index + 1 === modelos.length && (
                                    <Grid item>
                                      <Button
                                        type="button"
                                        className="main-btn"
                                        variant="contained"
                                        onClick={() => agregarModelo()}
                                      >
                                        Agregar modelo
                                      </Button>
                                    </Grid>
                                  )}
                                  <Grid
                                    item
                                    xs={12}
                                    sm={12}
                                    md={12}
                                    lg={12}
                                    xl={12}
                                  >
                                    <Divider
                                      variant="middle"
                                      sx={{ mb: 2, backgroundColor: "black" }}
                                    />
                                  </Grid>
                                </Grid>
                              </Form>
                            )}
                          </Formik>
                        );
                      })}
                  </React.Fragment>
                )}
              </FieldArray>

              <Grid container justifyContent="flex-end" sx={{ mt: 2 }}>
                <Grid item>
                  <Button
                    type="submit"
                    className="main-btn"
                    variant="contained"
                    color="success"
                  >
                    Guardar cambios
                  </Button>
                </Grid>
              </Grid>
            </Form>
          )}
        </Formik>
      )}
      <FormInmobiliaria
        isOpen={openInmobiliariaForm}
        handleClose={handleCloseInmobiliariaForm}
        reloadMethod={reloadInmobiliarias}
      />
    </div>
  );
};

export default FormProyecto;
