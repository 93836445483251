import * as React from "react";
import {
  Backdrop,
  Button,
  Stepper,
  Step,
  StepLabel,
  Grid,
  Box,
  Typography,
  CircularProgress,
  FormGroup,
  FormControlLabel,
  Checkbox,
  ImageList,
  ImageListItemBar,
  IconButton,
  ImageListItem,
} from "@mui/material";
import { useEffect } from "react";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  deleteProspecto,
  fetchAddPropiedad,
  fetchAddUsuario,
  fetchAllRegiones,
  fetchGetAllPlanes,
  getClientesPaginados,
  getProspecto,
  getUsuarioByEmail,
} from "../../action";
import Swal from "sweetalert2";
import FormAgregarPropietario from "./FormAgregarPropietario";
import { useHistory, useLocation } from "react-router-dom";
import { Field, Form, Formik } from "formik";
import { BARRIOS_COMUNAS } from "../../utils/constants";
import Dropzone from "react-dropzone";
import { Delete, Star } from "@mui/icons-material";
import { red, yellow } from "@mui/material/colors";
import Back from "../../assets/Back";
import * as Yup from "yup";
import FieldError from "../../utils/FieldError";
import { GoogleMap, useJsApiLoader } from "@react-google-maps/api";
import { MarkerF } from "@react-google-maps/api";
import utilsFunctions from "../../utils/utilsFunctions";

const validate = Yup.object().shape({
  idPlan: Yup.string().required("Ingrese un plan"),
  tipoPropiedad: Yup.string().required("Ingrese un tipo de propiedad"),
  dormitorios: Yup.number()
    .typeError("Ingrese un valor numerico")
    .min(0, "Ingrese valor mayor a 0")
    .required("Ingrese dormitorios"),
  banio: Yup.number()
    .typeError("Ingrese un valor numerico")
    .min(0, "Ingrese valor mayor a 0")
    .required("Ingrese banos"),
  cantEstacionamiento: Yup.number()
    .typeError("Ingrese un valor numerico")
    .min(0, "Ingrese valor mayor a 0")
    .required("Ingrese estacionamientos"),
  superficieUtil: Yup.number()
    .min(0, "Ingrese valor mayor a 0")
    .required("Ingrese superficie util, sin puntos ni comas")
    .typeError("Ingrese solo numeros"),
  superficieTotales: Yup.number()
    .typeError("Ingrese un valor numerico, sin puntos ni comas")
    .min(0, "Ingrese valor mayor a 0")
    .required("Ingrese superficie total"),
  bodega: Yup.number()
    .typeError("Ingrese un valor numerico, sin puntos ni comas")
    .min(0, "Ingrese valor mayor a 0")
    .required("Ingrese bodegas"),
  idRegion: Yup.number().required("Ingrese region"),
  comuna: Yup.string().required("Ingrese comuna"),
  nombreCalle: Yup.string().required("Ingrese calle"),
  numero: Yup.number()
    .typeError("Ingrese un valor numerico, sin puntos ni comas")
    .required("Ingrese numero"),
  numeroDepartamento: Yup.string()
    .when("tipoPropiedad", {
      is: "Departamento", // alternatively: (val) => val === true
      then: (schema) => schema.min(1),
      otherwise: (schema) => schema.notRequired(),
    })
    .required("Ingrese numero departamento"),
  valor: Yup.number()
    .typeError("Ingrese un valor numerico, sin puntos ni comas")
    .min(0, "Ingrese valor mayor a 0")
    .required("Ingrese precio"),
  gastosComunes: Yup.number()
    .typeError("Ingrese un valor numerico, sin puntos ni comas")
    .min(1, "Ingrese valor mayor a 0")
    .required("Ingrese gastos comunes"),
  tipoPrecio: Yup.string().required("Ingrese tipo moneda"),
  titulo: Yup.string().required("Ingrese título").min(1, "Ingrese un título para mostrar en portales Propins y externos").max(60, "Máximo 60 caracteres")
});

const UPLOAD_MIME_TYPES = [
  "image/png",
  "image/x-citrix-png",
  "image/x-png",
  "image/jpeg",
  "image/x-citrix-jpeg",
  "image/pjpeg",
  "video/mp4",
];

const mapContainerStyle = {
  width: "100%",
  height: "200px",
};

const steps = ["Datos propietario", "Datos propiedad"];

const AgregarPropietario = () => {
  const [lat, setLat] = useState(-33.43616371280176);
  const [lng, setLng] = useState(-70.63379790744193);
  const { isLoaded } = useJsApiLoader({
    id: "google-map-script",
    googleMapsApiKey: "AIzaSyCZh4ceCuAMermnESxIFECllWWURCP6Ucs",
  });

  const [map, setMap] = React.useState(null);
  const center = {
    lat,
    lng,
  };

  const onLoad = React.useCallback(function callback(map) {
    // This is just an example of getting and using the map instance!!! don't just blindly copy!
    const bounds = new window.google.maps.LatLngBounds(center);
    if (map.current) {
      map.current.fitBounds(bounds);
    }

    setMap(map);
  }, []);

  const onUnmount = React.useCallback(function callback(map) {
    setMap(null);
  }, []);

  const handleBlur = (e) => {
    if (e.target.name === "nombreCalle") {
      if (e.target.value !== "" && e.target.value !== null) {
        setNombreCalle(e.target.value);
      } else {
        setNombreCalle(null);
      }
    }
    if (e.target.name === "numero") {
      if (e.target.value !== "" && e.target.value !== null) {
        setNumero(e.target.value);
      } else {
        setNumero(null);
      }
    }
    if (e.target.name === "comuna") {
      if (e.target.value !== "" && e.target.value !== null) {
        setComuna(e.target.value);
      } else {
        setComuna(null);
      }
    }
  };

  const getCoords = async (address) => {
    const res = await utilsFunctions.GetLatitudeAndLongitudeByAddress(address);
    if (res.status === "ok") {
      setLat(res.lat);
      setLng(res.lng);
    }
  };

  const [nombreCalle, setNombreCalle] = useState("");
  const [numero, setNumero] = useState("");
  const [comuna, setComuna] = useState("");

  useEffect(() => {
    if (nombreCalle && numero && comuna) {
      getCoords(`${nombreCalle} ${numero}, ${comuna}`);
    }
  }, [nombreCalle, numero, comuna]);

  const dispatch = useDispatch();
  const history = useHistory();
  const [isLoading, setIsLoading] = useState(false);
  const [fromProspecto, setFromProspecto] = useState(false);
  const [isSuccessAddPropietario, setSuccessAddPropietario] = useState(false);
  const [isErrorAddPropietario, setIsErrorAddPropietario] = useState(false);

  const [initialValuesPropietario, setInitialValuesPropietario] = useState({
    tipoPersona: "",
    nombres: "",
    apellidos: "",
    rut: "",
    telefono: "",
    email: "",
    fechaNacimiento: new Date(),
  });
  const addedProp = useSelector((state) => state.app.propiedad);
  const comunasUser = useSelector((state) => state.app.comunas);
  const planes = useSelector((state) => state.app.itemPlanes);
  const clienteCreado = useSelector((state) => state.app.addUsuario);
  const requestAddUsuario = useSelector((state) => state.app.requestAddUsuario);
  const requestAddPropiedad = useSelector(
    (state) => state.app.requestAddPropiedad
  );
  const errorMessage = useSelector((state) => state.app.errorMessage);
  const [imageValidationMessage, setImageValidationMessage] = useState([]);
  const [propietarioData, setPropietarioData] = useState(null);
  const [propiedadData, setPropiedadData] = useState(null);
  const [isOpenBackdrop, setOpenBackdrop] = useState(false);
  const handleCloseBackdrop = () => setOpenBackdrop(false);
  const [activeStep, setActiveStep] = React.useState(0);
  const [skipped, setSkipped] = React.useState(new Set());
  const [images, setImages] = useState([]);
  const [masCaracteristicas, setMasCaracteristicas] = useState(false);
  const [escritorio, setEscritorio] = useState(false);
  const onChangeEscritorio = (e) => setEscritorio(e.target.checked);
  const [alarma, setAlarma] = useState(false);
  const onChangeAlarma = (e) => setAlarma(e.target.checked);
  const [cocinaAmo, setCocinaAmo] = useState(false);
  const onChangeCocinaAmo = (e) => setCocinaAmo(e.target.checked);
  const [logia, setLogia] = useState(false);
  const onChangeLogia = (e) => setLogia(e.target.checked);
  const [portonAut, setPortonAut] = useState(false);
  const onChangePortonAut = (e) => setPortonAut(e.target.checked);
  const [salaDeEstar, setSalaDeEstar] = useState(false);
  const onChangeSalaDeEstar = (e) => setSalaDeEstar(e.target.checked);
  const [accesoControlado, setAccesoControlado] = useState(false);
  const onChangeAccesoControlado = (e) => setAccesoControlado(e.target.checked);
  const [areasVerdes, setAreasVerdes] = useState(false);
  const onChangeAreasVerdes = (e) => setAreasVerdes(e.target.checked);
  const [bicicletros, setBicicletros] = useState(false);
  const onChangeBicicletros = (e) => setBicicletros(e.target.checked);
  const [camaraSeguridad, setCamaraSeguridad] = useState(false);
  const onChangeCamaraSeguridad = (e) => setCamaraSeguridad(e.target.checked);
  const [citofono, setCitofono] = useState(false);
  const onChangeCitofono = (e) => setCitofono(e.target.checked);
  const [estVisita, setEstVisita] = useState(false);
  const onChangeEstVisita = (e) => setEstVisita(e.target.checked);
  const [juegosInf, setJuegosInf] = useState(false);
  const onChangeJuegosInf = (e) => setJuegosInf(e.target.checked);
  const [piscina, setPiscina] = useState(false);
  const onChangePiscina = (e) => setPiscina(e.target.checked);
  const [portonElec, setPortonElec] = useState(false);
  const onChangePortonElec = (e) => setPortonElec(e.target.checked);
  const [quincho, setQuincho] = useState(false);
  const onChangeQuincho = (e) => setQuincho(e.target.checked);
  const [salaDeCine, setSalaDeCine] = useState(false);
  const onChangeSalaDeCine = (e) => setSalaDeCine(e.target.checked);
  const [salaDeEventos, setSalaDeEventos] = useState(false);
  const onChangeSalaDeEventos = (e) => setSalaDeEventos(e.target.checked);
  const [salonDeJuegos, setSalonDeJuegos] = useState(false);
  const onChangeSalonDeJuegos = (e) => setSalonDeJuegos(e.target.checked);
  const [sauna, setSauna] = useState(false);
  const onChangeSauna = (e) => setSauna(e.target.checked);
  const [amoblado, setAmoblado] = useState(false);
  const onChangeAmoblado = (e) => setAmoblado(e.target.checked);
  const [adjuntarVideoPropins, setAdjuntarVideoPropins] = useState(false);
  const onChangeAdjuntarVideoPropins = (e) =>
    setAdjuntarVideoPropins(e.target.checked);
  const [admiteMascotas, setAdmiteMascotas] = useState(false);
  const onChangeAdmiteMascotas = (e) => setAdmiteMascotas(e.target.checked);
  const [banioServicio, setBanioServicio] = useState(false);
  const onChangeBanioServicio = (e) => setBanioServicio(e.target.checked);
  const [initialValuesPropiedad, setInitialValuesPropiedad] = useState({
    idCliente: "",
    idPlan: "",
    dormitorios: "",
    banio: "",
    cantEstacionamiento: "",
    superficieUtil: "",
    superficieTotales: "",
    banioServicio,
    bodega: "0",
    gastosComunes: 0,
    contribuciones: 0,
    servicios: 0,
    anioConstruccion: "",
    calefaccion: "",
    escritorio,
    logia,
    portonAut,
    cocinaAmo,
    salaDeEstar,
    alarma,
    amoblado,
    condominio: false,
    region: "",
    comuna: "",
    barrio: "",
    nombreCalle: "",
    numero: "",
    valor: "",
    tipoPrecio: "",
    idRegion: "",
    accesoControlado,
    estVisita,
    portonElec,
    salonDeJuegos,
    areasVerdes,
    quincho,
    sauna,
    camaraSeguridad,
    bicicletros,
    salaDeCine,
    citofono,
    juegosInf,
    piscina,
    salaDeEventos,
    //id: initialValues && initialValues.id ? initialValues.id : "",
    imageContainerName: "",
    glosa: "",
    fechaTermino: "",
    disponibilidad: new Date(),
    lat: "",
    lon: "",
    tipoPropiedadInt: 0,
    orientacion: "",
    rol: "",
    pisoNumero: 0,
    ap: 0,
    cantEstacionamiento: "0",
    tieneBodega: false,
    via: "",
    codigoPostal: "",
    numeroDepartamento: 0,
    exclusividad: false,
    destacar: false,
    imagenes: [],
    observacionesInternas: "",
    observacionesPublicas: "",
    referenciaCalle: "",
    referenciaCalleA: "",
    referenciaCalleB: "",
    urlMattePort: "",
    horaVisitaFotografo: "",
    fechaVisitaFotografoString: "",
    idFotografo: null,
    detallePlan: "",
    fechaTermino: new Date(),
    anioConstruccion: 0,
    tipoPropiedad: "",
    titulo: "",
    adjuntarVideoPropins,
    admiteMascotas,
  });
  const regiones = useSelector(
    (state) =>
      state.app.itemRegiones &&
      state.app.itemRegiones.length > 0 &&
      state.app.itemRegiones[0].regiones
  );
  
  const requestGetRegiones = useSelector(
    (state) => state.app.requestGetRegiones
  );

  let { search } = useLocation();
  let query = new URLSearchParams(search);
  let idProspecto = query.get("idProspecto");
  useEffect(() => {
    if (idProspecto !== null) {
      dispatch(getProspecto("va", idProspecto));
      setFromProspecto(true);
    }
  }, [idProspecto]);

  const usuario = useSelector((state) => state.app.usuarioByFilter);
  const requestStateUsuario = useSelector(
    (state) => state.app.requestGetUsuarioByEmail
  );

  const prospecto = useSelector((state) => state.app.prospecto);
  const requestGetProspecto = useSelector(
    (state) => state.app.requestGetProspecto
  );

  useEffect(() => {
    if (fromProspecto && prospecto !== null) {
      const dataPropietario = {
        tipoPersona: prospecto.tipoContratoProspecto,
        nombres: prospecto.nombres,
        apellidos: prospecto.apellidos,
        rut: prospecto.rut,
        telefono: prospecto.telefono,
        email: prospecto.email,
        fechaNacimiento: prospecto.fechaNacimiento
          ? prospecto.fechaNacimiento
          : new Date(),
      };
      const dataPropiedad = {
        ...initialValuesPropiedad,
        dormitorios: prospecto.dormitorios,
        banio: prospecto.banios,
        idRegion: prospecto.idRegion,
        comuna: prospecto.comuna,
        nombreCalle: prospecto.calle,
        numero: prospecto.numero,
        idRegion: prospecto.idRegion,
        tipoPropiedad: prospecto.tipoPropiedad,
        numeroDepartamento: prospecto.numeroDepartamento,
        gastosComunes: 0,
        contribuciones: 0,
      };
      setInitialValuesPropietario(dataPropietario);
      setInitialValuesPropiedad(dataPropiedad);
    }
  }, [fromProspecto, prospecto]);

  useEffect(() => {
    dispatch(fetchGetAllPlanes());
    dispatch(fetchAllRegiones());
    //dispatch(fetchGetAllClientes());
    dispatch(getClientesPaginados(0, 999, "true"));
  }, []);
  const isStepOptional = (step) => {
    return step === 6;
    //return step === 1;
  };

  const isStepSkipped = (step) => {
    return skipped.has(step);
  };

  const handleNext = () => {
    let newSkipped = skipped;
    if (isStepSkipped(activeStep)) {
      newSkipped = new Set(newSkipped.values());
      newSkipped.delete(activeStep);
    }

    setActiveStep((prevActiveStep) => prevActiveStep + 1);
    setSkipped(newSkipped);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleSkip = () => {
    if (!isStepOptional(activeStep)) {
      // You probably want to guard against something like this,
      // it should never occur unless someone's actively trying to break something.
      throw new Error("You can't skip a step that isn't optional.");
    }

    setActiveStep((prevActiveStep) => prevActiveStep + 1);
    setSkipped((prevSkipped) => {
      const newSkipped = new Set(prevSkipped.values());
      newSkipped.add(activeStep);
      return newSkipped;
    });
  };

  const handleReset = () => {
    setActiveStep(0);
  };

  useEffect(() => {
    if (
      requestAddUsuario === "LOADING" ||
      requestAddPropiedad === "LOADING" ||
      (fromProspecto && requestGetProspecto === "LOADING")
    ) {
      setIsLoading(true);
    } else {
      setIsLoading(false);
    }
  }, [
    requestAddUsuario,
    requestAddPropiedad,
    fromProspecto,
    requestGetProspecto,
  ]);

  const addPropiedad = (idClienteParam) => {
    if (!propiedadData.txtFechaTerm) {
      const hoy = new Date();
      propiedadData.fechaTermino = new Date(hoy.setMonth(hoy.getMonth() + 6));
    }

    if (!propiedadData.idRegion) {
      propiedadData.idRegion = 15;
    }

    if (!propiedadData.ap) {
      propiedadData.ap = propiedadData.numeroDepartamento
        ? propiedadData.numeroDepartamento
        : 0;
    }

    let noEditoVisitaFotografo = false;
    let tituloGenerico = `${propiedadData.tipoPropiedad} ${propiedadData.comuna} ${propiedadData.dormitorios}D ${propiedadData.banio}B`;
    const data = {
      ...propiedadData,
      titulo: propiedadData.titulo ? propiedadData.titulo : tituloGenerico,
      banioServicio,
      condominio: propiedadData.condominio ? "Cerrado" : "Abierto",
      carCom: {
        accesoControlado,
        estVisita,
        portonElec,
        salonDeJuegos,
        areasVerdes,
        quincho,
        sauna,
        camaraSeguridad,
        bicicletros,
        salaDeCine,
        citofono,
        juegosInf,
        piscina,
        salaDeEventos,
      },
      propCar: {
        salaDeEstar,
        calefaccion: propiedadData.calefaccion,
        alarma,
        escritorio,
        logia,
        portonAut,
        cocinaAmo,
      },
      loc: {
        x: lat,
        y: lng,
      },
      idCliente:
        clienteCreado && clienteCreado.clienteId
          ? clienteCreado.clienteId
          : idClienteParam,
      imagenes: images,
      admiteMascotas,
      adjuntarVideoPropins,
      amoblado,
    };
    dispatch(fetchAddPropiedad(data));
  };

  useEffect(() => {
    if (requestAddUsuario === "SUCCESS") {
      setSuccessAddPropietario(true);
      setIsErrorAddPropietario(false);
      addPropiedad();
    }
    if (requestAddUsuario === "ERROR") {
      setIsErrorAddPropietario(true);
      fireError("user");
    }
  }, [requestAddUsuario, clienteCreado]);

  useEffect(() => {
    if (
      isErrorAddPropietario &&
      usuario !== null &&
      requestStateUsuario === "IDLE" &&
      usuario.email === propietarioData.email &&
      usuario.clienteId !== null &&
      usuario.clienteId !== ""
    ) {
      //setIsErrorAddPropietario(false);
      //console.log(usuario)
      console.log("el cliente ya existe");
      addPropiedad(usuario.clienteId);
    }
  }, [isErrorAddPropietario, usuario, requestStateUsuario]);

  useEffect(() => {
    if (requestAddPropiedad === "SUCCESS") {
      fireSuccess();
    } else if (requestAddPropiedad === "ERROR") {
      fireError("prop");
    }
  }, [requestAddPropiedad]);

  const fireError = (module) => {
    if (module === "user") {
      if (errorMessage.includes("mail")) {
        dispatch(getUsuarioByEmail(propietarioData.email));
      } else {
        Swal.fire(
          "Error",
          `Ha habido un error agregando al usuario: ${errorMessage}`,
          "error"
        );
      }
    } else {
      Swal.fire({
        title: "Error",
        text: `Se ha agregado al cliente exitosamente, pero ha habido un error agregando la propiedad. Desea ir al perfil de propietario y agregar la propiedad desde ahi?`,
        showDenyButton: false,
        showCancelButton: true,
        confirmButtonText: "Ir al perfil propietario",
        cancelButtonText: "Cancelar",
      }).then((result) => {
        if (
          result.value ||
          (result.dismiss === "backdrop" &&
            clienteCreado &&
            clienteCreado.clienteId)
        ) {
          history.push(
            `/propietarios/propiedades?id=${clienteCreado.clienteId}`
          );
        }
      });
    }
  };

  const fireSuccess = () => {
    /*
    if (
      addedProp !== null &&
      addedProp.comuna !== null &&
      comunasUser !== null &&
      !comunasUser.has(addedProp.comuna)
    ) {
      dispatch(setComunas(new Set([...comunasUser, addedProp.comuna])));
    }
    */
    if (fromProspecto && prospecto !== null && prospecto.id !== null) {
      dispatch(deleteProspecto("va", prospecto.id));
    }
    Swal.fire({
      title: "Propietario agregado",
      text: "Se ha agregado el propietario exitosamente",
      icon: "success",
    }).then((result) => {
      if (result.value || result.dismiss === "backdrop") {
        if (
          result.value ||
          (result.dismiss === "backdrop" &&
            clienteCreado &&
            clienteCreado.clienteId)
        ) {
          history.push(
            `/propietarios/propiedades?id=${clienteCreado.clienteId}`
          );
        }
        //history.push("/mispropiedades");
      }
    });
  };

  const handleSubmit = (formValues) => {
    setPropiedadData(formValues);

    const datosPropietario = {
      fechaNacimiento: propietarioData.fechaNacimiento,
      nombres: propietarioData.nombres,
      apellidos: propietarioData.apellidos,
      rut: propietarioData.rut,
      email: propietarioData.email,
      oficio: "",
      telefono: propietarioData.telefono,
      inmobiliariaId: null,
      direccion: "",
      proyectosInmobiliariosId: null,
      password: "temppass123",
      tipoCuenta: 1,
      datosBancarios: {
        medioPago: propietarioData.medioPago,
        banco: propietarioData.banco,
        tipoCuenta: propietarioData.tipoCuenta,
        numeroCuenta: propietarioData.numeroCuenta,
      },
    };
    dispatch(fetchAddUsuario(datosPropietario));
  };

  const handleSubmitPropietario = (formValues) => {
    handleNext();
    setPropietarioData(formValues);
  };

  const deleteImage = (file) => {
    setImages(images.filter((f) => f.name !== file.name));
  };

  const setPortada = (file) => {
    let image = images.find((f) => f.name === file.name);
    if (image.esPortada) {
      image.esPortada = false;
    } else {
      image.esPortada = true;
    }
    setImages(
      images.map((a) => {
        return a.name === file.name ? image : a;
      })
    );
  };

  const fileToBase64 = (file) => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result);
      reader.onerror = (error) => reject(error);
    });
  };

  const fileValidator = (file) => {
    let { name, type, size } = file;

    size = size / 1024;
    if (!UPLOAD_MIME_TYPES.includes(type)) {
      return `${name}: el tipo de archivo es inválido!`;
    } else if (size > 5000) {
      return `${name}: el archivo es muy pesado!`;
    }
    return null;
  };

  const onDrop = (files) => {
    let limit = 6;
    const msg = `No puedes agregar más de ${limit} imágenes`;
    setImageValidationMessage([]);
    if (images.length >= limit) {
      setImageValidationMessage([...imageValidationMessage, msg]);
    } else {
      if (files) {
        if (files.length + images.length > limit) {
          setImageValidationMessage([...imageValidationMessage, msg]);
        } else {
          files.map((f) => {
            var result = fileValidator(f);
            if (result === null) {
              fileToBase64(f).then((file64) => {
                const newFile = {
                  name: f.name,
                  value: file64,
                  esPortada: false,
                };
                setImages((oldValue) => [...oldValue, newFile]);
              });
            } else {
              setImageValidationMessage((oldValue) => [...oldValue, result]);
            }
          });
        }
      }
    }
  };

  const thumbs =
    images &&
    images.length > 0 &&
    images.map((file) => (
      <ImageListItem
        key={file.name}
        className={file.esPortada ? "destacar" : ""}
      >
        <img
          src={file.value ? file.value : file.downloadLink}
          srcSet={file.value ? file.value : file.downloadLink}
          alt={file.name}
          loading="lazy"
          width="240px"
          height="170px"
        />
        <ImageListItemBar
          sx={{
            background: "none",
          }}
          position="bottom"
          actionIcon={
            <IconButton
              sx={{ color: "rgba(245, 66, 66, 1)" }}
              aria-label={`info about ${file.name}`}
              onClick={() => deleteImage(file)}
            >
              <Delete />
            </IconButton>
          }
        />
        <ImageListItemBar
          title={file.name}
          position="top"
          actionIcon={
            <IconButton
              sx={{ color: "rgba(255, 255, 255, 0.54)" }}
              aria-label={`info about ${file.name}`}
              onClick={() => setPortada(file)}
            >
              {file.esPortada ? <Star sx={{ color: yellow[500] }} /> : <Star />}
            </IconButton>
          }
        />
      </ImageListItem>
    ));

  const renderImageValidationMessage = () => {
    return imageValidationMessage.map((m) => (
      <Typography
        variant="caption"
        display="block"
        gutterBottom
        sx={{ color: red[500] }}
      >
        {m}
      </Typography>
    ));
  };

  return (
    <div className="contenido">
      <Back />
      <div className="header">
        <h2>Agregar propietario</h2>
      </div>
      <Grid container>
        <Grid item xs={12} sm={12} md={10} lg={10} xl={10}>
          <Box sx={{ width: "100%" }}>
            <Stepper activeStep={activeStep}>
              {steps.map((label, index) => {
                const stepProps = {};
                const labelProps = {};
                if (isStepOptional(index)) {
                  labelProps.optional = (
                    <Typography variant="caption">Optional</Typography>
                  );
                }
                if (isStepSkipped(index)) {
                  stepProps.completed = false;
                }
                return (
                  <Step key={label} {...stepProps}>
                    <StepLabel {...labelProps}>{label}</StepLabel>
                  </Step>
                );
              })}
            </Stepper>
            {activeStep === steps.length ? (
              <React.Fragment>
                <Typography sx={{ mt: 2, mb: 1 }}>
                  All steps completed - you&apos;re finished
                </Typography>
                <Box sx={{ display: "flex", flexDirection: "row", pt: 2 }}>
                  <Box sx={{ flex: "1 1 auto" }} />
                  <Button onClick={handleReset}>Reset</Button>
                </Box>
              </React.Fragment>
            ) : (
              <React.Fragment>
                {isLoading && (
                  <Backdrop
                    sx={{
                      color: "#fff",
                      zIndex: (theme) => theme.zIndex.drawer + 1,
                    }}
                    open={true}
                    onClick={handleCloseBackdrop}
                  >
                    <CircularProgress color="inherit" />
                  </Backdrop>
                )}
                {activeStep === 0 && (
                  <FormAgregarPropietario
                    initialValues={initialValuesPropietario}
                    submitMethod={handleSubmitPropietario}
                    nextButton={
                      <Button
                        type="submit"
                        className="main-btn"
                        variant="contained"
                        color="success"
                      >
                        {"Siguiente"}
                      </Button>
                    }
                  />
                )}
                {activeStep === 1 && (
                  <Formik
                    enableReinitialize
                    initialValues={initialValuesPropiedad}
                    onSubmit={
                      isSuccessAddPropietario ? addPropiedad : handleSubmit
                    }
                    validationSchema={validate}
                  >
                    {({ values, handleSubmit }) => (
                      <Form onSubmit={handleSubmit}>
                        {isLoading && (
                          <Backdrop
                            sx={{
                              color: "#fff",
                              zIndex: (theme) => theme.zIndex.drawer + 1,
                            }}
                            open={true}
                            onClick={handleCloseBackdrop}
                          >
                            <CircularProgress color="inherit" />
                          </Backdrop>
                        )}
                        <Grid container spacing={4} sx={{ mt: 1 }}>
                          <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                            <Grid container spacing={1}>
                              <Grid
                                item
                                xs={12}
                                sm={12}
                                md={12}
                                lg={12}
                                xl={12}
                              >
                                <h5 className="alignLeft">Plan</h5>
                              </Grid>
                            </Grid>
                            <Grid container spacing={1}>
                              <Grid item xs={6} sm={6} md={6} lg={6} xl={6}>
                                <label className="milabel">
                                  Plan contratado
                                </label>
                                <Field
                                  name="idPlan"
                                  as="select"
                                  className="miSelect"
                                >
                                  <option value={""}>- Seleccione -</option>
                                  {planes &&
                                    planes.map((c) => (
                                      <option value={c.id}>{c.nombre}</option>
                                    ))}
                                </Field>
                                <FieldError name="idPlan" />
                              </Grid>
                            </Grid>

                            {values &&
                              values.planContratado &&
                              !values.planContratado.esVenta && (
                                <div>
                                  <Grid container spacing={1}>
                                    <Grid
                                      item
                                      xs={6}
                                      sm={6}
                                      md={6}
                                      lg={6}
                                      xl={6}
                                    >
                                      <label className="milabel">Banco</label>
                                      <Field
                                        name="banco"
                                        as="select"
                                        className="miSelect"
                                      >
                                        <option value="Departamento">
                                          Banco de Chile
                                        </option>
                                        <option value="Casa">
                                          Banco estado
                                        </option>
                                      </Field>
                                    </Grid>
                                    <Grid
                                      item
                                      xs={6}
                                      sm={6}
                                      md={6}
                                      lg={6}
                                      xl={6}
                                    >
                                      <label className="milabel">
                                        Tipo de cuenta
                                      </label>
                                      <Field
                                        name="banco"
                                        as="select"
                                        className="miSelect"
                                      >
                                        <option value="Departamento">
                                          Cuenta corriente
                                        </option>
                                        <option value="Casa">
                                          Cuenta vista
                                        </option>
                                      </Field>
                                    </Grid>
                                  </Grid>
                                  <Grid container spacing={1}>
                                    <Grid
                                      item
                                      xs={12}
                                      sm={12}
                                      md={12}
                                      lg={12}
                                      xl={12}
                                    >
                                      <label className="milabel">
                                        Numero de cuenta
                                      </label>
                                      <Field
                                        name="numcuenta"
                                        type="text"
                                        className="miSelect"
                                      />
                                    </Grid>
                                  </Grid>
                                </div>
                              )}

                            <Grid container spacing={1} sx={{ mt: 2 }}>
                              <Grid
                                item
                                xs={12}
                                sm={12}
                                md={12}
                                lg={12}
                                xl={12}
                              >
                                <h5 className="alignLeft">
                                  Características propiedad
                                </h5>
                              </Grid>
                            </Grid>
                            <Grid container spacing={1}>
                              <Grid
                                item
                                xs={12}
                                sm={12}
                                md={12}
                                lg={12}
                                xl={12}
                              >
                                <label className="milabel">Título</label>
                                <Field
                                  name="titulo"
                                  type="text"
                                  className="miSelect"
                                />
                                <FieldError name="titulo" />
                              </Grid>
                              <Grid
                                item
                                xs={12}
                                sm={12}
                                md={12}
                                lg={12}
                                xl={12}
                              >
                                <label className="milabel">Descripción</label>
                                <Field
                                  name="observacionesPublicas"
                                  type="text"
                                  className="miTextarea"
                                  as="textarea"
                                />
                                <FieldError name="observacionesPublicas" />
                              </Grid>
                            </Grid>
                            <Grid container spacing={1}>
                              <Grid item xs={6} sm={6} md={6} lg={6} xl={6}>
                                <label className="milabel">
                                  Tipo propiedad
                                </label>
                                <Field
                                  name="tipoPropiedad"
                                  as="select"
                                  className="selectFullWidth"
                                >
                                  <option value={""}>- Seleccione -</option>
                                  <option value="Departamento">
                                    Departamento
                                  </option>
                                  <option value="Casa">Casa</option>
                                </Field>
                                <FieldError name="tipoPropiedad" />
                              </Grid>
                              <Grid item xs={6} sm={6} md={6} lg={6} xl={6}>
                                <label className="milabel">Orientación</label>
                                <Field
                                  name="orientacion"
                                  type="text"
                                  className="miSelect"
                                />
                                <FieldError name="orientacion" />
                              </Grid>
                            </Grid>
                            <Grid container spacing={1}>
                              <Grid item xs={6} sm={6} md={4} lg={4} xl={4}>
                                <label className="milabel">Dormitorios</label>
                                <Field
                                  name="dormitorios"
                                  type="text"
                                  className="miSelect"
                                />
                                <FieldError name="dormitorios" />
                              </Grid>
                              <Grid item xs={6} sm={6} md={4} lg={4} xl={4}>
                                <label className="milabel">Baños</label>
                                <Field
                                  name="banio"
                                  type="text"
                                  className="miSelect"
                                />
                                <FieldError name="banio" />
                              </Grid>
                              <Grid item xs={6} sm={6} md={4} lg={4} xl={4}>
                                <label className="milabel">
                                  Estacionamientos
                                </label>
                                <Field
                                  name="cantEstacionamiento"
                                  type="text"
                                  className="miSelect"
                                />
                                <FieldError name="cantEstacionamiento" />
                              </Grid>
                            </Grid>
                            <Grid container spacing={1}>
                              <Grid item xs={6} sm={6} md={4} lg={4} xl={4}>
                                <label className="milabel">
                                  Superficie total
                                </label>
                                <Field
                                  name="superficieTotales"
                                  type="text"
                                  className="miSelect"
                                />
                                <FieldError name="superficieTotales" />
                              </Grid>
                              <Grid item xs={6} sm={6} md={4} lg={4} xl={4}>
                                <label className="milabel">
                                  Superficie útil
                                </label>
                                <Field
                                  name="superficieUtil"
                                  type="text"
                                  className="miSelect"
                                />
                                <FieldError name="superficieUtil" />
                              </Grid>
                              <Grid item xs={6} sm={6} md={4} lg={4} xl={4}>
                                <label className="milabel">Bodegas</label>
                                <Field
                                  name="bodega"
                                  as="select"
                                  className="selectFullWidth"
                                >
                                  <option value={""}>- Seleccione -</option>
                                  <option value="0">0</option>
                                  <option value="1">1</option>
                                  <option value="2">2</option>
                                  <option value="3">3</option>
                                  <option value="4">4</option>
                                  <option value="5">5</option>
                                </Field>
                                <FieldError name="bodega" />
                              </Grid>
                            </Grid>
                            <Grid container spacing={1}>
                              <Grid item xs={6} sm={6} md={4} lg={4} xl={4}>
                                <label className="milabel">
                                  Gastos comunes
                                </label>
                                <Field
                                  name="gastosComunes"
                                  type="text"
                                  className="miSelect"
                                />
                                <FieldError name="gastosComunes" />
                              </Grid>
                              <Grid item xs={6} sm={6} md={4} lg={4} xl={4}>
                                <label className="milabel">
                                  Contribuciones
                                </label>
                                <Field
                                  name="contribuciones"
                                  type="text"
                                  className="miSelect"
                                />
                              </Grid>
                            </Grid>
                            {values &&
                              values.idPlan &&
                              planes &&
                              planes.some((p) => p.id === values.idPlan) &&
                              !planes.find((p) => p.id === values.idPlan)
                                .esVenta && (
                                <Grid container>
                                  <Grid
                                    item
                                    xs={12}
                                    sm={6}
                                    md={4}
                                    lg={4}
                                    xl={4}
                                  >
                                    <FormGroup>
                                      <FormControlLabel
                                        control={
                                          <Checkbox
                                            checked={amoblado}
                                            onChange={onChangeAmoblado}
                                            name="amoblado"
                                            size="small"
                                          />
                                        }
                                        classes={{ root: "alignLeft" }}
                                        label="Amoblado"
                                      />
                                    </FormGroup>
                                  </Grid>
                                  <Grid
                                    item
                                    xs={12}
                                    sm={6}
                                    md={4}
                                    lg={4}
                                    xl={4}
                                  >
                                    <FormGroup>
                                      <FormControlLabel
                                        control={
                                          <Checkbox
                                            checked={admiteMascotas}
                                            onChange={onChangeAdmiteMascotas}
                                            name="admiteMascotas"
                                            size="small"
                                          />
                                        }
                                        classes={{ root: "alignLeft" }}
                                        label="Admite mascotas"
                                      />
                                    </FormGroup>
                                  </Grid>
                                </Grid>
                              )}
                            <div className="seccion">
                              <Button
                                className="masCaracteristicas"
                                onClick={() =>
                                  setMasCaracteristicas(!masCaracteristicas)
                                }
                              >
                                {" "}
                                Mas Caracteristicas
                              </Button>
                            </div>

                            <div id={masCaracteristicas ? "" : "noVisible"}>
                              <Grid container>
                                <Grid
                                  item
                                  xs={12}
                                  sm={12}
                                  md={12}
                                  lg={12}
                                  xl={12}
                                >
                                  <h3 className="subtitle alignLeft">
                                    Características propiedad
                                  </h3>
                                </Grid>
                              </Grid>
                              <Grid container spacing={1}>
                                <Grid item xs={12} sm={4} md={4} lg={4} xl={4}>
                                  <label className="milabel">
                                    Año construcción
                                  </label>
                                  <Field
                                    name="anioConstruccion"
                                    type="text"
                                    className="miSelect"
                                  />
                                </Grid>
                                <Grid item xs={12} sm={8} md={8} lg={8} xl={8}>
                                  <label className="milabel">Calefacción</label>
                                  <Field
                                    name="calefaccion"
                                    as="select"
                                    className="selectFullWidth"
                                  >
                                    <option value={""}>- Seleccione -</option>
                                    <option value="losa">Losa</option>
                                    <option value="radiador">Radiador</option>
                                    <option value="electrica">Eléctrica</option>
                                    <option value="otro">Otro</option>
                                  </Field>
                                </Grid>
                              </Grid>
                              <Grid container spacing={1}>
                                <Grid item xs={12} sm={6} md={4} lg={4} xl={4}>
                                  <FormGroup>
                                    <FormControlLabel
                                      control={
                                        <Checkbox
                                          checked={escritorio}
                                          size="small"
                                          name="escritorio"
                                          onChange={onChangeEscritorio}
                                        />
                                      }
                                      classes={{ root: "alignLeft" }}
                                      label="Escritorio"
                                    />
                                  </FormGroup>
                                </Grid>
                                <Grid item xs={12} sm={6} md={4} lg={4} xl={4}>
                                  <FormGroup>
                                    <FormControlLabel
                                      control={
                                        <Checkbox
                                          checked={logia}
                                          onChange={onChangeLogia}
                                          name="logia"
                                          size="small"
                                        />
                                      }
                                      classes={{ root: "alignLeft" }}
                                      label="Logia"
                                    />
                                  </FormGroup>
                                </Grid>
                                <Grid item xs={12} sm={6} md={4} lg={4} xl={4}>
                                  <FormGroup>
                                    <FormControlLabel
                                      control={
                                        <Checkbox
                                          checked={portonAut}
                                          onChange={onChangePortonAut}
                                          name="portonAut"
                                          size="small"
                                        />
                                      }
                                      classes={{ root: "alignLeft" }}
                                      label="Portón automático"
                                    />
                                  </FormGroup>
                                </Grid>
                              </Grid>

                              <Grid container spacing={1}>
                                <Grid item xs={12} sm={6} md={4} lg={4} xl={4}>
                                  <FormGroup>
                                    <FormControlLabel
                                      control={
                                        <Checkbox
                                          checked={cocinaAmo}
                                          onChange={onChangeCocinaAmo}
                                          name="cocinaAmo"
                                          size="small"
                                        />
                                      }
                                      classes={{ root: "alignLeft" }}
                                      label="Cocina amoblada"
                                    />
                                  </FormGroup>
                                </Grid>
                                <Grid item xs={12} sm={6} md={4} lg={4} xl={4}>
                                  <FormGroup>
                                    <FormControlLabel
                                      control={
                                        <Checkbox
                                          checked={salaDeEstar}
                                          onChange={onChangeSalaDeEstar}
                                          name="salaDeEstar"
                                          size="small"
                                        />
                                      }
                                      classes={{ root: "alignLeft" }}
                                      label="Sala de estar"
                                    />
                                  </FormGroup>
                                </Grid>
                                <Grid item xs={12} sm={6} md={4} lg={4} xl={4}>
                                  <FormGroup>
                                    <FormControlLabel
                                      control={
                                        <Checkbox
                                          name="alarma"
                                          checked={alarma}
                                          onChange={onChangeAlarma}
                                          size="small"
                                        />
                                      }
                                      classes={{ root: "alignLeft" }}
                                      label="Alarma"
                                    />
                                  </FormGroup>
                                </Grid>
                              </Grid>

                              <Grid container spacing={1}>
                                <Grid item xs={12} sm={6} md={4} lg={4} xl={4}>
                                  <FormGroup>
                                    <FormControlLabel
                                      control={
                                        <Checkbox
                                          name="condominio"
                                          size="small"
                                        />
                                      }
                                      classes={{ root: "alignLeft" }}
                                      label="Condominio"
                                    />
                                  </FormGroup>
                                </Grid>
                                <Grid item xs={12} sm={6} md={4} lg={4} xl={4}>
                                  <FormGroup>
                                    <FormControlLabel
                                      control={
                                        <Checkbox
                                          checked={banioServicio}
                                          onChange={onChangeBanioServicio}
                                          name="banioServicio"
                                          size="small"
                                        />
                                      }
                                      classes={{ root: "alignLeft" }}
                                      label="Baño servicio"
                                    />
                                  </FormGroup>
                                </Grid>
                              </Grid>

                              <Grid container spacing={1} sx={{ mt: 2 }}>
                                <Grid
                                  item
                                  xs={12}
                                  sm={12}
                                  md={12}
                                  lg={12}
                                  xl={12}
                                >
                                  <h3 className="subtitle alignLeft">
                                    Características de la comunidad
                                  </h3>
                                </Grid>
                              </Grid>

                              <Grid container spacing={1}>
                                <Grid item xs={12} sm={6} md={4} lg={4} xl={4}>
                                  <FormGroup>
                                    <FormControlLabel
                                      control={
                                        <Checkbox
                                          checked={accesoControlado}
                                          onChange={onChangeAccesoControlado}
                                          name="accesoControlado"
                                          size="small"
                                        />
                                      }
                                      classes={{ root: "alignLeft" }}
                                      label="Acceso controlado"
                                    />
                                  </FormGroup>
                                </Grid>
                                <Grid item xs={12} sm={6} md={4} lg={4} xl={4}>
                                  <FormGroup>
                                    <FormControlLabel
                                      control={
                                        <Checkbox
                                          checked={areasVerdes}
                                          onChange={onChangeAreasVerdes}
                                          name="areasVerdes"
                                          size="small"
                                        />
                                      }
                                      classes={{ root: "alignLeft" }}
                                      label="Áreas verdes"
                                    />
                                  </FormGroup>
                                </Grid>
                                <Grid item xs={12} sm={6} md={4} lg={4} xl={4}>
                                  <FormGroup>
                                    <FormControlLabel
                                      control={
                                        <Checkbox
                                          checked={portonElec}
                                          onChange={onChangePortonElec}
                                          name="portonElec"
                                          size="small"
                                        />
                                      }
                                      classes={{ root: "alignLeft" }}
                                      label="Portón eléctrico"
                                    />
                                  </FormGroup>
                                </Grid>
                              </Grid>
                              <Grid container spacing={1}>
                                <Grid item xs={12} sm={6} md={4} lg={4} xl={4}>
                                  <FormGroup>
                                    <FormControlLabel
                                      control={
                                        <Checkbox
                                          checked={camaraSeguridad}
                                          onChange={onChangeCamaraSeguridad}
                                          name="camaraSeguridad"
                                          size="small"
                                        />
                                      }
                                      classes={{ root: "alignLeft" }}
                                      label="Cámara seguridad"
                                    />
                                  </FormGroup>
                                </Grid>
                                <Grid item xs={12} sm={6} md={4} lg={4} xl={4}>
                                  <FormGroup>
                                    <FormControlLabel
                                      control={
                                        <Checkbox
                                          checked={quincho}
                                          onChange={onChangeQuincho}
                                          name="quincho"
                                          size="small"
                                        />
                                      }
                                      classes={{ root: "alignLeft" }}
                                      label="Quincho"
                                    />
                                  </FormGroup>
                                </Grid>
                                <Grid item xs={12} sm={6} md={4} lg={4} xl={4}>
                                  <FormGroup>
                                    <FormControlLabel
                                      control={
                                        <Checkbox
                                          checked={salaDeCine}
                                          onChange={onChangeSalaDeCine}
                                          name="salaDeCine"
                                          size="small"
                                        />
                                      }
                                      classes={{ root: "alignLeft" }}
                                      label="Sala de cine"
                                    />
                                  </FormGroup>
                                </Grid>
                              </Grid>
                              <Grid container spacing={1}>
                                <Grid item xs={12} sm={6} md={4} lg={4} xl={4}>
                                  <FormGroup>
                                    <FormControlLabel
                                      control={
                                        <Checkbox
                                          checked={citofono}
                                          onChange={onChangeCitofono}
                                          name="citofono"
                                          size="small"
                                        />
                                      }
                                      classes={{ root: "alignLeft" }}
                                      label="Citófono"
                                    />
                                  </FormGroup>
                                </Grid>
                                <Grid item xs={12} sm={6} md={4} lg={4} xl={4}>
                                  <FormGroup>
                                    <FormControlLabel
                                      control={
                                        <Checkbox
                                          checked={sauna}
                                          onChange={onChangeSauna}
                                          name="sauna"
                                          size="small"
                                        />
                                      }
                                      classes={{ root: "alignLeft" }}
                                      label="Sauna"
                                    />
                                  </FormGroup>
                                </Grid>
                                <Grid item xs={12} sm={6} md={4} lg={4} xl={4}>
                                  <FormGroup>
                                    <FormControlLabel
                                      control={
                                        <Checkbox
                                          checked={salonDeJuegos}
                                          onChange={onChangeSalonDeJuegos}
                                          name="salaDeJuegos"
                                          size="small"
                                        />
                                      }
                                      classes={{ root: "alignLeft" }}
                                      label="Sala de juegos"
                                    />
                                  </FormGroup>
                                </Grid>
                              </Grid>
                              <Grid container spacing={1}>
                                <Grid item xs={12} sm={6} md={4} lg={4} xl={4}>
                                  <FormGroup>
                                    <FormControlLabel
                                      control={
                                        <Checkbox
                                          checked={salaDeEventos}
                                          onChange={onChangeSalaDeEventos}
                                          name="salaDeEventos"
                                          size="small"
                                        />
                                      }
                                      classes={{ root: "alignLeft" }}
                                      label="Sala de eventos"
                                    />
                                  </FormGroup>
                                </Grid>
                                <Grid item xs={12} sm={6} md={4} lg={4} xl={4}>
                                  <FormGroup>
                                    <FormControlLabel
                                      control={
                                        <Checkbox
                                          checked={bicicletros}
                                          onChange={onChangeBicicletros}
                                          name="bicicletros"
                                          size="small"
                                        />
                                      }
                                      classes={{ root: "alignLeft" }}
                                      label="Bicicleteros"
                                    />
                                  </FormGroup>
                                </Grid>
                                <Grid item xs={12} sm={6} md={4} lg={4} xl={4}>
                                  <FormGroup>
                                    <FormControlLabel
                                      control={
                                        <Checkbox
                                          checked={piscina}
                                          onChange={onChangePiscina}
                                          name="piscina"
                                          size="small"
                                        />
                                      }
                                      classes={{ root: "alignLeft" }}
                                      label="Piscina"
                                    />
                                  </FormGroup>
                                </Grid>
                              </Grid>

                              <Grid container spacing={1}>
                                <Grid item xs={12} sm={6} md={4} lg={4} xl={4}>
                                  <FormGroup>
                                    <FormControlLabel
                                      control={
                                        <Checkbox
                                          checked={juegosInf}
                                          onChange={onChangeJuegosInf}
                                          name="juegosInf"
                                          size="small"
                                        />
                                      }
                                      classes={{ root: "alignLeft" }}
                                      label="Juegos infantiles"
                                    />
                                  </FormGroup>
                                </Grid>
                                <Grid item xs={12} sm={6} md={4} lg={4} xl={4}>
                                  <FormGroup>
                                    <FormControlLabel
                                      control={
                                        <Checkbox
                                          checked={estVisita}
                                          onChange={onChangeEstVisita}
                                          name="estVisitas"
                                          size="small"
                                        />
                                      }
                                      classes={{ root: "alignLeft" }}
                                      label="Estacionamiento visitas"
                                    />
                                  </FormGroup>
                                </Grid>
                              </Grid>
                            </div>
                          </Grid>

                          <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                            <Grid container spacing={1}>
                              <Grid
                                item
                                xs={12}
                                sm={12}
                                md={12}
                                lg={12}
                                xl={12}
                              >
                                <h5 className="alignLeft">Ubicación</h5>
                              </Grid>
                            </Grid>
                            {requestGetRegiones == "IDLE" && (
                              <Grid container spacing={1}>
                                <Grid item xs={6} sm={6} md={6} lg={6} xl={6}>
                                  <label className="milabel">Región</label>
                                  <Field
                                    name="idRegion"
                                    as="select"
                                    className="miSelect"
                                  >
                                    <option value="">- Seleccione -</option>
                                    {regiones &&
                                      regiones.map((r) => (
                                        <option value={r.numero}>
                                          {r.region}
                                        </option>
                                      ))}
                                  </Field>
                                  <FieldError name="idRegion" />
                                </Grid>
                                <Grid item xs={6} sm={6} md={6} lg={6} xl={6}>
                                  <label className="milabel">Comuna</label>
                                  <Field
                                    name="comuna"
                                    as="select"
                                    className="miSelect"
                                    onBlur={handleBlur}
                                  >
                                    <option value={""}>- Seleccione -</option>
                                    {regiones &&
                                      values &&
                                      values.idRegion &&
                                      regiones.some(
                                        (u) => u.numero == values.idRegion
                                      ) &&
                                      regiones.find(
                                        (u) => u.numero == values.idRegion
                                      ).comunas &&
                                      regiones
                                        .find(
                                          (u) => u.numero == values.idRegion
                                        )
                                        .comunas.map((c) => (
                                          <option value={c}>{c}</option>
                                        ))}
                                  </Field>
                                  <FieldError name="comuna" />
                                </Grid>
                              </Grid>
                            )}
                            {values &&
                            values.tipoPropiedad === "Departamento" ? (
                              <Grid container spacing={1}>
                                <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                                  <label className="milabel">Calle</label>
                                  <Field
                                    name="nombreCalle"
                                    type="text"
                                    className="miSelect"
                                    onBlur={handleBlur}
                                  />
                                  <FieldError name="nombreCalle" />
                                </Grid>
                                <Grid item xs={6} sm={6} md={3} lg={3} xl={3}>
                                  <label className="milabel">Número</label>
                                  <Field
                                    name="numero"
                                    type="text"
                                    className="miSelect"
                                    onBlur={handleBlur}
                                  />
                                  <FieldError name="numero" />
                                </Grid>
                                <Grid item xs={6} sm={6} md={3} lg={3} xl={3}>
                                  <label className="milabel">Nro. depto.</label>
                                  <Field
                                    name="numeroDepartamento"
                                    type="text"
                                    className="miSelect"
                                  />
                                  <FieldError name="numeroDepartamento" />
                                </Grid>
                              </Grid>
                            ) : (
                              <Grid container spacing={1}>
                                <Grid item xs={12} sm={12} md={8} lg={8} xl={8}>
                                  <label className="milabel">Calle</label>
                                  <Field
                                    name="nombreCalle"
                                    type="text"
                                    className="miSelect"
                                    onBlur={handleBlur}
                                  />
                                  <FieldError name="nombreCalle" />
                                </Grid>
                                <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
                                  <label className="milabel">Número</label>
                                  <Field
                                    name="numero"
                                    type="text"
                                    className="miSelect"
                                    onBlur={handleBlur}
                                  />
                                </Grid>
                                <FieldError name="numero" />
                              </Grid>
                            )}

                            {requestGetRegiones == "IDLE" && (
                              <Grid container spacing={1}>
                                <Grid
                                  item
                                  xs={12}
                                  sm={12}
                                  md={12}
                                  lg={12}
                                  xl={12}
                                >
                                  <label className="milabel">Barrio</label>
                                  <Field
                                    name="barrio"
                                    as="select"
                                    className="miSelect"
                                  >
                                    <option value={""}>- Seleccione -</option>
                                    {values &&
                                      values.comuna &&
                                      values.comuna != null &&
                                      values.comuna != undefined &&
                                      BARRIOS_COMUNAS[values.comuna] != null &&
                                      BARRIOS_COMUNAS[values.comuna] !=
                                        undefined &&
                                      BARRIOS_COMUNAS[values.comuna].map(
                                        (p) => <option value={p}>{p}</option>
                                      )}
                                  </Field>
                                </Grid>
                              </Grid>
                            )}

                            <Grid container spacing={1}>
                              <Grid
                                item
                                xs={12}
                                sm={12}
                                md={12}
                                lg={12}
                                xl={12}
                              >
                                <label className="milabel">Mapa</label>
                                {isLoaded && (
                                  <GoogleMap
                                    mapContainerStyle={mapContainerStyle}
                                    center={center}
                                    zoom={15}
                                    onLoad={onLoad}
                                    onUnmount={onUnmount}
                                  >
                                    {nombreCalle && numero && comuna && (
                                      <MarkerF
                                        onLoad={onLoad}
                                        position={center}
                                      />
                                    )}
                                    <></>
                                  </GoogleMap>
                                )}
                              </Grid>
                            </Grid>

                            <Grid container spacing={1} sx={{ mt: 2 }}>
                              <Grid
                                item
                                xs={12}
                                sm={12}
                                md={12}
                                lg={12}
                                xl={12}
                              >
                                <h5 className="alignLeft">Precio propiedad</h5>
                              </Grid>
                            </Grid>

                            <Grid container spacing={1}>
                              <Grid item xs={6} sm={6} md={6} lg={6} xl={6}>
                                <label className="milabel">Tipo moneda</label>
                                <Field
                                  name="tipoPrecio"
                                  as="select"
                                  className="miSelect"
                                >
                                  <option value={""}>- Seleccione -</option>
                                  <option value="CLP">CLP</option>
                                  <option value="UF">UF</option>
                                </Field>
                                <FieldError name="tipoMoneda" />
                              </Grid>
                              <Grid item xs={6} sm={6} md={6} lg={6} xl={6}>
                                <label className="milabel">Precio</label>
                                <Field
                                  name="valor"
                                  type="text"
                                  className="miSelect"
                                />
                                <FieldError name="valor" />
                              </Grid>
                            </Grid>

                            <Grid container spacing={1} sx={{ mt: 2 }}>
                              <Grid
                                item
                                xs={12}
                                sm={12}
                                md={12}
                                lg={12}
                                xl={12}
                              >
                                <h5 className="alignLeft">Imágenes</h5>
                              </Grid>
                            </Grid>

                            <Grid container spacing={1}>
                              <Grid
                                item
                                xs={12}
                                sm={12}
                                md={12}
                                lg={12}
                                xl={12}
                              >
                                <FormGroup>
                                  <FormControlLabel
                                    control={
                                      <Checkbox
                                        checked={adjuntarVideoPropins}
                                        onChange={onChangeAdjuntarVideoPropins}
                                        name="adjuntarVideoPropins"
                                        size="small"
                                      />
                                    }
                                    classes={{ root: "alignLeft" }}
                                    label="Adjuntar video Propins"
                                  />
                                </FormGroup>
                              </Grid>
                            </Grid>

                            <Grid container spacing={1} sx={{ mt: 2 }}>
                              <Grid
                                item
                                xs={12}
                                sm={12}
                                md={12}
                                lg={12}
                                xl={12}
                              >
                                <Dropzone
                                  name="imagenes"
                                  onDropAccepted={onDrop}
                                  accept={UPLOAD_MIME_TYPES}
                                >
                                  {({ getRootProps, getInputProps }) => (
                                    <section>
                                      <div
                                        {...getRootProps({
                                          className: "dropzonenew",
                                        })}
                                      >
                                        <input {...getInputProps()} />
                                        <p>
                                          Arrastre los archivos aqui o haga
                                          click para seleccionar
                                        </p>
                                      </div>
                                    </section>
                                  )}
                                </Dropzone>
                                <Typography
                                  variant="caption"
                                  display="block"
                                  gutterBottom
                                >
                                  Se aceptan imágenes y videos con formato .PNG,
                                  .JPG, .JPEG y .mp4. 6 fotos máximo a la vez
                                  (se pueden agregar más después, en el perfil
                                  de la propiedad)
                                </Typography>
                                {imageValidationMessage.length > 0 && (
                                  <div>{renderImageValidationMessage()}</div>
                                )}
                                <ImageList
                                  rowHeight={170}
                                  gap={2}
                                  sx={{ height: 400 }}
                                >
                                  {thumbs}
                                </ImageList>
                              </Grid>
                            </Grid>
                          </Grid>
                        </Grid>
                        <Grid container spacing={4} justifyContent="flex-end">
                          <Grid item>
                            <Button
                              disabled={false}
                              onClick={handleBack}
                              sx={{ mr: 1 }}
                              className="main-btn"
                            >
                              Atrás
                            </Button>
                          </Grid>
                          <Grid item>
                            <Button
                              type="submit"
                              className="main-btn"
                              variant="contained"
                              color="success"
                            >
                              Guardar
                            </Button>
                          </Grid>
                        </Grid>
                      </Form>
                    )}
                  </Formik>
                )}

                <Box sx={{ display: "flex", flexDirection: "row", pt: 2 }}>
                  {/* <Button
                    disabled={activeStep === 0}
                    onClick={handleBack}
                    sx={{ mr: 1 }}
                    className="main-btn"
                  >
                    Atrás
                  </Button>*/}

                  <Box sx={{ flex: "1 1 auto" }} />
                  {isStepOptional(activeStep) && (
                    <Button color="inherit" onClick={handleSkip} sx={{ mr: 1 }}>
                      Skip
                    </Button>
                  )}
                </Box>
              </React.Fragment>
            )}
          </Box>
        </Grid>
      </Grid>
    </div>
  );
};

export default AgregarPropietario;
