import {
  Button,
  CircularProgress,
  Divider,
  Grid,
  IconButton,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Tooltip,
} from "@mui/material";
import { Field, Form, Formik } from "formik";
import { useSelector } from "react-redux";
import FirmantesForm from "./FirmantesForm";
import ItemForm from "./ItemForm";
import { useState } from "react";
import { Delete } from "@mui/icons-material";

const estados = {
  0: "Bueno",
  1: "Regular",
  2: "Malo",
};

export const FormGenerarInventarioInmueble = ({
  idPropiedad,
  idProspecto,
  handleSubmit,
  handleClose,
    //datosAlvaro,
  //guardarDatosAlvaro,
  //editDatosAlvaro,
  //setEditDatosAlvaro,
  onChangePropiedad,
  onChangeIdProspecto,
  validRut,
  prospecto,
  setFirmante,
  validationMessages,
  usarDocumentosAlvaro,
  onChangeUsarDocumentosAlvaro
}) => {
  const requestPropiedades = useSelector(
    (state) => state.app.requestPropiedadesPaginadas
  );
  const requestGetProspectos = useSelector(
    (state) => state.app.requestGetProspectosCA
  );
  const prospectos = useSelector(
    (state) => state.app.prospectosCA && state.app.prospectosCA.results
  );
  const propiedades = useSelector(
    (state) =>
      state.app.propiedadesPaginadas && state.app.propiedadesPaginadas.results
  );

  const [addingItem, setAddingItem] = useState(false);
  const [items, setItems] = useState([]);
  const addItem = (item) => {
    setAddingItem(false);
    setItems([...items, item]);
  };
  const cancelAddItem = () => setAddingItem(false);
  const onRemoveItem = (prop) =>
    setItems(items.filter((p) => p.descripcion !== prop.descripcion));

  const submitLocal = (formValues) => {
    setFirmante({
      rut: prospecto.rut,
      nombres: prospecto.nombres,
      apellidos: prospecto.apellidos,
      email: prospecto.email,
      telefono: prospecto.telefono,
    });
    const data = {
      ...formValues,
      inventario: items,
    };
    handleSubmit(data);
  };

  return (
    <Formik
      initialValues={{
        idPropiedad,
        idProspecto,
        observaciones: "",
        archivoVersoIdentidad: "",
        archivoReversoIdentidad: "",
        //archivoCertificadoDocumentoDominio: "",
        archivoVersoIdentidadPropins: "",
        archivoReversoIdentidadPropins: "",
      }}
      //validate={validate}
      onSubmit={submitLocal}
    >
      {({ values, errors, handleSubmit, setFieldValue }) => (
        <>
          <Form onSubmit={handleSubmit} className="form-container">
            <Grid container>
              {requestPropiedades === "LOADING" && <CircularProgress />}
              {requestPropiedades === "IDLE" && (
                <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                  <label className="milabel">Propiedad</label>
                  <Field
                    name="idPropiedad"
                    as="select"
                    className="miSelect"
                    value={idPropiedad}
                    onChange={onChangePropiedad}
                  >
                    <option value={""}>- Seleccione -</option>
                    {propiedades.map((c) => {
                      return (
                        <option value={c.id}>
                          {c.nombreCalle} {c.numero}
                        </option>
                      );
                    })}
                  </Field>
                </Grid>
              )}
              {requestGetProspectos === "LOADING" && <CircularProgress />}
              {requestGetProspectos === "IDLE" && (
                <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                  <label className="milabel">Prospecto comprador</label>
                  <Field
                    name="idProspecto"
                    as="select"
                    className="miSelect"
                    value={idProspecto}
                    onChange={onChangeIdProspecto}
                  >
                    <option value={""}>- Seleccione -</option>
                    {prospectos.map((c) => {
                      return (
                        <option value={c.id}>
                          {c.nombres} {c.apellidos}
                        </option>
                      );
                    })}
                  </Field>
                </Grid>
              )}
            </Grid>

            <Grid item xs={12} sm={12} md={12} lg={12} xl={12} sx={{ mt: 2 }}>
              <Grid
                container
                alignItems="center"
                justifyContent={"space-between"}
              >
                <Grid item>
                  <h5 className="alignLeft">Items inmueble</h5>
                </Grid>
                <Grid item>
                  {!addingItem && (
                    <Button
                      size="small"
                      type="button"
                      className="main-btn"
                      variant="contained"
                      color="success"
                      onClick={() => setAddingItem(true)}
                    >
                      Agregar item
                    </Button>
                  )}
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
              {items.length === 0 && (
                <div>No has agregado ningún item todavía</div>
              )}
              {items.length > 0 && (
                <TableContainer component={Paper} sx={{ mt: 2 }}>
                  <Table
                    sx={{ maxWidth: "auto" }}
                    size="small"
                    aria-label="simple table"
                  >
                    <TableHead>
                      <TableRow>
                        <TableCell>Descripción</TableCell>
                        <TableCell align="right">Ctdad</TableCell>
                        <TableCell align="right">Estado</TableCell>
                        <TableCell align="right">Observaciones</TableCell>
                        <TableCell align="right">Eliminar</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {items.map((row) => (
                        <TableRow
                          key={row.descripcion}
                          sx={{
                            "&:last-child td, &:last-child th": { border: 0 },
                          }}
                        >
                          <TableCell component="th" scope="row">
                            {row.descripcion}
                          </TableCell>
                          <TableCell align="right">{row.cantidad}</TableCell>
                          <TableCell align="right">
                            <div
                              className={
                                row.estado === 0
                                  ? "confirmada"
                                  : row.estado === 1
                                  ? "pendiente"
                                  : "cancelada"
                              }
                              style={{ textAlign: "center" }}
                            >
                              {estados[row.estado]}
                            </div>
                          </TableCell>
                          <TableCell align="right">
                            {row.observaciones}
                          </TableCell>
                          <TableCell align="right">
                            <Tooltip title="Quitar" aria-label="quitar">
                              <IconButton
                                //color="primary"
                                aria-label="Quitar"
                                component="span"
                                onClick={() => onRemoveItem(row)}
                              >
                                <Delete />
                              </IconButton>
                            </Tooltip>
                          </TableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                </TableContainer>
              )}
            </Grid>
            <Grid item xs={12} sm={12} md={12} lg={12} xl={12} sx={{ mt: 4 }}>
              <Divider
                variant="middle"
                sx={{ mb: 2, backgroundColor: "black" }}
              />
            </Grid>
            {addingItem && (
              <ItemForm handleSubmit={addItem} handleClose={cancelAddItem} />
            )}
            <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
              <label className="milabel">Observaciones</label>
              <Field
                name="observaciones"
                type="text"
                className="miTextarea"
                as="textarea"
              />
            </Grid>
            {idPropiedad && idProspecto && prospecto && (
              <FirmantesForm
                setFieldValue={setFieldValue}
//datosAlvaro={datosAlvaro}
                //editDatosAlvaro={editDatosAlvaro}
                //setEditDatosAlvaro={setEditDatosAlvaro}
                //guardarDatosAlvaro={guardarDatosAlvaro}
                firmante={prospecto}
                editPath={`/prospectos/comprador/requerimientos?id=${prospecto.id}`}
                usarDocumentosAlvaro={usarDocumentosAlvaro}
                onChangeUsarDocumentosAlvaro={onChangeUsarDocumentosAlvaro}
              />
            )}
            {validationMessages && validationMessages.length > 0 && (
              <Grid container sx={{ mt: 2 }}>
                <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                  Se han encontrado los siguientes problemas con la validación:
                </Grid>
                {validationMessages.map((v) => (
                  <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                    {v}
                  </Grid>
                ))}
              </Grid>
            )}

            <Grid container justifyContent="flex-end" sx={{ mt: 2 }}>
              <Grid item>
                <Button onClick={handleClose} className="main-btn">
                  Cancelar
                </Button>
              </Grid>
              <Grid item>
                <Button
                  type="submit"
                  className="main-btn"
                  variant="contained"
                  color="success"
                >
                  Guardar
                </Button>
              </Grid>
            </Grid>
          </Form>
        </>
      )}
    </Formik>
  );
};
