import { red, yellow } from "@mui/material/colors";
import { useDispatch } from "react-redux";
import Dropzone from "react-dropzone";
import React, { useEffect, useState } from "react";
import {
  Grid,
  IconButton,
  ImageList,
  ImageListItem,
  ImageListItemBar,
  Typography,
} from "@mui/material";
import { Delete, Download, Star } from "@mui/icons-material";
import "./Dropzone.css";
import { fetchPostUpdatePropiedad } from "../../../action";

const isMobile =
  !!navigator.userAgent.match(/iphone|android|blackberry/gi) || false;

const UPLOAD_MIME_TYPES = [
  "image/png",
  "image/x-citrix-png",
  "image/x-png",
  "image/jpeg",
  "image/x-citrix-jpeg",
  "image/pjpeg",
  "video/mp4",
];

const FotosPropiedad = ({ prop }) => {
  const dispatch = useDispatch();
  const [images, setImages] = useState(
    prop &&
      prop.imagenes &&
      Array.isArray(prop.imagenes) &&
      prop.imagenes.length > 0
      ? prop.imagenes
      : []
  );
  const initialImages = prop && prop.imagenes ? prop.imagenes : [];
  const [imageValidationMessage, setImageValidationMessage] = useState([]);
  const [modified, setModified] = useState(false);
  const [openBackdrop, setOpenBackdrop] = useState(false);
  const handleCloseBackdrop = () => setOpenBackdrop(false);

  const actualizarImagenesPropiedad = () => {
    const data = {
      ...prop,
      idPlan: prop && prop.planContratado && prop.planContratado.id,
      imagenes: images,
    };
    dispatch(fetchPostUpdatePropiedad(data));
  };

  useEffect(() => {
    if (images.toString() !== initialImages.toString()) {
      setModified(true);
    } else {
      setModified(false);
    }
  }, [images]);

  const deleteImage = (file) => {
    setImages(images.filter((f) => f.name !== file.name));
  };

  const setPortada = (file) => {
    let image = images.find((f) => f.name === file.name);
    if (image.esPortada) {
      image.esPortada = false;
    } else {
      image.esPortada = true;
    }
    setImages(
      images.map((a) => {
        return a.name === file.name ? image : a;
      })
    );
  };

  const fileToBase64 = (file) => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result);
      reader.onerror = (error) => reject(error);
    });
  };

  const fileValidator = (file) => {
    let { name, type, size } = file;

    size = size / 1024;
    if (!UPLOAD_MIME_TYPES.includes(type)) {
      return `${name}: el tipo de archivo es inválido!`;
    } else if (size > 5000) {
      return `${name}: el archivo es muy pesado!`;
    }
    return null;
  };

  const onDrop = (files) => {
    const limit = 12;
    const msg = `No puedes agregar más de ${limit} imágenes`;
    setImageValidationMessage([]);
    if (files) {
      if (files.length > limit) {
        setImageValidationMessage([...imageValidationMessage, msg]);
      } else {
        files.map((f) => {
          var result = fileValidator(f);
          if (result === null) {
            fileToBase64(f).then((file64) => {
              const newFile = {
                name: f.name,
                value: file64,
                esPortada: false,
              };
              setImages((oldValue) => [...oldValue, newFile]);
            });
          } else {
            setImageValidationMessage((oldValue) => [...oldValue, result]);
          }
        });
      }
    }
  };

  const renderImageValidationMessage = () => {
    return imageValidationMessage.map((m) => (
      <Typography
        variant="caption"
        display="block"
        gutterBottom
        sx={{ color: red[500] }}
      >
        {m}
      </Typography>
    ));
  };

  const downloadImage = async (file) => {
    //console.log(e.target.href);
    fetch(file.downloadLink, {
      method: "GET",
      headers: {},
    })
      .then((response) => {
        response.arrayBuffer().then(function (buffer) {
          const url = window.URL.createObjectURL(new Blob([buffer]));
          const link = document.createElement("a");
          link.href = url;
          link.setAttribute("download", "image.png"); //or any other extension
          document.body.appendChild(link);
          link.click();
        });
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const thumbs =
    images &&
    images.length > 0 &&
    images.map((file) => {
      if (!file.name.includes(".mp4")) {
        return (
          <ImageListItem
            //sx={{maxHeight: "100px"}}
            key={file.name}
            className={file.esPortada ? "destacar" : ""}
          >
            <img
              src={file.value ? `${file.value}` : `${file.downloadLink}`}
              //srcSet={file.value ? `${file.value}?w=164&h=164&fit=crop&auto=format&dpr=2 2x` : `${file.downloadLink}?w=164&h=164&fit=crop&auto=format&dpr=2 2x`}
              alt={file.name}
              loading="lazy"
              width="170px"
              height="100px"
              className="img-ficha"
            />
            <ImageListItemBar
              sx={{
                background: "none",
              }}
              position="bottom"
              actionIcon={
                <React.Fragment>
                  <IconButton
                    sx={{ color: "rgba(255, 255, 255, 1)" }}
                    aria-label={`info about ${file.name}`}
                    onClick={() => downloadImage(file)}
                  >
                    <Download />
                  </IconButton>
                  <IconButton
                    sx={{ color: "rgba(245, 66, 66, 1)" }}
                    aria-label={`info about ${file.name}`}
                    onClick={() => deleteImage(file)}
                  >
                    <Delete />
                  </IconButton>
                </React.Fragment>
              }
            />
            <ImageListItemBar
              title={file.name}
              position="top"
              actionIcon={
                <IconButton
                  sx={{ color: "rgba(255, 255, 255, 0.54)" }}
                  aria-label={`info about ${file.name}`}
                  onClick={() => setPortada(file)}
                >
                  {file.esPortada ? (
                    <Star sx={{ color: yellow[500] }} />
                  ) : (
                    <Star />
                  )}
                </IconButton>
              }
            />
          </ImageListItem>
        );
      } else {
        return (
          <ImageListItem
            //sx={{maxHeight: "100px"}}
            key={file.name}
            className={file.esPortada ? "destacar" : ""}
          >
            <video
              width="270px"
              height="170px"
              autoPlay
              loop
              muted
              //poster="https://assets.codepen.io/6093409/river.jpg"
            >
              <source
                src={file.value ? file.value : file.downloadLink}
                type="video/mp4"
              />
            </video>
            <ImageListItemBar
              sx={{
                background: "none",
              }}
              position="bottom"
              actionIcon={
                <IconButton
                  sx={{ color: "rgba(245, 66, 66, 1)" }}
                  aria-label={`info about ${file.name}`}
                  onClick={() => deleteImage(file)}
                >
                  <Delete />
                </IconButton>
              }
            />
            <ImageListItemBar
              title={file.name}
              position="top"
              actionIcon={
                <IconButton
                  sx={{ color: "rgba(255, 255, 255, 0.54)" }}
                  aria-label={`info about ${file.name}`}
                  onClick={() => setPortada(file)}
                >
                  {file.esPortada ? (
                    <Star sx={{ color: yellow[500] }} />
                  ) : (
                    <Star />
                  )}
                </IconButton>
              }
            />
          </ImageListItem>
        );
      }
    });

  return (
    <div className="main">
      <h3 className="alignLeft">Imágenes y videos propiedad</h3>
      {images && images.length === 0 && (
        <div>Esta propiedad no tiene imágenes ni videos.</div>
      )}
      {imageValidationMessage.length > 0 && (
        <div>{renderImageValidationMessage()}</div>
      )}
      <Grid container spacing={1}>
        <Grid item xs={12} sm={12} md={12} lg={11} xl={10}>
          <ImageList cols={isMobile ? 2 : 4} rowHeight={150} gap={4}>
            {thumbs}
          </ImageList>
        </Grid>
      </Grid>

      <Grid container>
        <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
          <h4 className="alignLeft">Agregar fotos y videos</h4>
        </Grid>
        <Grid
          item
          xs={12}
          sm={12}
          md={12}
          lg={12}
          xl={12}
          className="alignLeft"
        >
          Se aceptan imágenes y videos con formato .PNG, .JPG, .JPEG y .mp4
        </Grid>
        {modified && (
          <Grid
            item
            xs={12}
            sm={12}
            md={12}
            lg={12}
            xl={12}
            className="alignLeft"
          >
            <button
              className="boton"
              onClick={() => actualizarImagenesPropiedad()}
            >
              Confirmar cambios
            </button>
          </Grid>
        )}
      </Grid>

      <Grid container>
        <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
          <Dropzone
            name="imagenes"
            onDropAccepted={onDrop}
            accept={UPLOAD_MIME_TYPES}
          >
            {({ getRootProps, getInputProps }) => (
              <section>
                <div
                  {...getRootProps({ className: "dropzone-ficha-prop-fotos" })}
                >
                  <input {...getInputProps()} />
                  <p>
                    Arrastre las imágenes aquí o haga click para seleccionar
                  </p>
                </div>
              </section>
            )}
          </Dropzone>
        </Grid>
      </Grid>
      {/* 
<h2 className="alignLeft">Videos propiedad</h2>
        <div className="imagenes">
          <img className="imagen"></img>
          <div className="agregarImagen">
            <div>
              <p style={{ fontSize: "14px" }}>Añadir videos</p>
              <button className="miButton">Seleccionar</button>
            </div>
          </div>
        </div>
*/}
    </div>
  );
};

export default FotosPropiedad;
