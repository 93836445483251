import {
  Backdrop,
  Button,
  Card,
  CardContent,
  CircularProgress,
  FormControlLabel,
  FormGroup,
  Grid,
  Table,
} from "@mui/material";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  exportarTasacion,
  postGetPropiedadesSimilaresTasacion,
  postGetTasacion,
  postGetValoresPreliminaresTasacion,
  setExportTasacion,
  setValoresPreliminaresTasacion,
} from "../../action";
import { Field, Form, Formik } from "formik";
import { BARRIOS_COMUNAS } from "../../utils/constants";
import Checkbox from "@mui/material/Checkbox";
import Swal from "sweetalert2";

const Tasacion = () => {
  const dispatch = useDispatch();
  const exportTasacion = useSelector((state) => state.app.exportTasacion);
  const [isError, setIsError] = useState(false);
  const [tasacionData, setTasacionData] = useState(null);
  const requestStateValoresPreliminaresTasacion = useSelector(
    (state) => state.app.requestStateValoresPreliminaresTasacion
  );
  const requestStateTasacion = useSelector(
    (state) => state.app.requestStateTasacion
  );
  const valoresPreliminaresTasacion = useSelector(
    (state) =>
      state.app.valoresPreliminaresTasacion &&
      state.app.valoresPreliminaresTasacion.data
  );
  const resultadoTasacion = useSelector(
    (state) => state.app.resultadoTasacion && state.app.resultadoTasacion.data
  );
  const propiedadesSimilaresTasacion = useSelector(
    (state) =>
      state.app.propiedadesSimilaresTasacion &&
      state.app.propiedadesSimilaresTasacion.results
  );
  const requestStatePropiedadesSimilaresTasacion = useSelector(
    (state) => state.app.requestStatePropiedadesSimilaresTasacion
  );
  const errorMessage = useSelector((state) => state.app.errorMessage);
  const [tipoVivienda, setTipoVivienda] = useState(null);
  const [casa, setCasa] = useState(false);
  const [departamento, setDepartamento] = useState(false);
  const onChangeDepartamento = (e) => {
    setDepartamento(e.target.checked);

    if (e.target.checked) {
      setTipoVivienda("Departamento");
    }

    setCasa(false);
  };
  const onChangeCasa = (e) => {
    setCasa(e.target.checked);
    if (e.target.checked) {
      setTipoVivienda("Casa");
    }
    setDepartamento(false);
  };
  const [comuna, setComuna] = useState("");
  const onChangeComuna = (e) => {
    setComuna(e.target.value);
    setBarrio("");
    setReady(false);
  };
  const [barrio, setBarrio] = useState("");
  const onChangeBarrio = (e) => {
    setBarrio(e.target.value);
  };
  const [esVenta, setEsVenta] = useState(false);
  const [esArriendo, setEsArriendo] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [ready, setReady] = useState(false);

  useEffect(() => {
    dispatch(setValoresPreliminaresTasacion(null));
  }, []);

  useEffect(() => {
    if (requestStateTasacion === "LOADING") {
      setIsLoading(true);
    } else {
      setIsLoading(false);
    }
    if (requestStateTasacion === "SUCCESS") {
      setReady(true);
    }
  }, [requestStateTasacion]);

  useEffect(() => {
    if (requestStateValoresPreliminaresTasacion === "ERROR") {
      setIsError(true);
    } else if (requestStateValoresPreliminaresTasacion === "SUCCESS") {
      setIsError(false);
    } else {
    }
  }, [requestStateValoresPreliminaresTasacion]);

  useEffect(() => {
    const data = {
      comuna,
      sector: barrio,
      tipoVivienda,
      numeroDormitorios: 0,
      numeroEstacionamientos: 0,
      metrosUtiles: 0,
      metrosTotales: 0,
    };

    if (
      data.comuna !== null &&
      data.sector !== null &&
      data.sector !== "" &&
      tipoVivienda !== null &&
      (esVenta || esArriendo)
    ) {
      dispatch(
        postGetValoresPreliminaresTasacion(data, esVenta ? "venta" : "arriendo")
      );
    }
  }, [barrio, tipoVivienda, esVenta]);

  const onClickArriendo = () => {
    setEsArriendo(true);
    setEsVenta(false);
  };

  const onClickVenta = () => {
    setEsArriendo(false);
    setEsVenta(true);
  };

  if (requestStateTasacion === "ERROR") {
    if (errorMessage !== null && errorMessage.includes("resultados")) {
      Swal.fire({
        title: "Sin resultados",
        text: errorMessage,
        icon: "warning",
      });
    } else {
      Swal.fire({
        title: "Error",
        text: errorMessage,
        icon: "error",
      });
    }
  }

  const handleSubmit = (formValues) => {
    const data = {
      comuna,
      sector: barrio,
      tipoVivienda,
      numeroDormitorios: formValues.dormitorios,
      numeroEstacionamientos: formValues.estacionamientos,
      metrosUtiles: formValues.metrosUtiles,
      metrosTotales: formValues.metrosTotales,
    };
    dispatch(postGetTasacion(esVenta ? "venta" : "arriendo", data));
    dispatch(
      postGetPropiedadesSimilaresTasacion(
        esVenta ? "venta" : "arriendo",
        data,
        5,
        0
      )
    );
    const dataTasacion = {
      comuna,
      sector: barrio,
      tipoVivienda,
      numeroBodegas: formValues.bodega,
      numeroBanios: formValues.banos,
      numeroDormitorios: formValues.dormitorios,
      numeroEstacionamientos: formValues.estacionamientos,
      metrosUtiles: formValues.metrosUtiles,
      metrosTotales: formValues.metrosTotales,
      bodega: formValues.bodega,
      banos: formValues.banos,
      direccion: formValues.direccion,
      tipoMoneda: esVenta ? "UF" : "CLP",
      tipoOperacion: esVenta ? "VENTA" : "ARRIENDO",
    };
    setTasacionData(dataTasacion);
  };

  const descargar = () => {
    dispatch(exportarTasacion(tasacionData, esVenta ? "venta" : "arriendo"));
  };

  useEffect(() => {
    if (exportTasacion !== null && exportTasacion !== undefined) {
      const url = window.URL.createObjectURL(new Blob([exportTasacion]));
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", `Resultado tasacion.pdf`);
      document.body.appendChild(link);
      link.click();
      dispatch(setExportTasacion(null));
    }
  }, [exportTasacion]);

  const getComunas = () => {
    let comunasOptions = Object.keys(BARRIOS_COMUNAS);

    comunasOptions.sort(function (a, b) {
      if (a < b) {
        return -1;
      }
      if (a > b) {
        return 1;
      }
      return 0;
    });
    return comunasOptions;
  };

  return (
    <div className="contenido">
      <div className="header">
        <h2>Tasación</h2>
      </div>

      <div className="main">
        {ready ? (
          <div>
            {requestStatePropiedadesSimilaresTasacion === "LOADING" && (
              <CircularProgress />
            )}
            {resultadoTasacion &&
              requestStatePropiedadesSimilaresTasacion !== "LOADING" && (
                <div>
                  <Grid container spacing={2} sx={{ mb: 2 }}>
                    <Grid
                      item
                      xs={12}
                      sm={12}
                      md={6}
                      lg={6}
                      xl={6}
                      className="full-height"
                    >
                      <Grid container spacing={2}>
                        <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                          <Card sx={{ minWidth: 275, pl: 2, pr: 2 }}>
                            <CardContent>
                              <Grid
                                container
                                spacing={2}
                                sx={{ mb: 2 }}
                                justifyContent="space-between"
                              >
                                <Grid
                                  item
                                  xs={12}
                                  sm={12}
                                  md={12}
                                  lg={12}
                                  xl={12}
                                  className="metrica-title"
                                >
                                  ESTIMACIÓN VALOR DE{" "}
                                  {esVenta ? "VENTA UF" : "ARRIENDO CLP"}
                                </Grid>
                                <Grid
                                  item
                                  xs={12}
                                  sm={12}
                                  md={12}
                                  lg={12}
                                  xl={12}
                                >
                                  <Grid container spacing={2}>
                                    <Grid
                                      item
                                      xs={12}
                                      sm={12}
                                      md={4}
                                      lg={4}
                                      xl={4}
                                    >
                                      {resultadoTasacion &&
                                        resultadoTasacion.valorMinimo}
                                    </Grid>
                                    <Grid
                                      item
                                      xs={12}
                                      sm={12}
                                      md={4}
                                      lg={4}
                                      xl={4}
                                    >
                                      -
                                    </Grid>
                                    <Grid
                                      item
                                      xs={12}
                                      sm={12}
                                      md={4}
                                      lg={4}
                                      xl={4}
                                    >
                                      {resultadoTasacion &&
                                        resultadoTasacion.valorMaximo}
                                    </Grid>
                                  </Grid>
                                </Grid>
                              </Grid>
                            </CardContent>
                          </Card>
                        </Grid>
                        {esVenta && (
                          <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                            <Card sx={{ minWidth: 275, pl: 2, pr: 2 }}>
                              <CardContent>
                                <Grid
                                  container
                                  spacing={2}
                                  sx={{ mb: 2 }}
                                  justifyContent="space-between"
                                >
                                  <Grid
                                    item
                                    xs={12}
                                    sm={12}
                                    md={12}
                                    lg={12}
                                    xl={12}
                                    className="metrica-title"
                                  >
                                    VALOR VENTA INMEDIATA CON PLAN FAST
                                  </Grid>
                                  <Grid
                                    item
                                    xs={12}
                                    sm={12}
                                    md={12}
                                    lg={12}
                                    xl={12}
                                  >
                                    <Grid container spacing={2}>
                                      <Grid
                                        item
                                        xs={12}
                                        sm={12}
                                        md={4}
                                        lg={4}
                                        xl={4}
                                      >
                                        {resultadoTasacion &&
                                          resultadoTasacion.valorFastMinimo}
                                      </Grid>
                                      <Grid
                                        item
                                        xs={12}
                                        sm={12}
                                        md={4}
                                        lg={4}
                                        xl={4}
                                      >
                                        -
                                      </Grid>
                                      <Grid
                                        item
                                        xs={12}
                                        sm={12}
                                        md={4}
                                        lg={4}
                                        xl={4}
                                      >
                                        {resultadoTasacion &&
                                          resultadoTasacion.valorFastMaximo}
                                      </Grid>
                                    </Grid>
                                  </Grid>
                                </Grid>
                              </CardContent>
                            </Card>
                          </Grid>
                        )}
                        <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                          <Card sx={{ minWidth: 275, pl: 2, pr: 2 }}>
                            <CardContent>
                              <Grid
                                container
                                spacing={2}
                                justifyContent="space-between"
                              >
                                <Grid
                                  item
                                  xs={12}
                                  sm={12}
                                  md={12}
                                  lg={12}
                                  xl={12}
                                  className="metrica-title"
                                >
                                  VALOR POR UF/M2
                                </Grid>
                                <Grid
                                  item
                                  xs={12}
                                  sm={12}
                                  md={12}
                                  lg={12}
                                  xl={12}
                                >
                                  <Grid container spacing={2}>
                                    <Grid
                                      item
                                      xs={12}
                                      sm={12}
                                      md={12}
                                      lg={12}
                                      xl={12}
                                    >
                                      {resultadoTasacion &&
                                        resultadoTasacion.valorPromedioUFM2}
                                    </Grid>
                                  </Grid>
                                </Grid>
                              </Grid>
                            </CardContent>
                          </Card>
                        </Grid>
                      </Grid>
                    </Grid>
                    <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                      <Grid container className="full-height">
                        <Grid
                          item
                          xs={12}
                          sm={12}
                          md={12}
                          lg={12}
                          xl={12}
                          sx={{ height: "100%" }}
                        >
                          <Card
                            sx={{ minWidth: 275, pl: 2, pr: 2, height: "100%" }}
                          >
                            <CardContent sx={{ height: "100%" }}>
                              <Grid
                                container
                                sx={{ height: "100%" }}
                                justifyContent="space-between"
                              >
                                <Grid
                                  item
                                  xs={12}
                                  sm={12}
                                  md={12}
                                  lg={12}
                                  xl={12}
                                  className="metrica-title"
                                >
                                  DATOS DE LA PROPIEDAD TASADA
                                </Grid>
                                <Grid
                                  item
                                  xs={12}
                                  sm={12}
                                  md={12}
                                  lg={12}
                                  xl={12}
                                >
                                  <Grid container>
                                    <Grid
                                      item
                                      xs={12}
                                      sm={12}
                                      md={6}
                                      lg={6}
                                      xl={6}
                                      className="alignLeft"
                                    >
                                      TIPO PROPIEDAD: {tipoVivienda}
                                    </Grid>
                                    <Grid
                                      item
                                      xs={12}
                                      sm={12}
                                      md={6}
                                      lg={6}
                                      xl={6}
                                      className="alignLeft"
                                    >
                                      DIRECCION:{" "}
                                      {tasacionData && tasacionData.direccion}
                                    </Grid>
                                  </Grid>
                                </Grid>
                                <Grid
                                  item
                                  xs={12}
                                  sm={12}
                                  md={12}
                                  lg={12}
                                  xl={12}
                                  className="metrica-title"
                                >
                                  CARACTERISTICAS DE LA PROPIEDAD TASADA
                                </Grid>
                                <Grid
                                  item
                                  xs={12}
                                  sm={12}
                                  md={12}
                                  lg={12}
                                  xl={12}
                                >
                                  <Grid container>
                                    <Grid
                                      item
                                      xs={12}
                                      sm={12}
                                      md={6}
                                      lg={6}
                                      xl={6}
                                      className="alignLeft"
                                    >
                                      DORMITORIOS:{" "}
                                      {tasacionData &&
                                        tasacionData.numeroDormitorios}
                                    </Grid>
                                    <Grid
                                      item
                                      xs={12}
                                      sm={12}
                                      md={6}
                                      lg={6}
                                      xl={6}
                                      className="alignLeft"
                                    >
                                      BODEGA:{" "}
                                      {tasacionData && tasacionData.bodega}
                                    </Grid>
                                  </Grid>
                                </Grid>
                                <Grid
                                  item
                                  xs={12}
                                  sm={12}
                                  md={12}
                                  lg={12}
                                  xl={12}
                                >
                                  <Grid container>
                                    <Grid
                                      item
                                      xs={12}
                                      sm={12}
                                      md={6}
                                      lg={6}
                                      xl={6}
                                      className="alignLeft"
                                    >
                                      BAÑOS:{" "}
                                      {tasacionData && tasacionData.banos}
                                    </Grid>
                                    <Grid
                                      item
                                      xs={12}
                                      sm={12}
                                      md={6}
                                      lg={6}
                                      xl={6}
                                      className="alignLeft"
                                    >
                                      M2 TOTALES:{" "}
                                      {tasacionData &&
                                        tasacionData.metrosTotales}
                                    </Grid>
                                  </Grid>
                                </Grid>
                                <Grid
                                  item
                                  xs={12}
                                  sm={12}
                                  md={12}
                                  lg={12}
                                  xl={12}
                                >
                                  <Grid container>
                                    <Grid
                                      item
                                      xs={12}
                                      sm={12}
                                      md={6}
                                      lg={6}
                                      xl={6}
                                      className="alignLeft"
                                    >
                                      ESTACIONAMIENTOS:{" "}
                                      {tasacionData &&
                                        tasacionData.numeroEstacionamientos}
                                    </Grid>
                                    <Grid
                                      item
                                      xs={12}
                                      sm={12}
                                      md={6}
                                      lg={6}
                                      xl={6}
                                      className="alignLeft"
                                    >
                                      M2 UTILES:{" "}
                                      {tasacionData &&
                                        tasacionData.metrosUtiles}
                                    </Grid>
                                  </Grid>
                                </Grid>
                              </Grid>
                            </CardContent>
                          </Card>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid container>
                    <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                      <Card
                        sx={{ minWidth: 275, pl: 2, pr: 2, height: "100%" }}
                      >
                        <CardContent sx={{ height: "100%" }}>
                          <Table borderless>
                            <thead className="paraTabla">
                              <tr>
                                <th>Barrio, comuna</th>
                                <th>Superficie util</th>
                                <th>Superficie total</th>
                                <th>Precio</th>
                              </tr>
                            </thead>

                            <tbody className="paraTabla">
                              {propiedadesSimilaresTasacion &&
                                propiedadesSimilaresTasacion.length > 0 &&
                                propiedadesSimilaresTasacion.map((m) => (
                                  <tr>
                                    <td>
                                      {m.barrio}, {m.comuna}
                                    </td>
                                    <td>{m.superficieUtil}</td>
                                    <td>{m.superficieTotal}</td>
                                    <td>{m.precio}</td>
                                  </tr>
                                ))}
                            </tbody>
                          </Table>
                        </CardContent>
                      </Card>
                    </Grid>
                    <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                      <Button
                        type="button"
                        className="main-btn"
                        variant="contained"
                        color="success"
                        sx={{ color: "white" }}
                        onClick={() => descargar()}
                      >
                        Descargar
                      </Button>
                    </Grid>
                  </Grid>
                </div>
              )}
          </div>
        ) : (
          <Grid container>
            <Grid item xs={12} sm={12} md={8} lg={8} xl={6}>
              {isLoading && (
                <Backdrop
                  sx={{
                    color: "#fff",
                    zIndex: (theme) => theme.zIndex.drawer + 1,
                  }}
                  open={true}
                  //onClick={handleCloseBackdrop}
                >
                  <CircularProgress color="inherit" />
                </Backdrop>
              )}
              <Formik
                initialValues={{
                  direccion: "",
                  dormitorios: "",
                  banos: "",
                  metrosUtiles: "",
                  metrosTotales: "",
                  estacionamientos: "",
                  bodega: "",
                  departamento,
                  casa,
                }}
                //validate={validate}
                onSubmit={handleSubmit}
              >
                {({
                  values,
                  errors,
                  touched,
                  handleChange,
                  handleBlur,
                  handleSubmit,
                  setFieldValue,
                  isSubmitting,
                }) => (
                  <>
                    <Form onSubmit={handleSubmit} className="form-container">
                      <Grid container justifyContent={"space-around"}>
                        <Grid item xs={12} sm={6} md={4} lg={4} xl={4}>
                          <button
                            type="button"
                            className={`btn-arrendar-vender-base btn-arrendar-vender-${
                              esArriendo ? "active" : "inactive"
                            }`}
                            onClick={onClickArriendo}
                          >
                            Arrendar
                          </button>
                        </Grid>
                        <Grid item xs={12} sm={6} md={4} lg={4} xl={4}>
                          <button
                            type="button"
                            className={`btn-arrendar-vender-base btn-arrendar-vender-${
                              esVenta ? "active" : "inactive"
                            }`}
                            onClick={onClickVenta}
                          >
                            Vender
                          </button>
                        </Grid>
                      </Grid>
                      <Grid container justifyContent={"space-around"}>
                        <Grid item xs={12} sm={6} md={4} lg={4} xl={4}>
                          <FormGroup>
                            <FormControlLabel
                              control={
                                <Checkbox
                                  checked={casa}
                                  onChange={onChangeCasa}
                                  name="casa"
                                  size="small"
                                />
                              }
                              label="Casa"
                            />
                          </FormGroup>
                        </Grid>
                        <Grid item xs={12} sm={6} md={4} lg={4} xl={4}>
                          <FormGroup>
                            <FormControlLabel
                              control={
                                <Checkbox
                                  checked={departamento}
                                  onChange={onChangeDepartamento}
                                  name="departamento"
                                  size="small"
                                />
                              }
                              label="Departamento"
                            />
                          </FormGroup>
                        </Grid>
                      </Grid>

                      <Grid container>
                        <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                          <label className="milabel">Comuna</label>
                          <Field
                            name="comuna"
                            as="select"
                            value={comuna}
                            onChange={onChangeComuna}
                            className="miSelect"
                          >
                            <option value={""}>- Seleccione -</option>
                            <optgroup label="Región metropolitana">
                              {getComunas()
                                .filter(
                                  (b) => b != "Quilpué" && b != "Villa Alemana"
                                )
                                .map((c) => (
                                  <option value={c}>{c}</option>
                                ))}
                            </optgroup>
                            <optgroup label="Región de Valparaíso">
                              {getComunas()
                                .filter(
                                  (b) => b == "Quilpué" || b == "Villa Alemana"
                                )
                                .map((c) => (
                                  <option value={c}>{c}</option>
                                ))}
                            </optgroup>
                          </Field>
                        </Grid>
                      </Grid>
                      <Grid container>
                        <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                          <label className="milabel">Dirección</label>
                          <Field
                            name="direccion"
                            type="text"
                            className="miSelect"
                          />
                        </Grid>
                      </Grid>

                      <Grid container>
                        {comuna !== "" && comuna !== null && (
                          <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                            <label className="milabel">Barrio</label>
                            <Field
                              name="comuna"
                              as="select"
                              value={barrio}
                              onChange={onChangeBarrio}
                              className="miSelect"
                            >
                              <option value={""}>- Seleccione -</option>
                              {comuna &&
                                BARRIOS_COMUNAS[comuna].map((c) => (
                                  <option value={c}>{c}</option>
                                ))}
                            </Field>
                          </Grid>
                        )}
                      </Grid>
                      {requestStateValoresPreliminaresTasacion ===
                        "LOADING" && <CircularProgress />}
                      {isError && (
                        <div>
                          No se han encontrado valores para tasar la propiedad
                          ingresada
                        </div>
                      )}
                      {requestStateValoresPreliminaresTasacion !== "LOADING" &&
                        requestStateValoresPreliminaresTasacion !== "ERROR" &&
                        !isError &&
                        valoresPreliminaresTasacion && (
                          <div>
                            <Grid container spacing={2}>
                              <Grid item xs={12} sm={6} md={6} lg={6} xl={6}>
                                <label className="milabel">
                                  Superficie total
                                </label>
                                <Field
                                  name="metrosTotales"
                                  type="text"
                                  className="miSelect"
                                  placeholder={`Ingrese valor entre ${valoresPreliminaresTasacion.minMetrosTotales} y ${valoresPreliminaresTasacion.maxMetrosTotales}`}
                                />
                              </Grid>
                              <Grid item xs={12} sm={6} md={6} lg={6} xl={6}>
                                <label className="milabel">
                                  Superficie Util
                                </label>
                                <Field
                                  name="metrosUtiles"
                                  type="text"
                                  className="miSelect"
                                />
                              </Grid>
                            </Grid>
                            <Grid container spacing={2}>
                              <Grid item xs={12} sm={6} md={6} lg={6} xl={6}>
                                <label className="milabel">Piso</label>
                                <Field
                                  name="piso"
                                  type="text"
                                  className="miSelect"
                                />
                              </Grid>
                              <Grid item xs={12} sm={6} md={6} lg={6} xl={6}>
                                <label className="milabel">Rol</label>
                                <Field
                                  name="rol"
                                  type="text"
                                  className="miSelect"
                                />
                              </Grid>
                            </Grid>
                            <Grid container spacing={2}>
                              <Grid item xs={12} sm={6} md={6} lg={6} xl={6}>
                                <label className="milabel">Dormitorios</label>
                                <Field
                                  name="dormitorios"
                                  type="text"
                                  className="miSelect"
                                  placeholder={`Ingrese valor entre ${valoresPreliminaresTasacion.minDormitorios} y ${valoresPreliminaresTasacion.maxDormitorios}`}
                                />
                              </Grid>
                              <Grid item xs={12} sm={6} md={6} lg={6} xl={6}>
                                <label className="milabel">Baños</label>
                                <Field
                                  name="banos"
                                  type="text"
                                  className="miSelect"
                                />
                              </Grid>
                            </Grid>
                            <Grid container spacing={2}>
                              <Grid item xs={12} sm={6} md={6} lg={6} xl={6}>
                                <label className="milabel">
                                  Estacionamientos
                                </label>
                                <Field
                                  name="estacionamientos"
                                  type="text"
                                  className="miSelect"
                                />
                              </Grid>
                              <Grid item xs={12} sm={6} md={6} lg={6} xl={6}>
                                <label className="milabel">Bodega</label>
                                <Field
                                  name="bodega"
                                  type="text"
                                  className="miSelect"
                                />
                              </Grid>
                            </Grid>
                            <Grid
                              container
                              justifyContent={"space-around"}
                              sx={{ mt: 2 }}
                            >
                              <Grid item xs={12} sm={6} md={6} lg={6} xl={6}>
                                <Button
                                  type="submit"
                                  className="main-btn"
                                  variant="contained"
                                  color="success"
                                >
                                  Tasar
                                </Button>
                              </Grid>
                            </Grid>
                          </div>
                        )}
                    </Form>
                  </>
                )}
              </Formik>
            </Grid>
          </Grid>
        )}
      </div>
    </div>
  );
};

export default Tasacion;
