import { CircularProgress, Grid } from "@mui/material";
import React, { useEffect, useState } from "react";
import moment from "moment";
import { useDispatch, useSelector } from "react-redux";
import {
  getClientesPaginados,
  getPropiedadesPaginadas,
  getProspectos,
} from "../../action";
import utilsFunc from "../../utils/utilsFunctions";

const currentMonth = moment().get("month");
const currentYear = moment().get("year");

const pastDate = moment().subtract(1, "months");

const Metricas = () => {
  const dispatch = useDispatch();
  const [isLoading, setIsLoading] = useState(false);
  const [metricas, setMetricas] = useState({});
  const propietarios = useSelector(
    (state) => state.app.clientes && state.app.clientes.results
  );
  const [prospectos, setProspectos] = useState([]);
  const requestGetPropietarios = useSelector(
    (state) => state.app.requestGetClientes && state.app.requestGetClientes
  );
  let propiedades = useSelector(
    (state) =>
      state.app.propiedadesPaginadas && state.app.propiedadesPaginadas.results
  );
  const requestPropiedadesPaginadas = useSelector(
    (state) => state.app.requestPropiedadesPaginadas
  );
  const prospectosCA = useSelector(
    (state) => state.app.prospectosCA && state.app.prospectosCA.results
  );
  const requestGetProspectosCA = useSelector(
    (state) => state.app.requestGetProspectosCA
  );
  const prospectosVA = useSelector(
    (state) => state.app.prospectosCA && state.app.prospectosVA.results
  );
  const requestGetProspectosVA = useSelector(
    (state) => state.app.requestGetProspectosVA
  );

  const userData = useSelector((state) => state.app.itemUsuario);
  const idBroker =
    userData && userData.tipoCuenta === 10 ? userData.userId : "";
  const { formatToThousandSeparator } = utilsFunc;

  useEffect(() => {
    dispatch(
      getPropiedadesPaginadas(
        "",
        idBroker,
        0,
        999,
        "todos",
        "createdAtDescending",
        "",
        "",
        "",
        0,
        0,
        0,
        0,
        0,
        0,
        0,
        0,
        0,
        0,
        "",
        "",
        ""
      )
    );
    /*
    dispatch(getProspectos("ca", 0, 999, "", "", "", 0, 0));
    dispatch(getProspectos("va", 0, 999, "", "", "", 0, 0));
    dispatch(getClientesPaginados(0, 999, "true"));
    */
  }, []);

  useEffect(() => {
    if (
      requestPropiedadesPaginadas === "LOADING" ||
      requestGetProspectosVA === "LOADING" ||
      requestGetProspectosCA === "LOADING"
    ) {
      setIsLoading(true);
    } else {
      setIsLoading(false);
    }
  }, [
    requestPropiedadesPaginadas,
    requestGetProspectosVA,
    requestGetProspectosCA,
  ]);

  useEffect(() => {
    let comunasArray = [];
    let propiedadesCurrentMonth = [];
    let propiedadesPastMonth = [];
    let propiedadesCurrentMonthVenta = [];
    let propiedadesCurrentMonthArriendo = [];
    let propiedadesVenta = [];
    let propiedadesArriendo = [];
    let propiedadesCerradas = [];
    let comisiones = 0;

    if (
      propiedades &&
      propiedades.length > 0 &&
      requestPropiedadesPaginadas !== "LOADING"
    ) {
      propiedades.forEach((p) => {
        if (!comunasArray.some((c) => c.name === p.comuna)) {
          comunasArray.push({
            name: p.comuna,
            count: 1,
          });
        } else {
          let obj = comunasArray.find((c) => c.name === p.comuna);
          let index = comunasArray.indexOf(obj);
          obj.count = obj.count + 1;
          comunasArray[index] = obj;
        }

        const fechaCreacion = moment(p.createdAt.split("T")[0]);
        if (
          fechaCreacion.month() === currentMonth &&
          fechaCreacion.year() === currentYear
        ) {
          propiedadesCurrentMonth.push(p);
          if (p.planContratado !== null) {
            if (p.planContratado.esVenta) {
              propiedadesCurrentMonthVenta.push(p);
            } else {
              propiedadesCurrentMonthArriendo.push(p);
            }
          }
        }

        if (
          fechaCreacion.month() === pastDate.month() &&
          fechaCreacion.year() === pastDate.year()
        ) {
          propiedadesPastMonth.push(p);
        }

        if (p.planContratado !== null) {
          if (p.planContratado.esVenta) {
            propiedadesVenta.push(p);
          } else {
            propiedadesArriendo.push(p);
          }
        }

        const fechaTermino = moment(p.fechaTermino.split("T")[0]);
        if (fechaTermino.year().toString().includes("20")) {
          if (
            fechaTermino.month() === currentMonth &&
            fechaTermino.year() === currentYear
          ) {
            propiedadesCerradas.push(p);
            if (p.planContratado !== null) {
              if (p.planContratado.esVenta) {
                comisiones = comisiones + p.valorCompraPropiedad * 0.5;
              } else {
                comisiones = comisiones + p.valorCompraPropiedad * 0.5;
              }
            }
          }
        }
      });
    }
    let mejoraCaptacionPropiedades =
      propiedadesCurrentMonth.length - propiedadesPastMonth.length;

    setMetricas({
      ...metricas,
      comisiones,
      propiedadesArriendo,
      propiedadesVenta,
      propiedadesCerradas,
      mejoraCaptacionPropiedades: `${mejoraCaptacionPropiedades}`,
      propiedadesCaptadasCurrentMonth: propiedadesCurrentMonth,
      propiedadesCaptadasPastMonth: propiedadesPastMonth,
      propiedadesCaptadasCurrentMonthVenta: propiedadesCurrentMonthVenta,
      propiedadesCaptadasCurrentMonthArriendo: propiedadesCurrentMonthArriendo,
    });
    //setComunas(comunasArray);
  }, [propiedades, requestPropiedadesPaginadas]);

  useEffect(() => {
    let prospectosCurrentMonth = [];
    let prospectosPastMonth = [];
    let prospectosCAcaptados = [];
    let prospectosVAcaptados = [];
    var prospectosUnion = [...new Set([...prospectosCA, ...prospectosVA])];
    setProspectos(prospectosUnion);
    if (
      prospectosUnion &&
      prospectosUnion.length > 0 &&
      requestGetProspectosCA !== "LOADING" &&
      requestGetProspectosVA !== "LOADING"
    ) {
      prospectosUnion.forEach((p) => {
        const fechaCreacion = moment(p.createdAt.split("T")[0]);
        if (
          fechaCreacion.month() === currentMonth &&
          fechaCreacion.year() === currentYear
        ) {
          prospectosCurrentMonth.push(p);

          if (p.tipoPropiedad !== null) {
            prospectosVAcaptados.push(p);
          } else {
            prospectosCAcaptados.push(p);
          }
        }

        if (
          fechaCreacion.month() === pastDate.month() &&
          fechaCreacion.year() === pastDate.year()
        ) {
          prospectosPastMonth.push(p);
        }
      });
    }
    let mejoraCaptacionProspectos =
      prospectosCurrentMonth.length - prospectosPastMonth.length;

    setMetricas({
      ...metricas,
      mejoraCaptacionProspectos: `${mejoraCaptacionProspectos}`,
      prospectosCAcaptados,
      prospectosVAcaptados,
      prospectosCaptadosCurrentMonth: prospectosCurrentMonth,
      prospectosCaptadosPastMonth: prospectosPastMonth,
    });
  }, [
    prospectosCA,
    prospectosVA,
    requestGetProspectosCA,
    requestGetProspectosVA,
  ]);

  useEffect(() => {
    let prospectosPasadosClienteCurrentMonth = [];
    let prospectosPasadosClientePastMonth = [];
    let prospectosPasadosCliente = [];

    if (
      prospectos &&
      prospectos.length > 0 &&
      propietarios &&
      propietarios.length > 0 &&
      requestGetProspectosCA !== "LOADING" &&
      requestGetProspectosVA !== "LOADING" &&
      requestGetPropietarios !== "LOADING"
    ) {
      propietarios.forEach((p) => {
        const fechaCreacion = moment(p.createdAt.split("T")[0]);
        if (prospectos.some((x) => x.email === p.mail)) {
          prospectosPasadosCliente.push(p);
        }
        if (
          fechaCreacion.month() === currentMonth &&
          fechaCreacion.year() === currentYear
        ) {
          if (prospectos.some((x) => x.email === p.mail)) {
            prospectosPasadosClienteCurrentMonth.push(p);
          }
        }

        if (
          fechaCreacion.month() === pastDate.month() &&
          fechaCreacion.year() === pastDate.year()
        ) {
          if (prospectos.some((x) => x.email === p.mail)) {
            prospectosPasadosClientePastMonth.push(p);
          }
        }
      });
    }
    /*
    let mejoraCaptacionProspectos = 
        prospectosCurrentMonth.length - prospectosPastMonth.length;
    */
    setMetricas({
      ...metricas,
      //mejoraCaptacionProspectos,
      prospectosPasadosCliente,
      prospectosPasadosClientePastMonth,
      prospectosPasadosClienteCurrentMonth,
    });
  }, [prospectos]);

  return (
    <>
      <div className="header">
        <h2>Métricas</h2>
      </div>
      <div className="sobrePropiedades">
        <h3 className="alignLeft" style={{ marginBottom: "20px" }}>
          Métricas generales
        </h3>
        {isLoading && <CircularProgress />}
        {metricas && !isLoading && (
          <Grid container>
            <Grid item xs={12} sm={12} md={9} lg={9} xl={9}>
              <Grid container spacing={2} sx={{ mb: 2 }}>
                <Grid item xs={12} sm={6} md={4} lg={4} xl={4}>
                  <div className="metrica">
                    <div className="middle">
                      <div className="metrica-title">Propiedades captadas</div>
                      <h3 className="metrica-subtitle">
                        {metricas.mejoraCaptacionPropiedades > 0 && (
                          <div>
                            Captaste {metricas.mejoraCaptacionPropiedades} más
                            propiedades que el mes anterior
                          </div>
                        )}
                        {metricas.mejoraCaptacionPropiedades === 0 && (
                          <div>
                            No hubo mejora en tus captaciones respecto al mes
                            anterior
                          </div>
                        )}
                        {metricas.mejoraCaptacionPropiedades < 0 && (
                          <div>
                            Captaste {metricas.mejoraCaptacionPropiedades} menos
                            propiedades que el mes anterior
                          </div>
                        )}
                      </h3>
                      <div className="metrica-value">
                        {metricas.propiedadesCaptadasCurrentMonth &&
                          metricas.propiedadesCaptadasCurrentMonth.length}
                      </div>
                    </div>
                  </div>
                </Grid>
                <Grid item xs={12} sm={6} md={4} lg={4} xl={4}>
                  <div className="metrica">
                    <div className="middle">
                      <div className="metrica-title">Propiedades captadas</div>
                      <div className="metrica-subtitle">Venta</div>
                      <div className="metrica-value">
                        {metricas.propiedadesCaptadasCurrentMonthVenta &&
                          metricas.propiedadesCaptadasCurrentMonthVenta.length}
                      </div>
                    </div>
                  </div>
                </Grid>
                <Grid item xs={12} sm={6} md={4} lg={4} xl={4}>
                  <div className="metrica">
                    <div className="middle">
                      <div className="metrica-title">Propiedades captadas</div>
                      <div className="metrica-subtitle">Arriendo</div>
                      <div className="metrica-value">
                        {metricas.propiedadesCaptadasCurrentMonthArriendo &&
                          metricas.propiedadesCaptadasCurrentMonthArriendo
                            .length}
                      </div>
                    </div>
                  </div>
                </Grid>
                {/*
              <Grid item xs={12} sm={6} md={4} lg={4} xl={4}>
                <div className="metrica">
                  <div className="middle">
                    <div className="metrica-title">Propiedades</div>
                    <div className="metrica-subtitle">En total</div>
                    <div className="metrica-value">
                      {propiedades && propiedades.length}
                    </div>
                  </div>
                </div>
              </Grid>
              <Grid item xs={12} sm={6} md={4} lg={4} xl={4}>
                <div className="metrica">
                  <div className="middle">
                    <div className="metrica-title">Propiedades</div>
                    <div className="metrica-subtitle">Venta</div>
                    <div className="metrica-value">
                      {metricas.propiedadesVenta &&
                        metricas.propiedadesVenta.length}
                    </div>
                  </div>
                </div>
              </Grid>
              <Grid item xs={12} sm={6} md={4} lg={4} xl={4}>
                <div className="metrica">
                  <div className="middle">
                    <div className="metrica-title">Propiedades</div>
                    <div className="metrica-subtitle">Arriendo</div>
                    <div className="metrica-value">
                      {metricas.propiedadesArriendo &&
                        metricas.propiedadesArriendo.length}
                    </div>
                  </div>
                </div>
              </Grid>
                      */}

                <Grid item xs={12} sm={6} md={4} lg={4} xl={4}>
                  <div className="metrica">
                    <div className="middle">
                      <div className="metrica-title">Prospectos captados</div>
                      <div className="metrica-subtitle">
                        {metricas.mejoraCaptacionProspectos > 0 && (
                          <div>
                            Captaste {metricas.mejoraCaptacionProspectos} más
                            prospectos que el mes anterior
                          </div>
                        )}
                        {metricas.mejoraCaptacionProspectos === 0 && (
                          <div>
                            No hubo mejora en tus captaciones respecto al mes
                            anterior
                          </div>
                        )}
                        {metricas.mejoraCaptacionProspectos < 0 && (
                          <div>
                            Captaste {metricas.mejoraCaptacionProspectos} menos
                            prospectos que el mes anterior
                          </div>
                        )}
                      </div>
                      <div className="metrica-value">
                        {metricas.prospectosCaptadosCurrentMonth &&
                          metricas.prospectosCaptadosCurrentMonth.length}{" "}
                      </div>
                    </div>
                  </div>
                </Grid>
                <Grid item xs={12} sm={6} md={4} lg={4} xl={4}>
                  <div className="metrica">
                    <div className="middle">
                      <div className="metrica-title">Prospectos captados</div>
                      <h3 className="metrica-subtitle">Vendedor/arrendador</h3>
                      <div className="metrica-value">
                        {metricas.prospectosVAcaptados &&
                          metricas.prospectosVAcaptados.length}
                      </div>
                    </div>
                  </div>
                </Grid>
                <Grid item xs={12} sm={6} md={4} lg={4} xl={4}>
                  <div className="metrica">
                    <div className="middle">
                      <div className="metrica-title">Prospectos captados</div>
                      <h3 className="metrica-subtitle">
                        Comprador/arrendatario
                      </h3>
                      <div className="metrica-value">
                        {metricas.prospectosCAcaptados &&
                          metricas.prospectosCAcaptados.length}
                      </div>
                    </div>
                  </div>
                </Grid>
                {/* 
              <Grid item xs={12} sm={6} md={4} lg={4} xl={4}>
                <div className="metrica">
                  <div className="middle">
                    <div className="metrica-title">Prospectos</div>
                    <div className="metrica-subtitle">En total</div>
                    <div className="metrica-value">
                      {prospectosCA &&
                        prospectosVA &&
                        prospectosCA.length + prospectosVA.length}
                    </div>
                  </div>
                </div>
              </Grid>
              <Grid item xs={12} sm={6} md={4} lg={4} xl={4}>
                <div className="metrica">
                  <div className="middle">
                    <div className="metrica-title">Prospectos</div>
                    <h3 className="metrica-subtitle">Vendedor/arrendador</h3>
                    <div className="metrica-value">
                      {prospectosVA && prospectosVA.length}
                    </div>
                  </div>
                </div>
              </Grid>
              <Grid item xs={12} sm={6} md={4} lg={4} xl={4}>
                <div className="metrica">
                  <div className="middle">
                    <div className="metrica-title">Prospectos</div>
                    <h3 className="metrica-subtitle">Comprador/arrendatario</h3>
                    <div className="metrica-value">
                      {prospectosCA && prospectosCA.length}
                    </div>
                  </div>
                </div>
              </Grid>
                      */}

                <Grid item xs={12} sm={6} md={4} lg={4} xl={4}>
                  <div className="metrica">
                    <div className="middle">
                      <div className="metrica-title">Propietarios</div>
                      <div className="metrica-subtitle">En total</div>
                      <div className="metrica-value">
                        {propietarios && propietarios.length}
                      </div>
                    </div>
                  </div>
                </Grid>
                <Grid item xs={12} sm={6} md={4} lg={4} xl={4}>
                  <div className="metrica">
                    <div className="middle">
                      <div className="metrica-title">Propietarios</div>
                      <h3 className="metrica-subtitle">Desde prospectos</h3>
                      <div className="metrica-value">
                        {metricas.prospectosPasadosCliente &&
                          metricas.prospectosPasadosCliente.length}
                      </div>
                    </div>
                  </div>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        )}
        <h3 className="alignLeft" style={{ marginBottom: "20px" }}>
          Métricas de rendimiento
        </h3>
        {metricas && !isLoading && (
          <Grid container>
            <Grid item xs={12} sm={12} md={9} lg={9} xl={9}>
              <Grid container spacing={2} sx={{ mb: 2 }}>
                <Grid item xs={12} sm={6} md={4} lg={4} xl={4}>
                  <div className="metrica">
                    <div className="middle">
                      <div className="metrica-title">Propiedades cerradas</div>
                      <h3 className="metrica-subtitle">Este mes</h3>
                      <div className="metrica-value">
                        {metricas.propiedadesCerradas &&
                          metricas.propiedadesCerradas.length}
                      </div>
                    </div>
                  </div>
                </Grid>
                <Grid item xs={12} sm={6} md={4} lg={4} xl={4}>
                  <div className="metrica">
                    <div className="middle">
                      <div className="metrica-title">Comisiones recaudadas</div>
                      <h3 className="metrica-subtitle">Este mes</h3>
                      <div className="metrica-value">
                        $
                        {metricas.comisiones &&
                          metricas.comisiones !== 0 &&
                          formatToThousandSeparator(metricas.comisiones)}
                      </div>
                    </div>
                  </div>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        )}
      </div>
    </>
  );
};
export default Metricas;
