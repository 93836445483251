import { useDispatch, useSelector } from "react-redux";
import {
  CircularProgress,
  Grid,
  TablePagination,
  Paper,
  Table,
  TableBody,
  TableContainer,
  TableHead,
  TableRow,
  styled,
} from "@mui/material";
import {
  fetchGetAllUsuarios,
  getActividades,
  getProyectosPaginados,
} from "../../action";
import { useEffect, useState } from "react";
import Swal from "sweetalert2";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";

const isMobile =
  !!navigator.userAgent.match(/iphone|android|blackberry/gi) || false;

const columnsNonMobile = [
  { id: "nombre", label: "Nombre", minWidth: 170, align: "center" },
  { id: "inmobiliaria", label: "Inmobiliaria", minWidth: 100, align: "center" },
  { id: "valorDesde", label: "Valor desde", minWidth: 70, align: "center" },
  { id: "destacado", label: "Destacado", minWidth: 70, align: "center" },
  //{ id: "exclusivo", label: "Exclusivo", minWidth: 70, align: "center" },
  { id: "tipo", label: "Tipo", minWidth: 70, align: "center" },
  { id: "comuna", label: "Comuna", minWidth: 70, align: "center" },
  { id: "direccion", label: "Direccion", minWidth: 70, align: "center" },
  { id: "ver", label: "Ver", minWidth: 140, align: "center" },
  { id: "acciones", label: "Acciones", minWidth: 80, align: "center" },
];

const columnsMobile = [
  { id: "nombre", label: "Nombre", minWidth: 90, align: "center" },
  { id: "inmobiliaria", label: "Inmobiliaria", minWidth: 90, align: "center" },
  { id: "acciones", label: "Acciones", minWidth: 1, align: "center" },
];

const columns = isMobile ? columnsMobile : columnsNonMobile;

const StyledTableHead = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: "#00a62b",
    color: theme.palette.common.white,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));

const ListaInmobiliarias = () => {
  const dispatch = useDispatch();
  return (
    <div className="contenido">
      <div className="header">
        <h2>Inmobiliarias</h2>
      </div>
      <Grid container justifyContent="flex-start">
        <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
          Pagina en construccion
        </Grid>
      </Grid>
    </div>
  );
};

export default ListaInmobiliarias;
