import { Formik, Form, Field } from "formik";
import { useDispatch, useSelector } from "react-redux";
import {
  Backdrop,
  Box,
  Button,
  CircularProgress,
  Grid,
  IconButton,
  Modal,
  Tooltip,
  Typography,
} from "@mui/material";
import { ESTADOS_ACTIVIDAD } from "../../utils/constants";
import DatePicker from "react-date-picker";
import { useEffect, useState } from "react";
import {
  getPropiedadesPaginadas,
  postActividad,
  putActividad,
} from "../../action";
import Swal from "sweetalert2";
import FileUpload from "../../assets/FileUpload";
import CloseIcon from "@mui/icons-material/Close";
import { Close } from "@mui/icons-material";
import * as Yup from "yup";
import FieldError from "../../utils/FieldError";

const validate = Yup.object().shape({
  //idBroker: Yup.string().required("Seleccione un broker"),
  titulo: Yup.string().required("Ingrese un titulo"),
});

export const ALLOWED_MIME_TYPES = [
  "image/png",
  "image/x-citrix-png",
  "image/x-png",
  "image/jpeg",
  "image/x-citrix-jpeg",
  "image/pjpeg",
  "application/pdf",
];

const isMobile =
    !!navigator.userAgent.match(/iphone|android|blackberry/gi) || false;

const style = {
  position: "absolute",
  top: "0px",
  left: "50%",
  transform: "translate(-50%, 0%)",
  width: 500,
  bgcolor: "background.paper",
  boxShadow: 24,
  p: 4,
  //maxHeight: '90%'
};

const styleMobile = {
  position: "absolute",
  top: "0px",
  left: "0px",
  //transform: "translate(-50%, 0%)",
  width: "100vw",
  bgcolor: "background.paper",
  boxShadow: 24,
  p: 4,
  //maxHeight: '90%'
};

const FormAgregarActividad = ({
  isOpen,
  handleClose,
  idPropiedad,
  idCliente,
  idProspecto,
  values,
  reloadMethod,
}) => {
  const dispatch = useDispatch();
  const [isLoading, setIsLoading] = useState(false);
  const [fechaActividad, setFechaActividad] = useState(new Date());
  const [archivos, setArchivos] = useState(
    values && values.archivos ? values.archivos : []
  );
  const propiedades = useSelector(
    (state) =>
      state.app.propiedadesPaginadas && state.app.propiedadesPaginadas.results
  );
  const requestPropiedades = useSelector(
    (state) => state.app.requestPropiedadesPaginadas
  );
  const requestPutActividad = useSelector(
    (state) => state.app.requestPutActividad
  );
  const requestPostActividad = useSelector(
    (state) => state.app.requestPostActividad
  );
  const fechaActividadFixed = values ? values.fechaActividad : null;
  const errorMessage = useSelector((state) => state.app.errorMessage);
  useEffect(() => {
    dispatch(
      getPropiedadesPaginadas(
        "",
        "",
        0,
        999,
        "todos",
        "createdAtDescending",
        "",
        "",
        "",
        0,
        0,
        0,
        0,
        0,
        0,
        0,
        0,
        0,
        0,
        "",
        "",
        idCliente
      )
    );
  }, []);

  useEffect(() => {
    if (
      values !== null &&
      values.archivos !== null &&
      values.archivos.length > 0
    ) {
      setArchivos(values.archivos);
    } else {
      setArchivos([]);
    }

    if (fechaActividadFixed !== null) {
      const soloFecha = values.fechaActividad.split("T")[0];
      const partesFecha = soloFecha.split("-");
      const anio = partesFecha[0];
      const mes = partesFecha[1] - 1;
      const dia = partesFecha[2];
      setFechaActividad(new Date(anio, mes, dia));
    }
  }, [values]);

  const fileToBase64 = (file) => {
    if (file) {
      return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => resolve(reader.result);
        reader.onerror = (error) => reject(error);
      });
    }
  };

  const onDrop = (files) => {
    if (files) {
      files.map((f) => {
        if (f !== null) {
          fileToBase64(f).then((file64) => {
            const newFile = {
              name: f.name,
              value: file64,
              deleted: false
            };
            setArchivos((oldValue) => [...oldValue, newFile]);
          });
        }
      });
    }
  };

  const handleSubmit = (formValues) => {
    const data = {
      ...formValues,
      fechaActividad,
      archivos,
    };
    if (values && values.id) {
      dispatch(putActividad(values.id, data));
    } else {
      dispatch(postActividad(data));
    }
  };

  if (requestPutActividad === "SUCCESS") {
    handleClose();
    Swal.fire({
      title: "Actividad actualizada",
      text: "Se ha actualizado la actividad exitosamente",
      icon: "success",
    }).then((result) => {
      if (result.value || result.dismiss === "backdrop") {
        reloadMethod();
      }
    });
  }

  if (requestPostActividad === "SUCCESS") {
    handleClose();
    Swal.fire({
      title: "Actividad agregada",
      text: "Se ha agregado la actividad exitosamente",
      icon: "success",
    }).then((result) => {
      if (result.value || result.dismiss === "backdrop") {
        reloadMethod();
      }
    });
  }

  if (requestPostActividad === "ERROR" || requestPutActividad === "ERROR") {
    Swal.fire({
      title: "Error",
      text: errorMessage,
      icon: "error"
    });
  }

  useEffect(() => {
    if (
      requestPutActividad === "LOADING" ||
      requestPostActividad === "LOADING"
    ) {
      setIsLoading(true);
    } else {
      setIsLoading(false);
    }
  }, [requestPutActividad, requestPostActividad]);

  const [open, setOpen] = useState(false);

  const handleCloseBackdrop = () => {
    setOpen(false);
  };

  const deleteFile = (file) => {
    if (values !== null) {
      let archivo = archivos.find((f) => f.name === file.name);
      archivo.deleted = true;
      setArchivos(archivos.map(a => {return a.name === file.name ? archivo : a}));
    } else {
      setArchivos(archivos.filter(f => f.name !== file.name));
    }
    
  };

  const handleCloseLocal = () => {
    setArchivos([]);
    handleClose();
  };

  return (
    <Modal
      disableScrollLock={false}
      open={isOpen}
      onClose={handleCloseLocal}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"   
      sx={{
        overflowY: "auto",
        top: isMobile ? "0px" : "10px",
        maxHeight: isMobile ? "100%" : "95%",
      }}
    >
      <Box sx={isMobile ? styleMobile : style}>
        {isLoading && (
          <Backdrop
            sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
            open={true}
            onClick={handleCloseBackdrop}
          >
            <CircularProgress color="inherit" />
          </Backdrop>
        )}
        <Grid container justifyContent="space-between" alignItems={"center"} sx={{marginBottom: 2}}>
          <Grid item xs={10} sm={10} md={10} lg={10} xl={10}>
            <h4>Agregar actividad</h4>
          </Grid>
          <Grid item>
            <IconButton
              color="primary"
              aria-label="copiar"
              component="span"
              onClick={() => handleClose()}
            >
              <Close sx={{ fontSize: "20pt" }} />
            </IconButton>
          </Grid>
        </Grid>        
        <Formik
          initialValues={{
            titulo: values && values.titulo,
            descripcion: values && values.descripcion,
            estadoActividad: values && values.estadoActividad ? values.estadoActividad : 1,
            fechaActividad,
            archivos,
            idPropiedad: idPropiedad
              ? idPropiedad
              : values && values.idPropiedad
              ? values.idPropiedad
              : "",
            idCliente: idCliente
              ? idCliente
              : values && values.idCliente
              ? values.idCliente
              : "",
            idProspecto: idProspecto
              ? idProspecto
              : values && values.idProspecto
              ? values.idProspecto
              : "",
            subidoPor: values && values.subidoPor
          }}
          onSubmit={handleSubmit}
          validationSchema={validate}
        >
          {({ values, handleSubmit }) => (
            <Form onSubmit={handleSubmit}>
              <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                {requestPropiedades === "LOADING" && <CircularProgress />}
                {requestPropiedades === "IDLE" && propiedades && (
                  <div>
                    {!idProspecto && (
                      <label className="milabel">Propiedad</label>
                    )}
                    {idPropiedad && !idProspecto && (
                      <Field
                        name="idPropiedad"
                        as="select"
                        className="miSelect"
                        disabled
                      >
                        {propiedades.map((c) => (
                          <option value={c.id}>
                            {c.nombreCalle} {c.numero}
                          </option>
                        ))}
                      </Field>
                    )}
                    {idCliente && !idProspecto && (
                      <Field
                        name="idPropiedad"
                        as="select"
                        className="miSelect"
                      >
                        <option value={""}>- Seleccione -</option>
                        {propiedades.map((c) => (
                          <option value={c.id}>
                            {c.nombreCalle} {c.numero}
                          </option>
                        ))}
                      </Field>
                    )}
                  </div>
                )}
              </Grid>
              <Grid container>
                <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                  <label className="milabel">Titulo</label>
                  <Field name="titulo" type="text" className="miSelect" />
                  <FieldError name="titulo" />
                </Grid>
              </Grid>
              <Grid container>
                <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                  <label className="milabel">Descripcion</label>
                  <Field name="descripcion" type="text" as="textarea" className="miTextarea" />
                </Grid>
              </Grid>
              <Grid container>
                <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                  <label className="milabel">Estado actividad</label>
                  <Field
                    name="estadoActividad"
                    as="select"
                    className="miSelect"
                  >
                    {Object.keys(ESTADOS_ACTIVIDAD).map((c) => (
                      <option value={c}>{ESTADOS_ACTIVIDAD[c]}</option>
                    ))}
                  </Field>
                </Grid>
              </Grid>
              <Grid container justifyContent="flex-start">
                <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                  <label className="milabel">Fecha actividad</label>
                  <DatePicker
                    value={fechaActividad}
                    onChange={setFechaActividad}
                  />
                </Grid>
              </Grid>
              <Grid container justifyContent="flex-start">
                <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                  <label className="milabel">Archivos</label>
                  <FileUpload
                    name="archivos"
                    uploadMultiple={true}
                    acceptedFiles={ALLOWED_MIME_TYPES}
                    onDrop={onDrop}
                    sizeLimit={5000000}
                    maxFiles={30}
                    showFiles={false}
                  />
                </Grid>
              </Grid>
              <Grid container justifyContent="flex-start">
                <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                  <Typography variant="caption" display="block" gutterBottom>
                    Se aceptan imágenes y archivos PDF
                  </Typography>
                </Grid>
              </Grid>

              {archivos &&
                Array.isArray(archivos) &&
                archivos.length > 0 &&
                archivos.map((file) => {
                  if (!file.deleted) {
                    return (
                      <Grid container justifyContent="space-between">
                        <Grid
                          item
                          xs={10}
                          sm={10}
                          md={10}
                          lg={10}
                          xl={10}
                          className="alignLeft"
                        >
                          {file.name}
                        </Grid>
                        <Grid item sx={{ textAlign: "right" }}>
                          <Tooltip title="Eliminar" aria-label="eliminar">
                            <IconButton
                              aria-label="Eliminar"
                              component="span"
                              onClick={() => deleteFile(file)}
                            >
                              <CloseIcon />
                            </IconButton>
                          </Tooltip>
                        </Grid>
                      </Grid>
                    );
                  }
                })}

              <Grid container justifyContent="flex-end" sx={{ mt: 2 }}>
                <Grid item>
                  <Button onClick={handleCloseLocal} className="main-btn">
                    Cancelar
                  </Button>
                </Grid>
                <Grid item>
                  <Button
                    type="submit"
                    className="main-btn"
                    variant="contained"
                    color="success"
                  >
                    Guardar
                  </Button>
                </Grid>
              </Grid>
            </Form>
          )}
        </Formik>
      </Box>
    </Modal>
  );
};

export default FormAgregarActividad;
