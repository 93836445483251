import React from "react";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { Button, Grid } from "@mui/material";
import { useHistory } from "react-router-dom";

const Back = ({ urlPath, onClickMethod }) => {
  const history = useHistory();
  const goBack = () => {
    onClickMethod
      ? onClickMethod()
      : urlPath
      ? history.push(urlPath)
      : history.goBack();
  };
  return (
    <Grid container justifyContent="flex-start" className="alignLeft">
      <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
        <Button
          onClick={goBack}
          className="main-btn back-btn"
          startIcon={<ArrowBackIcon />}
        >
          Atrás
        </Button>
      </Grid>
    </Grid>
  );
};

export default Back;
