import { combineReducers } from "redux";

export const setPropietario = (payload) => ({
  type: "propietario/add",
  payload,
});

export const setPerfil = (payload) => ({
  type: "perfil/set",
  payload,
});

export const setRequerimiento = (payload) => ({
  type: "requerimiento/add",
  payload,
});

export const propietariosReducer = (
  state = [
    {
      id: 1,
      nombre: "Cristobal",
      apellido: "Uriarte",
      tipo: "Natural",
      numeroPropiedades: 2,
      telefono: "968785835",
      mail: "cruriarte@propins.cl",
      plan: "",
    },
    {
      id: 2,
      nombre: "Tony",
      apellido: "Stark",
      tipo: "Avenger",
      numeroPropiedades: 3,
      telefono: "12345678",
      mail: "tstark@propins.cl",
      plan: "",
    },
  ],
  action
) => {
  switch (action.type) {
    case "propietario/add": {
      console.log("agragar propietario");
      return state.concat({ ...action.payload });
    }

    default: {
      return state;
    }
  }
};

export const prospectosReducer = (
  state = [
    {
      id: 1,
      nombre: "Thor",
      apellido: "Hijo de odin",
      tipo: "Semidios",

      telefono: "No tiene",
      mail: "thor@propins.cl",
      plan: "",
      prospecto: "comprador",
    },
    {
      id: 2,
      nombre: "Natasha",
      apellido: "Romanof",
      tipo: "Natural",

      telefono: "10191817",
      mail: "tkamado@propins.cl",
      plan: "",
      prospecto: "comprador",
    },
    {
      id: 3,
      nombre: "Peter",
      apellido: "Parkerr",
      tipo: "Juridica",
      numeroPropiedades: 1,
      telefono: "12341234",
      mail: "pparker@propins.cl",
      plan: "",
      prospecto: "vendedor",
    },
    {
      id: 4,
      nombre: "Tangiro",
      apellido: "Kamado",
      tipo: "Natural",
      numeroPropiedades: 1,
      telefono: "10191817",
      mail: "tkamado@propins.cl",
      plan: "",
      prospecto: "vendedor",
    },
  ],
  action
) => {
  switch (action.type) {
    case "prospecto/add": {
      console.log("agragar propietario");
      return state.concat({ ...action.payload });
    }

    default: {
      return state;
    }
  }
};

export const requerimientosReducer = (
  state = [
    {
      id: 1,
      idProspecto: 1,
      tipo: "departamento",
      region: "metropolitana",
      dormitoriosMin: 1,
      dormitoriosMax: 2,
      m2Totales: "30mt2 - 40mt2",
      m2Utiles: "30mt2-40mt2",
      operacion: "arriendo",
      comuna: ["comuna1", "comuna2", "comuna3"],
      banosMin: 1,
      banosMax: 2,
      precio: 500000,
      estacionamiento: 1,
    },
  ],
  action
) => {
  switch (action.type) {
    case "requerimiento/add": {
      return state.concat({ ...action.payload });
    }

    default: {
      return state;
    }
  }
};

export const misPropiedadesReducer = (
  state = [
    {
      id: "Prop_1",
      idDueno: 2,
      direccion: "Calle de spiderman",
      comuna: "Nueva York",
      region: "USA",
      numero: "1234",
      dormitorios: 3,
      banos: 2,
      tipo: "Depto",
      operacion: "Arriendo",
      precio: "500.000",
      link: "propins.cl",
      plan: "Regular",
      estacionamiento: 2,
      m2Totales: "30mt2 - 40mt2",
      m2Utiles: "30mt2-40mt2",
      confirmada: true,
    },
    {
      id: "Prop_2",
      idDueno: 2,
      direccion: "TorreStark",
      comuna: "Nueva York",
      region: "Metropolitana",
      numero: "4321",
      dormitorios: 10,
      banos: 10,
      tipo: "Depto",
      operacion: "Venta",
      precio: "100.000.000.000",
      link: "propins.cl",
      plan: "Regular",
      estacionamiento: 0,
      m2Totales: "60mt2 - 80mt2",
      m2Utiles: "60mt2-80mt2",
      confirmada: true,
    },
    {
      id: "Prop_3",
      idDueno: 3,
      direccion: "Sucasita",
      comuna: "Nueva York",
      region: "Arica",
      numero: "4321",
      dormitorios: 5,
      banos: 3,
      tipo: "Casa",
      operacion: "Venta",
      precio: "1.000.000.000",
      link: "propins.cl",
      plan: "Regular",
      estacionamiento: 1,
      confirmada: true,
    },
    {
      id: "Prop_4",
      idDueno: 3,
      direccion: "Sucasita2",
      comuna: "San Fransisco",
      region: "De los lagos",
      numero: "1902",
      dormitorios: 2,
      banos: 2,
      tipo: "Depto",
      operacion: "Venta",
      precio: "800.000.000",
      link: "propins.cl",
      plan: "",
      estacionamiento: 1,
      confirmada: false,
    },
    {
      id: "Prop_5",
      idDueno: 4,
      direccion: "Japon",
      comuna: "Tokio",
      numero: "12341",
      dormitorios: 1,
      banos: 1,
      tipo: "Depto",
      operacion: "Venta",
      precio: "500.000.000",
      link: "propins.cl",
      plan: "",
      confirmada: false,
    },
  ],
  action
) => {
  switch (action.type) {
    case "prospecto/add": {
      console.log("agragar propietario");
      return state.concat({ ...action.payload });
    }

    default: {
      return state;
    }
  }
};

export const otrasPropiedadesReducer = (
  state = [
    {
      id: "Prop_4",
      comuna: "Providencia",
      dormitorios: 3,
      banos: 2,
      tipo: "Departamento",
      operacion: "Arriendo",
      precio: "800.000",
      link: "propins.cl",
      broker: "Alicia Rios",
    },
    {
      id: "Prop_5",

      comuna: "La reina",
      dormitorios: 2,
      banos: 1,
      tipo: "Departamento",
      operacion: "Venta",
      precio: "150.000.000",
      link: "propins.cl",
      broker: "Alicia Rios",
    },
  ],
  action
) => {
  switch (action.type) {
    case "prospecto/add": {
      console.log("agragar propietario");
      return state.concat({ ...action.payload });
    }

    default: {
      return state;
    }
  }
};

export const actividadesReducer = (
  state = [
    {
      idDueno: 2,
      id: 1,
      tipo: "reunion",
      titulo: "Reunion inicial para aclarar dudas",
      descripcion: "",
      estado: "Confirmada",
      archivos: ["pdf1", "pdf2"],
      fecha: "20-11-22",
    },
    {
      idDueno: 2,
      id: 2,
      tipo: "mail",
      titulo: "Solicitar documentos necesarios",
      descripcion:
        "Pedirle al cliente que me evnie los documentos para comenzar con el proceso",
      estado: "Pendiente",
      archivos: [],
      fecha: "22-11-22",
    },
  ],
  action
) => {
  switch (action.type) {
    case "actividad/add": {
      console.log("actividad agregada");
      return state.concat({ ...action.payload });
    }

    default: {
      return state;
    }
  }
};

export const reducer = combineReducers({
  propietarios: propietariosReducer,
  prospectos: prospectosReducer,
  misPropiedades: misPropiedadesReducer,
  otrasPropiedades: otrasPropiedadesReducer,
  actividades: actividadesReducer,
  requerimientos: requerimientosReducer,
});
