import {
  Backdrop,
  Box,
  CircularProgress,
  Grid,
  IconButton,
  Modal,
} from "@mui/material";
import React, { useState } from "react";
import DetalleActividad from "./DetalleActividad";
import ArchivosActividad from "./ArchivosActividad";
import { Close } from "@mui/icons-material";

const isMobile =
  !!navigator.userAgent.match(/iphone|android|blackberry/gi) || false;

const ModalDetalleActividad = ({
  isOpen,
  handleClose,
  values,
  getNombreUsuario,
}) => {
  const [isLoading, setIsLoading] = useState(false);
  const [verArchivos, setVerArchivos] = useState(false);

  const toggleVerArchivos = () => {
    setVerArchivos(!verArchivos);
  };

  const style = {
    position: "absolute",
    top: verArchivos ? "0%" : "50%",
    left: "50%",
    transform: verArchivos ? "translate(-50%, 0%)" : "translate(-50%, -50%)",
    width: verArchivos ? 800 : 600,
    bgcolor: "background.paper",
    boxShadow: 24,
    p: 4,
  };

  const styleMobile = {
    position: "absolute",
    width: "100vw",
    bgcolor: "background.paper",
    boxShadow: 24,
    p: 4,
    top: verArchivos ? "0%" : "50%",
    left: "50%",
    transform: verArchivos ? "translate(-50%, 0%)" : "translate(-50%, -50%)",
  };

  if (values != null) {
    return (
      <Modal
        disableScrollLock={false}
        open={isOpen}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        sx={{
          overflowY: "auto",
        }}
      >
        <Box sx={isMobile ? styleMobile : style}>
          {isLoading && (
            <Backdrop
              sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
              open={true}
              onClick={handleClose}
            >
              <CircularProgress color="inherit" />
            </Backdrop>
          )}
          <Grid
            container
            justifyContent="space-between"
            alignItems={"center"}
            sx={{ marginBottom: 2 }}
          >
            <Grid item xs={10} sm={10} md={10} lg={10} xl={10}>
              <h4>Detalle actividad</h4>
            </Grid>
            <Grid item>
              <IconButton
                color="primary"
                aria-label="copiar"
                component="span"
                onClick={() => handleClose()}
              >
                <Close sx={{ fontSize: "20pt" }} />
              </IconButton>
            </Grid>
          </Grid>
          {!isLoading && values && (
            <>
              {!verArchivos ? (
                <DetalleActividad
                  values={values}
                  getNombreUsuario={getNombreUsuario}
                  toggleVerArchivos={toggleVerArchivos}
                />
              ) : (
                <ArchivosActividad
                  values={values}
                  toggleVerArchivos={toggleVerArchivos}
                />
              )}
            </>
          )}
        </Box>
      </Modal>
    );
  }
};

export default ModalDetalleActividad;
