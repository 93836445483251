//TODO: implementar eliminar
import "../../css/styles.css";
import EmailOutlinedIcon from "@mui/icons-material/EmailOutlined";
import WhatsAppIcon from "@mui/icons-material/WhatsApp";
import LocalPhoneIcon from "@mui/icons-material/LocalPhone";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { setPerfil } from "../../features/actions";
import { Link } from "react-router-dom";
import { getClientesPaginados, getPropiedadesPaginadas } from "../../action";
import {
  CircularProgress,
  Grid,
  IconButton,
  Paper,
  Table,
  TableBody,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  Tooltip,
  styled,
} from "@mui/material";
import { Error, Search } from "@mui/icons-material";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";

const isMobile =
  !!navigator.userAgent.match(/iphone|android|blackberry/gi) || false;

const columnsNonMobile = [
  { id: "nombre", label: "Nombre", minWidth: 170, align: "center" },
  { id: "tipo", label: "Tipo", minWidth: 100, align: "center" },
  { id: "numprops", label: "N° Propiedades", minWidth: 70, align: "center" },
  { id: "contacto", label: "Contacto", minWidth: 140, align: "center" },
  { id: "perfil", label: "Perfil", minWidth: 80, align: "center" },
];

const columnsMobile = [
  { id: "nombre", label: "Nombre", minWidth: 100, align: "center" },
  { id: "contacto", label: "Contacto", minWidth: 160, align: "center" },
  { id: "perfil", label: "Perfil", minWidth: 40, align: "center" },
];

const columns = isMobile ? columnsMobile : columnsNonMobile;

const StyledTableHead = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: "#00a62b",
    color: theme.palette.common.white,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));

const ListadoPropietarios = () => {
  const dispatch = useDispatch();
  const [buscador, setBuscador] = useState("");
  const propietarios = useSelector(
    (state) => state.app.clientes && state.app.clientes.results
  );
  const data = useSelector((state) => state.app.clientes && state.app.clientes);
  const requestGetPropietarios = useSelector(
    (state) => state.app.requestGetClientes && state.app.requestGetClientes
  );
  const propiedades = useSelector(
    (state) =>
      state.app.propiedadesPaginadas && state.app.propiedadesPaginadas.results
  );
  const requestPropiedadesPaginadas = useSelector(
    (state) =>
      state.app.requestPropiedadesPaginadas &&
      state.app.requestPropiedadesPaginadas
  );
  const [propiedadesPorPropietario, setPropiedadesPorPropietario] = useState(
    []
  );
  const [isLoading, setIsLoading] = useState(false);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [isOpenAddPropietarioModal, setIsOpenAddPropietarioModal] =
    useState(false);
  const handleClosePropietarioModal = () => setIsOpenAddPropietarioModal(false);

  useEffect(() => {
    if (
      requestGetPropietarios === "LOADING" ||
      requestPropiedadesPaginadas === "LOADING"
    ) {
      setIsLoading(true);
    } else {
      setIsLoading(false);
    }
  }, [requestGetPropietarios, requestPropiedadesPaginadas]);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  useEffect(() => {
    dispatch(getPropiedadesPaginadas("", "", 0, 999, "todos"));
    obtenerPropiedadesPorPropietario();
  }, []);

  useEffect(() => {
    dispatch(getClientesPaginados(page + 1, rowsPerPage, "true", buscador));
  }, [page, rowsPerPage, buscador]);

  const obtenerPropiedadesPorPropietario = () => {
    let propietariosIds = [];
    propiedades &&
      propiedades.forEach((p) => {
        if (propietariosIds.some((c) => c.idCliente === p.idCliente)) {
          let objIndex = propietariosIds.findIndex(
            (i) => i.idCliente === p.idCliente
          );
          propietariosIds[objIndex].propiedadesCount =
            propietariosIds[objIndex].propiedadesCount + 1;
        } else {
          //let obj = propietarios.find(i => i.id === p.idCliente);
          propietariosIds.push({
            idCliente: p.idCliente,
            //nombre: obj ? `${obj.nombres} ${obj.apellidos}` : "",
            propiedadesCount: 1,
          });
        }
      });
    setPropiedadesPorPropietario(propietariosIds);
  };

  const onChangeBuscador = (e) => {
    setBuscador(e.target.value);
  };

  return (
    <div className="contenido">
      <div className="header">
        <h2>Propietarios</h2>
      </div>
      <Grid container sx={{ mt: 2 }} justifyContent={"space-between"}>
        <Grid item xs={12} sm={6} md={3} lg={3} xl={3} className="alignLeft">
          <Link to={`/propietarios/agregar`}>
            <button
              className="boton"
              onClick={() => {
                //setIsOpenAddPropietarioModal(true);
              }}
            >
              Agregar Propietario
            </button>
          </Link>
        </Grid>
        {/*
        <Grid item xs={12} sm={6} md={3} lg={3} xl={3} className="alignLeft">
          <select className="seleccionador">
            <option>Ordenar por </option>
          </select>
        </Grid>
        */}

        <Grid item xs={12} sm={6} md={3} lg={3} xl={3} className="alignLeft">
          <input
            type="text"
            placeholder="Buscar"
            className="buscar"
            onKeyUp={onChangeBuscador}
          />
          <Search />
        </Grid>
      </Grid>
      <div className="main">
        {isLoading && <CircularProgress />}
        {requestGetPropietarios === "ERROR" && (
          <Grid
            container
            spacing={1}
            justifyContent="center"
            alignItems="center"
          >
            <Grid item xs={6} sm={6} md={4} lg={4} xl={4}>
              <Paper variant="outlined">
                <Error sx={{ fontSize: 40 }} />
                Ha habido un error
              </Paper>
            </Grid>
          </Grid>
        )}
        {requestGetPropietarios === "IDLE" && !isLoading && propietarios && (
          <Paper
            sx={{ width: "100%", overflow: "hidden", marginBottom: "2rem" }}
          >
            <TableContainer>
              <Table size={isMobile ? "small" : "medium"}>
                <TableHead>
                  <TableRow>
                    {columns.map((column) => (
                      <StyledTableHead
                        key={column.id}
                        align={column.align}
                        style={{ minWidth: column.minWidth }}
                      >
                        {column.label}
                      </StyledTableHead>
                    ))}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {propietarios.length === 0 ? (
                    <div>No se han encontrado resultados para su búsqueda</div>
                  ) : (
                    propietarios.map((propietario, i) => {
                      let telefonoWhatsapp = propietario.telefono;
                      let telefonoLlamada = propietario.telefono;
                      if (
                        telefonoWhatsapp !== null &&
                        telefonoWhatsapp !== undefined
                      ) {
                        telefonoWhatsapp = telefonoWhatsapp.trim();
                        telefonoWhatsapp = telefonoWhatsapp.replace("+", "");
                        if (telefonoWhatsapp.length === 8) {
                          telefonoWhatsapp = `569${telefonoWhatsapp}`;
                        } else if (telefonoWhatsapp.length === 9) {
                          telefonoWhatsapp = `56${telefonoWhatsapp}`;
                        } else {
                        }
                      }
                      if (
                        telefonoLlamada !== null &&
                        telefonoLlamada !== undefined
                      ) {
                        telefonoLlamada = telefonoLlamada.trim();
                        if (telefonoLlamada.length === 8) {
                          telefonoLlamada = `569${telefonoLlamada}`;
                        } else if (telefonoLlamada.length === 9) {
                          telefonoLlamada = `56${telefonoLlamada}`;
                        } else if (telefonoLlamada.length === 11) {
                          telefonoLlamada = `+56${telefonoLlamada}`;
                        } else {
                        }
                      }

                      if (isMobile) {
                        return (
                          <TableRow className={i % 2 === 0 ? "filaOscura" : ""}>
                            <TableCell align="center">
                              {propietario.nombres} {propietario.apellidos}
                            </TableCell>
                            <TableCell align="center">
                              <IconButton
                                onClick={() =>
                                  (window.location = `mailto:${propietario.mail}`)
                                }
                                //color="danger"
                                aria-label="email"
                                component="span"
                              >
                                <EmailOutlinedIcon />
                              </IconButton>
                              <a
                                href={`https://api.whatsapp.com/send?phone=${telefonoWhatsapp}`}
                                target="_blank"
                                rel="noreferrer"
                              >
                                <IconButton
                                  //color="danger"
                                  aria-label="whatsapp"
                                  component="span"
                                >
                                  <WhatsAppIcon />
                                </IconButton>
                              </a>
                              <IconButton
                                onClick={() =>
                                  (window.location = `tel:${telefonoLlamada}`)
                                }
                                //color="danger"
                                aria-label="llamar"
                                component="span"
                              >
                                <LocalPhoneIcon />
                              </IconButton>
                            </TableCell>
                            <TableCell align="center">
                              <Link
                                className="link"
                                to={
                                  "/propietarios/propiedades?id=" +
                                  propietario.id
                                }
                                onClick={(e) => {
                                  dispatch(setPerfil(propietario.nombre));
                                }}
                              >
                                Ver perfil
                              </Link>
                            </TableCell>
                          </TableRow>
                        );
                      } else {
                        return (
                          <TableRow className={i % 2 === 0 ? "filaOscura" : ""}>
                            <TableCell align="center">
                              {propietario.nombres} {propietario.apellidos}
                            </TableCell>
                            <TableCell align="center">
                              {propietario.esPersonaJuridica
                                ? "Jurídico"
                                : "Natural"}
                            </TableCell>
                            <TableCell align="center">
                              <a
                                className="link"
                                href="/propietarios/propiedades"
                              >
                                {propiedadesPorPropietario.some(
                                  (p) => p.idCliente === propietario.id
                                ) &&
                                  propiedadesPorPropietario.find(
                                    (p) => p.idCliente === propietario.id
                                  ).propiedadesCount}
                              </a>
                            </TableCell>
                            <TableCell align="center">
                              <Tooltip title="Enviar email" aria-label="email">
                                <IconButton
                                  onClick={() =>
                                    (window.location = `mailto:${propietario.mail}`)
                                  }
                                  //color="danger"
                                  aria-label="email"
                                  component="span"
                                >
                                  <EmailOutlinedIcon />
                                </IconButton>
                              </Tooltip>
                              <a
                                href={`https://api.whatsapp.com/send?phone=${telefonoWhatsapp}`}
                                target="_blank"
                                rel="noreferrer"
                              >
                                <Tooltip title="Whatsapp" aria-label="whatsapp">
                                  <IconButton
                                    //color="danger"
                                    aria-label="whatsapp"
                                    component="span"
                                  >
                                    <WhatsAppIcon />
                                  </IconButton>
                                </Tooltip>
                              </a>
                              <Tooltip title="Llamar" aria-label="llamar">
                                <IconButton
                                  onClick={() =>
                                    (window.location = `tel:${telefonoLlamada}`)
                                  }
                                  //color="danger"
                                  aria-label="llamar"
                                  component="span"
                                >
                                  <LocalPhoneIcon />
                                </IconButton>
                              </Tooltip>
                            </TableCell>
                            <TableCell align="center">
                              <Link
                                className="link"
                                to={
                                  "/propietarios/propiedades?id=" +
                                  propietario.id
                                }
                                onClick={(e) => {
                                  dispatch(setPerfil(propietario.nombre));
                                }}
                              >
                                Ver perfil
                              </Link>
                            </TableCell>
                          </TableRow>
                        );
                      }
                    })
                  )}
                </TableBody>
              </Table>
            </TableContainer>
            {requestGetPropietarios === "IDLE" &&
              propietarios &&
              propietarios.length > 0 && (
                <TablePagination
                  component="div"
                  count={data && data.totalResults}
                  page={page}
                  onPageChange={handleChangePage}
                  rowsPerPage={rowsPerPage}
                  onRowsPerPageChange={handleChangeRowsPerPage}
                  SelectProps={{
                    inputProps: { "aria-label": "Cantidad" },
                    native: true,
                  }}
                  labelRowsPerPage={""}
                />
              )}
          </Paper>
        )}
      </div>
    </div>
  );
};

export default ListadoPropietarios;
