import { Button, CircularProgress, Grid } from "@mui/material";
import { Field, Form, Formik } from "formik";
import { useSelector } from "react-redux";
import FirmantesForm from "./FirmantesForm";

export const FormGenerarCartaOferta = ({
  idPropiedad,
  idProspecto,
  onChangeIdProspecto,
  prospecto,
  handleSubmit,
  handleClose,
  onChangePropiedad,
  setFirmante,
  firmante,
  validationMessages,
  tipoDocumento,
  usarDocumentosAlvaro,
  onChangeUsarDocumentosAlvaro,
  tipoMoneda,
  onChangeTipoMoneda
}) => {
  const requestPropiedades = useSelector(
    (state) => state.app.requestPropiedadesPaginadas
  );
  const propiedades = useSelector(
    (state) =>
      state.app.propiedadesPaginadas && state.app.propiedadesPaginadas.results
  );

  const requestGetProspectos = useSelector(
    (state) => state.app.requestGetProspectosCA
  );
  const prospectos = useSelector(
    (state) => state.app.prospectosCA && state.app.prospectosCA.results
  );

  const requestStateCliente = useSelector(
    (state) => state.app.requestGetCliente
  );
  const cliente = useSelector((state) => state.app.cliente);
/*
  useEffect(() => {
    if (cliente !== null) {
      setFirmante({
        rut: cliente.rut,
        nombres: cliente.nombres,
        apellidos: cliente.apellidos,
        email: cliente.mail,
        telefono: cliente.telefono,
      });
    }
  }, [cliente]);
*/
  const submitLocal = (formValues) => {
    setFirmante({
      rut: prospecto.rut,
      nombres: prospecto.nombres,
      apellidos: prospecto.apellidos,
      email: prospecto.email,
      telefono: prospecto.telefono,
    });

    handleSubmit(formValues);
  };

  const getPrecioReferencial = () => {
    let precio = "";
    if (idPropiedad !== null && propiedades.some(p => p.id === idPropiedad)) {
      const prop = propiedades.find(p => p.id === idPropiedad);
      precio = `Precio referencial ${prop.tipoPrecio} ${prop.valor}`
    }
    return precio;
  }

  return (
    <Formik
      initialValues={{
        idPropiedad,
        idProspecto,
        tipoMoneda: tipoMoneda,
        precioFinal: "",
        archivoVersoIdentidad: "",
        archivoReversoIdentidad: "",
        //archivoCertificadoDocumentoDominio: "",
        archivoVersoIdentidadPropins: "",
        archivoReversoIdentidadPropins: "",
      }}
      //validate={validate}
      onSubmit={submitLocal}
    >
      {({ values, errors, handleSubmit, setFieldValue }) => (
        <>
          <Form onSubmit={handleSubmit} className="form-container">
            <Grid container>
              {requestPropiedades === "LOADING" && <CircularProgress />}
              {requestPropiedades === "IDLE" && (
                <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                  <label className="milabel">Propiedad</label>
                  <Field
                    name="idPropiedad"
                    as="select"
                    className="miSelect"
                    value={idPropiedad}
                    onChange={onChangePropiedad}
                  >
                    <option value={""}>- Seleccione -</option>
                    {propiedades.map((c) => {
                      return (
                        <option value={c.id}>
                          {c.nombreCalle} {c.numero}
                        </option>
                      );
                    })}
                  </Field>
                </Grid>
              )}
              {requestGetProspectos === "LOADING" && <CircularProgress />}
              {requestGetProspectos === "IDLE" && (
                <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                  <label className="milabel">Prospecto comprador</label>
                  <Field
                    name="idProspecto"
                    as="select"
                    className="miSelect"
                    value={idProspecto}
                    onChange={onChangeIdProspecto}
                  >
                    <option value={""}>- Seleccione -</option>
                    {prospectos.map((c) => {
                      return (
                        <option value={c.id}>
                          {c.nombres} {c.apellidos}
                        </option>
                      );
                    })}
                  </Field>
                </Grid>
              )}
            </Grid>
            {idPropiedad && requestStateCliente !== "LOADING" && cliente && prospecto && (
              <FirmantesForm
                setFieldValue={setFieldValue}
//datosAlvaro={datosAlvaro}
                //editDatosAlvaro={editDatosAlvaro}
                //setEditDatosAlvaro={setEditDatosAlvaro}
                //guardarDatosAlvaro={guardarDatosAlvaro}
                firmante={firmante}
                editPath={`/propietarios/propiedades?id=${cliente.id}`}
                tipoDocumento={tipoDocumento}
                usarDocumentosAlvaro={usarDocumentosAlvaro}
                onChangeUsarDocumentosAlvaro={onChangeUsarDocumentosAlvaro}
              />
            )}

            {validationMessages && validationMessages.length > 0 && (
              <Grid container sx={{ mt: 2 }}>
                <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                  Se han encontrado los siguientes problemas con la validación:
                </Grid>
                {validationMessages.map((v) => (
                  <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                    {v}
                  </Grid>
                ))}
              </Grid>
            )}

            <Grid container spacing={1}>
              <Grid item xs={3} sm={3} md={3} lg={3} xl={3}>
                <label className="milabel">Tipo moneda</label>
                <Field
                  name="tipoMoneda"
                  as="select"
                  className="miSelect"
                  value={tipoMoneda}
                  onChange={onChangeTipoMoneda}
                >
                  <option value="UF">UF</option>
                  <option value="CLP">CLP</option>
                </Field>
              </Grid>
              <Grid item xs={9} sm={9} md={9} lg={9} xl={9}>
                <label className="milabel">Precio</label>
                <Field name="precioFinal" type="text" className="miSelect" placeholder={getPrecioReferencial()} />
              </Grid>
            </Grid>

            <Grid container justifyContent="flex-end" sx={{ mt: 2 }}>
              <Grid item>
                <Button onClick={handleClose} className="main-btn">
                  Cancelar
                </Button>
              </Grid>
              <Grid item>
                <Button
                  type="submit"
                  className="main-btn"
                  variant="contained"
                  color="success"
                >
                  Guardar
                </Button>
              </Grid>
            </Grid>
          </Form>
        </>
      )}
    </Formik>
  );
};
