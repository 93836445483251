import * as React from "react";
import Tooltip from "@mui/material/Tooltip";
import IconButton from "@mui/material/IconButton";
import TableCell from "@mui/material/TableCell";
import TableRow from "@mui/material/TableRow";
import { useState } from "react";
import { Menu, MenuItem } from "@mui/material";
import InsertLinkIcon from "@mui/icons-material/InsertLink";
import { MoreVert, TouchApp } from "@mui/icons-material";
import { ESTADOS_PROPIEDAD } from "../../utils/constants";
import { OpenInNew } from "@mui/icons-material";
import utilsFunc from "../../utils/utilsFunctions";
import { alpha, styled } from "@mui/material/styles";
import { useSelector } from "react-redux";

const StyledMenu = styled((props) => (
  <Menu
    elevation={0}
    anchorOrigin={{
      vertical: "bottom",
      horizontal: "right",
    }}
    transformOrigin={{
      vertical: "top",
      horizontal: "right",
    }}
    {...props}
  />
))(({ theme }) => ({
  "& .MuiPaper-root": {
    borderRadius: 6,
    marginTop: theme.spacing(1),
    minWidth: 180,
    color:
      theme.palette.mode === "light"
        ? "rgb(55, 65, 81)"
        : theme.palette.grey[300],
    boxShadow:
      "rgb(255, 255, 255) 0px 0px 0px 0px, rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px",
    "& .MuiMenu-list": {
      padding: "4px 0",
    },
    "& .MuiMenuItem-root": {
      "& .MuiSvgIcon-root": {
        fontSize: 18,
        color: theme.palette.text.secondary,
        marginRight: theme.spacing(1.5),
      },
      "&:active": {
        backgroundColor: alpha(
          theme.palette.primary.main,
          theme.palette.action.selectedOpacity
        ),
      },
    },
  },
}));

export const FilaOtraPropiedad = ({
  propiedad,
  i,
  isMobile,
  onCopyLink,
  onClickSolicitarCanje,
}) => {
  const userData = useSelector((state) => state.app.itemUsuario);
  const [anchorEl, setAnchorEl] = useState(null);
  const openMenu = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  let baseUrl =
    window.location.hostname.includes("dev.propins.cl") ||
    window.location.hostname.includes("localhost")
      ? "https://dev.propins.cl/info-propiedad?idprop="
      : "https://propins.cl/info-propiedad?idprop=";
  let linkFicha = baseUrl + propiedad.id;

  const { formatToThousandSeparator } = utilsFunc;

  if (isMobile) {
    return (
      <TableRow className={i % 2 === 0 ? "filaOscura" : ""}>
        <TableCell align="center">{propiedad.comuna}</TableCell>
        <TableCell align="center">{propiedad.codigoPropiedad}</TableCell>
        <TableCell align="center">
          {formatToThousandSeparator(propiedad.valor)} {propiedad.tipoPrecio}
        </TableCell>
        <TableCell align="center">
          <IconButton
            onClick={handleClick}
            //color="danger"
            aria-label="acciones"
            component="span"
          >
            <MoreVert />
          </IconButton>
          <StyledMenu
            id="demo-customized-menu"
            MenuListProps={{
              "aria-labelledby": "demo-customized-button",
            }}
            anchorEl={anchorEl}
            open={openMenu}
            onClose={handleClose}
          >
            {propiedad.tarjetaPropiedadHabilitada && (
              <a target="_blank" href={linkFicha} rel="noreferrer">
                <MenuItem disableRipple>
                  <OpenInNew />
                  Ir a ficha
                </MenuItem>
              </a>
            )}
            {propiedad.tarjetaPropiedadHabilitada && (
              <MenuItem disableRipple onClick={() => onCopyLink(propiedad)}>
                <InsertLinkIcon />
                Copiar enlace
              </MenuItem>
            )}
            {propiedad.idBroker !== userData.userId && (
              <MenuItem
                disableRipple
                onClick={() => onClickSolicitarCanje(propiedad)}
              >
                <TouchApp />
                Solicitar canje
              </MenuItem>
            )}
          </StyledMenu>
        </TableCell>
      </TableRow>
    );
  } else {
    return (
      <TableRow className={i % 2 === 0 ? "filaOscura" : ""}>
        <TableCell align="center">
          {propiedad.planContratado
            ? propiedad.planContratado.esVenta
              ? "Venta"
              : "Arriendo"
            : "Sin información"}
        </TableCell>
        <TableCell align="center">
          {formatToThousandSeparator(propiedad.valor)} {propiedad.tipoPrecio}
        </TableCell>
        <TableCell align="center">{propiedad.comuna}</TableCell>
        <TableCell align="center">{propiedad.tipoPropiedad}</TableCell>
        <TableCell align="center">
          {propiedad.dormitorios}D {propiedad.banio}B
        </TableCell>
        <TableCell align="center">
          {ESTADOS_PROPIEDAD[propiedad.estadoPropiedad]}
        </TableCell>
        <TableCell align="center">{propiedad.codigoPropiedad}</TableCell>
        <TableCell align="center">
          {propiedad.tarjetaPropiedadHabilitada ? (
            <a
              className="link"
              target="_blank"
              href={linkFicha}
              rel="noreferrer"
            >
              Ver ficha
            </a>
          ) : (
            <div>Sin ficha publicada</div>
          )}
        </TableCell>
        <TableCell align="center">
          {propiedad.tarjetaPropiedadHabilitada && (
            <Tooltip title="Copiar enlace" aria-label="copiar">
              <IconButton
                color="primary"
                aria-label="copiar"
                component="span"
                onClick={() => onCopyLink(propiedad)}
              >
                <InsertLinkIcon />
              </IconButton>
            </Tooltip>
          )}
        </TableCell>
        <TableCell align="center">
          {propiedad.idBroker !== userData.userId && (
            <button
              className="miButton"
              onClick={() => onClickSolicitarCanje(propiedad)}
            >
              Solicitar
            </button>
          )}
        </TableCell>
      </TableRow>
    );
  }
};
