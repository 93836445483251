import {
  CircularProgress,
  Grid,
  Paper,
  Snackbar,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  styled,
  tableCellClasses,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  descargarVistaPreviaDocumento,
  fetchGetAllUsuarios,
  getPortfolios,
  getPropiedadesPaginadas,
  postRetryPortfolio,
} from "../../action";
import ModalGenerarDocumento from "./ModalGenerarDocumento";
import ModalDetalleProcesoFirma from "./ModalDetalleProcesoFirma";
import Swal from "sweetalert2";
import InsertDriveFileIcon from "@mui/icons-material/InsertDriveFile";
import "./Documentos.css";
import { FilaDocumento } from "./FilaDocumento";

const isMobile =
  !!navigator.userAgent.match(/iphone|android|blackberry/gi) || false;

const columnsNonMobile = [
  { id: "titulo", label: "Titulo documento", minWidth: 40, align: "center" },
  { id: "tipo", label: "Tipo", minWidth: 70, align: "center" },
  {
    id: "descripcion",
    label: "Descripcion",
    minWidth: "10px",
    align: "center",
  },
  {
    id: "fechaCreacion",
    label: "Fecha creacion",
    minWidth: 40,
    align: "center",
  },
  { id: "usuario", label: "Usuario", minWidth: 40, align: "center" },
  { id: "estado", label: "Estado proceso", minWidth: 140, align: "center" },
  { id: "actions", label: "Acciones", minWidth: 120, align: "center" },
];

const columnsMobile = [
  { id: "titulo", label: "Titulo documento", minWidth: 40, align: "center" },
  { id: "tipo", label: "Tipo", minWidth: 40, align: "center" },
  {
    id: "fechaCreacion",
    label: "Fecha creacion",
    minWidth: 70,
    align: "center",
  },
  { id: "acciones", label: "Acciones", minWidth: 1, align: "center" },
];

const columns = isMobile ? columnsMobile : columnsNonMobile;

const listaTiposDocumento = [
  {
    name: "Orden de canje",
    className: "orden-canje",
    id: 4,
    permissions: [0, 9, 10],
  },
  {
    name: "Orden de arriendo",
    className: "orden-arriendo",
    id: 5,
    permissions: [0, 9, 10],
  },
  {
    name: "Carta oferta",
    className: "carta-oferta",
    id: 6,
    permissions: [0, 9],
  },
  {
    name: "Aceptación carta oferta",
    className: "aceptacion-carta-oferta",
    id: 7,
    permissions: [0, 9],
  },
  {
    name: "Orden de venta",
    className: "orden-venta",
    id: 8,
    permissions: [0, 9, 10],
  },
  {
    name: "Orden de visita",
    className: "orden-visita",
    id: 9,
    permissions: [0, 9, 10],
  },
];

const StyledTableHead = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: "#00a62b",
    color: theme.palette.common.white,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));

const Documentos = () => {
  const dispatch = useDispatch();
  const userData = useSelector((state) => state.app.itemUsuario);
  const idBroker =
    userData && userData.tipoCuenta === 10 ? userData.userId : "";
  const [portfolio, setPortfolio] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const propiedades = useSelector(
    (state) =>
      state.app.propiedadesPaginadas && state.app.propiedadesPaginadas.results
  );
  const usuarios = useSelector((state) => state.app.itemsUsuarios);
  const [documentos, setDocumentos] = useState(true);
  const [isOpen, setIsOpen] = useState(false);
  const [isOpenProcessDetailModal, setIsOpenProcessDetailModal] =
    useState(false);
  const handleCloseProcessDetailModal = () =>
    setIsOpenProcessDetailModal(false);
  const handleOpenProcessDetailModal = (portfolio) => {
    setPortfolio(portfolio);
    setIsOpenProcessDetailModal(true);
  };
  const [tipoDocumento, setTipoDocumento] = useState(null);
  const [modalTitle, setModalTitle] = useState("");
  const handleClose = () => setIsOpen(false);
  const handleOpenModal = (index, title) => {
    setTipoDocumento(index);
    setModalTitle(title);
    setIsOpen(true);
  };
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };
  const [downloadNotification, setDownloadNotification] = useState(false);
  const handleCloseDownloadNotification = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setDownloadNotification(false);
  };
  const [historial, setHistorial] = useState(false);
  const portfolios = useSelector(
    (state) => state.app.portfolios && state.app.portfolios.results
  );
  const data = useSelector(
    (state) => state.app.portfolios && state.app.portfolios
  );
  const requestGetPortfolios = useSelector(
    (state) => state.app.requestGetPortfolios
  );
  const requestPortfolioRetry = useSelector(
    (state) => state.app.requestPortfolioRetry
  );

  useEffect(() => {
    if (
      requestGetPortfolios === "LOADING" ||
      requestPortfolioRetry === "LOADING"
    ) {
      setIsLoading(true);
    } else {
      setIsLoading(false);
    }
  }, [requestGetPortfolios, requestPortfolioRetry]);

  useEffect(() => {
    dispatch(getPortfolios(page + 1, rowsPerPage));
  }, [page, rowsPerPage]);

  useEffect(() => {
    dispatch(
      getPropiedadesPaginadas(
        "",
        idBroker,
        0,
        999,
        "true",
        "createdAtDescending",
        "",
        "",
        "",
        0,
        0,
        0,
        0,
        0,
        0,
        0,
        0,
        0,
        0,
        "",
        "",
        ""
      )
    );
    dispatch(fetchGetAllUsuarios());
  }, []);

  const reiniciarFirmaDigital = (idPortfolio) => {
    dispatch(postRetryPortfolio(idPortfolio));
  };

  if (requestPortfolioRetry === "SUCCESS") {
    Swal.fire({
      title: "Documento generado",
      text: "Se ha logrado reiniciar la firma exitosamente",
      icon: "success",
    }).then((result) => {
      if (result.value || result.dismiss === "backdrop") {
        window.location.reload();
        //dispatch(getPortfolios(page + 1, rowsPerPage));
      }
    });
  }

  const descargarPlantilla = (tipoDocumento) => {
    setDownloadNotification(true);
    dispatch(descargarVistaPreviaDocumento(tipoDocumento));
  };

  return (
    <div className="contenido">
      <div className="header">
        <h2>Documentos</h2>
      </div>
      <div className="opciones">
        <h3
          id={documentos ? "active1" : "notActive1"}
          onClick={() => {
            setDocumentos(true);
            setHistorial(false);
          }}
        >
          Lista documentos
        </h3>
        <h3
          id={historial ? "active1" : "notActive1"}
          onClick={() => {
            setDocumentos(false);
            setHistorial(true);
          }}
        >
          Historial
        </h3>
      </div>
      <div className={`main ${!documentos ? "d-none" : ""}`}>
        <Grid
          container
          spacing={4}
          sx={{ marginBottom: 4 }}
          justifyContent={isMobile ? "center" : "flex-start"}
        >
          {listaTiposDocumento.map((d) => {
            if (d.permissions.includes(userData.tipoCuenta)) {
              return (
                <Grid item>
                  <div className="document-card">
                    <div
                      className={`thumbnail-documento thumbnail-${d.className} reporte`}
                    >
                      <button
                        className="crearDocum"
                        onClick={() =>
                          handleOpenModal(d.id, `Generar ${d.name.toLowerCase()}`)
                        }
                      >
                        Crear nuevo
                      </button>
                      <br />
                      <button
                        className="crearDocum"
                        onClick={() => descargarPlantilla(d.id)}
                      >
                        Descargar plantilla
                      </button>
                    </div>
                    <div className="tituloReporte">
                      <InsertDriveFileIcon
                        sx={{
                          margin: "0.3rem",
                          fontSize: "20pt",
                          color: "#00a62b",
                        }}
                      />
                      {d.name}
                    </div>
                  </div>
                </Grid>
              );
            }
          })}
        </Grid>
      </div>
      
      <div className={`historialDocumentos ${!historial ? "d-none" : ""}`}>
        {portfolios && !isLoading && portfolios.length > 0 && (
          <div className="barraNav">
            <div>
              <select className="seleccionador">
                <option>Ordenar por </option>
              </select>
              <input className="buscar" placeholder="Buscar"></input>
            </div>
          </div>
        )}
        <div className="main">
          {isLoading && <CircularProgress />}
          {portfolios && !isLoading && portfolios.length === 0 && (
            <div>No se han generado documentos</div>
          )}
          {portfolios && !isLoading && portfolios.length > 0 && (
            <Paper component={Paper} style={{ marginBottom: "2rem" }}>
              <TableContainer>
                <Table size={isMobile ? "small" : "medium"}>
                  <TableHead>
                    <TableRow>
                      {columns.map((column) => (
                        <StyledTableHead
                          key={column.id}
                          align={column.align}
                          style={{ minWidth: column.minWidth }}
                        >
                          {column.label}
                        </StyledTableHead>
                      ))}
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {propiedades.length === 0 ? (
                      <div>
                        No se han encontrado resultados para su búsqueda
                      </div>
                    ) : (
                      portfolios.map((portfolio, i) => (
                        <FilaDocumento
                          portfolio={portfolio}
                          i={i}
                          handleOpenProcessDetailModal={
                            handleOpenProcessDetailModal
                          }
                        />
                      ))
                    )}
                  </TableBody>
                </Table>
              </TableContainer>
              {!isLoading && portfolios && portfolios.length > 0 && (
                <TablePagination
                  component="div"
                  count={data && data.totalResults}
                  page={page}
                  onPageChange={handleChangePage}
                  rowsPerPage={rowsPerPage}
                  onRowsPerPageChange={handleChangeRowsPerPage}
                  SelectProps={{
                    inputProps: { "aria-label": "Cantidad" },
                    native: true,
                  }}
                  labelRowsPerPage={""}
                />
              )}
            </Paper>
          )}
        </div>
      </div>
      <ModalGenerarDocumento
        tipoDocumento={tipoDocumento}
        isOpen={isOpen}
        handleClose={handleClose}
        title={modalTitle}
      />
      <ModalDetalleProcesoFirma
        isOpen={isOpenProcessDetailModal}
        handleClose={handleCloseProcessDetailModal}
        portfolio={portfolio}
      />
      <Snackbar
        open={downloadNotification}
        autoHideDuration={4000}
        onClose={handleCloseDownloadNotification}
        message="Se está preparando la descarga"
      />
    </div>
  );
};

export default Documentos;
