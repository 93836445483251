import { useDispatch, useSelector } from "react-redux";
import { useHistory, useLocation } from "react-router-dom";
import { getPropiedadById } from "../../../action";
import { useEffect } from "react";
import {
  Backdrop,
  Box,
  CircularProgress,
  Tab,
  Tabs,
  Typography,
} from "@mui/material";
import { useState } from "react";
import Swal from "sweetalert2";
import PropiedadNavegacionMobile from "./PropiedadNavegacionMobile";
import FichaPropiedad from "./FichaPropiedad";
import { CabeceraFichaPropiedad } from "./CabeceraFichaPropiedad";
import FotosPropiedad from "./FotosPropiedad";
import ActividadesPropiedad from "./ActividadesPropiedad";
import PropietarioProp from "./PropietarioProp";
import Back from "../../../assets/Back";

const isMobile =
  !!navigator.userAgent.match(/iphone|android|blackberry/gi) || false;

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 0 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

const IndexFichaPropiedad = ({}) => {
  const history = useHistory();
  const dispatch = useDispatch();
  const [isLoading, setIsLoading] = useState(false);
  const [value, setValue] = useState(0);
  const handleChangeTab = (event, newValue) => {
    setValue(newValue);
  };
  const [openBackdrop, setOpenBackdrop] = useState(false);
  const handleCloseBackdrop = () => setOpenBackdrop(false);
  const [openBrokerModal, setOpenBrokerModal] = useState(false);
  const handleOpenBrokerModal = () => {
    setOpenBrokerModal(true);
  };
  const handleCloseBrokerModal = () => {
    setOpenBrokerModal(false);
  };
  const prop = useSelector(
    (state) => state.app.propiedad && state.app.propiedad.data
  );

  const userData = useSelector((state) => state.app.itemUsuario);
  const idBroker =
    userData && userData.tipoCuenta === 10 ? userData.userId : "";
  const errorMessage = useSelector((state) => state.app.errorMessage);
  const requestGetPropiedad = useSelector(
    (state) => state.app.requestGetPropiedad
  );
  const requestUpdatePropiedad = useSelector(
    (state) => state.app.requestUpdatePropiedad
  );
  const requestDeletePropiedad = useSelector(
    (state) => state.app.requestDeletePropiedad
  );
  const requestPostPI = useSelector((state) => state.app.requestPostPI);
  const requestPutPI = useSelector((state) => state.app.requestPutPI);
  const requestPostYapo = useSelector((state) => state.app.requestPostYapo);
  const requestDeleteTarjetaProp = useSelector(
    (state) => state.app.requestDeleteTarjetaProp
  );
  const requestPostTarjetaProp = useSelector(
    (state) => state.app.requestPostTarjetaProp
  );
  const requestPropiedadBroker = useSelector(
    (state) => state.app.requestPropiedadBroker
  );

  useEffect(() => {
    if (
      requestGetPropiedad === "LOADING" ||
      requestUpdatePropiedad === "LOADING" ||
      requestDeletePropiedad === "LOADING" ||
      requestPostTarjetaProp === "LOADING" ||
      requestDeleteTarjetaProp === "LOADING" ||
      requestPostPI === "LOADING" ||
      requestPostYapo === "LOADING" ||
      requestPutPI === "LOADING"
    ) {
      setOpenBackdrop(true);
      setIsLoading(true);
    } else {
      setOpenBackdrop(false);
      setIsLoading(false);
    }
  }, [
    requestGetPropiedad,
    requestUpdatePropiedad,
    requestDeletePropiedad,
    requestDeleteTarjetaProp,
    requestPostTarjetaProp,
    requestPostPI,
    requestPostYapo,
    requestPutPI,
  ]);

  if (requestUpdatePropiedad === "SUCCESS") {
    Swal.fire({
      title: "Propiedad actualizada",
      text: "Se ha actualizado la propiedad exitosamente",
      icon: "success",
    }).then(() => {
      reloadData();
    });
  }

  if (requestDeletePropiedad === "SUCCESS") {
    Swal.fire({
      title: "Propiedad eliminada",
      text: "Se ha eliminado la propiedad exitosamente",
      icon: "success",
    }).then(() => {
      history.push("/mispropiedades");
    });
  }

  if (requestPostYapo === "ERROR") {
    Swal.fire("Error", errorMessage, "error");
  }
  if (requestPostYapo === "SUCCESS") {
    Swal.fire({
      title: "Publicado en Yapo",
      text: "Se ha publicado la propiedad en Yapo exitosamente",
      icon: "success",
    }).then((result) => {
      if (result.value || result.dismiss === "backdrop") {
        window.location.reload();
      }
    });
  }

  if (requestPostPI === "ERROR") {
    Swal.fire("Error", errorMessage, "error");
  }
  if (requestPostPI === "SUCCESS") {
    Swal.fire({
      title: "Publicado en Portal Inmobiliario",
      text: "Se ha publicado la propiedad en Portal Inmobiliario exitosamente",
      icon: "success",
    }).then((result) => {
      if (result.value || result.dismiss === "backdrop") {
        window.location.reload();
      }
    });
  }

  if (requestPostTarjetaProp === "SUCCESS") {
    Swal.fire({
      title: "Tarjeta propiedad generada",
      text: "Se ha generado la tarjeta para la propiedad seleccionada",
      icon: "success",
    }).then((result) => {
      if (result.value || result.dismiss === "backdrop") {
        reloadData();
      }
    });
  }

  if (requestDeleteTarjetaProp === "SUCCESS") {
    Swal.fire({
      title: "Tarjeta propiedad deshabilitada",
      text: "Se ha deshabilitado la tarjeta para la propiedad seleccionada",
      icon: "success",
    }).then((result) => {
      if (result.value || result.dismiss === "backdrop") {
        reloadData();
      }
    });
  }

  if (requestPropiedadBroker === "SUCCESS") {
    Swal.fire({
      title: "Broker actualizado",
      text: "Se ha actualizado el broker asociado a la propiedad exitosamente",
      icon: "success",
    }).then((result) => {
      if (result.value || result.dismiss === "backdrop") {
        handleCloseBrokerModal();
        reloadData();
      }
    });
  }

  if (
    requestDeleteTarjetaProp === "ERROR" ||
    requestPostTarjetaProp === "ERROR" ||
    requestPropiedadBroker === "ERROR"
  ) {
    Swal.fire({
      title: "Error",
      text: errorMessage,
      icon: "error",
    });
  }

  let { search } = useLocation();
  let query = new URLSearchParams(search);
  let perfil = query.get("id");
  useEffect(() => {
    dispatch(getPropiedadById(perfil));
  }, [perfil]);

  const reloadData = () => {
    dispatch(getPropiedadById(perfil));
  };

  return (
    <div className={isMobile ? "contenido mb-100px" : "contenido"}>
      {(userData && prop && userData.tipoCuenta === 10 && prop.idBroker) ||userData.tipoCuenta != 10 ? (
        <>
          <CabeceraFichaPropiedad
            id={perfil}
            userData={userData}
            prop={prop}
            idBroker={idBroker}
            reloadData={reloadData}
            requestUpdatePropiedad={requestUpdatePropiedad}
            openBrokerModal={openBrokerModal}
            handleOpenBrokerModal={handleOpenBrokerModal}
            handleCloseBrokerModal={handleCloseBrokerModal}
          />

          {isMobile ? (
            <PropiedadNavegacionMobile
              id={perfil}
              currentTab={value}
              handleChange={handleChangeTab}
            />
          ) : (
            <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
              <Tabs
                value={value}
                onChange={handleChangeTab}
                aria-label="basic tabs example"
              >
                <Tab label="Ficha propiedad" {...a11yProps(0)} />
                <Tab label="Fotos" {...a11yProps(1)} />
                <Tab label="Actividades" {...a11yProps(2)} />
                <Tab label="Propietario" {...a11yProps(2)} />
              </Tabs>
            </Box>
          )}

          <TabPanel value={value} index={0}>
            {isLoading ? (
              <Backdrop
                sx={{
                  color: "#fff",
                  zIndex: (theme) => theme.zIndex.drawer + 1,
                }}
                open={openBackdrop}
                onClick={handleCloseBackdrop}
              >
                <CircularProgress color="inherit" />
              </Backdrop>
            ) : (
              <FichaPropiedad prop={prop} />
            )}
          </TabPanel>

          <TabPanel value={value} index={1}>
            {isLoading ? (
              <Backdrop
                sx={{
                  color: "#fff",
                  zIndex: (theme) => theme.zIndex.drawer + 1,
                }}
                open={openBackdrop}
                onClick={handleCloseBackdrop}
              >
                <CircularProgress color="inherit" />
              </Backdrop>
            ) : (
              <FotosPropiedad prop={prop} />
            )}
          </TabPanel>

          <TabPanel value={value} index={2}>
            {isLoading ? (
              <Backdrop
                sx={{
                  color: "#fff",
                  zIndex: (theme) => theme.zIndex.drawer + 1,
                }}
                open={openBackdrop}
                onClick={handleCloseBackdrop}
              >
                <CircularProgress color="inherit" />
              </Backdrop>
            ) : (
              <ActividadesPropiedad id={perfil} />
            )}
          </TabPanel>

          <TabPanel value={value} index={3}>
            {isLoading ? (
              <Backdrop
                sx={{
                  color: "#fff",
                  zIndex: (theme) => theme.zIndex.drawer + 1,
                }}
                open={openBackdrop}
                onClick={handleCloseBackdrop}
              >
                <CircularProgress color="inherit" />
              </Backdrop>
            ) : (
              <PropietarioProp idCliente={prop && prop.idCliente} />
            )}
          </TabPanel>
        </>
      ) : (
        <>
          <Back urlPath={"/mispropiedades"} />
          <div style={{ marginTop: "40px" }}>
            No tienes permiso para ver esta página
          </div>
        </>
      )}
    </div>
  );
};

export default IndexFichaPropiedad;
