import { useDispatch, useSelector } from "react-redux";
import {
  CircularProgress,
  Grid,
  TablePagination,
  Paper,
  Table,
  TableBody,
  TableContainer,
  TableHead,
  TableRow,
  styled,
} from "@mui/material";
import FormAgregarActividad from "./FormAgregarActividad";
import { fetchGetAllUsuarios, getActividades } from "../../action";
import { useEffect, useState } from "react";
import Swal from "sweetalert2";
import { FilaActividad } from "./FilaActividad";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import ModalDetalleActividad from "./ModalDetalleActividad";

const isMobile =
  !!navigator.userAgent.match(/iphone|android|blackberry/gi) || false;

const columnsNonMobile = [
  { id: "estado", label: "Estado", minWidth: 170, align: "center" },
  { id: "titulo", label: "Titulo", minWidth: 100, align: "center" },
  { id: "fecha", label: "Fecha", minWidth: 70, align: "center" },
  { id: "subidoPor", label: "Subido por", minWidth: 70, align: "center" },
  { id: "archivos", label: "Archivos", minWidth: 70, align: "center" },
  { id: "ver", label: "Ver", minWidth: 140, align: "center" },
  { id: "acciones", label: "Acciones", minWidth: 80, align: "center" },
];

const columnsMobile = [
  { id: "estado", label: "Estado", minWidth: 1, align: "center" },
  { id: "titulo", label: "Titulo", minWidth: 100, align: "center" },
  { id: "fecha", label: "Fecha", minWidth: 80, align: "center" },
  { id: "acciones", label: "Acciones", minWidth: 1, align: "center" },
];

const columns = isMobile ? columnsMobile : columnsNonMobile;

const StyledTableHead = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: "#00a62b",
    color: theme.palette.common.white,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));

const ListaActividades = ({ idPropiedad, idCliente, idProspecto }) => {
  const dispatch = useDispatch();
  const actividades = useSelector(
    (state) => state.app.actividades && state.app.actividades.results
  );
  const data = useSelector(
    (state) => state.app.actividades && state.app.actividades
  );
  const requestGetActividades = useSelector(
    (state) => state.app.requestGetActividades
  );
  const requestPutActividad = useSelector(
    (state) => state.app.requestPutActividad
  );
  const usuarios = useSelector((state) => state.app.itemsUsuarios);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [isLoading, setIsLoading] = useState(false);
  const [openEditModal, setOpenEditModal] = useState(false);
  const [openDetailModal, setOpenDetailModal] = useState(false);
  const [currentActivity, setCurrentActivity] = useState(null);
  const handleOpenEditModal = (activity) => {
    setCurrentActivity(activity);
    setOpenEditModal(true);
  };
  const handleCloseEditModal = () => setOpenEditModal(false);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };
  useEffect(() => {
    dispatch(fetchGetAllUsuarios());
    dispatch(
      getActividades(page, rowsPerPage, idPropiedad, idCliente, idProspecto)
    );
  }, [page, rowsPerPage, idPropiedad]);

  useEffect(() => {
    if (
      requestGetActividades === "LOADING" ||
      requestPutActividad === "LOADING"
    ) {
      setIsLoading(true);
    } else {
      setIsLoading(false);
    }
  }, [requestGetActividades, requestPutActividad]);

  if (requestPutActividad === "SUCCESS") {
    Swal.fire({
      title: "Actividad actualizada",
      text: "Se ha actualizado la actividad exitosamente",
      icon: "success",
    }).then((result) => {
      if (result.value || result.dismiss === "backdrop") {
        dispatch(
          getActividades(page, rowsPerPage, idPropiedad, idCliente, idProspecto)
        );
      }
    });
  }
  const onClickAddActivity = () => {
    handleOpenEditModal(null);
  };

  const reloadActividades = () => {
    dispatch(
      getActividades(page, rowsPerPage, idPropiedad, idCliente, idProspecto)
    );
  };
  const handleOpenDetailModal = (activity) => {
    setCurrentActivity(activity);
    setOpenDetailModal(true);
  };
  const handleCloseDetailModal = () => {
    setCurrentActivity(null);
    setOpenDetailModal(false);
  };

  const getNombreUsuario = (id) => {
    const user =
      usuarios.some((u) => u.id === id) && usuarios.find((u) => u.id === id);
    if (user) {
      return `${user.nombres} ${user.apellidos}`;
    }
    return "";
  };

  return (
    <Grid container justifyContent="flex-start">
      <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
        <Grid
          container
          className="alignLeft"
          justifyContent="flex-start"
          alignItems="center"
        >
          <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
            <button className="boton" onClick={onClickAddActivity}>
              Agregar actividad
            </button>
          </Grid>
        </Grid>

        <Grid
          container
          spacing={1}
          justifyContent="cenflex-startter"
          alignItems="center"
          sx={{ mt: 2, mb: 4 }}
        >
          <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
            {isLoading && <CircularProgress />}
            {requestGetActividades === "IDLE" &&
              !Swal.isVisible() &&
              !isLoading &&
              actividades && (
                <Paper
                  sx={{
                    width: "100%",
                    overflow: "hidden",
                    marginBottom: "2rem",
                  }}
                >
                  <TableContainer component={Paper}>
                    <Table size={isMobile ? "small" : "medium"}>
                      <TableHead>
                        <TableRow>
                          {columns.map((column) => (
                            <StyledTableHead
                              key={column.id}
                              align={column.align}
                              style={{ minWidth: column.minWidth }}
                            >
                              {column.label}
                            </StyledTableHead>
                          ))}
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {actividades.length === 0 ? (
                          <div>
                            No se han encontrado resultados para su búsqueda
                          </div>
                        ) : (
                          actividades.map((actividad, i) => (
                            <FilaActividad
                              actividad={actividad}
                              i={i}
                              isMobile={isMobile}
                              getNombreUsuario={getNombreUsuario}
                              handleOpenDetailModal={handleOpenDetailModal}
                              handleOpenEditModal={handleOpenEditModal}
                            />
                          ))
                        )}
                      </TableBody>
                    </Table>
                  </TableContainer>
                  {requestGetActividades === "IDLE" &&
                    actividades &&
                    actividades.length > 0 && (
                      <TablePagination
                        component="div"
                        count={data && data.totalResults}
                        page={page}
                        onPageChange={handleChangePage}
                        rowsPerPage={rowsPerPage}
                        onRowsPerPageChange={handleChangeRowsPerPage}
                        SelectProps={{
                          inputProps: { "aria-label": "Cantidad" },
                          native: true,
                        }}
                        labelRowsPerPage={""}
                      />
                    )}
                </Paper>
              )}
          </Grid>
        </Grid>

        <FormAgregarActividad
          idCliente={idCliente}
          idPropiedad={idPropiedad}
          idProspecto={idProspecto}
          reloadMethod={reloadActividades}
          isOpen={openEditModal}
          handleClose={handleCloseEditModal}
          values={currentActivity}
        />
        <ModalDetalleActividad
          isOpen={openDetailModal}
          handleClose={handleCloseDetailModal}
          values={currentActivity}
          getNombreUsuario={getNombreUsuario}
        />
      </Grid>
    </Grid>
  );
};

export default ListaActividades;
