//TODO: dividir en componentes distintos
import { ArrowBack, Delete, Search } from "@mui/icons-material";
import {
  Box,
  Button,
  Chip,
  CircularProgress,
  Grid,
  IconButton,
  Paper,
  TablePagination,
  Tooltip,
  Typography,
} from "@mui/material";
import { useEffect, useState } from "react";
import PlayCircleIcon from "@mui/icons-material/PlayCircle";
import { useDispatch, useSelector } from "react-redux";
import { deleteCapacitacion, getCapacitaciones } from "../../action";
import ModalAgregarCapacitacion from "./ModalAgregarCapacitacion";
import { CATEGORIAS_CAPACITACIONES } from "../../utils/constants";
import EditIcon from "@mui/icons-material/Edit";
import Swal from "sweetalert2";
import { red } from "@mui/material/colors";

const isMobile =
  !!navigator.userAgent.match(/iphone|android|blackberry/gi) || false;

const Capacitaciones = () => {
  const dispatch = useDispatch();
  const capacitaciones = useSelector(
    (state) => state.app.capacitaciones && state.app.capacitaciones.results
  );
  const data = useSelector((state) => state.app.capacitaciones);
  const requestGetCapacitaciones = useSelector(
    (state) => state.app.requestGetCapacitaciones
  );
  const requestDeleteCapacitacion = useSelector(
    (state) => state.app.requestDeleteCapacitacion
  );
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(20);
  const [openEditModal, setOpenEditModal] = useState(false);
  const [categoria, setCategoria] = useState(-1);
  const [currentCapacitacion, setCurrentCapacitacion] = useState(null);

  const [textFilter, setTextFilter] = useState("");

  const handleOpenEditModal = (activity) => {
    setCurrentCapacitacion(activity);
    setOpenEditModal(true);
  };
  const handleCloseEditModal = () => setOpenEditModal(false);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const onChangeBuscar = (e) => {
    setTextFilter(e.target.value);
  };

  const onClickVideo = (video) => {
    setCategoria(video.categoria);
    setCurrentCapacitacion(video);
    dispatch(getCapacitaciones(0, 100, video.categoria));
  };

  const onClickBack = () => {
    setCategoria(-1);
    setCurrentCapacitacion(null);
  };

  useEffect(() => {
    dispatch(getCapacitaciones(page, rowsPerPage, categoria));
  }, [page, rowsPerPage, categoria]);

  const reloadCapacitaciones = () =>
    dispatch(getCapacitaciones(page, rowsPerPage, categoria));

  const handleClickTag = (category) => {
    if (category === categoria) {
      setCategoria(-1);
    } else {
      setCategoria(category);
    }
  };

  const userData = useSelector((state) => state.app.itemUsuario);

  const showConfirmationDialog = (id) => {
    Swal.fire({
      title: `Eliminar capacitacion`,
      text: `Confirma que desea eliminar la capacitacion`,
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Confirmar",
    }).then((result) => {
      if (result.value) {
        dispatch(deleteCapacitacion(id));
      }
    });
  };

  if (requestDeleteCapacitacion === "SUCCESS") {
    Swal.fire({
      title: "Capacitacion eliminada",
      text: "Se ha eliminado la capacitacion exitosamente",
      icon: "success",
    }).then((result) => {
      if (result.value || result.dismiss === "backdrop") {
        reloadCapacitaciones();
        onClickBack();
      }
    });
  }

  return (
    <div className="contenido">
      {currentCapacitacion ? (
        <Grid container justifyContent="flex-start" className="alignLeft">
          <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
            <Button
              onClick={() => onClickBack()}
              className="main-btn back-btn"
              startIcon={<ArrowBack />}
            >
              Atrás
            </Button>
          </Grid>
        </Grid>
      ) : (
        <div className="header">
          <h2>Capacitaciones</h2>
        </div>
      )}

      <div className="main">
        {currentCapacitacion ? (
          <Grid container justifyContent={isMobile ? "center" : "flex-start"}>
            <Grid
              item
              xs={12}
              sm={12}
              md={12}
              lg={11}
              xl={10}
              className="alignLeft"
            >
              <Grid container justifyContent="flex-start">
                <Grid
                  item
                  xs={12}
                  sm={12}
                  md={8}
                  lg={8}
                  xl={8}
                  className="alignLeft"
                >
                  <h3>{CATEGORIAS_CAPACITACIONES[categoria]}</h3>
                </Grid>
              </Grid>
              <Grid
                container
                spacing={4}
                justifyContent={isMobile ? "center" : "flex-start"}
              >
                <Grid
                  item
                  xs={12}
                  sm={12}
                  md={8}
                  lg={8}
                  xl={8}
                  className="alignLeft"
                >
                  <iframe
                    title={currentCapacitacion.titulo}
                    src={`https://www.youtube.com/embed/${currentCapacitacion.idYoutube}`}
                    width={`100%`}
                    height={isMobile ? 300 : 500}
                    frameborder="0"
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                    allowfullscreen
                  ></iframe>
                  <h5>{currentCapacitacion.titulo}</h5>
                  <Typography>{currentCapacitacion.descripcion}</Typography>
                  {userData.tipoCuenta !== 10 && (
                    <Tooltip title="Editar" aria-label="editar">
                      <IconButton
                        //color="primary"
                        aria-label="Editar"
                        component="span"
                        onClick={() => handleOpenEditModal(currentCapacitacion)}
                      >
                        <EditIcon />
                      </IconButton>
                    </Tooltip>
                  )}
                  {userData.tipoCuenta !== 10 && (
                    <Tooltip title="Eliminar" aria-label="eliminar">
                      <IconButton
                        //color="primary"
                        aria-label="eliminar"
                        component="span"
                        onClick={() =>
                          showConfirmationDialog(currentCapacitacion.id)
                        }
                      >
                        <Delete sx={{ color: red[500] }} />
                      </IconButton>
                    </Tooltip>
                  )}
                </Grid>
                <Grid
                  item
                  xs={12}
                  sm={12}
                  md={4}
                  lg={4}
                  xl={4}
                  className={
                    requestGetCapacitaciones === "IDLE" ? "alignLeft" : ""
                  }
                >
                  {(requestGetCapacitaciones === "LOADING" ||
                    requestDeleteCapacitacion === "LOADING") && (
                    <CircularProgress sx={{ textAlign: "center" }} />
                  )}
                  {requestGetCapacitaciones === "IDLE" && (
                    <Grid
                      container
                      spacing={4}
                      justifyContent={isMobile ? "center" : "flex-start"}
                    >
                      <Grid
                        item
                        xs={12}
                        sm={12}
                        md={12}
                        lg={12}
                        xl={12}
                        className="alignLeft"
                      >
                        <h5>{CATEGORIAS_CAPACITACIONES[categoria]}</h5>
                        <Typography
                          variant="caption"
                          display="block"
                          gutterBottom
                        >
                          {capacitaciones && capacitaciones.length} videos
                        </Typography>
                      </Grid>
                      <Grid
                        item
                        xs={12}
                        sm={12}
                        md={12}
                        lg={12}
                        xl={12}
                        className="alignLeft"
                      >
                        {capacitaciones.map((v) => {
                          let duracionMinutos =
                            v.duracionMinutos.toString().length === 2
                              ? v.duracionMinutos
                              : "0" + v.duracionMinutos.toString();
                          let duracionSegundos =
                            v.duracionSegundos.toString().length === 2
                              ? v.duracionSegundos
                              : "0" + v.duracionSegundos.toString();
                          let duracion = `${duracionMinutos}:${duracionSegundos}`;
                          return (
                            <Grid
                              container
                              justifyContent="flex-start"
                              alignItems={"center"}
                              sx={{
                                pb: 1,
                                pt: 1,
                                backgroundColor:
                                  v.id === currentCapacitacion.id
                                    ? "#F5FFF8"
                                    : "white",
                              }}
                            >
                              <Grid
                                item
                                xs={12}
                                sm={4}
                                md={4}
                                lg={4}
                                xl={4}
                                className="alignLeft"
                                sx={{
                                  ml: 2,
                                  backgroundColor:
                                    v.id === currentCapacitacion.id
                                      ? "#F5FFF8"
                                      : "white",
                                }}
                              >
                                <Box
                                  className="cursor-pointer"
                                  sx={{
                                    height: "60px",
                                    backgroundColor: "#D9D9D9",
                                    "&:hover": {
                                      backgroundColor: "#b7b7b7",
                                      opacity: [0.9, 0.8, 0.7],
                                    },
                                  }}
                                  onClick={() => onClickVideo(v)}
                                >
                                  <div className="centered-icon-div">
                                    <PlayCircleIcon
                                      sx={{ color: "white", fontSize: 20 }}
                                    />
                                  </div>
                                </Box>
                              </Grid>
                              <Grid item className="alignLeft">
                                <Grid
                                  container
                                  justifyContent="flex-start"
                                  alignItems={"center"}
                                  sx={{ ml: 2 }}
                                >
                                  <Grid
                                    item
                                    xs={12}
                                    sm={12}
                                    md={12}
                                    lg={12}
                                    xl={12}
                                    className="alignLeft"
                                  >
                                    {v.titulo}
                                  </Grid>
                                  <Grid
                                    item
                                    xs={12}
                                    sm={12}
                                    md={12}
                                    lg={12}
                                    xl={12}
                                    className="alignLeft"
                                  >
                                    {duracion}
                                  </Grid>
                                </Grid>
                              </Grid>
                            </Grid>
                          );
                        })}
                      </Grid>
                    </Grid>
                  )}
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        ) : (
          <Grid container spacing={2}>
            <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
              <Grid container spacing={2} justifyContent={"space-between"}>
                <Grid item>
                  <h5>Videos</h5>
                </Grid>
                <Grid item>
                  <Grid
                    container
                    spacing={2}
                    justifyContent={"space-between"}
                    alignItems={"center"}
                  >
                    {userData.tipoCuenta !== 10 && (
                      <Grid item>
                        <button
                          className="boton"
                          onClick={() => handleOpenEditModal()}
                        >
                          Agregar capacitacion
                        </button>
                      </Grid>
                    )}

                    <Grid item>
                      <input
                        type="text"
                        placeholder="Buscar"
                        className="buscar"
                        onChange={onChangeBuscar}
                      />

                      <Search />
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
              <Grid
                container
                justifyContent={"flex-start"}
                spacing={1}
                sx={{ marginTop: "1rem" }}
              >
                {Object.keys(CATEGORIAS_CAPACITACIONES).map((t) => (
                  <Grid item>
                    <Chip
                      label={CATEGORIAS_CAPACITACIONES[t]}
                      onClick={() => handleClickTag(t)}
                      color={categoria === t ? "success" : "default"}
                    />
                  </Grid>
                ))}
              </Grid>
            </Grid>
            <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
              {requestGetCapacitaciones === "LOADING" && <CircularProgress />}
              {requestGetCapacitaciones === "IDLE" &&
                requestDeleteCapacitacion === "IDLE" &&
                capacitaciones &&
                capacitaciones !== null &&
                capacitaciones.length === 0 && (
                  <div>No se han subido capacitaciones todavia</div>
                )}
              {requestGetCapacitaciones === "IDLE" &&
                requestDeleteCapacitacion === "IDLE" &&
                capacitaciones &&
                capacitaciones !== null &&
                capacitaciones.length > 0 && (
                  <Grid container spacing={2}>
                    {capacitaciones.map((v) => (
                      <Grid item xs={12} sm={6} md={4} lg={4} xl={3}>
                        <Paper
                          className="cursor-pointer"
                          sx={{
                            backgroundImage: `url(http://img.youtube.com/vi/${v.idYoutube}/0.jpg)`,
                            backgroundPosition: `center`,
                            width: "100%",
                            height: "170px",
                            "&:hover": {
                              backgroundColor: "#b7b7b7",
                              opacity: [0.9, 0.8, 0.7],
                            },
                          }}
                          onClick={() => onClickVideo(v)}
                        >
                          <div className="thumbnail-icon">
                            <PlayCircleIcon
                              sx={{ color: "black", fontSize: 40 }}
                            />
                          </div>
                        </Paper>

                        <Typography sx={{ textAlign: "left" }}>
                          {v.titulo}
                        </Typography>
                      </Grid>
                    ))}
                  </Grid>
                )}
            </Grid>
            {requestGetCapacitaciones === "IDLE" &&
              capacitaciones &&
              capacitaciones.length > 0 && (
                <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                  <TablePagination
                    component="div"
                    count={data && data.totalResults}
                    page={page}
                    onPageChange={handleChangePage}
                    rowsPerPage={rowsPerPage}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                    SelectProps={{
                      inputProps: { "aria-label": "Cantidad" },
                      native: true,
                    }}
                    labelRowsPerPage={"Resultados por página"}
                  />
                </Grid>
              )}
          </Grid>
        )}
      </div>
      <ModalAgregarCapacitacion
        values={currentCapacitacion}
        isOpen={openEditModal}
        handleClose={handleCloseEditModal}
        reloadMethod={reloadCapacitaciones}
      />
    </div>
  );
};

export default Capacitaciones;
