import InfoPerfil from "./InfoPerfil";
import { Link, useLocation } from "react-router-dom";
import ListaActividades from "../Actividades/ListaActividades";
import Back from "../../assets/Back";
import { Divider, Grid } from "@mui/material";
import PropietariodNavegacionMobile from "./PropietarioNavegacionMobile";

const isMobile =
    !!navigator.userAgent.match(/iphone|android|blackberry/gi) || false;

const PerfilPropActividades = () => {
  let { search } = useLocation();
  let query = new URLSearchParams(search);
  let idCliente = query.get("id");  
  return (
    <div className="contenido">
      <Back urlPath="/propietarios" />
      <InfoPerfil idCliente={idCliente} />
      { !isMobile ? (
        <div className="opciones">
          <Link to={`/propietarios/propiedades?id=${idCliente}`}>
            <h3
              className="opcionPropiedades"
              id={
                window.location.pathname.includes("propiedades")
                  ? "active1"
                  : "notActive1"
              }
            >
              Propiedades
            </h3>
          </Link>
          <Link to={`/propietarios/actividades?id=${idCliente}`}>
            <h3
              className="opcionActividades"
              id={
                window.location.pathname.includes("actividades")
                  ? "active1"
                  : "notActive1"
              }
            >
              Actividades
            </h3>
          </Link>
        </div>
      ) : (
        <PropietariodNavegacionMobile id={idCliente} />
      )}
      
      {isMobile && (
        <Grid container justifyContent={"flex-start"} className="alignLeft" sx={{marginTop: 2}}>
          <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
            <Divider variant="middle" sx={{ mb: 2, backgroundColor: "black" }} />
          </Grid>
          <Grid item>
            <h2>Actividades</h2>
          </Grid>
        </Grid>
      )}
      <div className="main">
        <ListaActividades idCliente={idCliente} idPropiedad={""} idProspecto={""} />
      </div>
    </div>
  );
};

export default PerfilPropActividades;
