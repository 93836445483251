//TODO: Cambiar nombre de este componente
import { Link } from "react-router-dom";
import FiltroProspectos from "./../FiltroProspectos";
import { useDispatch, useSelector } from "react-redux";
import { useEffect, useState } from "react";
import {
  getProspectos,
  putProspectoCA,
  uploadArchivoProspectos,
} from "../../../action";
import {
  Backdrop,
  CircularProgress,
  Grid,
  Paper,
  Table,
  TableBody,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  styled,
} from "@mui/material";
import FormProspecto from "../FormProspecto";
import ProspectoNavegacionMobile from "../ProspectoNavegacionMobile";
import Swal from "sweetalert2";
import { FilaProspecto } from "../FilaProspecto";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import FileUpload from "../../../assets/FileUpload";

const isMobile =
  !!navigator.userAgent.match(/iphone|android|blackberry/gi) || false;

export const ALLOWED_MIME_TYPES = [
  "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
  "application/vnd.ms-excel",
];

const columnsNonMobile = [
  { id: "nombre", label: "Nombre", minWidth: 170, align: "center" },
  { id: "estado", label: "Estado", minWidth: 100, align: "center" },
  { id: "contacto", label: "Contacto", minWidth: 140, align: "center" },
  {
    id: "requerimientos",
    label: "Requerimientos",
    minWidth: 70,
    align: "center",
  },
  { id: "perfil", label: "Perfil", minWidth: 80, align: "center" },
  { id: "acciones", label: "Acciones", minWidth: 80, align: "center" },
];

const columnsMobile = [
  { id: "nombre", label: "Nombre", minWidth: 90, align: "center" },
  { id: "contacto", label: "Contacto", minWidth: 160, align: "center" },
  { id: "acciones", label: "Acciones", minWidth: 1, align: "center" },
];

const columns = isMobile ? columnsMobile : columnsNonMobile;

const StyledTableHead = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: "#00a62b",
    color: theme.palette.common.white,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));

const ListProspComprador = () => {
  const [subirArchivo, setSubirArchivo] = useState(false);
  const [archivo, setArchivo] = useState(null);
  const [filtroValues, setFiltroValues] = useState({
    tipoOperacion: localStorage.getItem("filtroProspectoTipoOperacion")
      ? localStorage.getItem("filtroProspectoTipoOperacion")
      : "",
    tipoPropiedad: localStorage.getItem("filtroProspectoTipoPropiedad")
      ? localStorage.getItem("filtroProspectoTipoPropiedad")
      : "",
    comuna: localStorage.getItem("filtroProspectoComuna")
      ? localStorage.getItem("filtroProspectoComuna")
      : "",
    barrio: localStorage.getItem("filtroProspectoBarrio")
      ? localStorage.getItem("filtroProspectoBarrio")
      : "",
    dormitorios: localStorage.getItem("filtroProspectoDormitorios")
      ? localStorage.getItem("filtroProspectoDormitorios")
      : 0,
    banios: localStorage.getItem("filtroProspectoBanios")
      ? localStorage.getItem("filtroProspectoBanios")
      : 0,
  });
  const dispatch = useDispatch();
  const prospectos = useSelector(
    (state) => state.app.prospectosCA && state.app.prospectosCA.results
  );
  const requestGetProspectos = useSelector(
    (state) => state.app.requestGetProspectosCA
  );
  const [isFormModalOpen, setFormModalOpen] = useState(false);

  const handleOpenFormModal = () => {
    setFormModalOpen(true);
  };
  const handleCloseFormModal = () => {
    setFormModalOpen(false);
  };
  const data = useSelector((state) => state.app.prospectosCA);
  const errorMessage = useSelector((state) => state.app.errorMessage);
  const requestStateUpdate = useSelector(
    (state) => state.app.requestUpdateProspecto
  );
  const requestStateDelete = useSelector(
    (state) => state.app.requestDeleteProspecto
  );
  const requestUploadArchivoProspectos = useSelector(
    (state) => state.app.requestUploadArchivoProspectos
  );
  const userData = useSelector((state) => state.app.itemUsuario);
  const [page, setPage] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(10);

  const [openBackdrop, setOpenBackdrop] = useState(true);
  const handleCloseBackdrop = () => setOpenBackdrop(false);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };
  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(1);
  };

  useEffect(() => {
    dispatch(
      getProspectos(
        "ca",
        page,
        rowsPerPage,
        filtroValues.tipoOperacion,
        filtroValues.tipoPropiedad,
        filtroValues.comuna,
        filtroValues.barrio,
        filtroValues.dormitorios,
        filtroValues.banios
      )
    );
  }, [page, rowsPerPage]);

  const reloadProspectos = () => {
    dispatch(
      getProspectos(
        "ca",
        page,
        rowsPerPage,
        filtroValues.tipoOperacion,
        filtroValues.tipoPropiedad,
        filtroValues.comuna,
        filtroValues.barrio,
        filtroValues.dormitorios,
        filtroValues.banios
      )
    );
  };

  const pausarProspecto = (prospecto) => {
    Swal.fire({
      title: `Pausar prospecto`,
      text: `Confirma que desea pausar el prospecto ${prospecto.nombres} ${prospecto.apellidos}`,
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Confirmar",
    }).then((result) => {
      if (result.value) {
        const data = {
          ...prospecto,
          estadoProcesoProspecto: 4,
        };
        dispatch(putProspectoCA(prospecto.id, data));
      }
    });
  };

  if (requestStateUpdate === "SUCCESS") {
    Swal.fire({
      title: "Prospecto actualizado",
      text: "Se ha actualizado el prospecto exitosamente",
      icon: "success",
    }).then((result) => {
      if (result.value || result.dismiss === "backdrop") {
        reloadProspectos();
      }
    });
  }
  if (requestUploadArchivoProspectos === "SUCCESS") {
    Swal.fire({
      title: "Prospectos subidos",
      text: "Se han subido los prospectos exitosamente",
      icon: "success",
    }).then((result) => {
      if (result.value || result.dismiss === "backdrop") {
        reloadProspectos();
      }
    });
  }

  if (requestStateDelete === "SUCCESS") {
    Swal.fire({
      title: "Prospecto eliminado",
      text: "Se ha eliminado el prospecto exitosamente",
      icon: "success",
    }).then((result) => {
      if (result.value || result.dismiss === "backdrop") {
        reloadProspectos();
        handleCloseBackdrop();
      }
    });
  }
  if (requestUploadArchivoProspectos === "ERROR") {
    Swal.fire({
      title: "Error",
      text: errorMessage,
      icon: "error",
    })
  }

  const onDrop = (files) => {
    if (files && files.length > 0) {
      setArchivo(files[0])
    }
  };

  const subirArchivoYapo = () => {
    let formData = new FormData();
    formData.append("file", archivo);
    dispatch(uploadArchivoProspectos(formData));
  };

  return (
    <div className="contenido">
      <div className="header">
        <h2>Prospectos</h2>
        <button className="boton" onClick={() => handleOpenFormModal()}>
          Agregar Prospecto
        </button>
      </div>
      {isMobile ? (
        <ProspectoNavegacionMobile />
      ) : (
        <div className="opciones" style={{ borderBottom: "1px solid" }}>
          <h3 className="vendedor">
            <Link
              id={
                window.location.pathname.includes("comprador")
                  ? "notActive1"
                  : "active1"
              }
              to="/prospectos/vendedor"
            >
              Vendedor/Arrendador
            </Link>
          </h3>
          <h3 className="comprador">
            <Link
              id={
                window.location.pathname.includes("comprador")
                  ? "active1"
                  : "notActive1"
              }
              to="/prospectos/comprador"
            >
              Comprador/Arrendatario
            </Link>
          </h3>
        </div>
      )}

      <FiltroProspectos
        page={page}
        pageSize={rowsPerPage}
        tipoProspecto={"ca"}
        filtroValues={filtroValues}
        setFiltroValues={setFiltroValues}
      />
      <div className="main2">
        {userData && userData.tipoCuenta !== 10 && (
          <div className="barraNav2">
            <button
              className="boton"
              onClick={() => setSubirArchivo(!subirArchivo)}
            >
              Subir leads Yapo
            </button>
          </div>
        )}

        {subirArchivo && (
          <Grid container sx={{mb: 4}}>
            <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
              <FileUpload
                name="archivos"
                uploadMultiple={false}
                acceptedFiles={ALLOWED_MIME_TYPES}
                onDrop={onDrop}
                sizeLimit={5000000}
                maxFiles={1}
                showFiles={true}
              />
            </Grid>
            <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
              El archivo debe tener formato xls o xlsx
            </Grid>
            <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
              Debe contener las cabeceras: Fecha, Hora, Tipo, Codigo, Nombre, Email, Telefono, Url
            </Grid>
            {subirArchivo && archivo && (
              <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                <button onClick={() => subirArchivoYapo()}>
                  Subir archivo
                </button>
              </Grid>
            )}
          </Grid>
        )}

        {requestStateDelete === "LOADING" || requestUploadArchivoProspectos === "LOADING" && (
          <Backdrop
            sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
            open={openBackdrop}
            onClick={handleCloseBackdrop}
          >
            <CircularProgress color="inherit" />
          </Backdrop>
        )}
        {requestGetProspectos === "LOADING" && <CircularProgress />}
        {requestGetProspectos === "IDLE" && prospectos && (
          <Paper component={Paper} style={{ marginBottom: "2rem" }}>
            <TableContainer>
              <Table size={isMobile ? "small" : "medium"}>
                <TableHead>
                  <TableRow>
                    {columns.map((column) => (
                      <StyledTableHead
                        key={column.id}
                        align={column.align}
                        style={{ minWidth: column.minWidth }}
                      >
                        {column.label}
                      </StyledTableHead>
                    ))}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {prospectos.length === 0 ? (
                    <div>No se han encontrado resultados para su búsqueda</div>
                  ) : (
                    prospectos.map((prospecto, i) => (
                      <FilaProspecto
                        prospecto={prospecto}
                        i={i}
                        isMobile={isMobile}
                        tipo="ca"
                        handleOpenFormModal={handleOpenFormModal}
                        pausarProspecto={pausarProspecto}
                      />
                    ))
                  )}
                </TableBody>
              </Table>
            </TableContainer>
            {requestGetProspectos === "IDLE" &&
              prospectos &&
              prospectos.length > 0 && (
                <TablePagination
                  component="div"
                  count={data && data.totalResults}
                  page={page}
                  onPageChange={handleChangePage}
                  rowsPerPage={rowsPerPage}
                  onRowsPerPageChange={handleChangeRowsPerPage}
                  SelectProps={{
                    inputProps: { "aria-label": "Cantidad" },
                    native: true,
                  }}
                  labelRowsPerPage={""}
                />
              )}
          </Paper>
        )}
      </div>
      <FormProspecto
        isOpen={isFormModalOpen}
        handleClose={handleCloseFormModal}
        reloadMethod={reloadProspectos}
      />
    </div>
  );
};

export default ListProspComprador;
