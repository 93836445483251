import { useDispatch, useSelector } from "react-redux";
import {
  CircularProgress,
  Grid,
  TablePagination,
  Paper,
  Table,
  TableBody,
  TableContainer,
  TableHead,
  TableRow,
  styled,
} from "@mui/material";
import {
  createTarjetaProyecto,
  deleteTarjetaProyectoByIdProyecto,
  fetchGetAllUsuarios,
  getProyectosPaginados,
} from "../../action";
import { useEffect, useState } from "react";
import Swal from "sweetalert2";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import { FilaProyecto } from "./FilaProyecto";
import { Link } from "react-router-dom/cjs/react-router-dom";

const isMobile =
  !!navigator.userAgent.match(/iphone|android|blackberry/gi) || false;

const columnsNonMobile = [
  { id: "nombre", label: "Nombre", minWidth: 170, align: "center" },
  { id: "inmobiliaria", label: "Inmobiliaria", minWidth: 100, align: "center" },
  { id: "valorDesde", label: "Valor desde", minWidth: 70, align: "center" },
  { id: "destacado", label: "Destacado", minWidth: 70, align: "center" },
  { id: "tipo", label: "Tipo", minWidth: 70, align: "center" },
  { id: "comuna", label: "Comuna", minWidth: 70, align: "center" },
  { id: "direccion", label: "Direccion", minWidth: 70, align: "center" },
  { id: "acciones", label: "Acciones", minWidth: 80, align: "center" },
];

const columnsMobile = [
  { id: "nombre", label: "Nombre", minWidth: 90, align: "center" },
  { id: "inmobiliaria", label: "Inmobiliaria", minWidth: 90, align: "center" },
  { id: "acciones", label: "Acciones", minWidth: 1, align: "center" },
];

const columns = isMobile ? columnsMobile : columnsNonMobile;

const StyledTableHead = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: "#00a62b",
    color: theme.palette.common.white,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));

const ListaProyectos = () => {
  const dispatch = useDispatch();
  const proyectos = useSelector(
    (state) => state.app.proyectos && state.app.proyectos.results
  );
  const data = useSelector(
    (state) => state.app.proyectos && state.app.proyectos
  );
  const requestGetProyectos = useSelector(
    (state) => state.app.requestGetProyectos
  );
  
  const requestDeleteTarjetaProyecto = useSelector((state) => state.app.requestDeleteTarjetaProyecto);
  const requestPostTarjetaProyecto = useSelector((state) => state.app.requestPostTarjetaProyecto);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [isLoading, setIsLoading] = useState(false);
  
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };
  useEffect(() => {
    dispatch(fetchGetAllUsuarios());
    dispatch(getProyectosPaginados(page, rowsPerPage));
  }, [page, rowsPerPage]);

  useEffect(() => {
    if (requestGetProyectos === "LOADING" || requestDeleteTarjetaProyecto === "LOADING" || requestPostTarjetaProyecto === "LOADING") {
      setIsLoading(true);
    } else {
      setIsLoading(false);
    }
  }, [requestGetProyectos, requestDeleteTarjetaProyecto, requestPostTarjetaProyecto]);
  
  if (requestDeleteTarjetaProyecto === "SUCCESS") {
    Swal.fire({
      title: "Tarjeta proyecto deshabilitada",
      text: "Se ha deshabilitado la tarjeta proyecto exitosamente",
      icon: "success",
    }).then((result) => {
      if (result.value || result.dismiss === "backdrop") {
        reload();
      }
    });
  }
  
  if (requestPostTarjetaProyecto === "SUCCESS") {
    Swal.fire({
      title: "Tarjeta proyecto habilitada",
      text: "Se ha habilitado la tarjeta proyecto exitosamente",
      icon: "success",
    }).then((result) => {
      if (result.value || result.dismiss === "backdrop") {
        reload();
      }
    });
  }

  const reload = () => {
    dispatch(getProyectosPaginados(page, rowsPerPage));
  };

  const showConfirmationDialog = (row, isDelete) => {
    let verb = "Generar";
    if (isDelete) {
      verb = "Deshabilitar";
    }
    Swal.fire({
      title: `${verb} tarjeta proyecto`,
      text: `Confirma que desea ${verb.toLowerCase()} la tarjeta para este proyecto`,
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Confirmar",
    }).then((result) => {
      if (result.value) {
        if (isDelete) {
          dispatch(deleteTarjetaProyectoByIdProyecto(row.id));
        } else {
          dispatch(createTarjetaProyecto(row.id));
        }
      }
    });
  };

  return (
    <div className="contenido">
      <div className="header">
        <h2>Proyectos</h2>
      </div>
      <Grid container justifyContent="flex-start">
        <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
          <Grid
            container
            className="alignLeft"
            justifyContent="flex-start"
            alignItems="center"
          >
            <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
              <Link to="/proyectos/agregar">
                <button className="boton">
                  Agregar proyecto
                </button>
              </Link>              
            </Grid>
          </Grid>          

          <Grid
            container
            spacing={1}
            justifyContent="center"
            alignItems="center"
            sx={{ mt: 2, mb: 4 }}
          >
            <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
              {isLoading && <CircularProgress />}
              {requestGetProyectos === "IDLE" &&
                !Swal.isVisible() &&
                !isLoading &&
                proyectos && (
                  <Paper
                    sx={{
                      width: "100%",
                      overflow: "hidden",
                      marginBottom: "2rem",
                    }}
                  >
                    <TableContainer component={Paper}>
                      <Table size={isMobile ? "small" : "medium"}>
                        <TableHead>
                          <TableRow>
                            {columns.map((column) => (
                              <StyledTableHead
                                key={column.id}
                                align={column.align}
                                style={{ minWidth: column.minWidth }}
                              >
                                {column.label}
                              </StyledTableHead>
                            ))}
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          {proyectos.length === 0 ? (
                            <div>
                              No se han encontrado resultados para su búsqueda
                            </div>
                          ) : (
                            proyectos.map((proyecto, i) => (
                              <FilaProyecto
                                proyecto={proyecto}
                                i={i}
                                isMobile={isMobile}
                                showConfirmationDialog={showConfirmationDialog}
                              />
                            ))
                          )}
                        </TableBody>
                      </Table>
                    </TableContainer>
                    {requestGetProyectos === "IDLE" &&
                      proyectos &&
                      proyectos.length > 0 && (
                        <TablePagination
                          component="div"
                          count={data && data.totalResults}
                          page={page}
                          onPageChange={handleChangePage}
                          rowsPerPage={rowsPerPage}
                          onRowsPerPageChange={handleChangeRowsPerPage}
                          SelectProps={{
                            inputProps: { "aria-label": "Cantidad" },
                            native: true,
                          }}
                          labelRowsPerPage={""}
                        />
                      )}
                  </Paper>
                )}
            </Grid>
          </Grid>
          {/*
<FormAgregarActividad
          idCliente={idCliente}
          idPropiedad={idPropiedad}
          idProspecto={idProspecto}
          reloadMethod={reloadActividades}
          isOpen={openEditModal}
          handleClose={handleCloseEditModal}
          values={currentActivity}
        />
        <DetalleActividad
          isOpen={openDetailModal}
          handleClose={handleCloseDetailModal}
          values={currentActivity}
          getNombreUsuario={getNombreUsuario}
        />
                      */}
        </Grid>
      </Grid>
    </div>
  );
};

export default ListaProyectos;
