import ListaActividades from "../../Actividades/ListaActividades";

const ActividadesPropiedad = ({id}) => {
  return (
    <div className="main">
      <h3 className="alignLeft">Actividades</h3>
      <ListaActividades
        idPropiedad={id}
        idCliente={""}
        idProspecto={""}
      />
    </div>
  );
};

export default ActividadesPropiedad;