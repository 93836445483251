import "./css/styles.css";

import PerfilPropietario from "./components/Propietarios/PerfilPropietario";
import { BrowserRouter, Switch, Route, Redirect } from "react-router-dom";
import PerfilPropActividades from "./components/Propietarios/PerfilPropActividades";
import PerfilProspecto from "./components/Prospectos/Vendedor/PerfilProspecto";
import ListMisPropiedades from "./components/Propiedades/ListMisPropiedades";
import PerfilComprador from "./components/Prospectos/Comprador/PerfilComprador";
import ListadoPropietarios from "./components/Propietarios/ListadoPropietarios";
import SideMenu from "./components/SideMenu/SideMenu";
import ListOtrasPropiedades from "./components/Propiedades/ListOtrasPropiedades";
import Documentos from "./components/Recursos/Documentos";
import Difusion from "./components/Recursos/Difusion";
import Reportes from "./components/Recursos/Reportes";
import ActividadesComprador from "./components/Prospectos/Comprador/ActividadesComprador";
import Agenda from "./components/Agenda/Agenda";
import Logout from "./components/SignIn/Logout";
import AgregarPropietario from "./components/Propietarios/AgregarPropietario";
import Capacitaciones from "./components/Recursos/Capacitaciones";
import Home from "./components/Home/Home";
import Tasacion from "./components/Tasacion/Tasacion";
import IndexSignIn from "./components/SignIn/Index";
import IndexProspectos from "./components/Prospectos/IndexProspectos";
import ActividadesVendedor from "./components/Prospectos/Vendedor/ActividadesVendedor";
import IndexFichaPropiedad from "./components/Propiedades/FichaPropiedad/IndexFichaPropiedad";
import ListaProyectos from "./components/Proyectos/ListaProyectos";
import ListaInmobiliarias from "./components/Inmobiliarias/ListaInmobiliarias";
import FormProyecto from "./components/Proyectos/FormProyecto";
import IndexReportes from "./components/Recursos/IndexReportes";

function isJsonString(str) {
  try {
      JSON.parse(str);
  } catch (e) {
      return false;
  }
  return true;
}

const IsAuthenticated = () => {
  const token = isJsonString(localStorage.getItem('user')) && JSON.parse(localStorage.getItem('user')) !== null && 
  JSON.parse(localStorage.getItem('user')).token !== null 
    ? JSON.parse(localStorage.getItem('user')).token 
    : "";
  if (token === "") {
    return false;
  }
  return true;
  /*
  const decodedToken = decodeToken(token)
  
  if (Date.now() > decodedToken.exp * 1000) {
    console.log("Token expired");
  }
  
  console.log(localStorage.getItem('user'))
  console.log(decodedToken)
  if (isExpired(token)) {
    console.log("expirao!")
    localStorage.removeItem("user");
    localStorage.removeItem("bearer");
  }
  //console.log(decodedToken)
  //console.log("expirado", isExpired(token))
  return !isExpired(token);
  */
}

function PrivateRoute({ children, ...rest }) {
  return (
    <Route
      {...rest}
      render={props =>
        IsAuthenticated() ? (
          children
        ) : (
          <Redirect
            to={{
              pathname: "/login",
            }}
          />
        )
      }
    />
  );
}


function App() {
  return (
    <BrowserRouter>
      <div className="contenedor">
        <Switch>
          <PrivateRoute exact path="/">
            <SideMenu />
            <Home />
          </PrivateRoute>
          <PrivateRoute exact path="/home">
            <SideMenu />
            <Home />
          </PrivateRoute>
          <PrivateRoute exact path="/propietarios">
            <SideMenu />
            <ListadoPropietarios />
          </PrivateRoute>
          <PrivateRoute exact path="/propietarios/agregar">
            <SideMenu />
            <AgregarPropietario />
          </PrivateRoute>
          <PrivateRoute path="/propietarios/propiedades">
            <SideMenu />
            <PerfilPropietario />
          </PrivateRoute>
          <PrivateRoute exact path="/propietarios/actividades">
            <SideMenu />
            <PerfilPropActividades />
          </PrivateRoute>
          <PrivateRoute exact path="/prospectos/vendedor">
            <SideMenu />
            <IndexProspectos />
          </PrivateRoute>
          <PrivateRoute exact path="/prospectos/comprador">
            <SideMenu />
            <IndexProspectos />
          </PrivateRoute>
          <PrivateRoute exact path="/prospectos/comprador/requerimientos">
            <SideMenu />
            <PerfilComprador />
          </PrivateRoute>
          <PrivateRoute exact path="/prospectos/comprador/actividades">
            <SideMenu />
            <ActividadesComprador />
          </PrivateRoute>
          <PrivateRoute exact path="/prospectos/vendedor/propiedad">
            <SideMenu />
            <PerfilProspecto />
          </PrivateRoute>
          <PrivateRoute exact path="/prospectos/vendedor/actividades">
            <SideMenu />
            <ActividadesVendedor />
          </PrivateRoute>
          <PrivateRoute exact path="/mispropiedades">
            <SideMenu />
            <ListMisPropiedades />
          </PrivateRoute>
          <PrivateRoute exact path="/otraspropiedades">
            <SideMenu />
            <ListOtrasPropiedades />
          </PrivateRoute>
          <PrivateRoute exact path="/proyectos">
            <SideMenu />
            <ListaProyectos />
          </PrivateRoute>
          <PrivateRoute exact path="/proyectos/agregar">
            <SideMenu />
            <FormProyecto />
          </PrivateRoute>
          <PrivateRoute exact path="/proyectos/:id">
            <SideMenu />
            <FormProyecto />
          </PrivateRoute>
          <PrivateRoute exact path="/inmobiliarias">
            <SideMenu />
            <ListaInmobiliarias />
          </PrivateRoute>
          <PrivateRoute exact path="/mispropiedades/datosFicha">
            <SideMenu />
            <IndexFichaPropiedad />
          </PrivateRoute>
          <PrivateRoute exact path="/agenda">
            <SideMenu />
            <Agenda />
          </PrivateRoute>
          <PrivateRoute exact path="/tasaciones">
            <SideMenu />
            <Tasacion />
          </PrivateRoute>
          <PrivateRoute exact path="/recursos/documentos">
            <SideMenu />
            <Documentos />
          </PrivateRoute>
          <PrivateRoute exact path="/recursos/difusion">
            <SideMenu />
            <Difusion />
          </PrivateRoute>
          <PrivateRoute exact path="/recursos/capacitaciones">
            <SideMenu />
            <Capacitaciones />
          </PrivateRoute>
          <PrivateRoute exact path="/recursos/reportes">
            <SideMenu />
            <IndexReportes />
          </PrivateRoute>
          <Route exact path="/login">
            <IndexSignIn />
          </Route>
          <Route exact path="/logout">
            <Logout />
          </Route>
        </Switch>
      </div>
    </BrowserRouter>
  );
}

export default App;
