import { Button, CircularProgress, Grid } from "@mui/material";
import { Field, Form, Formik } from "formik";
import { useSelector } from "react-redux";
import FirmantesForm from "./FirmantesForm";

export const FormGenerarActaEntrega = ({
  idPropiedad,
  idProspecto,
  handleSubmit,
  handleClose,
    //datosAlvaro,
  //guardarDatosAlvaro,
  //editDatosAlvaro,
  //setEditDatosAlvaro,
  onChangePropiedad,
  onChangeIdProspecto,
  validRut,
  prospecto,
  validationMessages,
  usarDocumentosAlvaro,
  onChangeUsarDocumentosAlvaro
}) => {
  const requestPropiedades = useSelector(
    (state) => state.app.requestPropiedadesPaginadas
  );
  const requestGetProspectos = useSelector(
    (state) => state.app.requestGetProspectosCA
  );
  const prospectos = useSelector(
    (state) => state.app.prospectosCA && state.app.prospectosCA.results
  );
  const propiedades = useSelector(
    (state) =>
      state.app.propiedadesPaginadas && state.app.propiedadesPaginadas.results
  );

  const submitLocal = (formValues) => {
    const data = {
      ...formValues,
      firmantes: [
        {
          rut: prospecto.rut,
          nombres: prospecto.nombres,
          apellidos: prospecto.apellidos,
          email: prospecto.email,
          //email: "cata.navarrete.95@gmail.com",
          telefono: prospecto.telefono,
          urlFirma: "",
          firmado: false,
          documentosIdentidad: [
            {
              tipoDocumentoIdentidad: 0,
              archivo: formValues.archivoVersoIdentidad,
              asignado: false,
            },
            {
              tipoDocumentoIdentidad: 1,
              archivo: formValues.archivoReversoIdentidad,
              asignado: false,
            },
          ],
        }
      ]
    }
    handleSubmit(data);
  }

  return (
    <Formik
      initialValues={{
        idPropiedad,
        idProspecto,
        archivoVersoIdentidad: "",
        archivoReversoIdentidad: "",
        //archivoCertificadoDocumentoDominio: "",
        archivoVersoIdentidadPropins: "",
        archivoReversoIdentidadPropins: "",
      }}
      onSubmit={submitLocal}
    >
      {({ values, errors, handleSubmit, setFieldValue }) => (
        <>
          <Form onSubmit={handleSubmit} className="form-container">
            <Grid container>
              {requestPropiedades === "LOADING" && <CircularProgress />}
              {requestPropiedades === "IDLE" && (
                <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                  <label className="milabel">Propiedad</label>
                  <Field
                    name="idPropiedad"
                    as="select"
                    className="miSelect"
                    value={idPropiedad}
                    onChange={onChangePropiedad}
                  >
                    <option value={""}>- Seleccione -</option>
                    {propiedades.map((c) => {
                      return (
                        <option value={c.id}>
                          {c.nombreCalle} {c.numero}
                        </option>
                      );
                    })}
                  </Field>
                </Grid>
              )}
              {requestGetProspectos === "LOADING" && <CircularProgress />}
              {requestGetProspectos === "IDLE" && (
                <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                  <label className="milabel">Prospecto comprador</label>
                  <Field
                    name="idProspecto"
                    as="select"
                    className="miSelect"
                    value={idProspecto}
                    onChange={onChangeIdProspecto}
                  >
                    <option value={""}>- Seleccione -</option>
                    {prospectos.map((c) => {
                      return (
                        <option value={c.id}>
                          {c.nombres} {c.apellidos}
                        </option>
                      );
                    })}
                  </Field>
                </Grid>
              )}
            </Grid>
            {idPropiedad && idProspecto && prospecto && (
              <FirmantesForm                
                setFieldValue={setFieldValue}
//datosAlvaro={datosAlvaro}
                //editDatosAlvaro={editDatosAlvaro}
                //setEditDatosAlvaro={setEditDatosAlvaro}
                //guardarDatosAlvaro={guardarDatosAlvaro}
                firmante={prospecto}                
                editPath={`/prospectos/comprador/requerimientos?id=${prospecto.id}`}
                usarDocumentosAlvaro={usarDocumentosAlvaro}
                onChangeUsarDocumentosAlvaro={onChangeUsarDocumentosAlvaro}
              />
            )}
            {validationMessages && validationMessages.length > 0 && (
              <Grid container sx={{ mt: 2 }}>
                <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                  Se han encontrado los siguientes problemas con la validación:
                </Grid>
                {validationMessages.map((v) => (
                  <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                    {v}
                  </Grid>
                ))}
              </Grid>
            )}

            <Grid container justifyContent="flex-end" sx={{ mt: 2 }}>
              <Grid item>
                <Button onClick={handleClose} className="main-btn">
                  Cancelar
                </Button>
              </Grid>
              <Grid item>
                <Button
                  type="submit"
                  className="main-btn"
                  variant="contained"
                  color="success"
                >
                  Guardar
                </Button>
              </Grid>
            </Grid>
          </Form>
        </>
      )}
    </Formik>
  );
};
