import { Close } from "@mui/icons-material";
import { Backdrop, Box, Button, CircularProgress, Grid, IconButton, Modal } from "@mui/material";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { solicitarCanje } from "../../action";
import Swal from "sweetalert2";

const isMobile =
    !!navigator.userAgent.match(/iphone|android|blackberry/gi) || false;

const style = {
  position: "absolute",
  top: "0px",
  left: "50%",
  transform: "translate(-50%, 0%)",
  width: 500,
  bgcolor: "background.paper",
  boxShadow: 24,
  p: 4,
  //maxHeight: '90%'
};

const styleMobile = {
  position: "absolute",
  top: "0px",
  left: "0px",
  //transform: "translate(-50%, 0%)",
  width: "100vw",
  bgcolor: "background.paper",
  boxShadow: 24,
  p: 4,
  //maxHeight: '90%'
};

const InfoBroker = ({ handleClose, isOpen, brokerData, idPropiedad }) => {
  const dispatch = useDispatch();
  const request = useSelector((state) => state.app.requestSolicitarCanje);
  const [openBackdrop, setOpenBackdrop] = useState(false);

  const handleCloseBackdrop = () => {
    setOpenBackdrop(false);
  };

  if (request === "SUCCESS") {
    handleClose();
    Swal.fire({
      title: "Solicitud enviada",
      text: "Se ha enviado la solicitud de canje exitosamente",
      icon: "success",
    });
  }

  return (
    <Modal
      disableScrollLock={false}
      open={isOpen}
      onClose={handleClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"   
      sx={{
        overflowY: "auto",
        top: isMobile ? "0px" : "10px",
        maxHeight: isMobile ? "100%" : "95%",
      }}
    >
      <Box sx={isMobile ? styleMobile : style}>
        {request === "LOADING" && (
          <Backdrop
            sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
            open={true}
            onClick={handleCloseBackdrop}
          >
            <CircularProgress color="inherit" />
          </Backdrop>
        )}
        <Grid container justifyContent="space-between" alignItems={"center"} sx={{marginBottom: 2}}>
          <Grid item xs={10} sm={10} md={10} lg={10} xl={10}>
            <h4>Solicitar canje</h4>
          </Grid>
          <Grid item>
            <IconButton
              color="primary"
              aria-label="copiar"
              component="span"
              onClick={() => handleClose()}
            >
              <Close sx={{ fontSize: "20pt" }} />
            </IconButton>
          </Grid>
        </Grid>        
        
        <Grid container justifyContent="flex-end" sx={{ mt: 2 }}>
          <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
            Nombre: {brokerData && brokerData.nombres} {brokerData && brokerData.apellidos}
          </Grid>
          <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
            Correo electronico: {brokerData && brokerData.email}
          </Grid>
          <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
            Telefono: {brokerData && brokerData.telefono}
          </Grid>
        </Grid>

        <Grid container justifyContent="flex-end" sx={{ mt: 2 }}>
          <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
            Confirme que desea solicitar canje
          </Grid>
        </Grid>

        <Grid container justifyContent="center" sx={{ mt: 2 }}>
          <Grid item>
            <Button
            onClick={() => dispatch(solicitarCanje(idPropiedad))}
              type="submit"
              className="main-btn"
              variant="contained"
              color="success"
            >
              Solicitar canje
            </Button>
          </Grid>
        </Grid>
      </Box>
    </Modal>
  );

};

export default InfoBroker;
