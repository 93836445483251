import * as React from "react";
import { Formik, Form, Field } from "formik";
import { useEffect, useState } from "react";
import {
  Backdrop,
  Button,
  Checkbox,
  CircularProgress,
  FormControlLabel,
  FormGroup,
  Grid,
  IconButton,
  ImageList,
  ImageListItem,
  ImageListItemBar,
  Typography,
} from "@mui/material";
import { BARRIOS_COMUNAS } from "../../utils/constants";
import Dropzone from "react-dropzone";
import { Delete, Star } from "@mui/icons-material";
import { red, yellow } from "@mui/material/colors";
import moment from "moment";
import Swal from "sweetalert2";
import { useDispatch, useSelector } from "react-redux";
import { fetchAddPropiedad, fetchPostUpdatePropiedad } from "../../action";
import { useHistory } from "react-router-dom";
import * as Yup from "yup";
import FieldError from "../../utils/FieldError";
import { GoogleMap, useJsApiLoader } from "@react-google-maps/api";
import { MarkerF } from "@react-google-maps/api";
import utilsFunctions from "../../utils/utilsFunctions";

const validate = Yup.object().shape({
  idCliente: Yup.string().required("Ingrese un cliente"),
  observacionesPublicas: Yup.string().required("Ingrese una descripcion"),
  idPlan: Yup.string().required("Ingrese un plan"),
  tipoPropiedad: Yup.string().required("Ingrese un tipo de propiedad"),
  dormitorios: Yup.number()
    .typeError("Ingrese un valor numerico")
    .min(0, "Ingrese valor mayor a 0")
    .required("Ingrese dormitorios"),
  banio: Yup.number()
    .typeError("Ingrese un valor numerico")
    .min(0, "Ingrese valor mayor a 0")
    .required("Ingrese banos"),
  cantEstacionamiento: Yup.number()
    .typeError("Ingrese un valor numerico")
    .min(0, "Ingrese valor mayor a 0")
    .required("Ingrese estacionamientos"),
  superficieUtil: Yup.number()
    .min(0, "Ingrese valor mayor a 0")
    .required("Ingrese superficie util, sin puntos ni comas")
    .typeError("Ingrese solo numeros"),
  superficieTotales: Yup.number()
    .typeError("Ingrese un valor numerico, sin puntos ni comas")
    .min(0, "Ingrese valor mayor a 0")
    .required("Ingrese superficie total"),
  bodega: Yup.number()
    .typeError("Ingrese un valor numerico, sin puntos ni comas")
    .min(0, "Ingrese valor mayor a 0")
    .required("Ingrese bodegas"),
  idRegion: Yup.number().required("Ingrese region"),
  comuna: Yup.string().required("Ingrese comuna"),
  nombreCalle: Yup.string().required("Ingrese calle"),
  numero: Yup.number()
    .typeError("Ingrese un valor numerico, sin puntos ni comas")
    .required("Ingrese numero"),
  numeroDepartamento: Yup.string()
    .when("tipoPropiedad", {
      is: "Departamento", // alternatively: (val) => val === true
      then: (schema) => schema.min(1),
      otherwise: (schema) => schema.notRequired(),
    })
    .required("Ingrese numero departamento"),
  valor: Yup.number()
    .typeError("Ingrese un valor numerico, sin puntos ni comas")
    .min(1, "Ingrese valor mayor a 0")
    .required("Ingrese precio"),
  gastosComunes: Yup.number()
    .typeError("Ingrese un valor numerico, sin puntos ni comas")
    .min(1, "Ingrese valor mayor a 0")
    .required("Ingrese gastos comunes"),
  tipoPrecio: Yup.string().required("Ingrese tipo moneda"),
  titulo: Yup.string().required("Ingrese título").min(1, "Ingrese un título para mostrar en portales Propins y externos").max(60, "Máximo 60 caracteres"),
});

const UPLOAD_MIME_TYPES = [
  "image/png",
  "image/x-citrix-png",
  "image/x-png",
  "image/jpeg",
  "image/x-citrix-jpeg",
  "image/pjpeg",
  "video/mp4",
];

const mapContainerStyle = {
  width: "100%",
  height: "200px",
};

const FormAgregarPropiedad = ({
  initialValues,
  planes,
  reloadMethod,
  idCliente,
  handleClose,
  inluirSelectClientes,
}) => {
  const [lat, setLat] = useState(
    initialValues && initialValues.loc && initialValues.loc.x
      ? initialValues.loc.x
      : -33.43616371280176
  );
  const [lng, setLng] = useState(
    initialValues && initialValues.loc && initialValues.loc.y
      ? initialValues.loc.y
      : -70.63379790744193
  );
  const { isLoaded } = useJsApiLoader({
    id: "google-map-script",
    googleMapsApiKey: "AIzaSyCZh4ceCuAMermnESxIFECllWWURCP6Ucs",
  });

  const [map, setMap] = React.useState(null);
  const center = {
    lat,
    lng,
  };

  const onLoad = React.useCallback(function callback(map) {
    // This is just an example of getting and using the map instance!!! don't just blindly copy!
    const bounds = new window.google.maps.LatLngBounds(center);
    if (map.current) {
      map.current.fitBounds(bounds);
    }

    setMap(map);
  }, []);

  const onUnmount = React.useCallback(function callback(map) {
    setMap(null);
  }, []);

  const requestGetCliente = useSelector((state) => state.app.requestGetCliente);
  const dispatch = useDispatch();
  const history = useHistory();
  const requestGetProspecto = useSelector(
    (state) => state.app.requestGetProspecto
  );
  const addedProp = useSelector((state) => state.app.propiedad);
  const [isLoading, setIsLoading] = useState(false);
  const [masCaracteristicas, setMasCaracteristicas] = useState(false);
  const [escritorio, setEscritorio] = useState(
    initialValues && initialValues.propCar && initialValues.propCar.escritorio
      ? initialValues.propCar.escritorio
      : false
  );
  const onChangeEscritorio = (e) => setEscritorio(e.target.checked);
  const [alarma, setAlarma] = useState(
    initialValues && initialValues.propCar && initialValues.propCar.alarma
      ? initialValues.propCar.alarma
      : false
  );
  const onChangeAlarma = (e) => setAlarma(e.target.checked);
  const [cocinaAmo, setCocinaAmo] = useState(
    initialValues && initialValues.propCar && initialValues.propCar.cocinaAmo
      ? initialValues.propCar.cocinaAmo
      : false
  );
  const onChangeCocinaAmo = (e) => setCocinaAmo(e.target.checked);
  const [logia, setLogia] = useState(
    initialValues && initialValues.propCar && initialValues.propCar.logia
      ? initialValues.propCar.logia
      : false
  );
  const onChangeLogia = (e) => setLogia(e.target.checked);
  const [portonAut, setPortonAut] = useState(
    initialValues && initialValues.propCar && initialValues.propCar.portonAut
      ? initialValues.propCar.portonAut
      : false
  );
  const onChangePortonAut = (e) => setPortonAut(e.target.checked);
  const [salaDeEstar, setSalaDeEstar] = useState(
    initialValues && initialValues.propCar && initialValues.propCar.salaDeEstar
      ? initialValues.propCar.salaDeEstar
      : false
  );
  const onChangeSalaDeEstar = (e) => setSalaDeEstar(e.target.checked);
  const [accesoControlado, setAccesoControlado] = useState(
    initialValues &&
      initialValues.carCom &&
      initialValues.carCom.accesoControlado
      ? initialValues.carCom.accesoControlado
      : false
  );
  const onChangeAccesoControlado = (e) => setAccesoControlado(e.target.checked);
  const [areasVerdes, setAreasVerdes] = useState(
    initialValues && initialValues.carCom && initialValues.carCom.areasVerdes
      ? initialValues.carCom.areasVerdes
      : false
  );
  const onChangeAreasVerdes = (e) => setAreasVerdes(e.target.checked);
  const [bicicletros, setBicicletros] = useState(
    initialValues && initialValues.carCom && initialValues.carCom.bicicletros
      ? initialValues.carCom.bicicletros
      : false
  );
  const onChangeBicicletros = (e) => setBicicletros(e.target.checked);
  const [camaraSeguridad, setCamaraSeguridad] = useState(
    initialValues &&
      initialValues.carCom &&
      initialValues.carCom.camaraSeguridad
      ? initialValues.carCom.camaraSeguridad
      : false
  );
  const onChangeCamaraSeguridad = (e) => setCamaraSeguridad(e.target.checked);
  const [citofono, setCitofono] = useState(
    initialValues && initialValues.carCom && initialValues.carCom.citofono
      ? initialValues.carCom.citofono
      : false
  );
  const onChangeCitofono = (e) => setCitofono(e.target.checked);
  const [estVisita, setEstVisita] = useState(
    initialValues && initialValues.carCom && initialValues.carCom.estVisita
      ? initialValues.carCom.estVisita
      : false
  );
  const onChangeEstVisita = (e) => setEstVisita(e.target.checked);
  const [juegosInf, setJuegosInf] = useState(
    initialValues && initialValues.carCom && initialValues.carCom.juegosInf
      ? initialValues.carCom.juegosInf
      : false
  );
  const onChangeJuegosInf = (e) => setJuegosInf(e.target.checked);
  const [piscina, setPiscina] = useState(
    initialValues && initialValues.carCom && initialValues.carCom.piscina
      ? initialValues.carCom.piscina
      : false
  );
  const onChangePiscina = (e) => setPiscina(e.target.checked);
  const [portonElec, setPortonElec] = useState(
    initialValues && initialValues.carCom && initialValues.carCom.portonElec
      ? initialValues.carCom.portonElec
      : false
  );
  const onChangePortonElec = (e) => setPortonElec(e.target.checked);
  const [quincho, setQuincho] = useState(
    initialValues && initialValues.carCom && initialValues.carCom.quincho
      ? initialValues.carCom.quincho
      : false
  );
  const onChangeQuincho = (e) => setQuincho(e.target.checked);
  const [salaDeCine, setSalaDeCine] = useState(
    initialValues && initialValues.carCom && initialValues.carCom.salaDeCine
      ? initialValues.carCom.salaDeCine
      : false
  );
  const onChangeSalaDeCine = (e) => setSalaDeCine(e.target.checked);
  const [salaDeEventos, setSalaDeEventos] = useState(
    initialValues && initialValues.carCom && initialValues.carCom.salaDeEventos
      ? initialValues.carCom.salaDeEventos
      : false
  );
  const onChangeSalaDeEventos = (e) => setSalaDeEventos(e.target.checked);
  const [salonDeJuegos, setSalonDeJuegos] = useState(
    initialValues && initialValues.carCom && initialValues.carCom.salonDeJuegos
      ? initialValues.carCom.salonDeJuegos
      : false
  );
  const onChangeSalonDeJuegos = (e) => setSalonDeJuegos(e.target.checked);
  const [sauna, setSauna] = useState(
    initialValues && initialValues.carCom && initialValues.carCom.sauna
      ? initialValues.carCom.sauna
      : false
  );
  const onChangeSauna = (e) => setSauna(e.target.checked);
  const [amoblado, setAmoblado] = useState(
    initialValues && initialValues.amoblado ? initialValues.amoblado : false
  );
  const onChangeAmoblado = (e) => setAmoblado(e.target.checked);
  const [adjuntarVideoPropins, setAdjuntarVideoPropins] = useState(
    initialValues && initialValues.adjuntarVideoPropins
      ? initialValues.adjuntarVideoPropins
      : false
  );
  const onChangeAdjuntarVideoPropins = (e) =>
    setAdjuntarVideoPropins(e.target.checked);

  const [admiteMascotas, setAdmiteMascotas] = useState(
    initialValues && initialValues.admiteMascotas
      ? initialValues.admiteMascotas
      : false
  );
  const onChangeAdmiteMascotas = (e) => setAdmiteMascotas(e.target.checked);

  const [banioServicio, setBanioServicio] = useState(
    initialValues && initialValues.banioServicio
      ? initialValues.banioServicio
      : false
  );
  const onChangeBanioServicio = (e) => setBanioServicio(e.target.checked);

  const requestAddPropiedad = useSelector(
    (state) => state.app.requestAddPropiedad
  );
  const requestUpdatePropiedad = useSelector(
    (state) => state.app.requestUpdatePropiedad
  );
  const errorMessage = useSelector(
    (state) => state.app.errorMessage
  );
  const clientes = useSelector(
    (state) => state.app.clientes && state.app.clientes.results
  );

  const [images, setImages] = useState([]);
  const [imageValidationMessage, setImageValidationMessage] = useState([]);
  const regiones = useSelector(
    (state) =>
      state.app.itemRegiones &&
      state.app.itemRegiones.length > 0 &&
      state.app.itemRegiones[0].regiones
  );
  const requestGetRegiones = useSelector(
    (state) => state.app.requestGetRegiones
  );
  if (requestUpdatePropiedad === "SUCCESS") {
    handleClose();
    Swal.fire({
      title: "Propiedad actualizada",
      text: "Se ha actualizado la propiedad exitosamente",
      icon: "success",
    }).then((result) => {
      if (result.value || result.dismiss === "backdrop") {
        reloadMethod();
      }
    });
  }
  if (requestUpdatePropiedad === "ERROR") {
    Swal.fire({
      title: "Error",
      text: errorMessage,
      icon: "error",
    })
  }

  if (requestAddPropiedad === "SUCCESS") {
    /*
    if (addedProp !== null && addedProp.comuna !== null && comunasUser !== null && !comunasUser.has(addedProp.comuna)) {
      dispatch(setComunas(new Set([...comunasUser, addedProp.comuna])));
    }
    */
    Swal.fire({
      title: "Propiedad agregada",
      text: "Se ha agregado la propiedad exitosamente",
      icon: "success",
    }).then((result) => {
      //si esta en mispropiedades
      if (result.value || result.dismiss === "backdrop") {
        if (reloadMethod) {
          handleClose();
          reloadMethod();
        } else {
          history.push("/mispropiedades/");
        }
      }
    });
  }

  useEffect(() => {
    if (
      requestUpdatePropiedad === "LOADING" ||
      requestAddPropiedad === "LOADING" ||
      requestGetProspecto === "LOADING" ||
      requestGetCliente === "LOADING"
    ) {
      setIsLoading(true);
    } else {
      setIsLoading(false);
    }
  }, [
    requestUpdatePropiedad,
    requestAddPropiedad,
    requestGetProspecto,
    requestGetCliente,
  ]);

  useEffect(() => {
    if (initialValues !== null) {
      if (
        initialValues.imagenes !== null &&
        initialValues.imagenes.length > 0
      ) {
        setImages(initialValues.imagenes);
      }
      if (initialValues.nombreCalle !== null && initialValues.numero !== null) {
        const address = `${initialValues.nombreCalle} ${initialValues.numero}, ${initialValues.comuna}`;
        getCoords(address);
      }
    } else {
      setImages([]);
    }
  }, [initialValues]);

  const getCoords = async (address) => {
    const res = await utilsFunctions.GetLatitudeAndLongitudeByAddress(address);
    if (res.status === "ok") {
      setLat(res.lat);
      setLng(res.lng);
    }
  };

  const addProp = (formValues) => {
    if (!formValues.txtFechaTerm) {
      const hoy = new Date();
      formValues.fechaTermino = new Date(hoy.setMonth(hoy.getMonth() + 6));
    }

    if (!formValues.idRegion) {
      formValues.idRegion = 15;
    }

    if (!formValues.ap) {
      formValues.ap = formValues.numeroDepartamento
        ? formValues.numeroDepartamento
        : 0;
    }

    let noEditoVisitaFotografo = false;
    let tituloGenerico = `${formValues.tipoPropiedad} ${formValues.comuna} ${formValues.dormitorios}D ${formValues.banio}B`;
    const data = {
      ...formValues,
      titulo: formValues.titulo ? formValues.titulo : tituloGenerico,
      banioServicio,
      condominio: formValues.condominio ? "Cerrado" : "Abierto",
      carCom: {
        accesoControlado,
        estVisita,
        portonElec,
        salonDeJuegos,
        areasVerdes,
        quincho,
        sauna,
        camaraSeguridad,
        bicicletros,
        salaDeCine,
        citofono,
        juegosInf,
        piscina,
        salaDeEventos,
      },
      propCar: {
        salaDeEstar,
        calefaccion: formValues.calefaccion,
        alarma,
        escritorio,
        logia,
        portonAut,
        cocinaAmo,
      },
      loc: {
        x: lat,
        y: lng,
      },
      imagenes: images,
      amoblado,
      adjuntarVideoPropins,
      admiteMascotas,
    };

    dispatch(fetchAddPropiedad(data));
  };
  const handleSubmit = (formValues) => {
    if (!formValues.txtFechaTerm) {
      const hoy = new Date();
      formValues.fechaTermino = new Date(hoy.setMonth(hoy.getMonth() + 6));
    }

    if (!formValues.idRegion) {
      formValues.idRegion = 15;
    }

    if (!formValues.ap) {
      formValues.ap = formValues.numeroDepartamento
        ? formValues.numeroDepartamento
        : 0;
    }

    let noEditoVisitaFotografo = false;
    const data = {
      ...formValues,
      numeroDepartamento:
        formValues.numeroDepartamento && formValues.numeroDepartamento !== ""
          ? formValues.numeroDepartamento
          : 0,
      banioServicio,
      condominio: formValues.condominio ? "Cerrado" : "Abierto",
      carCom: {
        accesoControlado,
        estVisita,
        portonElec,
        salonDeJuegos,
        areasVerdes,
        quincho,
        sauna,
        camaraSeguridad,
        bicicletros,
        salaDeCine,
        citofono,
        juegosInf,
        piscina,
        salaDeEventos,
      },
      propCar: {
        salaDeEstar,
        calefaccion: formValues.calefaccion,
        alarma,
        escritorio,
        logia,
        portonAut,
        cocinaAmo,
      },
      loc: {
        x: lat,
        y: lng,
      },
      imagenes: images,
      amoblado,
      adjuntarVideoPropins,
      admiteMascotas,
    };

    if (initialValues.id !== null) {
      const updateData = {
        ...data,
        id: initialValues.id,
      };
      //console.log(updateData);
      dispatch(fetchPostUpdatePropiedad(updateData));
    }
  };
  /*
  useEffect(
    () => () => {
      // Make sure to revoke the data uris to avoid memory leaks
      images.forEach((file) => URL.revokeObjectURL(file.preview));
    },
    [images]
  );
*/

  const deleteImage = (file) => {
    setImages(images.filter((f) => f.name !== file.name));
  };

  const setPortada = (file) => {
    let image = images.find((f) => f.name === file.name);
    if (image.esPortada) {
      image.esPortada = false;
    } else {
      image.esPortada = true;
    }
    setImages(
      images.map((a) => {
        return a.name === file.name ? image : a;
      })
    );
  };

  const fileToBase64 = (file) => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result);
      reader.onerror = (error) => reject(error);
    });
  };

  const fileValidator = (file) => {
    let { name, type, size } = file;

    size = size / 1024;
    if (!UPLOAD_MIME_TYPES.includes(type)) {
      return `${name}: el tipo de archivo es inválido!`;
    } else if (size > 5000) {
      return `${name}: el archivo es muy pesado!`;
    }
    return null;
  };

  const onDrop = (files) => {
    let limit = 6;
    if (initialValues && initialValues.id) {
      limit = 12;
    }
    const msg = `No puedes agregar más de ${limit} imágenes`;
    setImageValidationMessage([]);
    if (images.length >= limit) {
      setImageValidationMessage([...imageValidationMessage, msg]);
    } else {
      if (files) {
        if (files.length + images.length > 6) {
          setImageValidationMessage([...imageValidationMessage, msg]);
        } else {
          files.map((f) => {
            var result = fileValidator(f);
            if (result === null) {
              fileToBase64(f).then((file64) => {
                const newFile = {
                  name: f.name,
                  value: file64,
                  esPortada: false,
                };
                setImages((oldValue) => [...oldValue, newFile]);
              });
            } else {
              setImageValidationMessage((oldValue) => [...oldValue, result]);
            }
          });
        }
      }
    }
  };

  const renderImageValidationMessage = () => {
    return imageValidationMessage.map((m) => (
      <Typography
        variant="caption"
        display="block"
        gutterBottom
        sx={{ color: red[500] }}
      >
        {m}
      </Typography>
    ));
  };

  const thumbs =
    images &&
    images.length > 0 &&
    images.map((file) => {
      if (!file.name.includes(".mp4")) {
        return (
          <ImageListItem
            key={file.name}
            className={file.esPortada ? "destacar" : ""}
          >
            <img
              src={file.value ? file.value : file.downloadLink}
              srcSet={file.value ? file.value : file.downloadLink}
              alt={file.name}
              loading="lazy"
              width="240px"
              height="170px"
            />
            <ImageListItemBar
              sx={{
                background: "none",
              }}
              position="bottom"
              actionIcon={
                <IconButton
                  sx={{ color: "rgba(245, 66, 66, 1)" }}
                  aria-label={`info about ${file.name}`}
                  onClick={() => deleteImage(file)}
                >
                  <Delete />
                </IconButton>
              }
            />
            <ImageListItemBar
              title={file.name}
              position="top"
              actionIcon={
                <IconButton
                  sx={{ color: "rgba(255, 255, 255, 0.54)" }}
                  aria-label={`info about ${file.name}`}
                  onClick={() => setPortada(file)}
                >
                  {file.esPortada ? (
                    <Star sx={{ color: yellow[500] }} />
                  ) : (
                    <Star />
                  )}
                </IconButton>
              }
            />
          </ImageListItem>
        );
      } else {
        return (
          <ImageListItem
            key={file.name}
            className={file.esPortada ? "destacar" : ""}
          >
            <video
              width="240px"
              height="170px"
              autoPlay
              loop
              muted
              //poster="https://assets.codepen.io/6093409/river.jpg"
            >
              <source
                src={file.value ? file.value : file.downloadLink}
                type="video/mp4"
              />
            </video>
            <ImageListItemBar
              sx={{
                background: "none",
              }}
              position="bottom"
              actionIcon={
                <IconButton
                  sx={{ color: "rgba(245, 66, 66, 1)" }}
                  aria-label={`info about ${file.name}`}
                  onClick={() => deleteImage(file)}
                >
                  <Delete />
                </IconButton>
              }
            />
            <ImageListItemBar
              title={file.name}
              position="top"
              actionIcon={
                <IconButton
                  sx={{ color: "rgba(255, 255, 255, 0.54)" }}
                  aria-label={`info about ${file.name}`}
                  onClick={() => setPortada(file)}
                >
                  {file.esPortada ? (
                    <Star sx={{ color: yellow[500] }} />
                  ) : (
                    <Star />
                  )}
                </IconButton>
              }
            />
          </ImageListItem>
        );
      }
    });
  const hoy = new Date();
  const [open, setOpen] = useState(false);
  const handleCloseBackdrop = () => {
    setOpen(false);
  };

  const handleBlur = (e) => {
    if (e.target.name === "nombreCalle") {
      if (e.target.value !== "" && e.target.value !== null) {
        setNombreCalle(e.target.value);
      } else {
        setNombreCalle(null);
      }
    }
    if (e.target.name === "numero") {
      if (e.target.value !== "" && e.target.value !== null) {
        setNumero(e.target.value);
      } else {
        setNumero(null);
      }
    }
    if (e.target.name === "comuna") {
      if (e.target.value !== "" && e.target.value !== null) {
        setComuna(e.target.value);
      } else {
        setComuna(null);
      }
    }
  };

  const [nombreCalle, setNombreCalle] = useState(
    initialValues && initialValues.nombreCalle
  );
  const [numero, setNumero] = useState(initialValues && initialValues.numero);
  const [comuna, setComuna] = useState(initialValues && initialValues.comuna);

  useEffect(() => {
    if (nombreCalle && numero && comuna) {
      getCoords(`${nombreCalle} ${numero}, ${comuna}`);
    }
  }, [nombreCalle, numero, comuna]);

  return (
    <Formik
      //enableReinitialize
      initialValues={{
        idCliente:
          initialValues && initialValues.idCliente
            ? initialValues.idCliente
            : idCliente
            ? idCliente
            : "",
        idPlan:
          initialValues &&
          initialValues.planContratado &&
          initialValues.planContratado.id,
        tipoPropiedad:
          initialValues && initialValues.tipoPropiedad
            ? initialValues.tipoPropiedad
            : "",
        dormitorios:
          initialValues && initialValues.dormitorios
            ? initialValues.dormitorios
            : 0,
        banio: initialValues && initialValues.banio ? initialValues.banio : 0,
        cantEstacionamiento: initialValues && initialValues.cantEstacionamiento,
        superficieUtil: initialValues && initialValues.superficieUtil,
        superficieTotales: initialValues && initialValues.superficieTotales,
        banioServicio,
        bodega: initialValues && initialValues.bodega,
        gastosComunes:
          initialValues && initialValues.gastosComunes
            ? initialValues.gastosComunes
            : 0,
        contribuciones:
          initialValues && initialValues.contribuciones
            ? initialValues.contribuciones
            : 0,
        servicios: 0,
        anioConstruccion: initialValues && initialValues.anioConstruccion,
        calefaccion:
          initialValues &&
          initialValues.propCar &&
          initialValues.propCar.calefaccion,
        escritorio,
        logia,
        portonAut,
        cocinaAmo,
        salaDeEstar,
        alarma,
        amoblado,
        condominio:
          initialValues &&
          initialValues.condominio &&
          initialValues.condominio.toLowerCase() === "abierto"
            ? true
            : false,
        region:
          initialValues && initialValues.idRegion ? initialValues.idRegion : 15,
        comuna:
          initialValues && initialValues.comuna ? initialValues.comuna : "",
        barrio: initialValues && initialValues.barrio,
        nombreCalle:
          initialValues && initialValues.nombreCalle
            ? initialValues.nombreCalle
            : "",
        numero:
          initialValues && initialValues.numero ? initialValues.numero : "",
        valor: initialValues && initialValues.valor,
        tipoPrecio: initialValues && initialValues.tipoPrecio,
        idRegion:
          initialValues && initialValues.idRegion ? initialValues.idRegion : 15,
        accesoControlado,
        estVisita,
        portonElec,
        salonDeJuegos,
        areasVerdes,
        quincho,
        sauna,
        camaraSeguridad,
        bicicletros,
        salaDeCine,
        citofono,
        juegosInf,
        piscina,
        salaDeEventos,
        //id: initialValues && initialValues.id ? initialValues.id : "",
        imageContainerName:
          initialValues && initialValues.imageContainerName
            ? initialValues.imageContainerName
            : "",
        glosa: initialValues && initialValues.glosa ? initialValues.glosa : "",
        fechaTermino: initialValues && initialValues.fechaTermino,
        disponibilidad:
          initialValues && initialValues.disponibilidad
            ? new Date(initialValues.disponibilidad)
            : new Date(),
        lat:
          initialValues && initialValues.loc && initialValues.loc.x
            ? initialValues.loc.x
            : "",
        lon:
          initialValues && initialValues.loc && initialValues.loc.y
            ? initialValues.loc.y
            : "",
        tipoPropiedadInt: initialValues && initialValues.tipoPropiedadInt,
        orientacion:
          initialValues && initialValues.orientacion
            ? initialValues.orientacion
            : "",
        rol: initialValues && initialValues.rol ? initialValues.rol : "",
        pisoNúmero:
          initialValues && initialValues.pisoNúmero
            ? initialValues.pisoNúmero
            : 0,
        ap: initialValues && initialValues.ap,
        cantEstacionamiento:
          initialValues && initialValues.cantEstacionamiento
            ? initialValues.cantEstacionamiento
            : "0",
        tieneBodega:
          initialValues && initialValues.tieneBodega
            ? initialValues.tieneBodega
            : false,
        via: initialValues && initialValues.via ? initialValues.via : "",
        codigoPostal: "",
        numeroDepartamento:
          initialValues &&
          initialValues.numeroDepartamento &&
          initialValues.numeroDepartamento !== ""
            ? initialValues.numeroDepartamento
            : 0,
        exclusividad:
          initialValues && initialValues.exclusividad
            ? initialValues.exclusividad
            : false,
        destacar:
          initialValues && initialValues.destacar
            ? initialValues.destacar
            : false,

        imagenes: images,
        observacionesInternas:
          initialValues && initialValues.observacionesInternas
            ? initialValues.observacionesInternas
            : "",
        observacionesPublicas:
          initialValues && initialValues.observacionesPublicas
            ? initialValues.observacionesPublicas
            : "",
        referenciaCalle:
          initialValues && initialValues.referenciaCalle
            ? initialValues.referenciaCalle
            : "",
        referenciaCalleA:
          initialValues && initialValues.referenciaCalleA
            ? initialValues.referenciaCalleA
            : "",
        referenciaCalleB:
          initialValues && initialValues.referenciaCalleB
            ? initialValues.referenciaCalleB
            : "",
        urlMattePort: initialValues && initialValues.urlMattePort,
        horaVisitaFotografo: initialValues && initialValues.horaVisitaFotografo,
        fechaVisitaFotografoString:
          initialValues && initialValues.fechaVisitaFotografoString
            ? moment(initialValues.fechaVisitaFotografo).format("YYYY-MM-DD")
            : "",
        idFotografo: initialValues && initialValues.idFotografo,
        detallePlan:
          initialValues && initialValues.detallePlan
            ? initialValues.detallePlan
            : "",
        fechaTermino:
          initialValues && initialValues.fechaTermino
            ? initialValues.fechaTermino
            : new Date(hoy.setMonth(hoy.getMonth() + 6)),
        anioConstruccion:
          initialValues && initialValues.anioConstruccion
            ? initialValues.anioConstruccion
            : 0,
        idBroker: initialValues && initialValues.idBroker,
        publicadaPortalInmobiliario:
          initialValues && initialValues.publicadaPortalInmobiliario
            ? initialValues.publicadaPortalInmobiliario
            : false,
        idPublicacionPI:
          initialValues && initialValues.idPublicacionPI
            ? initialValues.idPublicacionPI
            : null,
        titulo:
          initialValues && initialValues.titulo ? initialValues.titulo : null,
        adjuntarVideoPropins,
        admiteMascotas,
      }}
      validationSchema={validate}
      onSubmit={initialValues ? handleSubmit : addProp}
    >
      {({ values, errors, handleSubmit }) => {
        return (
          <Form onSubmit={handleSubmit}>
            {isLoading && (
              <Backdrop
                sx={{
                  color: "#fff",
                  zIndex: (theme) => theme.zIndex.drawer + 1,
                }}
                open={true}
                onClick={handleCloseBackdrop}
              >
                <CircularProgress color="inherit" />
              </Backdrop>
            )}
            <Grid container spacing={2}>
              <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                <Grid container>
                  <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                    <h5 className="alignLeft">Plan</h5>
                  </Grid>
                </Grid>
                <Grid container>
                  <Grid item xs={6} sm={6} md={6} lg={6} xl={6}>
                    <label className="milabel">Plan contratado</label>
                    <Field name="idPlan" as="select" className="miSelect">
                      <option value={""}>- Seleccione -</option>
                      {planes &&
                        planes.map((c) => (
                          <option value={c.id}>{c.nombre}</option>
                        ))}
                    </Field>
                    <FieldError name="idPlan" />
                  </Grid>
                </Grid>
                <Grid container spacing={1} sx={{ mt: 2 }}>
                  <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                    <h5 className="alignLeft">Cliente y datos bancarios</h5>
                  </Grid>
                </Grid>
                <Grid container spacing={1}>
                  <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                    <label className="milabel">Cliente</label>
                    <Field
                      name="idCliente"
                      as="select"
                      className="miSelect"
                      //disabled={idCliente ? true : false}
                    >
                      <option value={""}>- Seleccione -</option>
                      {clientes &&
                        clientes.length > 0 &&
                        clientes.map((c) => (
                          <option value={c.id}>
                            {c.nombres} {c.apellidos}
                          </option>
                        ))}
                    </Field>
                    <FieldError name="idCliente" />
                  </Grid>
                </Grid>

                {values &&
                  values.planContratado &&
                  !values.planContratado.esVenta && (
                    <div>
                      <Grid container spacing={1}>
                        <Grid item xs={6} sm={6} md={6} lg={6} xl={6}>
                          <label className="milabel">Banco</label>
                          <Field name="banco" as="select" className="miSelect">
                            <option value="Departamento">Banco de Chile</option>
                            <option value="Casa">Banco estado</option>
                          </Field>
                        </Grid>
                        <Grid item xs={6} sm={6} md={6} lg={6} xl={6}>
                          <label className="milabel">Tipo de cuenta</label>
                          <Field name="banco" as="select" className="miSelect">
                            <option value="Departamento">
                              Cuenta corriente
                            </option>
                            <option value="Casa">Cuenta vista</option>
                          </Field>
                        </Grid>
                      </Grid>
                      <Grid container spacing={1}>
                        <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                          <label className="milabel">Número de cuenta</label>
                          <Field
                            name="numcuenta"
                            type="text"
                            className="miSelect"
                          />
                        </Grid>
                      </Grid>
                    </div>
                  )}

                <Grid container spacing={1} sx={{ mt: 2 }}>
                  <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                    <h5 className="alignLeft">Características propiedad</h5>
                  </Grid>
                </Grid>
                <Grid container spacing={1}>
                  <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                    <label className="milabel">Título</label>
                    <Field name="titulo" type="text" className="miSelect" />
                    <FieldError name="titulo" />
                  </Grid>
                  <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                    <label className="milabel">Descripción</label>
                    <Field
                      name="observacionesPublicas"
                      type="text"
                      className="miTextarea"
                      as="textarea"
                    />
                    <FieldError name="observacionesPublicas" />
                  </Grid>
                </Grid>
                <Grid container spacing={1}>
                  <Grid item xs={6} sm={6} md={6} lg={6} xl={6}>
                    <label className="milabel">Tipo propiedad</label>
                    <Field
                      name="tipoPropiedad"
                      as="select"
                      className="selectFullWidth"
                    >
                      <option value={""}>- Seleccione -</option>
                      <option value="Departamento">Departamento</option>
                      <option value="Casa">Casa</option>
                    </Field>
                    <FieldError name="tipoPropiedad" />
                  </Grid>
                  <Grid item xs={6} sm={6} md={6} lg={6} xl={6}>
                    <label className="milabel">Orientación</label>
                    <Field
                      name="orientacion"
                      type="text"
                      className="miSelect"
                    />
                    <FieldError name="orientacion" />
                  </Grid>
                </Grid>
                <Grid container spacing={1}>
                  <Grid item xs={6} sm={6} md={4} lg={4} xl={4}>
                    <label className="milabel">Dormitorios</label>
                    <Field
                      name="dormitorios"
                      type="text"
                      className="miSelect"
                    />
                    <FieldError name="dormitorios" />
                  </Grid>
                  <Grid item xs={6} sm={6} md={4} lg={4} xl={4}>
                    <label className="milabel">Baños</label>
                    <Field name="banio" type="text" className="miSelect" />
                    <FieldError name="banio" />
                  </Grid>
                  <Grid item xs={6} sm={6} md={4} lg={4} xl={4}>
                    <label className="milabel">Estacionamientos</label>
                    <Field
                      name="cantEstacionamiento"
                      type="text"
                      className="miSelect"
                    />
                    <FieldError name="cantEstacionamiento" />
                  </Grid>
                </Grid>
                <Grid container spacing={1}>
                  <Grid item xs={6} sm={6} md={4} lg={4} xl={4}>
                    <label className="milabel">Superficie total</label>
                    <Field
                      name="superficieTotales"
                      type="text"
                      className="miSelect"
                    />
                    <FieldError name="superficieTotales" />
                  </Grid>
                  <Grid item xs={6} sm={6} md={4} lg={4} xl={4}>
                    <label className="milabel">Superficie útil</label>
                    <Field
                      name="superficieUtil"
                      type="text"
                      className="miSelect"
                    />
                    <FieldError name="superficieUtil" />
                  </Grid>
                  <Grid item xs={6} sm={6} md={4} lg={4} xl={4}>
                    <label className="milabel">Bodegas</label>
                    <Field name="bodega" type="text" className="miSelect" />
                    <FieldError name="bodega" />
                  </Grid>
                </Grid>
                <Grid container spacing={1} sx={{ marginBottom: 1 }}>
                  <Grid item xs={6} sm={6} md={4} lg={4} xl={4}>
                    <label className="milabel">Gastos comunes</label>
                    <Field
                      name="gastosComunes"
                      type="text"
                      className="miSelect"
                    />
                    <FieldError name="gastosComunes" />
                  </Grid>
                  <Grid item xs={6} sm={6} md={4} lg={4} xl={4}>
                    <label className="milabel">Contribuciones</label>
                    <Field
                      name="contribuciones"
                      type="text"
                      className="miSelect"
                    />
                  </Grid>
                </Grid>
                {values &&
                  values.idPlan &&
                  planes &&
                  planes.some((p) => p.id === values.idPlan) &&
                  !planes.find((p) => p.id === values.idPlan).esVenta && (
                    <Grid container>
                      <Grid item xs={12} sm={6} md={4} lg={4} xl={4}>
                        <FormGroup>
                          <FormControlLabel
                            control={
                              <Checkbox
                                checked={amoblado}
                                onChange={onChangeAmoblado}
                                name="amoblado"
                                size="small"
                              />
                            }
                            classes={{ root: "alignLeft" }}
                            label="Amoblado"
                          />
                        </FormGroup>
                      </Grid>
                      <Grid item xs={12} sm={6} md={4} lg={4} xl={4}>
                        <FormGroup>
                          <FormControlLabel
                            control={
                              <Checkbox
                                checked={admiteMascotas}
                                onChange={onChangeAdmiteMascotas}
                                name="admiteMascotas"
                                size="small"
                              />
                            }
                            classes={{ root: "alignLeft" }}
                            label="Admite mascotas"
                          />
                        </FormGroup>
                      </Grid>
                    </Grid>
                  )}

                <div className="seccion">
                  <Button
                    className="masCaracteristicas"
                    onClick={() => setMasCaracteristicas(!masCaracteristicas)}
                  >
                    {" "}
                    Mas Caracteristicas
                  </Button>
                </div>

                <div id={masCaracteristicas ? "" : "noVisible"}>
                  <Grid container>
                    <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                      <h5 className="subtitle alignLeft">
                        Características propiedad
                      </h5>
                    </Grid>
                  </Grid>
                  <Grid container spacing={1}>
                    <Grid item xs={12} sm={4} md={4} lg={4} xl={4}>
                      <label className="milabel">Año construcción</label>
                      <Field
                        name="anioConstruccion"
                        type="text"
                        className="miSelect"
                      />
                    </Grid>
                    <Grid item xs={12} sm={8} md={8} lg={8} xl={8}>
                      <label className="milabel">Calefacción</label>
                      <Field
                        name="calefaccion"
                        as="select"
                        className="selectFullWidth"
                      >
                        <option value={""}>- Seleccione -</option>
                        <option value="losa">Losa</option>
                        <option value="radiador">Radiador</option>
                        <option value="electrica">Eléctrica</option>
                        <option value="otro">Otro</option>
                      </Field>
                    </Grid>
                  </Grid>
                  <Grid container spacing={1}>
                    <Grid item xs={12} sm={6} md={4} lg={4} xl={4}>
                      <FormGroup>
                        <FormControlLabel
                          control={
                            <Checkbox
                              checked={escritorio}
                              size="small"
                              name="escritorio"
                              onChange={onChangeEscritorio}
                            />
                          }
                          classes={{ root: "alignLeft" }}
                          label="Escritorio"
                        />
                      </FormGroup>
                    </Grid>
                    <Grid item xs={12} sm={6} md={4} lg={4} xl={4}>
                      <FormGroup>
                        <FormControlLabel
                          control={
                            <Checkbox
                              checked={logia}
                              onChange={onChangeLogia}
                              name="logia"
                              size="small"
                            />
                          }
                          classes={{ root: "alignLeft" }}
                          label="Logia"
                        />
                      </FormGroup>
                    </Grid>
                    <Grid item xs={12} sm={6} md={4} lg={4} xl={4}>
                      <FormGroup>
                        <FormControlLabel
                          control={
                            <Checkbox
                              checked={portonAut}
                              onChange={onChangePortonAut}
                              name="portonAut"
                              size="small"
                            />
                          }
                          classes={{ root: "alignLeft" }}
                          label="Portón automático"
                        />
                      </FormGroup>
                    </Grid>
                  </Grid>

                  <Grid container spacing={1}>
                    <Grid item xs={12} sm={6} md={4} lg={4} xl={4}>
                      <FormGroup>
                        <FormControlLabel
                          control={
                            <Checkbox
                              checked={cocinaAmo}
                              onChange={onChangeCocinaAmo}
                              name="cocinaAmo"
                              size="small"
                            />
                          }
                          classes={{ root: "alignLeft" }}
                          label="Cocina amoblada"
                        />
                      </FormGroup>
                    </Grid>
                    <Grid item xs={12} sm={6} md={4} lg={4} xl={4}>
                      <FormGroup>
                        <FormControlLabel
                          control={
                            <Checkbox
                              checked={salaDeEstar}
                              onChange={onChangeSalaDeEstar}
                              name="salaDeEstar"
                              size="small"
                            />
                          }
                          classes={{ root: "alignLeft" }}
                          label="Sala de estar"
                        />
                      </FormGroup>
                    </Grid>
                    <Grid item xs={12} sm={6} md={4} lg={4} xl={4}>
                      <FormGroup>
                        <FormControlLabel
                          control={
                            <Checkbox
                              name="alarma"
                              checked={alarma}
                              onChange={onChangeAlarma}
                              size="small"
                            />
                          }
                          classes={{ root: "alignLeft" }}
                          label="Alarma"
                        />
                      </FormGroup>
                    </Grid>
                  </Grid>

                  <Grid container spacing={1}>
                    <Grid item xs={12} sm={6} md={4} lg={4} xl={4}>
                      <FormGroup>
                        <FormControlLabel
                          control={<Checkbox name="condominio" size="small" />}
                          classes={{ root: "alignLeft" }}
                          label="Condominio"
                        />
                      </FormGroup>
                    </Grid>
                    <Grid item xs={12} sm={6} md={4} lg={4} xl={4}>
                      <FormGroup>
                        <FormControlLabel
                          control={
                            <Checkbox
                              checked={banioServicio}
                              onChange={onChangeBanioServicio}
                              name="banioServicio"
                              size="small"
                            />
                          }
                          classes={{ root: "alignLeft" }}
                          label="Baño servicio"
                        />
                      </FormGroup>
                    </Grid>
                  </Grid>

                  <Grid container spacing={1} sx={{ mt: 2 }}>
                    <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                      <h5 className="subtitle alignLeft">
                        Características de la comunidad
                      </h5>
                    </Grid>
                  </Grid>

                  <Grid container spacing={1}>
                    <Grid item xs={12} sm={6} md={4} lg={4} xl={4}>
                      <FormGroup>
                        <FormControlLabel
                          control={
                            <Checkbox
                              checked={accesoControlado}
                              onChange={onChangeAccesoControlado}
                              name="accesoControlado"
                              size="small"
                            />
                          }
                          classes={{ root: "alignLeft" }}
                          label="Acceso controlado"
                        />
                      </FormGroup>
                    </Grid>
                    <Grid item xs={12} sm={6} md={4} lg={4} xl={4}>
                      <FormGroup>
                        <FormControlLabel
                          control={
                            <Checkbox
                              checked={areasVerdes}
                              onChange={onChangeAreasVerdes}
                              name="areasVerdes"
                              size="small"
                            />
                          }
                          classes={{ root: "alignLeft" }}
                          label="Áreas verdes"
                        />
                      </FormGroup>
                    </Grid>
                    <Grid item xs={12} sm={6} md={4} lg={4} xl={4}>
                      <FormGroup>
                        <FormControlLabel
                          control={
                            <Checkbox
                              checked={portonElec}
                              onChange={onChangePortonElec}
                              name="portonElec"
                              size="small"
                            />
                          }
                          classes={{ root: "alignLeft" }}
                          label="Portón eléctrico"
                        />
                      </FormGroup>
                    </Grid>
                  </Grid>
                  <Grid container spacing={1}>
                    <Grid item xs={12} sm={6} md={4} lg={4} xl={4}>
                      <FormGroup>
                        <FormControlLabel
                          control={
                            <Checkbox
                              checked={camaraSeguridad}
                              onChange={onChangeCamaraSeguridad}
                              name="camaraSeguridad"
                              size="small"
                            />
                          }
                          classes={{ root: "alignLeft" }}
                          label="Cámara seguridad"
                        />
                      </FormGroup>
                    </Grid>
                    <Grid item xs={12} sm={6} md={4} lg={4} xl={4}>
                      <FormGroup>
                        <FormControlLabel
                          control={
                            <Checkbox
                              checked={quincho}
                              onChange={onChangeQuincho}
                              name="quincho"
                              size="small"
                            />
                          }
                          classes={{ root: "alignLeft" }}
                          label="Quincho"
                        />
                      </FormGroup>
                    </Grid>
                    <Grid item xs={12} sm={6} md={4} lg={4} xl={4}>
                      <FormGroup>
                        <FormControlLabel
                          control={
                            <Checkbox
                              checked={salaDeCine}
                              onChange={onChangeSalaDeCine}
                              name="salaDeCine"
                              size="small"
                            />
                          }
                          classes={{ root: "alignLeft" }}
                          label="Sala de cine"
                        />
                      </FormGroup>
                    </Grid>
                  </Grid>
                  <Grid container spacing={1}>
                    <Grid item xs={12} sm={6} md={4} lg={4} xl={4}>
                      <FormGroup>
                        <FormControlLabel
                          control={
                            <Checkbox
                              checked={citofono}
                              onChange={onChangeCitofono}
                              name="citofono"
                              size="small"
                            />
                          }
                          classes={{ root: "alignLeft" }}
                          label="Citófono"
                        />
                      </FormGroup>
                    </Grid>
                    <Grid item xs={12} sm={6} md={4} lg={4} xl={4}>
                      <FormGroup>
                        <FormControlLabel
                          control={
                            <Checkbox
                              checked={sauna}
                              onChange={onChangeSauna}
                              name="sauna"
                              size="small"
                            />
                          }
                          classes={{ root: "alignLeft" }}
                          label="Sauna"
                        />
                      </FormGroup>
                    </Grid>
                    <Grid item xs={12} sm={6} md={4} lg={4} xl={4}>
                      <FormGroup>
                        <FormControlLabel
                          control={
                            <Checkbox
                              checked={salonDeJuegos}
                              onChange={onChangeSalonDeJuegos}
                              name="salaDeJuegos"
                              size="small"
                            />
                          }
                          classes={{ root: "alignLeft" }}
                          label="Sala de juegos"
                        />
                      </FormGroup>
                    </Grid>
                  </Grid>
                  <Grid container spacing={1}>
                    <Grid item xs={12} sm={6} md={4} lg={4} xl={4}>
                      <FormGroup>
                        <FormControlLabel
                          control={
                            <Checkbox
                              checked={salaDeEventos}
                              onChange={onChangeSalaDeEventos}
                              name="salaDeEventos"
                              size="small"
                            />
                          }
                          classes={{ root: "alignLeft" }}
                          label="Sala de eventos"
                        />
                      </FormGroup>
                    </Grid>
                    <Grid item xs={12} sm={6} md={4} lg={4} xl={4}>
                      <FormGroup>
                        <FormControlLabel
                          control={
                            <Checkbox
                              checked={bicicletros}
                              onChange={onChangeBicicletros}
                              name="bicicletros"
                              size="small"
                            />
                          }
                          classes={{ root: "alignLeft" }}
                          label="Bicicleteros"
                        />
                      </FormGroup>
                    </Grid>
                    <Grid item xs={12} sm={6} md={4} lg={4} xl={4}>
                      <FormGroup>
                        <FormControlLabel
                          control={
                            <Checkbox
                              checked={piscina}
                              onChange={onChangePiscina}
                              name="piscina"
                              size="small"
                            />
                          }
                          classes={{ root: "alignLeft" }}
                          label="Piscina"
                        />
                      </FormGroup>
                    </Grid>
                  </Grid>

                  <Grid container spacing={1}>
                    <Grid item xs={12} sm={6} md={4} lg={4} xl={4}>
                      <FormGroup>
                        <FormControlLabel
                          control={
                            <Checkbox
                              checked={juegosInf}
                              onChange={onChangeJuegosInf}
                              name="juegosInf"
                              size="small"
                            />
                          }
                          classes={{ root: "alignLeft" }}
                          label="Juegos infantiles"
                        />
                      </FormGroup>
                    </Grid>
                    <Grid item xs={12} sm={6} md={4} lg={4} xl={4}>
                      <FormGroup>
                        <FormControlLabel
                          control={
                            <Checkbox
                              checked={estVisita}
                              onChange={onChangeEstVisita}
                              name="estVisitas"
                              size="small"
                            />
                          }
                          classes={{ root: "alignLeft" }}
                          label="Estacionamiento visitas"
                        />
                      </FormGroup>
                    </Grid>
                  </Grid>
                </div>
              </Grid>

              <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                <Grid container spacing={1}>
                  <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                    <h5 className="alignLeft">Ubicación</h5>
                  </Grid>
                </Grid>
                {requestGetRegiones == "IDLE" && (
                  <Grid container spacing={1}>
                    <Grid item xs={6} sm={6} md={6} lg={6} xl={6}>
                      <label className="milabel">Región</label>
                      <Field name="idRegion" as="select" className="miSelect">
                        <option value="">- Seleccione -</option>
                        {regiones &&
                          regiones.map((r) => (
                            <option value={r.numero}>{r.region}</option>
                          ))}
                      </Field>
                      <FieldError name="idRegion" />
                    </Grid>
                    <Grid item xs={6} sm={6} md={6} lg={6} xl={6}>
                      <label className="milabel">Comuna</label>
                      <Field
                        name="comuna"
                        as="select"
                        className="miSelect"
                        onBlur={handleBlur}
                      >
                        <option value={""}>- Seleccione -</option>
                        {regiones &&
                          values &&
                          values.idRegion &&
                          regiones.some((u) => u.numero == values.idRegion) &&
                          regiones.find((u) => u.numero == values.idRegion)
                            .comunas &&
                          regiones
                            .find((u) => u.numero == values.idRegion)
                            .comunas.map((c) => <option value={c}>{c}</option>
                            )}
                      </Field>
                      <FieldError name="comuna" />
                    </Grid>
                  </Grid>
                )}

                {values && values.tipoPropiedad === "Departamento" ? (
                  <Grid container spacing={1}>
                    <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                      <label className="milabel">Calle</label>
                      <Field
                        name="nombreCalle"
                        type="text"
                        className="miSelect"
                        onBlur={handleBlur}
                      />
                      <FieldError name="nombreCalle" />
                    </Grid>
                    <Grid item xs={6} sm={6} md={3} lg={3} xl={3}>
                      <label className="milabel">Número</label>
                      <Field
                        name="numero"
                        type="text"
                        className="miSelect"
                        onBlur={handleBlur}
                      />
                      <FieldError name="numero" />
                    </Grid>
                    <Grid item xs={6} sm={6} md={3} lg={3} xl={3}>
                      <label className="milabel">Nro. depto.</label>
                      <Field
                        name="numeroDepartamento"
                        type="text"
                        className="miSelect"
                      />
                      <FieldError name="numeroDepartamento" />
                    </Grid>
                  </Grid>
                ) : (
                  <Grid container spacing={1}>
                    <Grid item xs={12} sm={12} md={8} lg={8} xl={8}>
                      <label className="milabel">Calle</label>
                      <Field
                        name="nombreCalle"
                        type="text"
                        className="miSelect"
                        onBlur={handleBlur}
                      />
                      <FieldError name="nombreCalle" />
                    </Grid>
                    <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
                      <label className="milabel">Número</label>
                      <Field
                        name="numero"
                        type="text"
                        className="miSelect"
                        onBlur={handleBlur}
                      />
                      <FieldError name="numero" />
                    </Grid>
                  </Grid>
                )}

                {requestGetRegiones == "IDLE" && (
                  <Grid container spacing={1}>
                    <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                      <label className="milabel">Barrio</label>
                      <Field name="barrio" as="select" className="miSelect">
                        <option value={""}>- Seleccione -</option>
                        {values &&
                          values.comuna &&
                          values.comuna != null &&
                          values.comuna != undefined &&
                          BARRIOS_COMUNAS[values.comuna] != null &&
                          BARRIOS_COMUNAS[values.comuna] != undefined &&
                          BARRIOS_COMUNAS[values.comuna].map((p) => (
                            <option value={p}>{p}</option>
                          ))}
                      </Field>
                    </Grid>
                  </Grid>
                )}

                <Grid container spacing={1}>
                  <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                    <label className="milabel">Mapa</label>
                    {isLoaded && (
                      <GoogleMap
                        id="map"
                        mapContainerStyle={mapContainerStyle}
                        center={center}
                        zoom={15}
                        onLoad={onLoad}
                        onUnmount={onUnmount}
                        //onClick={onClickMap}
                      >
                        {nombreCalle && numero && comuna && (
                          <MarkerF onLoad={onLoad} position={center} />
                        )}
                        <></>
                      </GoogleMap>
                    )}
                  </Grid>
                </Grid>

                <Grid container spacing={1} sx={{ mt: 2 }}>
                  <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                    <h5 className="alignLeft">Precio propiedad</h5>
                  </Grid>
                </Grid>

                <Grid container spacing={1}>
                  <Grid item xs={6} sm={6} md={6} lg={6} xl={6}>
                    <label className="milabel">Tipo moneda</label>
                    <Field name="tipoPrecio" as="select" className="miSelect">
                      <option value={""}>- Seleccione -</option>
                      <option value="CLP">CLP</option>
                      <option value="UF">UF</option>
                    </Field>
                    <FieldError name="tipoPrecio" />
                  </Grid>
                  <Grid item xs={6} sm={6} md={6} lg={6} xl={6}>
                    <label className="milabel">Precio</label>
                    <Field name="valor" type="text" className="miSelect" />
                    <FieldError name="valor" />
                  </Grid>
                </Grid>

                <Grid container spacing={1} sx={{ mt: 2 }}>
                  <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                    <h5 className="alignLeft">Imágenes</h5>
                  </Grid>
                </Grid>

                <Grid container spacing={1}>
                  <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                    <FormGroup>
                      <FormControlLabel
                        control={
                          <Checkbox
                            checked={adjuntarVideoPropins}
                            onChange={onChangeAdjuntarVideoPropins}
                            name="adjuntarVideoPropins"
                            size="small"
                          />
                        }
                        classes={{ root: "alignLeft" }}
                        label="Adjuntar video Propins"
                      />
                    </FormGroup>
                  </Grid>
                </Grid>

                <Grid container spacing={1}>
                  <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                    <Dropzone
                      name="imagenes"
                      onDropAccepted={onDrop}
                      accept={UPLOAD_MIME_TYPES}
                      maxFiles={12}
                    >
                      {({ getRootProps, getInputProps }) => (
                        <section>
                          <div {...getRootProps({ className: "dropzonenew" })}>
                            <input {...getInputProps()} />
                            <p>
                              Arrastre las imágenes aquí o haga click para
                              seleccionar
                            </p>
                          </div>
                        </section>
                      )}
                    </Dropzone>
                    <Typography variant="caption" display="block" gutterBottom>
                      Se aceptan imágenes y videos con formato .PNG, .JPG, .JPEG
                      y .mp4. {initialValues && initialValues.id ? 12 : 6} fotos
                      máximo a la vez (se pueden agregar más después, en el
                      perfil de la propiedad)
                    </Typography>
                    {imageValidationMessage.length > 0 && (
                      <div>{renderImageValidationMessage()}</div>
                    )}
                    <ImageList rowHeight={170} gap={2} sx={{ height: 400 }}>
                      {thumbs}
                    </ImageList>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
            <Grid container spacing={4} justifyContent="flex-end">
              <Grid item>
                <Button onClick={handleClose} className="main-btn">
                  Cancelar
                </Button>
              </Grid>

              <Grid item>
                <Button
                  type="submit"
                  className="main-btn"
                  variant="contained"
                  color="success"
                >
                  Guardar
                </Button>
              </Grid>
            </Grid>
          </Form>
        );
      }}
    </Formik>
  );
};

export default FormAgregarPropiedad;
