import { Link, useLocation } from "react-router-dom";
import SearchIcon from "@mui/icons-material/Search";
import { useDispatch, useSelector } from "react-redux";
import InfoProspecto from "../InfoProspecto";
import { useState } from "react";
import { useEffect } from "react";
import { fetchAllRegiones, getProspecto, getRequerimientos } from "../../../action";
import FormRequerimiento from "./FormRequerimiento";
import { CircularProgress } from "@mui/material";
import RequerimientoItem from "./RequerimientoItem";
import ProspectoCompradorNavegacionMobile from "./ProspectoCompradorNavegacionMobile";
import Back from "../../../assets/Back";
import Swal from "sweetalert2";

const isMobile =
  !!navigator.userAgent.match(/iphone|android|blackberry/gi) || false;

const PerfilComprador = () => {
  const dispatch = useDispatch();
  const requerimientos = useSelector(
    (state) => state.app.requerimientos && state.app.requerimientos.results
  );
  const requestGetRequerimientos = useSelector(
    (state) => state.app.requestGetRequerimientos
  );
  const [isOpen, setIsOpen] = useState(false);
  const handleClose = () => setIsOpen(false);
  const [isOpenAddRequirementModal, setIsOpenAddRequirementModal] =
    useState(false);
  const handleOpenAddRequirementModal = () => {
    setIsOpenAddRequirementModal(true);
  };
  const handleCloseAddRequirementModal = () => {
    setIsOpenAddRequirementModal(false);
  };
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };
  let { search } = useLocation();
  let query = new URLSearchParams(search);
  let perfil = query.get("id");

  const requestStateUpdate = useSelector(
    (state) => state.app.requestUpdateProspecto
  );

  if (requestStateUpdate === "SUCCESS") {
    Swal.fire({
      title: "Prospecto actualizado",
      text: "Se ha actualizado el prospecto exitosamente",
      icon: "success",
    }).then((result) => {
      if (result.value || result.dismiss === "backdrop") {
        reload();
        handleClose();
      }
    });
  }

  const reload = () => {
    dispatch(getProspecto("ca", perfil));
  }

  useEffect(() => {
    dispatch(fetchAllRegiones());
  }, []);
  useEffect(() => {
    dispatch(getRequerimientos(page, rowsPerPage, perfil, "", "", "", 0, 0));
  }, [page, rowsPerPage]);

  const reloadData = () => {
    dispatch(getRequerimientos(page, rowsPerPage, perfil, "", "", "", 0, 0));
  };

  return (
    <div className="contenido">
      <Back urlPath="/prospectos/comprador" />
      <div className="header">
        <h2>Perfil prospecto comprador/arrendatario</h2>
      </div>

      <InfoProspecto handleClose={handleClose} setIsOpen={setIsOpen} isOpen={isOpen} tipoProspecto={"ca"} />

      {!isMobile ? (
        <div className="opciones" style={{ borderBottom: "1px solid" }}>
          <Link
            id={
              window.location.pathname.includes("requerimientos")
                ? "active1"
                : "notActive1"
            }
            to={`/prospectos/comprador/requerimientos?id=${perfil}`}
          >
            <h3 className="requerimiento">Requerimientos</h3>
          </Link>
          <Link
            id={
              window.location.pathname.includes("actividades")
                ? "active1"
                : "notActive1"
            }
            to={`/prospectos/comprador/actividades?id=${perfil}`}
          >
            <h3 className="actvidades">Actividades</h3>
          </Link>
        </div>
      ) : (
        <ProspectoCompradorNavegacionMobile id={perfil} />
      )}

      <div className="main2">
        <div className="barraNav">
          <button
            className="boton"
            onClick={() => handleOpenAddRequirementModal()}
          >
            Agregar requerimiento
          </button>
          <div>
            <select className="seleccionador">
              <option>Ordenar por </option>
            </select>{" "}
            <input type="text" placeholder="Buscar" className="buscar"></input>
            <SearchIcon />
          </div>
        </div>
        {requestGetRequerimientos === "LOADING" && <CircularProgress />}
        {requestGetRequerimientos === "IDLE" &&
          (!requerimientos || requerimientos.length === 0) && (
            <div>No se han agregado requerimientos todavia</div>
          )}
        {requestGetRequerimientos === "IDLE" &&
          requerimientos &&
          requerimientos.length > 0 &&
          requerimientos.map((requerimiento) => {
            return (
              <RequerimientoItem
                requerimiento={requerimiento}
                reloadMethod={reloadData}
              />
            );
          })}
      </div>
      <FormRequerimiento
        isOpen={isOpenAddRequirementModal}
        handleClose={handleCloseAddRequirementModal}
        idProspecto={perfil}
        reloadMethod={reloadData}
      />
    </div>
  );
};

export default PerfilComprador;
