import { useDispatch } from "react-redux";
import { fetchAllRegiones } from "../../action";
import ListProspComprador from "./Comprador/ListProspComprador";
import ListPropsPropietario from "./Vendedor/ListProspPropietario";
import { useEffect } from "react";

const IndexProspectos = ({}) => {
  const dispatch = useDispatch();  
  useEffect(() => {
    dispatch(fetchAllRegiones());
  }, []);
  if (window.location.pathname.includes("comprador")) {
    return (
        <ListProspComprador />
    );
  } else {
    return (
        <ListPropsPropietario />
    );
  }
};

export default IndexProspectos;