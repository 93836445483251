import InfoPerfil from "../../Propietarios/InfoPerfil";

const PropietarioProp = ({ idCliente }) => {
  return (
    <div className="main">
      <InfoPerfil idCliente={idCliente} />
    </div>
  );
};

export default PropietarioProp;