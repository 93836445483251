import { Button, Grid } from "@mui/material";
import { ESTADOS_ACTIVIDAD } from "../../utils/constants";
import React from "react";
import moment from "moment";
import { Visibility } from "@mui/icons-material";

const DetalleActividad = ({ values, getNombreUsuario, toggleVerArchivos }) => (
  <Grid
    container
    justifyContent="flex-start"
    className="alignLeft"
    rowSpacing={2}
  >
    <Grid item xs={6} sm={6} md={6} lg={6} xl={6} className="subtitle">
      Título
    </Grid>
    <Grid item xs={6} sm={6} md={6} lg={6} xl={6}>
      {values && values.titulo}
    </Grid>
    <Grid item xs={6} sm={6} md={6} lg={6} xl={6} className="subtitle">
      Descripción
    </Grid>
    <Grid item xs={6} sm={6} md={6} lg={6} xl={6}>
      {values && values.descripcion}
    </Grid>
    <Grid item xs={6} sm={6} md={6} lg={6} xl={6} className="subtitle">
      Estado actividad
    </Grid>
    <Grid item xs={6} sm={6} md={6} lg={6} xl={6}>
      {values &&
        values.estadoActividad != null &&
        values.estadoActividad != undefined && (
          <div
            className={
              values.estadoActividad === 0
                ? "confirmada"
                : values.estadoActividad === 1
                ? "pendiente"
                : "cancelada"
            }
            style={{ textAlign: "center" }}
          >
            {ESTADOS_ACTIVIDAD[values.estadoActividad]}
          </div>
        )}
    </Grid>
    <Grid item xs={6} sm={6} md={6} lg={6} xl={6} className="subtitle">
      Fecha actividad
    </Grid>
    <Grid item xs={6} sm={6} md={6} lg={6} xl={6}>
      {values && moment(values.fechaActividad).format("DD-MM-YYYY")}
    </Grid>
    <Grid item xs={6} sm={6} md={6} lg={6} xl={6} className="subtitle">
      Subido por
    </Grid>
    <Grid item xs={6} sm={6} md={6} lg={6} xl={6}>
      {values && getNombreUsuario(values.subidoPor)}
    </Grid>
    <Grid item xs={6} sm={6} md={6} lg={6} xl={6} className="subtitle">
      Archivos
    </Grid>
    <Grid item xs={6} sm={6} md={6} lg={6} xl={6}>
      {values && values.archivos && values.archivos.length > 0 && (
        <Button
          onClick={() => toggleVerArchivos()}
          variant="text"
          startIcon={<Visibility />}
        >
          Ver archivos
        </Button>
      )}
    </Grid>
  </Grid>
);

export default DetalleActividad;
