import {
  Box,
  Collapse,
  Grid,
  IconButton,
  TableRow,
  Typography,
} from "@mui/material";
import TableCell from "@mui/material/TableCell";
import React, { useState } from "react";
import { useSelector } from "react-redux";
import "moment/locale/es";
import { KeyboardArrowDown, KeyboardArrowUp } from "@mui/icons-material";

const isMobile =
  !!navigator.userAgent.match(/iphone|android|blackberry/gi) || false;

const FilaMeta = ({ meta, i }) => {
  const brokers = useSelector((state) => state.app.usuariosFiltrados);
  const [open, setOpen] = useState(false);

  const getNombreBroker = (email) => {
    const user =
      brokers &&
      brokers.some((u) => u.email === email) &&
      brokers.find((u) => u.email === email);
    if (user) {
      return `${user.nombres} ${user.apellidos}`;
    }
    return "";
  };

  if (isMobile) {
    return (
      <React.Fragment>
        <TableRow
          className={i % 2 === 0 ? "filaOscura" : ""}
          sx={{ "& > *": { borderBottom: "unset" } }}
        >
          <TableCell>
            <IconButton
              aria-label="expand row"
              size="small"
              onClick={() => setOpen(!open)}
            >
              {open ? <KeyboardArrowUp /> : <KeyboardArrowDown />}
            </IconButton>
          </TableCell>
          <TableCell align="center">
            {meta && meta.nombreBroker
              ? meta.nombreBroker
              : getNombreBroker(meta.emailBroker)}
          </TableCell>
          <TableCell align="center">{meta && meta.periodo}</TableCell>
        </TableRow>
        <TableRow>
          <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
            <Collapse in={open} timeout="auto" unmountOnExit>
              <Box sx={{ margin: 1 }}>
                <Typography variant="h6" gutterBottom>
                  Detalle
                </Typography>
                <Grid container>
                  <Grid item xs={6} sm={6}>
                    <b>Meta</b>
                  </Grid>
                  <Grid item xs={6} sm={6} style={{ textAlign: "right" }}>
                    {meta && meta.valorMeta}
                  </Grid>
                  <Grid item xs={6} sm={6}>
                    <b>Comisión ganada</b>
                  </Grid>
                  <Grid item xs={6} sm={6} style={{ textAlign: "right" }}>
                    {meta && meta.comisionPropins}
                  </Grid>
                  <Grid item xs={6} sm={6}>
                    <b>Comisión ganada</b>
                  </Grid>
                  <Grid item xs={6} sm={6} style={{ textAlign: "right" }}>
                    {meta &&
                      parseInt((meta.comisionPropins / meta.valorMeta) * 100)}
                    %
                  </Grid>
                </Grid>
              </Box>
            </Collapse>
          </TableCell>
        </TableRow>
      </React.Fragment>
    );
  } else {
    return (
      <React.Fragment>
        <TableRow
          className={i % 2 === 0 ? "filaOscura" : ""}
          sx={{ "& > *": { borderBottom: "unset" } }}
        >
          <TableCell align="center">
            {meta && meta.nombreBroker
              ? meta.nombreBroker
              : getNombreBroker(meta.emailBroker)}
          </TableCell>
          <TableCell align="center">{meta && meta.periodo}</TableCell>
          <TableCell align="center">{meta && meta.comisionPropins}</TableCell>
          <TableCell align="center">
            {meta && parseInt((meta.comisionPropins / meta.valorMeta) * 100)}%
          </TableCell>
          <TableCell align="center">{meta && meta.valorMeta}</TableCell>
        </TableRow>
      </React.Fragment>
    );
  }
};
export default FilaMeta;
