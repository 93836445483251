import {
  Backdrop,
  Button,
  CircularProgress,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Paper,
  Select,
  Table,
  TableBody,
  TableContainer,
  TableHead,
  TableRow,
  styled,
} from "@mui/material";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import React, { useEffect, useState } from "react";
import moment from "moment";
import { useDispatch, useSelector } from "react-redux";
import { getMetas, uploadArchivoMetas } from "../../action";
import Swal from "sweetalert2";
import FileUpload from "../../assets/FileUpload";
import utilsFunc from "../../utils/utilsFunctions";
import 'moment/locale/es'
import FilaMeta from "./FilaMeta";

export const ALLOWED_MIME_TYPES = [
  "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
  "application/vnd.ms-excel",
];

const { GetTrimester } = utilsFunc;

const isMobile =
  !!navigator.userAgent.match(/iphone|android|blackberry/gi) || false;

const columnsNonMobile = [
  { id: "nombre", label: "Nombre Broker", minWidth: 170, align: "center" },
  { id: "periodo", label: "Periodo", minWidth: 100, align: "center" },
  { id: "avance", label: "Avance", minWidth: 100, align: "center" },
  { id: "porcentaje", label: "% Avance", minWidth: 140, align: "center" },
  {
    id: "meta",
    label: "Meta",
    minWidth: 70,
    align: "center",
  },
];

const columnsMobile = [
  { id: "open", label: "", minWidth: 30, align: "center" },
  { id: "nombre", label: "Nombre Broker", minWidth: 170, align: "center" },
  { id: "periodo", label: "Periodo", minWidth: 100, align: "center" },
];

const columns = isMobile ? columnsMobile : columnsNonMobile;

const Metas = () => {
  const dispatch = useDispatch();
  const brokers = useSelector((state) => state.app.usuariosFiltrados);
  const [subirArchivo, setSubirArchivo] = useState(false);
  const [file, setFile] = useState(null);
  const [fileError, setFileError] = useState(null);
  const [periodo, setPeriodo] = useState("");
  const [subirPeriodo, setSubirPeriodo] = useState("");
  const metas = useSelector((state) => state.app.asdf);
  const requestGetMetas = useSelector((state) => state.app.requestGetMetas);
  const requestUploadArchivoMetas = useSelector(
    (state) => state.app.requestUploadArchivoMetas
  );

  const handleChange = (e) => {
    setPeriodo(e.target.value);
    dispatch(getMetas(e.target.value));
  };

  const handleChangeSubirPeriodo = (e) => {
    setSubirPeriodo(e.target.value);
    //dispatch(getMetas(e.target.value));
  };

  useEffect(() => {
    const today = moment();
    let month = today.month() + 1;
    let year = today.year();
    let currentPeriod = `${month}/${year}`;
    setPeriodo(currentPeriod);
  }, []);

  const generarOpcionesTrimestres = () => {
    let options = [];
    let jump = 0;
    for (var i = 0; i < 10; i++) {
      const today = moment();
      let period = today.subtract(jump, "months");
      let month = period.month() + 1;
      let year = period.year();
      let currentPeriod = `${month}/${year}`;

      options.push({
        label: GetTrimester(month, year),
        value: currentPeriod,
      });
      jump = jump + 3;
    }
    return options.map((p) => <MenuItem value={p.value}>{p.label}</MenuItem>);
  };

  const generarOpcionesPeriodos = () => {
    let options = [];
    let jump = 0;
    for (var i = 0; i < 12; i++) {
      const today = moment();
      let period = today.subtract(jump, "months");
      let month = period.month() + 1;
      let monthName = today.format("MMMM");
      let year = period.year();
      let currentPeriod = `${month}/${year}`;

      options.push({
        label: `${monthName} ${year}`,
        value: currentPeriod,
      });
      jump = jump + 1;
    }
    return options.map((p) => <MenuItem value={p.value}>{p.label}</MenuItem>);
  };

  const reloadMethod = () => {
    setFile(null);
    setFileError(null);
    dispatch(getMetas(periodo));
  };

  const onDrop = (files) => {
    if (files && files.length > 0) {
      setFile(files[0]);
    }
  };

  const upload = () => {
    let formData = new FormData();
    formData.append("file", file);
    dispatch(uploadArchivoMetas(formData, subirPeriodo));
  };

  const handleClose = () => console.log("close");

  if (requestUploadArchivoMetas === "SUCCESS") {
    Swal.fire({
      title: "Archivo subido",
      text: "Se ha subido el archivo de metas exitosamente",
      icon: "success",
    }).then((result) => {
      if (result.value || result.dismiss === "backdrop") {
        reloadMethod();
      }
    });
  }

  const StyledTableHead = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
      backgroundColor: "#00a62b",
      color: theme.palette.common.white,
    },
    [`&.${tableCellClasses.body}`]: {
      fontSize: 14,
    },
  }));

  return (
    <>
      <div className="header">
        <h2>Metas</h2>
        <Button onClick={() => setSubirArchivo(!subirArchivo)}>
          Subir archivo metas
        </Button>
      </div>
      {requestUploadArchivoMetas === "LOADING" && (
        <Backdrop
          sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
          open={true}
          onClick={handleClose}
        >
          <CircularProgress color="inherit" />
        </Backdrop>
      )}
      {subirArchivo && (
        <>
          <h3 className="alignLeft">Subir archivo metas</h3>
          <Grid container>
            <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
              <FormControl fullWidth size={"small"}>
                <InputLabel id="demo-simple-select-label">Periodo a subir</InputLabel>
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  value={subirPeriodo}
                  label="Age"
                  onChange={handleChangeSubirPeriodo}
                >
                  {generarOpcionesPeriodos()}
                </Select>
              </FormControl>
            </Grid>
          </Grid>
          <FileUpload
            name="archivos"
            uploadMultiple={false}
            acceptedFiles={ALLOWED_MIME_TYPES}
            onDrop={onDrop}
            sizeLimit={5000000}
            maxFiles={1}
            showFiles={true}
          />
          <Grid container justifyContent={"start"} className="alignLeft" style={{marginBottom: 40}}>
            <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
              Sólo se admiten archivos .xls y .xlsx
            </Grid>
            <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
              El archivo debe tener las siguientes cabeceras: EmailBroker,
              CodigoProp (códigos propiedad deben estar separados por comas), ValorCierre, ComisionBrokerVendedor,
              ComisionBrokerComprador, ComisionPropins, Meta
            </Grid>
            <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
              Las cabeceras que indiquen valores numéricos no deben incluir comas, puntos ni símbolos $.
            </Grid>
            {fileError && (
              <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                {fileError}
              </Grid>
            )}
            {file && (
              <>
                <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                  {file.name}
                </Grid>
                <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                  <button onClick={() => upload()}>Subir archivo</button>
                </Grid>
              </>
            )}
          </Grid>
        </>
      )}
      <Grid container justifyContent={"space-between"}>
        <Grid item>
          <h3 className="alignLeft">Resumen</h3>
        </Grid>
        <Grid item xs={6} sm={6} md={4} lg={4} xl={4}>
          <FormControl fullWidth size={"small"}>
            <InputLabel id="demo-simple-select-label">Periodo</InputLabel>
            <Select
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              value={periodo}
              label="Age"
              onChange={handleChange}
            >
              {generarOpcionesTrimestres()}
            </Select>
          </FormControl>
        </Grid>
      </Grid>

      {requestGetMetas === "LOADING" && <CircularProgress />}
      {requestGetMetas === "IDLE" && metas && (
        <Paper component={Paper} style={{ marginBottom: "2rem" }}>
          <TableContainer>
            <Table size={isMobile ? "small" : "medium"}>
              <TableHead>
                <TableRow>
                  {columns.map((column) => (
                    <StyledTableHead
                      key={column.id}
                      align={column.align}
                      style={{ minWidth: column.minWidth }}
                    >
                      {column.label}
                    </StyledTableHead>
                  ))}
                </TableRow>
              </TableHead>
              <TableBody>
                {metas.length === 0 ? (
                  <div>No se han encontrado resultados para su búsqueda</div>
                ) : (
                  metas.map((meta, i) => (
                    <FilaMeta meta={meta} i={i} />
                  ))
                )}
              </TableBody>
            </Table>
          </TableContainer>
        </Paper>
      )}
    </>
  );
};
export default Metas;
