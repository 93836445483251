import SearchIcon from "@mui/icons-material/Search";
import FiltroPropiedades from "./FiltroPropiedades";
import { useDispatch, useSelector } from "react-redux";
import InfoBroker from "./InfoBroker";
import React, { useState } from "react";
import { getPropiedadesPaginadas, getUsuariosFiltrados } from "../../action";
import { useEffect } from "react";
import {
  CircularProgress,
  Grid,
  Paper,
  Snackbar,
  Table,
  TableBody,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  styled,
} from "@mui/material";
import { Error } from "@mui/icons-material";
import { FilaOtraPropiedad } from "./FilaOtraPropiedad";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";

const isMobile =
  !!navigator.userAgent.match(/iphone|android|blackberry/gi) || false;

const columnsNonMobile = [
  { id: "operacion", label: "Operación", minWidth: "10px", align: "center" },
  { id: "precio", label: "Precio", minWidth: 40, align: "center" },
  { id: "comuna", label: "Comuna", minWidth: 40, align: "center" },
  { id: "tipo", label: "Tipo", minWidth: 70, align: "center" },
  { id: "tipologia", label: "Tipología", minWidth: 50, align: "center" },
  { id: "estado", label: "Estado", minWidth: 140, align: "center" },
  { id: "codigo", label: "Código", minWidth: 40, align: "center" },
  { id: "ficha", label: "Ficha", minWidth: 80, align: "center" },
  { id: "actions", label: "Acciones", minWidth: 120, align: "center" },
  { id: "solicitar", label: "Solicitar", minWidth: 120, align: "center" },
];

const columnsMobile = [
  { id: "comuna", label: "Comuna", minWidth: 40, align: "center" },
  { id: "codigo", label: "Código", minWidth: 40, align: "center" },
  { id: "precio", label: "Precio", minWidth: 70, align: "center" },
  { id: "acciones", label: "Acciones", minWidth: 1, align: "center" },
];

const columns = isMobile ? columnsMobile : columnsNonMobile;

const StyledTableHead = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: "#00a62b",
    color: theme.palette.common.white,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));

const ListOtrasPropiedades = () => {
  const dispatch = useDispatch();
  let propiedades = useSelector(
    (state) =>
      state.app.propiedadesPaginadas && state.app.propiedadesPaginadas.results
  );
  let data = useSelector((state) => state.app.propiedadesPaginadas);
  let requestState = useSelector(
    (state) =>
      state.app.requestPropiedadesPaginadas &&
      state.app.requestPropiedadesPaginadas
  );
  let brokers = useSelector((state) => state.app.usuariosFiltrados);
  const userData = useSelector((state) => state.app.itemUsuario);
  const [filtroValues, setFiltroValues] = useState({
    tipoOperacion: localStorage.getItem('filtroPropiedadTipoOperacion')
      ? localStorage.getItem('filtroPropiedadTipoOperacion') : "",
    tipoPropiedad: localStorage.getItem('filtroPropiedadTipoPropiedad')
    ? localStorage.getItem('filtroPropiedadTipoPropiedad') : "",
    comuna: localStorage.getItem('filtroPropiedadComuna')
    ? localStorage.getItem('filtroPropiedadComuna') : "",
    dormitoriosDesde: localStorage.getItem('filtroPropiedadDormitoriosDesde')
      ? localStorage.getItem('filtroPropiedadDormitoriosDesde') : 0,
    dormitoriosHasta: localStorage.getItem('filtroPropiedadDormitoriosHasta')
    ? localStorage.getItem('filtroPropiedadDormitoriosHasta') : 0,
    baniosDesde: localStorage.getItem('filtroPropiedadBaniosDesde')
    ? localStorage.getItem('filtroPropiedadBaniosDesde') : 0,
    baniosHasta: localStorage.getItem('filtroPropiedadBaniosHasta')
    ? localStorage.getItem('filtroPropiedadBaniosHasta') : 0,
    codigoPropiedad: localStorage.getItem('filtroPropiedadCodigo')
    ? localStorage.getItem('filtroPropiedadCodigo') : "",
    superficieTotalDesde: localStorage.getItem('filtroPropiedadSuperficieTotalDesde')
    ? localStorage.getItem('filtroPropiedadSuperficieTotalDesde') : 0,
    superficieTotalHasta: localStorage.getItem('filtroPropiedadSuperficieTotalHasta')
    ? localStorage.getItem('filtroPropiedadSuperficieTotalHasta') : 0,
    superficieUtilDesde: localStorage.getItem('filtroPropiedadSuperficieUtilDesde')
    ? localStorage.getItem('filtroPropiedadSuperficieUtilDesde') : 0,
    superficieUtilHasta: localStorage.getItem('filtroPropiedadSuperficieUtilHasta')
    ? localStorage.getItem('filtroPropiedadSuperficieUtilHasta') : 0,
    estadoPropiedad: localStorage.getItem('filtroPropiedadEstado')
    ? localStorage.getItem('filtroPropiedadEstado') : "",
    tipoMoneda: localStorage.getItem('filtroPropiedadTipoMoneda')
    ? localStorage.getItem('filtroPropiedadTipoMoneda') : "",
    estacionamiento: localStorage.getItem('filtroPropiedadEstacionamientos')
    ? localStorage.getItem('filtroPropiedadEstacionamientos') : 0,
    bodega: localStorage.getItem('filtroPropiedadBodegas')
    ? localStorage.getItem('filtroPropiedadBodegas') : 0,
    idBroker: ""
  });
  const [buscador, setBuscador] = useState("");
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [sortBy, setSortBy] = useState("createdAtDescending");
  const [currentBroker, setCurrentBroker] = useState(null);
  const [currentProp, setCurrentProp] = useState(null);
  const [copyNotification, setCopyNotification] = useState(false);
  const handleCloseCopyNotification = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setCopyNotification(false);
  };
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const onChangeSortBy = (event) => {
    setSortBy(event.target.value);
  };

  const onClickSolicitarCanje = (propiedad) => {
    const idBroker =
      propiedad && propiedad.idBroker ? propiedad.idBroker : null;
    if (idBroker && brokers && brokers.length > 0) {
      const filteredBroker = brokers.find((b) => b.id === idBroker);
      setCurrentProp(propiedad.id);
      setCurrentBroker(filteredBroker);
      setEstadoInfo(true);
    }
  };

  useEffect(() => {    
    dispatch(
      getPropiedadesPaginadas(
        "",
        filtroValues.idBroker,
        page + 1,
        rowsPerPage,
        "todos",
        sortBy,
        filtroValues.comuna,
        filtroValues.tipoPropiedad,
        filtroValues.tipoOperacion,
        filtroValues.valorDesde,
        filtroValues.valorHasta,
        filtroValues.baniosDesde,
        filtroValues.baniosHasta,
        filtroValues.dormitoriosDesde,
        filtroValues.dormitoriosHasta,
        filtroValues.superficieUtilDesde,
        filtroValues.superficieUtilHasta,
        filtroValues.superficieTotalDesde,
        filtroValues.superficieTotalHasta,
        filtroValues.codigoPropiedad,
        filtroValues.tipoMoneda,
        "",
        buscador,
        filtroValues.estacionamiento,
        filtroValues.bodega
      )
    );    
  }, [page, rowsPerPage, sortBy, buscador]);

  useEffect(() => {    
    setFiltroValues(filtroValues)
  }, [filtroValues]);

  useEffect(() => {
    dispatch(getUsuariosFiltrados(10));
  }, []);

  const sortOptions = {
    createdAtDescending: "Ordenar por fecha de creación descendiente",
    createdAtAscending: "Ordenar por fecha de creación ascendiente",
    precioAscending: "Ordenar por precio ascendiente",
    precioDescending: "Ordenar por precio descendiente",
  };

  const [estadoInfo, setEstadoInfo] = useState(false);

  const onCopyLink = (propiedad) => {
    setCopyNotification(true);
    let baseUrl =
      window.location.hostname.includes("dev.propins.cl") ||
      window.location.hostname.includes("localhost")
        ? "https://dev.propins.cl/info-propiedad?idprop="
        : "https://propins.cl/info-propiedad?idprop=";

    navigator.clipboard.writeText(`${baseUrl}${propiedad.id}`);
  };

  const onChangeBuscador = (e) => {
    setBuscador(e.target.value);
  };

  return (
    <div className="contenido" style={{width: '100%'}}>
      <div className="header">
        <h2>Otras propiedades</h2>
      </div>
      <FiltroPropiedades
        page={page}
        rowsPerPage={rowsPerPage}
        sortBy={sortBy}
        getPropiedades={getPropiedadesPaginadas}
        filtroValues={filtroValues}
        setFiltroValues={setFiltroValues}
        buscador={buscador}
        />
      <div className="main2">
        <Grid container justifyContent={"flex-end"}>
          <Grid item>
            <select
              value={sortBy}
              onChange={onChangeSortBy}
              className="seleccionador"
              placeholder="Ordenar por..."
            >
              {Object.keys(sortOptions).map((s) => (
                <option value={s}>{sortOptions[s]}</option>
              ))}
            </select>
          </Grid>
          <Grid item>
            <input
              type="text"
              placeholder="Buscar"
              className="buscar"
              onKeyUp={onChangeBuscador}
            />
            <SearchIcon />
          </Grid>
        </Grid>
        {requestState === "ERROR" && (
          <Grid
            container
            spacing={1}
            justifyContent="center"
            alignItems="center"
          >
            <Grid item xs={6} sm={6} md={4} lg={4} xl={4}>
              <Paper variant="outlined">
                <Error sx={{ fontSize: 40 }} />
                Ha habido un error
              </Paper>
            </Grid>
          </Grid>
        )}
        {requestState === "LOADING" && <CircularProgress />}
        {requestState === "IDLE" && propiedades && (
          <Paper component={Paper} style={{ marginBottom: "2rem" }}>
            <TableContainer>
              <Table size={isMobile ? "small" : "medium"}>
                <TableHead>
                  <TableRow>
                    {columns.map((column) => (
                      <StyledTableHead
                        key={column.id}
                        align={column.align}
                        style={{ minWidth: column.minWidth }}
                      >
                        {column.label}
                      </StyledTableHead>
                    ))}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {propiedades.length === 0 ? (
                    <div>No se han encontrado resultados para su búsqueda</div>
                  ) : (
                    propiedades.map((propiedad, i) => (
                      <FilaOtraPropiedad
                        propiedad={propiedad}
                        i={i}
                        isMobile={isMobile}
                        onCopyLink={onCopyLink}
                        onClickSolicitarCanje={onClickSolicitarCanje}
                      />
                    ))
                  )}
                </TableBody>
              </Table>
            </TableContainer>
            {requestState === "IDLE" &&
              propiedades &&
              propiedades.length > 0 && (
                <TablePagination
                  component="div"
                  count={data && data.totalResults}
                  page={page}
                  onPageChange={handleChangePage}
                  rowsPerPage={rowsPerPage}
                  onRowsPerPageChange={handleChangeRowsPerPage}
                  SelectProps={{
                    inputProps: { "aria-label": "Cantidad" },
                    native: true,
                  }}
                  labelRowsPerPage={"Resultados por página"}
                />
              )}
          </Paper>
        )}
      </div>

      <Snackbar
        open={copyNotification}
        autoHideDuration={4000}
        onClose={handleCloseCopyNotification}
        message="Enlace copiado"
      />

      <InfoBroker
        isOpen={estadoInfo}
        handleClose={setEstadoInfo}
        brokerData={currentBroker}
        idPropiedad={currentProp}
      />
    </div>
  );
};

export default ListOtrasPropiedades;
