import FiltroPropiedades from "./FiltroPropiedades";
import { useDispatch, useSelector } from "react-redux";
import SearchIcon from "@mui/icons-material/Search";

import {
  getPropiedadesPaginadas,
  postTarjetaProp,
  deleteTarjetaProp,
  publicarEnYapo,
  publicarEnPI,
  actualizarEnPI,
  fetchGetAllPlanes,
  fetchAllRegiones,
  getClientesPaginados,
  fetchDeletePropiedad,
  descargarFichaPropiedad,
  setFichaPropiedad,
  eliminarEnPI,
  actualizarEnYapo,
  eliminarEnYapo,
  fetchGetAllUsuarios,
  getUsuariosFiltrados,
  putPropiedadAsignarBroker,
} from "../../action";
import { useEffect, useState } from "react";
import {
  Backdrop,
  Box,
  Button,
  CircularProgress,
  Dialog,
  DialogTitle,
  Grid,
  IconButton,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Modal,
  Paper,
  Snackbar,
  Table,
  TableBody,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  styled,
} from "@mui/material";
import { Close, Error, OpenInNew, RotateLeft } from "@mui/icons-material";
import FormAgregarPropiedad from "./FormAgregarPropiedad";
import Swal from "sweetalert2";
import { FilaPropiedad } from "./FilaPropiedad";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import { Field, Form, Formik } from "formik";
import * as Yup from "yup";
import FieldError from "../../utils/FieldError";

const validate = Yup.object().shape({
  idBroker: Yup.string().required("Seleccione un broker"),
});

const isMobile =
  !!navigator.userAgent.match(/iphone|android|blackberry/gi) || false;

const columnsNonMobile = [
  { id: "precio", label: "Precio", minWidth: 40, align: "center" },
  { id: "operacion", label: "Operación", minWidth: "10px", align: "center" },
  { id: "comuna", label: "Comuna", minWidth: 40, align: "center" },
  { id: "codigo", label: "Código", minWidth: 40, align: "center" },
  { id: "tipo", label: "Tipo", minWidth: 70, align: "center" },
  { id: "tipologia", label: "Tipología", minWidth: 50, align: "center" },
  { id: "estado", label: "Estado", minWidth: 140, align: "center" },
  { id: "ficha", label: "Ficha", minWidth: 80, align: "center" },
  { id: "actions", label: "Acciones", minWidth: 120, align: "center" },
];

const columnsMobile = [
  { id: "comuna", label: "Comuna", minWidth: 40, align: "center" },
  { id: "codigo", label: "Código", minWidth: 40, align: "center" },
  { id: "precio", label: "Precio", minWidth: 70, align: "center" },
  { id: "acciones", label: "Acciones", minWidth: 1, align: "center" },
];

const columns = isMobile ? columnsMobile : columnsNonMobile;

const style = {
  position: "absolute",
  top: "0px",
  left: "50%",
  transform: "translate(-50%, 0%)",
  width: 1000,
  bgcolor: "background.paper",
  boxShadow: 24,
  p: 4,
  //maxHeight: '90%'
};

const styleBrokerModal = {
  position: "absolute",
  top: "0px",
  left: "50%",
  transform: "translate(-50%, 0%)",
  width: 600,
  bgcolor: "background.paper",
  boxShadow: 24,
  p: 4,
  //maxHeight: '90%'
};

const styleMobile = {
  position: "absolute",
  top: "0px",
  left: "0px",
  //transform: "translate(-50%, 0%)",
  width: "100vw",
  bgcolor: "background.paper",
  boxShadow: 24,
  p: 4,
  //maxHeight: '90%'
};

const StyledTableHead = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: "#00a62b",
    color: theme.palette.common.white,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));

const TablaPropiedades = ({
  idBroker,
  idCliente,
  incluirFiltro,
  incluirBotonCrearPropiedad,
  inluirSelectClientes,
}) => {
  const dispatch = useDispatch();
  let propiedades = useSelector(
    (state) =>
      state.app.propiedadesPaginadas && state.app.propiedadesPaginadas.results
  );
  let data = useSelector((state) => state.app.propiedadesPaginadas);
  const userData = useSelector((state) => state.app.itemUsuario);
  const [buscador, setBuscador] = useState(
    localStorage.getItem("filtroPropiedadBuscador")
      ? localStorage.getItem("filtroPropiedadBuscador")
      : ""
  );
  const requestPropiedadesPaginadas = useSelector(
    (state) =>
      state.app.requestPropiedadesPaginadas &&
      state.app.requestPropiedadesPaginadas
  );
  const requestDeleteTarjetaProp = useSelector(
    (state) => state.app.requestDeleteTarjetaProp
  );
  const requestPostTarjetaProp = useSelector(
    (state) => state.app.requestPostTarjetaProp
  );
  const requestUpdatePropiedad = useSelector(
    (state) => state.app.requestUpdatePropiedad
  );
  const requestPropiedadBroker = useSelector(
    (state) => state.app.requestPropiedadBroker
  );
  const requestUsuarioFiltrados = useSelector(
    (state) => state.app.requestUsuarioFiltrados
  );
  const brokers = useSelector((state) => state.app.usuariosFiltrados);
  const errorMessage = useSelector((state) => state.app.errorMessage);
  const planes = useSelector((state) => state.app.itemPlanes);
  const [isLoading, setIsLoading] = useState(false);
  const [currentProp, setCurrentProp] = useState(null);
  const [copyNotification, setCopyNotification] = useState(false);

  const handleCloseCopyNotification = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setCopyNotification(false);
  };
  const fichaPropiedad = useSelector((state) => state.app.fichaPropiedad);
  const requestPostPI = useSelector((state) => state.app.requestPostPI);
  const requestPutPI = useSelector((state) => state.app.requestPutPI);
  const requestDeletePI = useSelector((state) => state.app.requestDeletePI);
  const requestPostYapo = useSelector((state) => state.app.requestPostYapo);
  const requestPutYapo = useSelector((state) => state.app.requestPutYapo);
  const requestDeleteYapo = useSelector((state) => state.app.requestDeleteYapo);
  const requestDeletePropiedad = useSelector(
    (state) => state.app.requestDeletePropiedad
  );

  useEffect(() => {
    if (
      requestPropiedadesPaginadas === "LOADING" ||
      requestDeletePropiedad === "LOADING" ||
      requestPostTarjetaProp === "LOADING" ||
      requestDeleteTarjetaProp === "LOADING" ||
      requestPostPI === "LOADING" ||
      requestPutPI === "LOADING" ||
      requestDeletePI === "LOADING" ||
      requestPostYapo === "LOADING" ||
      requestPutYapo === "LOADING" ||
      requestDeleteYapo === "LOADING"
    ) {
      setIsLoading(true);
    } else {
      setIsLoading(false);
    }
  }, [
    requestPropiedadesPaginadas,
    requestDeletePropiedad,
    requestDeleteTarjetaProp,
    requestPostTarjetaProp,
    requestPostYapo,
    requestPutYapo,
    requestDeleteYapo,
    requestPostPI,
    requestPutPI,
    requestDeletePI,
  ]);

  useEffect(() => {
    dispatch(fetchGetAllPlanes());
    dispatch(fetchAllRegiones());
    //dispatch(fetchGetAllClientes());
    dispatch(getUsuariosFiltrados(10));
    dispatch(fetchGetAllUsuarios());
    dispatch(getClientesPaginados(0, 999, "true"));
  }, []);
  const [filtroValues, setFiltroValues] = useState({
    tipoOperacion: localStorage.getItem("filtroPropiedadTipoOperacion")
      ? localStorage.getItem("filtroPropiedadTipoOperacion")
      : "",
    tipoPropiedad: localStorage.getItem("filtroPropiedadTipoPropiedad")
      ? localStorage.getItem("filtroPropiedadTipoPropiedad")
      : "",
    comuna: localStorage.getItem("filtroPropiedadComuna")
      ? localStorage.getItem("filtroPropiedadComuna")
      : "",
    dormitoriosDesde: localStorage.getItem("filtroPropiedadDormitoriosDesde")
      ? localStorage.getItem("filtroPropiedadDormitoriosDesde")
      : 0,
    dormitoriosHasta: localStorage.getItem("filtroPropiedadDormitoriosHasta")
      ? localStorage.getItem("filtroPropiedadDormitoriosHasta")
      : 0,
    baniosDesde: localStorage.getItem("filtroPropiedadBaniosDesde")
      ? localStorage.getItem("filtroPropiedadBaniosDesde")
      : 0,
    baniosHasta: localStorage.getItem("filtroPropiedadBaniosHasta")
      ? localStorage.getItem("filtroPropiedadBaniosHasta")
      : 0,
    codigoPropiedad: localStorage.getItem("filtroPropiedadCodigo")
      ? localStorage.getItem("filtroPropiedadCodigo")
      : "",
    superficieTotalDesde: localStorage.getItem(
      "filtroPropiedadSuperficieTotalDesde"
    )
      ? localStorage.getItem("filtroPropiedadSuperficieTotalDesde")
      : 0,
    superficieTotalHasta: localStorage.getItem(
      "filtroPropiedadSuperficieTotalHasta"
    )
      ? localStorage.getItem("filtroPropiedadSuperficieTotalHasta")
      : 0,
    superficieUtilDesde: localStorage.getItem(
      "filtroPropiedadSuperficieUtilDesde"
    )
      ? localStorage.getItem("filtroPropiedadSuperficieUtilDesde")
      : 0,
    superficieUtilHasta: localStorage.getItem(
      "filtroPropiedadSuperficieUtilHasta"
    )
      ? localStorage.getItem("filtroPropiedadSuperficieUtilHasta")
      : 0,
    estadoPropiedad: localStorage.getItem("filtroPropiedadEstado")
      ? localStorage.getItem("filtroPropiedadEstado")
      : "",
    tipoMoneda: localStorage.getItem("filtroPropiedadTipoMoneda")
      ? localStorage.getItem("filtroPropiedadTipoMoneda")
      : "",
    estacionamiento: localStorage.getItem("filtroPropiedadEstacionamientos")
      ? localStorage.getItem("filtroPropiedadEstacionamientos")
      : 0,
    bodega: localStorage.getItem("filtroPropiedadBodegas")
      ? localStorage.getItem("filtroPropiedadBodegas")
      : 0,
    idBroker: userData && userData.tipoCuenta === 10 ? userData.userId : ""
      
  });
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [sortBy, setSortBy] = useState("createdAtDescending");

  const [openDialogYapo, setOpenDialogYapo] = useState(false);
  const handleDialogYapoOpen = (prop) => {
    setCurrentProp(prop);
    setOpenDialogYapo(true);
  };
  const handleCloseDialogYapo = () => setOpenDialogYapo(false);

  const [openDialogPI, setOpenDialogPI] = useState(false);
  const handleDialogPIOpen = (prop) => {
    setCurrentProp(prop);
    setOpenDialogPI(true);
  };
  const handleCloseDialogPI = () => setOpenDialogPI(false);

  const [openEditModal, setOpenEditModal] = useState(false);
  const handleOpenEditModal = (prop) => {
    setCurrentProp(prop);
    setOpenEditModal(true);
  };

  const handleCloseEditModal = (e, reason) => {
    setOpenEditModal(false);
  };

  const [openBrokerModal, setOpenBrokerModal] = useState(false);
  const handleOpenBrokerModal = (prop) => {
    setCurrentProp(prop);
    setOpenBrokerModal(true);
  };
  const handleCloseBrokerModal = (e, reason) => {
    setOpenBrokerModal(false);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const onChangeSortBy = (event) => {
    setSortBy(event.target.value);
  };

  const openLinkPI = (idPublicacion) => {
    const link = `https://www.portalinmobiliario.com/${idPublicacion.substring(
      0,
      3
    )}-${idPublicacion.substring(3)}`;
    window.open(link, "_blank");
  };

  const publicarEnPortalPI = (propiedadSeleccionada) => {
    let cuentaConCtdadMinimaFotos = !(
      propiedadSeleccionada.imagenes === null ||
      (Array.isArray(propiedadSeleccionada.imagenes) &&
        propiedadSeleccionada.imagenes.length < 12)
    );
    const msgWarning = `<p class="red"><strong>Nota:</strong> la propiedad no cuenta con el mínimo de 12 fotos para estar bien posicionada en Portal Inmobiliario</p>`;
    Swal.fire({
      title: `Publicar en Portal Inmobiliario`,
      html: `<p>¿Está seguro de publicar esta propiedad en Portal Inmobiliario?</p> ${
        !cuentaConCtdadMinimaFotos ? msgWarning : ""
      }`,
      //text: `¿Está seguro de publicar esta propiedad en Portal Inmobiliario?`,
      showCancelButton: true,
      confirmButtonText: "Publicar",
      cancelButtonText: `Cancelar`,
    }).then((result) => {
      if (result.value) {
        handleCloseDialogPI();
        dispatch(publicarEnPI(propiedadSeleccionada.id));
      }
    });
  };

  const publicarEnPortalYapo = (propiedadSeleccionada) => {
    Swal.fire({
      title: `Publicar en Yapo`,
      text: `¿Está seguro de publicar esta propiedad en Yapo?`,
      showCancelButton: true,
      confirmButtonText: "Publicar",
      cancelButtonText: `Cancelar`,
    }).then((result) => {
      if (result.value) {
        dispatch(publicarEnYapo(propiedadSeleccionada.id));
      }
    });
  };

  const actualizarEnPortalInmobiliario = (propiedadSeleccionada) => {
    Swal.fire({
      title: `Actualizar publicación en Portal Inmobiliario`,
      html: `<p>¿Está seguro de actualizar la publicación de esta propiedad en Portal Inmobiliario?</p><p> <strong>Nota:</strong> Ten en consideración que los campos que puedes actualizar son:</p>
      <p>
      Ubicación (dirección, comuna y/o ciudad)
      <br />Precio
      <br />Dormitorios
      <br />Baños
      <br />Estacionamientos
      <br />Bodegas
      <br />Superficie util
      <br />Superficie total
      </p>`,
      showCancelButton: true,
      confirmButtonText: "Publicar",
      cancelButtonText: `Cancelar`,
    }).then((result) => {
      if (result.value) {
        handleCloseDialogPI();
        dispatch(actualizarEnPI(propiedadSeleccionada.id));
      }
    });
  };

  const actualizarYapo = (propiedadSeleccionada) => {
    Swal.fire({
      title: `Actualizar publicación en Yapo`,
      text: `¿Está seguro de actualizar la publicación de esta propiedad en Yapo?`,
      showCancelButton: true,
      confirmButtonText: "Actualizar",
      cancelButtonText: `Cancelar`,
    }).then((result) => {
      if (result.value) {
        handleCloseDialogYapo();
        dispatch(actualizarEnYapo(propiedadSeleccionada.id));
      }
    });
  };
  const eliminarYapo = (propiedadSeleccionada) => {
    Swal.fire({
      title: `Eliminar publicación en Yapo`,
      text: `¿Está seguro de eliminar la publicación de esta propiedad en Yapo?`,
      showCancelButton: true,
      confirmButtonText: "Eliminar",
      cancelButtonText: `Cancelar`,
    }).then((result) => {
      if (result.value) {
        handleCloseDialogYapo();
        dispatch(eliminarEnYapo(propiedadSeleccionada.id));
      }
    });
  };

  const eliminarEnPortalInmobiliario = (propiedadSeleccionada) => {
    Swal.fire({
      title: `Eliminar publicación en Portal Inmobiliario`,
      text: `¿Está seguro de eliminar la publicación de esta propiedad en Portal Inmobiliario?`,
      showCancelButton: true,
      confirmButtonText: "Eliminar",
      cancelButtonText: `Cancelar`,
    }).then((result) => {
      if (result.value) {
        handleCloseDialogPI();
        dispatch(eliminarEnPI(propiedadSeleccionada.id));
      }
    });
  };

  if (requestPostYapo === "ERROR") {
    Swal.fire("Error", errorMessage, "error");
  }
  if (requestPostYapo === "SUCCESS") {
    Swal.fire({
      title: "Publicado en Yapo",
      text: "Se ha publicado la propiedad en Yapo exitosamente",
      icon: "success",
    }).then((result) => {
      if (result.value || result.dismiss === "backdrop") {
        reloadPropiedades();
      }
    });
  }

  if (requestPutYapo === "SUCCESS") {
    Swal.fire({
      title: "Actualizado en Yapo",
      text: "Se ha actualizado la propiedad en Yapo exitosamente",
      icon: "success",
    }).then((result) => {
      if (result.value || result.dismiss === "backdrop") {
        reloadPropiedades();
      }
    });
  }

  if (requestDeleteYapo === "SUCCESS") {
    Swal.fire({
      title: "Eliminado en Yapo",
      text: "Se ha eliminado la propiedad en Yapo exitosamente",
      icon: "success",
    }).then((result) => {
      if (result.value || result.dismiss === "backdrop") {
        reloadPropiedades();
      }
    });
  }

  if (requestPostPI === "ERROR") {
    Swal.fire("Error", errorMessage, "error");
  }

  if (requestPostPI === "SUCCESS") {
    Swal.fire({
      title: "Publicado en Portal Inmobiliario",
      text: "Se ha publicado la propiedad en Portal Inmobiliario exitosamente",
      icon: "success",
    }).then((result) => {
      if (result.value || result.dismiss === "backdrop") {
        reloadPropiedades();
      }
    });
  }

  if (requestPutPI === "SUCCESS") {
    Swal.fire({
      title: "Actualizado en Portal Inmobiliario",
      text: "Se ha actualizado la propiedad en Portal Inmobiliario exitosamente",
      icon: "success",
    }).then((result) => {
      if (result.value || result.dismiss === "backdrop") {
        reloadPropiedades();
      }
    });
  }

  if (requestDeletePI === "SUCCESS") {
    Swal.fire({
      title: "Eliminado en Portal Inmobiliario",
      text: "Se ha eliminado la propiedad en Portal Inmobiliario exitosamente",
      icon: "success",
    }).then((result) => {
      if (result.value || result.dismiss === "backdrop") {
        reloadPropiedades();
      }
    });
  }

  if (requestPropiedadBroker == "SUCCESS") {
    Swal.fire({
      title: "Broker actualizado",
      text: "Se ha actualizado el broker asociado a la propiedad exitosamente",
      icon: "success",
    }).then((result) => {
      if (result.value || result.dismiss === "backdrop") {
        handleCloseBrokerModal();
        reloadPropiedades();
      }
    });
  }

  useEffect(() => {
    dispatch(
      getPropiedadesPaginadas(
        "",
        filtroValues.idBroker,
        page + 1,
        rowsPerPage,
        "todos",
        sortBy,
        filtroValues.comuna,
        filtroValues.tipoPropiedad,
        filtroValues.tipoOperacion,
        filtroValues.valorDesde,
        filtroValues.valorHasta,
        filtroValues.baniosDesde,
        filtroValues.baniosHasta,
        filtroValues.dormitoriosDesde,
        filtroValues.dormitoriosHasta,
        filtroValues.superficieUtilDesde,
        filtroValues.superficieUtilHasta,
        filtroValues.superficieTotalDesde,
        filtroValues.superficieTotalHasta,
        filtroValues.codigoPropiedad,
        filtroValues.tipoMoneda,
        idCliente,
        buscador,
        filtroValues.estacionamiento,
        filtroValues.bodega
      )
    );
  }, [page, rowsPerPage, sortBy, idCliente, buscador]);

  const sortOptions = {
    createdAtDescending: "Ordenar por fecha de creación descendiente",
    createdAtAscending: "Ordenar por fecha de creación ascendiente",
    precioAscending: "Ordenar por precio ascendiente",
    precioDescending: "Ordenar por precio descendiente",
  };

  const onCopyLink = (propiedad) => {
    setCopyNotification(true);
    let baseUrl =
      window.location.hostname.includes("dev.propins.cl") ||
      window.location.hostname.includes("localhost")
        ? "https://dev.propins.cl/info-propiedad?idprop="
        : "https://propins.cl/info-propiedad?idprop=";

    navigator.clipboard.writeText(`${baseUrl}${propiedad.id}`);
  };

  const showConfirmationDialog = (row, isDelete) => {
    let verb = "Generar";
    if (isDelete) {
      verb = "Deshabilitar";
    }
    Swal.fire({
      title: `${verb} tarjeta propiedad`,
      text: `Confirma que desea ${verb.toLowerCase()} la tarjeta para esta propiedad`,
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Confirmar",
    }).then((result) => {
      if (result.value) {
        if (isDelete) {
          dispatch(deleteTarjetaProp(row.id));
        } else {
          dispatch(postTarjetaProp(row.id));
        }
      }
    });
  };

  const showDeleteConfirmationDialog = (id) => {
    Swal.fire({
      title: `Eliminar la propiedad`,
      text: `Confirma que desea eliminar esta propiedad`,
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Confirmar",
    }).then((result) => {
      if (result.value) {
        dispatch(fetchDeletePropiedad(id));
      }
    });
  };

  if (requestPostTarjetaProp === "SUCCESS") {
    Swal.fire({
      title: "Tarjeta propiedad generada",
      text: "Se ha generado la tarjeta para la propiedad seleccionada",
      icon: "success",
    }).then((result) => {
      if (result.value || result.dismiss === "backdrop") {
        reloadPropiedades();
      }
    });
  }

  if (requestDeletePropiedad === "SUCCESS") {
    Swal.fire({
      title: "Propiedad eliminada",
      text: "Se ha eliminado la propiedad exitosamente",
      icon: "success",
    }).then((result) => {
      if (result.value || result.dismiss === "backdrop") {
        reloadPropiedades();
      }
    });
  }

  if (requestDeleteTarjetaProp === "SUCCESS") {
    Swal.fire({
      title: "Tarjeta propiedad deshabilitada",
      text: "Se ha deshabilitado la tarjeta para la propiedad seleccionada",
      icon: "success",
    }).then((result) => {
      if (result.value || result.dismiss === "backdrop") {
        reloadPropiedades();
      }
    });
  }

  if (
    requestDeleteTarjetaProp === "ERROR" ||
    requestPostTarjetaProp === "ERROR" ||
    requestPropiedadBroker === "ERROR"
  ) {
    Swal.fire({
      title: "Error",
      text: errorMessage,
      icon: "error",
    }).then((result) => {
      if (result.value || result.dismiss === "backdrop") {
        reloadPropiedades();
      }
    });
  }

  const reloadPropiedades = () => {
    dispatch(
      getPropiedadesPaginadas(
        "",
        idBroker,
        page + 1,
        rowsPerPage,
        "todos",
        sortBy,
        "",
        "",
        "",
        0,
        0,
        0,
        0,
        0,
        0,
        0,
        0,
        0,
        0,
        "",
        "",
        idCliente,
        ""
      )
    );
  };
  
  const onChangeBuscador = (e) => {
    localStorage.setItem("filtroPropiedadBuscador", buscador);
    setBuscador(e.target.value);
  };

  const descargarFicha = (id) => {
    dispatch(descargarFichaPropiedad(id));
  };

  const submitBroker = (formValues) => {
    if (currentProp) {
      dispatch(putPropiedadAsignarBroker(currentProp.id, formValues.idBroker));
    }
  };

  useEffect(() => {
    if (fichaPropiedad !== null && fichaPropiedad !== undefined) {
      const url = window.URL.createObjectURL(new Blob([fichaPropiedad]));
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", `Ficha propiedad.pdf`);
      document.body.appendChild(link);
      link.click();
      dispatch(setFichaPropiedad(null));
    }
  }, [fichaPropiedad]);

  return (
    <Grid container>
      <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
        {incluirFiltro && (
          <Grid container>
            <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
              <FiltroPropiedades
                page={page}
                rowsPerPage={rowsPerPage}
                sortBy={sortBy}
                getPropiedades={getPropiedadesPaginadas}
                filtroValues={filtroValues}
                setFiltroValues={setFiltroValues}
                buscador={buscador}
              />
            </Grid>
          </Grid>
        )}
        <Grid
          sx={{ mt: 2 }}
          container
          justifyContent={
            incluirBotonCrearPropiedad ? "space-between" : "flex-end"
          }
        >
          {incluirBotonCrearPropiedad && (
            <Grid item className="alignLeft">
              <button
                className="boton"
                onClick={() => handleOpenEditModal(null)}
              >
                Agregar propiedad
              </button>
            </Grid>
          )}
          <Grid item>
            <Grid container justifyContent={"space-between"}>
              <Grid item>
                <select
                  value={sortBy}
                  onChange={onChangeSortBy}
                  className="seleccionador"
                  placeholder="Ordenar por..."
                >
                  {Object.keys(sortOptions).map((s) => (
                    <option value={s}>{sortOptions[s]}</option>
                  ))}
                </select>
              </Grid>
              <Grid item>
                <input
                  type="text"
                  placeholder="Buscar"
                  className="buscar"
                  onKeyUp={onChangeBuscador}
                />
                <SearchIcon />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        {isLoading && <CircularProgress />}
        {requestPropiedadesPaginadas === "ERROR" && (
          <Grid
            container
            sx={{ mt: 2 }}
            justifyContent="center"
            alignItems="center"
          >
            <Grid item xs={6} sm={6} md={4} lg={4} xl={4}>
              <Paper variant="outlined">
                <Error sx={{ fontSize: 40 }} />
                Ha habido un error
              </Paper>
            </Grid>
          </Grid>
        )}
        {requestPropiedadesPaginadas === "IDLE" &&
          !Swal.isVisible() &&
          !isLoading &&
          propiedades && (
            <Paper component={Paper} style={{ marginBottom: "2rem" }}>
              <TableContainer>
                <Table size={isMobile ? "small" : "medium"}>
                  <TableHead>
                    <TableRow>
                      {columns.map((column) => (
                        <StyledTableHead
                          key={column.id}
                          align={column.align}
                          style={{ minWidth: column.minWidth }}
                        >
                          {column.label}
                        </StyledTableHead>
                      ))}
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {propiedades.length === 0 ? (
                      <div>
                        No se han encontrado resultados para su búsqueda
                      </div>
                    ) : (
                      propiedades.map((propiedad, i) => (
                        <FilaPropiedad
                          propiedad={propiedad}
                          i={i}
                          isMobile={isMobile}
                          showConfirmationDialog={showConfirmationDialog}
                          descargarFicha={descargarFicha}
                          showDeleteConfirmationDialog={
                            showDeleteConfirmationDialog
                          }
                          handleOpenEditModal={handleOpenEditModal}
                          onCopyLink={onCopyLink}
                          handleDialogYapoOpen={handleDialogYapoOpen}
                          publicarEnPortalYapo={publicarEnPortalYapo}
                          handleDialogPIOpen={handleDialogPIOpen}
                          publicarEnPortalPI={publicarEnPortalPI}
                          handleOpenBrokerModal={handleOpenBrokerModal}
                        />
                      ))
                    )}
                  </TableBody>
                </Table>
              </TableContainer>
              {requestPropiedadesPaginadas === "IDLE" &&
                propiedades &&
                propiedades.length > 0 && (
                  <TablePagination
                    component="div"
                    count={data && data.totalResults}
                    page={page}
                    onPageChange={handleChangePage}
                    rowsPerPage={rowsPerPage}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                    SelectProps={{
                      inputProps: { "aria-label": "Cantidad" },
                      native: true,
                    }}
                    labelRowsPerPage={""}
                  />
                )}
            </Paper>
          )}

        <Modal
          disableScrollLock={false}
          open={openEditModal}
          onClose={handleCloseEditModal}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
          sx={{
            overflowY: "auto",
            top: isMobile ? "0px" : "10px",
            maxHeight: isMobile ? "100%" : "95%",
          }}
        >
          <Box sx={isMobile ? styleMobile : style}>
            <Grid
              container
              justifyContent="space-between"
              alignItems={"center"}
              sx={{ marginBottom: 2 }}
            >
              <Grid item xs={10} sm={10} md={10} lg={10} xl={10}>
                <h3 className="alignLeft">
                  {currentProp ? "Editar propiedad" : "Agregar propiedad"}
                </h3>
              </Grid>
              <Grid item>
                <IconButton
                  color="primary"
                  aria-label="copiar"
                  component="span"
                  onClick={() => handleCloseEditModal()}
                >
                  <Close sx={{ fontSize: "20pt" }} />
                </IconButton>
              </Grid>
            </Grid>
            <FormAgregarPropiedad
              initialValues={currentProp}
              planes={planes}
              idCliente={idCliente}
              submitMethod={null}
              requestState={requestUpdatePropiedad}
              reloadMethod={reloadPropiedades}
              handleClose={handleCloseEditModal}
              inluirSelectClientes={inluirSelectClientes}
            />
          </Box>
        </Modal>

        <Modal
          disableScrollLock={false}
          open={openBrokerModal}
          onClose={handleCloseBrokerModal}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
          sx={{
            overflowY: "auto",
            top: isMobile ? "0px" : "10px",
            maxHeight: isMobile ? "100%" : "95%",
          }}
        >
          <Box sx={isMobile ? styleMobile : styleBrokerModal}>
            {requestPropiedadBroker == "LOADING" && (
              <Backdrop
                sx={{
                  color: "#fff",
                  zIndex: (theme) => theme.zIndex.drawer + 1,
                }}
                open={true}
                //onClick={handleClose}
              >
                <CircularProgress color="inherit" />
              </Backdrop>
            )}
            <Grid
              container
              justifyContent="space-between"
              alignItems={"center"}
              sx={{ marginBottom: 2 }}
            >
              <Grid item xs={10} sm={10} md={10} lg={10} xl={10}>
                <h3 className="alignLeft">Administrar broker propiedad</h3>
              </Grid>
              <Grid item>
                <IconButton
                  color="primary"
                  aria-label="copiar"
                  component="span"
                  onClick={() => handleCloseBrokerModal()}
                >
                  <Close sx={{ fontSize: "20pt" }} />
                </IconButton>
              </Grid>
            </Grid>
            <Grid container justifyContent="flex-start">
              <Grid
                item
                xs={12}
                sm={12}
                md={12}
                lg={12}
                xl={12}
                className="alignLeft"
              >
                Propiedad: {currentProp && currentProp.codigoPropiedad}
              </Grid>
            </Grid>
            <Formik
              initialValues={{
                idBroker: currentProp && currentProp.idBroker,
              }}
              validationSchema={validate}
              onSubmit={submitBroker}
            >
              {({ handleSubmit }) => (
                <>
                  <Form onSubmit={handleSubmit} className="form-container">
                    <Grid container>
                      <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                        <label className="milabel">Broker</label>
                        <Field
                          name="idBroker"
                          as="select"
                          className="miSelect"
                          //disabled={idCliente ? true : false}
                        >
                          <option value={""}>- Seleccione -</option>
                          {brokers &&
                            brokers.length > 0 &&
                            brokers.map((c) => (
                              <option value={c.id}>
                                {c.nombres} {c.apellidos}
                              </option>
                            ))}
                        </Field>
                        <FieldError name="idBroker" />
                      </Grid>
                    </Grid>
                    <Grid container justifyContent="flex-end" sx={{ mt: 2 }}>
                      <Grid item>
                        <Button
                          onClick={handleCloseBrokerModal}
                          className="main-btn"
                        >
                          Cancelar
                        </Button>
                      </Grid>
                      <Grid item>
                        <Button
                          type="submit"
                          className="main-btn"
                          variant="contained"
                          color="success"
                        >
                          Guardar
                        </Button>
                      </Grid>
                    </Grid>
                  </Form>
                </>
              )}
            </Formik>
          </Box>
        </Modal>

        <Dialog
          onClose={handleCloseDialogYapo}
          aria-labelledby="simple-dialog-title"
          open={openDialogYapo}
        >
          <DialogTitle id="simple-dialog-title">
            Administrar publicación en Yapo
          </DialogTitle>
          <List>
            <ListItem autoFocus button>
              <ListItemIcon>
                <OpenInNew />
              </ListItemIcon>
              <ListItemText
                primary="Ir a la publicación"
                onClick={() =>
                  window.open(`${currentProp.urlPublicacionYapo}`, "_blank")
                }
              />
            </ListItem>

            <ListItem autoFocus button>
              <ListItemIcon>
                <RotateLeft />
              </ListItemIcon>
              <ListItemText
                primary="Actualizar publicación en Yapo"
                secondary="En caso de que hayas actualizado los datos de la propiedad"
                onClick={() => actualizarYapo(currentProp)}
              />
            </ListItem>
            <ListItem autoFocus button>
              <ListItemIcon>
                <Close />
              </ListItemIcon>
              <ListItemText
                primary="Eliminar publicación en Yapo"
                secondary="Dar de baja la publicación"
                onClick={() => eliminarYapo(currentProp)}
              />
            </ListItem>
          </List>
        </Dialog>

        <Dialog
          onClose={handleCloseDialogPI}
          aria-labelledby="simple-dialog-title"
          open={openDialogPI}
        >
          <DialogTitle id="simple-dialog-title">
            Administrar publicación en Portal Inmobiliario
          </DialogTitle>
          <List>
            <ListItem autoFocus button>
              <ListItemIcon>
                <RotateLeft />
              </ListItemIcon>
              <ListItemText
                primary="Actualizar publicación en Portal Inmobiliario"
                secondary="En caso de que hayas actualizado los datos de la propiedad"
                onClick={() => actualizarEnPortalInmobiliario(currentProp)}
              />
            </ListItem>

            <ListItem autoFocus button>
              <ListItemIcon>
                <OpenInNew />
              </ListItemIcon>
              <ListItemText
                primary="Ir a la publicación"
                onClick={() => openLinkPI(currentProp.idPublicacionPI)}
              />
            </ListItem>
            <ListItem autoFocus button>
              <ListItemIcon>
                <Close />
              </ListItemIcon>
              <ListItemText
                primary="Eliminar publicación en Portal Inmobiliario"
                secondary="Dar de baja la publicación"
                onClick={() => eliminarEnPortalInmobiliario(currentProp)}
              />
            </ListItem>
          </List>
        </Dialog>
      </Grid>
      <Snackbar
        open={copyNotification}
        autoHideDuration={4000}
        onClose={handleCloseCopyNotification}
        message="Enlace copiado"
      />
    </Grid>
  );
};

export default TablaPropiedades;
