import { Formik, Form, Field } from "formik";
import { useDispatch, useSelector } from "react-redux";
import {
  Backdrop,
  Box,
  Button,
  CircularProgress,
  Grid,
  IconButton,
  ImageList,
  ImageListItem,
  ImageListItemBar,
  Modal,
  Typography,
} from "@mui/material";
import { useEffect, useState } from "react";
import {
  fetchAddInmobiliaria,
} from "../../action";
import Swal from "sweetalert2";
import { Close, Delete } from "@mui/icons-material";
import * as Yup from "yup";
import Dropzone from "react-dropzone";
import FieldError from "../../utils/FieldError";

const validate = Yup.object().shape({
  direccion: Yup.string().required("Ingrese una dirección"),
  urlInmobiliaria: Yup.string().required("Ingrese una url"),
  mail: Yup.string().email('Ingrese un correo electrónico válido').required("Ingrese un correo electrónico"),
  nombre: Yup.string().required("Ingrese un nombre"),
  telefono: Yup.string().required("Ingrese un teléfono"),
});

export const ALLOWED_MIME_TYPES = [
  "image/png",
  "image/x-citrix-png",
  "image/x-png",
  "image/jpeg",
  "image/x-citrix-jpeg",
  "image/pjpeg",
];

const isMobile =
  !!navigator.userAgent.match(/iphone|android|blackberry/gi) || false;

const style = {
  position: "absolute",
  top: "0px",
  left: "50%",
  transform: "translate(-50%, 0%)",
  width: 500,
  bgcolor: "background.paper",
  boxShadow: 24,
  p: 4,
  //maxHeight: '90%'
};

const styleMobile = {
  position: "absolute",
  top: "0px",
  left: "0px",
  //transform: "translate(-50%, 0%)",
  width: "100vw",
  bgcolor: "background.paper",
  boxShadow: 24,
  p: 4,
  //maxHeight: '90%'
};

const FormInmobiliaria = ({ isOpen, handleClose, initialValues, reloadMethod }) => {
  const dispatch = useDispatch();
  const [isLoading, setIsLoading] = useState(false);
  const [logo, setLogo] = useState(
    initialValues && initialValues.logo ? initialValues.logo : null
  );
  const requestPutInmobiliaria = useSelector(
    (state) => state.app.requestPutInmobiliaria
  );
  const requestAddInmobiliaria = useSelector(
    (state) => state.app.requestAddInmobiliaria
  );
  const errorMessage = useSelector((state) => state.app.errorMessage);
  useEffect(() => {
    if (initialValues != null && initialValues.logo != null) {
      setLogo(initialValues.logo);
    } else {
      setLogo(null);
    }
  }, [initialValues]);

  const fileToBase64 = (file) => {
    if (file) {
      return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => resolve(reader.result);
        reader.onerror = (error) => reject(error);
      });
    }
  };

  const onDrop = (files) => {
    if (files) {
      files.map((f) => {
        if (f !== null) {
          fileToBase64(f).then((file64) => {
            const newFile = {
              name: f.name,
              value: file64,
            };
            setLogo(newFile);
          });
        }
      });
    }
  };

  const handleSubmit = (formValues) => {
    const data = {
      ...formValues,
      logo,
    };
    dispatch(fetchAddInmobiliaria(data));
  };

  if (requestPutInmobiliaria === "SUCCESS") {
    handleClose();
    Swal.fire({
      title: "Inmobiliaria actualizada",
      text: "Se ha actualizado la inmobiliaria exitosamente",
      icon: "success",
    }).then((result) => {
      if (result.value || result.dismiss === "backdrop") {
       // reloadMethod();
      }
    });
  }

  if (requestAddInmobiliaria === "SUCCESS") {
    handleClose();
    Swal.fire({
      title: "Inmobiliaria agregada",
      text: "Se ha agregado la inmobiliaria exitosamente",
      icon: "success",
    }).then((result) => {
      if (result.value || result.dismiss === "backdrop") {
        reloadMethod();
      }
    });
  }

  if (requestAddInmobiliaria === "ERROR" || requestPutInmobiliaria === "ERROR") {
    Swal.fire({
      title: "Error",
      text: errorMessage,
      icon: "error",
    });
  }

  useEffect(() => {
    if (
      requestPutInmobiliaria === "LOADING" ||
      requestAddInmobiliaria === "LOADING"
    ) {
      setIsLoading(true);
    } else {
      setIsLoading(false);
    }
  }, [requestPutInmobiliaria, requestAddInmobiliaria]);

  const [open, setOpen] = useState(false);

  const handleCloseBackdrop = () => {
    setOpen(false);
  };

  return (
    <Modal
      disableScrollLock={false}
      open={isOpen}
      onClose={handleClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"   
      sx={{
        overflowY: "auto",
        top: isMobile ? "0px" : "10px",
        maxHeight: isMobile ? "100%" : "95%",
      }}
    >
      <Box sx={isMobile ? styleMobile : style}>
        {isLoading && (
          <Backdrop
            sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
            open={true}
            onClick={handleCloseBackdrop}
          >
            <CircularProgress color="inherit" />
          </Backdrop>
        )}
        <Grid
          container
          justifyContent="space-between"
          alignItems={"center"}
          sx={{ marginBottom: 2 }}
        >
          <Grid item xs={10} sm={10} md={10} lg={10} xl={10}>
            <h4>Crear inmobiliaria</h4>
          </Grid>
          <Grid item>
            <IconButton
              color="primary"
              aria-label="copiar"
              component="span"
              onClick={() => handleClose()}
            >
              <Close sx={{ fontSize: "20pt" }} />
            </IconButton>
          </Grid>
        </Grid>
        <Formik
          initialValues={{
            direccion: initialValues && initialValues.direccion,
            urlInmobiliaria: initialValues && initialValues.urlInmobiliaria,
            mail: initialValues && initialValues.mail,
            nombre: initialValues && initialValues.nombre,
            telefono: initialValues && initialValues.telefono,
            imgLogoUrl: initialValues && initialValues.imgLogoUrl,
            logo: logo,
          }}
          onSubmit={handleSubmit}
          validationSchema={validate}
        >
          {({ values, handleSubmit }) => (
            <Form onSubmit={handleSubmit}>
              <Grid container spacing={2}>
                <Grid item xs={12} sm={6} md={6} lg={6} xl={6}>
                  <label className="milabel">Nombre</label>
                  <Field name="nombre" type="text" className="miSelect" />
                  <FieldError name="nombre" />
                </Grid>
                <Grid item xs={12} sm={6} md={6} lg={6} xl={6}>
                  <label className="milabel">Teléfono</label>
                  <Field name="telefono" type="text" className="miSelect" />
                  <FieldError name="telefono" />
                </Grid>
                <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                  <label className="milabel">Dirección</label>
                  <Field name="direccion" type="text" className="miSelect" />
                  <FieldError name="direccion" />
                </Grid>
                <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                  <label className="milabel">Correo electrónico</label>
                  <Field name="mail" type="text" className="miSelect" />
                  <FieldError name="mail" />
                </Grid>
                <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                  <label className="milabel">Url sitio web inmobiliaria</label>
                  <Field
                    name="urlInmobiliaria"
                    type="text"
                    className="miSelect"
                  />
                  <FieldError name="urlInmobiliaria" />
                </Grid>
              </Grid>
              <Grid container justifyContent="flex-start">
                <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                  <label className="milabel">Logo</label>
                  <Dropzone
                    name="logo"
                    multiple={false}
                    onDropAccepted={(files) => onDrop(files)}
                    accept={ALLOWED_MIME_TYPES}
                    maxFiles={1}
                  >
                    {({ getRootProps, getInputProps }) => (
                      <section>
                        <div
                          {...getRootProps({
                            className: "dropzonenew",
                          })}
                        >
                          <input {...getInputProps()} />
                          <p>
                            Arrastre las imágenes aquí o haga click para
                            seleccionar
                          </p>
                        </div>
                      </section>
                    )}
                  </Dropzone>
                  <FieldError name="logo" />
                  <Typography variant="caption" display="block" gutterBottom>
                    Formatos de imagen aceptados: .PNG, .JPG y .JPEG y .mp4.
                  </Typography>
                  <ImageList
                    rowHeight={170}
                    gap={2}
                    cols={isMobile ? 2 : 3}
                    sx={{ width: "100%" }}
                  >
                    {logo && (
                      <ImageListItem key={1}>
                        <img
                          src={
                            logo.value
                              ? logo.value
                              : logo.downloadLink
                          }
                          srcSet={
                            logo.value
                              ? logo.value
                              : logo.downloadLink
                          }
                          alt={logo.name}
                          loading="lazy"
                          width="240px"
                          height="170px"
                        />
                        <ImageListItemBar
                          sx={{
                            background: "none",
                          }}
                          position="bottom"
                          actionIcon={
                            <IconButton
                              sx={{
                                color: "rgba(245, 66, 66, 1)",
                              }}
                              aria-label={`info about ${logo.name}`}
                              onClick={() => setLogo(null)}
                            >
                              <Delete />
                            </IconButton>
                          }
                        />
                      </ImageListItem>
                    )}
                  </ImageList>
                </Grid>
              </Grid>

              <Grid container justifyContent="flex-end" sx={{ mt: 2 }}>
                <Grid item>
                  <Button onClick={handleClose} className="main-btn">
                    Cancelar
                  </Button>
                </Grid>
                <Grid item>
                  <Button
                    type="submit"
                    className="main-btn"
                    variant="contained"
                    color="success"
                  >
                    Guardar
                  </Button>
                </Grid>
              </Grid>
            </Form>
          )}
        </Formik>
      </Box>
    </Modal>
  );
};

export default FormInmobiliaria;
