/** @format */

//const urlService = "https://localhost:44355";
//const urlService = "https://localhost:5001";
const urlService = window.location.hostname.includes("dev.propins.cl") ||
    window.location.hostname.includes("localhost")
    ? "https://apidev.propins.cl"
    : "https://api.propins.cl";

export { urlService };
