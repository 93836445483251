import EditIcon from "@mui/icons-material/Edit";
import { Grid, Tooltip, IconButton, CircularProgress } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import { getProspecto } from "../../../action";
import FormPropiedadProspecto from "./FormPropiedadProspecto";

const PropesctoProp = ({isOpen, handleClose, setIsOpen}) => {
  const dispatch = useDispatch();
  const propiedadProspecto = useSelector((state) => state.app.prospecto);
  const request = useSelector((state) => state.app.requestGetProspecto);
  let { search } = useLocation();
  let query = new URLSearchParams(search);
  let perfil = query.get("id");
  
  const reloadData = () => {
    dispatch(getProspecto("va", perfil));
  }

  if (request === "LOADING") {
    return (<CircularProgress />)
  }
  else if (request !== "LOADING" && propiedadProspecto !== null) {
    return (
      <div>
        <Grid container justifyContent="space-between">
          <Grid item>
            <h3 className="alignLeft">Información general</h3>
          </Grid>
          <Grid item>
            <Tooltip title="Editar" aria-label="editar">
              <IconButton
                color="primary"
                aria-label="editar"
                component="span"
                onClick={() => setIsOpen(true)}
              >
                <EditIcon />
              </IconButton>
            </Tooltip>
          </Grid>
        </Grid>
          <Grid container spacing={1}>
            <Grid item xs={6} sm={6} md={4} lg={4} xl={3}>
              <label className="otrolabel">Link</label>
              <label className="otrolabel2">
                <a href={propiedadProspecto.linkPropiedad} className="link" target="_blank" rel="noreferrer">
                  {propiedadProspecto.linkPropiedad}
                </a>                
              </label>
            </Grid>
            <Grid item xs={6} sm={6} md={4} lg={4} xl={3}>
              <label className="otrolabel">Operación</label>
              <label className="otrolabel2">
                {propiedadProspecto.tipoOperacion === 0 ? "Arriendo" : "Venta"}
              </label>
            </Grid>
            <Grid item xs={6} sm={6} md={4} lg={4} xl={3}>
              <label className="otrolabel">TipoPropiedad</label>
              <label className="otrolabel2">
                {propiedadProspecto.tipoPropiedad}
              </label>
            </Grid>
          </Grid>
          <Grid container sx={{mt: 3}}>
            <Grid item>
              <h3 className="alignLeft">Ubicación</h3>
            </Grid>
          </Grid>
          <Grid container spacing={1}>            
            <Grid item xs={6} sm={6} md={4} lg={4} xl={3}>
              <label className="otrolabel">Calle</label>
              <label className="otrolabel2">
                {propiedadProspecto.calle}
              </label>
            </Grid>
            <Grid item xs={6} sm={6} md={4} lg={4} xl={3}>
              <label className="otrolabel">Número</label>
              <label className="otrolabel2">
                {propiedadProspecto.numero}
              </label>
            </Grid>
            {propiedadProspecto.tipoPropiedad === "Departamento" && (
              <Grid item xs={6} sm={6} md={4} lg={4} xl={3}>
                <label className="otrolabel">Número departamento</label>
                <label className="otrolabel2">
                  {propiedadProspecto.numeroDepartamento}
                </label>
              </Grid>
            )}            
          </Grid>
          <Grid container spacing={1} sx={{mt: 0.5, mb: 6}}>
            <Grid item xs={6} sm={6} md={4} lg={4} xl={3}>
              <label className="otrolabel">Comuna</label>
              <label className="otrolabel2">
                {propiedadProspecto.comuna}
              </label>
            </Grid>
            <Grid item xs={6} sm={6} md={4} lg={4} xl={3}>
              <label className="otrolabel">Dormitorios</label>
              <label className="otrolabel2">
                {propiedadProspecto.dormitorios}
              </label>
            </Grid>
            <Grid item xs={6} sm={6} md={4} lg={4} xl={3}>
              <label className="otrolabel">Baños</label>
              <label className="otrolabel2">
                {propiedadProspecto.banios}
              </label>
            </Grid>
          </Grid>
          <FormPropiedadProspecto values={propiedadProspecto} isOpen={isOpen} handleClose={handleClose} reloadMethod={reloadData} />
        </div>
    );
  } else {
    return (<div></div>)
  }
};

export default PropesctoProp;
