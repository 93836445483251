//TODO: implementar validacion
import { Formik, Form, Field } from "formik";
import { useDispatch, useSelector } from "react-redux";
import {
  Backdrop,
  Box,
  Button,
  CircularProgress,
  Grid,
  Modal,
} from "@mui/material";
import Swal from "sweetalert2";
import FileUpload from "../../assets/FileUpload";
import { postReporte } from "../../action";

const isMobile =
  !!navigator.userAgent.match(/iphone|android|blackberry/gi) || false;

const style = {
  position: "absolute",
  top: "0px",
  left: "50%",
  transform: "translate(-50%, 0%)",
  width: 500,
  bgcolor: "background.paper",
  boxShadow: 24,
  p: 4,
  //maxHeight: '90%'
};

const styleMobile = {
  position: "absolute",
  top: "0px",
  left: "0px",
  //transform: "translate(-50%, 0%)",
  width: "100vw",
  bgcolor: "background.paper",
  boxShadow: 24,
  p: 4,
  //maxHeight: '90%'
};

const ModalSubirReporte = ({ isOpen, handleClose, reloadMethod }) => {
  const dispatch = useDispatch();

  const requestPostReporte = useSelector(
    (state) => state.app.requestPostReporte
  );

  const errorMessage = useSelector((state) => state.app.errorMessage);

  const handleSubmit = (formValues) => {
    console.log(formValues);
    dispatch(postReporte(formValues));
  };

  const onDrop = (file, setFieldValueMethod, fieldName) => {
    if (file) {
      file.map((f) => {
        if (f) {
          fileToBase64(f).then((file64) => {
            /*
            file64 = {
              name: f.name,
              value: file64,
            };
            */
            //esto es para el update
            //values.archivoPDF = [...values.archivoPDF, file64];
            /*
            this.setState({
                file: file64
            });
            */
            setFieldValueMethod(fieldName, file64);
          });
        }
        return null;
      });
    }
  };

  const fileToBase64 = (file) => {
    if (file) {
      return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => resolve(reader.result);
        reader.onerror = (error) => reject(error);
      });
    }
  };

  if (requestPostReporte === "SUCCESS") {
    Swal.fire({
      title: "Reporte subido",
      text: "Se ha subido el reporte exitosamente",
      icon: "success",
    }).then((result) => {
      if (result.value || result.dismiss === "backdrop") {
        handleClose();
        reloadMethod();
      }
    });
  }

  if (requestPostReporte === "ERROR") {
    Swal.fire({
      title: "Error",
      text: errorMessage,
      icon: "error",
    });
  }

  
  /*
  if (
    documento !== null &&
    documento !== undefined &&
    requestDocument !== "IDLE" &&
    requestDocument !== "ERROR"
  ) {
    const url = window.URL.createObjectURL(new Blob([documento]));
    const link = document.createElement("a");
    link.href = url;
    let fileName = "file";
    link.setAttribute("download", `Orden visita${fechaVisita}.pdf`);
    document.body.appendChild(link);
    link.click();
    dispatch(setDocument(null));
  }
*/
  return (
    <Modal
      disableScrollLock={false}
      open={isOpen}
      onClose={handleClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"   
      sx={{
        overflowY: "auto",
        top: isMobile ? "0px" : "10px",
        maxHeight: isMobile ? "100%" : "95%",
      }}
    >
      <Box sx={isMobile ? styleMobile : style}>
        {requestPostReporte === "LOADING" && (
          <Backdrop
            sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
            open={true}
            onClick={handleClose}
          >
            <CircularProgress color="inherit" />
          </Backdrop>
        )}
        <h4>Subir reporte</h4>
        <Formik
          initialValues={{
            nombre: "",
            base64String: "",
          }}
          //validate={validate}
          onSubmit={handleSubmit}
        >
          {({ handleSubmit, setFieldValue }) => (
            <>
              <Form onSubmit={handleSubmit} className="form-container">
                <Grid container>
                  <Grid item xs={12} sm={12} md={12} lg={12} xl={12} sx={{mb: 2}}>
                    <label className="milabel">Nombre</label>
                    <Field name="nombre" type="text" className="miSelect" />
                  </Grid>
                  <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                    Seleccione archivo
                    <FileUpload
                      name="base64String"
                      acceptedFiles={["application/pdf"]}
                      onDrop={(file) =>
                        onDrop(file, setFieldValue, "base64String")
                      }
                      sizeLimit={5000000}
                      maxFiles={1}
                      showFiles={true}
                    />
                  </Grid>
                </Grid>

                <Grid container justifyContent="flex-end" sx={{ mt: 2 }}>
                  <Grid item>
                    <Button onClick={handleClose} className="main-btn">
                      Cancelar
                    </Button>
                  </Grid>
                  <Grid item>
                    <Button
                      type="submit"
                      className="main-btn"
                      variant="contained"
                      color="success"
                    >
                      Guardar
                    </Button>
                  </Grid>
                </Grid>
              </Form>
            </>
          )}
        </Formik>
      </Box>
    </Modal>
  );
};

export default ModalSubirReporte;
