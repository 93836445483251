import { Close } from "@mui/icons-material";
import {
  Box,
  Button,
  Grid,
  IconButton,
  Modal,
} from "@mui/material";
import { Field, Form, Formik } from "formik";
import { useState } from "react";
import { useDispatch } from "react-redux";
import FormProspCompradorArrendatario from "./Comprador/FormProspCompradorArrendatario";
import FormProspectoVendedor from "./Vendedor/FormProspectoVendedor";

const isMobile =
    !!navigator.userAgent.match(/iphone|android|blackberry/gi) || false;

const style = {
  position: "absolute",
  top: "0px",
  left: "50%",
  transform: "translate(-50%, 0%)",
  width: 500,
  bgcolor: "background.paper",
  boxShadow: 24,
  p: 4,
  //maxHeight: '90%'
};

const styleMobile = {
  position: "absolute",
  top: "0px",
  left: "0px",
  //transform: "translate(-50%, 0%)",
  width: "100vw",
  bgcolor: "background.paper",
  boxShadow: 24,
  p: 4,
  //maxHeight: '90%'
};

const styleMobileProspectoForm = {
  position: "absolute",
  top: "0px",
  left: "0px",
  //transform: "translate(0%, -50%)",
  width: "100vw",
  bgcolor: "background.paper",
  boxShadow: 24,
  p: 4,
};

const FormProspecto = ({ isOpen, handleClose, reloadMethod }) => {
  const dispatch = useDispatch();
  const handleSubmit = (formData) => {
    handleGoBack(formData.tipoProspecto);
  };
  const handleGoBack = (val) => {
    setTipoProspecto(val);
    switch (val) {
        case "va":
            setTitle("Agregar prospecto vendedor/arrendador");
            break;
        case "ca":
            setTitle("Agregar prospecto comprador/arrendatario");
            break;
        default:
            setTitle("Agregar prospecto");
            break;
    }
  }
  const [tipoProspecto, setTipoProspecto] = useState("");
  const [title, setTitle] = useState("Agregar prospecto");

  return (
    <Modal
      disableScrollLock={false}
      open={isOpen}
      onClose={handleClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"   
      sx={{
        overflowY: "auto",
        top: isMobile ? "0px" : "10px",
        maxHeight: isMobile ? "100%" : "95%",
      }}
    >
      <Box sx={!isMobile ? style : tipoProspecto === "" ? styleMobile : styleMobileProspectoForm}>
        <Grid container justifyContent="space-between" alignItems={"center"}>
          <Grid item xs={10} sm={10} md={10} lg={10} xl={10}>
            <h4>{ title }</h4>
          </Grid>
          <Grid item>
            <IconButton
              color="primary"
              aria-label="copiar"
              component="span"
              onClick={() => handleClose()}
            >
              <Close sx={{ fontSize: "20pt" }} />
            </IconButton>
          </Grid>
        </Grid>
        
        { tipoProspecto === "" && (
            <Formik
            initialValues={{
              tipoProspecto: "",
            }}
            onSubmit={handleSubmit}
          >
            {({ values, handleSubmit }) => (
              <Form onSubmit={handleSubmit}>
                <Grid container justifyContent="center">
                  <Grid item>
                    <label className="milabel">Tipo prospecto</label>
                    <Field name="tipoProspecto" as="select" className="miSelect">
                      <option value={""}>- Seleccione -</option>
                      <option value={"va"}>Vendedor/arrendador</option>
                      <option value={"ca"}>Comprador/arrendatario</option>
                    </Field>
                  </Grid>
                </Grid>
                <Grid container justifyContent="flex-end" sx={{mt: 2}}>
                  <Grid item>
                    <Button onClick={handleClose} className="main-btn">
                      Cancelar
                    </Button>
                  </Grid>
                  <Grid item>
                    <Button disabled={!(values && values.tipoProspecto && values.tipoProspecto !== "")} 
                      type="submit" className="main-btn" variant="contained" color="success">
                      Continuar
                    </Button>
                  </Grid>
                </Grid>
              </Form>
            )}
          </Formik>
        )}        
        { tipoProspecto === "va" && (
            <FormProspectoVendedor handleClose={handleClose} setTipoProspecto={handleGoBack} reloadMethod={reloadMethod} />
        ) }
        { tipoProspecto === "ca" && (
            <FormProspCompradorArrendatario handleClose={handleClose} setTipoProspecto={handleGoBack} reloadMethod={reloadMethod} />
        ) }
      </Box>
    </Modal>
  );
};

export default FormProspecto;
