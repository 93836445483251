import {
  Backdrop,
  Button,
  CardContent,
  CircularProgress,
  Grid,
} from "@mui/material";
import { Field, Form, Formik } from "formik";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { restablecerPassword, setReestablecerPassword, setValidateToken } from "../../action";
import Swal from 'sweetalert2';
import FieldError from "../../utils/FieldError";

const ChangePassword = ({changeLoginState}) => {
  const dispatch = useDispatch();
  const [message, setMessage] = useState(null);
  const request = useSelector((state) => state.app.requestCambioContraseña);
  const tokenPassword = useSelector((state) => state.app.tokenPassword);
  const restablecer = useSelector((state) => state.app.restablecer);
  const lastErrorMessage = useSelector((state) => state.app.errorMessage);

  useEffect(() => {
    if (request === "ERROR") {
      //setReadyToRedirect(false);
      setMessage(lastErrorMessage);
    }
  }, [request]);

  useEffect(() => {
    if (tokenPassword !== null && (tokenPassword.estado !== null && tokenPassword.estado === 0) 
      //&& (recover && (!recover.estado || recover.estado === 0))
    ) {
/*
      console.log(1)
      console.log("token expirado")
      Swal.fire({
        title: "Token Expirado!",
        text: "Su token expiró, haga click en 'olvidaste tu contraseña' y siga las instrucciones",
        icon: "warning",
      }).then((result) => {
        if (result.value || result.dismiss === "backdrop") {
          dispatch(setReestablecerPassword(null));
          dispatch(setValidateToken(null));
          changeLoginState("login");
        }
      });
      */
    } else if (restablecer && restablecer.estado === 1) {
      Swal.fire({
        title: "Cambio de contraseña exitoso",
        text: "Tu contraseña ha sido modificada con éxito, ahora puedes iniciar sesión",
        icon: "success",
      }).then((result) => {
        if (result.value || result.dismiss === "backdrop") {
          dispatch(setReestablecerPassword(null));
          dispatch(setValidateToken(null));
          changeLoginState("login");
        }
      });
    } else if (restablecer && restablecer.estado === 0) {
      Swal.fire({
        title: "Ups!",
        text: restablecer.mensajes,
        icon: "error",
      });
    } else if (!restablecer) {
      console.log("--")
    }
  }, [restablecer, tokenPassword]);

  const handleSubmit = (formValues) => {
    if (tokenPassword) {
        const data = {
            password: formValues.password,
            email: tokenPassword.usuarioEmail,
        }
        dispatch(restablecerPassword(data));
    }
  };

  return (
    <CardContent>
      <Grid container>
        <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
          <h3>Cambiar contraseña</h3>
        </Grid>
      </Grid>
      {request === "LOADING" && (
        <Backdrop
          sx={{
            color: "#fff",
            zIndex: (theme) => theme.zIndex.drawer + 1,
          }}
          open={true}
          onClick={console.log("cerrar")}
        >
          <CircularProgress color="inherit" />
        </Backdrop>
      )}
      <Formik
        initialValues={{
          passwordConfirm: "",
          password: "",
        }}
        validate={validate}
        onSubmit={handleSubmit}
      >
        {({ values, errors, handleSubmit }) => (
          <Form onSubmit={handleSubmit}>
            <Grid container>
              <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                <label className="milabel">Contraseña</label>
                <Field name="password" type="password" className="miSelect" disabled={request === "LOADING"} />
                <FieldError
                  name={"password"}
                  className={`color-red`}
                  //component={ErrorLabel}
                />
              </Grid>
            </Grid>
            <Grid container>
              <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                <label className="milabel">Confirmar contraseña</label>
                <Field name="passwordConfirm" type="password" className="miSelect" disabled={request === "LOADING"} />
                <FieldError
                  name={"passwordConfirm"}
                  className={`color-red`}
                  //component={ErrorLabel}
                />
              </Grid>
            </Grid>

            <Grid container justifyContent="center" sx={{ mt: 2 }}>
              <Grid item>
                <Button
                  type="submit"
                  className="main-btn"
                  variant="contained"
                  color="success"
                >
                  Ingresar
                </Button>
              </Grid>
            </Grid>
          </Form>
        )}
      </Formik>
      <Grid container>
        <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
          {message && <div>{message}</div>}
        </Grid>
      </Grid>
    </CardContent>
  );
};

const validate = (formValues) => {
  const errors = {};

  if (formValues.password === "") {
    errors.password = "Por favor ingrese una nueva contraseña";
  } else if (formValues.password.length < 7) {
    errors.password = "Su contraseña debe tener al menos 6 caracteres";
  } else {

  }

  if (formValues.passwordConfirm === "" || formValues.passwordConfirm !== formValues.password) {
    errors.passwordConfirm = "Las contraseñas ingresadas no coinciden";
  }
  console.log(errors);
  return errors;
}

export default ChangePassword;
