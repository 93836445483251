import PersonIcon from "@mui/icons-material/Person";
import PersonOutlineIcon from "@mui/icons-material/PersonOutline";
import HomeIcon from "@mui/icons-material/Home";
import EventNoteIcon from "@mui/icons-material/EventNote";
import MapsHomeWorkIcon from "@mui/icons-material/MapsHomeWork";
import HomeWorkOutlinedIcon from "@mui/icons-material/HomeWorkOutlined";
import MonetizationOnIcon from "@mui/icons-material/MonetizationOn";
import BusinessCenterIcon from "@mui/icons-material/BusinessCenter";
import LogoutIcon from '@mui/icons-material/Logout';

export const SideBarData = [
  {
    title: "Home",
    icon: <HomeIcon />,
    link: "/home",
    accountType: [0,9,10]
  },

  {
    title: "Clientes",
    icon: <PersonIcon />,
    link: "/propietarios",
    accountType: [0,9,10],
    submenu: [
      {
        title: "Propietarios",
        icon: <PersonIcon />,
        link: "/propietarios",
        accountType: [0,9,10]
      },
    
      {
        title: "Prospectos",
        icon: <PersonOutlineIcon />,
        link: "/prospectos/vendedor",
        accountType: [0,9,10]
      },
    ]
  },
  {
    title: "Propiedades",
    icon: <MapsHomeWorkIcon />,
    link: "/propiedades",
    accountType: [0,9,10],
    submenu: [
      {
        title: "Mis propiedades",
        icon: <MapsHomeWorkIcon />,
        link: "/mispropiedades",
        accountType: [0,9,10],
      },
      {
        title: "Otras propiedades",
        icon: <HomeWorkOutlinedIcon />,
        link: "/otraspropiedades",
        accountType: [0,9,10],
      },
    ],
  },

  {
    title: "Proyectos",
    icon: <MapsHomeWorkIcon />,
    link: "/proyectos",
    accountType: [0,9],
    submenu: [
      {
        title: "Proyectos",
        icon: <MapsHomeWorkIcon />,
        link: "/proyectos",
        accountType: [0,9],
      },
    ],
  },
  {
    title: "Agenda",
    icon: <EventNoteIcon />,
    link: "/agenda",
    accountType: [0,9,10],
  },

  {
    title: "Tasaciones",
    icon: <MonetizationOnIcon />,
    link: "/tasaciones",
    accountType: [0,9,10],
  },

  {
    title: "Recursos",
    icon: <BusinessCenterIcon />,
    link: "/recursos",
    accountType: [0,9,10],
    submenu: [
      {
        title: "Documentos",
        icon: <MonetizationOnIcon />,
        link: "/recursos/documentos",
        accountType: [0,9,10],
      },
      {
        title: "Difusion",
        icon: <MonetizationOnIcon />,
        link: "/recursos/difusion",
        accountType: [0,9,10],
      },
      {
        title: "Capacitaciones",
        icon: <MonetizationOnIcon />,
        link: "/recursos/capacitaciones",
        accountType: [0,9,10],
      },
      {
        title: "Reportes",
        icon: <MonetizationOnIcon />,
        link: "/recursos/reportes",
        accountType: [0,9,10],
      },
    ],
  },
  {
    title: "Cerrar sesión",
    icon: <LogoutIcon />,
    link: "/logout",
    accountType: [0,9,10],
  },
];
