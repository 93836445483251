import { Formik, Form, Field } from "formik";
import { useDispatch, useSelector } from "react-redux";
import {
  Backdrop,
  Box,
  Button,
  CircularProgress,
  Grid,
  IconButton,
  Modal,
} from "@mui/material";
import { CATEGORIAS_CAPACITACIONES } from "../../utils/constants";
import { useEffect, useState } from "react";
import {
  postCapacitacion,
  putCapacitacion,
} from "../../action";
import Swal from "sweetalert2";
import { Close } from "@mui/icons-material";

export const ALLOWED_MIME_TYPES = [
  "image/png",
  "image/x-citrix-png",
  "image/x-png",
  "image/jpeg",
  "image/x-citrix-jpeg",
  "image/pjpeg",
  "application/pdf",
];

const isMobile =
  !!navigator.userAgent.match(/iphone|android|blackberry/gi) || false;

const style = {
  position: "absolute",
  top: "0px",
  left: "50%",
  transform: "translate(-50%, 0%)",
  width: 500,
  bgcolor: "background.paper",
  boxShadow: 24,
  p: 4,
  //maxHeight: '90%'
};

const styleMobile = {
  position: "absolute",
  top: "0px",
  left: "0px",
  //transform: "translate(-50%, 0%)",
  width: "100vw",
  bgcolor: "background.paper",
  boxShadow: 24,
  p: 4,
  //maxHeight: '90%'
};

const ModalAgregarCapacitacion = ({
  isOpen,
  handleClose,
  values,
  reloadMethod,
}) => {
  const dispatch = useDispatch();
  const [isLoading, setIsLoading] = useState(false);
  const requestPutCapacitacion = useSelector(
    (state) => state.app.requestPutCapacitacion
  );
  const requestPostCapacitacion = useSelector(
    (state) => state.app.requestPostCapacitacion
  );

  const handleSubmit = (formValues) => {
    let idYoutube = "";
    if (formValues && formValues.link) {
      if (formValues.link.includes("youtube.com") && formValues.link.includes("=")) {
        idYoutube = formValues.link.split("=")[1];
      } else if (formValues.link.includes("youtu.be")) {
        idYoutube = formValues.link.split("/").slice(-1)[0];
      }
    }
    const data = {
      ...formValues,
      idYoutube
    };
    if (values && values.id) {
      dispatch(putCapacitacion(values.id, data));
    } else {
      dispatch(postCapacitacion(data));
    }
  };

  if (requestPutCapacitacion === "SUCCESS") {
    handleClose();
    Swal.fire({
      title: "Capacitacion actualizada",
      text: "Se ha actualizado la capacitacion exitosamente",
      icon: "success",
    }).then((result) => {
      if (result.value || result.dismiss === "backdrop") {
        reloadMethod();
      }
    });
  }

  if (requestPostCapacitacion === "SUCCESS") {
    handleClose();
    Swal.fire({
      title: "Capacitacion agregada",
      text: "Se ha agregado la capacitacion exitosamente",
      icon: "success",
    }).then((result) => {
      if (result.value || result.dismiss === "backdrop") {
        reloadMethod();
      }
    });
  }

  useEffect(() => {
    if (
      requestPutCapacitacion === "LOADING" ||
      requestPostCapacitacion === "LOADING"
    ) {
      setIsLoading(true);
    } else {
      setIsLoading(false);
    }
  }, [requestPostCapacitacion, requestPutCapacitacion]);

  const [open, setOpen] = useState(false);

  const handleCloseBackdrop = () => {
    setOpen(false);
  };

  return (
    <Modal
      disableScrollLock={false}
      open={isOpen}
      onClose={handleClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"   
      sx={{
        overflowY: "auto",
        top: isMobile ? "0px" : "10px",
        maxHeight: isMobile ? "100%" : "95%",
      }}
    >
      <Box sx={isMobile ? styleMobile : style}>
        {isLoading && (
          <Backdrop
            sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
            open={true}
            onClick={handleCloseBackdrop}
          >
            <CircularProgress color="inherit" />
          </Backdrop>
        )}
        <Grid
          container
          justifyContent="space-between"
          alignItems={"center"}
          sx={{ marginBottom: 2 }}
        >
          <Grid item xs={10} sm={10} md={10} lg={10} xl={10}>
            <h4>Agregar capacitacion</h4>
          </Grid>
          <Grid item>
            <IconButton
              color="primary"
              aria-label="copiar"
              component="span"
              onClick={() => handleClose()}
            >
              <Close sx={{ fontSize: "20pt" }} />
            </IconButton>
          </Grid>
        </Grid>
        <Formik
          initialValues={{
            titulo: values && values.titulo,
            descripcion: values && values.descripcion,
            categoria: values && values.categoria,
            link: values && values.link,
            idYoutube: values && values.idYoutube,
            duracionMinutos: values && values.duracionMinutos,
            duracionSegundos: values && values.duracionSegundos,
            orden: values && values.orden ? values.orden : 0,
          }}
          onSubmit={handleSubmit}
        >
          {({ values, handleSubmit }) => (
            <Form onSubmit={handleSubmit}>
              <Grid container>
                <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                  <label className="milabel">Titulo</label>
                  <Field name="titulo" type="text" className="miSelect" />
                </Grid>
              </Grid>
              <Grid container>
                <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                  <label className="milabel">Descripcion</label>
                  <Field name="descripcion" type="text" className="miSelect" />
                </Grid>
              </Grid>
              <Grid container>
                <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                  <label className="milabel">Link</label>
                  <Field name="link" type="text" className="miSelect" />
                </Grid>
              </Grid>
              <Grid container>
                <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                  <label className="milabel">Duracion</label>
                </Grid>
              </Grid>
              <Grid container spacing={1}>
                <Grid item xs={6} sm={6} md={6} lg={6} xl={6}>
                  <label className="milabel">Minutos</label>
                  <Field name="duracionMinutos" type="text" className="miSelect" />
                </Grid>
                <Grid item xs={6} sm={6} md={6} lg={6} xl={6}>
                  <label className="milabel">Segundos</label>
                  <Field name="duracionSegundos" type="text" className="miSelect" />
                </Grid>
              </Grid>
              <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                <label className="milabel">Categoria</label>
                <Field name="categoria" as="select" className="miSelect">
                  <option value={""}>- Seleccione -</option>
                  {Object.keys(CATEGORIAS_CAPACITACIONES).map((c) => (
                    <option value={c}>
                      {CATEGORIAS_CAPACITACIONES[c]}
                    </option>
                  ))}
                </Field>
              </Grid>

              <Grid container justifyContent="flex-end" sx={{ mt: 2 }}>
                <Grid item>
                  <Button onClick={handleClose} className="main-btn">
                    Cancelar
                  </Button>
                </Grid>
                <Grid item>
                  <Button
                    type="submit"
                    className="main-btn"
                    variant="contained"
                    color="success"
                  >
                    Guardar
                  </Button>
                </Grid>
              </Grid>
            </Form>
          )}
        </Formik>
      </Box>
    </Modal>
  );
};

export default ModalAgregarCapacitacion;
