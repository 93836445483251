import { Backdrop, Button, CircularProgress, Grid } from "@mui/material";
import { Formik, Form, Field } from "formik";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { postProspecto } from "../../../action";
import Swal from "sweetalert2";
import * as Yup from "yup";
import FieldError from "../../../utils/FieldError";

const validate = Yup.object().shape({
  nombres: Yup.string().required("Ingrese nombres"),
  apellidos: Yup.string().required("Ingrese apellidos"),
  rut: Yup.string().required("Ingrese rut"),
  email: Yup.string()
    .email("Ingrese un correo electrónico válido")
    .required("Ingrese correo electrónico"),
  telefono: Yup.string()
    .min(8, "Ingrese al menos 8 dígitos")
    .max(12, "Ingrese máximo 12 dígitos")
    .required("Ingrese teléfono"),
  tipoOperacion: Yup.string().required("Ingrese tipo operación"),
  tipoContratoProspecto: Yup.string().required(
    "Ingrese tipo persona prospecto"
  ),
  estadoProcesoProspecto: Yup.string().required(
    "Ingrese estado proceso prospecto"
  ),
});

const FormProspectoVendedor = ({
  handleClose,
  setTipoProspecto,
  reloadMethod,
}) => {
  const dispatch = useDispatch();
  const [isLoading, setIsLoading] = useState(false);
  const request = useSelector((state) => state.app.requestPostProspecto);
  const regiones = useSelector(
    (state) =>
      state.app.itemRegiones &&
      state.app.itemRegiones.length > 0 &&
      state.app.itemRegiones[0].regiones
  );

  const requestGetRegiones = useSelector(
    (state) => state.app.requestGetRegiones
  );
  const handleSubmit = (formData) => {
    dispatch(postProspecto("va", formData));
  };
  const [showBackdrop, setShowBackdrop] = useState(false);
  const handleShowBackdrop = () => {
    setShowBackdrop(false);
  };

  useEffect(() => {
    if (request === "LOADING") {
      setIsLoading(true);
    } else {
      setIsLoading(false);
    }
  }, [request]);

  if (request === "SUCCESS") {
    //handleClose();
    Swal.fire({
      title: "Prospecto agregado",
      text: "Se ha agregado el prospecto exitosamente",
      icon: "success",
    }).then((result) => {
      if (result.value || result.dismiss === "backdrop") {
        handleClose();
        reloadMethod();
      }
    });
  }

  return (
    <Grid container>
      <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
        {isLoading && (
          <Backdrop
            sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
            open={true}
            onClick={handleShowBackdrop}
          >
            <CircularProgress color="inherit" />
          </Backdrop>
        )}
        <Formik
          initialValues={{
            nombres: "",
            apellidos: "",
            rut: "",
            email: "",
            telefono: "",
            tipoPropiedad: "",
            tipoOperacion: "",
            tipoContratoProspecto: "",
            estadoProcesoProspecto: "",
            dormitorios: "0",
            banios: "0",
            idRegion: "15",
            comuna: "",
            calle: "",
            numero: "0",
            linkPropiedad: "",
          }}
          onSubmit={handleSubmit}
          validationSchema={validate}
        >
          {({ values, handleSubmit }) => (
            <Form onSubmit={handleSubmit}>
              <Grid container spacing={1} className="alignLeft" sx={{ mt: 1 }}>
                <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                  <h5>Datos personales</h5>
                </Grid>
              </Grid>
              <Grid container spacing={1}>
                <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                  <label className="milabel">Nombre</label>
                  <Field name="nombres" type="text" className="miSelect" />
                  <FieldError name="nombres" />
                </Grid>
                <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                  <label className="milabel">Apellidos</label>
                  <Field name="apellidos" type="text" className="miSelect" />
                  <FieldError name="apellidos" />
                </Grid>
              </Grid>
              <Grid container spacing={1}>
                <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                  <label className="milabel">Teléfono</label>
                  <Field name="telefono" type="text" className="miSelect" />
                  <FieldError name="telefono" />
                </Grid>
                <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                  <label className="milabel">Rut</label>
                  <Field name="rut" type="text" className="miSelect" />
                  <FieldError name="rut" />
                </Grid>
              </Grid>
              <Grid container spacing={1}>
                <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                  <label className="milabel">Correo electrónico</label>
                  <Field name="email" type="text" className="miSelect" />
                  <FieldError name="email" />
                </Grid>
              </Grid>
              <Grid container spacing={1}>
                <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                  <label className="milabel">Tipo persona</label>
                  <Field
                    name="tipoContratoProspecto"
                    as="select"
                    className="miSelect"
                  >
                    <option value={""}>- Seleccione -</option>
                    <option value={0}>Corredor/canje</option>
                    <option value={1}>Persona jurídica</option>
                    <option value={2}>Persona natural</option>
                  </Field>
                  <FieldError name="tipoContratoProspecto" />
                </Grid>
                <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                  <label className="milabel">Estado del proceso</label>
                  <Field
                    name="estadoProcesoProspecto"
                    as="select"
                    className="miSelect"
                  >
                    <option value={""}>- Seleccione -</option>
                    <option value={0}>1er contacto</option>
                    <option value={1}>2do contacto</option>
                    <option value={2}>3er contacto</option>
                    <option value={3}>4to contacto</option>
                    <option value={4}>Pausado</option>
                  </Field>
                  <FieldError name="estadoProcesoProspecto" />
                </Grid>
              </Grid>

              <Grid container spacing={1} className="alignLeft" sx={{ mt: 2 }}>
                <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                  <h5>Características</h5>
                </Grid>
              </Grid>
              <Grid container spacing={1}>
                <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                  <label className="milabel">Tipo propiedad</label>
                  <Field name="tipoPropiedad" as="select" className="miSelect">
                    <option value={""}>- Seleccione -</option>
                    <option value={"Departamento"}>Departamento</option>
                    <option value={"Casa"}>Casa</option>
                  </Field>
                </Grid>
                <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                  <label className="milabel">Operación</label>
                  <Field name="tipoOperacion" as="select" className="miSelect">
                    <option value={""}>- Seleccione -</option>
                    <option value={0}>Arriendo</option>
                    <option value={1}>Venta</option>
                  </Field>
                  <FieldError name="tipoOperacion" />
                </Grid>
              </Grid>
              <Grid container spacing={1}>
                <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                  <label className="milabel">Dormitorios</label>
                  <Field name="dormitorios" type="text" className="miSelect" />
                </Grid>
                <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                  <label className="milabel">Baños</label>
                  <Field name="banios" type="text" className="miSelect" />
                </Grid>
              </Grid>
              <Grid container>
                <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                  <label className="milabel">Link propiedad</label>
                  <Field
                    name="linkPropiedad"
                    type="text"
                    className="miSelect"
                  />
                </Grid>
              </Grid>
              <Grid container spacing={1} className="alignLeft" sx={{ mt: 2 }}>
                <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                  <h5>Ubicación</h5>
                </Grid>
              </Grid>
              {requestGetRegiones == "IDLE" && (
                <Grid container spacing={1}>
                  <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                    <label className="milabel">Región</label>
                    <Field name="idRegion" as="select" className="miSelect">
                      <option value={""}>- Seleccione -</option>
                      {regiones &&
                        regiones.map((r) => (
                          <option value={r.numero}>{r.region}</option>
                        ))}
                    </Field>
                  </Grid>
                  <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                    <label className="milabel">Comuna</label>
                    <Field name="comuna" as="select" className="miSelect">
                      <option value={""}>- Seleccione -</option>
                      {regiones &&
                        values &&
                        values.idRegion &&
                        regiones.some((u) => u.numero == values.idRegion) &&
                        regiones.find((u) => u.numero == values.idRegion).comunas &&
                        regiones.find((u) => u.numero == values.idRegion).comunas != null &&
                        regiones.find((u) => u.numero == values.idRegion).comunas != undefined &&
                        regiones.find((u) => u.numero == values.idRegion).comunas.map((c) => <option value={c}>{c}</option>)}
                    </Field>
                  </Grid>
                </Grid>
              )}
              {values && values.tipoPropiedad === "Departamento" ? (
                <Grid container spacing={1}>
                  <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                    <label className="milabel">Calle</label>
                    <Field name="calle" type="text" className="miSelect" />
                  </Grid>
                  <Grid item xs={6} sm={6} md={3} lg={3} xl={3}>
                    <label className="milabel">Número</label>
                    <Field name="numero" type="text" className="miSelect" />
                  </Grid>
                  <Grid item xs={6} sm={6} md={3} lg={3} xl={3}>
                    <label className="milabel">Nro. depto.</label>
                    <Field
                      name="numeroDepartamento"
                      type="text"
                      className="miSelect"
                    />
                  </Grid>
                </Grid>
              ) : (
                <Grid container spacing={1}>
                  <Grid item xs={12} sm={12} md={8} lg={8} xl={8}>
                    <label className="milabel">Calle</label>
                    <Field name="calle" type="text" className="miSelect" />
                  </Grid>
                  <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
                    <label className="milabel">Número</label>
                    <Field name="numero" type="text" className="miSelect" />
                  </Grid>
                </Grid>
              )}

              <Grid container justifyContent="flex-end" sx={{ mt: 2 }}>
                <Grid item>
                  <Button
                    onClick={() => setTipoProspecto("")}
                    className="alignLeft main-btn"
                  >
                    Atrás
                  </Button>
                </Grid>
                <Grid item>
                  <Button onClick={handleClose} className="main-btn">
                    Cancelar
                  </Button>
                </Grid>
                <Grid item>
                  <Button
                    type="submit"
                    className="main-btn"
                    variant="contained"
                    color="success"
                  >
                    Guardar
                  </Button>
                </Grid>
              </Grid>
            </Form>
          )}
        </Formik>
      </Grid>
    </Grid>
  );
};

export default FormProspectoVendedor;
