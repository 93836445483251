import { useHistory } from "react-router-dom";
import { BottomNavigation, BottomNavigationAction, Paper } from "@mui/material";
import {
  Assignment,
  Home,
} from "@mui/icons-material";

const PropietariodNavegacionMobile = ({ id }) => {
  const history = useHistory();
  return (
    <Paper
      sx={{ position: "fixed", bottom: 0, left: 0, right: 0, zIndex: 1 }}
      elevation={3}
    >
      <BottomNavigation
        showLabels
        //value={value}
        onChange={(event, newValue) => {
          switch (newValue) {
            case 1:
              history.push(`/propietarios/actividades?id=${id}`);
              break;
            default:
              history.push(`/propietarios/propiedades?id=${id}`);
              break;
          }
        }}
      >
        <BottomNavigationAction label="Propiedades" icon={<Home sx={{color: window.location.pathname.includes("ropiedades") ? "#00a72c" : "inherit"}} />} />
        <BottomNavigationAction label="Actividades" icon={<Assignment sx={{color: window.location.pathname.includes("ctividades") ? "#00a72c" : "inherit"}} />} />
      </BottomNavigation>
    </Paper>
  );
};

export default PropietariodNavegacionMobile;
