import { useHistory } from "react-router-dom";
import { BottomNavigation, BottomNavigationAction, Paper } from "@mui/material";
import {
  AddBusiness,
  PriceChange,
} from "@mui/icons-material";

const ProspectoNavegacionMobile = () => {
  const history = useHistory();
  return (
    <Paper
      sx={{ position: "fixed", bottom: 0, left: 0, right: 0, zIndex: 30 }}
      elevation={3}
    >
      <BottomNavigation
        showLabels
        //value={value}
        onChange={(event, newValue) => {
          switch (newValue) {
            case 1:
              history.push(`/prospectos/comprador`);
              break;
            default:
              history.push(`/prospectos/vendedor`);
              break;
          }
        }}
      >
        <BottomNavigationAction label="Vendedor" icon={<PriceChange sx={{color: window.location.pathname.includes("vendedor") ? "#00a72c" : "inherit"}} />} />
        <BottomNavigationAction label="Comprador" icon={<AddBusiness sx={{color: window.location.pathname.includes("comprador") ? "#00a72c" : "inherit"}} />} />
      </BottomNavigation>
    </Paper>
  );
};

export default ProspectoNavegacionMobile;
