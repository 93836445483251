import * as React from "react";
import Tooltip from "@mui/material/Tooltip";
import IconButton from "@mui/material/IconButton";
import TableCell from "@mui/material/TableCell";
import TableRow from "@mui/material/TableRow";
import EditIcon from "@mui/icons-material/Edit";
import { useState } from "react";
import { Menu, MenuItem } from "@mui/material";
import { alpha, styled } from "@mui/material/styles";
import {
  ArtTrack,
  Cancel,
  CheckCircle,
  MoreVert,
} from "@mui/icons-material";
import { useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

const StyledMenu = styled((props) => (
  <Menu
    elevation={0}
    anchorOrigin={{
      vertical: "bottom",
      horizontal: "right",
    }}
    transformOrigin={{
      vertical: "top",
      horizontal: "right",
    }}
    {...props}
  />
))(({ theme }) => ({
  "& .MuiPaper-root": {
    borderRadius: 6,
    marginTop: theme.spacing(1),
    minWidth: 180,
    color:
      theme.palette.mode === "light"
        ? "rgb(55, 65, 81)"
        : theme.palette.grey[300],
    boxShadow:
      "rgb(255, 255, 255) 0px 0px 0px 0px, rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px",
    "& .MuiMenu-list": {
      padding: "4px 0",
    },
    "& .MuiMenuItem-root": {
      "& .MuiSvgIcon-root": {
        fontSize: 18,
        color: theme.palette.text.secondary,
        marginRight: theme.spacing(1.5),
      },
      "&:active": {
        backgroundColor: alpha(
          theme.palette.primary.main,
          theme.palette.action.selectedOpacity
        ),
      },
    },
  },
}));

export const FilaProyecto = ({
  proyecto,
  i,
  isMobile,
  showConfirmationDialog
}) => {
  const dispatch = useDispatch();
  const [anchorEl, setAnchorEl] = useState(null);
  const openMenu = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const history = useHistory();
  const editar = (id) => history.push(`/proyectos/${id}`);
  const userData = useSelector((state) => state.app.itemUsuario);
  
  if (isMobile) {
    return (
      <React.Fragment>
        <TableRow
          className={i % 2 === 0 ? "filaOscura" : ""}
          sx={{ "& > *": { borderBottom: "unset" } }}
        >
          <TableCell align="center">{proyecto.nombre}</TableCell>
          <TableCell align="center">{proyecto.nombreInmobiliaria}</TableCell>

          <TableCell>
            <IconButton
              onClick={handleClick}
              //color="danger"
              aria-label="acciones"
              component="span"
            >
              <MoreVert />
            </IconButton>
            <StyledMenu
              id="demo-customized-menu"
              MenuListProps={{
                "aria-labelledby": "demo-customized-button",
              }}
              anchorEl={anchorEl}
              open={openMenu}
              onClose={handleClose}
            >
              <MenuItem disableRipple onClick={() => editar(proyecto.id)}>
                <EditIcon />
                Editar
              </MenuItem>
              {userData.tipoCuenta !== 10 &&
                (proyecto.tarjetaProyectoHabilitada ? (
                  <MenuItem
                    disableRipple
                    onClick={(e) => showConfirmationDialog(proyecto, true)}
                  >
                    <ArtTrack sx={{ color: "#43a047 !important" }}  />
                    Deshabilitar tarjeta
                  </MenuItem>
                ) : (
                  <MenuItem
                    disableRipple
                    onClick={(e) => showConfirmationDialog(proyecto, false)}
                  >
                    <ArtTrack />
                    Habilitar tarjeta
                  </MenuItem>
                ))}
            </StyledMenu>
          </TableCell>
        </TableRow>
      </React.Fragment>
    );
  } else {
    return (
      <React.Fragment>
        <TableRow className={i % 2 === 0 ? "filaOscura" : ""}>
          <TableCell align="center">{proyecto.nombre}</TableCell>
          <TableCell align="center">{proyecto.nombreInmobiliaria}</TableCell>
          <TableCell align="center">{proyecto.valorDesde}</TableCell>
          <TableCell align="center">
            {proyecto.destacado ? <CheckCircle /> : <Cancel />}
          </TableCell>
          <TableCell align="center">{proyecto.tipoProyecto}</TableCell>
          <TableCell align="center">
            {proyecto.comuna}
            {proyecto.modelos && proyecto.modelos.length}
          </TableCell>
          <TableCell align="center">
            {proyecto.nombreCalle} {proyecto.numero}
          </TableCell>
          <TableCell align="center">
            <Tooltip title="Editar" aria-label="editar">
              <IconButton
                //color="primary"
                aria-label="Editar"
                component="span"
                onClick={() => editar(proyecto.id)}
              >
                <EditIcon />
              </IconButton>
            </Tooltip>
            {userData.tipoCuenta !== 10 &&
              (proyecto.tarjetaProyectoHabilitada ? (
                <Tooltip
                  title="Deshabilitar tarjeta proyecto"
                  aria-label="deshabilitar tarjeta prop"
                >
                  <IconButton
                    className="iconbutton-deshabilitado"
                    //color="danger"
                    aria-label="Deshabilitar tarjeta prop"
                    component="span"
                    onClick={(e) => showConfirmationDialog(proyecto, true)}
                  >
                    <ArtTrack sx={{ color: "#43a047 !important" }}  />
                  </IconButton>
                </Tooltip>
              ) : (
                <Tooltip
                  title="Generar tarjeta proyecto"
                  aria-label="generar tarjeta prop"
                >
                  <IconButton
                    className="iconbutton-habilitado"
                    //color="primary"
                    aria-label="Generar tarjeta prop"
                    component="span"
                    onClick={(e) => showConfirmationDialog(proyecto, false)}
                  >
                    <ArtTrack />
                  </IconButton>
                </Tooltip>
              ))}
          </TableCell>
        </TableRow>
      </React.Fragment>
    );
  }
};
