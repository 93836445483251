import { PhotoSizeSelectActual } from "@mui/icons-material";
import { CircularProgress } from "@mui/material";
import { useEffect } from "react";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getGraficas, getUsuariosFiltrados } from "../../action";
import FormAgregarGrafica from "./FormAgregarGrafica";
import "./Difusion.css";

const Difusion = () => {
  const dispatch = useDispatch();
  const userData = useSelector((state) => state.app.itemUsuario);
  const [activo, setActivo] = useState("graficas");
  const [openModalGrafica, setOpenModalGrafica] = useState(false);
  const handleCloseModalGrafica = () => setOpenModalGrafica(false);
  const graficas = useSelector(
    (state) => state.app.graficas && state.app.graficas.results
  );
  const requestGetGraficas = useSelector(
    (state) => state.app.requestGetGraficas
  );
  const [isLoading, setIsLoading] = useState(false);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };
  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  useEffect(() => {
    dispatch(getGraficas(page, rowsPerPage));
  }, [page, rowsPerPage]);

  useEffect(() => {
    if (requestGetGraficas === "LOADING") {
      setIsLoading(true);
    } else {
      setIsLoading(false);
    }
  }, [requestGetGraficas]);

  useEffect(() => {
    dispatch(getUsuariosFiltrados(10));
  }, []);

  const getClassName = (name) => {
    if (name !== null && name !== "") {
      if (name.includes("roker")) {
        return "tarjeta-presentacion";
      }
      if (name.includes("ropiedad")) {
        return "tarjeta-prop";
      }
      if (name.includes("enta")) {
        return "planes-venta";
      }
      if (name.includes("rriendo")) {
        return "planes-arriendo";
      }
    } else {
      return "";
    }
  };

  return (
    <div className="contenido">
      <div className="headerDifusion">
        <h2>Difusión</h2>
        {userData.tipoCuenta !== 10 && (
          <button className="boton" onClick={() => setOpenModalGrafica(true)}>
            Agregar +
          </button>
        )}
        <p className="descRecursos">
          Material descargable para compartir con tus clientes
        </p>
      </div>
      <div className="opciones" style={{ marginTop: "30px" }}>
        <h3
          id={activo === "graficas" ? "active1" : "notActive1"}
          onClick={() => {
            setActivo("graficas");
          }}
        >
          Gráficas
        </h3>
        {/*
        <h3
          id={activo === "landing" ? "active1" : "notActive1"}
          onClick={() => {
            setActivo("landing");
          }}
        >
          Landing pages
        </h3>
        <h3
          id={activo === "carteles" ? "active1" : "notActive1"}
          onClick={() => {
            setActivo("carteles");
          }}
        >
          Carteles
        </h3>
        <h3
          id={activo === "otros" ? "active1" : "notActive1"}
          onClick={() => {
            setActivo("otros");
          }}
        >
          Otros
        </h3>
        */}
      </div>
      <div className="main">
        {isLoading && <CircularProgress />}
        {requestGetGraficas === "IDLE" && !isLoading && graficas && (
          <div className="otros">
            {graficas.length > 0 &&
              graficas.map((g) => (
                <a
                  className="link"
                  target="_blank"
                  href={g.link}
                  rel="noreferrer"
                >
                  <div className="cursor-pointer">
                    <div
                      className={`difusion thumbnail-difusion thumbnail-${getClassName(g.nombre)}`}
                    >
                      <div className="titulo-difusion">
                        <PhotoSizeSelectActual
                          sx={{
                            margin: "0.3rem",
                            fontSize: "20pt",
                            color: "#00a62b",
                          }}
                        />
                        {g.nombre}
                      </div>
                    </div>
                  </div>
                </a>
              ))}
          </div>
        )}
      </div>
      <FormAgregarGrafica
        handleClose={handleCloseModalGrafica}
        isOpen={openModalGrafica}
      />
    </div>
  );
};

export default Difusion;
