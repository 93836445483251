import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { fetchAllRegiones } from "../../action";
import TablaPropiedades from "./TablaPropiedades";

const ListMisPropiedades = () => {
  const dispatch = useDispatch();
  const userData = useSelector((state) => state.app.itemUsuario);
  const idBroker = userData && userData.tipoCuenta === 10 ? userData.userId : "";
  useEffect(() => {
    dispatch(fetchAllRegiones());
  }, []);
  return (
    <div className="contenido">
      <div className="header">
        <h2>Propiedades</h2>
      </div>      
      <TablaPropiedades idCliente={""} idBroker={idBroker} incluirFiltro={true} incluirBotonCrearPropiedad={true} inluirSelectClientes={true} />
    </div>
  );
};

export default ListMisPropiedades;
