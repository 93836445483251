import * as React from "react";
import Tooltip from "@mui/material/Tooltip";
import IconButton from "@mui/material/IconButton";
import TableCell from "@mui/material/TableCell";
import TableRow from "@mui/material/TableRow";
import moment from "moment";
import { useState } from "react";
import { Menu, MenuItem } from "@mui/material";
import { alpha, styled } from "@mui/material/styles";
import {
  Done,
  Download,
  FindInPage,
  MoreVert,
  NotInterested,
  Timelapse,
} from "@mui/icons-material";
import { TIPO_CONTRATO } from "../../utils/constants";
import { useSelector } from "react-redux";

const isMobile =
  !!navigator.userAgent.match(/iphone|android|blackberry/gi) || false;

const StyledMenu = styled((props) => (
  <Menu
    elevation={0}
    anchorOrigin={{
      vertical: "bottom",
      horizontal: "right",
    }}
    transformOrigin={{
      vertical: "top",
      horizontal: "right",
    }}
    {...props}
  />
))(({ theme }) => ({
  "& .MuiPaper-root": {
    borderRadius: 6,
    marginTop: theme.spacing(1),
    minWidth: 180,
    color:
      theme.palette.mode === "light"
        ? "rgb(55, 65, 81)"
        : theme.palette.grey[300],
    boxShadow:
      "rgb(255, 255, 255) 0px 0px 0px 0px, rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px",
    "& .MuiMenu-list": {
      padding: "4px 0",
    },
    "& .MuiMenuItem-root": {
      "& .MuiSvgIcon-root": {
        fontSize: 18,
        color: theme.palette.text.secondary,
        marginRight: theme.spacing(1.5),
      },
      "&:active": {
        backgroundColor: alpha(
          theme.palette.primary.main,
          theme.palette.action.selectedOpacity
        ),
      },
    },
  },
}));

export const FilaDocumento = ({
  portfolio,
  i,
  handleOpenProcessDetailModal,
}) => {
  const usuarios = useSelector((state) => state.app.itemsUsuarios);
  const propiedades = useSelector(
    (state) =>
      state.app.propiedadesPaginadas && state.app.propiedadesPaginadas.results
  );
  const [anchorEl, setAnchorEl] = useState(null);
  const openMenu = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const fechaCreacion = moment(portfolio.createdAt.split("T")[0]);
  let urlDocumentoFirmado = "";
  const documento =
    portfolio.documentos !== null &&
    Array.isArray(portfolio.documentos) &&
    portfolio.documentos.length > 0
      ? portfolio.documentos[0]
      : null;

  if (documento !== null) {
    urlDocumentoFirmado =
      documento.urlDocumentoContrato != null &&
      documento.urlDocumentoContrato != undefined &&
      documento.urlDocumentoContrato != ""
        ? documento.urlDocumentoContrato
        : documento.urlDocumentoContratoGoFirmex;
  }
  let nombreSubidoPor = "";
  const userSubidoPor =
    usuarios.some((u) => u.id === portfolio.subidoPor) &&
    usuarios.find((u) => u.id === portfolio.subidoPor);
  if (userSubidoPor) {
    nombreSubidoPor = `${userSubidoPor.nombres} ${userSubidoPor.apellidos}`;
  }

  const getDireccionPropiedad = (idPropiedad) => {
    let propiedad =
      propiedades && propiedades.filter((items) => items.id === idPropiedad);
    if (propiedad !== null && propiedad.length > 0) {
      propiedad = propiedad[0];
      if (
        propiedad.tipoPropiedad === "Casa" &&
        propiedad.nombreCalle === null
      ) {
        return `${propiedad.direccionReferencial} ${propiedad.numero}, ${propiedad.comuna}`;
      }

      if (
        propiedad.tipoPropiedad === "Casa" &&
        propiedad.nombreCalle !== null
      ) {
        return `${propiedad.nombreCalle} ${propiedad.numero}, ${propiedad.comuna}`;
      }

      if (
        propiedad.tipoPropiedad !== "Casa" &&
        propiedad.nombreCalle === null
      ) {
        return `${propiedad.direccionReferencial} ${propiedad.numero} departamento ${propiedad.numeroDepartamento}, ${propiedad.comuna}`;
      }
      if (
        propiedad.tipoPropiedad !== "Casa" &&
        propiedad.nombreCalle !== null
      ) {
        return `${propiedad.nombreCalle} ${propiedad.numero} departamento ${propiedad.numeroDepartamento}, ${propiedad.comuna}`;
      }
    }
  };

  if (isMobile) {
    return (
      <React.Fragment>
        <TableRow
          className={i % 2 === 0 ? "filaOscura" : ""}
          sx={{ "& > *": { borderBottom: "unset" } }}
        >
          <TableCell>
            {TIPO_CONTRATO[portfolio.tipoDocumento]} n° {portfolio.numero}
          </TableCell>
          <TableCell align="center">
            {portfolio.soloPlantilla ? "Sólo plantilla " : "Documento real "}
          </TableCell>
          <TableCell align="center">
            {moment(portfolio.createdAt).format("DD-MM-YYYY")}
          </TableCell>
          <TableCell>
            {(!portfolio.soloPlantilla || portfolio.portafolioCompleto) && (
              <>
                <IconButton
                  onClick={handleClick}
                  //color="danger"
                  aria-label="acciones"
                  component="span"
                >
                  <MoreVert />
                </IconButton>
                <StyledMenu
                  id="demo-customized-menu"
                  MenuListProps={{
                    "aria-labelledby": "demo-customized-button",
                  }}
                  anchorEl={anchorEl}
                  open={openMenu}
                  onClose={handleClose}
                >
                  {!portfolio.soloPlantilla && (
                    <MenuItem
                      disableRipple
                      onClick={() => handleOpenProcessDetailModal(portfolio)}
                    >
                      <FindInPage />
                      Ver detalle proceso
                    </MenuItem>
                  )}

                  {portfolio.portafolioCompleto && (
                    <a
                      href={urlDocumentoFirmado}
                      target="_blank"
                      rel="noreferrer"
                    >
                      <MenuItem disableRipple>
                        <Download />
                        Descargar documento firmado
                      </MenuItem>
                    </a>
                  )}
                </StyledMenu>
              </>
            )}
          </TableCell>
        </TableRow>
      </React.Fragment>
    );
  } else {
    return (
      <React.Fragment>
        <TableRow className={i % 2 === 0 ? "filaOscura" : ""}>
          <TableCell>
            {TIPO_CONTRATO[portfolio.tipoDocumento]} n° {portfolio.numero}
          </TableCell>
          <TableCell align="center">
            {portfolio.soloPlantilla ? "Sólo plantilla " : "Documento real "}
          </TableCell>
          <TableCell align="center">
            {TIPO_CONTRATO[portfolio.tipoDocumento]} para propiedad{" "}
            {getDireccionPropiedad(portfolio.idPropiedad)}
          </TableCell>
          <TableCell align="center">
            {moment(portfolio.createdAt).format("DD-MM-YYYY")}
          </TableCell>
          <TableCell align="center">
            {portfolio.soloPlantilla ? "Descargado por " : "Generado por "}
            {nombreSubidoPor}
          </TableCell>
          <TableCell align="center">
            {portfolio.soloPlantilla ? (
              <Tooltip
                title="No aplica para plantillas descargadas"
                aria-label="Plantilla"
              >
                <IconButton
                  color="primary"
                  aria-label="Plantilla"
                  component="span"
                >
                  <NotInterested />
                </IconButton>
              </Tooltip>
            ) : portfolio.portafolioCompleto ? (
              <Tooltip
                title="Proceso finalizado"
                aria-label="Formulario completado"
              >
                <IconButton
                  color="primary"
                  aria-label="Cambiar estado"
                  component="span"
                >
                  <Done />
                </IconButton>
              </Tooltip>
            ) : (
              <Tooltip
                title="Portafolio sigue en proceso"
                aria-label="Formulario completado"
              >
                <IconButton
                  color="primary"
                  aria-label="Cambiar estado"
                  component="span"
                >
                  <Timelapse />
                </IconButton>
              </Tooltip>
            )}
          </TableCell>
          <TableCell align="center">
            {!portfolio.soloPlantilla && (
              <React.Fragment>
                <Tooltip title="Ver detalle proceso" aria-label="ver proceso">
                  <IconButton
                    color="primary"
                    aria-label="Ver proceso"
                    component="span"
                    onClick={() => handleOpenProcessDetailModal(portfolio)}
                  >
                    <FindInPage />
                  </IconButton>
                </Tooltip>
                {/* false && !portfolio.portafolioCreado && (
                  <Tooltip title="Reiniciar" aria-label="reiniciar">
                    <IconButton
                      color="primary"
                      aria-label="Reiniciar"
                      component="span"
                      onClick={() => reiniciarFirmaDigital(portfolio.id)}
                    >
                      <Replay />
                    </IconButton>
                  </Tooltip>
                ) */}
                {portfolio.portafolioCompleto && (
                  <a
                    href={urlDocumentoFirmado}
                    target="_blank"
                    rel="noreferrer"
                  >
                    <Tooltip
                      title="Descargar documento firmado"
                      aria-label="ver proceso"
                    >
                      <IconButton
                        color="primary"
                        aria-label="Ver proceso"
                        component="span"
                      >
                        <Download />
                      </IconButton>
                    </Tooltip>
                  </a>
                )}
              </React.Fragment>
            )}
          </TableCell>
        </TableRow>
      </React.Fragment>
    );
  }
};
