import { Backdrop, Box, Button, CircularProgress, Grid, IconButton, Modal } from "@mui/material";
import { Formik, Form, Field } from "formik";
import { useEffect } from "react";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { putPropiedadProspectoVA } from "../../../action";
import { useLocation } from "react-router-dom";
import { Close } from "@mui/icons-material";
import * as Yup from "yup";
import FieldError from "../../../utils/FieldError";

const validate = Yup.object().shape({
  tipoPropiedad: Yup.string().required("Ingrese tipo de propiedad"),
  tipoOperacion: Yup.string().required("Ingrese tipo de operación"),
  dormitorios: Yup.number()
    .typeError("Ingrese un valor numérico")
    .min(0, "Ingrese valor mayor a 0")
    .required("Ingrese dormitorios"),
  banios: Yup.number()
    .typeError("Ingrese un valor numérico")
    .min(0, "Ingrese valor mayor a 0")
    .required("Ingrese baños"),
  idRegion: Yup.number().required("Ingrese región"),
  comuna: Yup.string().required("Ingrese comuna"),
  calle: Yup.string().required("Ingrese calle"),
  numero: Yup.number()
    .typeError("Ingrese un valor numérico, sin puntos ni comas")
    .required("Ingrese número"),
    /*
  numeroDepartamento: Yup.string()
    .when("tipoPropiedad", {
      is: "Departamento", // alternatively: (val) => val === true
      then: (schema) => schema.min(1),
      otherwise: (schema) => schema.notRequired(),
    })
    .required("Ingrese numero departamento"),
    */
});

const isMobile =
    !!navigator.userAgent.match(/iphone|android|blackberry/gi) || false;

const style = {
  position: "absolute",
  top: "0px",
  left: "50%",
  transform: "translate(-50%, 0%)",
  width: 500,
  bgcolor: "background.paper",
  boxShadow: 24,
  p: 4,
  //maxHeight: '90%'
};

const styleMobile = {
  position: "absolute",
  top: "0px",
  left: "0px",
  //transform: "translate(-50%, 0%)",
  width: "100vw",
  bgcolor: "background.paper",
  boxShadow: 24,
  p: 4,
  //maxHeight: '90%'
};

const FormPropiedadProspecto = ({ isOpen, handleClose, reloadMethod, values }) => {
  const dispatch = useDispatch();
  let { search } = useLocation();
  let query = new URLSearchParams(search);
  let perfil = query.get("id");
  const [isLoading, setIsLoading] = useState(false);
  const request = useSelector((state) => state.app.requestUpdateProspecto);
  const regiones = useSelector(
    (state) =>
      state.app.itemRegiones &&
      state.app.itemRegiones.length > 0 &&
      state.app.itemRegiones[0].regiones
  );
  const handleSubmit = (formData) => {
    dispatch(putPropiedadProspectoVA(perfil, formData));
  };
  const [showBackdrop, setShowBackdrop] = useState(false);
  const handleShowBackdrop = () => {
    setShowBackdrop(false);
  };

  useEffect(() => {
    if (request === "LOADING") {
      setIsLoading(true);
    } else {
      setIsLoading(false);
    }
  }, [request]);

  return (
    <Modal
      disableScrollLock={false}
      open={isOpen}
      onClose={handleClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"   
      sx={{
        overflowY: "auto",
        top: isMobile ? "0px" : "10px",
        maxHeight: isMobile ? "100%" : "95%",
      }}
    >
      <Box sx={isMobile ? styleMobile : style}>
        <Grid container justifyContent="space-between" alignItems={"center"} sx={{marginBottom: 2}}>
          <Grid item xs={10} sm={10} md={10} lg={10} xl={10}>
            <h4>Editar propiedad del prospecto</h4>
          </Grid>
          <Grid item>
            <IconButton
              color="primary"
              aria-label="copiar"
              component="span"
              onClick={() => handleClose()}
            >
              <Close sx={{ fontSize: "20pt" }} />
            </IconButton>
          </Grid>
        </Grid>
        <Grid container>
          <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
            {isLoading && (
              <Backdrop
                sx={{
                  color: "#fff",
                  zIndex: (theme) => theme.zIndex.drawer + 1,
                }}
                open={true}
                onClick={handleShowBackdrop}
              >
                <CircularProgress color="inherit" />
              </Backdrop>
            )}
            <Formik
              initialValues={{
                tipoPropiedad: values.tipoPropiedad,
                tipoOperacion: values.tipoOperacion,
                dormitorios: values.dormitorios,
                banios: values.banios,
                idRegion: values.idRegion,
                comuna: values.comuna,
                calle: values.calle,
                numero: values.numero,
                numeroDepartamento: values.numeroDepartamento,
                linkPropiedad: values.linkPropiedad,
              }}
              onSubmit={handleSubmit}
              validationSchema={validate}
            >
              {({ values, handleSubmit }) => (
                <Form onSubmit={handleSubmit}>
                  <Grid
                    container
                    className="alignLeft"
                  >
                    <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                      <h5>Caracteristicas</h5>
                    </Grid>
                  </Grid>
                  <Grid container spacing={1}>
                    <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                      <label className="milabel">Tipo propiedad</label>
                      <Field
                        name="tipoPropiedad"
                        as="select"
                        className="miSelect"
                      >
                        <option value={""}>- Seleccione -</option>
                        <option value={"Departamento"}>Departamento</option>
                        <option value={"Casa"}>Casa</option>
                      </Field>
                      <FieldError name="tipoPropiedad" />
                    </Grid>
                    <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                      <label className="milabel">Operación</label>
                      <Field
                        name="tipoOperacion"
                        as="select"
                        className="miSelect"
                      >
                        <option value={""}>- Seleccione -</option>
                        <option value={0}>Arriendo</option>
                        <option value={1}>Venta</option>
                      </Field>
                      <FieldError name="tipoOperacion" />
                    </Grid>
                  </Grid>
                  <Grid container spacing={1}>
                    <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                      <label className="milabel">Dormitorios</label>
                      <Field name="dormitorios" type="text" className="miSelect" />
                      <FieldError name="dormitorios" />
                    </Grid>
                    <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                      <label className="milabel">Baños</label>
                      <Field name="banios" type="text" className="miSelect" />
                      <FieldError name="banios" />
                    </Grid>
                  </Grid>
                  <Grid container>
                    <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                      <label className="milabel">Link propiedad</label>
                      <Field
                        name="linkPropiedad"
                        type="text"
                        className="miSelect"
                      />
                    </Grid>
                  </Grid>
                  <Grid
                    container
                    spacing={1}
                    className="alignLeft"
                    sx={{ mt: 2 }}
                  >
                    <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                      <h5>Ubicación</h5>
                    </Grid>
                  </Grid>
                  <Grid container spacing={1}>
                    <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                      <label className="milabel">Región</label>
                      <Field name="idRegion" as="select" className="miSelect">
                        <option value={""}>- Seleccione -</option>
                        {regiones &&
                          regiones.map((r) => (
                            <option value={r.numero}>{r.region}</option>
                          ))}
                      </Field>
                      <FieldError name="idRegion" />
                    </Grid>
                    <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                      <label className="milabel">Comuna</label>
                      <Field name="comuna" as="select" className="miSelect">
                        <option value={""}>- Seleccione -</option>
                        {regiones &&
                          values &&
                          values.idRegion &&
                          regiones
                            .find((u) => u.numero === values.idRegion)
                            .comunas.map((c) => <option value={c}>{c}</option>)}
                      </Field>
                      <FieldError name="comuna" />
                    </Grid>
                  </Grid>

                  {values && values.tipoPropiedad === "Departamento" ? (
                <Grid container spacing={1}>
                  <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                    <label className="milabel">Calle</label>
                    <Field
                      name="calle"
                      type="text"
                      className="miSelect"
                    />
                    <FieldError name="calle" />
                  </Grid>
                  <Grid item xs={6} sm={6} md={3} lg={3} xl={3}>
                    <label className="milabel">Número</label>
                    <Field name="numero" type="text" className="miSelect" />
                      <FieldError name="numero" />
                  </Grid>
                  <Grid item xs={6} sm={6} md={3} lg={3} xl={3}>
                    <label className="milabel">Nro. depto.</label>
                    <Field
                      name="numeroDepartamento"
                      type="text"
                      className="miSelect"
                    />
                  </Grid>
                </Grid>
              ) : (
                  <Grid container spacing={1}>
                    <Grid item xs={12} sm={12} md={8} lg={8} xl={8}>
                      <label className="milabel">Calle</label>
                      <Field name="calle" type="text" className="miSelect" />
                      <FieldError name="calle" />
                    </Grid>
                    <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
                      <label className="milabel">Número</label>
                      <Field name="numero" type="text" className="miSelect" />
                      <FieldError name="numero" />
                    </Grid>
                  </Grid>
              )}

                  <Grid container justifyContent="flex-end" sx={{ mt: 2 }}>
                    <Grid item>
                      <Button onClick={handleClose} className="main-btn">
                        Cancelar
                      </Button>
                    </Grid>
                    <Grid item>
                      <Button
                        type="submit"
                        className="main-btn"
                        variant="contained"
                        color="success"
                      >
                        Guardar
                      </Button>
                    </Grid>
                  </Grid>
                </Form>
              )}
            </Formik>
          </Grid>
        </Grid>
      </Box>
    </Modal>
  );
};

export default FormPropiedadProspecto;
