import { Box, Tooltip } from "@mui/material";
import "./CeldaCalendario.css";
import { red } from "@mui/material/colors";

const CeldaCalendario = (props) => {
  //console.log(props);
  const { title, event } = props;
  const { tipo } = event;
  return (
    <Tooltip title={title} aria-label="habilitar">
      <Box className={tipo === "visita" ? "celda-visita" : "celda-reu"} component="span" sx={{padding: 1}}>{title}</Box>
    </Tooltip>
  );
};

export default CeldaCalendario;
