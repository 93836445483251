//TODO: Cambiar nombre archivo generado
//TODO: implementar validacion
import { useDispatch, useSelector } from "react-redux";
import {
  Backdrop,
  Box,
  CircularProgress,
  Modal,
} from "@mui/material";
import { useEffect, useState } from "react";
import {
  getPropiedadById,
  getCliente,
  getProspectos,
  setPreviewDocument,
  crearPortafolio,
} from "../../action";
import Swal from "sweetalert2";
import {
  FormGenerarOrdenVisita,
} from "./FormGenerarOrdenVisita";
import { FormGenerarOrdenVenta } from "./FormGenerarOrdenVenta";
import { FormGenerarOrdenArriendo } from "./FormGenerarOrdenArriendo";
import { FormGenerarOrdenCanje } from "./FormGenerarOrdenCanje";
import { FormGenerarActaEntrega } from "./FormGenerarActaEntrega";
import { FormGenerarInventarioInmueble } from "./FormGenerarInventarioInmueble";
import { FormGenerarCartaOferta } from "./FormGenerarCartaOferta";
import { FormGenerarAceptacionCartaOferta } from "./FormGenerarAceptacionCartaOferta";

const regexpTelefono = new RegExp(/^\+?569 ?[0-9]{8}$/);
const regexpRut = new RegExp(/^[0-9]+-[0-9kK]{1}$/);
const regexpEmail = new RegExp(
  /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/
);

const isMobile =
  !!navigator.userAgent.match(/iphone|android|blackberry/gi) || false;

const style = {
  position: "absolute",
  top: "0px",
  left: "50%",
  transform: "translate(-50%, 0%)",
  width: 700,
  bgcolor: "background.paper",
  boxShadow: 24,
  p: 4,
};

const styleMobile = {
  position: "absolute",
  top: "0px",
  left: "0px",
  //transform: "translate(0%, -50%)",
  width: "100vw",
  bgcolor: "background.paper",
  boxShadow: 24,
  p: 4,
};

const ModalGenerarDocumento = ({
  tipoDocumento,
  isOpen,
  handleClose,
  reloadMethod,
  title,
}) => {
  const dispatch = useDispatch();
  const [usarDocumentosAlvaro, setUsarDocumentosAlvaro] = useState(true);
  const onChangeUsarDocumentosAlvaro = (e) => setUsarDocumentosAlvaro(e.target.checked)
  const [isLoading, setIsLoading] = useState(false);
  const [idPropiedad, setIdPropiedad] = useState("");
  const [idProspecto, setIdProspecto] = useState("");
  const [prospecto, setProspecto] = useState(null);
  const [fechaVisita, setFechaVisita] = useState(null);
  const [horaVisita, setHoraVisita] = useState("");
  const onChangeHoraVisita = (e) => setHoraVisita(e.target.value);
  const [firmante, setFirmante] = useState(null);
  const datosAlvaro = {
    rut: "15385044-5",
    nombres: "Alvaro",
    apellidos: "Devescovi",
    email: "alvaro@propins.cl",
    telefono: `+56978542618`,
  };
  const [validationMessages, setValidationMessages] = useState([]);
  const onChangeIdProspecto = (e) => setIdProspecto(e.target.value);
  const onChangePropiedad = (e) => setIdPropiedad(e.target.value);

  useEffect(() => {
    setValidationMessages([]);
    setFirmante(null);
    //orden de venta
    if (tipoDocumento === 8) {

    }
    //orden de visita
    if (tipoDocumento !== 9) {
      setFechaVisita(null);
      setHoraVisita("");
      setProspecto(null);
      setIdProspecto("")
    }
  }, [tipoDocumento])

  const formatTelefono = (telefono) => {
    if (telefono !== null && telefono !== undefined) {
      telefono = telefono.trim();
      if (telefono.length === 8) {
        telefono = `+569${telefono}`;
      } else if (telefono.length === 9) {
        telefono = `+56${telefono}`;
      } else if (telefono.length === 11) {
        telefono = `+56${telefono}`;
      } else {
      }
    }
    return telefono;
  }

  const validateFirmante = (firmanteValues) => {
    let valid = true;
    let telefono = formatTelefono(firmanteValues.telefono);
    if (!regexpTelefono.test(telefono)) {
      setValidationMessages([
        ...validationMessages,
        "El telefono del firmante no cumple con el formato +569XXXXXXXX",
      ]);
      valid = false;
    }
    if (!regexpRut.test(firmanteValues.rut) || firmanteValues.rut.includes(".") || !firmanteValues.rut.includes("-")) {
      setValidationMessages([
        ...validationMessages,
        "El rut del firmante no cumple con el formato exigido. No debe contener puntos y debe contener un guion",
      ]);
      valid = false;
    }
    if (!regexpEmail.test(firmanteValues.email)) {
      setValidationMessages([
        ...validationMessages,
        "El correo electronico del firmante es invalido",
      ]);
      valid = false;
    }
    if (firmanteValues.nombres === "") {
      setValidationMessages([
        ...validationMessages,
        "Ingrese nombres para el firmante",
      ]);
      valid = false;
    }
    if (firmanteValues.apellidos === "") {
      setValidationMessages([
        ...validationMessages,
        "Ingrese apellidos para el firmante",
      ]);
      valid = false;
    }
    return valid;
  }

  const propiedad = useSelector(
    (state) => state.app.propiedad && state.app.propiedad.data
  );
  const requestPostPortfolio = useSelector(
    (state) => state.app.requestPostPortfolio
  );
  const prospectos = useSelector(
    (state) => state.app.prospectosCA && state.app.prospectosCA.results
  );
  const previewDocument = useSelector((state) => state.app.previewDocument);

  const [tipoMoneda, setTipoMoneda] = useState("UF");
  const onChangeTipoMoneda = (e) => setTipoMoneda(e.target.value);
  
  useEffect(() => {
    dispatch(getPropiedadById(idPropiedad));
  }, [idPropiedad]);

  useEffect(() => {
    dispatch(getProspectos("ca", 1, 999, "", "", "", 0, 0));
    dispatch(setPreviewDocument(null));
  }, []);
  
  useEffect(() => {
    if (propiedad && propiedad.idCliente) {
      dispatch(getCliente(propiedad.idCliente));
    }
  }, [propiedad]);

  useEffect(() => {
    if (idProspecto && idProspecto !== "") {
      const prosp = prospectos && prospectos.find((p) => p.id === idProspecto);
      setProspecto(prosp);
      setFirmante({
        rut: prosp.rut,
        nombres: prosp.nombres,
        apellidos: prosp.apellidos,
        email: prosp.email,
        telefono: prosp.telefono
      })
    } else {
      setProspecto(null);
    }
  }, [idProspecto]);

  useEffect(() => {
    if (prospecto !== null) {
      setFirmante({
        rut: prospecto.rut,
        nombres: prospecto.nombres,
        apellidos: prospecto.apellidos,
        email: prospecto.email,
        telefono: prospecto.telefono
      })
    } 
  }, [prospecto]);

  const errorMessage = useSelector((state) => state.app.errorMessage);

  const handleSubmit = (formValues, isDownload) => {
    //console.log(formValues);
    //let fechaFormateada = moment(fechaVisita).format("DD/MM/YYYY");
    let firmantes = [];
    let documentos = [];
    documentos.push({
      tipoDocumentoContrato: tipoDocumento,
      archivo: "",
      asignado: false,
      urlDocumentoContratoGoFirmex: "",
      urlDocumentoContrato: "",
    });
    //datos de alvaro
    firmantes.push({
      ...datosAlvaro,
      urlFirma: "",
      firmado: false,
      documentosIdentidad: [
        {
          tipoDocumentoIdentidad: 0,
          archivo: formValues.archivoVersoIdentidadPropins,
          asignado: false,
          urlDocumento: usarDocumentosAlvaro ? "https://propiblobstorage.blob.core.windows.net:443/acd72ce1-366e-4b58-9235-e475ecff6d63/Alvaro%20Devescovi%200.png" : ""
        },
        {
          tipoDocumentoIdentidad: 1,
          archivo: formValues.archivoReversoIdentidadPropins,
          asignado: false,
          urlDocumento: usarDocumentosAlvaro ? "https://propiblobstorage.blob.core.windows.net:443/acd72ce1-366e-4b58-9235-e475ecff6d63/Alvaro%20Devescovi%201.png" : ""
        },
        /*
        {
          tipoDocumentoIdentidad: 2,
          archivo: f.archivoCertificadoDocumentoDominio,
          asignado: false
        },
        */
      ],
    });

    if (firmante !== null) {
      const valid = validateFirmante(firmante);
      if (valid) {
        setValidationMessages([]);
        let validatedFirmante = firmante;
        
        validatedFirmante.telefono = formatTelefono(validatedFirmante.telefono);
        //setFirmante(validateFirmante)
        firmantes.push({
          ...validatedFirmante,
          urlFirma: "",
          firmado: false,
          documentosIdentidad: [
            {
              tipoDocumentoIdentidad: 0,
              archivo: formValues.archivoVersoIdentidad,
              asignado: false,
            },
            {
              tipoDocumentoIdentidad: 1,
              archivo: formValues.archivoReversoIdentidad,
              asignado: false,
            },
            /*
            {
              tipoDocumentoIdentidad: 2,
              archivo: f.archivoCertificadoDocumentoDominio,
              asignado: false
            },
            */
          ],
        });

        const data = {
          idBroker: "",
          firmantes,
          documentos,
          idPortafolioGoFirmex: "",
          portafolioCreado: false,
          evaluacionIniciada: false,
          portafolioCompleto: false,
          descripcionEstadoPortafolio: "",
          idPropiedad,
          idProspecto,
          tipoDocumento,
          fecha: fechaVisita,
          hora: horaVisita,
          numero: 0,
          propiedadesCanje: formValues.propiedadesCanje,
          representacionBrokerExterno: formValues.representacionBrokerExterno,
          items: formValues.inventario,
          observaciones: formValues.observaciones,
          lastError: "No error",
          precioFinal: formValues.precioFinal,
          tipoMoneda
        };

        console.log(data);
        
        if (isDownload) {
          //dispatch(descargarVistaPreviaDocumento(data));
        } else {
          console.log("submitted")
          dispatch(crearPortafolio(data));
          //dispatch(descargarVistaPreviaDocumento(data));
        }
      }
    }      
  };

  useEffect(() => {
    if (
      requestPostPortfolio === "LOADING"
    ) {
      setIsLoading(true);
    } else {
      setIsLoading(false);
    }
  }, [
    requestPostPortfolio,
  ]);

  if (requestPostPortfolio === "SUCCESS") {
    handleClose();
    Swal.fire({
      title: "Documento generado",
      text: "Se ha generado el documento exitosamente",
      icon: "success",
    }).then((result) => {
      if (result.value || result.dismiss === "backdrop") {
        window.location.reload();
      }
    });
  }

  if (requestPostPortfolio === "ERROR") {
    Swal.fire({
      title: "Error",
      text: errorMessage,
      icon: "error",
    });
  }

  useEffect(() => {
    if (previewDocument !== null &&
      previewDocument !== undefined) {
        const url = window.URL.createObjectURL(new Blob([previewDocument]));
        const link = document.createElement("a");
        link.href = url;
        let fileName = "Vista previa.pdf";
        link.setAttribute("download", fileName);
        document.body.appendChild(link);
        link.click();
        dispatch(setPreviewDocument(null));
    } 
  }, [
    previewDocument
  ]);

  return (
    <Modal
      disableScrollLock={false}
      open={isOpen}
      onClose={handleClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
      sx={{
        overflowY: "auto",
        top: "10px",
        maxHeight: isMobile ? "100%" : "95%",
      }}
    >
      <Box sx={isMobile ? styleMobile : style}>
        {isLoading && (
          <Backdrop
            sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
            open={true}
            onClick={handleClose}
          >
            <CircularProgress color="inherit" />
          </Backdrop>
        )}
        <h4>{title}</h4>
        {tipoDocumento === 4 && (
          <FormGenerarOrdenCanje               
            handleSubmit={handleSubmit}
            handleClose={handleClose}
            validationMessages={validationMessages}
            firmante={firmante}
            setFirmante={setFirmante}
            tipoDocumento={tipoDocumento}
            usarDocumentosAlvaro={usarDocumentosAlvaro}
            onChangeUsarDocumentosAlvaro={onChangeUsarDocumentosAlvaro}
          />
        )}
        {tipoDocumento === 5 && (
          <FormGenerarOrdenArriendo
            idPropiedad={idPropiedad}            
            handleSubmit={handleSubmit}
            handleClose={handleClose}
            onChangePropiedad={onChangePropiedad}
            validationMessages={validationMessages}
            firmante={firmante}
            setFirmante={setFirmante}
            tipoDocumento={tipoDocumento}
            usarDocumentosAlvaro={usarDocumentosAlvaro}
            onChangeUsarDocumentosAlvaro={onChangeUsarDocumentosAlvaro}
          />
        )}
        {tipoDocumento === 6 && (
          <FormGenerarCartaOferta
            idPropiedad={idPropiedad}            
            idProspecto={idProspecto}
            prospecto={prospecto}
            onChangeIdProspecto={onChangeIdProspecto}
            handleSubmit={handleSubmit}
            handleClose={handleClose}
            onChangePropiedad={onChangePropiedad}
            validationMessages={validationMessages}
            firmante={firmante}
            setFirmante={setFirmante}
            tipoDocumento={tipoDocumento}
            usarDocumentosAlvaro={usarDocumentosAlvaro}
            onChangeUsarDocumentosAlvaro={onChangeUsarDocumentosAlvaro}
            onChangeTipoMoneda={onChangeTipoMoneda}
            tipoMoneda={tipoMoneda}
          />
        )}
        {tipoDocumento === 7 && (
          <FormGenerarAceptacionCartaOferta
            idPropiedad={idPropiedad}            
            handleSubmit={handleSubmit}
            handleClose={handleClose}
            onChangePropiedad={onChangePropiedad}
            validationMessages={validationMessages}
            firmante={firmante}
            setFirmante={setFirmante}
            tipoDocumento={tipoDocumento}
            usarDocumentosAlvaro={usarDocumentosAlvaro}
            onChangeUsarDocumentosAlvaro={onChangeUsarDocumentosAlvaro}
          />
        )}
        {tipoDocumento === 8 && (
          <FormGenerarOrdenVenta
            idPropiedad={idPropiedad}            
            handleSubmit={handleSubmit}
            handleClose={handleClose}
            onChangePropiedad={onChangePropiedad}
            validationMessages={validationMessages}
            firmante={firmante}
            setFirmante={setFirmante}
            tipoDocumento={tipoDocumento}
            usarDocumentosAlvaro={usarDocumentosAlvaro}
            onChangeUsarDocumentosAlvaro={onChangeUsarDocumentosAlvaro}
          />
        )}
        {tipoDocumento === 9 && (
          <FormGenerarOrdenVisita
            idPropiedad={idPropiedad}
            idProspecto={idProspecto}
            handleSubmit={handleSubmit}
            handleClose={handleClose}
            onChangePropiedad={onChangePropiedad}
            onChangeIdProspecto={onChangeIdProspecto}
            prospecto={prospecto}
            validationMessages={validationMessages}
            onChangeHoraVisita={onChangeHoraVisita}
            horaVisita={horaVisita}
            fechaVisita={fechaVisita}
            setFechaVisita={setFechaVisita}
            firmante={firmante}
            setFirmante={setFirmante}
            tipoDocumento={tipoDocumento}
            usarDocumentosAlvaro={usarDocumentosAlvaro}
            onChangeUsarDocumentosAlvaro={onChangeUsarDocumentosAlvaro}
          />
        )}

        {tipoDocumento === 10 && (
          <FormGenerarActaEntrega
            idPropiedad={idPropiedad}
            idProspecto={idProspecto}
            handleSubmit={handleSubmit}
            handleClose={handleClose}
            onChangePropiedad={onChangePropiedad}
            onChangeIdProspecto={onChangeIdProspecto}
            prospecto={prospecto}
            validationMessages={validationMessages}
            firmante={firmante}
            setFirmante={setFirmante}
            tipoDocumento={tipoDocumento}
            usarDocumentosAlvaro={usarDocumentosAlvaro}
            onChangeUsarDocumentosAlvaro={onChangeUsarDocumentosAlvaro}
          />
        )}
        {tipoDocumento === 11 && (
          <FormGenerarInventarioInmueble
            idPropiedad={idPropiedad}
            idProspecto={idProspecto}
            handleSubmit={handleSubmit}
            handleClose={handleClose}
            onChangePropiedad={onChangePropiedad}
            onChangeIdProspecto={onChangeIdProspecto}
            prospecto={prospecto}
            validationMessages={validationMessages}
            firmante={firmante}
            setFirmante={setFirmante}
            tipoDocumento={tipoDocumento}
            usarDocumentosAlvaro={usarDocumentosAlvaro}
            onChangeUsarDocumentosAlvaro={onChangeUsarDocumentosAlvaro}
          />
        )}
        
      </Box>
    </Modal>
  );
};

export default ModalGenerarDocumento;
