import { setLocale } from "yup";

//console.log("yup!")
setLocale({
    mixed: {
      default: 'Valor inválido',
    },
    number: {
      min: 'Ingrese valor mayor a ${min}',
      typeError: 'Ingrese solo numeros',
    },
});