/** @format */

import {
  RECEIVE_SUBIR_PROPIEDAD,
  ADD_CLIENTE,
  ADD_USUARIO,
  POST_ADD_INMOBILIARIA,
  GET_ALL_CLIENTES,
  BEGIN_ALL_CLIENTES,
  GET_USER_BY_ROL,
  ADD_HORARIO_EMP,
  GET_ALL_PROPIEDADES,
  GET_ALL_VISITAS,
  GET_ALL_REGIONES,
  REQUEST_GET_REGIONES,
  POST_LOGIN_USUARIO,
  GET_HORARIO_BY_USER,
  CLEAR_ACTION,
  POST_UPDATE_PROPIEDAD,
  POST_INSERT_INMOBILIARIA,
  GET_ALL_INMOBILIARIAS,
  REQUEST_GET_ALL_INMOBILIARIAS,
  GET_ALL_USUARIOS,
  USUARIO_LOGOUT,
  PUT_UPDATE_INMOBILIARIA,
  RESET_INITIAL_VALUES,
  PUT_UPDATE_CLIENTE,
  GET_TIPOS_CONTRATOS,
  POST_INSERT_TIPO_CONTRATO,
  GET_ALL_SERVICIOS_BASE,
  ADD_PLAN,
  INITIAL_MENSAJE,
  ADD_SERVICIO_BASE,
  GET_ALL_PLANES,
  REQUEST_GET_ALL_PLANES,
  GET_PLAN_BY_ID,
  GET_OFERTAS_BY_PUBLICACION,
  ADD_PROPIEDADES_IMAGES,
  RESET_PROPIEDADES_IMAGES,
  ADD_VALORACION,
  SET_LOADING,
  UPDATE_PLAN,
  DELETE_PLAN,
  ADD_SERVICIO_ADICIONAL,
  LIST_SERVICIOS_ADICIONALES,
  DELETE_SERVICIO_ADICIONAL,
  UPDATE_SERVICIO_ADICIONAL,
  GET_ALL_AGENDAS,
  UPDATE_AGENDAS,
  REQUEST_DELETE_PROPIEDAD,
  SIGNATURE,
  GET_ALL_PROYECTOS,
  POST_PROYECTO_RES,
  REQUEST_POST_PROYECTO,
  REQUEST_PUT_PROYECTO,
  PUT_PROYECTO_RES,
  SET_POST_TARJETA_PROYECTO,
  SET_REQUEST_POST_TARJETA_PROYECTO,
  SET_REQUEST_DELETE_TARJETA_PROYECTO,
  CHANGE_ROWS,
  REQUEST_UPDATE_ESTADO_PROPIEDAD,
  SET_RESPONSE_MSG,
  SET_PROPIEDADES_PAGINADAS,
  REQUEST_GET_PROPIEDADES_PAGINADAS,
  SET_PROPIEDAD,
  REQUEST_GET_PROPIEDAD,
  SET_TARJETA_PROP,
  REQUEST_POST_TARJETA_PROP,
  REQUEST_DELETE_TARJETA_PROP,
  REQUEST_POST_UPLOAD_DATOS_TASACION,
  REQUEST_DELETE_ALL_DATOS_TASACION,
  REQUEST_POST_UPLOAD_PI_PROPIEDADES,
  REQUEST_POST_ADD_SUSCRIPCION,
  GET_SUSCRIPCION_EXPORT,
  REQUEST_GET_SUSCRIPCION_EXPORT,
  REQUEST_GET_EXCEL_YAPO,
  SET_SUSCRIPCION_LAST_UPDATED,
  REQUEST_SUSCRIPCION_LAST_UPDATED,
  REQUEST_ADD_USUARIO,
  REQUEST_UPDATE_USUARIO,
  SET_VISITA_FOTOGRAFO,
  SET_REQUEST_LOADING_VISITA_FOTOGRAFO,
  SET_AGENDA_FOTOGRAFO,
  SET_REQUEST_LOADING_AGENDA_FOTOGRAFO,
  SET_USUARIOS_FILTRADOS,
  SET_REQUEST_USUARIOS_FILTRADOS,
  SET_ASIGNAR_BROKER,
  SET_REQUEST_ASIGNAR_BROKER,
  SET_SUSCRIPCIONES,
  SET_REQUEST_SUSCRIPCIONES,
  SET_SUSCRIPCION,
  SET_REQUEST_SUSCRIPCION,
  SET_UPDATE_SUSCRIPCION,
  SET_REQUEST_UPDATE_SUSCRIPCION,
  SET_AGENDA_CLIENTE,
  SET_REQUEST_AGENDA_CLIENTE,
  SET_VISITAS_BY_FECHA_AND_ID,
  SET_REQUEST_VISITAS_BY_FECHA_AND_ID,
  SET_REQUEST_AGENDAR_VISITA_BROKER_SUSCRIPTOR,
  SET_VISITAS_BROKER_SUSCRIPTOR,
  SET_REQUEST_VISITAS_BROKER_SUSCRIPTOR,
  SET_EMBAJADOR,
  SET_REQUEST_REFERIR_EMBAJADOR,
  SET_BROKER,
  SET_REQUEST_POST_BROKER,
  SET_REQUEST_GET_BROKER,
  SET_REQUEST_PUT_BROKER,
  SET_REQUEST_CAMBIO_CONTRASEÑA,
  SET_USUARIO,
  SET_REQUEST_GET_USUARIO,
  SET_ERROR_MESSAGE,
  SET_REQUEST_CONFIRMAR_VISITA,
  SET_REFERIR_VENDEDOR,
  SET_REQUEST_REFERIR_VENDEDOR,
  SET_VENDEDORES,
  SET_REQUEST_GET_VENDEDORES,
  SET_REQUEST_USUARIO_CAMBIAR_CONTACTADO,
  SET_BROKERS,
  SET_REQUEST_GET_ALL_BROKERS,
  SET_REQUEST_UPDATE_AGENDA_CLIENTE,
  SET_REQUEST_AGENDAR_VISITA,
  SET_REQUEST_PUT_AGENDAR_VISITA,
  SET_REQUEST_CANCELAR_VISITA,
  SET_REQUEST_UPDATE_PLAN,
  SET_REQUEST_ADD_PLAN,
  SET_REQUEST_DELETE_PLAN,
  SET_REQUEST_ADD_SERVICIO_ADICIONAL,
  SET_REQUEST_UPDATE_SERVICIO_ADICIONAL,
  SET_REQUEST_DELETE_SERVICIO_ADICIONAL,
  SET_REQUEST_ADD_SERVICIO_BASE,
  SET_USUARIOS_PAGINADOS,
  SET_REQUEST_GET_USUARIOS_PAGINADOS,
  SET_LANDING_INMOBILIARIA,
  SET_REQUEST_GET_LANDING_INMOBILIARIA,
  SET_REQUEST_POST_LANDING_INMOBILIARIA,
  SET_LANDING_INMOBILIARIAS,
  SET_REQUEST_GET_ALL_LANDING_INMOBILIARIAS,
  SET_REQUEST_UPDATE_LANDING_INMOBILIARIA,
  SET_NEWSLETTER,
  SET_NEWSLETTERS,
  SET_REQUEST_POST_NEWSLETTER,
  SET_REQUEST_GET_ALL_NEWSLETTERS,
  SET_REQUEST_DELETE_NEWSLETTER_BY_ID,
  SET_REQUEST_SEND_EMAILS_NEWSLETTER,
  SET_CONTACTO_NEWSLETTER,
  SET_POSIBLES_LEADS,
  SET_REQUEST_UPLOAD_POSIBLES_LEADS,
  SET_REQUEST_GET_POSIBLES_LEADS,
  SET_REQUEST_UPDATE_POSIBLE_LEAD,
  SET_REQUEST_CAMBIAR_CONTACTADO_POSIBLE_LEAD,
  RECOVER_PASSWORD,
  REQUEST_RECOVER_PASSWORD,
  VALIDATE_TOKEN,
  RESTABLECER_PASSWORD,
  SET_REQUEST_DELETE_USER,
  SET_REQUEST_LOGIN,
  GET_EXCEL_YAPO,
  SET_REQUEST_POST_YAPO,
  SET_REQUEST_PUT_YAPO,
  SET_REQUEST_DELETE_YAPO,
  SET_REQUEST_POST_PI,
  SET_REQUEST_PUT_PI,
  SET_REQUEST_DELETE_PI,
  SET_REQUEST_POST_PORTFOLIO,
  SET_REQUEST_GET_PORTFOLIOS,
  SET_PORTFOLIOS,
  SET_REQUEST_PUT_SIGNER,
  SET_REQUEST_PUT_DOCUMENTS_PORTFOLIO,
  SET_REQUEST_PUT_SIGNER_DOCUMENTS_PORTFOLIO,
  SET_REQUEST_PORTFOLIO_RETRY,
  SET_USER_BY_ID,
  SET_REQUEST_USER_BY_ID,
  SET_CLIENTE,
  SET_REQUEST_GET_CLIENTE,
  REQUEST_UPDATE_PROPIEDAD,
  SET_ACTIVIDAD,
  REQUEST_GET_ACTIVIDAD,
  SET_ACTIVIDADES,
  REQUEST_GET_ACTIVIDADES,
  REQUEST_POST_ACTIVIDAD,
  REQUEST_PUT_ACTIVIDAD,
  SET_CLIENTES,
  REQUEST_GET_CLIENTES,
  SET_USUARIO_BY_FILTER,
  REQUEST_GET_USUARIO_BY_EMAIL,
  SET_PROSPECTOS_VA,
  SET_PROSPECTOS_CA,
  SET_PROSPECTO,
  REQUEST_GET_PROSPECTOS_VA,
  REQUEST_GET_PROSPECTOS_CA,
  REQUEST_POST_PROSPECTO,
  REQUEST_GET_PROSPECTO,
  REQUEST_DELETE_PROSPECTO,
  SET_REQUERIMIENTOS,
  REQUEST_GET_REQUERIMIENTOS,
  SET_REQUERIMIENTO,
  REQUEST_POST_REQUERIMIENTO,
  REQUEST_UPDATE_PROSPECTO,
  REQUEST_PUT_REQUERIMIENTO,
  REQUEST_ADD_PROPIEDAD,
  SET_DOCUMENT,
  REQUEST_GENERATE_DOCUMENT,
  SET_RESULTADO_TASACION,
  SET_REQUEST_TASACION,
  SET_PROPIEDADES_SIMILARES_TASACION,
  SET_REQUEST_PROPIEDADES_SIMILARES_TASACION,
  SET_VALORES_PRELIMINARES_TASACION,
  SET_REQUEST_VALORES_PRELIMINARES_TASACION,
  SET_REPORTE,
  SET_REPORTES,
  REQUEST_GET_REPORTES,
  REQUEST_POST_REPORTE,
  SET_COMUNAS,
  SET_GRAFICA,
  SET_GRAFICAS,
  REQUEST_GET_GRAFICA,
  REQUEST_GET_GRAFICAS,
  REQUEST_POST_GRAFICA,
  REQUEST_PUT_GRAFICA,
  SET_CAPACITACION,
  SET_CAPACITACIONES,
  REQUEST_GET_CAPACITACION,
  REQUEST_GET_CAPACITACIONES,
  REQUEST_POST_CAPACITACION,
  REQUEST_PUT_CAPACITACION,
  REQUEST_DELETE_CAPACITACION,
  REQUEST_SOLICITAR_CANJE,
  SET_PREVIEW_DOCUMENT,
  REQUEST_PREVIEW_DOCUMENT,
  SET_EXPORT_TASACION,
  REQUEST_EXPORT_TASACION,
  FICHA_PROPIEDAD,
  REQUEST_DESCARGA_FICHA_PROPIEDAD,
  SET_REUNIONES,
  REQUEST_GET_REUNIONES,
  REQUEST_ACTUALIZAR_REUNION,
  SET_PROYECTO,
  SET_PROYECTOS,
  REQUEST_GET_PROYECTO,
  REQUEST_GET_PROYECTOS,
  REQUEST_ADD_INMOBILIARIA,
  REQUEST_PUT_INMOBILIARIA,
  REQUEST_UPLOAD_ARCHIVO_PROSPECTOS,
  SET_METAS,
  REQUEST_GET_METAS,
  REQUEST_UPLOAD_ARCHIVO_METAS,
  SET_ASDF
} from "../action";

const initialStateValues = {
  loading: false,
  respuesta: [],
  mensaje: "",
  itemsClientes: [],
  itemsUsuarios: [],
  itemPropiedades: [],
  itemVisitas: [],
  itemRegiones: [],
  requestGetRegiones: "IDLE",
  itemUsuario: [],
  horario: [],
  itemInmobiliarias: [],
  requestGetAllInmobiliarias: "IDLE",
  itemProyectos: [],
  tiposContrato: [],
  itemServicios: [],
  itemPlanes: [],
  requestGetPlanes: "IDLE",
  itemOfertas: [],
  imagenesPropiedad: [],
  valoracion: {},
  servicioAdicionales: [],
  agendas: [],
  agendaFotografo: [],
  rowsNumberProps: [0],
  requestUpdateEstadoPropiedad: "IDLE",
  responseMessage: null,
  propiedadesPaginadas: null,
  requestPropiedadesPaginadas: "IDLE",
  propiedad: null,
  requestGetPropiedad: "IDLE",
  tarjetaProp: null,
  requestPostTarjetaProp: "IDLE",
  requestDeleteTarjetaProp: "IDLE",
  requestPostUploadDatosTasacion: "IDLE",
  requestDeleteAllDatosTasacion: "IDLE",
  requestPostUploadPIPropiedades: "IDLE",
  requestPostAddSuscripcion: "IDLE",
  suscripcionExcel: null,
  requestSuscripcionExcel: "IDLE",
  suscripcionLastUpdated: null,
  requestSuscripcionLastUpdated: "IDLE",
  requestAddUsuario: "IDLE",
  requestUpdateUsuario: "IDLE",
  requestLoadingVisitaFotografo: false,
  visitaFotografo: null,
  agendaFotografo: null,
  requestLoadingAgendaFotografo: false,
  usuariosFiltrados: null,
  requestUsuarioFiltrados: "IDLE",
  propiedadBroker: null,
  requestPropiedadBroker: "IDLE",
  suscripciones: null,
  requestSuscripciones: "IDLE",
  suscripcion: null,
  requestSuscripcion: "IDLE",
  updateSuscripcion: null,
  requestUpdateSuscripcion: "IDLE",
  agendaCliente: null,
  requestAgendaCliente: "IDLE",
  visitasCliente: null,
  requestVisitasCliente: "IDLE",
  requestAgendarVisitaBroker: "IDLE",
  requestPutAgendarVisita: "IDLE",
  requestCancelarVisita: "IDLE",
  visitasBrokerSuscriptor: null,
  requestVisitasBrokerSuscriptor: "IDLE",
  embajador: null,
  requestReferirEmbajador: "IDLE",
  broker: null,
  requestPostBroker: "IDLE",
  requestGetBroker: "IDLE",
  requestPutBroker: "IDLE",
  requestCambioContraseña: "IDLE",
  usuario: null,
  requestGetUsuario: "IDLE",
  errorMessage: "",
  requestConfirmarVisita: "IDLE",
  vendedor: null,
  requestReferirVendedor: "IDLE",
  vendedores: null,
  requestGetVendedores: "IDLE",
  requestCambiarContactado: "IDLE",
  brokers: null,
  requestGetAllBrokers: "IDLE",
  requestUpdateAgendaCliente: "IDLE",
  requestAgendarVisita: "IDLE",
  requestUpdatePlan: "IDLE",
  requestAddPlan: "IDLE",
  requestDeletePlan: "IDLE",
  requestAddServicioAdicional: "IDLE",
  requestUpdateServicioAdicional: "IDLE",
  requestDeleteServicioAdicional: "IDLE",
  requestAddServicioBase: "IDLE",
  usuariosPaginados: null,
  requestGetUsuariosPaginados: "IDLE",
  landingInmobiliaria: null,
  requestGetLandingInmobiliaria: "IDLE",
  requestPostLandingInmobiliaria: "IDLE",
  landingInmobiliarias: null,
  requestGetAllLandingInmobiliarias: "IDLE",
  requestUpdateLandingInmobiliaria: "IDLE",
  newsletter: null,
  newsletters: null,
  requestPostNewsletter: "IDLE",
  requestGetAllNewsletters: "IDLE",
  requestDeleteNewsletterById: "IDLE",
  requestSendEmailsNewsletter: "IDLE",
  contactoNewsletter: null,
  posiblesLeads: null,
  requestUploadPosiblesLeads: "IDLE",
  requestGetPosiblesLeads: "IDLE",
  requestUpdatePosibleLead: "IDLE",
  requestCambiarContactadoPosibleLead: "IDLE",
  recover: null,
  requestRecover: "IDLE",
  tokenPassword: null,
  restablecer: null,
  requestDeleteUser: "IDLE",
  requestLogin: "IDLE",
  requestPostProyecto: "IDLE",
  requestPutProyecto: "IDLE",
  tarjetaProyecto: null,
  requestPostTarjetaProyecto: "IDLE",
  requestDeleteTarjetaProyecto: "IDLE",
  requestExcelYapo: "IDLE",
  excelYapo: null,
  requestPostYapo: "IDLE",
  requestPutYapo: "IDLE",
  requestDeleteYapo: "IDLE",
  requestPostPI: "IDLE",
  requestPutPI: "IDLE",
  requestDeletePI: "IDLE",
  requestPostPortfolio: "IDLE",
  requestGetPortfolios: "IDLE",
  portfolios: null,
  requestPutSigner: "IDLE",
  requestPutDocumentsPortfolio: "IDLE",
  requestPutSignerDocumentsPortfolio: "IDLE",
  requestPortfolioRetry: "IDLE",
  userById: null,
  requestUserById: "IDLE",
  cliente: null,
  requestGetCliente: "IDLE",
  requestUpdatePropiedad: "IDLE",
  actividad: null,
  requestGetActividad: "IDLE",
  actividades: null,
  requestGetActividades: "IDLE",
  requestPostActividad: "IDLE",
  requestPutActividad: "IDLE",
  clientes: null,
  requestGetClientes: "IDLE",
  usuarioByFilter: null,
  requestGetUsuarioByEmail: "IDLE",
  prospectosVA: null,
  prospectosCA: null,
  prospecto: null,
  requestGetProspectosVA: "IDLE",
  requestGetProspectosCA: "IDLE",
  requestPostProspecto: "IDLE",
  requestGetProspecto: "IDLE",
  requestDelteProspecto: "IDLE",
  requerimientos: null,
  requestGetRequerimientos: "IDLE",
  requerimiento: null,
  requestPostRequerimiento: "IDLE",
  requestUpdateProspecto: "IDLE",
  requestPutRequerimiento: "IDLE",
  requestAddPropiedad: "IDLE",
  addUsuario: null,
  requestGenerateDocument: "IDLE",
  document: null,
  requestStateTasacion: 'IDLE',
  resultadoTasacion: null,
  propiedadesSimilaresTasacion: null,
  requestStatePropiedadesSimilaresTasacion: 'IDLE',
  valoresPreliminaresTasacion: null,
  requestStateValoresPreliminaresTasacion: 'IDLE',
  reporte: null,
  reportes: null,
  requestGetReportes: "IDLE",
  requestPostReporte: "IDLE",
  comunas: new Set(),
  grafica: null,
  graficas: null,
  requestGetGrafica: "IDLE",
  requestGetGraficas: "IDLE",
  requestPostGrafica: "IDLE",
  requestPutGrafica: "IDLE",
  capacitacion: null,
  capacitaciones: null,
  requestGetCapacitacion: "IDLE",
  requestGetCapacitaciones: "IDLE",
  requestPostCapacitacion: "IDLE",
  requestPutCapacitacion: "IDLE",
  requestDeleteCapacitacion: "IDLE",
  requestSolicitarCanje: "IDLE",
  requestDeletePropiedad: "IDLE",
  previewDocument: null,
  requestPreviewDocument: "IDLE",
  exportTasacion: null,
  requestExportTasacion: "IDLE",
  fichaPropiedad: null,
  requestDescargaFichaPropiedad: "IDLE",
  reuniones: null,
  requestGetReuniones: "IDLE",
  requestActualizarReunion: "IDLE",
  proyecto: null,
  proyectos: null,
  requestGetProyecto: "IDLE",
  requestGetProyectos: "IDLE",
  requestAddInmobiliaria: "IDLE",
  requestPutInmobiliaria: "IDLE",
  requestUploadArchivoProspectos: "IDLE",
  metas: null,
  requestGetMetas: "IDLE",
  requestUploadArchivoMetas: "IDLE",
  asdf: null
};

const initialState = { ...initialStateValues };

const actionsMap = {
  [SET_LOADING]: (state, action) => {
    const { loading } = action;
    return {
      ...state,
      loading,
    };
  },
  [POST_INSERT_TIPO_CONTRATO]: (state, action) => {
    const { mensaje } = action;
    state = state || initialState;
    return {
      ...state,
      mensaje,
    };
  },
  [GET_TIPOS_CONTRATOS]: (state, action) => {
    const { tiposContrato } = action;
    state = state || initialState;
    return {
      ...state,
      tiposContrato,
    };
  },
  [PUT_UPDATE_CLIENTE]: (state, action) => {
    const { mensaje } = action.payload;
    state = state || initialState;
    return {
      ...state,
      mensaje,
    };
  },

  [PUT_UPDATE_INMOBILIARIA]: (state, action) => {
    const { mensaje } = action;
    state = state || initialState;
    return {
      ...state,
      mensaje,
    };
  },

  [RESET_INITIAL_VALUES]: (state, action) => {
    state = state || initialState;
    state = undefined;
    return {
      ...state,
      action,
    };
  },
  [USUARIO_LOGOUT]: (state, action) => {
    const { itemUsuario } = action;
    state = state || initialState;
    return {
      ...state,
      itemUsuario,
    };
  },
  [CLEAR_ACTION]: (state, action) => {
    const { mensaje } = action;
    state = state || initialState;
    return {
      ...state,
      mensaje,
    };
  },
  [CHANGE_ROWS]: (state, action) => {
    const { rowsNumberProps } = action;
    state = state || [0];
    return {
      ...state,
      rowsNumberProps,
    };
  },
  [RECEIVE_SUBIR_PROPIEDAD]: (state, action) => {
    const { mensaje } = action;
    state = state || initialState;
    return {
      ...state,
      mensaje,
      isLoading: false,
    };
  },

  [ADD_CLIENTE]: (state, action) => {
    const { mensaje } = action;
    state = state || initialState;
    return {
      ...state,
      mensaje,
      isLoading: false,
    };
  },

  [POST_ADD_INMOBILIARIA]: (state, action) => {
    const { mensaje } = action;
    state = state || initialState;
    return {
      ...state,
      mensaje,
      isLoading: false,
    };
  },

  [ADD_USUARIO]: (state, action) => {
    let { addUsuario } = action;
    return {
      ...state,
      addUsuario
    }
  },

  [BEGIN_ALL_CLIENTES]: (state, action) => {
    state = state || initialState;
    return {
      ...state,
      loading: true,
      error: null,
    };
  },

  [GET_ALL_CLIENTES]: (state, action) => {
    const { itemsClientes } = action;

    state = state || initialState;
    return {
      ...state,
      itemsClientes,
      isLoading: false,
    };
  },

  [GET_USER_BY_ROL]: (state, action) => {
    const { itemsUsuarios } = action;

    state = state || initialState;
    return {
      ...state,
      itemsUsuarios,
      isLoading: false,
    };
  },

  [ADD_HORARIO_EMP]: (state, action) => {
    const { mensaje } = action;
    state = state || initialState;
    return {
      ...state,
      mensaje,
      isLoading: false,
    };
  },
  [GET_ALL_PROPIEDADES]: (state, action) => {
    const { itemPropiedades } = action;
    state = state || initialState;
    return {
      ...state,
      itemPropiedades,
      isLoading: false,
    };
  },
  [GET_ALL_VISITAS]: (state, action) => {
    const { itemVisitas } = action;
    state = state || initialState;
    return {
      ...state,
      itemVisitas,
      isLoading: false,
    };
  },
  [GET_ALL_REGIONES]: (state, action) => {
    const { itemRegiones } = action;
    state = state || initialState;
    return {
      ...state,
      itemRegiones,
      isLoading: false,
    };
  },  
  [REQUEST_GET_REGIONES]: (state, action) => {
    const { requestGetRegiones } = action;
    return {
      ...state,
      requestGetRegiones
    };
  },
  [POST_LOGIN_USUARIO]: (state, action) => {
    const { itemUsuario } = action;
    state = state || initialState;
    return {
      ...state,
      itemUsuario,
      isLoading: false,
    };
  },
  [GET_HORARIO_BY_USER]: (state, action) => {
    const { horario } = action;
    state = state || initialState;
    return {
      ...state,
      horario,
      isLoading: false,
    };
  },
  [POST_UPDATE_PROPIEDAD]: (state, action) => {
    const { mensaje } = action;
    state = state || initialState;
    return {
      ...state,
      mensaje,
      isLoading: false,
    };
  },
  [POST_INSERT_INMOBILIARIA]: (state, action) => {
    const { mensaje } = action;
    state = state || initialState;
    return {
      ...state,
      mensaje,
      isLoading: false,
    };
  },
  [GET_ALL_INMOBILIARIAS]: (state, action) => {
    const { itemInmobiliarias } = action;
    state = state || initialState;
    return {
      ...state,
      itemInmobiliarias,
      isLoading: false,
    };
  },
  [REQUEST_GET_ALL_INMOBILIARIAS]: (state, action) => {
    const { requestGetAllInmobiliarias } = action;
    state = state || initialState;
    return {
      ...state,
      requestGetAllInmobiliarias,
    };
  },
  [GET_ALL_PROYECTOS]: (state, action) => {
    const { itemProyectos } = action;
    state = state || initialState;
    return {
      ...state,
      itemProyectos,
      isLoading: false,
    };
  },
  [GET_ALL_USUARIOS]: (state, action) => {
    const { itemsUsuarios } = action;
    state = state || initialState;
    return {
      ...state,
      itemsUsuarios,
      isLoading: false,
    };
  },
  [GET_ALL_SERVICIOS_BASE]: (state, action) => {
    const { itemServicios } = action;
    state = state || initialState;
    return {
      ...state,
      itemServicios,
      //isLoading: false,
    };
  },

  [ADD_PLAN]: (state, action) => {
    const { mensaje } = action;
    state = state || initialState;
    return {
      ...state,
      mensaje,
      isLoading: false,
    };
  },
  [INITIAL_MENSAJE]: (state, action) => {
    const { mensaje } = action;

    state = state || initialState;
    return {
      ...state,
      mensaje,
      isLoading: false,
    };
  },

  [ADD_SERVICIO_BASE]: (state, action) => {
    const { mensaje } = action;
    state = state || initialState;
    return {
      ...state,
      mensaje,
      isLoading: false,
    };
  },
  [GET_ALL_PLANES]: (state, action) => {
    const { itemPlanes } = action;
    state = state || initialState;
    return {
      ...state,
      itemPlanes,
      isLoading: false,
    };
  },
  [REQUEST_GET_ALL_PLANES]: (state, action) => {
    const { requestGetPlanes } = action;
    state = state || initialState;
    return {
      ...state,
      requestGetPlanes,
    };
  },
  [GET_PLAN_BY_ID]: (state, action) => {
    const { plan } = action;
    state = state || initialState;

    return {
      ...state,
      plan,
      isLoading: false,
    };
  },
  [GET_OFERTAS_BY_PUBLICACION]: (state, action) => {
    const { itemOfertas } = action;
    state = state || initialState;
    return {
      ...state,
      itemOfertas,
      isLoading: false,
    };
  },
  [ADD_PROPIEDADES_IMAGES]: (state, action) => {
    const { imagenesPropiedad } = action;
    state = state || initialState;
    return {
      ...state,
      imagenesPropiedad,
      isLoading: false,
    };
  },
  [RESET_PROPIEDADES_IMAGES]: (state, action) => {
    const { imagenesPropiedad } = action;
    state = state || initialState;
    return {
      ...state,
      imagenesPropiedad,
      isLoading: false,
    };
  },
  [ADD_VALORACION]: (state, action) => {
    const { valoracion } = action;
    state = state || initialState;
    return {
      ...state,
      valoracion,
      isLoading: false,
    };
  },
  [UPDATE_PLAN]: (state, action) => {
    const { mensaje } = action;
    state = state || initialState;
    return {
      ...state,
      mensaje,
      isLoading: false,
    };
  },
  [POST_PROYECTO_RES]: (state, action) => {
    const { mensaje } = action;
    state = state || initialState;
    return {
      ...state,
      mensaje,
      isLoading: false,
    };
  },
  [REQUEST_POST_PROYECTO]: (state, action) => {
    const { requestPostProyecto } = action;
    state = state || initialState;
    return {
      ...state,
      requestPostProyecto,
    };
  },
  [REQUEST_PUT_PROYECTO]: (state, action) => {
    const { requestPutProyecto } = action;
    state = state || initialState;
    return {
      ...state,
      requestPutProyecto,
    };
  },
  [PUT_PROYECTO_RES]: (state, action) => {
    const { mensaje } = action;
    state = state || initialState;
    return {
      ...state,
      mensaje,
      isLoading: false,
    };
  },
  [SET_POST_TARJETA_PROYECTO]: (state, action) => {
    let { tarjetaProyecto } = action;
    return {
      ...state,
      tarjetaProyecto,
    };
  },
  [SET_REQUEST_POST_TARJETA_PROYECTO]: (state, action) => {
    let { requestPostTarjetaProyecto } = action;
    return {
      ...state,
      requestPostTarjetaProyecto,
    };
  },
  [SET_REQUEST_DELETE_TARJETA_PROYECTO]: (state, action) => {
    let { requestDeleteTarjetaProyecto } = action;
    return {
      ...state,
      requestDeleteTarjetaProyecto,
    };
  },
  [DELETE_PLAN]: (state, action) => {
    const { mensaje, id } = action.payload;
    state = state || initialState;
    const itemPlanes =
      state.itemPlanes &&
      state.itemPlanes.filter((plan) => {
        return plan.id !== id;
      });

    return {
      ...state,
      mensaje,
      itemPlanes,
      isLoading: false,
    };
  },
  [ADD_SERVICIO_ADICIONAL]: (state, action) => {
    const { mensaje, data } = action.payload;
    state = state || initialState;

    const actuales = state.servicioAdicionales.filter(
      (dVal) => dVal.id !== data.id

      // !state.servicioAdicionales.find((serviAd) => serviAd.id === dVal.id)
    );

    const servicioAdicionales = [...actuales, data];

    return {
      ...state,
      mensaje,
      servicioAdicionales,
      isLoading: false,
    };
  },
  [LIST_SERVICIOS_ADICIONALES]: (state, action) => {
    const { data } = action.payload;
    state = state || initialState;
    const servicioAdicionales = [...data];

    return {
      ...state,
      servicioAdicionales,
      //isLoading: false,
    };
  },
  [DELETE_SERVICIO_ADICIONAL]: (state, action) => {
    const { id } = action;
    state = state || initialState;

    const servicioAdicionales =
      state.servicioAdicionales &&
      state.servicioAdicionales.filter((servicio) => {
        return servicio.id !== id;
      });

    return {
      ...state,
      servicioAdicionales,
      isLoading: false,
    };
  },
  [UPDATE_SERVICIO_ADICIONAL]: (state, action) => {
    const { mensaje } = action.payload;
    state = state || initialState;
    return {
      ...state,
      mensaje,
      isLoading: false,
    };
  },
  [GET_ALL_AGENDAS]: (state, action) => {
    const { agendas } = action;
    state = state || initialState;
    return {
      ...state,
      agendas,
      isLoading: false,
    };
  },
  [UPDATE_AGENDAS]: (state, action) => {
    const { data, mensaje } = action.payload;
    state = state || initialState;
    const sinEditar = state.agendas.filter((cita) => {
      return !data.find((editada) => cita.id === editada.id);
    });
    const agendas = [...sinEditar, ...data];
    return {
      ...state,
      agendas,
      mensaje,
      isLoading: false,
    };
  },
  [REQUEST_DELETE_PROPIEDAD]: (state, action) => {
    const { requestDeletePropiedad } = action;
    return {
      ...state,
      requestDeletePropiedad,
    };
  },
  [SIGNATURE]: (state, action) => {
    const { signature } = action;
    return {
      ...state,
      signature,
    };
  },
  [REQUEST_UPDATE_ESTADO_PROPIEDAD]: (state, action) => {
    const { requestUpdateEstadoPropiedad } = action;
    return {
      ...state,
      requestUpdateEstadoPropiedad,
    };
  },
  [SET_RESPONSE_MSG]: (state, action) => {
    const { responseMessage } = action;
    return {
      ...state,
      responseMessage,
    };
  },
  [SET_PROPIEDADES_PAGINADAS]: (state, action) => {
    const { propiedadesPaginadas } = action;
    return {
      ...state,
      propiedadesPaginadas,
    };
  },
  [REQUEST_GET_PROPIEDADES_PAGINADAS]: (state, action) => {
    const { requestPropiedadesPaginadas } = action;
    return {
      ...state,
      requestPropiedadesPaginadas,
    };
  },
  [SET_PROPIEDAD]: (state, action) => {
    const { propiedad } = action;
    return {
      ...state,
      propiedad,
    };
  },
  [REQUEST_GET_PROPIEDAD]: (state, action) => {
    const { requestGetPropiedad } = action;
    return {
      ...state,
      requestGetPropiedad,
    };
  },
  [SET_TARJETA_PROP]: (state, action) => {
    const { tarjetaProp } = action;
    return {
      ...state,
      tarjetaProp,
    };
  },
  [REQUEST_POST_TARJETA_PROP]: (state, action) => {
    const { requestPostTarjetaProp } = action;
    return {
      ...state,
      requestPostTarjetaProp,
    };
  },
  [REQUEST_DELETE_TARJETA_PROP]: (state, action) => {
    const { requestDeleteTarjetaProp } = action;
    return {
      ...state,
      requestDeleteTarjetaProp,
    };
  },
  [REQUEST_POST_UPLOAD_DATOS_TASACION]: (state, action) => {
    const { requestPostUploadDatosTasacion } = action;
    return {
      ...state,
      requestPostUploadDatosTasacion,
    };
  },
  [REQUEST_DELETE_ALL_DATOS_TASACION]: (state, action) => {
    const { requestDeleteAllDatosTasacion } = action;
    return {
      ...state,
      requestDeleteAllDatosTasacion,
    };
  },
  [REQUEST_POST_UPLOAD_PI_PROPIEDADES]: (state, action) => {
    const { requestPostUploadPIPropiedades } = action;
    return {
      ...state,
      requestPostUploadPIPropiedades,
    };
  },
  [REQUEST_POST_ADD_SUSCRIPCION]: (state, action) => {
    const { requestPostAddSuscripcion } = action;
    return {
      ...state,
      requestPostAddSuscripcion,
    };
  },
  [GET_SUSCRIPCION_EXPORT]: (state, action) => {
    const { suscripcionExcel } = action;
    return {
      ...state,
      suscripcionExcel,
    };
  },
  [REQUEST_GET_SUSCRIPCION_EXPORT]: (state, action) => {
    const { requestSuscripcionExcel } = action;
    return {
      ...state,
      requestSuscripcionExcel,
    };
  },

  [GET_EXCEL_YAPO]: (state, action) => {
    const { excelYapo } = action;
    return {
      ...state,
      excelYapo,
    };
  },

  [REQUEST_GET_EXCEL_YAPO]: (state, action) => {
    const { requestExcelYapo } = action;
    return {
      ...state,
      requestExcelYapo,
    };
  },
  [SET_SUSCRIPCION_LAST_UPDATED]: (state, action) => {
    const { suscripcionLastUpdated } = action;
    return {
      ...state,
      suscripcionLastUpdated,
    };
  },
  [REQUEST_SUSCRIPCION_LAST_UPDATED]: (state, action) => {
    const { requestSuscripcionLastUpdated } = action;
    return {
      ...state,
      requestSuscripcionLastUpdated,
    };
  },
  [REQUEST_ADD_USUARIO]: (state, action) => {
    const { requestAddUsuario } = action;
    return {
      ...state,
      requestAddUsuario,
    };
  },
  [REQUEST_UPDATE_USUARIO]: (state, action) => {
    const { requestUpdateUsuario } = action;
    return {
      ...state,
      requestUpdateUsuario,
    };
  },
  [SET_VISITA_FOTOGRAFO]: (state, action) => {
    let { visitaFotografo } = action;
    return {
      ...state,
      visitaFotografo,
    };
  },
  [SET_REQUEST_LOADING_VISITA_FOTOGRAFO]: (state, action) => {
    let { requestLoadingVisitaFotografo } = action;
    return {
      ...state,
      requestLoadingVisitaFotografo,
    };
  },
  [SET_AGENDA_FOTOGRAFO]: (state, action) => {
    let { agendaFotografo } = action;
    return {
      ...state,
      agendaFotografo,
    };
  },
  [SET_REQUEST_LOADING_AGENDA_FOTOGRAFO]: (state, action) => {
    let { requestLoadingAgendaFotografo } = action;
    return {
      ...state,
      requestLoadingAgendaFotografo,
    };
  },
  [SET_USUARIOS_FILTRADOS]: (state, action) => {
    let { usuariosFiltrados } = action;
    return {
      ...state,
      usuariosFiltrados,
    };
  },
  [SET_REQUEST_USUARIOS_FILTRADOS]: (state, action) => {
    let { requestUsuarioFiltrados } = action;
    return {
      ...state,
      requestUsuarioFiltrados,
    };
  },
  [SET_ASIGNAR_BROKER]: (state, action) => {
    let { propiedadBroker } = action;
    return {
      ...state,
      propiedadBroker,
    };
  },
  [SET_REQUEST_ASIGNAR_BROKER]: (state, action) => {
    let { requestPropiedadBroker } = action;
    return {
      ...state,
      requestPropiedadBroker,
    };
  },
  [SET_SUSCRIPCIONES]: (state, action) => {
    let { suscripciones } = action;
    return {
      ...state,
      suscripciones,
    };
  },
  [SET_REQUEST_SUSCRIPCIONES]: (state, action) => {
    let { requestSuscripciones } = action;
    return {
      ...state,
      requestSuscripciones,
    };
  },
  [SET_SUSCRIPCION]: (state, action) => {
    let { suscripcion } = action;
    return {
      ...state,
      suscripcion,
    };
  },
  [SET_REQUEST_SUSCRIPCION]: (state, action) => {
    let { requestSuscripcion } = action;
    return {
      ...state,
      requestSuscripcion,
    };
  },
  [SET_UPDATE_SUSCRIPCION]: (state, action) => {
    let { updateSuscripcion } = action;
    return {
      ...state,
      updateSuscripcion,
    };
  },
  [SET_REQUEST_UPDATE_SUSCRIPCION]: (state, action) => {
    let { requestUpdateSuscripcion } = action;
    return {
      ...state,
      requestUpdateSuscripcion,
    };
  },
  [SET_AGENDA_CLIENTE]: (state, action) => {
    let { agendaCliente } = action;
    return {
      ...state,
      agendaCliente,
    };
  },
  [SET_REQUEST_AGENDA_CLIENTE]: (state, action) => {
    let { requestAgendaCliente } = action;
    return {
      ...state,
      requestAgendaCliente,
    };
  },
  [SET_VISITAS_BY_FECHA_AND_ID]: (state, action) => {
    let { visitasCliente } = action;
    return {
      ...state,
      visitasCliente,
    };
  },
  [SET_REQUEST_VISITAS_BY_FECHA_AND_ID]: (state, action) => {
    let { requestVisitasCliente } = action;
    return {
      ...state,
      requestVisitasCliente,
    };
  },
  [SET_REQUEST_AGENDAR_VISITA_BROKER_SUSCRIPTOR]: (state, action) => {
    let { requestAgendarVisitaBroker } = action;
    return {
      ...state,
      requestAgendarVisitaBroker,
    };
  },
  [SET_VISITAS_BROKER_SUSCRIPTOR]: (state, action) => {
    let { visitasBrokerSuscriptor } = action;
    return {
      ...state,
      visitasBrokerSuscriptor,
    };
  },
  [SET_REQUEST_VISITAS_BROKER_SUSCRIPTOR]: (state, action) => {
    let { requestVisitasBrokerSuscriptor } = action;
    return {
      ...state,
      requestVisitasBrokerSuscriptor,
    };
  },
  [SET_EMBAJADOR]: (state, action) => {
    let { embajador } = action;
    return {
      ...state,
      embajador,
    };
  },
  [SET_REQUEST_REFERIR_EMBAJADOR]: (state, action) => {
    let { requestReferirEmbajador } = action;
    return {
      ...state,
      requestReferirEmbajador,
    };
  },
  [SET_BROKER]: (state, action) => {
    let { broker } = action;
    return {
      ...state,
      broker,
    };
  },
  [SET_REQUEST_POST_BROKER]: (state, action) => {
    let { requestPostBroker } = action;
    return {
      ...state,
      requestPostBroker,
    };
  },
  [SET_REQUEST_GET_BROKER]: (state, action) => {
    let { requestGetBroker } = action;
    return {
      ...state,
      requestGetBroker,
    };
  },
  [SET_REQUEST_PUT_BROKER]: (state, action) => {
    let { requestPutBroker } = action;
    return {
      ...state,
      requestPutBroker,
    };
  },
  [SET_REQUEST_CAMBIO_CONTRASEÑA]: (state, action) => {
    let { requestCambioContraseña } = action;
    return {
      ...state,
      requestCambioContraseña,
    };
  },
  [SET_USUARIO]: (state, action) => {
    let { usuario } = action;
    return {
      ...state,
      usuario,
    };
  },
  [SET_REQUEST_GET_USUARIO]: (state, action) => {
    let { requestGetUsuario } = action;
    return {
      ...state,
      requestGetUsuario,
    };
  },
  [SET_ERROR_MESSAGE]: (state, action) => {
    let { errorMessage } = action;
    return {
      ...state,
      errorMessage,
    };
  },
  [SET_REQUEST_CONFIRMAR_VISITA]: (state, action) => {
    let { requestConfirmarVisita } = action;
    return {
      ...state,
      requestConfirmarVisita,
    };
  },
  [SET_REFERIR_VENDEDOR]: (state, action) => {
    let { vendedor } = action;
    return {
      ...state,
      vendedor,
    };
  },
  [SET_REQUEST_REFERIR_VENDEDOR]: (state, action) => {
    let { requestReferirVendedor } = action;
    return {
      ...state,
      requestReferirVendedor,
    };
  },
  [SET_VENDEDORES]: (state, action) => {
    let { vendedores } = action;
    return {
      ...state,
      vendedores,
    };
  },
  [SET_REQUEST_GET_VENDEDORES]: (state, action) => {
    let { requestGetVendedores } = action;
    return {
      ...state,
      requestGetVendedores,
    };
  },
  [SET_REQUEST_USUARIO_CAMBIAR_CONTACTADO]: (state, action) => {
    let { requestCambiarContactado } = action;
    return {
      ...state,
      requestCambiarContactado,
    };
  },
  [SET_BROKERS]: (state, action) => {
    let { brokers } = action;
    return {
      ...state,
      brokers,
    };
  },
  [SET_REQUEST_GET_ALL_BROKERS]: (state, action) => {
    let { requestGetAllBrokers } = action;
    return {
      ...state,
      requestGetAllBrokers,
    };
  },
  [SET_REQUEST_UPDATE_AGENDA_CLIENTE]: (state, action) => {
    let { requestUpdateAgendaCliente } = action;
    return {
      ...state,
      requestUpdateAgendaCliente,
    };
  },
  [SET_REQUEST_AGENDAR_VISITA]: (state, action) => {
    let { requestAgendarVisita } = action;
    return {
      ...state,
      requestAgendarVisita,
    };
  },
  [SET_REQUEST_PUT_AGENDAR_VISITA]: (state, action) => {
    let { requestPutAgendarVisita } = action;
    return {
      ...state,
      requestPutAgendarVisita,
    };
  },
  [SET_REQUEST_CANCELAR_VISITA]: (state, action) => {
    let { requestCancelarVisita } = action;
    return {
      ...state,
      requestCancelarVisita,
    };
  },
  [SET_REQUEST_UPDATE_PLAN]: (state, action) => {
    let { requestUpdatePlan } = action;
    return {
      ...state,
      requestUpdatePlan,
    };
  },
  [SET_REQUEST_ADD_PLAN]: (state, action) => {
    let { requestAddPlan } = action;
    return {
      ...state,
      requestAddPlan,
    };
  },
  [SET_REQUEST_DELETE_PLAN]: (state, action) => {
    let { requestDeletePlan } = action;
    return {
      ...state,
      requestDeletePlan,
    };
  },
  [SET_REQUEST_ADD_SERVICIO_ADICIONAL]: (state, action) => {
    let { requestAddServicioAdicional } = action;
    return {
      ...state,
      requestAddServicioAdicional,
    };
  },
  [SET_REQUEST_UPDATE_SERVICIO_ADICIONAL]: (state, action) => {
    let { requestUpdateServicioAdicional } = action;
    return {
      ...state,
      requestUpdateServicioAdicional,
    };
  },
  [SET_REQUEST_DELETE_SERVICIO_ADICIONAL]: (state, action) => {
    let { requestDeleteServicioAdicional } = action;
    return {
      ...state,
      requestDeleteServicioAdicional,
    };
  },
  [SET_REQUEST_ADD_SERVICIO_BASE]: (state, action) => {
    let { requestAddServicioBase } = action;
    return {
      ...state,
      requestAddServicioBase,
    };
  },
  [SET_USUARIOS_PAGINADOS]: (state, action) => {
    let { usuariosPaginados } = action;
    return {
      ...state,
      usuariosPaginados,
    };
  },
  [SET_REQUEST_GET_USUARIOS_PAGINADOS]: (state, action) => {
    let { requestGetUsuariosPaginados } = action;
    return {
      ...state,
      requestGetUsuariosPaginados,
    };
  },
  [SET_LANDING_INMOBILIARIA]: (state, action) => {
    let { landingInmobiliaria } = action;
    return {
      ...state,
      landingInmobiliaria,
    };
  },
  [SET_REQUEST_GET_LANDING_INMOBILIARIA]: (state, action) => {
    let { requestGetLandingInmobiliaria } = action;
    return {
      ...state,
      requestGetLandingInmobiliaria,
    };
  },
  [SET_REQUEST_POST_LANDING_INMOBILIARIA]: (state, action) => {
    let { requestPostLandingInmobiliaria } = action;
    return {
      ...state,
      requestPostLandingInmobiliaria,
    };
  },
  [SET_LANDING_INMOBILIARIAS]: (state, action) => {
    let { landingInmobiliarias } = action;
    return {
      ...state,
      landingInmobiliarias,
    };
  },
  [SET_REQUEST_GET_ALL_LANDING_INMOBILIARIAS]: (state, action) => {
    let { requestGetAllLandingInmobiliarias } = action;
    return {
      ...state,
      requestGetAllLandingInmobiliarias,
    };
  },
  [SET_REQUEST_UPDATE_LANDING_INMOBILIARIA]: (state, action) => {
    let { requestUpdateLandingInmobiliaria } = action;
    return {
      ...state,
      requestUpdateLandingInmobiliaria,
    };
  },
  [SET_NEWSLETTER]: (state, action) => {
    let { newsletter } = action;
    return {
      ...state,
      newsletter,
    };
  },
  [SET_NEWSLETTERS]: (state, action) => {
    let { newsletters } = action;
    return {
      ...state,
      newsletters,
    };
  },
  [SET_REQUEST_GET_ALL_NEWSLETTERS]: (state, action) => {
    let { requestGetAllNewsletters } = action;
    return {
      ...state,
      requestGetAllNewsletters,
    };
  },
  [SET_REQUEST_POST_NEWSLETTER]: (state, action) => {
    let { requestPostNewsletter } = action;
    return {
      ...state,
      requestPostNewsletter,
    };
  },
  [SET_REQUEST_DELETE_NEWSLETTER_BY_ID]: (state, action) => {
    let { requestDeleteNewsletterById } = action;
    return {
      ...state,
      requestDeleteNewsletterById,
    };
  },
  [SET_REQUEST_SEND_EMAILS_NEWSLETTER]: (state, action) => {
    let { requestSendEmailsNewsletter } = action;
    return {
      ...state,
      requestSendEmailsNewsletter,
    };
  },
  [SET_CONTACTO_NEWSLETTER]: (state, action) => {
    let { contactoNewsletter } = action;
    return {
      ...state,
      contactoNewsletter,
    };
  },
  [SET_POSIBLES_LEADS]: (state, action) => {
    let { posiblesLeads } = action;
    return {
      ...state,
      posiblesLeads,
    };
  },
  [SET_REQUEST_UPLOAD_POSIBLES_LEADS]: (state, action) => {
    let { requestUploadPosiblesLeads } = action;
    return {
      ...state,
      requestUploadPosiblesLeads,
    };
  },
  [SET_REQUEST_GET_POSIBLES_LEADS]: (state, action) => {
    let { requestGetPosiblesLeads } = action;
    return {
      ...state,
      requestGetPosiblesLeads,
    };
  },
  [SET_REQUEST_UPDATE_POSIBLE_LEAD]: (state, action) => {
    let { requestUpdatePosibleLead } = action;
    return {
      ...state,
      requestUpdatePosibleLead,
    };
  },
  [SET_REQUEST_CAMBIAR_CONTACTADO_POSIBLE_LEAD]: (state, action) => {
    let { requestCambiarContactadoPosibleLead } = action;
    return {
      ...state,
      requestCambiarContactadoPosibleLead,
    };
  },
  [RECOVER_PASSWORD]: (state, action) => {
    let { recover } = action;
    return {
      ...state,
      recover,
    };
  },
  [REQUEST_RECOVER_PASSWORD]: (state, action) => {
    let { requestRecover } = action;
    return {
      ...state,
      requestRecover,
    };
  },
  [VALIDATE_TOKEN]: (state, action) => {
    let { tokenPassword } = action;
    return {
      ...state,
      tokenPassword,
    };
  },
  [RESTABLECER_PASSWORD]: (state, action) => {
    let { restablecer } = action;
    return {
      ...state,
      restablecer,
    };
  },
  [SET_REQUEST_DELETE_USER]: (state, action) => {
    let { requestDeleteUser } = action;
    return {
      ...state,
      requestDeleteUser,
    };
  },
  [SET_REQUEST_LOGIN]: (state, action) => {
    let { requestLogin } = action;
    return {
      ...state,
      requestLogin,
    };
  },
  [SET_REQUEST_POST_YAPO]: (state, action) => {
    let { requestPostYapo } = action;
    return {
      ...state,
      requestPostYapo,
    };
  },
  [SET_REQUEST_PUT_YAPO]: (state, action) => {
    let { requestPutYapo } = action;
    return {
      ...state,
      requestPutYapo,
    };
  },
  [SET_REQUEST_DELETE_YAPO]: (state, action) => {
    let { requestDeleteYapo } = action;
    return {
      ...state,
      requestDeleteYapo,
    };
  },
  [SET_REQUEST_POST_PI]: (state, action) => {
    let { requestPostPI } = action;
    return {
      ...state,
      requestPostPI,
    };
  },
  [SET_REQUEST_PUT_PI]: (state, action) => {
    let { requestPutPI } = action;
    return {
      ...state,
      requestPutPI,
    };
  },
  [SET_REQUEST_DELETE_PI]: (state, action) => {
    let { requestDeletePI } = action;
    return {
      ...state,
      requestDeletePI,
    };
  },
  [SET_REQUEST_POST_PORTFOLIO]: (state, action) => {
    let { requestPostPortfolio } = action;
    return {
      ...state,
      requestPostPortfolio,
    };
  },
  [SET_REQUEST_GET_PORTFOLIOS]: (state, action) => {
    let { requestGetPortfolios } = action;
    return {
      ...state,
      requestGetPortfolios,
    };
  },
  [SET_PORTFOLIOS]: (state, action) => {
    let { portfolios } = action;
    return {
      ...state,
      portfolios,
    };
  },
  [SET_REQUEST_PUT_SIGNER]: (state, action) => {
    let { requestPutSigner } = action;
    return {
      ...state,
      requestPutSigner,
    };
  },
  [SET_REQUEST_PUT_DOCUMENTS_PORTFOLIO]: (state, action) => {
    let { requestPutDocumentsPortfolio } = action;
    return {
      ...state,
      requestPutDocumentsPortfolio,
    };
  },
  [SET_REQUEST_PUT_SIGNER_DOCUMENTS_PORTFOLIO]: (state, action) => {
    let { requestPutSignerDocumentsPortfolio } = action;
    return {
      ...state,
      requestPutSignerDocumentsPortfolio,
    };
  },
  [SET_REQUEST_PORTFOLIO_RETRY]: (state, action) => {
    let { requestPortfolioRetry } = action;
    return {
      ...state,
      requestPortfolioRetry,
    };
  },
  [SET_USER_BY_ID]: (state, action) => {
    let { userById } = action;
    return {
      ...state,
      userById
    }
  },
  [SET_REQUEST_USER_BY_ID]: (state, action) => {
    let { requestUserById } = action;
    return {
      ...state,
      requestUserById
    }
  },
  [SET_CLIENTE]: (state, action) => {
    let { cliente } = action;
    return {
      ...state,
      cliente
    }
  },
  [SET_REQUEST_GET_CLIENTE]: (state, action) => {
    let { requestGetCliente } = action;
    return {
      ...state,
      requestGetCliente
    }
  },
  [REQUEST_UPDATE_PROPIEDAD]: (state, action) => {
    let { requestUpdatePropiedad } = action;
    return {
      ...state,
      requestUpdatePropiedad
    }
  },
  [SET_ACTIVIDAD]: (state, action) => {
    let { actividad } = action;
    return {
      ...state,
      actividad
    }
  },
  [REQUEST_GET_ACTIVIDAD]: (state, action) => {
    let { requestGetActividad } = action;
    return {
      ...state,
      requestGetActividad
    }
  },
  [SET_ACTIVIDADES]: (state, action) => {
    let { actividades } = action;
    return {
      ...state,
      actividades
    }
  },
  [REQUEST_GET_ACTIVIDADES]: (state, action) => {
    let { requestGetActividades } = action;
    return {
      ...state,
      requestGetActividades
    }
  },
  [REQUEST_POST_ACTIVIDAD]: (state, action) => {
    let { requestPostActividad } = action;
    return {
      ...state,
      requestPostActividad
    }
  },
  [REQUEST_PUT_ACTIVIDAD]: (state, action) => {
    let { requestPutActividad } = action;
    return {
      ...state,
      requestPutActividad
    }
  },
  [SET_CLIENTES]: (state, action) => {
    let { clientes } = action;
    return {
      ...state,
      clientes
    }
  },
  [REQUEST_GET_CLIENTES]: (state, action) => {
    let { requestGetClientes } = action;
    return {
      ...state,
      requestGetClientes
    }
  },
  [SET_USUARIO_BY_FILTER]: (state, action) => {
    let { usuarioByFilter } = action;
    return {
      ...state,
      usuarioByFilter
    }
  },
  [REQUEST_GET_USUARIO_BY_EMAIL]: (state, action) => {
    let { requestGetUsuarioByEmail } = action;
    return {
      ...state,
      requestGetUsuarioByEmail
    }
  },
  [SET_PROSPECTOS_VA]: (state, action) => {
    let { prospectosVA } = action;
    return {
      ...state,
      prospectosVA
    }
  },
  [SET_PROSPECTOS_CA]: (state, action) => {
    let { prospectosCA } = action;
    return {
      ...state,
      prospectosCA
    }
  },
  [SET_PROSPECTO]: (state, action) => {
    let { prospecto } = action;
    return {
      ...state,
      prospecto
    }
  },
  [REQUEST_GET_PROSPECTOS_VA]: (state, action) => {
    let { requestGetProspectosVA } = action;
    return {
      ...state,
      requestGetProspectosVA
    }
  },
  [REQUEST_GET_PROSPECTOS_CA]: (state, action) => {
    let { requestGetProspectosCA } = action;
    return {
      ...state,
      requestGetProspectosCA
    }
  },
  [REQUEST_POST_PROSPECTO]: (state, action) => {
    let { requestPostProspecto } = action;
    return {
      ...state,
      requestPostProspecto
    }
  },
  [REQUEST_GET_PROSPECTO]: (state, action) => {
    let { requestGetProspecto } = action;
    return {
      ...state,
      requestGetProspecto
    }
  },
  [SET_REQUERIMIENTOS]: (state, action) => {
    let { requerimientos } = action;
    return {
      ...state,
      requerimientos
    }
  },
  [REQUEST_GET_REQUERIMIENTOS]: (state, action) => {
    let { requestGetRequerimientos } = action;
    return {
      ...state,
      requestGetRequerimientos
    }
  },
  [SET_REQUERIMIENTO]: (state, action) => {
    let { requerimiento } = action;
    return {
      ...state,
      requerimiento
    }
  },
  [REQUEST_POST_REQUERIMIENTO]: (state, action) => {
    let { requestPostRequerimiento } = action;
    return {
      ...state,
      requestPostRequerimiento
    }
  },
  [REQUEST_UPDATE_PROSPECTO]: (state, action) => {
    let { requestUpdateProspecto } = action;
    return {
      ...state,
      requestUpdateProspecto
    }
  },
  [REQUEST_PUT_REQUERIMIENTO]: (state, action) => {
    let { requestPutRequerimiento } = action;
    return {
      ...state,
      requestPutRequerimiento
    }
  },
  [REQUEST_ADD_PROPIEDAD]: (state, action) => {
    let { requestAddPropiedad } = action;
    return {
      ...state,
      requestAddPropiedad
    }
  },
  [SET_DOCUMENT]: (state, action) => {
    let { document } = action;
    return {
      ...state,
      document
    }
  },
  [REQUEST_GENERATE_DOCUMENT]: (state, action) => {
    let { requestGenerateDocument } = action;
    return {
      ...state,
      requestGenerateDocument
    }
  },
  [SET_RESULTADO_TASACION]: (state, action) => {
    let { resultadoTasacion } = action;
    return {
      ...state,
      resultadoTasacion
    }
  },
  [SET_REQUEST_TASACION]: (state, action) => {
    let { requestStateTasacion } = action;
    return {
      ...state,
      requestStateTasacion
    }
  },
  [SET_PROPIEDADES_SIMILARES_TASACION]: (state, action) => {
    let { propiedadesSimilaresTasacion } = action;
    return {
      ...state,
      propiedadesSimilaresTasacion
    }
  },
  [SET_REQUEST_PROPIEDADES_SIMILARES_TASACION]: (state, action) => {
    let { requestStatePropiedadesSimilaresTasacion } = action;
    return {
      ...state,
      requestStatePropiedadesSimilaresTasacion
    }
  },
  [SET_VALORES_PRELIMINARES_TASACION]: (state, action) => {
    let { valoresPreliminaresTasacion } = action;
    return {
      ...state,
      valoresPreliminaresTasacion
    }
  },
  [SET_REQUEST_VALORES_PRELIMINARES_TASACION]: (state, action) => {
    let { requestStateValoresPreliminaresTasacion } = action;
    return {
      ...state,
      requestStateValoresPreliminaresTasacion
    }
  },


  [SET_REPORTE]: (state, action) => {
    let { reporte } = action;
    return {
      ...state,
      reporte
    }
  },
  [SET_REPORTES]: (state, action) => {
    let { reportes } = action;
    return {
      ...state,
      reportes
    }
  },
  [REQUEST_GET_REPORTES]: (state, action) => {
    let { requestGetReportes } = action;
    return {
      ...state,
      requestGetReportes
    }
  },
  [REQUEST_POST_REPORTE]: (state, action) => {
    let { requestPostReporte } = action;
    return {
      ...state,
      requestPostReporte
    }
  },
  [SET_COMUNAS]: (state, action) => {
    let { comunas } = action;
    return {
      ...state,
      comunas
    }
  },
  [REQUEST_DELETE_PROSPECTO]: (state, action) => {
    let { requestDeleteProspecto } = action;
    return {
      ...state,
      requestDeleteProspecto
    }
  },
  [SET_GRAFICA]: (state, action) => {
    let { grafica } = action;
    return {
      ...state,
      grafica
    }
  },
  [SET_GRAFICAS]: (state, action) => {
    let { graficas } = action;
    return {
      ...state,
      graficas
    }
  },
  [REQUEST_GET_GRAFICA]: (state, action) => {
    let { requestGetGrafica } = action;
    return {
      ...state,
      requestGetGrafica
    }
  },
  [REQUEST_GET_GRAFICAS]: (state, action) => {
    let { requestGetGraficas } = action;
    return {
      ...state,
      requestGetGraficas
    }
  },
  [REQUEST_POST_GRAFICA]: (state, action) => {
    let { requestPostGrafica } = action;
    return {
      ...state,
      requestPostGrafica
    }
  },
  [REQUEST_PUT_GRAFICA]: (state, action) => {
    let { requestPutGrafica } = action;
    return {
      ...state,
      requestPutGrafica
    }
  },  
  [SET_CAPACITACION]: (state, action) => {
    let { capacitacion } = action;
    return {
      ...state,
      capacitacion
    }
  },
  [SET_CAPACITACIONES]: (state, action) => {
    let { capacitaciones } = action;
    return {
      ...state,
      capacitaciones
    }
  },
  [REQUEST_GET_CAPACITACION]: (state, action) => {
    let { requestGetCapacitacion } = action;
    return {
      ...state,
      requestGetCapacitacion
    }
  },
  [REQUEST_GET_CAPACITACIONES]: (state, action) => {
    let { requestGetCapacitaciones } = action;
    return {
      ...state,
      requestGetCapacitaciones
    }
  },  
  [REQUEST_POST_CAPACITACION]: (state, action) => {
    let { requestPostCapacitacion } = action;
    return {
      ...state,
      requestPostCapacitacion
    }
  },  
  [REQUEST_PUT_CAPACITACION]: (state, action) => {
    let { requestPutCapacitacion } = action;
    return {
      ...state,
      requestPutCapacitacion
    }
  },  
  [REQUEST_DELETE_CAPACITACION]: (state, action) => {
    let { requestDeleteCapacitacion } = action;
    return {
      ...state,
      requestDeleteCapacitacion
    }
  },  
  [REQUEST_SOLICITAR_CANJE]: (state, action) => {
    let { requestSolicitarCanje } = action;
    return {
      ...state,
      requestSolicitarCanje
    }
  },  
  [SET_PREVIEW_DOCUMENT]: (state, action) => {
    let { previewDocument } = action;
    return {
      ...state,
      previewDocument
    }
  },  
  [REQUEST_PREVIEW_DOCUMENT]: (state, action) => {
    let { requestPreviewDocument } = action;
    return {
      ...state,
      requestPreviewDocument
    }
  },    
  [SET_EXPORT_TASACION]: (state, action) => {
    let { exportTasacion } = action;
    return {
      ...state,
      exportTasacion
    }
  }, 
  [REQUEST_EXPORT_TASACION]: (state, action) => {
    let { requestExportTasacion } = action;
    return {
      ...state,
      requestExportTasacion
    }
  },       
  [FICHA_PROPIEDAD]: (state, action) => {
    let { fichaPropiedad } = action;
    return {
      ...state,
      fichaPropiedad
    }
  }, 
  [REQUEST_DESCARGA_FICHA_PROPIEDAD]: (state, action) => {
    let { requestDescargaFichaPropiedad } = action;
    return {
      ...state,
      requestDescargaFichaPropiedad
    }
  },
  [SET_REUNIONES]: (state, action) => {
    let { reuniones } = action;
    return {
      ...state,
      reuniones
    }
  }, 
  [REQUEST_GET_REUNIONES]: (state, action) => {
    let { requestGetReuniones } = action;
    return {
      ...state,
      requestGetReuniones
    }
  }, 
  [REQUEST_ACTUALIZAR_REUNION]: (state, action) => {
    let { requestActualizarReunion } = action;
    return {
      ...state,
      requestActualizarReunion
    }
  }, 
  [SET_PROYECTO]: (state, action) => {
    let { proyecto } = action;
    return {
      ...state,
      proyecto
    }
  }, 
  [SET_PROYECTOS]: (state, action) => {
    let { proyectos } = action;
    return {
      ...state,
      proyectos
    }
  }, 
  [REQUEST_GET_PROYECTO]: (state, action) => {
    let { requestGetProyecto } = action;
    return {
      ...state,
      requestGetProyecto
    }
  }, 
  [REQUEST_GET_PROYECTOS]: (state, action) => {
    let { requestGetProyectos } = action;
    return {
      ...state,
      requestGetProyectos
    }
  }, 
  [REQUEST_ADD_INMOBILIARIA]: (state, action) => {
    let { requestAddInmobiliaria } = action;
    return {
      ...state,
      requestAddInmobiliaria
    }
  }, 
  [REQUEST_PUT_INMOBILIARIA]: (state, action) => {
    let { requestPutInmobiliaria } = action;
    return {
      ...state,
      requestPutInmobiliaria
    }
  }, 
  [REQUEST_UPLOAD_ARCHIVO_PROSPECTOS]: (state, action) => {
    let { requestUploadArchivoProspectos } = action;
    return {
      ...state,
      requestUploadArchivoProspectos
    }
  },   
  [SET_METAS]: (state, action) => {
    let { metas } = action;
    return {
      ...state,
      metas
    }
  }, 
  [REQUEST_GET_METAS]: (state, action) => {
    let { requestGetMetas } = action;
    return {
      ...state,
      requestGetMetas
    }
  }, 
  [REQUEST_UPLOAD_ARCHIVO_METAS]: (state, action) => {
    let { requestUploadArchivoMetas } = action;
    return {
      ...state,
      requestUploadArchivoMetas
    }
  }, 
  [SET_ASDF]: (state, action) => {
    let { asdf } = action;
    return {
      ...state,
      asdf
    }
  }, 
};

export default (state = initialState, action = {}) => {
  const fn = actionsMap[action.type];
  return fn ? fn(state, action) : state;
};
