import { Formik, Form, Field } from "formik";
import { useDispatch, useSelector } from "react-redux";
import { Backdrop, Box, Button, CircularProgress, Grid, Modal } from "@mui/material";
import { useEffect, useState } from "react";
import { updateUsuario } from "../../action";

const isMobile =
    !!navigator.userAgent.match(/iphone|android|blackberry/gi) || false;

export const UPLOAD_IMAGE_MIME_TYPES = [
  "image/png",
  "image/x-citrix-png",
  "image/x-png",
  "image/jpeg",
  "image/x-citrix-jpeg",
  "image/pjpeg",
];

const style = {
  position: "absolute",
  top: "0px",
  left: "50%",
  transform: "translate(-50%, 0%)",
  width: 500,
  bgcolor: "background.paper",
  boxShadow: 24,
  p: 4,
  //maxHeight: '90%'
};

const styleMobile = {
  position: "absolute",
  top: "0px",
  left: "0px",
  //transform: "translate(-50%, 0%)",
  width: "100vw",
  bgcolor: "background.paper",
  boxShadow: 24,
  p: 4,
  //maxHeight: '90%'
};

const ModalEditarDatosPersonales = ({ isOpen, handleClose, values }) => {
  const dispatch = useDispatch();
  const [isLoading, setIsLoading] = useState(false);
  const requestStateUpdate = useSelector((state) => state.app.requestUpdateUsuario);

  const handleSubmit = (formValues) => {
    const data = {        
        ...formValues,
        datosBancarios: values.datosBancarios,
      }      
      dispatch(updateUsuario(data));
  }

  useEffect(() => {
    if (requestStateUpdate === "LOADING") {
      setIsLoading(true);
    } else {
      setIsLoading(false);
    }
  }, [requestStateUpdate]);
  
  const [openBackdrop, setOpenBackdrop] = useState(false);
  const handleCloseBackdrop = () => {
    setOpenBackdrop(false);
  };

  return (
    <Modal
      disableScrollLock={false}
      open={isOpen}
      onClose={handleClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"   
      sx={{
        overflowY: "auto",
        top: isMobile ? "0px" : "10px",
        maxHeight: isMobile ? "100%" : "95%",
      }}
    >
      <Box sx={isMobile ? styleMobile : style}>
      {isLoading && (
        <Backdrop
          sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
          open={true}
          onClick={handleCloseBackdrop}
        >
          <CircularProgress color="inherit" />
        </Backdrop>
      )}
        <h4>Actualizar datos personales</h4>
        <Formik
          initialValues={{
            nombres: values && values.nombres,
            apellidos: values && values.apellidos,
            email: values && values.mail,
            telefono: values && values.telefono,
            rut: values && values.rut
          }}
          onSubmit={handleSubmit}
          >
            {({ 
              values,
              handleSubmit
             }) => (
            <Form onSubmit={handleSubmit}>
              <Grid container>
                <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                  <label className="milabel">Nombres</label>
                    <Field
                      name="nombres"
                      type="text"
                      className="miSelect"
                    />
                </Grid>
                <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                  <label className="milabel">Apellidos</label>
                    <Field
                      name="apellidos"
                      type="text"
                      className="miSelect"
                    />
                </Grid>
                <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                  <label className="milabel">Rut</label>
                    <Field
                      name="rut"
                      type="text"
                      className="miSelect"
                    />
                </Grid>
                <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                  <label className="milabel">Email</label>
                    <Field
                      name="email"
                      type="text"
                      className="miSelect"
                    />
                </Grid>
                <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                  <label className="milabel">Telefono</label>
                    <Field
                      name="telefono"
                      type="text"
                      className="miSelect"
                    />
                </Grid>
              </Grid>              
              <Grid container justifyContent="flex-end" sx={{mt: 2}}>
                <Grid item>
                  <Button onClick={handleClose} className="main-btn">
                    Cancelar
                  </Button>
                </Grid>
                <Grid item>
                  <Button type="submit" className="main-btn" variant="contained" color="success">
                    Guardar
                  </Button>
                </Grid>
              </Grid>
            </Form>
          )}
        </Formik>
      </Box>
    </Modal>
  );
};

export default ModalEditarDatosPersonales;
