import { Close, Error } from "@mui/icons-material";
import EditIcon from "@mui/icons-material/Edit";
import { Backdrop, Box, Button, CircularProgress, Grid, IconButton, Modal, Paper, Tooltip } from "@mui/material";
import { Field, Form, Formik } from "formik";
import { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getCliente, getUsuarioByEmail, updateUsuario } from "../../action";
import Swal from 'sweetalert2'

const isMobile =
    !!navigator.userAgent.match(/iphone|android|blackberry/gi) || false;

const style = {
  position: "absolute",
  top: "0px",
  left: "50%",
  transform: "translate(-50%, 0%)",
  width: 500,
  bgcolor: "background.paper",
  boxShadow: 24,
  p: 4,
  //maxHeight: '90%'
};

const styleMobile = {
  position: "absolute",
  top: "0px",
  left: "0px",
  //transform: "translate(-50%, 0%)",
  width: "100vw",
  bgcolor: "background.paper",
  boxShadow: 24,
  p: 4,
  //maxHeight: '90%'
};

const InfoPerfil = ({idCliente}) => {
  const dispatch = useDispatch();
  const requestState = useSelector((state) => state.app.requestGetCliente);
  const requestStateUsuario = useSelector((state) => state.app.requestGetUsuarioByEmail);
  const requestStateUpdate = useSelector((state) => state.app.requestUpdateUsuario);
  const errorMessage = useSelector((state) => state.app.errorMessage);
  const cliente = useSelector((state) => state.app.cliente);
  const usuario = useSelector((state) => state.app.usuarioByFilter);
  const [isOpen, setIsOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  useEffect(() => {
    dispatch(getCliente(idCliente));
  }, [idCliente]);
  useEffect(() => {
    dispatch(getUsuarioByEmail(cliente.mail));
  }, [cliente]);

  useEffect(() => {
    if (requestStateUpdate === "LOADING") {
      setIsLoading(true);
    } else {
      setIsLoading(false);
    }
  }, [requestStateUpdate]);

  const handleCloseBackdrop = () => {
    setIsOpen(false);
  }

  const handleSubmit = (values) => {
    const data = {
      ...values,
      datosBancarios: {
        banco: usuario.Banco,
        tipoCuenta: usuario.TipoCuentaBancaria,
        numeroCuenta: usuario.NumeroCuenta,
        medioPago: usuario.MedioPago
      }
    }
    dispatch(updateUsuario(data));
  }

  if (requestStateUpdate === "SUCCESS") {
    Swal.fire({
      title: "Propietario actualizado",
      text: "Se han actualizado los datos del propietario exitosamente",
      icon: "success"
    }).then((result) => {
      if (result.value || result.dismiss === "backdrop") {
        handleCloseBackdrop();
        dispatch(getCliente(idCliente));
        dispatch(getUsuarioByEmail(cliente.mail));
      }
    });
  }
  if (requestStateUpdate === "ERROR") {
    Swal.fire({
      title: "Error",
      text: errorMessage,
      icon: "error"
    });
  }
  
  if (requestState === "ERROR") {
    return (
      <Grid
        container
        sx={{mt: 2}}
        justifyContent="center"
        alignItems="center"
      >
        <Grid item xs={6} sm={6} md={4} lg={4} xl={4}>
          <Paper variant="outlined">
            <Error sx={{ fontSize: 40 }} />
            Ha habido un error
          </Paper>
        </Grid>
      </Grid>
    )
  }
  if (requestState === "LOADING" || requestStateUsuario === "LOADING") {
    return (<CircularProgress />)
  }
  if (requestState === "IDLE" && requestStateUsuario === "IDLE" && cliente && usuario) {
    return (
      <Grid container justifyContent={"flex-start"} className="alignLeft">

        <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
          <Grid container justifyContent={"flex-start"} alignItems="flex-start" className="alignLeft">
            <Grid item>
              <h3>Info propietario</h3>
            </Grid>
            <Grid item>
              <Tooltip title="Editar" aria-label="editar">
                <IconButton
                  color="primary"
                  aria-label="editar"
                  component="span"
                  onClick={() => setIsOpen(true)}
                >
                  <EditIcon />
                </IconButton>
              </Tooltip>
            </Grid>
          </Grid>
        </Grid>
        
        <Grid item xs={6} sm={6} md={4} lg={3} xl={2}>
          <h5>Nombre</h5>
            {usuario.nombres} {usuario.apellidos}
        </Grid>
        <Grid item xs={6} sm={6} md={4} lg={3} xl={2}>
          <h5>Tipo</h5>
          <p>{usuario.esPersonaJuridica ? "Persona jurídica" : "Persona natural"}</p>
        </Grid>
        <Grid item xs={6} sm={6} md={4} lg={3} xl={2}>
          <h5>Rut</h5>
          <p>{usuario.rut}</p>
        </Grid>
        <Grid item xs={6} sm={6} md={4} lg={3} xl={2}>
          <h5>Teléfono</h5>
          <p>{usuario.telefono}</p>
        </Grid>
        <Grid item xs={12} sm={12} md={4} lg={3} xl={3}>
          <h5>Correo electrónico</h5>
          <p>{usuario.email}</p>
        </Grid>
        
        <Modal
          disableScrollLock={false}
          open={isOpen}
          onClose={handleCloseBackdrop}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"   
          sx={{
            overflowY: "auto",
            top: isMobile ? "0px" : "10px",
            maxHeight: isMobile ? "100%" : "95%",
          }}
        >
          <Box sx={isMobile ? styleMobile : style}>
          {isLoading && (
            <Backdrop
              sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
              open={true}
              //onClick={handleClose}
            >
              <CircularProgress color="inherit" />
            </Backdrop>
          )}
            <Grid container justifyContent="space-between" alignItems={"center"}>
              <Grid item xs={10} sm={10} md={10} lg={10} xl={10}>
                <h4>Editar propietario</h4>
              </Grid>
              <Grid item>
                <IconButton
                  color="primary"
                  aria-label="copiar"
                  component="span"
                  onClick={() => handleCloseBackdrop()}
                >
                  <Close sx={{ fontSize: "20pt" }} />
                </IconButton>
              </Grid>
            </Grid>
            
            <Formik
              initialValues={{
                fechaNacimiento: usuario.fechaNacimiento,
                tipoCuenta: usuario.tipoCuenta,
                apellidos: usuario.apellidos,
                direccion: usuario.direccion,
                estadoCivil: "",
                nacionalidad: "",
                nombres: usuario.nombres,
                oficio: usuario.oficio,
                inmobiliariaId: usuario.inmobiliariaId,
                proyectosInmobiliariosId: usuario.proyectosInmobiliariosId,
                rut: usuario.rut,
                telefono: usuario.telefono,
                idString: usuario.id,
                email: usuario.email,
                /*
                datosBancarios: {
                  banco: values.Banco,
                  tipoCuenta: values.TipoCuentaBancaria,
                  numeroCuenta: values.NumeroCuenta,
                  medioPago: values.MedioPago
                }
                */
              }}
              onSubmit={handleSubmit}
              >
                {({ 
                  values,
                  handleSubmit
                }) => (
                <Form onSubmit={handleSubmit}>
                  <Grid container >
                    <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                      <label className="milabel">Nombres</label>
                      <Field
                        name="nombres"
                        type="text"
                        className="miSelect"
                      />
                    </Grid>
                  </Grid>
                  <Grid container>
                    <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                      <label className="milabel">Apellidos</label>
                      <Field
                        name="apellidos"
                        type="text"
                        className="miSelect"
                      />
                    </Grid>
                  </Grid>
                  <Grid container justifyContent="flex-start">
                    <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                      <label className="milabel">Rut</label>
                      <Field
                        name="rut"
                        type="text"
                        className="miSelect"
                      />
                    </Grid>
                  </Grid>
                  <Grid container justifyContent="flex-start">
                    <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                      <label className="milabel">Correo electrónico</label>
                      <Field
                        name="email"
                        type="text"
                        className="miSelect"
                      />
                    </Grid>
                  </Grid>
                  
                  <Grid container justifyContent="flex-start">
                    <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                      <label className="milabel">Teléfono</label>
                      <Field
                        name="telefono"
                        type="text"
                        className="miSelect"
                      />
                    </Grid>
                  </Grid>
                  <Grid container justifyContent="flex-end" sx={{mt: 2}}>
                    <Grid item>
                      <Button onClick={handleCloseBackdrop} className="main-btn">
                        Cancelar
                      </Button>
                    </Grid>
                    <Grid item>
                      <Button type="submit" className="main-btn" variant="contained" color="success">
                        Guardar
                      </Button>
                    </Grid>
                  </Grid>

                </Form>
              )}
            </Formik>
          </Box>
        </Modal>
      </Grid>
    )
  }
};

export default InfoPerfil;
