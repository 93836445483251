import { useEffect, useState } from "react";
import { Calendar, Views, momentLocalizer } from "react-big-calendar";
import "react-big-calendar/lib/css/react-big-calendar.css";
import { useDispatch, useSelector } from "react-redux";
import {
  getPropiedadesPaginadas,
  getProspectos,
  getReuniones,
  getUsuariosFiltrados,
  getVisitasFiltradas,
} from "../../action";
import moment from "moment";
import FormAgendarVisita from "./FormAgendarVisita";
import { CircularProgress, Grid } from "@mui/material";
import CeldaCalendario from "./CeldaCalendario";
import FormIniciarCapacitacion from "./FormIniciarCapacitacion";
import { Field, Form, Formik } from "formik";

moment.locale("es");

const messages = {
  week: "Semana",
  work_week: "Semana de trabajo",
  day: "Día",
  month: "Mes",
  previous: "Anterior",
  next: "Siguiente",
  today: "Hoy",
  agenda: "Diario",

  showMore: (total) => `+${total} más`,
};

const localizer = momentLocalizer(moment);

const isMobile =
  !!navigator.userAgent.match(/iphone|android|blackberry/gi) || false;

const Agenda = () => {
  const dispatch = useDispatch();
  const [isLoading, setIsLoading] = useState(false);
  const [events, setEvents] = useState([]);
  const [currentEvent, setCurrentEvent] = useState(null);
  const [openAgendarModal, setOpenAgendarModal] = useState(false);
  const agendarVisita = () => {
    setCurrentEvent(null);
    setOpenAgendarModal(true);
  };
  const handleCloseAgendarModal = () => setOpenAgendarModal(false);
  const [openIniciarCapacitacionModal, setOpenIniciarCapacitacionModal] =
    useState(false);
  const handleCloseIniciarCapacitacionModal = () =>
    setOpenIniciarCapacitacionModal(false);
  const brokers = useSelector((state) => state.app.usuariosFiltrados);
  const userData = useSelector((state) => state.app.itemUsuario);
  const visitas = useSelector((state) => state.app.visitasBrokerSuscriptor);
  const requestVisitasBrokerSuscriptor = useSelector(
    (state) => state.app.requestVisitasBrokerSuscriptor
  );
  const reuniones = useSelector((state) => state.app.reuniones);
  const requestGetReuniones = useSelector(
    (state) => state.app.requestGetReuniones
  );
  const requestUsuarioFiltrados = useSelector(
    (state) => state.app.requestUsuarioFiltrados
  );

  const idBroker = userData ? userData.userId : "";
  useEffect(() => {
    setEvents([]);
    dispatch(getUsuariosFiltrados(10));
    dispatch(
      getVisitasFiltradas(
        "",
        "",
        userData && userData.tipoCuenta === 10 ? userData.userId : "",
        "",
        false,
        false,
        true,
        true
      )
    );
    dispatch(getProspectos("ca", 0, 999, "", "", "", 0, 0));
    dispatch(getReuniones(idBroker));
    dispatch(
      getPropiedadesPaginadas(
        "",
        userData && userData.tipoCuenta === 10 ? userData.userId : "",
        1,
        999,
        true,
        "createdAtDescending",
        "",
        "",
        "",
        0,
        0,
        0,
        0,
        0,
        0,
        0,
        0,
        0,
        0,
        "",
        "",
        ""
      )
    );
  }, []);

  useEffect(() => {
    if (
      requestGetReuniones === "LOADING" ||
      requestVisitasBrokerSuscriptor === "LOADING"
    ) {
      setIsLoading(true);
    } else {
      setIsLoading(false);
    }
  }, [
    requestGetReuniones,
    requestVisitasBrokerSuscriptor,
    requestUsuarioFiltrados,
  ]);

  useEffect(() => {
    let visitasList = [];
    visitas &&
      visitas.map((v) => {
        if (v.fecha !== null) {
          let fechaSinTiempo = v.fecha.split(" ")[0];
          let fechaPartes = fechaSinTiempo.split("-");
          let dia = fechaPartes[0];
          let mes = fechaPartes[1] - 1;
          let anio = fechaPartes[2];
          if (anio.length === 2) {
            anio = `20${anio}`;
          }
          let tramoInicial = v.tramo && v.tramo.split("-")[0];
          let partesTramoInicial = tramoInicial && tramoInicial.split(":");
          let horaTramoInicial = partesTramoInicial[0];
          let minutosTramoInicial = partesTramoInicial[1];
          let fecha = new Date(
            anio,
            mes,
            dia,
            horaTramoInicial,
            minutosTramoInicial
          );

          if (!visitasList.some((e) => e.id == v.id)) {
            visitasList.push({
              id: v.id,
              title: `${v.tramo} hrs Visita propiedad ${v.propiedadDireccion}`,
              start: fecha,
              end: fecha,
              tipo: "visita",
              horaVisita: v.tramo,
              idPropiedad: v.propiedadId,
              idProspecto: v.idProspecto,
            });
          }
        }
      });

    const soloCapacitaciones = events.filter((e) => e.tipo == "reu");
    const nuevaListaEventos = visitasList.concat(soloCapacitaciones);

    setEvents(nuevaListaEventos);
  }, [visitas]);

  useEffect(() => {
    let reunionesList = [];
    reuniones &&
      reuniones.map((v) => {
        if (v.fecha !== null) {
          const title = v && v.titulo ? v.titulo : "Capacitación";
          let fechaSinTiempo = v.fecha.split(" ")[0];
          let fechaPartes = fechaSinTiempo.split("-");
          let dia = fechaPartes[0];
          let mes = fechaPartes[1] - 1;
          let anio = fechaPartes[2];
          if (anio.length === 2) {
            anio = `20${anio}`;
          }
          let tramoInicial = v.tramo && v.tramo.split("-")[0];
          let partesTramoInicial = tramoInicial && tramoInicial.split(":");
          let horaTramoInicial = partesTramoInicial[0];
          let minutosTramoInicial = partesTramoInicial[1];
          let fecha = new Date(
            anio,
            mes,
            dia,
            horaTramoInicial,
            minutosTramoInicial
          );
          if (!reunionesList.some((e) => e.id == v.id)) {
            reunionesList.push({
              id: v.id,
              title: `${v.tramo} hrs ${title}`,
              start: fecha,
              end: fecha,
              titulo: v.titulo,
              horaVisita: v.tramo,
              tipo: "reu",
              link: v.link,
              brokers: v.brokers,
              idCapacitacion: v.idCapacitacion,
            });
          }
        }
      });
    const soloVisitas = events.filter((e) => e.tipo == "visita");
    const nuevaListaEventos = reunionesList.concat(soloVisitas);

    setEvents(nuevaListaEventos);
  }, [reuniones]);

  const reloadData = () => {
    setEvents([]);
    dispatch(
      getVisitasFiltradas("", "", idBroker, "", false, false, true, true)
    );
    dispatch(getReuniones(idBroker));
  };

  const handleSelectEvent = (e) => {
    //userData && userData.tipoCuenta !== 10
    if (e.tipo === "visita") {
      setCurrentEvent(e);
      setOpenAgendarModal(true);
    } else {
      if (userData.tipoCuenta !== 10) {
        setCurrentEvent(e);
        setOpenIniciarCapacitacionModal(true);
      }
    }
  };

  const iniciarCapacitacion = () => {
    setCurrentEvent(null);
    setOpenIniciarCapacitacionModal(true);
  };

  const filtrarBroker = (formValues) => {
    dispatch(getReuniones(formValues.idBroker));
    dispatch(
      getVisitasFiltradas(
        "",
        "",
        formValues.idBroker,
        "",
        false,
        false,
        true,
        true
      )
    );
  };

  return (
    <div className="contenido" style={{width: '30%'}}>
      <Grid sx={{ mt: 2 }} container justifyContent={"space-between"}>
        <Grid item className="alignLeft">
          <h2>Agenda</h2>
        </Grid>
        <Grid item>
          <Grid container spacing={2} justifyContent={"space-between"}>
            <Grid item>
              {/*
<Tooltip title="Reload" aria-label="reload">
            <IconButton
              color="primary"
              aria-label="reload"
              component="span"
              onClick={() => reloadData()}
            >
              <Replay sx={{ fontSize: "20pt" }} />
            </IconButton>
            </Tooltip>
                */}
            </Grid>
            {userData && userData.tipoCuenta !== 10 && (
              <Grid item>
                <button className="boton" onClick={() => iniciarCapacitacion()}>
                  Iniciar capacitación
                </button>
              </Grid>
            )}
            <Grid item>
              <button className="boton" onClick={() => agendarVisita()}>
                Agregar visita propiedad +
              </button>
            </Grid>
          </Grid>
        </Grid>
        {userData && userData.tipoCuenta !== 10 && (
          <Grid container justifyContent={"flex-start"}>
            <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
              <Formik
                initialValues={{
                  idBroker: "",
                }}
                onSubmit={filtrarBroker}
              >
                {({ handleSubmit }) => (
                  <Form onSubmit={handleSubmit}>
                    <Grid
                      container
                      spacing={1}
                      justifyContent={"flex-start"}
                      alignItems={"center"}
                    >
                      {userData && userData.tipoCuenta !== 10 && (
                        <Grid item xs={12} sm={8} md={6} lg={4} xl={4}>
                          <label className="milabel">Broker</label>
                          <Field
                            name="idBroker"
                            as="select"
                            className="miSelect"
                          >
                            <option value={""}>- Seleccione -</option>
                            {brokers &&
                              brokers.length > 0 &&
                              brokers.map((c) => (
                                <option value={c.id}>
                                  {c.nombres} {c.apellidos}
                                </option>
                              ))}
                          </Field>
                        </Grid>
                      )}
                      <Grid
                        item
                        xs={12}
                        sm={12}
                        md={12}
                        lg={12}
                        xl={12}
                        sx={{ textAlign: "left" }}
                      >
                        <button
                          type="submit"
                          className="boton2"
                          style={{ margin: "10px" }}
                        >
                          Filtrar
                        </button>
                      </Grid>
                    </Grid>
                  </Form>
                )}
              </Formik>
            </Grid>
          </Grid>
        )}
      </Grid>
      {isLoading && <CircularProgress />}
      {!isLoading && (
        <Calendar
          selectable
          onSelectEvent={(e) => handleSelectEvent(e)}
          //onSelectSlot={handleSelectSlot}
          components={{ month: { event: CeldaCalendario } }}
          localizer={localizer}
          events={events}
          startAccessor="start"
          endAccessor="end"
          style={{ height: 650 }}
          messages={messages}
          culture={"es"}
          min={new Date(1972, 0, 1, 8, 0, 0)}
          max={new Date(1972, 0, 1, 21, 0, 0)}
          defaultView={isMobile ? Views.AGENDA : Views.MONTH}
        />
      )}
      <FormIniciarCapacitacion
        reloadMethod={reloadData}
        isOpen={openIniciarCapacitacionModal}
        handleClose={handleCloseIniciarCapacitacionModal}
        values={currentEvent}
      />
      <FormAgendarVisita
        reloadMethod={reloadData}
        idBroker={idBroker}
        isOpen={openAgendarModal}
        handleClose={handleCloseAgendarModal}
        values={currentEvent}
      />
    </div>
  );
};

export default Agenda;
