import { Button, CircularProgress, Grid } from "@mui/material";
import { Field, Form, Formik } from "formik";
import { useEffect } from "react";
import { useState } from "react";
import DatePicker from "react-date-picker";
import { useSelector } from "react-redux";
import { TRAMOS } from "../../utils/constants";
import FirmantesForm from "./FirmantesForm";

export const FormGenerarOrdenVisita = ({
  idPropiedad,
  idProspecto,
  handleSubmit,
  handleClose,
    //datosAlvaro,
  //guardarDatosAlvaro,
  //editDatosAlvaro,
  //setEditDatosAlvaro,
  onChangePropiedad,
  onChangeIdProspecto,
  prospecto,
  validationMessages,
  fechaVisita,
  setFechaVisita,
  horaVisita,
  onChangeHoraVisita,
  firmante,
  setFirmante,
  handleDownload,
  tipoDocumento,
  usarDocumentosAlvaro,
  onChangeUsarDocumentosAlvaro
}) => {
  const [prospectoLocal, setProspectoLocal] = useState(firmante);
  const requestPropiedades = useSelector(
    (state) => state.app.requestPropiedadesPaginadas
  );
  const requestGetProspectos = useSelector(
    (state) => state.app.requestGetProspectosCA
  );
  const prospectos = useSelector(
    (state) => state.app.prospectosCA && state.app.prospectosCA.results
  );
  const propiedades = useSelector(
    (state) =>
      state.app.propiedadesPaginadas && state.app.propiedadesPaginadas.results
  );

  const submitLocal = (formValues) => {
    setFirmante({
      rut: prospecto.rut,
      nombres: prospecto.nombres,
      apellidos: prospecto.apellidos,
      email: prospecto.email,
      telefono: prospecto.telefono
    })
    handleSubmit(formValues);
  }

  return (
    <Formik
      initialValues={{
        idPropiedad,
        idProspecto,
        fecha: fechaVisita,
        hora: horaVisita,
        archivoVersoIdentidad: "",
        archivoReversoIdentidad: "",
        //archivoCertificadoDocumentoDominio: "",
        archivoVersoIdentidadPropins: "",
        archivoReversoIdentidadPropins: "",
      }}
      //validate={validate}
      onSubmit={submitLocal}
    >
      {({ isValid, values, errors, handleSubmit, setFieldValue }) => (
        <>
          <Form onSubmit={handleSubmit} className="form-container">
            <Grid container>
              {requestPropiedades === "LOADING" && <CircularProgress />}
              {requestPropiedades === "IDLE" && (
                <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                  <label className="milabel">Propiedad</label>
                  <Field
                    name="idPropiedad"
                    as="select"
                    className="miSelect"
                    value={idPropiedad}
                    onChange={onChangePropiedad}
                  >
                    <option value={""}>- Seleccione -</option>
                    {propiedades.map((c) => {
                      return (
                        <option value={c.id}>
                          {c.nombreCalle} {c.numero}
                        </option>
                      );
                    })}
                  </Field>
                </Grid>
              )}
              {requestGetProspectos === "LOADING" && <CircularProgress />}
              {requestGetProspectos === "IDLE" && (
                <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                  <label className="milabel">Prospecto comprador</label>
                  <Field
                    name="idProspecto"
                    as="select"
                    className="miSelect"
                    value={idProspecto}
                    onChange={onChangeIdProspecto}
                  >
                    <option value={""}>- Seleccione -</option>
                    {prospectos.map((c) => {
                      return (
                        <option value={c.id}>
                          {c.nombres} {c.apellidos}
                        </option>
                      );
                    })}
                  </Field>
                </Grid>
              )}
            </Grid>
            {idPropiedad && prospecto && (
              <FirmantesForm                
                setFieldValue={setFieldValue}
                //datosAlvaro={datosAlvaro}
                //editDatosAlvaro={editDatosAlvaro}
                //setEditDatosAlvaro={setEditDatosAlvaro}
                //guardarDatosAlvaro={guardarDatosAlvaro}
                firmante={firmante}
                setFirmante={setFirmante}
                editPath={`/prospectos/comprador/requerimientos?id=${prospecto.id}`}
                tipoDocumento={tipoDocumento}
                usarDocumentosAlvaro={usarDocumentosAlvaro}
                onChangeUsarDocumentosAlvaro={onChangeUsarDocumentosAlvaro}
              />
            )}

            <Grid container sx={{ mt: 2 }}>
              <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                <label className="milabel">Fecha visita</label>
                <DatePicker value={fechaVisita} onChange={setFechaVisita} />
              </Grid>
            </Grid>
            <Grid container>
              <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                <label className="milabel">Hora</label>
                <Field
                  name="horaVisita"
                  as="select"
                  className="miSelect"
                  onChange={onChangeHoraVisita}
                >
                  {TRAMOS.map((c) => (
                    <option value={c}>{c}</option>
                  ))}
                </Field>
              </Grid>
            </Grid>
            {validationMessages && validationMessages.length > 0 && (
              <Grid container sx={{ mt: 2 }}>
                <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                  Se han encontrado los siguientes problemas con la validación:
                </Grid>
                {validationMessages.map((v) => (
                  <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                    {v}
                  </Grid>
                ))}
              </Grid>
            )}

            <Grid container justifyContent="flex-end" sx={{ mt: 2 }}>
              <Grid item>
                <Button onClick={handleClose} className="main-btn">
                  Cancelar
                </Button>
              </Grid>
              {/*
              <Grid item>
                <Button
                  type="submit"
                  className="main-btn"
                  variant="contained"
                  color="success"
                  //disabled={!isValid}
                  onClick={() => isValid && submitLocal(values, true)}
                >
                  Descargar vista previa
                </Button>
              </Grid>
                */}
              
              <Grid item>
                <Button
                  type="submit"
                  className="main-btn"
                  variant="contained"
                  color="success"
                >
                  Guardar
                </Button>
              </Grid>
            </Grid>
          </Form>
        </>
      )}
    </Formik>
  );
};
