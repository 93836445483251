//TODO: Agregar campos datos bancarios
import {
  Grid,
} from "@mui/material";
import { Formik, Form, Field, ErrorMessage } from "formik";
import { useEffect } from "react";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import DatePicker from 'react-date-picker'
import * as Yup from "yup";
import FieldError from "../../utils/FieldError";

const validate = Yup.object().shape({
  nombres: Yup.string().required("Ingrese nombres"),
  apellidos: Yup.string().required("Ingrese apellidos"),
  rut: Yup.string().required("Ingrese rut"),
  email: Yup.string().email('Ingrese un correo electronico valido').required("Ingrese correo electronico"),
  telefono: Yup.string().min(8, 'Ingrese al menos 8 digitos').max(12, 'Ingrese maximo 12 digitos').required("Ingrese telefono"),
});

const style = {
  position: 'absolute',
  top: '30%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 600,
  bgcolor: 'background.paper',
  boxShadow: 24,
  p: 4,
};

const FormAgregarPropietario = ({ handleClose, reloadMethod, submitMethod, nextButton, initialValues }) => {
  const dispatch = useDispatch();
  const [isLoading, setIsLoading] = useState(false);
  const [fechaNacimiento, setFechaNacimiento] = useState(initialValues && initialValues.fechaNacimiento ? initialValues.fechaNacimiento : new Date());
  const request = useSelector((state) => state.app.requestPostProspecto);
  const regiones = useSelector((state) => state.app.itemRegiones && state.app.itemRegiones.length > 0 && state.app.itemRegiones[0].regiones);
  const [showBackdrop, setShowBackdrop] = useState(false);
  const handleShowBackdrop = () => {
    setShowBackdrop(false);
  };

  useEffect(() => {
    if (request === "LOADING") {
      setIsLoading(true);
    } else {
      setIsLoading(false);
    }
  }, [request]);  

  return (
      <Grid container sx={{mt: 2}} justifyContent="center">
        <Grid item>
        <Formik
          enableReinitialize
          initialValues={initialValues}
          onSubmit={submitMethod}
          validationSchema={validate}
        >
          {({ values, handleSubmit }) => (
            <Form onSubmit={handleSubmit}>
              <Grid container>
                <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                  <label className="milabel">Tipo persona</label>
                  <Field name="tipoContratoProspecto" as="select" className="miSelect">
                    <option value={""}>- Seleccione -</option>
                    <option value={0}>Persona natural</option>
                    <option value={1}>Persona juridica</option>
                  </Field>
                </Grid>
              </Grid>

              <Grid container spacing={1}>
                <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                  <label className="milabel">Nombres</label>
                  <Field name="nombres" type="text" className="miSelect" />
                  <FieldError name="nombres" />
                </Grid>
                <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                  <label className="milabel">Apellidos</label>
                  <Field name="apellidos" type="text" className="miSelect" />
                  <FieldError name="apellidos" />
                </Grid>
              </Grid>

              <Grid container spacing={1}>
                <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                  <label className="milabel">Rut</label>
                  <Field name="rut" type="text" className="miSelect" />
                  <FieldError name="rut" />
                </Grid>
                <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                  <label className="milabel">Fecha nacimiento</label>
                  <DatePicker value={fechaNacimiento} onChange={setFechaNacimiento} />
                </Grid>
              </Grid>

              <Grid container spacing={1}>
                <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                  <label className="milabel">Correo electronico</label>
                  <Field name="email" type="text" className="miSelect" />
                  <FieldError name="email" />
                </Grid>
                <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                  <label className="milabel">Telefono</label>
                  <Field name="telefono" type="text" className="miSelect" />
                  <FieldError name="telefono" />
                </Grid>
              </Grid>

              <Grid container justifyContent="flex-end" sx={{mt: 4}}>

                <Grid item>
                { nextButton }
                </Grid>
              
              </Grid>
                
            </Form>
          )}
        </Formik>
        </Grid>
      </Grid>
  );
};

export default FormAgregarPropietario;
