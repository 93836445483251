import { Delete } from "@mui/icons-material";
import {
  Button,
  CircularProgress,
  Divider,
  Grid,
  IconButton,
  Tooltip,
} from "@mui/material";
import { Field, Form, Formik } from "formik";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import FirmantesForm from "./FirmantesForm";

export const FormGenerarOrdenCanje = ({
  handleSubmit,
  handleClose,
  firmante,
  setFirmante,
  validationMessages,
  tipoDocumento,
  usarDocumentosAlvaro,
  onChangeUsarDocumentosAlvaro,
  validateSigner
}) => {
  const [idPropiedadSeleccionada, setIdPropiedadSeleccionada] = useState("");
  const [propiedadesSeleccionadas, setPropiedadesSeleccionadas] = useState([]);
  const onAddPropiedad = () => {
    if (idPropiedadSeleccionada !== "") {
      const prop = propiedades && propiedades.find(p => p.id === idPropiedadSeleccionada)
      if (!propiedadesSeleccionadas.some(p => p.id === idPropiedadSeleccionada)) {
        const direccionBase = `${prop.nombreCalle} ${prop.numero}`
        const direccion = prop.tipoPropiedad === "Departamento" ? `${direccionBase} departamento ${prop.numeroDepartamento}` : `${direccionBase}`;
        const obj = {
          direccion,
          id: prop.id,
          codigoPropiedad: prop.codigoPropiedad
        }
        setPropiedadesSeleccionadas([...propiedadesSeleccionadas, obj])
      }
    }    
  };
  const onRemovePropiedad = (prop) =>
    setPropiedadesSeleccionadas(
      propiedadesSeleccionadas.filter((p) => p.id !== prop.id)
    );
  const requestPropiedades = useSelector(
    (state) => state.app.requestPropiedadesPaginadas
  );
  const propiedades = useSelector(
    (state) =>
      state.app.propiedadesPaginadas && state.app.propiedadesPaginadas.results
  );

  useEffect(() => {
    if (firmante === null) {
      setFirmante({
        rut: "",
        nombres: "",
        apellidos: "",
        email: "",
        telefono: ""
      });
    }    
  }, []);

  const submitLocal = (formValues) => {
    formValues.propiedadesCanje = propiedadesSeleccionadas;
    handleSubmit(formValues);
  };

  const onChange = (e) => {
    setIdPropiedadSeleccionada(e.target.value);
  }
  console.log(validateSigner)

  return (
    <Formik
      initialValues={{
        representacionBrokerExterno: "",
        archivoVersoIdentidad: "",
        archivoReversoIdentidad: "",
        //archivoCertificadoDocumentoDominio: "",
        archivoVersoIdentidadPropins: "",
        archivoReversoIdentidadPropins: "",
      }}
      //validate={validate}
      onSubmit={submitLocal}
    >
      {({ values, errors, handleSubmit, setFieldValue }) => (
        <>
          <Form onSubmit={handleSubmit} className="form-container">
            {requestPropiedades === "LOADING" && <CircularProgress />}
            {requestPropiedades === "IDLE" && propiedades && (
              <Grid container>
                <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                  <Grid container spacing={2} alignItems="flex-end">
                    <Grid item xs={7} sm={7} md={7} lg={7} xl={7}>
                      <label className="milabel">Propiedad</label>
                      <Field
                        name="idPropiedad"
                        as="select"
                        className="miSelect"
                        //value={idPropiedad}
                        onChange={onChange}
                      >
                        <option value={""}>- Seleccione -</option>
                        {propiedades.map((c) => {
                          return (
                            <option value={c.id}>
                              {c.codigoPropiedad} - {c.nombreCalle} {c.numero}
                            </option>
                          );
                        })}
                      </Field>
                    </Grid>
                    <Grid item>
                      <Button
                        className="main-btn"
                        variant="contained"
                        color="success"
                        onClick={() => onAddPropiedad()}
                      >
                        Agregar propiedad
                      </Button>
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item xs={12} sm={12} md={12} lg={12} xl={12} sx={{mt: 2}}>
                  <h5 className="alignLeft">Propiedades seleccionadas</h5>
                </Grid>
                <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                  {propiedadesSeleccionadas.length === 0 && (<div>No has agregado ninguna propiedad todavia</div>)}
                  {propiedadesSeleccionadas.length > 0 &&
                    propiedadesSeleccionadas.map((p) => {
                    return (
                      <Grid container alignItems="center">
                        <Grid item>
                          {p && p.codigoPropiedad} - {p && p.direccion}
                        </Grid>
                        <Grid item>
                          <Tooltip title="Quitar" aria-label="quitar">
                            <IconButton
                              //color="primary"
                              aria-label="Quitar"
                              component="span"
                              onClick={() => onRemovePropiedad(p)}
                            >
                              <Delete />
                            </IconButton>
                          </Tooltip>
                        </Grid>
                      </Grid>
                    )})}
                </Grid>
                <Grid item xs={12} sm={12} md={12} lg={12} xl={12} sx={{mt:4}}>
                  <Divider
                    variant="middle"
                    sx={{ mb: 2, backgroundColor: "black" }}
                  />
                </Grid>
                <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>                  
                  <label className="milabel">Representacion broker externo</label>
                  <Field
                    name="representacionBrokerExterno"
                    type="text" className="miSelect"
                  />
                </Grid>
              </Grid>
            )}

            <FirmantesForm
              setFieldValue={setFieldValue}
//datosAlvaro={datosAlvaro}
                //editDatosAlvaro={editDatosAlvaro}
                //setEditDatosAlvaro={setEditDatosAlvaro}
                //guardarDatosAlvaro={guardarDatosAlvaro}
              firmante={firmante}
              setFirmante={setFirmante}
              allowEditFirmanteLocal={true}
              tipoDocumento={tipoDocumento}
              usarDocumentosAlvaro={usarDocumentosAlvaro}
              onChangeUsarDocumentosAlvaro={onChangeUsarDocumentosAlvaro}
              validateSigner={validateSigner}
            />

            {validationMessages && validationMessages.length > 0 && (
              <Grid container sx={{ mt: 2 }}>
                <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                  Se han encontrado los siguientes problemas con la validación:
                </Grid>
                {validationMessages.map((v) => (
                  <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                    {v}
                  </Grid>
                ))}
              </Grid>
            )}

            <Grid container justifyContent="flex-end" sx={{ mt: 2 }}>
              <Grid item>
                <Button onClick={handleClose} className="main-btn">
                  Cancelar
                </Button>
              </Grid>
              <Grid item>
                <Button
                  type="submit"
                  className="main-btn"
                  variant="contained"
                  color="success"
                >
                  Guardar
                </Button>
              </Grid>
            </Grid>
          </Form>
        </>
      )}
    </Formik>
  );
};
