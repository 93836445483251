import * as React from "react";
import Tooltip from "@mui/material/Tooltip";
import IconButton from "@mui/material/IconButton";
import TableCell from "@mui/material/TableCell";
import TableRow from "@mui/material/TableRow";
import Link from "@mui/material/Link";
import EditIcon from "@mui/icons-material/Edit";
import { ESTADOS_ACTIVIDAD } from "../../utils/constants";
import moment from "moment";
import { useState } from "react";
import { Menu, MenuItem } from "@mui/material";
import { alpha, styled } from "@mui/material/styles";
import { MoreVert, Visibility } from "@mui/icons-material";

const StyledMenu = styled((props) => (
  <Menu
    elevation={0}
    anchorOrigin={{
      vertical: "bottom",
      horizontal: "right",
    }}
    transformOrigin={{
      vertical: "top",
      horizontal: "right",
    }}
    {...props}
  />
))(({ theme }) => ({
  "& .MuiPaper-root": {
    borderRadius: 6,
    marginTop: theme.spacing(1),
    minWidth: 180,
    color:
      theme.palette.mode === "light"
        ? "rgb(55, 65, 81)"
        : theme.palette.grey[300],
    boxShadow:
      "rgb(255, 255, 255) 0px 0px 0px 0px, rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px",
    "& .MuiMenu-list": {
      padding: "4px 0",
    },
    "& .MuiMenuItem-root": {
      "& .MuiSvgIcon-root": {
        fontSize: 18,
        color: theme.palette.text.secondary,
        marginRight: theme.spacing(1.5),
      },
      "&:active": {
        backgroundColor: alpha(
          theme.palette.primary.main,
          theme.palette.action.selectedOpacity
        ),
      },
    },
  },
}));

export const FilaActividad = ({
  actividad,
  i,
  isMobile,
  getNombreUsuario,
  handleOpenEditModal,
  handleOpenDetailModal,
}) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const openMenu = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  if (isMobile) {
    return (
      <React.Fragment>
        <TableRow className={i % 2 === 0 ? "filaOscura" : ""} sx={{ "& > *": { borderBottom: "unset" } }}>          
        <TableCell>
            <div
              className={
                actividad.estadoActividad === 0
                  ? "confirmada"
                  : actividad.estadoActividad === 1
                  ? "pendiente"
                  : "cancelada"
              }
              style={{ textAlign: "center" }}
            >
              {ESTADOS_ACTIVIDAD[actividad.estadoActividad][0]}
            </div>
          </TableCell>
          <TableCell align="center">{actividad.titulo}</TableCell>
          <TableCell align="center">
            {actividad && moment(actividad.fechaActividad).format("DD-MM-YYYY")}
          </TableCell>
          <TableCell>
          <IconButton
              onClick={handleClick}
              //color="danger"
              aria-label="acciones"
              component="span"
            >
              <MoreVert />
            </IconButton>
            <StyledMenu
              id="demo-customized-menu"
              MenuListProps={{
                "aria-labelledby": "demo-customized-button",
              }}
              anchorEl={anchorEl}
              open={openMenu}
              onClose={handleClose}
            >
              <MenuItem
                disableRipple
                onClick={() => handleOpenDetailModal(actividad)}
              >
                <Visibility />
                Ver detalles
              </MenuItem>
              <MenuItem
                disableRipple
                onClick={() => handleOpenEditModal(actividad)}
              >
                <EditIcon />
                Editar
              </MenuItem>         
            </StyledMenu>
          </TableCell>
        </TableRow>        
      </React.Fragment>
    );
  } else {
    return (
      <React.Fragment>
        <TableRow className={i % 2 === 0 ? "filaOscura" : ""}>
          <TableCell>
            <div
              className={
                actividad.estadoActividad === 0
                  ? "confirmada"
                  : actividad.estadoActividad === 1
                  ? "pendiente"
                  : "cancelada"
              }
              style={{ textAlign: "center" }}
            >
              {ESTADOS_ACTIVIDAD[actividad.estadoActividad]}
            </div>
          </TableCell>
          <TableCell align="center">{actividad.titulo}</TableCell>
          <TableCell align="center">
            {moment(actividad.fechaActividad).format("DD-MM-YYYY")}
          </TableCell>
          <TableCell align="center">
            {getNombreUsuario(actividad.subidoPor)}
          </TableCell>
          <TableCell align="center">
            {actividad.archivos &&
            actividad.archivos.length > 0 &&
            actividad.archivos.some((x) => !x.deleted)
              ? "Si"
              : "No"}
          </TableCell>
          <TableCell align="center">
            <a onClick={() => handleOpenDetailModal(actividad)}>
              <Link className="link">Ver detalle</Link>
            </a>
          </TableCell>
          <TableCell align="center">
            <Tooltip title="Editar" aria-label="editar">
              <IconButton
                //color="primary"
                aria-label="Editar"
                component="span"
                onClick={() => handleOpenEditModal(actividad)}
              >
                <EditIcon />
              </IconButton>
            </Tooltip>
          </TableCell>
        </TableRow>
      </React.Fragment>
    );
  }
};
