import InfoProspecto from "../InfoProspecto";
import PropiedadProspecto from "./PropiedadProspecto";
import AddHomeOutlinedIcon from "@mui/icons-material/AddHomeOutlined";
import { useDispatch, useSelector } from "react-redux";
import { Link, useLocation } from "react-router-dom";
import Back from "../../../assets/Back";
import ProspectoVendedorNavegacionMobile from "./ProspectoVendedorNavegacionMobile";
import Swal from "sweetalert2";
import { useState } from "react";
import { getProspecto } from "../../../action";

const isMobile =
    !!navigator.userAgent.match(/iphone|android|blackberry/gi) || false;

const PerfilProspecto = () => {
  const dispatch = useDispatch();
  let { search } = useLocation();
  let query = new URLSearchParams(search);
  let perfil = query.get("id");
  const [isOpenEditDatosPersonales, setIsOpenEditDatosPersonales] = useState(false);
  const handleCloseEditDatosPersonales = () => setIsOpenEditDatosPersonales(false);
  const [isOpenEditDatosPropiedad, setIsOpenEditDatosPropiedad] = useState(false);
  const handleCloseEditDatosPropiedad = () => setIsOpenEditDatosPropiedad(false);
  const requestStateUpdate = useSelector(
    (state) => state.app.requestUpdateProspecto
  );

  if (requestStateUpdate === "SUCCESS") {
    Swal.fire({
      title: "Prospecto actualizado",
      text: "Se ha actualizado el prospecto exitosamente",
      icon: "success",
    }).then((result) => {
      if (result.value || result.dismiss === "backdrop") {
        reload();
        handleCloseEditDatosPersonales();
        handleCloseEditDatosPropiedad();
      }
    });
  }

  const reload = () => {
    dispatch(getProspecto("va", perfil));
  }

  return (
    <div className="contenido">
      <Back urlPath={"/prospectos/vendedor"} />
      <div className="header">
        <h2>Perfil prospecto vendedor/arrendador</h2>
        <Link
          to={`/propietarios/agregar?idProspecto=${perfil}`}
        >
          <button className="miButton">
            {" "}
            <AddHomeOutlinedIcon /> Anadir a propietario
          </button>
        </Link>
      </div>
      <InfoProspecto handleClose={handleCloseEditDatosPersonales} isOpen={isOpenEditDatosPersonales} setIsOpen={setIsOpenEditDatosPersonales} tipoProspecto={"va"} />
      { !isMobile ? (
        <div className="opciones">
          <Link to={`/prospectos/vendedor/propiedad?id=${perfil}`}>
            <h3
              className="opcionPropiedad"
              id={
                window.location.pathname.includes("propiedad")
                  ? "active1"
                  : "notActive1"
              }
            >
              Datos Propiedad
            </h3>
          </Link>
          
          <Link to={`/prospectos/vendedor/actividades?id=${perfil}`}>
            <h3
              className="opcionActividades"
              id={
                window.location.pathname.includes("actividades")
                  ? "active1"
                  : "notActive1"
              }
            >
              Actividades
            </h3>
          </Link>
          
      </div>
      ) : (
        <ProspectoVendedorNavegacionMobile id={perfil} />
      )}
      
      <div className="main">
        <PropiedadProspecto handleClose={handleCloseEditDatosPropiedad} isOpen={isOpenEditDatosPropiedad} setIsOpen={setIsOpenEditDatosPropiedad} />
      </div>
    </div>
  );
};

export default PerfilProspecto;
