import { useHistory } from "react-router-dom";
import { BottomNavigation, BottomNavigationAction, Paper } from "@mui/material";
import {
  Assignment,
  ManageSearch,
} from "@mui/icons-material";

const ProspectoCompradorNavegacionMobile = ({id}) => {
  const history = useHistory();
  return (
    <Paper
      sx={{ position: "fixed", bottom: 0, left: 0, right: 0, zIndex: 30 }}
      elevation={3}
    >
      <BottomNavigation
        showLabels
        //value={value}
        onChange={(event, newValue) => {
          switch (newValue) {
            case 1:
              history.push(`/prospectos/comprador/actividades?id=${id}`);
              break;
            default:
              history.push(`/prospectos/comprador/requerimientos?id=${id}`);
              break;
          }
        }}
      >
        <BottomNavigationAction label="Requerimientos" icon={<ManageSearch sx={{color: window.location.pathname.includes("equerimiento") ? "#00a72c" : "inherit"}} />} />
        <BottomNavigationAction label="Actividades" icon={<Assignment sx={{color: window.location.pathname.includes("ctividades") ? "#00a72c" : "inherit"}} />} />
      </BottomNavigation>
    </Paper>
  );
};

export default ProspectoCompradorNavegacionMobile;
