import { Grid } from "@mui/material";
import { BARRIOS_COMUNAS, ESTADOS_PROPIEDAD } from "../../utils/constants";
import { getPropiedadesPaginadas } from "../../action";
import { useDispatch, useSelector } from "react-redux";
import { Field, Form, Formik } from "formik";

const FiltroPropiedades = ({
  page,
  rowsPerPage,
  sortBy,
  filtroValues,
  setFiltroValues,
  buscador,
}) => {
  const dispatch = useDispatch();
  const userData = useSelector((state) => state.app.itemUsuario);
  const brokers = useSelector((state) => state.app.usuariosFiltrados);

  const handleSubmit = (formValues) => {
    localStorage.setItem(
      "filtroPropiedadTipoOperacion",
      formValues.tipoOperacion
    );
    localStorage.setItem(
      "filtroPropiedadTipoPropiedad",
      formValues.tipoPropiedad
    );
    localStorage.setItem("filtroPropiedadComuna", formValues.comuna);
    localStorage.setItem(
      "filtroPropiedadDormitoriosDesde",
      formValues.dormitoriosDesde
    );
    localStorage.setItem(
      "filtroPropiedadDormitoriosHasta",
      formValues.dormitoriosHasta
    );
    localStorage.setItem("filtroPropiedadBaniosDesde", formValues.baniosDesde);
    localStorage.setItem("filtroPropiedadBaniosHasta", formValues.baniosHasta);
    localStorage.setItem("filtroPropiedadCodigo", formValues.codigoPropiedad);
    localStorage.setItem(
      "filtroPropiedadSuperficieTotalDesde",
      formValues.superficieTotalDesde
    );
    localStorage.setItem(
      "filtroPropiedadSuperficieTotalHasta",
      formValues.superficieTotalHasta
    );
    localStorage.setItem(
      "filtroPropiedadSuperficieUtilDesde",
      formValues.superficieUtilDesde
    );
    localStorage.setItem(
      "filtroPropiedadSuperficieUtilHasta",
      formValues.superficieUtilHasta
    );
    localStorage.setItem("filtroPropiedadEstado", formValues.estadoPropiedad);
    localStorage.setItem("filtroPropiedadTipoMoneda", formValues.tipoMoneda);
    localStorage.setItem(
      "filtroPropiedadEstacionamientos",
      formValues.estacionamiento
    );
    localStorage.setItem("filtroPropiedadBodegas", formValues.bodega);

    setFiltroValues(formValues);
    dispatch(
      getPropiedadesPaginadas(
        formValues.estadoPropiedad,
        formValues.idBroker,
        page,
        rowsPerPage,
        "todos",
        sortBy,
        formValues.comuna,
        formValues.tipoPropiedad,
        formValues.tipoOperacion,
        formValues.valorDesde,
        formValues.valorHasta,
        formValues.baniosDesde,
        formValues.baniosHasta,
        formValues.dormitoriosDesde,
        formValues.dormitoriosHasta,
        formValues.superficieUtilDesde,
        formValues.superficieUtilHasta,
        formValues.superficieTotalDesde,
        formValues.superficieTotalHasta,
        formValues.codigoPropiedad,
        formValues.tipoMoneda,
        "",
        buscador,
        formValues.estacionamiento,
        formValues.bodega
      )
    );
  };

  const limpiarFiltros = (formValues) => {
    localStorage.setItem("filtroPropiedadTipoOperacion", "");
    localStorage.setItem("filtroPropiedadTipoPropiedad", "");
    localStorage.setItem("filtroPropiedadComuna", "");
    localStorage.setItem("filtroPropiedadDormitoriosDesde", "0");
    localStorage.setItem("filtroPropiedadDormitoriosHasta", "0");
    localStorage.setItem("filtroPropiedadBaniosDesde", "0");
    localStorage.setItem("filtroPropiedadBaniosHasta", "0");
    localStorage.setItem("filtroPropiedadCodigo", "");
    localStorage.setItem("filtroPropiedadSuperficieTotalDesde", "0");
    localStorage.setItem("filtroPropiedadSuperficieTotalHasta", "0");
    localStorage.setItem("filtroPropiedadSuperficieUtilDesde", "0");
    localStorage.setItem("filtroPropiedadSuperficieUtilHasta", "0");
    localStorage.setItem("filtroPropiedadEstado", "");
    localStorage.setItem("filtroPropiedadTipoMoneda", "");
    localStorage.setItem("filtroPropiedadEstacionamientos", "0");
    localStorage.setItem("filtroPropiedadBodegas", "0");
    setFiltroValues(formValues);
    dispatch(
      getPropiedadesPaginadas(
        "",
        userData && userData.tipoCuenta === 10 ? userData.userId : "",
        page,
        rowsPerPage,
        "todos",
        sortBy,
        "",
        "",
        "",
        0,
        0,
        0,
        0,
        0,
        0,
        0,
        0,
        0,
        0,
        "",
        "",
        "",
        buscador,
        0,
        0
      )
    );
  };

  let estadosOptions = [];
  estadosOptions.push({ value: null, label: "- Seleccione -" });
  Object.keys(ESTADOS_PROPIEDAD).forEach((k) => {
    estadosOptions.push({ value: k, label: ESTADOS_PROPIEDAD[k] });
  });

  let comunasOptions = [];
  if (window.location.pathname.includes("otras")) {
    Object.keys(BARRIOS_COMUNAS).forEach((c) =>
      comunasOptions.push({ value: c, label: c })
    );
  } else {
    Object.keys(BARRIOS_COMUNAS).forEach((c) =>
      comunasOptions.push({ value: c, label: c })
    );
    //comunas && comunas.size > 0 && comunas.forEach((c) => comunasOptions.push({value: c, label: c}));
  }

  comunasOptions.sort(function (a, b) {
    if (a.label < b.label) {
      return -1;
    }
    if (a.label > b.label) {
      return 1;
    }
    return 0;
  });

  const handleReset = (formValues) => {
    limpiarFiltros(formValues);
  };

  return (
    <div style={{ marginBottom: "10px" }}>
      <Formik
        initialValues={filtroValues}
        onSubmit={handleSubmit}
        onReset={handleReset}
      >
        {({ values, handleSubmit, handleReset }) => (
          <Form onSubmit={handleSubmit} onReset={handleReset}>
            <Grid container spacing={1} justifyContent={"space-between"}>
              <Grid item xs={6} sm={4} md={4} lg={4} xl={3}>
                <label className="milabel">Operación</label>
                <Field name="tipoOperacion" as="select" className="miSelect">
                  <option value={""}>- Seleccione -</option>
                  <option value="arriendo">Arriendo</option>
                  <option value="venta">Venta</option>
                </Field>
              </Grid>
              <Grid item xs={6} sm={4} md={4} lg={4} xl={3}>
                <label className="milabel">Tipo inmueble</label>
                <Field name="tipoPropiedad" as="select" className="miSelect">
                  <option value={""}>- Seleccione -</option>
                  <option value="Departamento">Departamento</option>
                  <option value="Casa">Casa</option>
                </Field>
              </Grid>
              <Grid item xs={6} sm={4} md={4} lg={4} xl={3}>
                <label className="milabel">Comuna</label>
                <Field name="comuna" as="select" className="miSelect">
                  <option value={""}>- Seleccione -</option>
                  <optgroup label="Región metropolitana">
                    {comunasOptions
                      .filter(
                        (b) =>
                          b.label != "Quilpué" && b.label != "Villa Alemana"
                      )
                      .map((c) => (
                        <option value={c.value}>{c.label}</option>
                      ))}
                  </optgroup>
                  <optgroup label="Región de Valparaíso">
                    {comunasOptions
                      .filter(
                        (b) =>
                          b.label == "Quilpué" || b.label == "Villa Alemana"
                      )
                      .map((c) => (
                        <option value={c.value}>{c.label}</option>
                      ))}
                  </optgroup>
                </Field>
              </Grid>
              <Grid item xs={6} sm={4} md={4} lg={4} xl={3}>
                <label className="milabel">Dormitorios</label>
                <Grid container spacing={1}>
                  <Grid item xs={6} sm={6} md={6} lg={6} xl={6}>
                    <Field
                      name="dormitoriosDesde"
                      placeholder="Desde"
                      type="text"
                      className="miSelect"
                    />
                  </Grid>
                  <Grid item xs={6} sm={6} md={6} lg={6} xl={6}>
                    <Field
                      name="dormitoriosHasta"
                      placeholder="Hasta"
                      type="text"
                      className="miSelect"
                    />
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={6} sm={4} md={4} lg={4} xl={3}>
                <label className="milabel">Baños</label>
                <Grid container spacing={1}>
                  <Grid item xs={6} sm={6} md={6} lg={6} xl={6}>
                    <Field
                      name="baniosDesde"
                      placeholder="Desde"
                      type="text"
                      className="miSelect"
                    />
                  </Grid>
                  <Grid item xs={6} sm={6} md={6} lg={6} xl={6}>
                    <Field
                      name="baniosHasta"
                      placeholder="Hasta"
                      type="text"
                      className="miSelect"
                    />
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={6} sm={4} md={4} lg={4} xl={3}>
                <label className="milabel">Código propiedad</label>
                <Field
                  name="codigoPropiedad"
                  type="text"
                  className="miSelect"
                />
              </Grid>
              <Grid item xs={6} sm={4} md={4} lg={4} xl={3}>
                <label className="milabel">Superficie total</label>
                <Grid container spacing={1}>
                  <Grid item xs={6} sm={6} md={6} lg={6} xl={6}>
                    <Field
                      name="superficieTotalDesde"
                      placeholder="Desde"
                      type="text"
                      className="miSelect"
                    />
                  </Grid>
                  <Grid item xs={6} sm={6} md={6} lg={6} xl={6}>
                    <Field
                      name="superficieTotalHasta"
                      placeholder="Hasta"
                      type="text"
                      className="miSelect"
                    />
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={6} sm={4} md={4} lg={4} xl={3}>
                <label className="milabel">Superficie útil</label>
                <Grid container spacing={1}>
                  <Grid item xs={6} sm={6} md={6} lg={6} xl={6}>
                    <Field
                      name="superficieUtilDesde"
                      placeholder="Desde"
                      type="text"
                      className="miSelect"
                    />
                  </Grid>
                  <Grid item xs={6} sm={6} md={6} lg={6} xl={6}>
                    <Field
                      name="superficieUtilHasta"
                      placeholder="Hasta"
                      type="text"
                      className="miSelect"
                    />
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={6} sm={4} md={4} lg={4} xl={3}>
                <label className="milabel">Tipo moneda</label>
                <Field name="tipoMoneda" as="select" className="miSelect">
                  <option value={""}>- Seleccione -</option>
                  <option value="CLP">CLP</option>
                  <option value="UF">UF</option>
                </Field>
              </Grid>
              <Grid item xs={6} sm={4} md={4} lg={4} xl={3}>
                <label className="milabel">Valor</label>
                <Grid container spacing={1}>
                  <Grid item xs={6} sm={6} md={6} lg={6} xl={6}>
                    <Field
                      name="valorDesde"
                      placeholder="Desde"
                      type="text"
                      className="miSelect"
                    />
                  </Grid>
                  <Grid item xs={6} sm={6} md={6} lg={6} xl={6}>
                    <Field
                      name="valorHasta"
                      placeholder="Hasta"
                      type="text"
                      className="miSelect"
                    />
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={6} sm={4} md={4} lg={4} xl={3}>
                <label className="milabel">Estado propiedad</label>
                <Field name="estadoPropiedad" as="select" className="miSelect">
                  <option value={""}>- Seleccione -</option>
                  {estadosOptions.map((c) => (
                    <option value={c.value}>{c.label}</option>
                  ))}
                </Field>
              </Grid>
              <Grid item xs={6} sm={4} md={4} lg={4} xl={3}>
                <Grid container spacing={1}>
                  <Grid item xs={6} sm={6} md={6} lg={6} xl={6}>
                    <label className="milabel">Estacionamientos</label>
                    <Field
                      name="estacionamiento"
                      type="text"
                      className="miSelect"
                    />
                  </Grid>
                  <Grid item xs={6} sm={6} md={6} lg={6} xl={6}>
                    <label className="milabel">Bodegas</label>
                    <Field name="bodega" type="text" className="miSelect" />
                  </Grid>
                </Grid>
              </Grid>
              {userData &&
                userData.tipoCuenta !== 10 &&
                window.location.pathname.includes("mispropiedades") && (
                  <Grid item xs={12} sm={6} md={6} lg={4} xl={3}>
                    <label className="milabel">Broker</label>
                    <Field name="idBroker" as="select" className="miSelect">
                      <option value={""}>- Seleccione -</option>
                      {brokers &&
                        brokers.length > 0 &&
                        brokers.map((c) => (
                          <option value={c.id}>
                            {c.nombres} {c.apellidos}
                          </option>
                        ))}
                    </Field>
                  </Grid>
                )}
              <Grid item>
                <button
                  type="submit"
                  className="boton2"
                  style={{ margin: "10px" }}
                  //onClick={() => filtrar()}
                >
                  Filtrar
                </button>
                <button
                  type="button"
                  className="boton-gris"
                  style={{ margin: "10px" }}
                  onClick={(e) => handleReset(e)}
                >
                  Limpiar filtros
                </button>
              </Grid>
            </Grid>
          </Form>
        )}
      </Formik>
    </div>
  );
};

export default FiltroPropiedades;
