import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getReportes } from "../../action";
import { useState } from "react";
import "chart.js/auto";
import { CircularProgress, Grid, TablePagination } from "@mui/material";
import ModalSubirReporte from "./ModalSubirReporte";
import { InsertDriveFile } from "@mui/icons-material";

const isMobile =
  !!navigator.userAgent.match(/iphone|android|blackberry/gi) || false;

const Reportes = (reloadMethod) => {
  const dispatch = useDispatch();

  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };
  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };
  const [openModalSubirReporte, setOpenModalSubirReporte] = useState(false);
  const handleCloseModalSubirReporte = () => setOpenModalSubirReporte(false);
  const handleOpenModalSubirReporte = () => setOpenModalSubirReporte(true);
  const reportes = useSelector(
    (state) => state.app.reportes && state.app.reportes.results
  );
  const data = useSelector((state) => state.app.reportes);
  const requestGetReportes = useSelector(
    (state) => state.app.requestGetReportes
  );

  const userData = useSelector((state) => state.app.itemUsuario);

  const reloadReportes = () => {
    dispatch(getReportes(page, rowsPerPage));
  };

  useEffect(() => {
    dispatch(getReportes(page + 1, rowsPerPage));
  }, [page, rowsPerPage]);

  return (
    <>
      <div className="header">
        <h2>Reportes</h2>
      </div>
      {userData.tipoCuenta !== 10 && (
        <Grid container justifyContent={"flex-start"} sx={{ mb: 2 }}>
          <Grid item xs={12} sm={12} md={9} lg={9} xl={9} className="alignLeft">
            <button
              className="boton"
              onClick={() => handleOpenModalSubirReporte()}
            >
              Subir reporte
            </button>
          </Grid>
        </Grid>
      )}

      {requestGetReportes === "LOADING" && <CircularProgress />}
      {requestGetReportes === "IDLE" && reportes && reportes.length === 0 && (
        <div>No se han subido reportes todavía</div>
      )}
      {requestGetReportes === "IDLE" && reportes && reportes.length > 0 && (
        <>
          <Grid
            container
            spacing={4}
            sx={{ marginBottom: 4 }}
            justifyContent={isMobile ? "center" : "flex-start"}
          >
            {reportes &&
              reportes.map((m) => (
                <Grid item>
                  <div className="document-card">
                    <div className="documento thumbnail-documento thumbnail-reporte reporte">
                      <a href={m.downloadLink} target="_blank" rel="noreferrer">
                        <button className="crearDocum">Ver reporte</button>
                      </a>
                    </div>
                    <div className="tituloReporte">
                      <InsertDriveFile
                        sx={{
                          margin: "0.3rem",
                          fontSize: "20pt",
                          color: "#00a62b",
                        }}
                      />
                      {m.nombre}
                    </div>
                  </div>
                </Grid>
              ))}
          </Grid>
          <Grid
            container
            justifyContent={isMobile ? "center" : "flex-start"}
            alignItems="center"
          >
            <Grid item xs={12} sm={6} md={6} lg={6} xl={6}>
              <TablePagination
                component="div"
                count={data && data.totalResults}
                page={page}
                onPageChange={handleChangePage}
                rowsPerPage={rowsPerPage}
                onRowsPerPageChange={handleChangeRowsPerPage}
                SelectProps={{
                  inputProps: { "aria-label": "Cantidad" },
                  native: true,
                }}
                labelRowsPerPage={"Resultados por página"}
              />
            </Grid>
          </Grid>
        </>
      )}
      {/*
<Bar
        datasetIdKey="id"
        //options={options}
        data={{
          labels: comunas.map((c) => c.name),
          datasets: [
            {
              label: "Numero comunas",
              data: comunas.map((c) => c.count),
              backgroundColor: "rgba(255, 99, 132, 0.5)",
            },
          ],
        }}
      />
*/}
      <ModalSubirReporte
        isOpen={openModalSubirReporte}
        handleClose={handleCloseModalSubirReporte}
        reloadMethod={reloadReportes}
      />
    </>
  );
};

export default Reportes;
