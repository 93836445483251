import { Grid } from "@mui/material";
import { Field, Form, Formik } from "formik";
import { useDispatch } from "react-redux";
import { getProspectos } from "../../action";
import { BARRIOS_COMUNAS } from "../../utils/constants";

const FiltroProspectos = ({
  tipoProspecto,
  page,
  pageSize,
  setFiltroValues,
  filtroValues,
}) => {
  const dispatch = useDispatch();
  const handleSubmit = (formData) => {
    localStorage.setItem(
      "filtroProspectoTipoOperacion",
      formData.tipoOperacion
    );
    localStorage.setItem(
      "filtroProspectoTipoPropiedad",
      formData.tipoPropiedad
    );
    localStorage.setItem("filtroProspectoComuna", formData.comuna);
    localStorage.setItem("filtroProspectoBarrio", formData.barrio);
    localStorage.setItem("filtroProspectoDormitorios", formData.dormitorios);
    localStorage.setItem("filtroProspectoBanios", formData.banios);

    formData &&
      dispatch(
        getProspectos(
          tipoProspecto,
          page,
          pageSize,
          formData.tipoOperacion,
          formData.tipoPropiedad,
          formData.comuna,
          formData.barrio,
          formData.dormitorios,
          formData.banios
        )
      );
  };
  const limpiarFiltros = (formValues) => {
    localStorage.setItem("filtroProspectoTipoOperacion", "");
    localStorage.setItem("filtroProspectoTipoPropiedad", "");
    localStorage.setItem("filtroProspectoComuna", "");
    localStorage.setItem("filtroProspectoBarrio", "");
    localStorage.setItem("filtroProspectoDormitorios", "0");
    localStorage.setItem("filtroProspectoBanios", "0");
    setFiltroValues(formValues);
    dispatch(
      getProspectos(
        tipoProspecto,
        page,
        pageSize,
        filtroValues.tipoOperacion,
        filtroValues.tipoPropiedad,
        filtroValues.comuna,
        filtroValues.barrio,
        filtroValues.dormitorios,
        filtroValues.banios
      )
    );
  };

  const handleReset = (formValues) => {
    limpiarFiltros(formValues);
  };

  return (
    <Formik
      initialValues={{
        tipoOperacion: "",
        tipoPropiedad: "",
        comuna: "",
        barrio: "",
        dormitorios: 0,
        banios: 0,
      }}
      onSubmit={handleSubmit}
      onReset={handleReset}
    >
      {({ values, handleSubmit, handleReset }) => (
        <Form onSubmit={handleSubmit} onReset={handleReset}>
          <Grid container spacing={1} alignItems="center">
            <Grid item xs={6} sm={6} md={3} lg={2} xl={2}>
              <label className="milabel">Operación</label>
              <Field name="tipoOperacion" as="select" className="miSelect">
                <option value={""}>- Seleccione -</option>
                <option value={0}>Arriendo</option>
                <option value={1}>Venta</option>
              </Field>
            </Grid>
            <Grid item xs={6} sm={6} md={3} lg={2} xl={2}>
              <label className="milabel">Tipo Inmueble</label>
              <Field name="tipoPropiedad" as="select" className="miSelect">
                <option value={""}>- Seleccione -</option>
                <option value="Departamento">Departamento</option>
                <option value="Casa">Casa</option>
              </Field>
            </Grid>
            <Grid item xs={6} sm={3} md={2} lg={2} xl={2}>
              <label className="milabel">Dormitorios</label>
              <Field name="dormitorios" type="text" className="miSelect" />
            </Grid>
            <Grid item xs={6} sm={3} md={2} lg={2} xl={2}>
              <label className="milabel">Baños</label>
              <Field name="banios" type="text" className="miSelect" />
            </Grid>
            <Grid item xs={6} sm={6} md={3} lg={2} xl={2}>
              <label className="milabel">Comuna</label>
              <Field name="comuna" as="select" className="miSelect">
                <option value={""}>- Seleccione -</option>
                {Object.keys(BARRIOS_COMUNAS).map((c) => (
                  <option value={c}>{c}</option>
                ))}
              </Field>
            </Grid>
            {window.location.pathname.includes("comprador") && (
              <Grid item xs={6} sm={6} md={3} lg={2} xl={2}>
                <label className="milabel">Barrio</label>
                <Field name="barrio" as="select" className="miSelect">
                  <option value={""}>- Seleccione -</option>
                  {BARRIOS_COMUNAS[values.comuna] != null &&
                    BARRIOS_COMUNAS[values.comuna] != undefined &&
                    BARRIOS_COMUNAS[values.comuna].map((p) => (
                      <option value={p}>{p}</option>
                    ))}
                </Field>
              </Grid>
            )}
          </Grid>

          <Grid container spacing={1} alignItems="center">
            <Grid item>
              <button className="boton2" type="submit">
                Filtrar
              </button>
              <button
                type="button"
                className="boton-gris"
                style={{ margin: "10px" }}
                onClick={(e) => handleReset(e)}
              >
                Limpiar filtros
              </button>
            </Grid>
          </Grid>
        </Form>
      )}
    </Formik>
  );
};

export default FiltroProspectos;
