import {
  Close,
  Delete,
  Download,
  Edit,
  InsertLink,
  LocationOn,
  OpenInNew,
  Person,
  PostAdd,
  RotateLeft,
} from "@mui/icons-material";
import {
  alpha,
  Backdrop,
  Box,
  Button,
  CircularProgress,
  Dialog,
  DialogTitle,
  Grid,
  IconButton,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Modal,
  Snackbar,
  styled,
  Switch,
  Tooltip,
} from "@mui/material";
import { green, red } from "@mui/material/colors";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  actualizarEnPI,
  actualizarEnYapo,
  deleteTarjetaProp,
  descargarFichaPropiedad,
  eliminarEnPI,
  eliminarEnYapo,
  fetchDeletePropiedad,
  postTarjetaProp,
  publicarEnPI,
  publicarEnYapo,
  putPropiedadAsignarBroker,
  setFichaPropiedad,
} from "../../../action";
import Swal from "sweetalert2";
import FormAgendarVisita from "../../Agenda/FormAgendarVisita";
import Back from "../../../assets/Back";
import FormAgregarPropiedad from "../FormAgregarPropiedad";
import * as Yup from "yup";
import FieldError from "../../../utils/FieldError";
import { Field, Form, Formik } from "formik";

const validate = Yup.object().shape({
  idBroker: Yup.string().required("Seleccione un broker"),
});

const isMobile =
  !!navigator.userAgent.match(/iphone|android|blackberry/gi) || false;

const style = {
  position: "absolute",
  top: "0px",
  left: "50%",
  transform: "translate(-50%, 0%)",
  width: 1000,
  bgcolor: "background.paper",
  boxShadow: 24,
  p: 4,
  //maxHeight: '90%'
};

const styleBrokerModal = {
  position: "absolute",
  top: "0px",
  left: "50%",
  transform: "translate(-50%, 0%)",
  width: 600,
  bgcolor: "background.paper",
  boxShadow: 24,
  p: 4,
  //maxHeight: '90%'
};

const styleMobile = {
  position: "absolute",
  top: "0px",
  left: "0px",
  //transform: "translate(-50%, 0%)",
  width: "100vw",
  bgcolor: "background.paper",
  boxShadow: 24,
  p: 4,
  //maxHeight: '90%'
};

export const CabeceraFichaPropiedad = ({
  id,
  userData,
  prop,
  idBroker,
  reloadData,
  requestUpdatePropiedad,
  openBrokerModal,
  handleOpenBrokerModal,
  handleCloseBrokerModal
}) => {
  const dispatch = useDispatch();
  const brokers = useSelector((state) => state.app.usuariosFiltrados);

  const requestPropiedadBroker = useSelector(
    (state) => state.app.requestPropiedadBroker
  );

  const fichaPropiedad = useSelector((state) => state.app.fichaPropiedad);

  const planes = useSelector((state) => state.app.itemPlanes);

  const [openEditModal, setOpenEditModal] = useState(false);
  const handleOpenEditModal = () => setOpenEditModal(true);
  const handleCloseEditModal = () => setOpenEditModal(false);

  const [openDialogYapo, setOpenDialogYapo] = useState(false);
  const handleDialogYapoOpen = () => setOpenDialogYapo(true);
  const handleCloseDialogYapo = () => setOpenDialogYapo(false);

  const [openDialogPI, setOpenDialogPI] = useState(false);
  const handleDialogPIOpen = () => setOpenDialogPI(true);
  const handleCloseDialogPI = () => setOpenDialogPI(false);

  const [openAgendarModal, setOpenAgendarModal] = useState(false);
  const handleCloseAgendarModal = () => setOpenAgendarModal(false);

  const [copyNotification, setCopyNotification] = useState(false);
  const handleCloseCopyNotification = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setCopyNotification(false);
  };

  const onCopyLink = (propiedad) => {
    setCopyNotification(true);
    let baseUrl =
      window.location.hostname.includes("dev.propins.cl") ||
      window.location.hostname.includes("localhost")
        ? "https://dev.propins.cl/info-propiedad?idprop="
        : "https://propins.cl/info-propiedad?idprop=";

    navigator.clipboard.writeText(`${baseUrl}${propiedad.id}`);
  };

  const publicarEnPortalPI = () => {
    let cuentaConCtdadMinimaFotos = !(prop.imagenes === null || (Array.isArray(prop.imagenes) && prop.imagenes.length < 12));
    const msgWarning = `<p class="red"><strong>Nota:</strong> la propiedad no cuenta con el mínimo de 12 fotos para estar bien posicionada en Portal Inmobiliario</p>`;
    Swal.fire({
      title: `Publicar en Portal Inmobiliario`,
      html: `<p>¿Está seguro de publicar esta propiedad en Portal Inmobiliario?</p> ${!cuentaConCtdadMinimaFotos && msgWarning}`,
      //text: `¿Está seguro de publicar esta propiedad en Portal Inmobiliario?`,
      showCancelButton: true,
      confirmButtonText: "Publicar",
      cancelButtonText: `Cancelar`,
    }).then((result) => {
      if (result.value && prop && prop.id) {
        if (prop.imagenes === null || (Array.isArray(prop.imagenes) && prop.imagenes.length < 12)) {
          Swal.fire({
            title: "Error",
            text: "No puedes publicar una propiedad en Portal Inmobiliario que tenga menos de 12 fotos subidas",
            icon: "error"
          });
        } else {
          dispatch(publicarEnPI(prop.id));
        }
      }
    });
  };

  const actualizarEnPortalInmobiliario = () => {
    Swal.fire({
      title: `Publicar en Portal Inmobiliario`,
      html: `<p>¿Está seguro de actualizar la publicación de esta propiedad en Portal Inmobiliario?</p><p> <strong>Nota:</strong> Ten en consideración que los campos que puedes actualizar son:</p>
      <p>
      Ubicación (dirección, comuna y/o ciudad)
      <br />Precio
      <br />Dormitorios
      <br />Baños
      <br />Estacionamientos
      <br />Bodegas
      <br />Superficie util
      <br />Superficie total
      </p>`,
      showCancelButton: true,
      confirmButtonText: "Publicar",
      cancelButtonText: `Cancelar`,
    }).then((result) => {
      if (result.value && prop && prop.id) {
        dispatch(actualizarEnPI(prop.id));
      }
    });
  };

  const eliminarEnPortalInmobiliario = () => {
    Swal.fire({
      title: `Eliminar publicación en Portal Inmobiliario`,
      text: `¿Está seguro de eliminar la publicación de esta propiedad en Portal Inmobiliario?`,      
      showCancelButton: true,
      confirmButtonText: "Eliminar",
      cancelButtonText: `Cancelar`,
    }).then((result) => {
      if (result.value && prop && prop.id) {
        handleCloseDialogPI();
        dispatch(eliminarEnPI(prop.id));
      }
    });
  };
  
  const publicarEnPortalYapo = () => {
    Swal.fire({
      title: `Publicar en Yapo`,
      text: `¿Está seguro de publicar esta propiedad en Yapo?`,
      showCancelButton: true,
      confirmButtonText: "Publicar",
      cancelButtonText: `Cancelar`,
    }).then((result) => {
      if (result.value && prop && prop.id) {
        dispatch(publicarEnYapo(prop.id));
      }
    });
  };

  const actualizarYapo = () => {
    Swal.fire({
      title: `Actualizar publicación en Yapo`,
      text: `¿Está seguro de actualizar la publicación de esta propiedad en Yapo?`,
      showCancelButton: true,
      confirmButtonText: "Actualizar",
      cancelButtonText: `Cancelar`,
    }).then((result) => {
      if (result.value && prop && prop.id) {
        handleCloseDialogYapo();
        dispatch(actualizarEnYapo(prop.id));
      }
    });
  };
  const eliminarYapo = () => {
    Swal.fire({
      title: `Eliminar publicación en Yapo`,
      text: `¿Está seguro de eliminar la publicación de esta propiedad en Yapo?`,
      showCancelButton: true,
      confirmButtonText: "Eliminar",
      cancelButtonText: `Cancelar`,
    }).then((result) => {
      if (result.value && prop && prop.id) {
        handleCloseDialogYapo();
        dispatch(eliminarEnYapo(prop.id));
      }
    });
  };
  const showConfirmationDialog = (row, isDelete) => {
    let verb = "Generar";
    if (isDelete) {
      verb = "Deshabilitar";
    }
    Swal.fire({
      title: `${verb} tarjeta propiedad`,
      text: `Confirma que desea ${verb.toLowerCase()} la tarjeta para esta propiedad`,
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Confirmar",
    }).then((result) => {
      if (result.value) {
        if (isDelete) {
          dispatch(deleteTarjetaProp(row.id));
        } else {
          dispatch(postTarjetaProp(row.id));
        }
      }
    });
  };

  const showDeleteConfirmationDialog = (id) => {
    Swal.fire({
      title: `Eliminar la propiedad`,
      text: `Confirma que desea eliminar esta propiedad`,
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Confirmar",
    }).then((result) => {
      if (result.value) {
        dispatch(fetchDeletePropiedad(id));
      }
    });
  };

  const GreenSwitch = styled(Switch)(({ theme }) => ({
    "& .MuiSwitch-switchBase.Mui-checked": {
      color: green[600],
      "&:hover": {
        backgroundColor: alpha(green[600], theme.palette.action.hoverOpacity),
      },
    },
    "& .MuiSwitch-switchBase.Mui-checked + .MuiSwitch-track": {
      backgroundColor: green[600],
    },
  }));

  const descargarFicha = (id) => {
    dispatch(descargarFichaPropiedad(id));
  };

  const submitBroker = (formValues) => {
    dispatch(putPropiedadAsignarBroker(prop.id, formValues.idBroker));
  };

  const getNombreUsuario = (id) => {
    const user =
      brokers &&
      brokers.some((u) => u.id === id) &&
      brokers.find((u) => u.id === id);
    if (user) {
      return `${user.nombres} ${user.apellidos}`;
    }
    return "";
  };

  useEffect(() => {
    if (fichaPropiedad !== null && fichaPropiedad !== undefined) {
      const url = window.URL.createObjectURL(new Blob([fichaPropiedad]));
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", `Ficha propiedad ${prop && prop.codigoPropiedad}.pdf`);
      document.body.appendChild(link);
      link.click();
      dispatch(setFichaPropiedad(null));
    }
  }, [fichaPropiedad]);

  if (prop) {
    return (
      <Grid container justifyContent={"space-between"}>
        <Grid item xs={12} sm={6} md={6} lg={6} xl={6}>
          <Grid container justifyContent={"flex-start"}>
            <Back urlPath={"/mispropiedades"} />
            <Grid
              item
              xs={12}
              sm={6}
              md={6}
              lg={6}
              xl={6}
              className="alignLeft"
            >
              <h2 style={{ display: "inline" }}>Ficha propiedad</h2>
            </Grid>
            <Grid item>
              <Tooltip
                title={
                  prop.tarjetaPropiedadHabilitada
                    ? "Deshabilitar tarjeta propiedad"
                    : "Habilitar tarjeta propiedad"
                }
                aria-label="habilitar"
              >
                <GreenSwitch
                  checked={prop.tarjetaPropiedadHabilitada}
                  onChange={() =>
                    showConfirmationDialog(
                      prop,
                      prop.tarjetaPropiedadHabilitada
                    )
                  }
                />
              </Tooltip>
              <Tooltip title={"Descargar ficha"} aria-label="download">
                <IconButton
                  //color="danger"
                  aria-label="download"
                  component="span"
                  onClick={() => descargarFicha(prop.id)}
                >
                  <Download />
                </IconButton>
              </Tooltip>
              <Tooltip title="Copiar enlace" aria-label="copiar">
                <IconButton
                  color="primary"
                  aria-label="copiar"
                  component="span"
                  onClick={() => onCopyLink(prop)}
                >
                  <InsertLink sx={{ fontSize: "20pt" }} />
                </IconButton>
              </Tooltip>
              <Tooltip title="Eliminar propiedad" aria-label="copiar">
                <IconButton
                  color="primary"
                  aria-label="copiar"
                  component="span"
                  onClick={() => showDeleteConfirmationDialog(prop.id)}
                >
                  <Delete sx={{ fontSize: "20pt", color: red[500] }} />
                </IconButton>
              </Tooltip>
              <Tooltip title="Editar propiedad" aria-label="copiar">
                <IconButton
                  color="primary"
                  aria-label="copiar"
                  component="span"
                  onClick={() => handleOpenEditModal()}
                >
                  <Edit sx={{ fontSize: "20pt" }} />
                </IconButton>
              </Tooltip>
              {userData.tipoCuenta !== 10 && (
                <>
                {prop.idBroker != "" && prop.idBroker != null ? (
                  <Tooltip
                    title={`Broker asociado: ${getNombreUsuario(
                      prop.idBroker
                    )}`}
                    aria-label="broker"
                  >
                    <IconButton
                      color="primary"
                      aria-label="broker"
                      component="span"
                      onClick={() => handleOpenBrokerModal()}
                    >
                      <Person />
                    </IconButton>
                  </Tooltip>
                ) : (
                  <Tooltip
                    title="Sin broker asociado. Haga click para asociar broker"
                    aria-label="broker"
                  >
                    <IconButton
                      aria-label="broker"
                      component="span"
                      className="iconbutton-habilitado"
                      onClick={() => handleOpenBrokerModal()}
                    >
                      <Person />
                    </IconButton>
                  </Tooltip>
                )}
                {prop.publicadoEnYapo ? (
                  <Tooltip
                    title="Ver datos de publicacion en Yapo"
                    aria-label="datos yapo"
                  >
                    <IconButton
                      color="primary"
                      aria-label="datos yapo"
                      component="span"
                      onClick={() => handleDialogYapoOpen()}
                    >
                      <PostAdd />
                    </IconButton>
                  </Tooltip>
                ) : (
                  <Tooltip title="Publicar en Yapo" aria-label="copiar">
                    <IconButton
                      className="iconbutton-habilitado"
                      aria-label="copiar"
                      component="span"
                      onClick={() => publicarEnPortalYapo()}
                    >
                      <PostAdd sx={{ fontSize: "20pt" }} />
                    </IconButton>
                  </Tooltip>
                )}
                {prop.publicadaPortalInmobiliario ? (
                  <Tooltip
                    title="Ver datos de publicacion en Portal Inmobiliario"
                    aria-label="datos portal inmobiliario"
                  >
                    <IconButton
                      color="primary"
                      aria-label="datos portal inmobiliario"
                      component="span"
                      onClick={() => handleDialogPIOpen()}
                    >
                      <LocationOn />
                    </IconButton>
                  </Tooltip>
                ) : (
                  <Tooltip
                    title="Publicar en Portal Inmobiliario"
                    aria-label="copiar"
                  >
                    <IconButton
                      color="primary"
                      aria-label="copiar"
                      className="iconbutton-habilitado"
                      component="span"
                      onClick={() => publicarEnPortalPI()}
                    >
                      <LocationOn sx={{ fontSize: "20pt" }} />
                    </IconButton>
                  </Tooltip>
                )}
                </>
              )}
            </Grid>
          </Grid>
        </Grid>

        <Grid item>
          <button className="boton" onClick={() => setOpenAgendarModal(true)}>
            Agendar visita
          </button>
        </Grid>
        <FormAgendarVisita
          idPropiedad={id}
          reloadMethod={reloadData}
          idBroker={idBroker}
          isOpen={openAgendarModal}
          handleClose={handleCloseAgendarModal}
        />

        <Modal
          disableScrollLock={false}
          open={openEditModal}
          onClose={handleCloseEditModal}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
          sx={{
            overflowY: "auto",
            top: isMobile ? "0px" : "10px",
            maxHeight: isMobile ? "100%" : "95%",
          }}
        >
          <Box sx={isMobile ? styleMobile : style}>
            <Grid
              container
              justifyContent="space-between"
              alignItems={"center"}
              sx={{ marginBottom: 2 }}
            >
              <Grid item xs={10} sm={10} md={10} lg={10} xl={10}>
                <h3 className="alignLeft">Editar propiedad</h3>
              </Grid>
              <Grid item>
                <IconButton
                  color="primary"
                  aria-label="copiar"
                  component="span"
                  onClick={() => handleCloseEditModal()}
                >
                  <Close sx={{ fontSize: "20pt" }} />
                </IconButton>
              </Grid>
            </Grid>
            <FormAgregarPropiedad
              initialValues={prop}
              planes={planes}
              idCliente={prop.idCliente}
              //regiones={regiones}
              //clientes={clientes}
              //updatePropiedad={updatePropiedad}
              submitMethod={null}
              requestState={requestUpdatePropiedad}
              reloadMethod={reloadData}
              handleClose={handleCloseEditModal}
              inluirSelectClientes={false}
            />
          </Box>
        </Modal>

        <Modal
          disableScrollLock={false}
          open={openBrokerModal}
          onClose={handleCloseBrokerModal}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
          sx={{
            overflowY: "auto",
            top: isMobile ? "0px" : "10px",
            maxHeight: isMobile ? "100%" : "95%",
          }}
        >
          <Box sx={isMobile ? styleMobile : styleBrokerModal}>
            {requestPropiedadBroker == "LOADING" && (
              <Backdrop
                sx={{
                  color: "#fff",
                  zIndex: (theme) => theme.zIndex.drawer + 1,
                }}
                open={true}
                //onClick={handleClose}
              >
                <CircularProgress color="inherit" />
              </Backdrop>
            )}
            <Grid
              container
              justifyContent="space-between"
              alignItems={"center"}
              sx={{ marginBottom: 2 }}
            >
              <Grid item xs={10} sm={10} md={10} lg={10} xl={10}>
                <h3 className="alignLeft">Administrar broker propiedad</h3>
              </Grid>
              <Grid item>
                <IconButton
                  color="primary"
                  aria-label="copiar"
                  component="span"
                  onClick={() => handleCloseBrokerModal()}
                >
                  <Close sx={{ fontSize: "20pt" }} />
                </IconButton>
              </Grid>
            </Grid>
            <Formik
              initialValues={{
                idBroker: prop && prop.idBroker,
              }}
              validationSchema={validate}
              onSubmit={submitBroker}
            >
              {({ handleSubmit }) => (
                <>
                  <Form onSubmit={handleSubmit} className="form-container">
                    <Grid container>
                      <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                        <label className="milabel">Broker</label>
                        <Field
                          name="idBroker"
                          as="select"
                          className="miSelect"
                          //disabled={idCliente ? true : false}
                        >
                          <option value={""}>- Seleccione -</option>
                          {brokers &&
                            brokers.length > 0 &&
                            brokers.map((c) => (
                              <option value={c.id}>
                                {c.nombres} {c.apellidos}
                              </option>
                            ))}
                        </Field>
                        <FieldError name="idBroker" />
                      </Grid>
                    </Grid>
                    <Grid container justifyContent="flex-end" sx={{ mt: 2 }}>
                      <Grid item>
                        <Button
                          onClick={handleCloseBrokerModal}
                          className="main-btn"
                        >
                          Cancelar
                        </Button>
                      </Grid>
                      <Grid item>
                        <Button
                          type="submit"
                          className="main-btn"
                          variant="contained"
                          color="success"
                        >
                          Guardar
                        </Button>
                      </Grid>
                    </Grid>
                  </Form>
                </>
              )}
            </Formik>
          </Box>
        </Modal>

        <Dialog
          onClose={handleCloseDialogYapo}
          aria-labelledby="simple-dialog-title"
          open={openDialogYapo}
        >
          <DialogTitle id="simple-dialog-title">
            Administrar publicación en Yapo
          </DialogTitle>
          <List>
            <ListItem autoFocus button>
              <ListItemIcon>
                <OpenInNew />
              </ListItemIcon>
              <ListItemText
                primary="Ir a la publicación"
                onClick={() =>
                  window.open(`${prop.urlPublicacionYapo}`, "_blank")
                }
              />
            </ListItem>
            <ListItem autoFocus button>
              <ListItemIcon>
                <RotateLeft />
              </ListItemIcon>
              <ListItemText
                primary="Actualizar publicación en Yapo"
                secondary="En caso de que hayas actualizado los datos de la propiedad"
                onClick={() => actualizarYapo()}
              />
            </ListItem>
            <ListItem autoFocus button>
              <ListItemIcon>
                <Close />
              </ListItemIcon>
              <ListItemText
                primary="Eliminar publicación en Yapo"
                secondary="Dar de baja la publicación"
                onClick={() => eliminarYapo()}
              />
            </ListItem>
          </List>
        </Dialog>

        <Dialog
          onClose={handleCloseDialogPI}
          aria-labelledby="simple-dialog-title"
          open={openDialogPI}
        >
          <DialogTitle id="simple-dialog-title">
            Administrar publicación en Portal Inmobiliario
          </DialogTitle>
          <List>
            <ListItem autoFocus button>
              <ListItemIcon>
                <RotateLeft />
              </ListItemIcon>
              <ListItemText
                primary="Volver a publicar"
                secondary="En caso de que hayas actualizado los datos de la propiedad"
                onClick={() => actualizarEnPortalInmobiliario()}
              />
            </ListItem>

            <ListItem autoFocus button>
              <ListItemIcon>
                <OpenInNew />
              </ListItemIcon>
              <ListItemText
                primary="Ir a la publicación"
                onClick={() =>
                  window.open(
                    `https://www.portalinmobiliario.com/${prop.idPublicacionPI}`,
                    "_blank"
                  )
                }
              />
            </ListItem>
            <ListItem autoFocus button>
              <ListItemIcon>
                <Close />
              </ListItemIcon>
              <ListItemText
                primary="Eliminar publicación en Portal Inmobiliario"
                secondary="Dar de baja la publicación"
                onClick={() => eliminarEnPortalInmobiliario()}
              />
            </ListItem>
          </List>
        </Dialog>
        <Snackbar
          open={copyNotification}
          autoHideDuration={4000}
          onClose={handleCloseCopyNotification}
          message="Enlace copiado"
        />
      </Grid>
    );
  } else {
    return <div>Ha habido un problema cargando la propiedad</div>;
  }
};
