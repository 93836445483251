import * as React from "react";
import Tooltip from "@mui/material/Tooltip";
import IconButton from "@mui/material/IconButton";
import TableCell from "@mui/material/TableCell";
import TableRow from "@mui/material/TableRow";
import { useState } from "react";
import { Menu, MenuItem } from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import InsertLinkIcon from "@mui/icons-material/InsertLink";
import { Link } from "react-router-dom";
import {
  MoreVert,
  Person,
  ToggleOff,
  ToggleOn,
  Visibility,
} from "@mui/icons-material";
import { ESTADOS_PROPIEDAD } from "../../utils/constants";
import { Download, LocationOn, PostAdd } from "@mui/icons-material";
import utilsFunc from "../../utils/utilsFunctions";
import Switch from "@mui/material/Switch";
import { alpha, styled } from "@mui/material/styles";
import { green, red } from "@mui/material/colors";
import { useSelector } from "react-redux";

const StyledMenu = styled((props) => (
  <Menu
    elevation={0}
    anchorOrigin={{
      vertical: "bottom",
      horizontal: "right",
    }}
    transformOrigin={{
      vertical: "top",
      horizontal: "right",
    }}
    {...props}
  />
))(({ theme }) => ({
  "& .MuiPaper-root": {
    borderRadius: 6,
    marginTop: theme.spacing(1),
    minWidth: 180,
    color:
      theme.palette.mode === "light"
        ? "rgb(55, 65, 81)"
        : theme.palette.grey[300],
    boxShadow:
      "rgb(255, 255, 255) 0px 0px 0px 0px, rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px",
    "& .MuiMenu-list": {
      padding: "4px 0",
    },
    "& .MuiMenuItem-root": {
      "& .MuiSvgIcon-root": {
        fontSize: 18,
        color: theme.palette.text.secondary,
        marginRight: theme.spacing(1.5),
      },
      "&:active": {
        backgroundColor: alpha(
          theme.palette.primary.main,
          theme.palette.action.selectedOpacity
        ),
      },
    },
  },
}));

export const FilaPropiedad = ({
  propiedad,
  i,
  isMobile,
  showConfirmationDialog,
  descargarFicha,
  showDeleteConfirmationDialog,
  handleOpenEditModal,
  onCopyLink,
  handleDialogYapoOpen,
  publicarEnPortalYapo,
  handleDialogPIOpen,
  publicarEnPortalPI,
  handleOpenBrokerModal,
}) => {
  const userData = useSelector((state) => state.app.itemUsuario);
  const usuarios = useSelector((state) => state.app.itemsUsuarios);

  const [anchorEl, setAnchorEl] = useState(null);
  const openMenu = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const GreenSwitch = styled(Switch)(({ theme }) => ({
    "& .MuiSwitch-switchBase.Mui-checked": {
      color: green[600],
      "&:hover": {
        backgroundColor: alpha(green[600], theme.palette.action.hoverOpacity),
      },
    },
    "& .MuiSwitch-switchBase.Mui-checked + .MuiSwitch-track": {
      backgroundColor: green[600],
    },
  }));

  const { formatToThousandSeparator } = utilsFunc;

  const getNombreUsuario = (id) => {
    const user =
      usuarios &&
      usuarios.some((u) => u.id === id) &&
      usuarios.find((u) => u.id === id);
    if (user) {
      return `${user.nombres} ${user.apellidos}`;
    }
    return "";
  };

  if (isMobile) {
    return (
      <React.Fragment>
        <TableRow
          className={i % 2 === 0 ? "filaOscura" : ""}
          sx={{ "& > *": { borderBottom: "unset" } }}
        >
          <TableCell align="center">{propiedad && propiedad.comuna}</TableCell>
          <TableCell align="center">
            {propiedad && propiedad.codigoPropiedad}
          </TableCell>
          <TableCell align="center">
            {formatToThousandSeparator(propiedad.valor)} {propiedad.tipoPrecio}
          </TableCell>
          <TableCell padding={"0.5rem"} align="center">
            <IconButton
              onClick={handleClick}
              //color="danger"
              aria-label="acciones"
              component="span"
            >
              <MoreVert />
            </IconButton>
            <StyledMenu
              id="demo-customized-menu"
              MenuListProps={{
                "aria-labelledby": "demo-customized-button",
              }}
              anchorEl={anchorEl}
              open={openMenu}
              onClose={handleClose}
            >
              <Link to={"/mispropiedades/datosFicha?id=" + propiedad.id}>
                <MenuItem disableRipple>
                  <Visibility />
                  Ver ficha
                </MenuItem>
              </Link>
              <MenuItem
                disableRipple
                onClick={() =>
                  showConfirmationDialog(
                    propiedad,
                    propiedad.tarjetaPropiedadHabilitada
                  )
                }
              >
                {propiedad.tarjetaPropiedadHabilitada ? (
                  <ToggleOn sx={{ color: "#43a047 !important" }} />
                ) : (
                  <ToggleOff />
                )}
                {propiedad.tarjetaPropiedadHabilitada
                  ? "Deshabilitar tarjeta"
                  : "Habilitar tarjeta"}
              </MenuItem>
              <MenuItem
                onClick={() => descargarFicha(propiedad.id)}
                disableRipple
              >
                <Download />
                Descargar ficha
              </MenuItem>
              <MenuItem
                disableRipple
                onClick={() => showDeleteConfirmationDialog(propiedad.id)}
              >
                <DeleteIcon sx={{ color: red[500] }} fontSize="small" />
                Eliminar
              </MenuItem>
              {propiedad.tarjetaPropiedadHabilitada && (
                <MenuItem disableRipple onClick={() => onCopyLink(propiedad)}>
                  <InsertLinkIcon fontSize="small" />
                  Copiar enlace
                </MenuItem>
              )}
              {userData.tipoCuenta !== 10 && (
                <>
                  {propiedad.publicadaPortalInmobiliario ? (
                    <MenuItem
                      disableRipple
                      onClick={() => handleDialogPIOpen(propiedad)}
                    >
                      <LocationOn fontSize="small" />
                      Ver datos de publicacion en Portal Inmobiliario
                    </MenuItem>
                  ) : (
                    <MenuItem
                      disableRipple
                      onClick={() => publicarEnPortalPI(propiedad)}
                    >
                      <LocationOn fontSize="small" />
                      Publicar en Portal Inmobiliario
                    </MenuItem>
                  )}
                  {propiedad.publicadoEnYapo ? (
                    <MenuItem
                      disableRipple
                      onClick={() => handleDialogYapoOpen(propiedad)}
                    >
                      <PostAdd fontSize="small" />
                      Ver datos de publicacion en Yapo
                    </MenuItem>
                  ) : (
                    <MenuItem
                      disableRipple
                      onClick={() => publicarEnPortalYapo(propiedad)}
                    >
                      <PostAdd fontSize="small" />
                      Publicar en Yapo
                    </MenuItem>
                  )}
                  <MenuItem
                    disableRipple
                    onClick={() => handleOpenBrokerModal(propiedad)}
                  >
                    <Person fontSize="small" />
                    Ver broker
                  </MenuItem>
                </>
              )}
            </StyledMenu>
          </TableCell>
        </TableRow>
      </React.Fragment>
    );
  } else {
    return (
      <React.Fragment>
        <TableRow
          className={i % 2 === 0 ? "filaOscura" : ""}
          sx={{ "& > *": { borderBottom: "unset" } }}
        >
          <TableCell align="center">
            {propiedad &&
              `${formatToThousandSeparator(propiedad.valor)} ${
                propiedad.tipoPrecio
              }`}
          </TableCell>
          <TableCell align="center">
            {propiedad &&
            propiedad.planContratado &&
            propiedad.planContratado.esVenta
              ? "Venta"
              : "Arriendo"}
          </TableCell>
          <TableCell align="center">{propiedad && propiedad.comuna}</TableCell>
          <TableCell align="center">
            {propiedad && propiedad.codigoPropiedad}
          </TableCell>
          <TableCell align="center">
            {propiedad && propiedad.tipoPropiedad}
          </TableCell>
          <TableCell align="center">
            {propiedad && `${propiedad.dormitorios}D ${propiedad.banio}B`}
          </TableCell>
          <TableCell align="center">
            {propiedad && ESTADOS_PROPIEDAD[propiedad.estadoPropiedad]}
          </TableCell>
          <TableCell align="center">
            <Link
              className="link"
              to={"/mispropiedades/datosFicha?id=" + propiedad.id}
            >
              Ver ficha
            </Link>
          </TableCell>
          <TableCell align="center">
            <Tooltip
              title={
                propiedad.tarjetaPropiedadHabilitada
                  ? "Deshabilitar tarjeta propiedad"
                  : "Habilitar tarjeta propiedad"
              }
              aria-label="habilitar"
            >
              <GreenSwitch
                checked={propiedad.tarjetaPropiedadHabilitada}
                onChange={() =>
                  showConfirmationDialog(
                    propiedad,
                    propiedad.tarjetaPropiedadHabilitada
                  )
                }
              />
            </Tooltip>
            <Tooltip title={"Descargar ficha"} aria-label="download">
              <IconButton
                //color="danger"
                aria-label="download"
                component="span"
                onClick={() => descargarFicha(propiedad.id)}
              >
                <Download />
              </IconButton>
            </Tooltip>
            <Tooltip title="Eliminar" aria-label="eliminar">
              <IconButton
                //color="danger"
                aria-label="eliminar"
                component="span"
                onClick={() => showDeleteConfirmationDialog(propiedad.id)}
              >
                <DeleteIcon sx={{ color: red[500] }} />
              </IconButton>
            </Tooltip>
            <Tooltip title="Editar" aria-label="editar">
              <IconButton
                //color="primary"
                aria-label="Editar"
                component="span"
                onClick={() => handleOpenEditModal(propiedad)}
              >
                <EditIcon />
              </IconButton>
            </Tooltip>
            {propiedad.tarjetaPropiedadHabilitada && (
              <Tooltip title="Copiar enlace" aria-label="copiar">
                <IconButton
                  color="primary"
                  aria-label="copiar"
                  component="span"
                  onClick={() => onCopyLink(propiedad)}
                >
                  <InsertLinkIcon />
                </IconButton>
              </Tooltip>
            )}

            {userData.tipoCuenta !== 10 && (
              <>
                {propiedad.idBroker != "" && propiedad.idBroker != null ? (
                  <Tooltip
                    title={`Broker asociado: ${getNombreUsuario(
                      propiedad.idBroker
                    )}`}
                    aria-label="broker"
                  >
                    <IconButton
                      color="primary"
                      aria-label="broker"
                      component="span"
                      onClick={() => handleOpenBrokerModal(propiedad)}
                    >
                      <Person />
                    </IconButton>
                  </Tooltip>
                ) : (
                  <Tooltip
                    title="Sin broker asociado. Haga click para asociar broker"
                    aria-label="broker"
                  >
                    <IconButton
                      aria-label="broker"
                      component="span"
                      className="iconbutton-habilitado"
                      onClick={() => handleOpenBrokerModal(propiedad)}
                    >
                      <Person />
                    </IconButton>
                  </Tooltip>
                )}
                {propiedad.publicadoEnYapo ? (
                  <Tooltip
                    title="Ver datos de publicacion en Yapo"
                    aria-label="datos yapo"
                  >
                    <IconButton
                      color="primary"
                      aria-label="datos yapo"
                      component="span"
                      onClick={() => handleDialogYapoOpen(propiedad)}
                    >
                      <PostAdd />
                    </IconButton>
                  </Tooltip>
                ) : (
                  <Tooltip
                    title="Publicar en Yapo"
                    aria-label="publicar en yapo"
                  >
                    <IconButton
                      aria-label="publicar en yapo"
                      component="span"
                      className="iconbutton-habilitado"
                      onClick={() => publicarEnPortalYapo(propiedad)}
                    >
                      <PostAdd />
                    </IconButton>
                  </Tooltip>
                )}
                {propiedad.publicadaPortalInmobiliario ? (
                  <Tooltip
                    title="Ver datos de publicacion en Portal Inmobiliario"
                    aria-label="datos portal inmobiliario"
                  >
                    <IconButton
                      color="primary"
                      aria-label="datos portal inmobiliario"
                      component="span"
                      onClick={() => handleDialogPIOpen(propiedad)}
                    >
                      <LocationOn />
                    </IconButton>
                  </Tooltip>
                ) : (
                  <Tooltip
                    title="Publicar en Portal Inmobiliario"
                    aria-label="publicar en portal inmobiliario"
                  >
                    <IconButton
                      aria-label="publicar en portal inmobiliario"
                      component="span"
                      className="iconbutton-habilitado"
                      onClick={() => publicarEnPortalPI(propiedad)}
                    >
                      <LocationOn />
                    </IconButton>
                  </Tooltip>
                )}
              </>
            )}
          </TableCell>
        </TableRow>
      </React.Fragment>
    );
  }
};
