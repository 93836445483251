import { Formik, Form, Field } from "formik";
import { useDispatch, useSelector } from "react-redux";
import {
  Backdrop,
  Box,
  Button,
  CircularProgress,
  Grid,
  IconButton,
  Modal,
  Tooltip,
} from "@mui/material";
import DatePicker from "react-date-picker";
import { useEffect, useState } from "react";
import { actualizarVisitaReunion, agendarVisitaReunion } from "../../action";
import Swal from "sweetalert2";
import moment from "moment";
import utilsFunc from "../../utils/utilsFunctions";
import { TRAMOS } from "../../utils/constants";
import { Close, Delete } from "@mui/icons-material";
import * as Yup from "yup";
import FieldError from "../../utils/FieldError";

const validate = Yup.object().shape({
  //idBroker: Yup.string().required("Seleccione un broker"),
  titulo: Yup.string().required("Ingrese un titulo"),
});

const isMobile =
  !!navigator.userAgent.match(/iphone|android|blackberry/gi) || false;

const style = {
  position: "absolute",
  top: "0px",
  left: "50%",
  transform: "translate(-50%, 0%)",
  width: 500,
  bgcolor: "background.paper",
  boxShadow: 24,
  p: 4,
  //maxHeight: '90%'
};

const styleMobile = {
  position: "absolute",
  top: "0px",
  left: "0px",
  //transform: "translate(-50%, 0%)",
  width: "100vw",
  bgcolor: "background.paper",
  boxShadow: 24,
  p: 4,
  //maxHeight: '90%'
};

const FormIniciarCapacitacion = ({
  values,
  isOpen,
  handleClose,
  reloadMethod,
}) => {
  const dispatch = useDispatch();
  const requestUsuarioFiltrados = useSelector(
    (state) => state.app.requestUsuarioFiltrados
  );
  const brokers = useSelector((state) => state.app.usuariosFiltrados);

  const [isLoading, setIsLoading] = useState(false);

  const [fechaVisita, setFechaVisita] = useState(
    values && values.start ? values.start : null
  );
  const [horaVisita, setHoraVisita] = useState(
    values && values.horaVisita ? values.horaVisita : ""
  );
  const onChangeHoraVisita = (e) => setHoraVisita(e.target.value);

  useEffect(() => {
    if (values !== null) {
      if (values.start !== null) {
        setFechaVisita(values.start);
      }
      if (values.horaVisita !== null) {
        setHoraVisita(values.horaVisita);
      }
      if (values.brokers !== null) {
        setBrokersSeleccionados(values.brokers);
      } else {
        setBrokersSeleccionados([]);
      }
    }
  }, [values]);

  const requestAgendarVisita = useSelector(
    (state) => state.app.requestAgendarVisita
  );
  const requestActualizarReunion = useSelector(
    (state) => state.app.requestActualizarReunion
  );

  const errorMessage = useSelector((state) => state.app.errorMessage);

  const [idBrokerSeleccionado, setIdBrokerSeleccionado] = useState("");
  const [brokerSeleccionados, setBrokersSeleccionados] = useState(values && values.brokers ? values.brokers : []);
  const onAddBroker = () => {
    if (idBrokerSeleccionado !== "") {
      const broker =
        brokers && brokers.find((p) => p.id === idBrokerSeleccionado);
      if (!brokerSeleccionados.some((p) => p.id === idBrokerSeleccionado)) {
        // const direccionBase = `${prop.nombreCalle} ${prop.numero}`
        //const direccion = prop.tipoPropiedad === "Departamento" ? `${direccionBase} departamento ${prop.numeroDepartamento}` : `${direccionBase}`;
        const obj = {
          //direccion,
          id: broker.id,
          nombre: `${broker.nombres} ${broker.apellidos}`,
          email: broker.email,
          //codigoPropiedad: prop.codigoPropiedad
        };
        setBrokersSeleccionados([...brokerSeleccionados, obj]);
      }
    }
  };
  const onRemoveBroker = (broker) => {
    setBrokersSeleccionados(
      brokerSeleccionados.filter((p) => p.email !== broker.email)
    );
}
  const onChange = (e) => {
    setIdBrokerSeleccionado(e.target.value);
  };

  const handleSubmit = (formValues) => {
    let dayShowcalendar = moment(fechaVisita);

    let horaInicio = horaVisita.split("-")[0];
    let horaTermino = horaVisita.split("-")[1];
    const { getVisitEventCalendarLink } = utilsFunc;

    let ubicacion = "";

    let linkAddToGoogleCalendar = getVisitEventCalendarLink(
      ubicacion,
      moment(dayShowcalendar).format("YYYY-MM-DD"),
      horaInicio,
      horaTermino,
      "google"
    );
    let linkAddToOutlookCalendar = getVisitEventCalendarLink(
      ubicacion,
      moment(dayShowcalendar).format("YYYY-MM-DD"),
      horaInicio,
      horaTermino,
      "outlook"
    );

    let data = {
      titulo: formValues.titulo,
      idBroker: formValues.idBroker,
      fecha: new Date(moment(dayShowcalendar)),
      //clienteId: propiedad.idCliente,
      //usuarioId: idBroker,
      dia: moment(dayShowcalendar).isoWeekday(),
      tramo: horaVisita,
      //propiedadId: idCurrentProp,
      linkAgregarEventoAGoogleCalendar: linkAddToGoogleCalendar,
      linkAgregarEventoAOutlookCalendar: linkAddToOutlookCalendar,
      diasDuracion: 11,
      idCapacitacion: formValues.idCapacitacion,
      brokers: brokerSeleccionados,
      link: formValues.link
    };
    console.log(data);
    if (formValues.idCapacitacion) {
      dispatch(actualizarVisitaReunion(data));

    } else {
      dispatch(agendarVisitaReunion(data));

    }
  };

  useEffect(() => {
    if (
      requestUsuarioFiltrados === "LOADING" ||
      requestAgendarVisita === "LOADING" ||
      requestActualizarReunion === "LOADING"
    ) {
      setIsLoading(true);
    } else {
      setIsLoading(false);
    }
  }, [requestUsuarioFiltrados, requestAgendarVisita, requestActualizarReunion]);

  if (requestAgendarVisita === "SUCCESS") {
    handleClose();
    Swal.fire({
      title: "Visita agendada",
      text: "Se ha agendado la visita exitosamente",
      icon: "success",
    }).then((result) => {
      if (result.value || result.dismiss === "backdrop") {
        reloadMethod();
      }
    });
  }
  if (requestAgendarVisita === "ERROR" || requestActualizarReunion === "ERROR") {
    Swal.fire({
      title: "Error",
      text: errorMessage,
      icon: "error",
    });
  }
  
  if (requestActualizarReunion === "SUCCESS") {
    handleClose();
    Swal.fire({
      title: "Capacitación reagendada",
      text: "Se ha reagendado la capacitación exitosamente",
      icon: "success",
    }).then((result) => {
      if (result.value || result.dismiss === "backdrop") {
        reloadMethod();
      }
    });
  }
  
  return (
    <Modal
      disableScrollLock={false}
      open={isOpen}
      onClose={handleClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"         
      sx={{
        overflowY: "auto",
        top: isMobile ? "0px" : "10px",
        maxHeight: isMobile ? "100%" : "95%",
      }}
    >
      <Box sx={isMobile ? styleMobile : style}>
        {isLoading && (
          <Backdrop
            sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
            open={true}
            onClick={handleClose}
          >
            <CircularProgress color="inherit" />
          </Backdrop>
        )}
        <Grid
          container
          justifyContent="space-between"
          alignItems={"center"}
          sx={{ marginBottom: 2 }}
        >
          <Grid item xs={10} sm={10} md={10} lg={10} xl={10}>
            <h4>{values ? "Editar " : "Iniciar "} capacitación</h4>
          </Grid>
          <Grid item>
            <IconButton
              color="primary"
              aria-label="copiar"
              component="span"
              onClick={() => handleClose()}
            >
              <Close sx={{ fontSize: "20pt" }} />
            </IconButton>
          </Grid>
        </Grid>

        <Formik
          initialValues={{
            titulo: values && values.titulo ? values.titulo : "Capacitación ",
            idCapacitacion: values && values.idCapacitacion,
            link: values && values.link,
            //idBroker: "",
            fechaVisita,
            horaVisita,
          }}
          validationSchema={validate}
          onSubmit={handleSubmit}
        >
          {({
            values,
            errors,
            touched,
            handleChange,
            handleBlur,
            handleSubmit,
            setFieldValue,
            isSubmitting,
          }) => (
            <>
              <Form onSubmit={handleSubmit} className="form-container">
                <Grid container>
                  <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                    <label className="milabel">Titulo</label>
                    <Field name="titulo" type="text" className="miSelect" />
                    <FieldError name="titulo" />
                  </Grid>
                  <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                    <label className="milabel">Link</label>
                    <Field name="link" type="text" className="miSelect" />
                    <FieldError name="link" />
                  </Grid>
                </Grid>
                {requestUsuarioFiltrados === "LOADING" && <CircularProgress />}
                {requestUsuarioFiltrados === "IDLE" && brokers && (
                  <Grid container spacing={2} alignItems="flex-end">
                    <Grid item xs={7} sm={7} md={7} lg={7} xl={7}>
                      <label className="milabel">Broker</label>
                      <Field
                        name="idBroker"
                        as="select"
                        className="miSelect"
                        onChange={onChange}
                      >
                        <option value={""}>- Seleccione -</option>
                        {brokers.map((c) => (
                          <option value={c.id}>
                            {c.nombres} {c.apellidos}
                          </option>
                        ))}
                      </Field>
                    </Grid>
                    <Grid item>
                      <Button
                        className="main-btn"
                        variant="contained"
                        color="success"
                        onClick={() => onAddBroker()}
                      >
                        Agregar broker
                      </Button>
                    </Grid>
                  </Grid>
                )}
                <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                  {brokerSeleccionados.length === 0 && (
                    <div>No has agregado ningún broker todavía</div>
                  )}
                  {brokerSeleccionados.length > 0 &&
                    brokerSeleccionados.map((p) => {
                      return (
                        <Grid container alignItems="center">
                          <Grid item>{p && p.nombre}</Grid>
                          <Grid item>
                            <Tooltip title="Quitar" aria-label="quitar">
                              <IconButton
                                //color="primary"
                                aria-label="Quitar"
                                component="span"
                                onClick={() => onRemoveBroker(p)}
                              >
                                <Delete />
                              </IconButton>
                            </Tooltip>
                          </Grid>
                        </Grid>
                      );
                    })}
                </Grid>
                <Grid container>
                  <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                    <label className="milabel">Fecha inicio</label>
                    <DatePicker
                      name="fechaVisita"
                      value={fechaVisita}
                      onChange={setFechaVisita}
                    />
                  </Grid>
                </Grid>
                <Grid container>
                  <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                    <label className="milabel">Hora</label>
                    <Field
                      name="horaVisita"
                      as="select"
                      className="miSelect"
                      onChange={onChangeHoraVisita}
                      value={horaVisita}
                    >
                      {/* antes era tramos filtrados segun agenda del propietario, ahora se admiten todos los tramos posibles.
                        {tramos.map((c) => (
                          <option value={c.value}>{c.label}</option>
                        ))} */}
                      {TRAMOS.map((c) => (
                        <option value={c}>{c}</option>
                      ))}
                    </Field>
                  </Grid>
                </Grid>
                <Grid container justifyContent="flex-end" sx={{ mt: 2 }}>
                  <Grid item>
                    <Button onClick={handleClose} className="main-btn">
                      Cancelar
                    </Button>
                  </Grid>
                  <Grid item>
                    <Button
                      type="submit"
                      className="main-btn"
                      variant="contained"
                      color="success"
                    >
                      Guardar
                    </Button>
                  </Grid>
                </Grid>
              </Form>
            </>
          )}
        </Formik>
      </Box>
    </Modal>
  );
};

export default FormIniciarCapacitacion;
