import { Box, Button, Grid, Modal, Step, StepLabel, Stepper } from "@mui/material";

const isMobile =
  !!navigator.userAgent.match(/iphone|android|blackberry/gi) || false;

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 600,
  bgcolor: "background.paper",
  boxShadow: 24,
  p: 4,
};

const styleMobile = {
  position: "absolute",
  width: "100vw",
  bgcolor: "background.paper",
  boxShadow: 24,
  p: 4,
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
};

const steps = [
  "Documento creado en Propins",
  "Documento creado en GoFirmex",
  "Documento contrato asignado",
  "Documentos identidad asignados",
  "Procesando por GoFirmex",
  "Esperando ser firmado",
  "Finalizado",
];

const ModalDetalleProcesoFirma = ({
  portfolio,
  isOpen,
  handleClose,
  reloadMethod,
}) => {
  let activeStep = 0;
  if (portfolio !== null && portfolio !== undefined) {
    let documentosAsignados = true;
    if (portfolio.documentos.length === 0) {
      documentosAsignados = false;
    } else {
      portfolio.documentos.forEach((x) => {
        if (!x.asignado) {
          documentosAsignados = false;
          return;
        }
      });
    }
    let documentosIdentidadAsignados = true;
    if (portfolio.firmantes && portfolio.firmantes.length === 0) {
      documentosIdentidadAsignados = false;
    } else {
      portfolio.firmantes.forEach((x) => {
        x.documentosIdentidad.forEach((y) => {
          if (!y.asignado) {
            documentosIdentidadAsignados = false;
            return;
          }
        });
      });
    }
    if (portfolio.portafolioCompleto) {
      activeStep = 7;
    } else if (portfolio.evaluacionIniciada) {
      activeStep = 5;
    } else if (documentosIdentidadAsignados) {
      activeStep = 3;
    } else if (documentosAsignados) {
      activeStep = 2;
    } else if (portfolio.portafolioCreado) {
      activeStep = 1;
    }
  }

  return (
    <Modal
      open={isOpen}
      onClose={handleClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box sx={isMobile ? styleMobile : style}>
        <h4>Detalle proceso firma</h4>
        <Stepper
          activeStep={activeStep}
          alternativeLabel
          sx={{ mt: 4, alignItems: "unset" }}
          orientation={isMobile ? "vertical" : "horizontal"}
        >
          {steps.map((label) => (
            <Step key={label}>
              <StepLabel>{label}</StepLabel>
            </Step>
          ))}
        </Stepper>
        <Grid container spacing={4} justifyContent="flex-end">
          <Grid item>
            <Button onClick={handleClose} className="main-btn">
              Cerrar
            </Button>
          </Grid>
        </Grid>
      </Box>
    </Modal>
  );
};

export default ModalDetalleProcesoFirma;
