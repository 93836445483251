import ListaActividades from "../../Actividades/ListaActividades";
import InfoProspecto from "../InfoProspecto";
import AddHomeOutlinedIcon from "@mui/icons-material/AddHomeOutlined";
import { Link, useLocation } from "react-router-dom";
import ProspectoVendedorNavegacionMobile from "./ProspectoVendedorNavegacionMobile";
import Back from "../../../assets/Back";

const isMobile =
  !!navigator.userAgent.match(/iphone|android|blackberry/gi) || false;

const PerfilProsActividades = () => {
  let { search } = useLocation();
  let query = new URLSearchParams(search);
  let perfil = query.get("id");
  return (
    <div className="contenido">
      <Back urlPath="/prospectos/vendedor" />
      <div className="header">
        <h2>Perfil Prospecto</h2>

        <button className="anadirPropi">
          {" "}
          <AddHomeOutlinedIcon /> Anadir a propietario
        </button>
      </div>
      <InfoProspecto />
      { !isMobile ? (
        <div className="opciones">
          <Link to={`/prospectos/vendedor/propiedad?id=${perfil}`}>
            <h3
              className="opcionPropiedad"
              id={
                window.location.pathname.includes("propiedad")
                  ? "active1"
                  : "notActive1"
              }
            >
              Datos Propiedad
            </h3>
          </Link>
          
          <Link to={`/prospectos/vendedor/actividades?id=${perfil}`}>
            <h3
              className="opcionActividades"
              id={
                window.location.pathname.includes("actividades")
                  ? "active1"
                  : "notActive1"
              }
            >
              Actividades
            </h3>
          </Link>
          
      </div>
      ) : (
        <ProspectoVendedorNavegacionMobile id={perfil} />
      )}
      <div className="barraNav">
        <div>
          <select className="seleccionador">
            <option>Ordenar por </option>
          </select>
          <input className="buscar" placeholder="Buscar"></input>
        </div>
      </div>
      <div className="main">
        <ListaActividades idPropiedad={""} idCliente={""} idProspecto={perfil} />
      </div>
    </div>
  );
};
export default PerfilProsActividades;
