import * as React from "react";
import Tooltip from "@mui/material/Tooltip";
import IconButton from "@mui/material/IconButton";
import TableCell from "@mui/material/TableCell";
import TableRow from "@mui/material/TableRow";
import {
  ESTADO_PROCESO_PROSPECTO,
  TIPO_CONTRATO_PROSPECTO,
} from "../../utils/constants";
import { useState } from "react";
import { Menu, MenuItem } from "@mui/material";
import EmailOutlinedIcon from "@mui/icons-material/EmailOutlined";
import WhatsAppIcon from "@mui/icons-material/WhatsApp";
import LocalPhoneIcon from "@mui/icons-material/LocalPhone";
import AddHomeOutlinedIcon from "@mui/icons-material/AddHomeOutlined";
import { Link } from "react-router-dom";
import { Delete, MoreVert, Stop, Visibility } from "@mui/icons-material";
import { alpha, styled } from "@mui/material/styles";
import { deleteProspecto } from "../../action";
import Swal from "sweetalert2";
import { useDispatch } from "react-redux";

const StyledMenu = styled((props) => (
  <Menu
    elevation={0}
    anchorOrigin={{
      vertical: "bottom",
      horizontal: "right",
    }}
    transformOrigin={{
      vertical: "top",
      horizontal: "right",
    }}
    {...props}
  />
))(({ theme }) => ({
  "& .MuiPaper-root": {
    borderRadius: 6,
    marginTop: theme.spacing(1),
    minWidth: 180,
    color:
      theme.palette.mode === "light"
        ? "rgb(55, 65, 81)"
        : theme.palette.grey[300],
    boxShadow:
      "rgb(255, 255, 255) 0px 0px 0px 0px, rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px",
    "& .MuiMenu-list": {
      padding: "4px 0",
    },
    "& .MuiMenuItem-root": {
      "& .MuiSvgIcon-root": {
        fontSize: 18,
        color: theme.palette.text.secondary,
        marginRight: theme.spacing(1.5),
      },
      "&:active": {
        backgroundColor: alpha(
          theme.palette.primary.main,
          theme.palette.action.selectedOpacity
        ),
      },
    },
  },
}));

export const FilaProspecto = ({
  prospecto,
  i,
  isMobile,
  tipo,
  pausarProspecto,
}) => {
  const dispatch = useDispatch();
  const [anchorEl, setAnchorEl] = useState(null);
  const openMenu = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const eliminar = () => {
    Swal.fire({
      title: `Eliminar prospecto`,
      text: `¿Está seguro de eliminar el prospecto?`,      
      showCancelButton: true,
      confirmButtonText: "Eliminar",
      cancelButtonText: `Cancelar`,
    }).then((result) => {
      if (result.value && prospecto && prospecto.id && tipo) {
        //handleCloseDialogPI();
        dispatch(deleteProspecto(tipo, prospecto.id));
      }
    });
  };

  let telefonoWhatsapp = prospecto.telefono;
  let telefonoLlamada = prospecto.telefono;
  if (telefonoWhatsapp !== null && telefonoWhatsapp !== undefined) {
    telefonoWhatsapp = telefonoWhatsapp.trim();
    telefonoWhatsapp = telefonoWhatsapp.replace("+", "");
    if (telefonoWhatsapp.length === 8) {
      telefonoWhatsapp = `569${telefonoWhatsapp}`;
    } else if (telefonoWhatsapp.length === 9) {
      telefonoWhatsapp = `56${telefonoWhatsapp}`;
    } else {
    }
  }
  if (telefonoLlamada !== null && telefonoLlamada !== undefined) {
    telefonoLlamada = telefonoLlamada.trim();
    if (telefonoLlamada.length === 8) {
      telefonoLlamada = `569${telefonoLlamada}`;
    } else if (telefonoLlamada.length === 9) {
      telefonoLlamada = `56${telefonoLlamada}`;
    } else if (telefonoLlamada.length === 11) {
      telefonoLlamada = `+56${telefonoLlamada}`;
    } else {
    }
  }

  if (isMobile) {
    return (
      <React.Fragment>
        <TableRow
          className={i % 2 === 0 ? "filaOscura" : ""}
          sx={{ "& > *": { borderBottom: "unset" } }}
        >
          <TableCell align="center">
            {prospecto && `${prospecto.nombres} ${prospecto.apellidos}`}
          </TableCell>

          <TableCell align="center">
            <IconButton
              onClick={() => (window.location = `mailto:${prospecto.email}`)}
              //color="danger"
              aria-label="email"
              component="span"
            >
              <EmailOutlinedIcon />
            </IconButton>
            <a
              href={`https://api.whatsapp.com/send?phone=${telefonoWhatsapp}`}
              target="_blank"
              rel="noreferrer"
            >
              <IconButton
                //color="danger"
                aria-label="whatsapp"
                component="span"
              >
                <WhatsAppIcon />
              </IconButton>
            </a>
            <IconButton
              onClick={() => (window.location = `tel:${telefonoLlamada}`)}
              //color="danger"
              aria-label="llamar"
              component="span"
            >
              <LocalPhoneIcon />
            </IconButton>
          </TableCell>
          <TableCell padding={"0.5rem"} align="center">
            <IconButton
              onClick={handleClick}
              //color="danger"
              aria-label="acciones"
              component="span"
            >
              <MoreVert />
            </IconButton>
            <StyledMenu
              id="demo-customized-menu"
              MenuListProps={{
                "aria-labelledby": "demo-customized-button",
              }}
              anchorEl={anchorEl}
              open={openMenu}
              onClose={handleClose}
            >
              <Link
                to={
                  tipo === "va"
                    ? `/prospectos/vendedor/propiedad?id=${prospecto.id}`
                    : `/prospectos/comprador/requerimientos?id=${prospecto.id}`
                }
              >
                <MenuItem disableRipple>
                  <Visibility />
                  Ver perfil
                </MenuItem>
              </Link>
              <MenuItem
                disableRipple
                onClick={() => pausarProspecto(prospecto)}
              >
                <Stop />
                Pausar
              </MenuItem>
              <MenuItem
                disableRipple
                onClick={() => eliminar()}
              >
                <Delete />
                Eliminar
              </MenuItem>
            </StyledMenu>
          </TableCell>
        </TableRow>
      </React.Fragment>
    );
  } else {
    return (
      <React.Fragment>
        <TableRow
          className={i % 2 === 0 ? "filaOscura" : ""}
          sx={{ "& > *": { borderBottom: "unset" } }}
        >
          <TableCell align="center">
            {prospecto && `${prospecto.nombres} ${prospecto.apellidos}`}
          </TableCell>

          {tipo === "va" && (
            <TableCell align="center">
              {prospecto &&
                TIPO_CONTRATO_PROSPECTO[prospecto.tipoContratoProspecto]}
            </TableCell>
          )}
          <TableCell align="center">
            {prospecto &&
              ESTADO_PROCESO_PROSPECTO[prospecto.estadoProcesoProspecto]}
          </TableCell>
          <TableCell align="center">
            <Tooltip title="Enviar email" aria-label="email">
              <IconButton
                onClick={() => (window.location = `mailto:${prospecto.email}`)}
                //color="danger"
                aria-label="email"
                component="span"
              >
                <EmailOutlinedIcon />
              </IconButton>
            </Tooltip>
            <a
              href={`https://api.whatsapp.com/send?phone=${telefonoWhatsapp}`}
              target="_blank"
              rel="noreferrer"
            >
              <Tooltip title="Whatsapp" aria-label="whatsapp">
                <IconButton
                  //color="danger"
                  aria-label="whatsapp"
                  component="span"
                >
                  <WhatsAppIcon />
                </IconButton>
              </Tooltip>
            </a>
            <Tooltip title="Llamar" aria-label="llamar">
              <IconButton
                onClick={() => (window.location = `tel:${telefonoLlamada}`)}
                //color="danger"
                aria-label="llamar"
                component="span"
              >
                <LocalPhoneIcon />
              </IconButton>
            </Tooltip>
          </TableCell>
          <TableCell align="center">
            {tipo === "va" ? (
              <Link to={`/propietarios/agregar?idProspecto=${prospecto.id}`}>
                <button className="miButton">
                  {" "}
                  <AddHomeOutlinedIcon /> Anadir a propietario
                </button>
              </Link>
            ) : (
              <Link
                to={`/prospectos/comprador/requerimientos?id=${prospecto.id}`}
                className="link"
              >
                Tienes nuevos match
              </Link>
            )}
          </TableCell>
          <TableCell align="center">
            <Link
              to={
                tipo === "va"
                  ? `/prospectos/vendedor/propiedad?id=${prospecto.id}`
                  : `/prospectos/comprador/requerimientos?id=${prospecto.id}`
              }
              className="link"
            >
              Ver perfil
            </Link>
          </TableCell>
          <TableCell align="center">
            <Tooltip title="Acciones" aria-label="acciones">
              <IconButton
                onClick={handleClick}
                //color="danger"
                aria-label="acciones"
                component="span"
              >
                <MoreVert />
              </IconButton>
            </Tooltip>
            <Menu
              id="basic-menu"
              anchorEl={anchorEl}
              open={openMenu}
              onClose={handleClose}
              MenuListProps={{
                "aria-labelledby": "basic-button",
              }}
            >
              <MenuItem onClick={() => pausarProspecto(prospecto)}>
                Pausar
              </MenuItem>
              <MenuItem
                disableRipple
                onClick={() => eliminar()}
              >
                <Delete />
                Eliminar
              </MenuItem>
            </Menu>
          </TableCell>
        </TableRow>
      </React.Fragment>
    );
  }
};
