export function authHeader() {
  // return authorization header with jwt token
  // let jsonUser = localStorage.getItem("user");

  // if (
  //   jsonUser &&
  //   /^[\],:{}\s]*$/.test(
  //     jsonUser
  //       .replace(/\\["\\\/bfnrtu]/g, "@")
  //       .replace(
  //         /"[^"\\\n\r]*"|true|false|null|-?\d+(?:\.\d*)?(?:[eE][+\-]?\d+)?/g,
  //         "]"
  //       )
  //       .replace(/(?:^|:|,)(?:\s*\[)+/g, "")
  //   )
  // ) {
  let userDataLocalStorage = localStorage.getItem("user");
  //console.log(userDataLocalStorage)
  if (userDataLocalStorage && isJsonString(userDataLocalStorage)) {
    let user = JSON.parse(localStorage.getItem("user"));
    if (user && user.token) {
      return "Bearer " + user.token;
    } else {
      return null;
    }
  } else {
    return null;
  }
  
  //   } else {
  //     localStorage.removeItem("user");
  //     return;
  //   }
}

function isJsonString(str) {
  try {
      JSON.parse(str);
  } catch (e) {
      return false;
  }
  return true;
}