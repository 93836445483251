import {
  Grid,
} from "@mui/material";
import utilsFunc from "../../../utils/utilsFunctions";
import { useDispatch, useSelector } from "react-redux";
import { fetchGetAllUsuarios } from "../../../action";
import { useEffect } from "react";

const FichaPropiedad = ({ prop }) => {
  const dispatch = useDispatch();
  const { formatToThousandSeparator } = utilsFunc;
  const usuarios = useSelector((state) => state.app.itemsUsuarios);
  const regiones = useSelector(
    (state) =>
      state.app.itemRegiones &&
      state.app.itemRegiones.length > 0 &&
      state.app.itemRegiones[0].regiones
  );

  useEffect(() => {
    dispatch(fetchGetAllUsuarios());
  }, []);

  let tipoDireccion = "";
  let direccion = "";
  if (prop != null) {
    tipoDireccion =
      prop.tipoPropiedad == "Departamento" ? "depto" + prop.ap : "";
    direccion =
      prop.nombreCalle == null
        ? `${prop.direccionReferencial} ${prop.numero} ${tipoDireccion}`
        : `${prop.nombreCalle} ${prop.numero} ${tipoDireccion}`;
  }

  const getNombreUsuario = (id) => {
    const user =
      usuarios && usuarios.some((u) => u.id === id) && usuarios.find((u) => u.id === id);
    if (user) {
      return `${user.nombres} ${user.apellidos}`;
    }
    return "";
  };

  const getNombreRegion = (id) => {
    const region =
      regiones && regiones.some((u) => u.numero == id) && regiones.find((u) => u.numero == id);
    if (region) {
      return region.region;
    }
    return "";
  }

  if (prop == null || prop == undefined) {
    <div>No se ha encontrado la propiedad</div>
  } else {
    return (
      <div className="main">        
        <Grid container>
          <Grid item xs={6} sm={6} md={4} lg={4} xl={4}>
            <h3 className="alignLeft">Información general</h3>
          </Grid>
        </Grid>
        <Grid container spacing={1}>
          <Grid item xs={6} sm={6} md={4} lg={4} xl={3}>
            <label className="otrolabel">Plan</label>
            <label className="otrolabel2">
              {prop.planContratado && prop.planContratado.nombre}
            </label>
          </Grid>
          <Grid item xs={6} sm={6} md={4} lg={4} xl={3}>
            <label className="otrolabel">Precio</label>
            <label className="otrolabel2">
              {prop.valor &&
                prop.valor > 0 &&
                formatToThousandSeparator(prop.valor)}{" "}
              {prop.tipoPrecio}
            </label>
          </Grid>
          <Grid item xs={6} sm={6} md={4} lg={4} xl={3}>
            <label className="otrolabel">Código</label>
            <label className="otrolabel2">{prop.codigoPropiedad}</label>
          </Grid>
          <Grid item xs={6} sm={6} md={4} lg={4} xl={3}>
            <label className="otrolabel">Broker asociado</label>
            <label className="otrolabel2">{getNombreUsuario(prop.idBroker)}</label>
          </Grid>
          <Grid item xs={6} sm={6} md={4} lg={4} xl={3}>
            <label className="otrolabel">Subido por</label>
            <label className="otrolabel2">{getNombreUsuario(prop.subidoPor)}</label>
          </Grid>
        </Grid>
        <Grid container sx={{ mt: 4 }}>
          <Grid item xs={6} sm={6} md={4} lg={4} xl={4}>
            <h3 className="alignLeft">Ubicación</h3>
          </Grid>
        </Grid>
        <Grid container spacing={1}>
          <Grid item xs={6} sm={6} md={4} lg={4} xl={3}>
            <label className="otrolabel">Región</label>
            <label className="otrolabel2">{getNombreRegion(prop.region)}</label>
          </Grid>
          <Grid item xs={6} sm={6} md={4} lg={4} xl={3}>
            <label className="otrolabel">Comuna</label>
            <label className="otrolabel2">{prop.comuna}</label>
          </Grid>
          <Grid item xs={6} sm={6} md={4} lg={4} xl={3}>
            <label className="otrolabel">Barrio</label>
            <label className="otrolabel2">{prop.barrio ?? "Sin asignar"}</label>
          </Grid>
          <Grid item xs={6} sm={6} md={4} lg={4} xl={3}>
            <label className="otrolabel">Dirección</label>
            <label className="otrolabel2">{direccion}</label>
          </Grid>
          <Grid item xs={6} sm={6} md={4} lg={4} xl={3}>
            <label className="otrolabel">Número</label>
            <label className="otrolabel2">{prop.numero}</label>
          </Grid>
        </Grid>
        <Grid container spacing={1} sx={{ mt: 4 }}>
          <Grid item xs={6} sm={6} md={4} lg={4} xl={4}>
            <h3 className="alignLeft">Características</h3>
          </Grid>
        </Grid>
        <Grid container spacing={1}>
          <Grid item xs={6} sm={6} md={4} lg={4} xl={2}>
            <label className="otrolabel">Tipo propiedad</label>
            <label className="otrolabel2">{prop.tipoPropiedad}</label>
          </Grid>
          <Grid item xs={6} sm={6} md={4} lg={4} xl={2}>
            <label className="otrolabel">Dormitorios</label>
            <label className="otrolabel2">{prop.dormitorios}</label>
          </Grid>
          <Grid item xs={6} sm={6} md={4} lg={4} xl={2}>
            <label className="otrolabel">Baños</label>
            <label className="otrolabel2">{prop.banio}</label>
          </Grid>
          <Grid item xs={6} sm={6} md={4} lg={4} xl={2}>
            <label className="otrolabel">Estacionamientos</label>
            <label className="otrolabel2">{prop.cantEstacionamiento}</label>
          </Grid>
          <Grid item xs={6} sm={6} md={4} lg={4} xl={2}>
            <label className="otrolabel">Superficie total</label>
            <label className="otrolabel2">{prop.superficieTotales}</label>
          </Grid>
          <Grid item xs={6} sm={6} md={4} lg={4} xl={2}>
            <label className="otrolabel">Superficie útil</label>
            <label className="otrolabel2">{prop.superficieUtil}</label>
          </Grid>
          <Grid item xs={6} sm={6} md={4} lg={4} xl={2}>
            <label className="otrolabel">Gastos comunes</label>
            <label className="otrolabel2">
              {prop.gastosComunes &&
                prop.gastosComunes > 0 &&
                formatToThousandSeparator(prop.gastosComunes)}{" "}
              CLP
            </label>
          </Grid>
        </Grid>
        <Grid container spacing={1} sx={{ mt: 4 }}>
          <Grid item xs={12} sm={6} md={8} lg={8} xl={8}>
            <Grid container>
              <Grid item>
                <h3 className="otrolabel">Más características</h3>
              </Grid>
            </Grid>
            {prop && (prop.carCom || prop.propCar) && (
              <Grid container spacing={0.5} rowSpacing={0}>
                {prop.carCom && prop.carCom.accesoControlado && (
                  <Grid item xs={12} sm={12} md={6} lg={4} xl={4}>
                    <label className="otrolabel3">Acceso controlado</label>
                  </Grid>
                )}
                {prop.carCom && prop.carCom.areasVerdes && (
                  <Grid item xs={12} sm={12} md={6} lg={4} xl={4}>
                    <label className="otrolabel3">Áreas verdes</label>
                  </Grid>
                )}
                {prop.carCom && prop.carCom.bicicletros && (
                  <Grid item xs={12} sm={12} md={6} lg={4} xl={4}>
                    <label className="otrolabel3">Bicicleteros</label>
                  </Grid>
                )}
                {prop.carCom && prop.carCom.camaraSeguridad && (
                  <Grid item xs={12} sm={12} md={6} lg={4} xl={4}>
                    <label className="otrolabel3">Cámara seguridad</label>
                  </Grid>
                )}
                {prop.carCom && prop.carCom.citofono && (
                  <Grid item xs={12} sm={12} md={6} lg={4} xl={4}>
                    <label className="otrolabel3">Citófono</label>
                  </Grid>
                )}
                {prop.carCom && prop.carCom.estVisita && (
                  <Grid item xs={12} sm={12} md={6} lg={4} xl={4}>
                    <label className="otrolabel3">Estacionamiento visitas</label>
                  </Grid>
                )}
                {prop.carCom && prop.carCom.juegosInf && (
                  <Grid item xs={12} sm={12} md={6} lg={4} xl={4}>
                    <label className="otrolabel3">Juegos infantiles</label>
                  </Grid>
                )}
                {prop.carCom && prop.carCom.piscina && (
                  <Grid item xs={12} sm={12} md={6} lg={4} xl={4}>
                    <label className="otrolabel3">Piscina</label>
                  </Grid>
                )}
                {prop.carCom && prop.carCom.portonElec && (
                  <Grid item xs={12} sm={12} md={6} lg={4} xl={4}>
                    <label className="otrolabel3">Portón eléctrico</label>
                  </Grid>
                )}
                {prop.carCom && prop.carCom.quincho && (
                  <Grid item xs={12} sm={12} md={6} lg={4} xl={4}>
                    <label className="otrolabel3">Quincho</label>
                  </Grid>
                )}
                {prop.carCom && prop.carCom.salaDeCine && (
                  <Grid item xs={12} sm={12} md={6} lg={4} xl={4}>
                    <label className="otrolabel3">Sala de cine</label>
                  </Grid>
                )}
                {prop.carCom && prop.carCom.salaDeEventos && (
                  <Grid item xs={12} sm={12} md={6} lg={4} xl={4}>
                    <label className="otrolabel3">Sala de eventos</label>
                  </Grid>
                )}
                {prop.carCom && prop.carCom.salonDeJuegos && (
                  <Grid item xs={12} sm={12} md={6} lg={4} xl={4}>
                    <label className="otrolabel3">Salón de juegos</label>
                  </Grid>
                )}
                {prop.carCom && prop.carCom.sauna && (
                  <Grid item xs={12} sm={12} md={6} lg={4} xl={4}>
                    <label className="otrolabel3">Sauna</label>
                  </Grid>
                )}
                {prop.propCar && prop.propCar.alarma && (
                  <Grid item xs={12} sm={12} md={6} lg={4} xl={4}>
                    <label className="otrolabel3">Alarma</label>
                  </Grid>
                )}
                {prop.propCar && prop.propCar.calefaccion && (
                  <Grid item xs={12} sm={12} md={6} lg={4} xl={4}>
                    <label className="otrolabel3">
                      Calefacción {prop.propCar.calefaccion}
                    </label>
                  </Grid>
                )}
                {prop.propCar && prop.propCar.escritorio && (
                  <Grid item xs={12} sm={12} md={6} lg={4} xl={4}>
                    <label className="otrolabel3">Escritorio</label>
                  </Grid>
                )}
                {prop.propCar && prop.propCar.logia && (
                  <Grid item xs={12} sm={12} md={6} lg={4} xl={4}>
                    <label className="otrolabel3">Logia</label>
                  </Grid>
                )}
                {prop.propCar && prop.propCar.portonAut && (
                  <Grid item xs={12} sm={12} md={6} lg={4} xl={4}>
                    <label className="otrolabel3">Portón automático</label>
                  </Grid>
                )}
                {prop.propCar && prop.propCar.salaDeEstar && (
                  <Grid item xs={12} sm={12} md={6} lg={4} xl={4}>
                    <label className="otrolabel3">Sala de estar</label>
                  </Grid>
                )}
              </Grid>
            )}
          </Grid>
  
          <Grid item xs={12} sm={6} md={4} lg={4} xl={4}>
            <Grid container spacing={1}>
              <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                <h3 className="otrolabel">Titulo</h3>
                <label className="otrolabel3">{prop.titulo}</label>
              </Grid>
              <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                <h3 className="otrolabel">Descripción</h3>
                <label className="otrolabel3">{prop.observacionesPublicas}</label>
              </Grid>              
            </Grid>
          </Grid>          
        </Grid>
      </div>
    ); 
  }
  
};

export default FichaPropiedad;