import { Button, CircularProgress, Grid } from "@mui/material";
import { Field, Form, Formik } from "formik";
import { useEffect } from "react";
import { useSelector } from "react-redux";
import FirmantesForm from "./FirmantesForm";

export const FormGenerarOrdenVenta = ({
  idPropiedad,
  handleSubmit,
  handleClose,
    //datosAlvaro,
  //guardarDatosAlvaro,
  //editDatosAlvaro,
  //setEditDatosAlvaro,
  onChangePropiedad,
  setFirmante,
  firmante,
  validationMessages,
  tipoDocumento,
  usarDocumentosAlvaro,
  onChangeUsarDocumentosAlvaro
}) => {
  const requestPropiedades = useSelector(
    (state) => state.app.requestPropiedadesPaginadas
  );
  const propiedades = useSelector(
    (state) =>
      state.app.propiedadesPaginadas && state.app.propiedadesPaginadas.results
  );
  const requestStateCliente = useSelector(
    (state) => state.app.requestGetCliente
  );
  const cliente = useSelector((state) => state.app.cliente);

  useEffect(() => {
    if (cliente !== null) {
      setFirmante({
        rut: cliente.rut,
        nombres: cliente.nombres,
        apellidos: cliente.apellidos,
        email: cliente.mail,
        telefono: cliente.telefono
      });
    }    
  }, [cliente]);

  const submitLocal = (formValues) => {
    setFirmante(
      {
        rut: cliente.rut,
        nombres: cliente.nombres,
        apellidos: cliente.apellidos,
        email: cliente.mail,
        telefono: cliente.telefono
      });
    
    handleSubmit(formValues);
  }

  return (
    <Formik
      initialValues={{
        idPropiedad,
        archivoVersoIdentidad: "",
        archivoReversoIdentidad: "",
        //archivoCertificadoDocumentoDominio: "",
        archivoVersoIdentidadPropins: "",
        archivoReversoIdentidadPropins: "",
      }}
      //validate={validate}
      onSubmit={submitLocal}
    >
      {({ values, errors, handleSubmit, setFieldValue }) => (
        <>
          <Form onSubmit={handleSubmit} className="form-container">
            <Grid container>
              {requestPropiedades === "LOADING" && <CircularProgress />}
              {requestPropiedades === "IDLE" && (
                <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                  <label className="milabel">Propiedad</label>
                  <Field
                    name="idPropiedad"
                    as="select"
                    className="miSelect"
                    value={idPropiedad}
                    onChange={onChangePropiedad}
                  >
                    <option value={""}>- Seleccione -</option>
                    {propiedades.map((c) => {
                      return (
                        <option value={c.id}>
                          {c.nombreCalle} {c.numero}
                        </option>
                      );
                    })}
                  </Field>
                </Grid>
              )}
            </Grid>
            {idPropiedad && requestStateCliente !== "LOADING" && cliente && (
              <FirmantesForm                
                setFieldValue={setFieldValue}
//datosAlvaro={datosAlvaro}
                //editDatosAlvaro={editDatosAlvaro}
                //setEditDatosAlvaro={setEditDatosAlvaro}
                //guardarDatosAlvaro={guardarDatosAlvaro}
                firmante={firmante}
                editPath={`/propietarios/propiedades?id=${cliente.id}`}
                tipoDocumento={tipoDocumento}
                usarDocumentosAlvaro={usarDocumentosAlvaro}
                onChangeUsarDocumentosAlvaro={onChangeUsarDocumentosAlvaro}
              />
            )}
            
            {validationMessages && validationMessages.length > 0 && (
              <Grid container sx={{ mt: 2 }}>
                <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                  Se han encontrado los siguientes problemas con la validación:
                </Grid>
                {validationMessages.map((v) => (
                  <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                    {v}
                  </Grid>
                ))}
              </Grid>
            )}

            <Grid container justifyContent="flex-end" sx={{ mt: 2 }}>
              <Grid item>
                <Button onClick={handleClose} className="main-btn">
                  Cancelar
                </Button>
              </Grid>
              <Grid item>
                <Button
                  type="submit"
                  className="main-btn"
                  variant="contained"
                  color="success"
                >
                  Guardar
                </Button>
              </Grid>
            </Grid>
          </Form>
        </>
      )}
    </Formik>
  );
};
