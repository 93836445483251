import { Link, useLocation } from "react-router-dom";
import Back from "../../../assets/Back";
import ListaActividades from "../../Actividades/ListaActividades";
import InfoProspecto from "../InfoProspecto";
import ProspectoCompradorNavegacionMobile from "./ProspectoCompradorNavegacionMobile";

const isMobile =
  !!navigator.userAgent.match(/iphone|android|blackberry/gi) || false;

const ActividadesComprador = () => {
  let { search } = useLocation();
  let query = new URLSearchParams(search);
  let perfil = query.get("id");
  return (
    <div className="contenido">
      <Back urlPath="/prospectos/comprador" />
      <div className="header">
        {" "}
        <h2>Perfil comprador</h2>
      </div>
      <InfoProspecto tipoProspecto={"ca"} />
      {!isMobile ? (
        <div className="opciones" style={{ borderBottom: "1px solid" }}>
          <Link
            id={
              window.location.pathname.includes("requerimientos")
                ? "active1"
                : "notActive1"
            }
            to={`/prospectos/comprador/requerimientos?id=${perfil}`}
          >
            <h3 className="requerimiento">Requerimientos</h3>
          </Link>
          <Link
            id={
              window.location.pathname.includes("actividades")
                ? "active1"
                : "notActive1"
            }
            to={`/prospectos/comprador/actividades?id=${perfil}`}
          >
            <h3 className="actvidades">Actividades</h3>
          </Link>
        </div>
      ) : (
        <ProspectoCompradorNavegacionMobile id={perfil} />
      )}
      <div className="main">
        <ListaActividades
          idPropiedad={""}
          idCliente={""}
          idProspecto={perfil}
        />
      </div>
    </div>
  );
};

export default ActividadesComprador;
