import { Formik, Form, Field } from "formik";
import { useDispatch, useSelector } from "react-redux";
import {
  Backdrop,
  Box,
  Button,
  CircularProgress,
  Grid,
  IconButton,
  Modal,
} from "@mui/material";
import DatePicker from "react-date-picker";
import { useEffect, useState } from "react";
import {
  cancelarVisita,
  postAgendarVisita,
  putAgendarVisita,
} from "../../action";
import Swal from "sweetalert2";
import moment from "moment";
import utilsFunc from "../../utils/utilsFunctions";
import { TRAMOS } from "../../utils/constants";
import { Close } from "@mui/icons-material";
import * as Yup from "yup";
import FieldError from "../../utils/FieldError";
import ErrorLabel from "../../utils/ErrorLabel";

const validate = Yup.object().shape({
  idPropiedad: Yup.string().required("Seleccione una propiedad"),
  idProspecto: Yup.string().required("Seleccione un prospecto"),
});

export const UPLOAD_IMAGE_MIME_TYPES = [
  "image/png",
  "image/x-citrix-png",
  "image/x-png",
  "image/jpeg",
  "image/x-citrix-jpeg",
  "image/pjpeg",
];

const isMobile =
  !!navigator.userAgent.match(/iphone|android|blackberry/gi) || false;

const style = {
  position: "absolute",
  top: "0px",
  left: "50%",
  transform: "translate(-50%, 0%)",
  width: 500,
  bgcolor: "background.paper",
  boxShadow: 24,
  p: 4,
  //maxHeight: '90%'
};

const styleMobile = {
  position: "absolute",
  top: "0px",
  left: "0px",
  //transform: "translate(-50%, 0%)",
  width: "100vw",
  bgcolor: "background.paper",
  boxShadow: 24,
  p: 4,
  //maxHeight: '90%'
};

const FormAgendarVisita = ({
  idBroker,
  isOpen,
  handleClose,
  idPropiedad,
  values,
  reloadMethod,
}) => {
  const dispatch = useDispatch();
  const [isLoading, setIsLoading] = useState(false);
  const [tramos, setTramos] = useState([]);
  const [showValidationErrorFechaVisita, setShowValidationErrorFechaVisita] =
    useState(false);
  const [showValidationErrorHoraVisita, setShowValidationErrorHoraVisita] =
    useState(false);
  const [fechaVisita, setFechaVisita] = useState(
    values && values.start ? values.start : null
  );
  const [horaVisita, setHoraVisita] = useState(
    values && values.horaVisita ? values.horaVisita : ""
  );
  const onChangeHoraVisita = (e) => {
    setHoraVisita(e.target.value);
  };

  const propiedad = useSelector(
    (state) => state.app.propiedad && state.app.propiedad.data
  );
  const requestGetPropiedad = useSelector(
    (state) => state.app.requestGetPropiedad
  );

  const requestAgendarVisita = useSelector(
    (state) => state.app.requestAgendarVisita
  );
  const requestPutAgendarVisita = useSelector(
    (state) => state.app.requestPutAgendarVisita
  );

  const requestCancelarVisita = useSelector(
    (state) => state.app.requestCancelarVisita
  );

  const propiedades = useSelector(
    (state) =>
      state.app.propiedadesPaginadas && state.app.propiedadesPaginadas.results
  );
  const requestPropiedades = useSelector(
    (state) => state.app.requestPropiedadesPaginadas
  );
  const agendaCliente = useSelector((state) => state.app.agendaCliente);
  const requestAgendaCliente = useSelector(
    (state) => state.app.requestAgendaCliente
  );
  const visitasBrokerSuscriptor = useSelector(
    (state) => state.app.visitasBrokerSuscriptor
  );
  const requestVisitasBrokerSuscriptor = useSelector(
    (state) => state.app.requestVisitasBrokerSuscriptor
  );

  const visitasCliente = useSelector(
    (state) => state.app.visitasCliente && state.app.visitasCliente.value
  );
  const requestVisitasCliente = useSelector(
    (state) => state.app.requestVisitasCliente
  );

  const prospectosCA = useSelector(
    (state) => state.app.prospectosCA && state.app.prospectosCA.results
  );
  const requestGetProspectosCA = useSelector(
    (state) => state.app.requestGetProspectosCA
  );

  const errorMessage = useSelector((state) => state.app.errorMessage);

  useEffect(() => {
    if (values !== null && values !== undefined) {
      if (values.start !== null) {
        setFechaVisita(values.start);
      }
      if (values.horaVisita !== null) {
        setHoraVisita(values.horaVisita);
      }
      if (values.idPropiedad !== null) {
        //setIdCurrentProp(values.idPropiedad);
      }
    }
  }, [values]);

  useEffect(() => {
    if (values === null) {
      setHoraVisita("");
    }
  }, [values]);

  useEffect(() => {
    //hola();
  }, [requestVisitasBrokerSuscriptor, requestVisitasBrokerSuscriptor]);

  const hola = () => {
    let dayNumber = 0;
    let bloquesDay = [];
    let tramosDisponibles = [];
    tramosDisponibles.push({
      label: "--Seleccione--",
      value: "",
    });
    dayNumber = moment(fechaVisita).isoWeekday();

    if (agendaCliente !== null) {
      if (dayNumber === 1) bloquesDay = agendaCliente.bloques["Lunes"];
      if (dayNumber === 2) bloquesDay = agendaCliente.bloques["Martes"];
      if (dayNumber === 3) bloquesDay = agendaCliente.bloques["Miercoles"];
      if (dayNumber === 4) bloquesDay = agendaCliente.bloques["Jueves"];
      if (dayNumber === 5) bloquesDay = agendaCliente.bloques["Viernes"];
      if (dayNumber === 6) bloquesDay = agendaCliente.bloques["Sabado"];
      if (dayNumber === 7) bloquesDay = agendaCliente.bloques["Domingo"];

      if (bloquesDay !== null && bloquesDay.length > 0) {
        visitasCliente !== null &&
          visitasCliente.map((t) => {
            bloquesDay = bloquesDay.filter((item) => item.tramo !== t);
            return null;
          });

        visitasBrokerSuscriptor !== null &&
          visitasBrokerSuscriptor.map((t) => {
            bloquesDay = bloquesDay.filter((item) => item.tramo !== t);
            return null;
          });

        bloquesDay.forEach((b) => {
          tramosDisponibles.push({
            label: b.tramo,
            value: b.tramo,
          });
        });
      }
      setTramos(tramosDisponibles);
    }
  };

  const handleSubmit = (formValues) => {
    console.log("aa")
    if (fechaVisita === null || fechaVisita === "") {
      setShowValidationErrorFechaVisita(true);
      return;
    }
    if (horaVisita === null || horaVisita === "") {
      setShowValidationErrorHoraVisita(true);
      return;
    }

    setShowValidationErrorFechaVisita(false);
    setShowValidationErrorHoraVisita(false);

    let dayShowcalendar = moment(fechaVisita);

    let horaInicio = horaVisita.split("-")[0];
    let horaTermino = horaVisita.split("-")[1];
    const { getVisitEventCalendarLink } = utilsFunc;

    let ubicacion =
      propiedad && `${propiedad.direccionReferencial}, ${propiedad.comuna}`;

    let linkAddToGoogleCalendar = getVisitEventCalendarLink(
      ubicacion,
      moment(dayShowcalendar).format("YYYY-MM-DD"),
      horaInicio,
      horaTermino,
      "google"
    );
    let linkAddToOutlookCalendar = getVisitEventCalendarLink(
      ubicacion,
      moment(dayShowcalendar).format("YYYY-MM-DD"),
      horaInicio,
      horaTermino,
      "outlook"
    );

    let data = {
      fecha: new Date(moment(dayShowcalendar)),
      clienteId: propiedad && propiedad.idCliente,
      //usuarioId: idBroker,
      idProspecto: formValues.idProspecto,
      dia: moment(dayShowcalendar).isoWeekday(),
      tramo: horaVisita,
      propiedadId: formValues.idPropiedad,
      linkAgregarEventoAGoogleCalendar: linkAddToGoogleCalendar,
      linkAgregarEventoAOutlookCalendar: linkAddToOutlookCalendar,
    };
    if (values) {
      //actualizar
      dispatch(putAgendarVisita(values.id, data));
    } else {
      dispatch(postAgendarVisita(data));
    }
  };

  useEffect(() => {
    if (
      requestPropiedades === "LOADING" ||
      requestAgendaCliente === "LOADING" ||
      requestAgendarVisita === "LOADING" ||
      requestPutAgendarVisita === "LOADING" ||
      requestCancelarVisita === "LOADING"
    ) {
      setIsLoading(true);
    } else {
      setIsLoading(false);
    }
  }, [
    requestPropiedades,
    requestAgendaCliente,
    requestAgendarVisita,
    requestPutAgendarVisita,
    requestCancelarVisita,
  ]);

  if (requestAgendarVisita === "SUCCESS") {
    handleClose();
    Swal.fire({
      title: "Visita agendada",
      text: "Se ha agendado la visita exitosamente",
      icon: "success",
    }).then((result) => {
      if (result.value || result.dismiss === "backdrop") {
        reloadMethod();
      }
    });
  }

  if (requestPutAgendarVisita === "SUCCESS") {
    handleClose();
    Swal.fire({
      title: "Visita actualizada",
      text: "Se ha actualizado la visita exitosamente",
      icon: "success",
    }).then((result) => {
      if (result.value || result.dismiss === "backdrop") {
        reloadMethod();
      }
    });
  }

  if (requestCancelarVisita === "SUCCESS") {
    handleClose();
    Swal.fire({
      title: "Visita cancelada",
      text: "Se ha cancelado la visita exitosamente",
      icon: "success",
    }).then((result) => {
      if (result.value || result.dismiss === "backdrop") {
        reloadMethod();
      }
    });
  }

  if (
    requestAgendarVisita === "ERROR" ||
    requestPutAgendarVisita === "ERROR" ||
    requestCancelarVisita === "ERROR"
  ) {
    Swal.fire({
      title: "Error",
      text: errorMessage,
      icon: "error",
    });
  }

  const showConfirmationDialog = () => {
    Swal.fire({
      title: `Confirmar cancelación de visita`,
      text: `Confirma que desea cancelar esta visita`,
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Confirmar",
    }).then((result) => {
      if (result.value) {
        values && values.id && dispatch(cancelarVisita(values.id));
      }
    });
  };

  const getDireccionPropiedad = (idProp) => {
    let propiedad =
      propiedades && propiedades.filter((items) => items.id === idProp);
    if (propiedad !== null && propiedad.length > 0) {
      propiedad = propiedad[0];
      if (
        propiedad.tipoPropiedad === "Casa" &&
        propiedad.nombreCalle === null
      ) {
        return `${propiedad.direccionReferencial} ${propiedad.numero}, ${propiedad.comuna}`;
      }

      if (
        propiedad.tipoPropiedad === "Casa" &&
        propiedad.nombreCalle !== null
      ) {
        return `${propiedad.nombreCalle} ${propiedad.numero}, ${propiedad.comuna}`;
      }

      if (
        propiedad.tipoPropiedad !== "Casa" &&
        propiedad.nombreCalle === null
      ) {
        return `${propiedad.direccionReferencial} ${propiedad.numero} departamento ${propiedad.numeroDepartamento}, ${propiedad.comuna}`;
      }
      if (
        propiedad.tipoPropiedad !== "Casa" &&
        propiedad.nombreCalle !== null
      ) {
        return `${propiedad.nombreCalle} ${propiedad.numero} departamento ${propiedad.numeroDepartamento}, ${propiedad.comuna}`;
      }
    }
  };

  return (
    <Modal
      disableScrollLock={false}
      open={isOpen}
      onClose={handleClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
      sx={{
        overflowY: "auto",
        top: isMobile ? "0px" : "10px",
        maxHeight: isMobile ? "100%" : "95%",
      }}
    >
      <Box sx={isMobile ? styleMobile : style}>
        {isLoading && (
          <Backdrop
            sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
            open={true}
            onClick={handleClose}
          >
            <CircularProgress color="inherit" />
          </Backdrop>
        )}
        <Grid
          container
          justifyContent="space-between"
          alignItems={"center"}
          sx={{ marginBottom: 2 }}
        >
          <Grid item xs={10} sm={10} md={10} lg={10} xl={10}>
            <h4>Agendar visita</h4>
          </Grid>
          <Grid item>
            <IconButton
              color="primary"
              aria-label="copiar"
              component="span"
              onClick={() => handleClose()}
            >
              <Close sx={{ fontSize: "20pt" }} />
            </IconButton>
          </Grid>
        </Grid>
        <Formik
          initialValues={{
            idPropiedad:
              values && values.idPropiedad
                ? values.idPropiedad
                : idPropiedad
                ? idPropiedad
                : "",
            idProspecto: values && values.idProspecto,
            fechaVisita,
            horaVisita,
          }}
          //validate={validate}
          onSubmit={handleSubmit}
          validationSchema={validate}
        >
          {({ handleSubmit, setFieldValue }) => (
            <>
              <Form onSubmit={handleSubmit} className="form-container">
                <Grid container>
                  {requestPropiedades === "IDLE" && idPropiedad && (
                    <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                      <label className="milabel">Propiedad</label>
                      {getDireccionPropiedad(idPropiedad)}
                    </Grid>
                  )}
                  {requestPropiedades === "IDLE" && !idPropiedad && (
                    <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                      <label className="milabel">Propiedad</label>
                      <Field
                        name="idPropiedad"
                        as="select"
                        className="miSelect"
                      >
                        <option value={""}>- Seleccione -</option>
                        {propiedades.map((c) => {
                          return (
                            <option value={c.id}>
                              {c.nombreCalle} {c.numero}
                            </option>
                          );
                        })}
                      </Field>
                      <FieldError name="idPropiedad" />
                    </Grid>
                  )}
                  {requestGetProspectosCA === "IDLE" &&
                    prospectosCA &&
                    prospectosCA.length > 0 && (
                      <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                        <label className="milabel">Prospecto</label>
                        <Field
                          name="idProspecto"
                          as="select"
                          className="miSelect"
                        >
                          <option value={""}>- Seleccione -</option>
                          {prospectosCA.map((c) => {
                            return (
                              <option value={c.id}>
                                {c.nombres} {c.apellidos}
                              </option>
                            );
                          })}
                        </Field>
                        <FieldError name="idProspecto" />
                      </Grid>
                    )}
                </Grid>
                <Grid container>
                  <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                    <label className="milabel">Fecha visita</label>
                    <DatePicker
                      value={fechaVisita}
                      onChange={setFechaVisita}
                      minDate={new Date()}
                    />
                  </Grid>
                  {showValidationErrorFechaVisita && (
                    <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                      <ErrorLabel>Ingrese una fecha de visita</ErrorLabel>
                    </Grid>
                  )}
                </Grid>
                <Grid container>
                  <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                    <label className="milabel">Hora</label>
                    <Field
                      name="horaVisita"
                      as="select"
                      className="miSelect"
                      onChange={onChangeHoraVisita}
                      value={horaVisita}
                    >
                      {/* antes era tramos filtrados segun agenda del propietario, ahora se admiten todos los tramos posibles.
                        {tramos.map((c) => (
                          <option value={c.value}>{c.label}</option>
                        ))} */}
                      <option value="">- Seleccione -</option>
                      {TRAMOS.map((c) => (
                        <option value={c}>{c}</option>
                      ))}
                    </Field>
                  </Grid>
                  {showValidationErrorHoraVisita && (
                    <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                      <ErrorLabel>Ingrese una hora de visita</ErrorLabel>
                    </Grid>
                  )}
                </Grid>
                <Grid
                  container
                  justifyContent="flex-end"
                  sx={{ mt: 2 }}
                  spacing={2}
                >
                  {values && values.id && (
                    <Grid item>
                      <Button
                        type="button"
                        className="main-btn bg-red"
                        variant="contained"
                        onClick={() => showConfirmationDialog()}
                      >
                        Cancelar visita
                      </Button>
                    </Grid>
                  )}

                  <Grid item>
                    <Button
                      type="submit"
                      className="main-btn"
                      variant="contained"
                      color="success"
                    >
                      Guardar
                    </Button>
                  </Grid>
                </Grid>
              </Form>
            </>
          )}
        </Formik>
      </Box>
    </Modal>
  );
};

export default FormAgendarVisita;
