import { Close, Edit, UploadFile } from "@mui/icons-material";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Button,
  Divider,
  Grid,
  IconButton,
  Tooltip,
  Typography,
} from "@mui/material";
import { Field, Form, Formik } from "formik";
import { useEffect, useState } from "react";
import * as Yup from "yup";
import { useHistory } from "react-router-dom";
import FieldError from "../../utils/FieldError";

const validate = Yup.object().shape({
  descripcion: Yup.string().required("Ingrese descripción"),
  cantidad: Yup.number()
    .typeError("Ingrese un valor numérico")
    .min(1, "Ingrese valor mayor a 0")
    .required("Ingrese cantidad"),
  estado: Yup.string().required("Ingrese estado"),
});

const ItemForm = ({ handleSubmit, handleClose }) => {
  return (
    <Grid container>
      <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
        <h5 className="alignLeft">Agregar item</h5>
      </Grid>
      <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
        <Formik
          initialValues={{
            observaciones: "",
            estado: "",
            descripcion: "",
            cantidad: 0,
          }}
          onSubmit={handleSubmit}
          validationSchema={validate}
        >
          {({ values, isValid, handleSubmit }) => (
            <Form onSubmit={handleSubmit}>
              <Grid container spacing={1}>
                <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                  <label className="milabel">Descripcion</label>
                  <Field name="descripcion" type="text" className="miSelect" />
                  <FieldError name="descripcion" />
                </Grid>
              </Grid>
              <Grid container spacing={1}>
                <Grid item xs={4} sm={4} md={4} lg={4} xl={4}>
                  <label className="milabel">Cantidad</label>
                  <Field name="cantidad" type="text" className="miSelect" />
                  <FieldError name="cantidad" />
                </Grid>
                <Grid item xs={8} sm={8} md={8} lg={8} xl={8}>
                  <label className="milabel">Estado</label>
                  <Field name="estado" as="select" className="miSelect">
                    <option value={""}>- Seleccione -</option>
                    <option value={0}>Bueno</option>
                    <option value={1}>Regular</option>
                    <option value={2}>Malo</option>
                  </Field>
                  <FieldError name="estado" />
                </Grid>
              </Grid>
              <Grid container spacing={1}>
                <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                  <label className="milabel">Observaciones</label>
                  <Field
                    name="observaciones"
                    type="text"
                    className="miTextarea"
                    as="textarea"
                  />
                  <FieldError name="observaciones" />
                </Grid>
              </Grid>
              <Grid container justifyContent="flex-end" sx={{ mt: 2 }}>
                <Grid item>
                  <Button
                    type="button"
                    size="small"
                    onClick={handleClose}
                    className="main-btn"
                  >
                    Cancelar
                  </Button>
                </Grid>
                <Grid item>
                  <Button
                    size="small"
                    type="button"
                    className="main-btn"
                    variant="contained"
                    color="success"
                    onClick={isValid ? handleSubmit : console.log("error")}
                  >
                    Guardar item
                  </Button>
                </Grid>
              </Grid>
            </Form>
          )}
        </Formik>
      </Grid>
      <Grid item xs={12} sm={12} md={12} lg={12} xl={12} sx={{ mt: 4 }}>
        <Divider variant="middle" sx={{ mb: 2, backgroundColor: "black" }} />
      </Grid>
    </Grid>
  );
};

export default ItemForm;
