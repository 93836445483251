import { Grid } from '@mui/material';
import React, {Component} from 'react';
import Dropzone from 'react-dropzone';

class FileUpload extends Component {
  constructor() {
    super();
    this.state = {
      files: []
    };
  }

  onDrop = (fileArray) => {
    this.setState({
      files: fileArray
    });
  }

  render() {
    const files = this.props.showFiles && this.state.files.map(file => (
      <div>
        {file.name} - {file.size} bytes
      </div>
    ));

    return (
      <Dropzone 
        name={this.props.name}
        accept={this.props.acceptedFiles}
        multiple={this.props.uploadMultiple}
        //accept={["application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"]}
        //onDropRejected={() => this.props.onDrop(false)} 
        onDropAccepted={(file) => this.props.onDrop(file)} 
        maxFiles={this.props.maxFiles}
        onDrop={this.onDrop}
        maxSize={this.props.sizeLimit}
      >
        {({getRootProps, getInputProps}) => (
          <Grid container>
            <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
              <div {...getRootProps({className: 'dropzonenew'})}>
                <input {...getInputProps()} />
                <p>
                  { this.props.uploadMultiple ? "Arrastre archivos hasta aquí o haga click para seleccionarlos"
                  : "Arrastre el archivo hasta aquí o haga click para seleccionarlo"}
                  
                </p>
              </div>
            </Grid>
            <Grid item xs={12} sm={12} md={12} lg={12} xl={12} sx={{mt: 2}}>
              <h6 className="selected-file-title">Archivo(s) seleccionado(s)</h6>
              <Grid container justifyContent="flex-start">
                {files && files}
              </Grid>
            </Grid>
          </Grid>
        )}
      </Dropzone>
    );
  }
}

export default FileUpload;