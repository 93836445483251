import { useDispatch, useSelector } from "react-redux";
import EditIcon from "@mui/icons-material/Edit";
import { useLocation } from "react-router-dom";
import { useEffect } from "react";
import { getProspecto, putProspectoCA, putProspectoVA } from "../../action";
import {
  Backdrop,
  Box,
  Button,
  CircularProgress,
  Grid,
  IconButton,
  Modal,
  Paper,
  Tooltip,
} from "@mui/material";
import { Close, Error } from "@mui/icons-material";
import {
  ESTADO_PROCESO_PROSPECTO,
  TIPO_CONTRATO_PROSPECTO,
} from "../../utils/constants";
import { Field, Form, Formik } from "formik";
import { useState } from "react";

const isMobile =
  !!navigator.userAgent.match(/iphone|android|blackberry/gi) || false;

const style = {
  position: "absolute",
  top: "0px",
  left: "50%",
  transform: "translate(-50%, 0%)",
  width: 500,
  bgcolor: "background.paper",
  boxShadow: 24,
  p: 4,
  //maxHeight: '90%'
};

const styleMobile = {
  position: "absolute",
  top: "0px",
  left: "0px",
  //transform: "translate(-50%, 0%)",
  width: "100vw",
  bgcolor: "background.paper",
  boxShadow: 24,
  p: 4,
  //maxHeight: '90%'
};

const InfoProspecto = ({ tipoProspecto, isOpen, handleClose, setIsOpen }) => {
  const dispatch = useDispatch();
  const prospecto = useSelector((state) => state.app.prospecto);
  const requestState = useSelector((state) => state.app.requestGetProspecto);
  const requestStateUpdate = useSelector(
    (state) => state.app.requestUpdateProspecto
  );
  const [isLoading, setIsLoading] = useState(false);
  let { search } = useLocation();
  let query = new URLSearchParams(search);
  let perfil = query.get("id");
  useEffect(() => {
    dispatch(getProspecto(tipoProspecto, perfil));
  }, []);

  const handleSubmit = (formData) => {
    if (tipoProspecto === "va") {
      //onsole.log("not implemented")
      dispatch(putProspectoVA(perfil, formData));
    } else {
      dispatch(putProspectoCA(perfil, formData));
    }
  };

  useEffect(() => {
    if (requestStateUpdate === "LOADING") {
      setIsLoading(true);
    } else {
      setIsLoading(false);
    }
  }, [requestStateUpdate]);
  
  if (requestState === "ERROR") {
    return (
      <Grid
        container
        sx={{ mt: 2 }}
        justifyContent="center"
        alignItems="center"
      >
        <Grid item xs={6} sm={6} md={4} lg={4} xl={4}>
          <Paper variant="outlined">
            <Error sx={{ fontSize: 40 }} />
            Ha habido un error
          </Paper>
        </Grid>
      </Grid>
    );
  }
  if (requestState === "LOADING") {
    return <CircularProgress />;
  }
  if (requestState === "IDLE" && prospecto) {
    return (
      <Grid container justifyContent={"flex-start"} className="alignLeft">
        <Grid item xs={6} sm={6} md={4} lg={3} xl={2}>
          <h5>Nombre</h5>
          {prospecto.nombres} {prospecto.apellidos}
        </Grid>
        {prospecto.tipoContratoProspecto !== null && (
          <Grid item xs={6} sm={6} md={4} lg={3} xl={2}>
            <h5>Tipo prospecto</h5>
            <p>{TIPO_CONTRATO_PROSPECTO[prospecto.tipoContratoProspecto]}</p>
          </Grid>
        )}
        <Grid item xs={6} sm={6} md={4} lg={3} xl={2}>
          <h5>Estado</h5>
          <p>{ESTADO_PROCESO_PROSPECTO[prospecto.estadoProcesoProspecto]}</p>
        </Grid>
        <Grid item xs={6} sm={6} md={4} lg={3} xl={3}>
          <h5>Correo electrónico</h5>
          <p>{prospecto.email}</p>
        </Grid>
        <Grid item xs={6} sm={6} md={4} lg={3} xl={2}>
          <h5>Teléfono</h5>
          <p>{prospecto.telefono}</p>
        </Grid>
        <Grid item xs={6} sm={6} md={4} lg={3} xl={2}>
          <h5>Rut</h5>
          <p>{prospecto.rut}</p>
        </Grid>
        <Grid item xs={2} sm={2} md={1} lg={1} xl={1}>
          <Tooltip title="Editar" aria-label="editar">
            <IconButton
              color="primary"
              aria-label="editar"
              component="span"
              onClick={() => setIsOpen(true)}
            >
              <EditIcon />
            </IconButton>
          </Tooltip>
        </Grid>
        <Modal
          disableScrollLock={false}
          open={isOpen}
          onClose={handleClose}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"   
          sx={{
            overflowY: "auto",
            top: isMobile ? "0px" : "10px",
            maxHeight: isMobile ? "100%" : "95%",
          }}
        >
          <Box sx={isMobile ? styleMobile : style}>
            {isLoading && (
              <Backdrop
                sx={{
                  color: "#fff",
                  zIndex: (theme) => theme.zIndex.drawer + 1,
                }}
                open={true}
                //onClick={handleClose}
              >
                <CircularProgress color="inherit" />
              </Backdrop>
            )}
            <Grid
              container
              justifyContent="space-between"
              alignItems={"center"}
            >
              <Grid item>
                <h5>Editar prospecto</h5>
              </Grid>
              <Grid item>
                <IconButton
                  color="primary"
                  aria-label="copiar"
                  component="span"
                  onClick={() => handleClose()}
                >
                  <Close sx={{ fontSize: "20pt" }} />
                </IconButton>
              </Grid>
            </Grid>

            <Formik
              initialValues={{
                nombres: prospecto.nombres,
                apellidos: prospecto.apellidos,
                estadoProcesoProspecto: prospecto.estadoProcesoProspecto,
                email: prospecto.email,
                telefono: prospecto.telefono,
                tipoContratoProspecto: prospecto.tipoContratoProspecto,
                rut: prospecto.rut
              }}
              onSubmit={handleSubmit}
            >
              {({ values, handleSubmit }) => (
                <Form onSubmit={handleSubmit}>
                  <Grid container spacing={1}>
                    <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                      <label className="milabel">Nombres</label>
                      <Field name="nombres" type="text" className="miSelect" />
                    </Grid>
                    <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                      <label className="milabel">Apellidos</label>
                      <Field name="apellidos" type="text" className="miSelect" />
                    </Grid>
                  </Grid>
                  <Grid container justifyContent="flex-start" spacing={1}>
                    <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                      <label className="milabel">Teléfono</label>
                      <Field name="telefono" type="text" className="miSelect" />
                    </Grid>
                    <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                      <label className="milabel">Rut</label>
                      <Field name="rut" type="text" className="miSelect" />
                    </Grid>
                  </Grid>
                  <Grid container justifyContent="flex-start">
                    <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                      <label className="milabel">Correo electrónico</label>
                      <Field name="email" type="text" className="miSelect" />
                    </Grid>
                  </Grid>  
                  {prospecto.tipoContratoProspecto !== null && (
                    <Grid container spacing={1}>
                      <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                        <label className="milabel">
                          Tipo persona prospecto
                        </label>
                        <Field
                          name="tipoContratoProspecto"
                          as="select"
                          className="miSelect"
                        >
                          <option value={""}>- Seleccione -</option>
                          {Object.keys(TIPO_CONTRATO_PROSPECTO).map((c) => (
                            <option value={c}>
                              {TIPO_CONTRATO_PROSPECTO[c]}
                            </option>
                          ))}
                        </Field>
                      </Grid>
                    </Grid>
                  )}
                  <Grid container spacing={1}>
                    <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                      <label className="milabel">
                        Estado proceso prospecto
                      </label>
                      <Field
                        name="estadoProcesoProspecto"
                        as="select"
                        className="miSelect"
                      >
                        <option value={""}>- Seleccione -</option>
                        {Object.keys(ESTADO_PROCESO_PROSPECTO).map((c) => (
                          <option value={c}>
                            {ESTADO_PROCESO_PROSPECTO[c]}
                          </option>
                        ))}
                      </Field>
                    </Grid>
                  </Grid>
                                  
                  <Grid container justifyContent="flex-end" sx={{ mt: 2 }}>
                    <Grid item>
                      <Button
                        onClick={handleClose}
                        className="main-btn"
                      >
                        Cancelar
                      </Button>
                    </Grid>
                    <Grid item>
                      <Button
                        type="submit"
                        className="main-btn"
                        variant="contained"
                        color="success"
                      >
                        Guardar
                      </Button>
                    </Grid>
                  </Grid>
                </Form>
              )}
            </Formik>
          </Box>
        </Modal>
      </Grid>
    );
  }
};

export default InfoProspecto;
